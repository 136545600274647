import Image from "next/image";

import GradientDiv from "@/components/GradientDiv";
import QuoteMark from "@/public/pages/about/quote-mark.png";

type Props = {
  imgSrc?: string;
  name: string;
  title: string;
  testimonial: string;
};

export default function TestimonialItem(props: Props) {
  return (
    <div className="max-w-full flex flex-col items-center justify-start gap-3">
      {/* Picture */}
      {props.imgSrc && (
        <Image
          className="w-[150px] h-[150px] rounded-full"
          alt="Avatar"
          src={props.imgSrc}
          width={100}
          height={100}
        />
      )}

      {!props.imgSrc && (
        <GradientDiv className="w-[150px] h-[150px] rounded-full" />
      )}

      <div className="flex flex-col items-center">
        {/* Name */}
        <p>{props.name}</p>

        {/* Title */}
        <p
          style={{
            background:
              "var(--Logo-3, linear-gradient(130deg, #CAB8FF 2.24%, #74DEFF 97.15%))",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          className="font-light text-sm"
        >
          {props.title}
        </p>
      </div>

      <div className="flex gap-2 pt-2">
        <Image
          className="w-4 shrink-0 h-fit pt-1.5"
          alt="Quote Mark"
          src={QuoteMark.src}
          width={QuoteMark.width}
          height={QuoteMark.height}
        />
        <p className="max-w-full">{props.testimonial}</p>
      </div>
    </div>
  );
}
