import axios from "axios";
import Link from "next/link";
import { useRouter } from "next/router";

import ChromeSvg from "@/public/chrome.svg";
import EdgeSvg from "@/public/edge.svg";
import YoyoKidsSvg from "@/public/pages/about/yoyo_kids.svg";
import { sentryReport } from "@/utils/Error";

export default function GetStartedSection() {
  const router = useRouter();

  return (
    <div className="flex flex-col md:flex-row gap-8 w-full max-w-[var(--max-width-app)] text-[var(--color-text-light-blue)] my-20">
      <div className="flex flex-col items-center">
        <h1 className="text-[var(--color-primary)] text-3xl md:text-5xl !leading-tight font-medium w-[70%]">
          Getting started with Yoyo Shortcut
        </h1>
        <YoyoKidsSvg className="w-[65%]" />
      </div>

      <div className="flex flex-col gap-6 px-8">
        <div className="flex flex-col gap-4 bg-[var(--color-card)] p-4 rounded-xl">
          <div className="flex flex-col sm:flex-row gap-4 sm:items-center">
            <div className="px-2 py-1 bg-[var(--color-secondary)] w-fit rounded-lg text-sm font-medium">
              Step 1
            </div>
            <h2 className="text-xl">Get the extension</h2>
          </div>
          <p className="font-light">
            Simply install the extension compatible with your preferred browser
          </p>
          <div className="flex gap-4">
            <Link
              href={`https://chromewebstore.google.com/detail/yoyo-shortcut/${process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID}`}
              target="_blank"
            >
              <ChromeSvg className="w-10 h-auto" />
            </Link>

            <Link
              href={`https://chromewebstore.google.com/detail/yoyo-shortcut/${process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID}`}
              target="_blank"
            >
              <EdgeSvg className="w-10 h-auto" />
            </Link>
          </div>
        </div>

        <div className="flex flex-col gap-4 bg-[var(--color-card)] p-4 rounded-xl">
          <div className="flex flex-col sm:flex-row gap-4 sm:items-center">
            <div className="px-2 py-1 bg-[var(--color-secondary)] w-fit rounded-lg text-sm font-medium">
              Step 2
            </div>
            <h2 className="text-xl">
              Sign up for{" "}
              <span className="text-[#FFE88E] font-medium">free</span>
            </h2>
          </div>
          <p className="font-light">
            Create your Yoyo Shortcut account and unlock a world of streamlined
            web navigation.
          </p>
          <div
            className="rounded-lg py-1.5 px-5 bg-[var(--color-yellow)] text-black w-fit cursor-pointer"
            onClick={() => {
              // Logging should not intervene or block other code
              router
                .push("/signup")
                .then(() =>
                  axios({
                    method: "post",
                    url: `${process.env.NEXT_PUBLIC_BACKEND_HOST}/log/try-free-clicks`,
                    data: {
                      buttonName: "Get Started Section - Sign Up Button",
                    },
                  })
                )
                .catch((err) => {
                  sentryReport(err);
                });
            }}
          >
            Sign Up
          </div>
        </div>

        <div className="flex flex-col gap-4 bg-[var(--color-card)] p-4 rounded-xl">
          <div className="flex flex-col sm:flex-row gap-4 sm:items-center">
            <div className="px-2 py-1 bg-[var(--color-secondary)] w-fit rounded-lg text-sm font-medium">
              Step 3
            </div>
            <h2 className="text-xl">Create keywords for your links</h2>
          </div>

          <p className="font-light">
            While browsing your favorite website, simply press
          </p>

          <div className="font-semibold flex flex-wrap gap-2.5 items-center">
            MacOS:
            <span className="px-1.5 py-0.5 bg-[#595959] rounded">Option</span> +
            <span className="px-1.5 py-0.5 bg-[#595959] rounded">Shift</span> +
            <span className="px-1.5 py-0.5 bg-[#595959] rounded">S</span>
          </div>

          <div className="font-semibold flex flex-wrap gap-2.5 items-center">
            Windows:
            <span className="px-1.5 py-0.5 bg-[#595959] rounded">Alt</span> +
            <span className="px-1.5 py-0.5 bg-[#595959] rounded">Shift</span> +
            <span className="px-1.5 py-0.5 bg-[#595959] rounded">S</span>
          </div>

          <p className="font-light">
            to instantly bookmark and create keywords the current webpage.
          </p>
        </div>

        <div className="flex flex-col gap-4 bg-[var(--color-card)] p-4 rounded-xl">
          <div className="flex flex-col sm:flex-row gap-4 sm:items-center">
            <div className="px-2 py-1 bg-[var(--color-secondary)] w-fit rounded-lg text-sm font-medium">
              Step 4
            </div>
            <h2 className="text-xl">Navigate your website</h2>
          </div>

          <div className="flex flex-col gap-1.5">
            <div className="font-semibold flex flex-wrap gap-2.5 items-center">
              <p className="font-light">Type</p>
              <div className="flex px-1.5 py-0.5 bg-[#3C4D64] rounded">
                <span
                  style={{
                    background:
                      "var(--Logo-3, linear-gradient(130deg, #CAB8FF 2.24%, #74DEFF 97.15%))",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  yo
                </span>
              </div>{" "}
              +<span className="px-1.5 py-0.5 bg-[#595959] rounded">Space</span>{" "}
              +
              <span className="px-1.5 py-0.5 bg-[#595959] rounded">
                {"<keyword>"}
              </span>
            </div>

            <p className="font-light">
              into the address bar, and voilà! Experience the magic of direct,
              swift access.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
