/*
    asPath: asPath in nextjs router includes query string 
    route: route in nextjs router does not include query string
    - E.g: route = "/board", asPath = "/board?test=asdf"
*/

const FRONTEND_HOST =
  process.env.FRONTEND_HOST ?? process.env.NEXT_PUBLIC_FRONTEND_HOST;

export const getParams = (
  router: { route: string; asPath: string },
  paramKeys: string[]
): Record<string, string | null> => {
  const url = new URL(FRONTEND_HOST + router.asPath);
  const result: Record<string, string | null> = {};

  for (const key of paramKeys) {
    result[key] = url.searchParams.get(key);
  }

  return result;
};

export const setParam = (
  router: { route: string; asPath: string },
  param: { key: string; value: string }
): string => {
  const url = new URL(FRONTEND_HOST + router.asPath);
  url.searchParams.set(param.key, param.value);

  const result = url.searchParams.toString();
  return result.length === 0 ? "" : "?" + result;
};

export const setParams = (
  router: { route: string; asPath: string },
  params: { key: string; value: string }[]
): string => {
  const url = new URL(FRONTEND_HOST + router.asPath);

  for (const param of params) {
    url.searchParams.set(param.key, param.value);
  }

  const result = url.searchParams.toString();
  return result.length === 0 ? "" : "?" + result;
};

export const removeParams = (
  router: { route: string; asPath: string },
  paramKeys: string[]
): string => {
  const url = new URL(FRONTEND_HOST + router.asPath);

  for (const key of paramKeys) {
    url.searchParams.delete(key);
  }

  const result = url.searchParams.toString();
  return result.length === 0 ? "" : "?" + result;
};
