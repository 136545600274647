import Image from "next/image";

import BeautifulDashboardImg from "@/public/pages/about/beautiful-dashboard.png";
import CrossDeviceImg from "@/public/pages/about/cross-device.png";
import InstantBookmarkImg from "@/public/pages/about/instant-bookmark.png";
import NavigateToLinkImg from "@/public/pages/about/navigate-to-links.png";
import SmartKeywordsImg from "@/public/pages/about/smart-keywords.png";

export default function FeatureSection() {
  return (
    <div className="flex flex-col w-full max-w-6xl py-8 px-6 lg:py-16 lg:px-24 gap-12 text-[var(--color-text-light-blue)]">
      <h1 className="text-2xl lg:text-4xl !leading-tight text-[--color-text-highlight]">
        A whole <span className="text-[--color-yellow]">new way</span> to access
        your links and <span className="text-[--color-yellow]">optimize</span>{" "}
        your workflow in just a few simple steps
      </h1>

      <div className="flex flex-col gap-12 lg:gap-8 lg:grid lg:grid-rows-2 lg:grid-cols-2">
        <div className="flex flex-col justify-between gap-4 p-6 lg:p-8 !pb-0 bg-[--color-card] border-[--color-card-light] border-[1px] rounded-xl">
          <h1 className="text-xl font-medium">
            Create keywords for links from anywhere
          </h1>
          <p className="font-light">
            Add memorable keywords for any website instantly from anywhere.
          </p>
          <div className="flex grow items-end justify-center w-full">
            <Image
              alt="Instant Bookmark Image"
              className="w-full max-w-[500px] h-fit"
              src={InstantBookmarkImg.src}
              width={InstantBookmarkImg.width}
              height={InstantBookmarkImg.height}
            />
          </div>
        </div>

        <div className="flex flex-col gap-4 p-6 lg:p-8 !pb-0 bg-[--color-card] border-[--color-card-light] border-[1px] rounded-xl">
          <h1 className="text-xl font-medium">
            Navigate to links with the keywords you created
          </h1>
          <p className="font-light">
            Type keywords directly into your browser&apos;s search bar, and
            you&apos;ll instantly arrive at the website you need.
          </p>
          <div className="flex grow items-end justify-center w-full">
            <Image
              alt="Navigate To Link Image"
              className="w-[90%] max-w-[500px] h-fit"
              src={NavigateToLinkImg.src}
              width={NavigateToLinkImg.width}
              height={NavigateToLinkImg.height}
            />
          </div>
        </div>

        <div className="flex flex-col gap-4 p-6 lg:p-8 !pb-0 bg-[--color-card] border-[--color-card-light] border-[1px] rounded-xl">
          <h1 className="text-xl font-medium">
            Organize links the way you want
          </h1>
          <p className="font-light">
            Drag and drop to reorder and categorize links into dashboards.
            Tailor your dashboard&apos;s appearance to make it not just
            functional but also aesthetic.
          </p>
          <div className="flex grow items-end justify-center w-full">
            <Image
              alt="Beautiful Dashboard Image"
              className="w-full max-w-[500px] h-fit"
              src={BeautifulDashboardImg.src}
              width={BeautifulDashboardImg.width}
              height={BeautifulDashboardImg.height}
            />
          </div>
        </div>

        <div className="flex flex-col gap-4 p-6 lg:p-8 !pb-0 bg-[--color-card] border-[--color-card-light] border-[1px] rounded-xl">
          <h1 className="text-xl font-medium">
            Reuse keywords in different boards
          </h1>
          <p className="font-light">
            You may have similar link keywords across different boards: a
            personal todo list vs. a work todo list. To access links in a
            specific board, add keywords to that board. Then, chain the
            board&apos;s keywords with the link&apos;s keywords to navigate
            within that board&apos;s scope.
          </p>
          <div className="flex grow items-center justify-center w-full pb-2">
            <Image
              alt="Smart Keywords Image"
              className="w-full max-w-[500px] h-fit"
              src={SmartKeywordsImg.src}
              width={SmartKeywordsImg.width}
              height={SmartKeywordsImg.height}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-10 w-full">
        <h1 className="text-2xl lg:text-4xl text-[--color-text-highlight]">
          An <span className="text-[--color-yellow]">unopinionated</span> and{" "}
          <span className="text-[--color-yellow]">universal</span> bookmarking
          tool
        </h1>

        <div className="flex gap-10 lg:gap-6 w-full flex-col lg:grid lg:grid-cols-3 lg:grid-rows-2">
          <div className="row-span-2 flex items-center">
            <Image
              alt="Cross Device Image"
              className="w-full max-w-[300px] lg:max-w-[280px] h-fit"
              src={CrossDeviceImg.src}
              width={CrossDeviceImg.width}
              height={CrossDeviceImg.height}
            />
          </div>

          <div className="flex flex-col gap-2">
            <h3 className="text-xl">Cross-Device Sync</h3>
            <p className="font-light">
              Access your links and keywords from anywhere, ensuring a
              consistent experience across all your devices.
            </p>
          </div>

          <div className="flex flex-col gap-2">
            <h3 className="text-xl">No Lock-in</h3>
            <p className="font-light">
              Integrate seamlessly alongside other bookmarking apps or as a
              standalone solution without trade-offs.
            </p>
          </div>

          <div className="flex flex-col gap-2">
            <h3 className="text-xl">Customizable Keyboard Shortcuts</h3>
            <p className="font-light">
              If our default keyboard shortcuts are not intuitive to you,
              they’re totally customizable to your choice.
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-xl">Privacy and Security</h3>
            <p className="font-light">
              Rest easy knowing your data is secure. We prioritize the privacy
              and protection of your information.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
