import { useEffect, useState } from "react";

import clsx from "clsx";

import { Gradients } from "./gradients";

type Props = {
  className?: string;
};

export default function GradientDiv(props: Props) {
  const [random, setRandom] = useState<number | null>(null);

  useEffect(() => {
    // generateGradients();
    setRandom(Math.floor(Math.random() * Gradients.length));
  }, []);

  return (
    <div
      className={clsx(props.className, { [Gradients[random!]]: random })}
    ></div>
  );
}
