export function generateGradients() {
  const colors = [
    "red",
    "orange",
    "amber",
    "yellow",
    "lime",
    "green",
    "emerald",
    "teal",
    "cyan",
    "sky",
    "blue",
    "indigo",
    "violet",
    "purple",
    "fuchsia",
    "pink",
    "rose",
  ];

  const fades = [300, 400, 500, 600, 700, 800, 900, 950];

  let str = "";

  for (const color1 of colors) {
    for (const fade1 of fades) {
      for (const color2 of colors) {
        if (color1 === color2) {
          continue;
        }

        for (const fade2 of fades) {
          const fr = `from-${color1}-${fade1}`;
          const to = `to-${color2}-${fade2}`;

          str += `"bg-gradient-to-r ${fr} ${to}",`;
        }
      }
    }
  }

  console.log(str);
}

export const Gradients = [
  "bg-gradient-to-r from-red-300 to-orange-300",
  "bg-gradient-to-r from-red-300 to-orange-400",
  "bg-gradient-to-r from-red-300 to-orange-500",
  "bg-gradient-to-r from-red-300 to-orange-600",
  "bg-gradient-to-r from-red-300 to-orange-700",
  "bg-gradient-to-r from-red-300 to-orange-800",
  "bg-gradient-to-r from-red-300 to-orange-900",
  "bg-gradient-to-r from-red-300 to-orange-950",
  "bg-gradient-to-r from-red-300 to-amber-300",
  "bg-gradient-to-r from-red-300 to-amber-400",
  "bg-gradient-to-r from-red-300 to-amber-500",
  "bg-gradient-to-r from-red-300 to-amber-600",
  "bg-gradient-to-r from-red-300 to-amber-700",
  "bg-gradient-to-r from-red-300 to-amber-800",
  "bg-gradient-to-r from-red-300 to-amber-900",
  "bg-gradient-to-r from-red-300 to-amber-950",
  "bg-gradient-to-r from-red-300 to-yellow-300",
  "bg-gradient-to-r from-red-300 to-yellow-400",
  "bg-gradient-to-r from-red-300 to-yellow-500",
  "bg-gradient-to-r from-red-300 to-yellow-600",
  "bg-gradient-to-r from-red-300 to-yellow-700",
  "bg-gradient-to-r from-red-300 to-yellow-800",
  "bg-gradient-to-r from-red-300 to-yellow-900",
  "bg-gradient-to-r from-red-300 to-yellow-950",
  "bg-gradient-to-r from-red-300 to-lime-300",
  "bg-gradient-to-r from-red-300 to-lime-400",
  "bg-gradient-to-r from-red-300 to-lime-500",
  "bg-gradient-to-r from-red-300 to-lime-600",
  "bg-gradient-to-r from-red-300 to-lime-700",
  "bg-gradient-to-r from-red-300 to-lime-800",
  "bg-gradient-to-r from-red-300 to-lime-900",
  "bg-gradient-to-r from-red-300 to-lime-950",
  "bg-gradient-to-r from-red-300 to-green-300",
  "bg-gradient-to-r from-red-300 to-green-400",
  "bg-gradient-to-r from-red-300 to-green-500",
  "bg-gradient-to-r from-red-300 to-green-600",
  "bg-gradient-to-r from-red-300 to-green-700",
  "bg-gradient-to-r from-red-300 to-green-800",
  "bg-gradient-to-r from-red-300 to-green-900",
  "bg-gradient-to-r from-red-300 to-green-950",
  "bg-gradient-to-r from-red-300 to-emerald-300",
  "bg-gradient-to-r from-red-300 to-emerald-400",
  "bg-gradient-to-r from-red-300 to-emerald-500",
  "bg-gradient-to-r from-red-300 to-emerald-600",
  "bg-gradient-to-r from-red-300 to-emerald-700",
  "bg-gradient-to-r from-red-300 to-emerald-800",
  "bg-gradient-to-r from-red-300 to-emerald-900",
  "bg-gradient-to-r from-red-300 to-emerald-950",
  "bg-gradient-to-r from-red-300 to-teal-300",
  "bg-gradient-to-r from-red-300 to-teal-400",
  "bg-gradient-to-r from-red-300 to-teal-500",
  "bg-gradient-to-r from-red-300 to-teal-600",
  "bg-gradient-to-r from-red-300 to-teal-700",
  "bg-gradient-to-r from-red-300 to-teal-800",
  "bg-gradient-to-r from-red-300 to-teal-900",
  "bg-gradient-to-r from-red-300 to-teal-950",
  "bg-gradient-to-r from-red-300 to-cyan-300",
  "bg-gradient-to-r from-red-300 to-cyan-400",
  "bg-gradient-to-r from-red-300 to-cyan-500",
  "bg-gradient-to-r from-red-300 to-cyan-600",
  "bg-gradient-to-r from-red-300 to-cyan-700",
  "bg-gradient-to-r from-red-300 to-cyan-800",
  "bg-gradient-to-r from-red-300 to-cyan-900",
  "bg-gradient-to-r from-red-300 to-cyan-950",
  "bg-gradient-to-r from-red-300 to-sky-300",
  "bg-gradient-to-r from-red-300 to-sky-400",
  "bg-gradient-to-r from-red-300 to-sky-500",
  "bg-gradient-to-r from-red-300 to-sky-600",
  "bg-gradient-to-r from-red-300 to-sky-700",
  "bg-gradient-to-r from-red-300 to-sky-800",
  "bg-gradient-to-r from-red-300 to-sky-900",
  "bg-gradient-to-r from-red-300 to-sky-950",
  "bg-gradient-to-r from-red-300 to-blue-300",
  "bg-gradient-to-r from-red-300 to-blue-400",
  "bg-gradient-to-r from-red-300 to-blue-500",
  "bg-gradient-to-r from-red-300 to-blue-600",
  "bg-gradient-to-r from-red-300 to-blue-700",
  "bg-gradient-to-r from-red-300 to-blue-800",
  "bg-gradient-to-r from-red-300 to-blue-900",
  "bg-gradient-to-r from-red-300 to-blue-950",
  "bg-gradient-to-r from-red-300 to-indigo-300",
  "bg-gradient-to-r from-red-300 to-indigo-400",
  "bg-gradient-to-r from-red-300 to-indigo-500",
  "bg-gradient-to-r from-red-300 to-indigo-600",
  "bg-gradient-to-r from-red-300 to-indigo-700",
  "bg-gradient-to-r from-red-300 to-indigo-800",
  "bg-gradient-to-r from-red-300 to-indigo-900",
  "bg-gradient-to-r from-red-300 to-indigo-950",
  "bg-gradient-to-r from-red-300 to-violet-300",
  "bg-gradient-to-r from-red-300 to-violet-400",
  "bg-gradient-to-r from-red-300 to-violet-500",
  "bg-gradient-to-r from-red-300 to-violet-600",
  "bg-gradient-to-r from-red-300 to-violet-700",
  "bg-gradient-to-r from-red-300 to-violet-800",
  "bg-gradient-to-r from-red-300 to-violet-900",
  "bg-gradient-to-r from-red-300 to-violet-950",
  "bg-gradient-to-r from-red-300 to-purple-300",
  "bg-gradient-to-r from-red-300 to-purple-400",
  "bg-gradient-to-r from-red-300 to-purple-500",
  "bg-gradient-to-r from-red-300 to-purple-600",
  "bg-gradient-to-r from-red-300 to-purple-700",
  "bg-gradient-to-r from-red-300 to-purple-800",
  "bg-gradient-to-r from-red-300 to-purple-900",
  "bg-gradient-to-r from-red-300 to-purple-950",
  "bg-gradient-to-r from-red-300 to-fuchsia-300",
  "bg-gradient-to-r from-red-300 to-fuchsia-400",
  "bg-gradient-to-r from-red-300 to-fuchsia-500",
  "bg-gradient-to-r from-red-300 to-fuchsia-600",
  "bg-gradient-to-r from-red-300 to-fuchsia-700",
  "bg-gradient-to-r from-red-300 to-fuchsia-800",
  "bg-gradient-to-r from-red-300 to-fuchsia-900",
  "bg-gradient-to-r from-red-300 to-fuchsia-950",
  "bg-gradient-to-r from-red-300 to-pink-300",
  "bg-gradient-to-r from-red-300 to-pink-400",
  "bg-gradient-to-r from-red-300 to-pink-500",
  "bg-gradient-to-r from-red-300 to-pink-600",
  "bg-gradient-to-r from-red-300 to-pink-700",
  "bg-gradient-to-r from-red-300 to-pink-800",
  "bg-gradient-to-r from-red-300 to-pink-900",
  "bg-gradient-to-r from-red-300 to-pink-950",
  "bg-gradient-to-r from-red-300 to-rose-300",
  "bg-gradient-to-r from-red-300 to-rose-400",
  "bg-gradient-to-r from-red-300 to-rose-500",
  "bg-gradient-to-r from-red-300 to-rose-600",
  "bg-gradient-to-r from-red-300 to-rose-700",
  "bg-gradient-to-r from-red-300 to-rose-800",
  "bg-gradient-to-r from-red-300 to-rose-900",
  "bg-gradient-to-r from-red-300 to-rose-950",
  "bg-gradient-to-r from-red-400 to-orange-300",
  "bg-gradient-to-r from-red-400 to-orange-400",
  "bg-gradient-to-r from-red-400 to-orange-500",
  "bg-gradient-to-r from-red-400 to-orange-600",
  "bg-gradient-to-r from-red-400 to-orange-700",
  "bg-gradient-to-r from-red-400 to-orange-800",
  "bg-gradient-to-r from-red-400 to-orange-900",
  "bg-gradient-to-r from-red-400 to-orange-950",
  "bg-gradient-to-r from-red-400 to-amber-300",
  "bg-gradient-to-r from-red-400 to-amber-400",
  "bg-gradient-to-r from-red-400 to-amber-500",
  "bg-gradient-to-r from-red-400 to-amber-600",
  "bg-gradient-to-r from-red-400 to-amber-700",
  "bg-gradient-to-r from-red-400 to-amber-800",
  "bg-gradient-to-r from-red-400 to-amber-900",
  "bg-gradient-to-r from-red-400 to-amber-950",
  "bg-gradient-to-r from-red-400 to-yellow-300",
  "bg-gradient-to-r from-red-400 to-yellow-400",
  "bg-gradient-to-r from-red-400 to-yellow-500",
  "bg-gradient-to-r from-red-400 to-yellow-600",
  "bg-gradient-to-r from-red-400 to-yellow-700",
  "bg-gradient-to-r from-red-400 to-yellow-800",
  "bg-gradient-to-r from-red-400 to-yellow-900",
  "bg-gradient-to-r from-red-400 to-yellow-950",
  "bg-gradient-to-r from-red-400 to-lime-300",
  "bg-gradient-to-r from-red-400 to-lime-400",
  "bg-gradient-to-r from-red-400 to-lime-500",
  "bg-gradient-to-r from-red-400 to-lime-600",
  "bg-gradient-to-r from-red-400 to-lime-700",
  "bg-gradient-to-r from-red-400 to-lime-800",
  "bg-gradient-to-r from-red-400 to-lime-900",
  "bg-gradient-to-r from-red-400 to-lime-950",
  "bg-gradient-to-r from-red-400 to-green-300",
  "bg-gradient-to-r from-red-400 to-green-400",
  "bg-gradient-to-r from-red-400 to-green-500",
  "bg-gradient-to-r from-red-400 to-green-600",
  "bg-gradient-to-r from-red-400 to-green-700",
  "bg-gradient-to-r from-red-400 to-green-800",
  "bg-gradient-to-r from-red-400 to-green-900",
  "bg-gradient-to-r from-red-400 to-green-950",
  "bg-gradient-to-r from-red-400 to-emerald-300",
  "bg-gradient-to-r from-red-400 to-emerald-400",
  "bg-gradient-to-r from-red-400 to-emerald-500",
  "bg-gradient-to-r from-red-400 to-emerald-600",
  "bg-gradient-to-r from-red-400 to-emerald-700",
  "bg-gradient-to-r from-red-400 to-emerald-800",
  "bg-gradient-to-r from-red-400 to-emerald-900",
  "bg-gradient-to-r from-red-400 to-emerald-950",
  "bg-gradient-to-r from-red-400 to-teal-300",
  "bg-gradient-to-r from-red-400 to-teal-400",
  "bg-gradient-to-r from-red-400 to-teal-500",
  "bg-gradient-to-r from-red-400 to-teal-600",
  "bg-gradient-to-r from-red-400 to-teal-700",
  "bg-gradient-to-r from-red-400 to-teal-800",
  "bg-gradient-to-r from-red-400 to-teal-900",
  "bg-gradient-to-r from-red-400 to-teal-950",
  "bg-gradient-to-r from-red-400 to-cyan-300",
  "bg-gradient-to-r from-red-400 to-cyan-400",
  "bg-gradient-to-r from-red-400 to-cyan-500",
  "bg-gradient-to-r from-red-400 to-cyan-600",
  "bg-gradient-to-r from-red-400 to-cyan-700",
  "bg-gradient-to-r from-red-400 to-cyan-800",
  "bg-gradient-to-r from-red-400 to-cyan-900",
  "bg-gradient-to-r from-red-400 to-cyan-950",
  "bg-gradient-to-r from-red-400 to-sky-300",
  "bg-gradient-to-r from-red-400 to-sky-400",
  "bg-gradient-to-r from-red-400 to-sky-500",
  "bg-gradient-to-r from-red-400 to-sky-600",
  "bg-gradient-to-r from-red-400 to-sky-700",
  "bg-gradient-to-r from-red-400 to-sky-800",
  "bg-gradient-to-r from-red-400 to-sky-900",
  "bg-gradient-to-r from-red-400 to-sky-950",
  "bg-gradient-to-r from-red-400 to-blue-300",
  "bg-gradient-to-r from-red-400 to-blue-400",
  "bg-gradient-to-r from-red-400 to-blue-500",
  "bg-gradient-to-r from-red-400 to-blue-600",
  "bg-gradient-to-r from-red-400 to-blue-700",
  "bg-gradient-to-r from-red-400 to-blue-800",
  "bg-gradient-to-r from-red-400 to-blue-900",
  "bg-gradient-to-r from-red-400 to-blue-950",
  "bg-gradient-to-r from-red-400 to-indigo-300",
  "bg-gradient-to-r from-red-400 to-indigo-400",
  "bg-gradient-to-r from-red-400 to-indigo-500",
  "bg-gradient-to-r from-red-400 to-indigo-600",
  "bg-gradient-to-r from-red-400 to-indigo-700",
  "bg-gradient-to-r from-red-400 to-indigo-800",
  "bg-gradient-to-r from-red-400 to-indigo-900",
  "bg-gradient-to-r from-red-400 to-indigo-950",
  "bg-gradient-to-r from-red-400 to-violet-300",
  "bg-gradient-to-r from-red-400 to-violet-400",
  "bg-gradient-to-r from-red-400 to-violet-500",
  "bg-gradient-to-r from-red-400 to-violet-600",
  "bg-gradient-to-r from-red-400 to-violet-700",
  "bg-gradient-to-r from-red-400 to-violet-800",
  "bg-gradient-to-r from-red-400 to-violet-900",
  "bg-gradient-to-r from-red-400 to-violet-950",
  "bg-gradient-to-r from-red-400 to-purple-300",
  "bg-gradient-to-r from-red-400 to-purple-400",
  "bg-gradient-to-r from-red-400 to-purple-500",
  "bg-gradient-to-r from-red-400 to-purple-600",
  "bg-gradient-to-r from-red-400 to-purple-700",
  "bg-gradient-to-r from-red-400 to-purple-800",
  "bg-gradient-to-r from-red-400 to-purple-900",
  "bg-gradient-to-r from-red-400 to-purple-950",
  "bg-gradient-to-r from-red-400 to-fuchsia-300",
  "bg-gradient-to-r from-red-400 to-fuchsia-400",
  "bg-gradient-to-r from-red-400 to-fuchsia-500",
  "bg-gradient-to-r from-red-400 to-fuchsia-600",
  "bg-gradient-to-r from-red-400 to-fuchsia-700",
  "bg-gradient-to-r from-red-400 to-fuchsia-800",
  "bg-gradient-to-r from-red-400 to-fuchsia-900",
  "bg-gradient-to-r from-red-400 to-fuchsia-950",
  "bg-gradient-to-r from-red-400 to-pink-300",
  "bg-gradient-to-r from-red-400 to-pink-400",
  "bg-gradient-to-r from-red-400 to-pink-500",
  "bg-gradient-to-r from-red-400 to-pink-600",
  "bg-gradient-to-r from-red-400 to-pink-700",
  "bg-gradient-to-r from-red-400 to-pink-800",
  "bg-gradient-to-r from-red-400 to-pink-900",
  "bg-gradient-to-r from-red-400 to-pink-950",
  "bg-gradient-to-r from-red-400 to-rose-300",
  "bg-gradient-to-r from-red-400 to-rose-400",
  "bg-gradient-to-r from-red-400 to-rose-500",
  "bg-gradient-to-r from-red-400 to-rose-600",
  "bg-gradient-to-r from-red-400 to-rose-700",
  "bg-gradient-to-r from-red-400 to-rose-800",
  "bg-gradient-to-r from-red-400 to-rose-900",
  "bg-gradient-to-r from-red-400 to-rose-950",
  "bg-gradient-to-r from-red-500 to-orange-300",
  "bg-gradient-to-r from-red-500 to-orange-400",
  "bg-gradient-to-r from-red-500 to-orange-500",
  "bg-gradient-to-r from-red-500 to-orange-600",
  "bg-gradient-to-r from-red-500 to-orange-700",
  "bg-gradient-to-r from-red-500 to-orange-800",
  "bg-gradient-to-r from-red-500 to-orange-900",
  "bg-gradient-to-r from-red-500 to-orange-950",
  "bg-gradient-to-r from-red-500 to-amber-300",
  "bg-gradient-to-r from-red-500 to-amber-400",
  "bg-gradient-to-r from-red-500 to-amber-500",
  "bg-gradient-to-r from-red-500 to-amber-600",
  "bg-gradient-to-r from-red-500 to-amber-700",
  "bg-gradient-to-r from-red-500 to-amber-800",
  "bg-gradient-to-r from-red-500 to-amber-900",
  "bg-gradient-to-r from-red-500 to-amber-950",
  "bg-gradient-to-r from-red-500 to-yellow-300",
  "bg-gradient-to-r from-red-500 to-yellow-400",
  "bg-gradient-to-r from-red-500 to-yellow-500",
  "bg-gradient-to-r from-red-500 to-yellow-600",
  "bg-gradient-to-r from-red-500 to-yellow-700",
  "bg-gradient-to-r from-red-500 to-yellow-800",
  "bg-gradient-to-r from-red-500 to-yellow-900",
  "bg-gradient-to-r from-red-500 to-yellow-950",
  "bg-gradient-to-r from-red-500 to-lime-300",
  "bg-gradient-to-r from-red-500 to-lime-400",
  "bg-gradient-to-r from-red-500 to-lime-500",
  "bg-gradient-to-r from-red-500 to-lime-600",
  "bg-gradient-to-r from-red-500 to-lime-700",
  "bg-gradient-to-r from-red-500 to-lime-800",
  "bg-gradient-to-r from-red-500 to-lime-900",
  "bg-gradient-to-r from-red-500 to-lime-950",
  "bg-gradient-to-r from-red-500 to-green-300",
  "bg-gradient-to-r from-red-500 to-green-400",
  "bg-gradient-to-r from-red-500 to-green-500",
  "bg-gradient-to-r from-red-500 to-green-600",
  "bg-gradient-to-r from-red-500 to-green-700",
  "bg-gradient-to-r from-red-500 to-green-800",
  "bg-gradient-to-r from-red-500 to-green-900",
  "bg-gradient-to-r from-red-500 to-green-950",
  "bg-gradient-to-r from-red-500 to-emerald-300",
  "bg-gradient-to-r from-red-500 to-emerald-400",
  "bg-gradient-to-r from-red-500 to-emerald-500",
  "bg-gradient-to-r from-red-500 to-emerald-600",
  "bg-gradient-to-r from-red-500 to-emerald-700",
  "bg-gradient-to-r from-red-500 to-emerald-800",
  "bg-gradient-to-r from-red-500 to-emerald-900",
  "bg-gradient-to-r from-red-500 to-emerald-950",
  "bg-gradient-to-r from-red-500 to-teal-300",
  "bg-gradient-to-r from-red-500 to-teal-400",
  "bg-gradient-to-r from-red-500 to-teal-500",
  "bg-gradient-to-r from-red-500 to-teal-600",
  "bg-gradient-to-r from-red-500 to-teal-700",
  "bg-gradient-to-r from-red-500 to-teal-800",
  "bg-gradient-to-r from-red-500 to-teal-900",
  "bg-gradient-to-r from-red-500 to-teal-950",
  "bg-gradient-to-r from-red-500 to-cyan-300",
  "bg-gradient-to-r from-red-500 to-cyan-400",
  "bg-gradient-to-r from-red-500 to-cyan-500",
  "bg-gradient-to-r from-red-500 to-cyan-600",
  "bg-gradient-to-r from-red-500 to-cyan-700",
  "bg-gradient-to-r from-red-500 to-cyan-800",
  "bg-gradient-to-r from-red-500 to-cyan-900",
  "bg-gradient-to-r from-red-500 to-cyan-950",
  "bg-gradient-to-r from-red-500 to-sky-300",
  "bg-gradient-to-r from-red-500 to-sky-400",
  "bg-gradient-to-r from-red-500 to-sky-500",
  "bg-gradient-to-r from-red-500 to-sky-600",
  "bg-gradient-to-r from-red-500 to-sky-700",
  "bg-gradient-to-r from-red-500 to-sky-800",
  "bg-gradient-to-r from-red-500 to-sky-900",
  "bg-gradient-to-r from-red-500 to-sky-950",
  "bg-gradient-to-r from-red-500 to-blue-300",
  "bg-gradient-to-r from-red-500 to-blue-400",
  "bg-gradient-to-r from-red-500 to-blue-500",
  "bg-gradient-to-r from-red-500 to-blue-600",
  "bg-gradient-to-r from-red-500 to-blue-700",
  "bg-gradient-to-r from-red-500 to-blue-800",
  "bg-gradient-to-r from-red-500 to-blue-900",
  "bg-gradient-to-r from-red-500 to-blue-950",
  "bg-gradient-to-r from-red-500 to-indigo-300",
  "bg-gradient-to-r from-red-500 to-indigo-400",
  "bg-gradient-to-r from-red-500 to-indigo-500",
  "bg-gradient-to-r from-red-500 to-indigo-600",
  "bg-gradient-to-r from-red-500 to-indigo-700",
  "bg-gradient-to-r from-red-500 to-indigo-800",
  "bg-gradient-to-r from-red-500 to-indigo-900",
  "bg-gradient-to-r from-red-500 to-indigo-950",
  "bg-gradient-to-r from-red-500 to-violet-300",
  "bg-gradient-to-r from-red-500 to-violet-400",
  "bg-gradient-to-r from-red-500 to-violet-500",
  "bg-gradient-to-r from-red-500 to-violet-600",
  "bg-gradient-to-r from-red-500 to-violet-700",
  "bg-gradient-to-r from-red-500 to-violet-800",
  "bg-gradient-to-r from-red-500 to-violet-900",
  "bg-gradient-to-r from-red-500 to-violet-950",
  "bg-gradient-to-r from-red-500 to-purple-300",
  "bg-gradient-to-r from-red-500 to-purple-400",
  "bg-gradient-to-r from-red-500 to-purple-500",
  "bg-gradient-to-r from-red-500 to-purple-600",
  "bg-gradient-to-r from-red-500 to-purple-700",
  "bg-gradient-to-r from-red-500 to-purple-800",
  "bg-gradient-to-r from-red-500 to-purple-900",
  "bg-gradient-to-r from-red-500 to-purple-950",
  "bg-gradient-to-r from-red-500 to-fuchsia-300",
  "bg-gradient-to-r from-red-500 to-fuchsia-400",
  "bg-gradient-to-r from-red-500 to-fuchsia-500",
  "bg-gradient-to-r from-red-500 to-fuchsia-600",
  "bg-gradient-to-r from-red-500 to-fuchsia-700",
  "bg-gradient-to-r from-red-500 to-fuchsia-800",
  "bg-gradient-to-r from-red-500 to-fuchsia-900",
  "bg-gradient-to-r from-red-500 to-fuchsia-950",
  "bg-gradient-to-r from-red-500 to-pink-300",
  "bg-gradient-to-r from-red-500 to-pink-400",
  "bg-gradient-to-r from-red-500 to-pink-500",
  "bg-gradient-to-r from-red-500 to-pink-600",
  "bg-gradient-to-r from-red-500 to-pink-700",
  "bg-gradient-to-r from-red-500 to-pink-800",
  "bg-gradient-to-r from-red-500 to-pink-900",
  "bg-gradient-to-r from-red-500 to-pink-950",
  "bg-gradient-to-r from-red-500 to-rose-300",
  "bg-gradient-to-r from-red-500 to-rose-400",
  "bg-gradient-to-r from-red-500 to-rose-500",
  "bg-gradient-to-r from-red-500 to-rose-600",
  "bg-gradient-to-r from-red-500 to-rose-700",
  "bg-gradient-to-r from-red-500 to-rose-800",
  "bg-gradient-to-r from-red-500 to-rose-900",
  "bg-gradient-to-r from-red-500 to-rose-950",
  "bg-gradient-to-r from-red-600 to-orange-300",
  "bg-gradient-to-r from-red-600 to-orange-400",
  "bg-gradient-to-r from-red-600 to-orange-500",
  "bg-gradient-to-r from-red-600 to-orange-600",
  "bg-gradient-to-r from-red-600 to-orange-700",
  "bg-gradient-to-r from-red-600 to-orange-800",
  "bg-gradient-to-r from-red-600 to-orange-900",
  "bg-gradient-to-r from-red-600 to-orange-950",
  "bg-gradient-to-r from-red-600 to-amber-300",
  "bg-gradient-to-r from-red-600 to-amber-400",
  "bg-gradient-to-r from-red-600 to-amber-500",
  "bg-gradient-to-r from-red-600 to-amber-600",
  "bg-gradient-to-r from-red-600 to-amber-700",
  "bg-gradient-to-r from-red-600 to-amber-800",
  "bg-gradient-to-r from-red-600 to-amber-900",
  "bg-gradient-to-r from-red-600 to-amber-950",
  "bg-gradient-to-r from-red-600 to-yellow-300",
  "bg-gradient-to-r from-red-600 to-yellow-400",
  "bg-gradient-to-r from-red-600 to-yellow-500",
  "bg-gradient-to-r from-red-600 to-yellow-600",
  "bg-gradient-to-r from-red-600 to-yellow-700",
  "bg-gradient-to-r from-red-600 to-yellow-800",
  "bg-gradient-to-r from-red-600 to-yellow-900",
  "bg-gradient-to-r from-red-600 to-yellow-950",
  "bg-gradient-to-r from-red-600 to-lime-300",
  "bg-gradient-to-r from-red-600 to-lime-400",
  "bg-gradient-to-r from-red-600 to-lime-500",
  "bg-gradient-to-r from-red-600 to-lime-600",
  "bg-gradient-to-r from-red-600 to-lime-700",
  "bg-gradient-to-r from-red-600 to-lime-800",
  "bg-gradient-to-r from-red-600 to-lime-900",
  "bg-gradient-to-r from-red-600 to-lime-950",
  "bg-gradient-to-r from-red-600 to-green-300",
  "bg-gradient-to-r from-red-600 to-green-400",
  "bg-gradient-to-r from-red-600 to-green-500",
  "bg-gradient-to-r from-red-600 to-green-600",
  "bg-gradient-to-r from-red-600 to-green-700",
  "bg-gradient-to-r from-red-600 to-green-800",
  "bg-gradient-to-r from-red-600 to-green-900",
  "bg-gradient-to-r from-red-600 to-green-950",
  "bg-gradient-to-r from-red-600 to-emerald-300",
  "bg-gradient-to-r from-red-600 to-emerald-400",
  "bg-gradient-to-r from-red-600 to-emerald-500",
  "bg-gradient-to-r from-red-600 to-emerald-600",
  "bg-gradient-to-r from-red-600 to-emerald-700",
  "bg-gradient-to-r from-red-600 to-emerald-800",
  "bg-gradient-to-r from-red-600 to-emerald-900",
  "bg-gradient-to-r from-red-600 to-emerald-950",
  "bg-gradient-to-r from-red-600 to-teal-300",
  "bg-gradient-to-r from-red-600 to-teal-400",
  "bg-gradient-to-r from-red-600 to-teal-500",
  "bg-gradient-to-r from-red-600 to-teal-600",
  "bg-gradient-to-r from-red-600 to-teal-700",
  "bg-gradient-to-r from-red-600 to-teal-800",
  "bg-gradient-to-r from-red-600 to-teal-900",
  "bg-gradient-to-r from-red-600 to-teal-950",
  "bg-gradient-to-r from-red-600 to-cyan-300",
  "bg-gradient-to-r from-red-600 to-cyan-400",
  "bg-gradient-to-r from-red-600 to-cyan-500",
  "bg-gradient-to-r from-red-600 to-cyan-600",
  "bg-gradient-to-r from-red-600 to-cyan-700",
  "bg-gradient-to-r from-red-600 to-cyan-800",
  "bg-gradient-to-r from-red-600 to-cyan-900",
  "bg-gradient-to-r from-red-600 to-cyan-950",
  "bg-gradient-to-r from-red-600 to-sky-300",
  "bg-gradient-to-r from-red-600 to-sky-400",
  "bg-gradient-to-r from-red-600 to-sky-500",
  "bg-gradient-to-r from-red-600 to-sky-600",
  "bg-gradient-to-r from-red-600 to-sky-700",
  "bg-gradient-to-r from-red-600 to-sky-800",
  "bg-gradient-to-r from-red-600 to-sky-900",
  "bg-gradient-to-r from-red-600 to-sky-950",
  "bg-gradient-to-r from-red-600 to-blue-300",
  "bg-gradient-to-r from-red-600 to-blue-400",
  "bg-gradient-to-r from-red-600 to-blue-500",
  "bg-gradient-to-r from-red-600 to-blue-600",
  "bg-gradient-to-r from-red-600 to-blue-700",
  "bg-gradient-to-r from-red-600 to-blue-800",
  "bg-gradient-to-r from-red-600 to-blue-900",
  "bg-gradient-to-r from-red-600 to-blue-950",
  "bg-gradient-to-r from-red-600 to-indigo-300",
  "bg-gradient-to-r from-red-600 to-indigo-400",
  "bg-gradient-to-r from-red-600 to-indigo-500",
  "bg-gradient-to-r from-red-600 to-indigo-600",
  "bg-gradient-to-r from-red-600 to-indigo-700",
  "bg-gradient-to-r from-red-600 to-indigo-800",
  "bg-gradient-to-r from-red-600 to-indigo-900",
  "bg-gradient-to-r from-red-600 to-indigo-950",
  "bg-gradient-to-r from-red-600 to-violet-300",
  "bg-gradient-to-r from-red-600 to-violet-400",
  "bg-gradient-to-r from-red-600 to-violet-500",
  "bg-gradient-to-r from-red-600 to-violet-600",
  "bg-gradient-to-r from-red-600 to-violet-700",
  "bg-gradient-to-r from-red-600 to-violet-800",
  "bg-gradient-to-r from-red-600 to-violet-900",
  "bg-gradient-to-r from-red-600 to-violet-950",
  "bg-gradient-to-r from-red-600 to-purple-300",
  "bg-gradient-to-r from-red-600 to-purple-400",
  "bg-gradient-to-r from-red-600 to-purple-500",
  "bg-gradient-to-r from-red-600 to-purple-600",
  "bg-gradient-to-r from-red-600 to-purple-700",
  "bg-gradient-to-r from-red-600 to-purple-800",
  "bg-gradient-to-r from-red-600 to-purple-900",
  "bg-gradient-to-r from-red-600 to-purple-950",
  "bg-gradient-to-r from-red-600 to-fuchsia-300",
  "bg-gradient-to-r from-red-600 to-fuchsia-400",
  "bg-gradient-to-r from-red-600 to-fuchsia-500",
  "bg-gradient-to-r from-red-600 to-fuchsia-600",
  "bg-gradient-to-r from-red-600 to-fuchsia-700",
  "bg-gradient-to-r from-red-600 to-fuchsia-800",
  "bg-gradient-to-r from-red-600 to-fuchsia-900",
  "bg-gradient-to-r from-red-600 to-fuchsia-950",
  "bg-gradient-to-r from-red-600 to-pink-300",
  "bg-gradient-to-r from-red-600 to-pink-400",
  "bg-gradient-to-r from-red-600 to-pink-500",
  "bg-gradient-to-r from-red-600 to-pink-600",
  "bg-gradient-to-r from-red-600 to-pink-700",
  "bg-gradient-to-r from-red-600 to-pink-800",
  "bg-gradient-to-r from-red-600 to-pink-900",
  "bg-gradient-to-r from-red-600 to-pink-950",
  "bg-gradient-to-r from-red-600 to-rose-300",
  "bg-gradient-to-r from-red-600 to-rose-400",
  "bg-gradient-to-r from-red-600 to-rose-500",
  "bg-gradient-to-r from-red-600 to-rose-600",
  "bg-gradient-to-r from-red-600 to-rose-700",
  "bg-gradient-to-r from-red-600 to-rose-800",
  "bg-gradient-to-r from-red-600 to-rose-900",
  "bg-gradient-to-r from-red-600 to-rose-950",
  "bg-gradient-to-r from-red-700 to-orange-300",
  "bg-gradient-to-r from-red-700 to-orange-400",
  "bg-gradient-to-r from-red-700 to-orange-500",
  "bg-gradient-to-r from-red-700 to-orange-600",
  "bg-gradient-to-r from-red-700 to-orange-700",
  "bg-gradient-to-r from-red-700 to-orange-800",
  "bg-gradient-to-r from-red-700 to-orange-900",
  "bg-gradient-to-r from-red-700 to-orange-950",
  "bg-gradient-to-r from-red-700 to-amber-300",
  "bg-gradient-to-r from-red-700 to-amber-400",
  "bg-gradient-to-r from-red-700 to-amber-500",
  "bg-gradient-to-r from-red-700 to-amber-600",
  "bg-gradient-to-r from-red-700 to-amber-700",
  "bg-gradient-to-r from-red-700 to-amber-800",
  "bg-gradient-to-r from-red-700 to-amber-900",
  "bg-gradient-to-r from-red-700 to-amber-950",
  "bg-gradient-to-r from-red-700 to-yellow-300",
  "bg-gradient-to-r from-red-700 to-yellow-400",
  "bg-gradient-to-r from-red-700 to-yellow-500",
  "bg-gradient-to-r from-red-700 to-yellow-600",
  "bg-gradient-to-r from-red-700 to-yellow-700",
  "bg-gradient-to-r from-red-700 to-yellow-800",
  "bg-gradient-to-r from-red-700 to-yellow-900",
  "bg-gradient-to-r from-red-700 to-yellow-950",
  "bg-gradient-to-r from-red-700 to-lime-300",
  "bg-gradient-to-r from-red-700 to-lime-400",
  "bg-gradient-to-r from-red-700 to-lime-500",
  "bg-gradient-to-r from-red-700 to-lime-600",
  "bg-gradient-to-r from-red-700 to-lime-700",
  "bg-gradient-to-r from-red-700 to-lime-800",
  "bg-gradient-to-r from-red-700 to-lime-900",
  "bg-gradient-to-r from-red-700 to-lime-950",
  "bg-gradient-to-r from-red-700 to-green-300",
  "bg-gradient-to-r from-red-700 to-green-400",
  "bg-gradient-to-r from-red-700 to-green-500",
  "bg-gradient-to-r from-red-700 to-green-600",
  "bg-gradient-to-r from-red-700 to-green-700",
  "bg-gradient-to-r from-red-700 to-green-800",
  "bg-gradient-to-r from-red-700 to-green-900",
  "bg-gradient-to-r from-red-700 to-green-950",
  "bg-gradient-to-r from-red-700 to-emerald-300",
  "bg-gradient-to-r from-red-700 to-emerald-400",
  "bg-gradient-to-r from-red-700 to-emerald-500",
  "bg-gradient-to-r from-red-700 to-emerald-600",
  "bg-gradient-to-r from-red-700 to-emerald-700",
  "bg-gradient-to-r from-red-700 to-emerald-800",
  "bg-gradient-to-r from-red-700 to-emerald-900",
  "bg-gradient-to-r from-red-700 to-emerald-950",
  "bg-gradient-to-r from-red-700 to-teal-300",
  "bg-gradient-to-r from-red-700 to-teal-400",
  "bg-gradient-to-r from-red-700 to-teal-500",
  "bg-gradient-to-r from-red-700 to-teal-600",
  "bg-gradient-to-r from-red-700 to-teal-700",
  "bg-gradient-to-r from-red-700 to-teal-800",
  "bg-gradient-to-r from-red-700 to-teal-900",
  "bg-gradient-to-r from-red-700 to-teal-950",
  "bg-gradient-to-r from-red-700 to-cyan-300",
  "bg-gradient-to-r from-red-700 to-cyan-400",
  "bg-gradient-to-r from-red-700 to-cyan-500",
  "bg-gradient-to-r from-red-700 to-cyan-600",
  "bg-gradient-to-r from-red-700 to-cyan-700",
  "bg-gradient-to-r from-red-700 to-cyan-800",
  "bg-gradient-to-r from-red-700 to-cyan-900",
  "bg-gradient-to-r from-red-700 to-cyan-950",
  "bg-gradient-to-r from-red-700 to-sky-300",
  "bg-gradient-to-r from-red-700 to-sky-400",
  "bg-gradient-to-r from-red-700 to-sky-500",
  "bg-gradient-to-r from-red-700 to-sky-600",
  "bg-gradient-to-r from-red-700 to-sky-700",
  "bg-gradient-to-r from-red-700 to-sky-800",
  "bg-gradient-to-r from-red-700 to-sky-900",
  "bg-gradient-to-r from-red-700 to-sky-950",
  "bg-gradient-to-r from-red-700 to-blue-300",
  "bg-gradient-to-r from-red-700 to-blue-400",
  "bg-gradient-to-r from-red-700 to-blue-500",
  "bg-gradient-to-r from-red-700 to-blue-600",
  "bg-gradient-to-r from-red-700 to-blue-700",
  "bg-gradient-to-r from-red-700 to-blue-800",
  "bg-gradient-to-r from-red-700 to-blue-900",
  "bg-gradient-to-r from-red-700 to-blue-950",
  "bg-gradient-to-r from-red-700 to-indigo-300",
  "bg-gradient-to-r from-red-700 to-indigo-400",
  "bg-gradient-to-r from-red-700 to-indigo-500",
  "bg-gradient-to-r from-red-700 to-indigo-600",
  "bg-gradient-to-r from-red-700 to-indigo-700",
  "bg-gradient-to-r from-red-700 to-indigo-800",
  "bg-gradient-to-r from-red-700 to-indigo-900",
  "bg-gradient-to-r from-red-700 to-indigo-950",
  "bg-gradient-to-r from-red-700 to-violet-300",
  "bg-gradient-to-r from-red-700 to-violet-400",
  "bg-gradient-to-r from-red-700 to-violet-500",
  "bg-gradient-to-r from-red-700 to-violet-600",
  "bg-gradient-to-r from-red-700 to-violet-700",
  "bg-gradient-to-r from-red-700 to-violet-800",
  "bg-gradient-to-r from-red-700 to-violet-900",
  "bg-gradient-to-r from-red-700 to-violet-950",
  "bg-gradient-to-r from-red-700 to-purple-300",
  "bg-gradient-to-r from-red-700 to-purple-400",
  "bg-gradient-to-r from-red-700 to-purple-500",
  "bg-gradient-to-r from-red-700 to-purple-600",
  "bg-gradient-to-r from-red-700 to-purple-700",
  "bg-gradient-to-r from-red-700 to-purple-800",
  "bg-gradient-to-r from-red-700 to-purple-900",
  "bg-gradient-to-r from-red-700 to-purple-950",
  "bg-gradient-to-r from-red-700 to-fuchsia-300",
  "bg-gradient-to-r from-red-700 to-fuchsia-400",
  "bg-gradient-to-r from-red-700 to-fuchsia-500",
  "bg-gradient-to-r from-red-700 to-fuchsia-600",
  "bg-gradient-to-r from-red-700 to-fuchsia-700",
  "bg-gradient-to-r from-red-700 to-fuchsia-800",
  "bg-gradient-to-r from-red-700 to-fuchsia-900",
  "bg-gradient-to-r from-red-700 to-fuchsia-950",
  "bg-gradient-to-r from-red-700 to-pink-300",
  "bg-gradient-to-r from-red-700 to-pink-400",
  "bg-gradient-to-r from-red-700 to-pink-500",
  "bg-gradient-to-r from-red-700 to-pink-600",
  "bg-gradient-to-r from-red-700 to-pink-700",
  "bg-gradient-to-r from-red-700 to-pink-800",
  "bg-gradient-to-r from-red-700 to-pink-900",
  "bg-gradient-to-r from-red-700 to-pink-950",
  "bg-gradient-to-r from-red-700 to-rose-300",
  "bg-gradient-to-r from-red-700 to-rose-400",
  "bg-gradient-to-r from-red-700 to-rose-500",
  "bg-gradient-to-r from-red-700 to-rose-600",
  "bg-gradient-to-r from-red-700 to-rose-700",
  "bg-gradient-to-r from-red-700 to-rose-800",
  "bg-gradient-to-r from-red-700 to-rose-900",
  "bg-gradient-to-r from-red-700 to-rose-950",
  "bg-gradient-to-r from-red-800 to-orange-300",
  "bg-gradient-to-r from-red-800 to-orange-400",
  "bg-gradient-to-r from-red-800 to-orange-500",
  "bg-gradient-to-r from-red-800 to-orange-600",
  "bg-gradient-to-r from-red-800 to-orange-700",
  "bg-gradient-to-r from-red-800 to-orange-800",
  "bg-gradient-to-r from-red-800 to-orange-900",
  "bg-gradient-to-r from-red-800 to-orange-950",
  "bg-gradient-to-r from-red-800 to-amber-300",
  "bg-gradient-to-r from-red-800 to-amber-400",
  "bg-gradient-to-r from-red-800 to-amber-500",
  "bg-gradient-to-r from-red-800 to-amber-600",
  "bg-gradient-to-r from-red-800 to-amber-700",
  "bg-gradient-to-r from-red-800 to-amber-800",
  "bg-gradient-to-r from-red-800 to-amber-900",
  "bg-gradient-to-r from-red-800 to-amber-950",
  "bg-gradient-to-r from-red-800 to-yellow-300",
  "bg-gradient-to-r from-red-800 to-yellow-400",
  "bg-gradient-to-r from-red-800 to-yellow-500",
  "bg-gradient-to-r from-red-800 to-yellow-600",
  "bg-gradient-to-r from-red-800 to-yellow-700",
  "bg-gradient-to-r from-red-800 to-yellow-800",
  "bg-gradient-to-r from-red-800 to-yellow-900",
  "bg-gradient-to-r from-red-800 to-yellow-950",
  "bg-gradient-to-r from-red-800 to-lime-300",
  "bg-gradient-to-r from-red-800 to-lime-400",
  "bg-gradient-to-r from-red-800 to-lime-500",
  "bg-gradient-to-r from-red-800 to-lime-600",
  "bg-gradient-to-r from-red-800 to-lime-700",
  "bg-gradient-to-r from-red-800 to-lime-800",
  "bg-gradient-to-r from-red-800 to-lime-900",
  "bg-gradient-to-r from-red-800 to-lime-950",
  "bg-gradient-to-r from-red-800 to-green-300",
  "bg-gradient-to-r from-red-800 to-green-400",
  "bg-gradient-to-r from-red-800 to-green-500",
  "bg-gradient-to-r from-red-800 to-green-600",
  "bg-gradient-to-r from-red-800 to-green-700",
  "bg-gradient-to-r from-red-800 to-green-800",
  "bg-gradient-to-r from-red-800 to-green-900",
  "bg-gradient-to-r from-red-800 to-green-950",
  "bg-gradient-to-r from-red-800 to-emerald-300",
  "bg-gradient-to-r from-red-800 to-emerald-400",
  "bg-gradient-to-r from-red-800 to-emerald-500",
  "bg-gradient-to-r from-red-800 to-emerald-600",
  "bg-gradient-to-r from-red-800 to-emerald-700",
  "bg-gradient-to-r from-red-800 to-emerald-800",
  "bg-gradient-to-r from-red-800 to-emerald-900",
  "bg-gradient-to-r from-red-800 to-emerald-950",
  "bg-gradient-to-r from-red-800 to-teal-300",
  "bg-gradient-to-r from-red-800 to-teal-400",
  "bg-gradient-to-r from-red-800 to-teal-500",
  "bg-gradient-to-r from-red-800 to-teal-600",
  "bg-gradient-to-r from-red-800 to-teal-700",
  "bg-gradient-to-r from-red-800 to-teal-800",
  "bg-gradient-to-r from-red-800 to-teal-900",
  "bg-gradient-to-r from-red-800 to-teal-950",
  "bg-gradient-to-r from-red-800 to-cyan-300",
  "bg-gradient-to-r from-red-800 to-cyan-400",
  "bg-gradient-to-r from-red-800 to-cyan-500",
  "bg-gradient-to-r from-red-800 to-cyan-600",
  "bg-gradient-to-r from-red-800 to-cyan-700",
  "bg-gradient-to-r from-red-800 to-cyan-800",
  "bg-gradient-to-r from-red-800 to-cyan-900",
  "bg-gradient-to-r from-red-800 to-cyan-950",
  "bg-gradient-to-r from-red-800 to-sky-300",
  "bg-gradient-to-r from-red-800 to-sky-400",
  "bg-gradient-to-r from-red-800 to-sky-500",
  "bg-gradient-to-r from-red-800 to-sky-600",
  "bg-gradient-to-r from-red-800 to-sky-700",
  "bg-gradient-to-r from-red-800 to-sky-800",
  "bg-gradient-to-r from-red-800 to-sky-900",
  "bg-gradient-to-r from-red-800 to-sky-950",
  "bg-gradient-to-r from-red-800 to-blue-300",
  "bg-gradient-to-r from-red-800 to-blue-400",
  "bg-gradient-to-r from-red-800 to-blue-500",
  "bg-gradient-to-r from-red-800 to-blue-600",
  "bg-gradient-to-r from-red-800 to-blue-700",
  "bg-gradient-to-r from-red-800 to-blue-800",
  "bg-gradient-to-r from-red-800 to-blue-900",
  "bg-gradient-to-r from-red-800 to-blue-950",
  "bg-gradient-to-r from-red-800 to-indigo-300",
  "bg-gradient-to-r from-red-800 to-indigo-400",
  "bg-gradient-to-r from-red-800 to-indigo-500",
  "bg-gradient-to-r from-red-800 to-indigo-600",
  "bg-gradient-to-r from-red-800 to-indigo-700",
  "bg-gradient-to-r from-red-800 to-indigo-800",
  "bg-gradient-to-r from-red-800 to-indigo-900",
  "bg-gradient-to-r from-red-800 to-indigo-950",
  "bg-gradient-to-r from-red-800 to-violet-300",
  "bg-gradient-to-r from-red-800 to-violet-400",
  "bg-gradient-to-r from-red-800 to-violet-500",
  "bg-gradient-to-r from-red-800 to-violet-600",
  "bg-gradient-to-r from-red-800 to-violet-700",
  "bg-gradient-to-r from-red-800 to-violet-800",
  "bg-gradient-to-r from-red-800 to-violet-900",
  "bg-gradient-to-r from-red-800 to-violet-950",
  "bg-gradient-to-r from-red-800 to-purple-300",
  "bg-gradient-to-r from-red-800 to-purple-400",
  "bg-gradient-to-r from-red-800 to-purple-500",
  "bg-gradient-to-r from-red-800 to-purple-600",
  "bg-gradient-to-r from-red-800 to-purple-700",
  "bg-gradient-to-r from-red-800 to-purple-800",
  "bg-gradient-to-r from-red-800 to-purple-900",
  "bg-gradient-to-r from-red-800 to-purple-950",
  "bg-gradient-to-r from-red-800 to-fuchsia-300",
  "bg-gradient-to-r from-red-800 to-fuchsia-400",
  "bg-gradient-to-r from-red-800 to-fuchsia-500",
  "bg-gradient-to-r from-red-800 to-fuchsia-600",
  "bg-gradient-to-r from-red-800 to-fuchsia-700",
  "bg-gradient-to-r from-red-800 to-fuchsia-800",
  "bg-gradient-to-r from-red-800 to-fuchsia-900",
  "bg-gradient-to-r from-red-800 to-fuchsia-950",
  "bg-gradient-to-r from-red-800 to-pink-300",
  "bg-gradient-to-r from-red-800 to-pink-400",
  "bg-gradient-to-r from-red-800 to-pink-500",
  "bg-gradient-to-r from-red-800 to-pink-600",
  "bg-gradient-to-r from-red-800 to-pink-700",
  "bg-gradient-to-r from-red-800 to-pink-800",
  "bg-gradient-to-r from-red-800 to-pink-900",
  "bg-gradient-to-r from-red-800 to-pink-950",
  "bg-gradient-to-r from-red-800 to-rose-300",
  "bg-gradient-to-r from-red-800 to-rose-400",
  "bg-gradient-to-r from-red-800 to-rose-500",
  "bg-gradient-to-r from-red-800 to-rose-600",
  "bg-gradient-to-r from-red-800 to-rose-700",
  "bg-gradient-to-r from-red-800 to-rose-800",
  "bg-gradient-to-r from-red-800 to-rose-900",
  "bg-gradient-to-r from-red-800 to-rose-950",
  "bg-gradient-to-r from-red-900 to-orange-300",
  "bg-gradient-to-r from-red-900 to-orange-400",
  "bg-gradient-to-r from-red-900 to-orange-500",
  "bg-gradient-to-r from-red-900 to-orange-600",
  "bg-gradient-to-r from-red-900 to-orange-700",
  "bg-gradient-to-r from-red-900 to-orange-800",
  "bg-gradient-to-r from-red-900 to-orange-900",
  "bg-gradient-to-r from-red-900 to-orange-950",
  "bg-gradient-to-r from-red-900 to-amber-300",
  "bg-gradient-to-r from-red-900 to-amber-400",
  "bg-gradient-to-r from-red-900 to-amber-500",
  "bg-gradient-to-r from-red-900 to-amber-600",
  "bg-gradient-to-r from-red-900 to-amber-700",
  "bg-gradient-to-r from-red-900 to-amber-800",
  "bg-gradient-to-r from-red-900 to-amber-900",
  "bg-gradient-to-r from-red-900 to-amber-950",
  "bg-gradient-to-r from-red-900 to-yellow-300",
  "bg-gradient-to-r from-red-900 to-yellow-400",
  "bg-gradient-to-r from-red-900 to-yellow-500",
  "bg-gradient-to-r from-red-900 to-yellow-600",
  "bg-gradient-to-r from-red-900 to-yellow-700",
  "bg-gradient-to-r from-red-900 to-yellow-800",
  "bg-gradient-to-r from-red-900 to-yellow-900",
  "bg-gradient-to-r from-red-900 to-yellow-950",
  "bg-gradient-to-r from-red-900 to-lime-300",
  "bg-gradient-to-r from-red-900 to-lime-400",
  "bg-gradient-to-r from-red-900 to-lime-500",
  "bg-gradient-to-r from-red-900 to-lime-600",
  "bg-gradient-to-r from-red-900 to-lime-700",
  "bg-gradient-to-r from-red-900 to-lime-800",
  "bg-gradient-to-r from-red-900 to-lime-900",
  "bg-gradient-to-r from-red-900 to-lime-950",
  "bg-gradient-to-r from-red-900 to-green-300",
  "bg-gradient-to-r from-red-900 to-green-400",
  "bg-gradient-to-r from-red-900 to-green-500",
  "bg-gradient-to-r from-red-900 to-green-600",
  "bg-gradient-to-r from-red-900 to-green-700",
  "bg-gradient-to-r from-red-900 to-green-800",
  "bg-gradient-to-r from-red-900 to-green-900",
  "bg-gradient-to-r from-red-900 to-green-950",
  "bg-gradient-to-r from-red-900 to-emerald-300",
  "bg-gradient-to-r from-red-900 to-emerald-400",
  "bg-gradient-to-r from-red-900 to-emerald-500",
  "bg-gradient-to-r from-red-900 to-emerald-600",
  "bg-gradient-to-r from-red-900 to-emerald-700",
  "bg-gradient-to-r from-red-900 to-emerald-800",
  "bg-gradient-to-r from-red-900 to-emerald-900",
  "bg-gradient-to-r from-red-900 to-emerald-950",
  "bg-gradient-to-r from-red-900 to-teal-300",
  "bg-gradient-to-r from-red-900 to-teal-400",
  "bg-gradient-to-r from-red-900 to-teal-500",
  "bg-gradient-to-r from-red-900 to-teal-600",
  "bg-gradient-to-r from-red-900 to-teal-700",
  "bg-gradient-to-r from-red-900 to-teal-800",
  "bg-gradient-to-r from-red-900 to-teal-900",
  "bg-gradient-to-r from-red-900 to-teal-950",
  "bg-gradient-to-r from-red-900 to-cyan-300",
  "bg-gradient-to-r from-red-900 to-cyan-400",
  "bg-gradient-to-r from-red-900 to-cyan-500",
  "bg-gradient-to-r from-red-900 to-cyan-600",
  "bg-gradient-to-r from-red-900 to-cyan-700",
  "bg-gradient-to-r from-red-900 to-cyan-800",
  "bg-gradient-to-r from-red-900 to-cyan-900",
  "bg-gradient-to-r from-red-900 to-cyan-950",
  "bg-gradient-to-r from-red-900 to-sky-300",
  "bg-gradient-to-r from-red-900 to-sky-400",
  "bg-gradient-to-r from-red-900 to-sky-500",
  "bg-gradient-to-r from-red-900 to-sky-600",
  "bg-gradient-to-r from-red-900 to-sky-700",
  "bg-gradient-to-r from-red-900 to-sky-800",
  "bg-gradient-to-r from-red-900 to-sky-900",
  "bg-gradient-to-r from-red-900 to-sky-950",
  "bg-gradient-to-r from-red-900 to-blue-300",
  "bg-gradient-to-r from-red-900 to-blue-400",
  "bg-gradient-to-r from-red-900 to-blue-500",
  "bg-gradient-to-r from-red-900 to-blue-600",
  "bg-gradient-to-r from-red-900 to-blue-700",
  "bg-gradient-to-r from-red-900 to-blue-800",
  "bg-gradient-to-r from-red-900 to-blue-900",
  "bg-gradient-to-r from-red-900 to-blue-950",
  "bg-gradient-to-r from-red-900 to-indigo-300",
  "bg-gradient-to-r from-red-900 to-indigo-400",
  "bg-gradient-to-r from-red-900 to-indigo-500",
  "bg-gradient-to-r from-red-900 to-indigo-600",
  "bg-gradient-to-r from-red-900 to-indigo-700",
  "bg-gradient-to-r from-red-900 to-indigo-800",
  "bg-gradient-to-r from-red-900 to-indigo-900",
  "bg-gradient-to-r from-red-900 to-indigo-950",
  "bg-gradient-to-r from-red-900 to-violet-300",
  "bg-gradient-to-r from-red-900 to-violet-400",
  "bg-gradient-to-r from-red-900 to-violet-500",
  "bg-gradient-to-r from-red-900 to-violet-600",
  "bg-gradient-to-r from-red-900 to-violet-700",
  "bg-gradient-to-r from-red-900 to-violet-800",
  "bg-gradient-to-r from-red-900 to-violet-900",
  "bg-gradient-to-r from-red-900 to-violet-950",
  "bg-gradient-to-r from-red-900 to-purple-300",
  "bg-gradient-to-r from-red-900 to-purple-400",
  "bg-gradient-to-r from-red-900 to-purple-500",
  "bg-gradient-to-r from-red-900 to-purple-600",
  "bg-gradient-to-r from-red-900 to-purple-700",
  "bg-gradient-to-r from-red-900 to-purple-800",
  "bg-gradient-to-r from-red-900 to-purple-900",
  "bg-gradient-to-r from-red-900 to-purple-950",
  "bg-gradient-to-r from-red-900 to-fuchsia-300",
  "bg-gradient-to-r from-red-900 to-fuchsia-400",
  "bg-gradient-to-r from-red-900 to-fuchsia-500",
  "bg-gradient-to-r from-red-900 to-fuchsia-600",
  "bg-gradient-to-r from-red-900 to-fuchsia-700",
  "bg-gradient-to-r from-red-900 to-fuchsia-800",
  "bg-gradient-to-r from-red-900 to-fuchsia-900",
  "bg-gradient-to-r from-red-900 to-fuchsia-950",
  "bg-gradient-to-r from-red-900 to-pink-300",
  "bg-gradient-to-r from-red-900 to-pink-400",
  "bg-gradient-to-r from-red-900 to-pink-500",
  "bg-gradient-to-r from-red-900 to-pink-600",
  "bg-gradient-to-r from-red-900 to-pink-700",
  "bg-gradient-to-r from-red-900 to-pink-800",
  "bg-gradient-to-r from-red-900 to-pink-900",
  "bg-gradient-to-r from-red-900 to-pink-950",
  "bg-gradient-to-r from-red-900 to-rose-300",
  "bg-gradient-to-r from-red-900 to-rose-400",
  "bg-gradient-to-r from-red-900 to-rose-500",
  "bg-gradient-to-r from-red-900 to-rose-600",
  "bg-gradient-to-r from-red-900 to-rose-700",
  "bg-gradient-to-r from-red-900 to-rose-800",
  "bg-gradient-to-r from-red-900 to-rose-900",
  "bg-gradient-to-r from-red-900 to-rose-950",
  "bg-gradient-to-r from-red-950 to-orange-300",
  "bg-gradient-to-r from-red-950 to-orange-400",
  "bg-gradient-to-r from-red-950 to-orange-500",
  "bg-gradient-to-r from-red-950 to-orange-600",
  "bg-gradient-to-r from-red-950 to-orange-700",
  "bg-gradient-to-r from-red-950 to-orange-800",
  "bg-gradient-to-r from-red-950 to-orange-900",
  "bg-gradient-to-r from-red-950 to-orange-950",
  "bg-gradient-to-r from-red-950 to-amber-300",
  "bg-gradient-to-r from-red-950 to-amber-400",
  "bg-gradient-to-r from-red-950 to-amber-500",
  "bg-gradient-to-r from-red-950 to-amber-600",
  "bg-gradient-to-r from-red-950 to-amber-700",
  "bg-gradient-to-r from-red-950 to-amber-800",
  "bg-gradient-to-r from-red-950 to-amber-900",
  "bg-gradient-to-r from-red-950 to-amber-950",
  "bg-gradient-to-r from-red-950 to-yellow-300",
  "bg-gradient-to-r from-red-950 to-yellow-400",
  "bg-gradient-to-r from-red-950 to-yellow-500",
  "bg-gradient-to-r from-red-950 to-yellow-600",
  "bg-gradient-to-r from-red-950 to-yellow-700",
  "bg-gradient-to-r from-red-950 to-yellow-800",
  "bg-gradient-to-r from-red-950 to-yellow-900",
  "bg-gradient-to-r from-red-950 to-yellow-950",
  "bg-gradient-to-r from-red-950 to-lime-300",
  "bg-gradient-to-r from-red-950 to-lime-400",
  "bg-gradient-to-r from-red-950 to-lime-500",
  "bg-gradient-to-r from-red-950 to-lime-600",
  "bg-gradient-to-r from-red-950 to-lime-700",
  "bg-gradient-to-r from-red-950 to-lime-800",
  "bg-gradient-to-r from-red-950 to-lime-900",
  "bg-gradient-to-r from-red-950 to-lime-950",
  "bg-gradient-to-r from-red-950 to-green-300",
  "bg-gradient-to-r from-red-950 to-green-400",
  "bg-gradient-to-r from-red-950 to-green-500",
  "bg-gradient-to-r from-red-950 to-green-600",
  "bg-gradient-to-r from-red-950 to-green-700",
  "bg-gradient-to-r from-red-950 to-green-800",
  "bg-gradient-to-r from-red-950 to-green-900",
  "bg-gradient-to-r from-red-950 to-green-950",
  "bg-gradient-to-r from-red-950 to-emerald-300",
  "bg-gradient-to-r from-red-950 to-emerald-400",
  "bg-gradient-to-r from-red-950 to-emerald-500",
  "bg-gradient-to-r from-red-950 to-emerald-600",
  "bg-gradient-to-r from-red-950 to-emerald-700",
  "bg-gradient-to-r from-red-950 to-emerald-800",
  "bg-gradient-to-r from-red-950 to-emerald-900",
  "bg-gradient-to-r from-red-950 to-emerald-950",
  "bg-gradient-to-r from-red-950 to-teal-300",
  "bg-gradient-to-r from-red-950 to-teal-400",
  "bg-gradient-to-r from-red-950 to-teal-500",
  "bg-gradient-to-r from-red-950 to-teal-600",
  "bg-gradient-to-r from-red-950 to-teal-700",
  "bg-gradient-to-r from-red-950 to-teal-800",
  "bg-gradient-to-r from-red-950 to-teal-900",
  "bg-gradient-to-r from-red-950 to-teal-950",
  "bg-gradient-to-r from-red-950 to-cyan-300",
  "bg-gradient-to-r from-red-950 to-cyan-400",
  "bg-gradient-to-r from-red-950 to-cyan-500",
  "bg-gradient-to-r from-red-950 to-cyan-600",
  "bg-gradient-to-r from-red-950 to-cyan-700",
  "bg-gradient-to-r from-red-950 to-cyan-800",
  "bg-gradient-to-r from-red-950 to-cyan-900",
  "bg-gradient-to-r from-red-950 to-cyan-950",
  "bg-gradient-to-r from-red-950 to-sky-300",
  "bg-gradient-to-r from-red-950 to-sky-400",
  "bg-gradient-to-r from-red-950 to-sky-500",
  "bg-gradient-to-r from-red-950 to-sky-600",
  "bg-gradient-to-r from-red-950 to-sky-700",
  "bg-gradient-to-r from-red-950 to-sky-800",
  "bg-gradient-to-r from-red-950 to-sky-900",
  "bg-gradient-to-r from-red-950 to-sky-950",
  "bg-gradient-to-r from-red-950 to-blue-300",
  "bg-gradient-to-r from-red-950 to-blue-400",
  "bg-gradient-to-r from-red-950 to-blue-500",
  "bg-gradient-to-r from-red-950 to-blue-600",
  "bg-gradient-to-r from-red-950 to-blue-700",
  "bg-gradient-to-r from-red-950 to-blue-800",
  "bg-gradient-to-r from-red-950 to-blue-900",
  "bg-gradient-to-r from-red-950 to-blue-950",
  "bg-gradient-to-r from-red-950 to-indigo-300",
  "bg-gradient-to-r from-red-950 to-indigo-400",
  "bg-gradient-to-r from-red-950 to-indigo-500",
  "bg-gradient-to-r from-red-950 to-indigo-600",
  "bg-gradient-to-r from-red-950 to-indigo-700",
  "bg-gradient-to-r from-red-950 to-indigo-800",
  "bg-gradient-to-r from-red-950 to-indigo-900",
  "bg-gradient-to-r from-red-950 to-indigo-950",
  "bg-gradient-to-r from-red-950 to-violet-300",
  "bg-gradient-to-r from-red-950 to-violet-400",
  "bg-gradient-to-r from-red-950 to-violet-500",
  "bg-gradient-to-r from-red-950 to-violet-600",
  "bg-gradient-to-r from-red-950 to-violet-700",
  "bg-gradient-to-r from-red-950 to-violet-800",
  "bg-gradient-to-r from-red-950 to-violet-900",
  "bg-gradient-to-r from-red-950 to-violet-950",
  "bg-gradient-to-r from-red-950 to-purple-300",
  "bg-gradient-to-r from-red-950 to-purple-400",
  "bg-gradient-to-r from-red-950 to-purple-500",
  "bg-gradient-to-r from-red-950 to-purple-600",
  "bg-gradient-to-r from-red-950 to-purple-700",
  "bg-gradient-to-r from-red-950 to-purple-800",
  "bg-gradient-to-r from-red-950 to-purple-900",
  "bg-gradient-to-r from-red-950 to-purple-950",
  "bg-gradient-to-r from-red-950 to-fuchsia-300",
  "bg-gradient-to-r from-red-950 to-fuchsia-400",
  "bg-gradient-to-r from-red-950 to-fuchsia-500",
  "bg-gradient-to-r from-red-950 to-fuchsia-600",
  "bg-gradient-to-r from-red-950 to-fuchsia-700",
  "bg-gradient-to-r from-red-950 to-fuchsia-800",
  "bg-gradient-to-r from-red-950 to-fuchsia-900",
  "bg-gradient-to-r from-red-950 to-fuchsia-950",
  "bg-gradient-to-r from-red-950 to-pink-300",
  "bg-gradient-to-r from-red-950 to-pink-400",
  "bg-gradient-to-r from-red-950 to-pink-500",
  "bg-gradient-to-r from-red-950 to-pink-600",
  "bg-gradient-to-r from-red-950 to-pink-700",
  "bg-gradient-to-r from-red-950 to-pink-800",
  "bg-gradient-to-r from-red-950 to-pink-900",
  "bg-gradient-to-r from-red-950 to-pink-950",
  "bg-gradient-to-r from-red-950 to-rose-300",
  "bg-gradient-to-r from-red-950 to-rose-400",
  "bg-gradient-to-r from-red-950 to-rose-500",
  "bg-gradient-to-r from-red-950 to-rose-600",
  "bg-gradient-to-r from-red-950 to-rose-700",
  "bg-gradient-to-r from-red-950 to-rose-800",
  "bg-gradient-to-r from-red-950 to-rose-900",
  "bg-gradient-to-r from-red-950 to-rose-950",
  "bg-gradient-to-r from-orange-300 to-red-300",
  "bg-gradient-to-r from-orange-300 to-red-400",
  "bg-gradient-to-r from-orange-300 to-red-500",
  "bg-gradient-to-r from-orange-300 to-red-600",
  "bg-gradient-to-r from-orange-300 to-red-700",
  "bg-gradient-to-r from-orange-300 to-red-800",
  "bg-gradient-to-r from-orange-300 to-red-900",
  "bg-gradient-to-r from-orange-300 to-red-950",
  "bg-gradient-to-r from-orange-300 to-amber-300",
  "bg-gradient-to-r from-orange-300 to-amber-400",
  "bg-gradient-to-r from-orange-300 to-amber-500",
  "bg-gradient-to-r from-orange-300 to-amber-600",
  "bg-gradient-to-r from-orange-300 to-amber-700",
  "bg-gradient-to-r from-orange-300 to-amber-800",
  "bg-gradient-to-r from-orange-300 to-amber-900",
  "bg-gradient-to-r from-orange-300 to-amber-950",
  "bg-gradient-to-r from-orange-300 to-yellow-300",
  "bg-gradient-to-r from-orange-300 to-yellow-400",
  "bg-gradient-to-r from-orange-300 to-yellow-500",
  "bg-gradient-to-r from-orange-300 to-yellow-600",
  "bg-gradient-to-r from-orange-300 to-yellow-700",
  "bg-gradient-to-r from-orange-300 to-yellow-800",
  "bg-gradient-to-r from-orange-300 to-yellow-900",
  "bg-gradient-to-r from-orange-300 to-yellow-950",
  "bg-gradient-to-r from-orange-300 to-lime-300",
  "bg-gradient-to-r from-orange-300 to-lime-400",
  "bg-gradient-to-r from-orange-300 to-lime-500",
  "bg-gradient-to-r from-orange-300 to-lime-600",
  "bg-gradient-to-r from-orange-300 to-lime-700",
  "bg-gradient-to-r from-orange-300 to-lime-800",
  "bg-gradient-to-r from-orange-300 to-lime-900",
  "bg-gradient-to-r from-orange-300 to-lime-950",
  "bg-gradient-to-r from-orange-300 to-green-300",
  "bg-gradient-to-r from-orange-300 to-green-400",
  "bg-gradient-to-r from-orange-300 to-green-500",
  "bg-gradient-to-r from-orange-300 to-green-600",
  "bg-gradient-to-r from-orange-300 to-green-700",
  "bg-gradient-to-r from-orange-300 to-green-800",
  "bg-gradient-to-r from-orange-300 to-green-900",
  "bg-gradient-to-r from-orange-300 to-green-950",
  "bg-gradient-to-r from-orange-300 to-emerald-300",
  "bg-gradient-to-r from-orange-300 to-emerald-400",
  "bg-gradient-to-r from-orange-300 to-emerald-500",
  "bg-gradient-to-r from-orange-300 to-emerald-600",
  "bg-gradient-to-r from-orange-300 to-emerald-700",
  "bg-gradient-to-r from-orange-300 to-emerald-800",
  "bg-gradient-to-r from-orange-300 to-emerald-900",
  "bg-gradient-to-r from-orange-300 to-emerald-950",
  "bg-gradient-to-r from-orange-300 to-teal-300",
  "bg-gradient-to-r from-orange-300 to-teal-400",
  "bg-gradient-to-r from-orange-300 to-teal-500",
  "bg-gradient-to-r from-orange-300 to-teal-600",
  "bg-gradient-to-r from-orange-300 to-teal-700",
  "bg-gradient-to-r from-orange-300 to-teal-800",
  "bg-gradient-to-r from-orange-300 to-teal-900",
  "bg-gradient-to-r from-orange-300 to-teal-950",
  "bg-gradient-to-r from-orange-300 to-cyan-300",
  "bg-gradient-to-r from-orange-300 to-cyan-400",
  "bg-gradient-to-r from-orange-300 to-cyan-500",
  "bg-gradient-to-r from-orange-300 to-cyan-600",
  "bg-gradient-to-r from-orange-300 to-cyan-700",
  "bg-gradient-to-r from-orange-300 to-cyan-800",
  "bg-gradient-to-r from-orange-300 to-cyan-900",
  "bg-gradient-to-r from-orange-300 to-cyan-950",
  "bg-gradient-to-r from-orange-300 to-sky-300",
  "bg-gradient-to-r from-orange-300 to-sky-400",
  "bg-gradient-to-r from-orange-300 to-sky-500",
  "bg-gradient-to-r from-orange-300 to-sky-600",
  "bg-gradient-to-r from-orange-300 to-sky-700",
  "bg-gradient-to-r from-orange-300 to-sky-800",
  "bg-gradient-to-r from-orange-300 to-sky-900",
  "bg-gradient-to-r from-orange-300 to-sky-950",
  "bg-gradient-to-r from-orange-300 to-blue-300",
  "bg-gradient-to-r from-orange-300 to-blue-400",
  "bg-gradient-to-r from-orange-300 to-blue-500",
  "bg-gradient-to-r from-orange-300 to-blue-600",
  "bg-gradient-to-r from-orange-300 to-blue-700",
  "bg-gradient-to-r from-orange-300 to-blue-800",
  "bg-gradient-to-r from-orange-300 to-blue-900",
  "bg-gradient-to-r from-orange-300 to-blue-950",
  "bg-gradient-to-r from-orange-300 to-indigo-300",
  "bg-gradient-to-r from-orange-300 to-indigo-400",
  "bg-gradient-to-r from-orange-300 to-indigo-500",
  "bg-gradient-to-r from-orange-300 to-indigo-600",
  "bg-gradient-to-r from-orange-300 to-indigo-700",
  "bg-gradient-to-r from-orange-300 to-indigo-800",
  "bg-gradient-to-r from-orange-300 to-indigo-900",
  "bg-gradient-to-r from-orange-300 to-indigo-950",
  "bg-gradient-to-r from-orange-300 to-violet-300",
  "bg-gradient-to-r from-orange-300 to-violet-400",
  "bg-gradient-to-r from-orange-300 to-violet-500",
  "bg-gradient-to-r from-orange-300 to-violet-600",
  "bg-gradient-to-r from-orange-300 to-violet-700",
  "bg-gradient-to-r from-orange-300 to-violet-800",
  "bg-gradient-to-r from-orange-300 to-violet-900",
  "bg-gradient-to-r from-orange-300 to-violet-950",
  "bg-gradient-to-r from-orange-300 to-purple-300",
  "bg-gradient-to-r from-orange-300 to-purple-400",
  "bg-gradient-to-r from-orange-300 to-purple-500",
  "bg-gradient-to-r from-orange-300 to-purple-600",
  "bg-gradient-to-r from-orange-300 to-purple-700",
  "bg-gradient-to-r from-orange-300 to-purple-800",
  "bg-gradient-to-r from-orange-300 to-purple-900",
  "bg-gradient-to-r from-orange-300 to-purple-950",
  "bg-gradient-to-r from-orange-300 to-fuchsia-300",
  "bg-gradient-to-r from-orange-300 to-fuchsia-400",
  "bg-gradient-to-r from-orange-300 to-fuchsia-500",
  "bg-gradient-to-r from-orange-300 to-fuchsia-600",
  "bg-gradient-to-r from-orange-300 to-fuchsia-700",
  "bg-gradient-to-r from-orange-300 to-fuchsia-800",
  "bg-gradient-to-r from-orange-300 to-fuchsia-900",
  "bg-gradient-to-r from-orange-300 to-fuchsia-950",
  "bg-gradient-to-r from-orange-300 to-pink-300",
  "bg-gradient-to-r from-orange-300 to-pink-400",
  "bg-gradient-to-r from-orange-300 to-pink-500",
  "bg-gradient-to-r from-orange-300 to-pink-600",
  "bg-gradient-to-r from-orange-300 to-pink-700",
  "bg-gradient-to-r from-orange-300 to-pink-800",
  "bg-gradient-to-r from-orange-300 to-pink-900",
  "bg-gradient-to-r from-orange-300 to-pink-950",
  "bg-gradient-to-r from-orange-300 to-rose-300",
  "bg-gradient-to-r from-orange-300 to-rose-400",
  "bg-gradient-to-r from-orange-300 to-rose-500",
  "bg-gradient-to-r from-orange-300 to-rose-600",
  "bg-gradient-to-r from-orange-300 to-rose-700",
  "bg-gradient-to-r from-orange-300 to-rose-800",
  "bg-gradient-to-r from-orange-300 to-rose-900",
  "bg-gradient-to-r from-orange-300 to-rose-950",
  "bg-gradient-to-r from-orange-400 to-red-300",
  "bg-gradient-to-r from-orange-400 to-red-400",
  "bg-gradient-to-r from-orange-400 to-red-500",
  "bg-gradient-to-r from-orange-400 to-red-600",
  "bg-gradient-to-r from-orange-400 to-red-700",
  "bg-gradient-to-r from-orange-400 to-red-800",
  "bg-gradient-to-r from-orange-400 to-red-900",
  "bg-gradient-to-r from-orange-400 to-red-950",
  "bg-gradient-to-r from-orange-400 to-amber-300",
  "bg-gradient-to-r from-orange-400 to-amber-400",
  "bg-gradient-to-r from-orange-400 to-amber-500",
  "bg-gradient-to-r from-orange-400 to-amber-600",
  "bg-gradient-to-r from-orange-400 to-amber-700",
  "bg-gradient-to-r from-orange-400 to-amber-800",
  "bg-gradient-to-r from-orange-400 to-amber-900",
  "bg-gradient-to-r from-orange-400 to-amber-950",
  "bg-gradient-to-r from-orange-400 to-yellow-300",
  "bg-gradient-to-r from-orange-400 to-yellow-400",
  "bg-gradient-to-r from-orange-400 to-yellow-500",
  "bg-gradient-to-r from-orange-400 to-yellow-600",
  "bg-gradient-to-r from-orange-400 to-yellow-700",
  "bg-gradient-to-r from-orange-400 to-yellow-800",
  "bg-gradient-to-r from-orange-400 to-yellow-900",
  "bg-gradient-to-r from-orange-400 to-yellow-950",
  "bg-gradient-to-r from-orange-400 to-lime-300",
  "bg-gradient-to-r from-orange-400 to-lime-400",
  "bg-gradient-to-r from-orange-400 to-lime-500",
  "bg-gradient-to-r from-orange-400 to-lime-600",
  "bg-gradient-to-r from-orange-400 to-lime-700",
  "bg-gradient-to-r from-orange-400 to-lime-800",
  "bg-gradient-to-r from-orange-400 to-lime-900",
  "bg-gradient-to-r from-orange-400 to-lime-950",
  "bg-gradient-to-r from-orange-400 to-green-300",
  "bg-gradient-to-r from-orange-400 to-green-400",
  "bg-gradient-to-r from-orange-400 to-green-500",
  "bg-gradient-to-r from-orange-400 to-green-600",
  "bg-gradient-to-r from-orange-400 to-green-700",
  "bg-gradient-to-r from-orange-400 to-green-800",
  "bg-gradient-to-r from-orange-400 to-green-900",
  "bg-gradient-to-r from-orange-400 to-green-950",
  "bg-gradient-to-r from-orange-400 to-emerald-300",
  "bg-gradient-to-r from-orange-400 to-emerald-400",
  "bg-gradient-to-r from-orange-400 to-emerald-500",
  "bg-gradient-to-r from-orange-400 to-emerald-600",
  "bg-gradient-to-r from-orange-400 to-emerald-700",
  "bg-gradient-to-r from-orange-400 to-emerald-800",
  "bg-gradient-to-r from-orange-400 to-emerald-900",
  "bg-gradient-to-r from-orange-400 to-emerald-950",
  "bg-gradient-to-r from-orange-400 to-teal-300",
  "bg-gradient-to-r from-orange-400 to-teal-400",
  "bg-gradient-to-r from-orange-400 to-teal-500",
  "bg-gradient-to-r from-orange-400 to-teal-600",
  "bg-gradient-to-r from-orange-400 to-teal-700",
  "bg-gradient-to-r from-orange-400 to-teal-800",
  "bg-gradient-to-r from-orange-400 to-teal-900",
  "bg-gradient-to-r from-orange-400 to-teal-950",
  "bg-gradient-to-r from-orange-400 to-cyan-300",
  "bg-gradient-to-r from-orange-400 to-cyan-400",
  "bg-gradient-to-r from-orange-400 to-cyan-500",
  "bg-gradient-to-r from-orange-400 to-cyan-600",
  "bg-gradient-to-r from-orange-400 to-cyan-700",
  "bg-gradient-to-r from-orange-400 to-cyan-800",
  "bg-gradient-to-r from-orange-400 to-cyan-900",
  "bg-gradient-to-r from-orange-400 to-cyan-950",
  "bg-gradient-to-r from-orange-400 to-sky-300",
  "bg-gradient-to-r from-orange-400 to-sky-400",
  "bg-gradient-to-r from-orange-400 to-sky-500",
  "bg-gradient-to-r from-orange-400 to-sky-600",
  "bg-gradient-to-r from-orange-400 to-sky-700",
  "bg-gradient-to-r from-orange-400 to-sky-800",
  "bg-gradient-to-r from-orange-400 to-sky-900",
  "bg-gradient-to-r from-orange-400 to-sky-950",
  "bg-gradient-to-r from-orange-400 to-blue-300",
  "bg-gradient-to-r from-orange-400 to-blue-400",
  "bg-gradient-to-r from-orange-400 to-blue-500",
  "bg-gradient-to-r from-orange-400 to-blue-600",
  "bg-gradient-to-r from-orange-400 to-blue-700",
  "bg-gradient-to-r from-orange-400 to-blue-800",
  "bg-gradient-to-r from-orange-400 to-blue-900",
  "bg-gradient-to-r from-orange-400 to-blue-950",
  "bg-gradient-to-r from-orange-400 to-indigo-300",
  "bg-gradient-to-r from-orange-400 to-indigo-400",
  "bg-gradient-to-r from-orange-400 to-indigo-500",
  "bg-gradient-to-r from-orange-400 to-indigo-600",
  "bg-gradient-to-r from-orange-400 to-indigo-700",
  "bg-gradient-to-r from-orange-400 to-indigo-800",
  "bg-gradient-to-r from-orange-400 to-indigo-900",
  "bg-gradient-to-r from-orange-400 to-indigo-950",
  "bg-gradient-to-r from-orange-400 to-violet-300",
  "bg-gradient-to-r from-orange-400 to-violet-400",
  "bg-gradient-to-r from-orange-400 to-violet-500",
  "bg-gradient-to-r from-orange-400 to-violet-600",
  "bg-gradient-to-r from-orange-400 to-violet-700",
  "bg-gradient-to-r from-orange-400 to-violet-800",
  "bg-gradient-to-r from-orange-400 to-violet-900",
  "bg-gradient-to-r from-orange-400 to-violet-950",
  "bg-gradient-to-r from-orange-400 to-purple-300",
  "bg-gradient-to-r from-orange-400 to-purple-400",
  "bg-gradient-to-r from-orange-400 to-purple-500",
  "bg-gradient-to-r from-orange-400 to-purple-600",
  "bg-gradient-to-r from-orange-400 to-purple-700",
  "bg-gradient-to-r from-orange-400 to-purple-800",
  "bg-gradient-to-r from-orange-400 to-purple-900",
  "bg-gradient-to-r from-orange-400 to-purple-950",
  "bg-gradient-to-r from-orange-400 to-fuchsia-300",
  "bg-gradient-to-r from-orange-400 to-fuchsia-400",
  "bg-gradient-to-r from-orange-400 to-fuchsia-500",
  "bg-gradient-to-r from-orange-400 to-fuchsia-600",
  "bg-gradient-to-r from-orange-400 to-fuchsia-700",
  "bg-gradient-to-r from-orange-400 to-fuchsia-800",
  "bg-gradient-to-r from-orange-400 to-fuchsia-900",
  "bg-gradient-to-r from-orange-400 to-fuchsia-950",
  "bg-gradient-to-r from-orange-400 to-pink-300",
  "bg-gradient-to-r from-orange-400 to-pink-400",
  "bg-gradient-to-r from-orange-400 to-pink-500",
  "bg-gradient-to-r from-orange-400 to-pink-600",
  "bg-gradient-to-r from-orange-400 to-pink-700",
  "bg-gradient-to-r from-orange-400 to-pink-800",
  "bg-gradient-to-r from-orange-400 to-pink-900",
  "bg-gradient-to-r from-orange-400 to-pink-950",
  "bg-gradient-to-r from-orange-400 to-rose-300",
  "bg-gradient-to-r from-orange-400 to-rose-400",
  "bg-gradient-to-r from-orange-400 to-rose-500",
  "bg-gradient-to-r from-orange-400 to-rose-600",
  "bg-gradient-to-r from-orange-400 to-rose-700",
  "bg-gradient-to-r from-orange-400 to-rose-800",
  "bg-gradient-to-r from-orange-400 to-rose-900",
  "bg-gradient-to-r from-orange-400 to-rose-950",
  "bg-gradient-to-r from-orange-500 to-red-300",
  "bg-gradient-to-r from-orange-500 to-red-400",
  "bg-gradient-to-r from-orange-500 to-red-500",
  "bg-gradient-to-r from-orange-500 to-red-600",
  "bg-gradient-to-r from-orange-500 to-red-700",
  "bg-gradient-to-r from-orange-500 to-red-800",
  "bg-gradient-to-r from-orange-500 to-red-900",
  "bg-gradient-to-r from-orange-500 to-red-950",
  "bg-gradient-to-r from-orange-500 to-amber-300",
  "bg-gradient-to-r from-orange-500 to-amber-400",
  "bg-gradient-to-r from-orange-500 to-amber-500",
  "bg-gradient-to-r from-orange-500 to-amber-600",
  "bg-gradient-to-r from-orange-500 to-amber-700",
  "bg-gradient-to-r from-orange-500 to-amber-800",
  "bg-gradient-to-r from-orange-500 to-amber-900",
  "bg-gradient-to-r from-orange-500 to-amber-950",
  "bg-gradient-to-r from-orange-500 to-yellow-300",
  "bg-gradient-to-r from-orange-500 to-yellow-400",
  "bg-gradient-to-r from-orange-500 to-yellow-500",
  "bg-gradient-to-r from-orange-500 to-yellow-600",
  "bg-gradient-to-r from-orange-500 to-yellow-700",
  "bg-gradient-to-r from-orange-500 to-yellow-800",
  "bg-gradient-to-r from-orange-500 to-yellow-900",
  "bg-gradient-to-r from-orange-500 to-yellow-950",
  "bg-gradient-to-r from-orange-500 to-lime-300",
  "bg-gradient-to-r from-orange-500 to-lime-400",
  "bg-gradient-to-r from-orange-500 to-lime-500",
  "bg-gradient-to-r from-orange-500 to-lime-600",
  "bg-gradient-to-r from-orange-500 to-lime-700",
  "bg-gradient-to-r from-orange-500 to-lime-800",
  "bg-gradient-to-r from-orange-500 to-lime-900",
  "bg-gradient-to-r from-orange-500 to-lime-950",
  "bg-gradient-to-r from-orange-500 to-green-300",
  "bg-gradient-to-r from-orange-500 to-green-400",
  "bg-gradient-to-r from-orange-500 to-green-500",
  "bg-gradient-to-r from-orange-500 to-green-600",
  "bg-gradient-to-r from-orange-500 to-green-700",
  "bg-gradient-to-r from-orange-500 to-green-800",
  "bg-gradient-to-r from-orange-500 to-green-900",
  "bg-gradient-to-r from-orange-500 to-green-950",
  "bg-gradient-to-r from-orange-500 to-emerald-300",
  "bg-gradient-to-r from-orange-500 to-emerald-400",
  "bg-gradient-to-r from-orange-500 to-emerald-500",
  "bg-gradient-to-r from-orange-500 to-emerald-600",
  "bg-gradient-to-r from-orange-500 to-emerald-700",
  "bg-gradient-to-r from-orange-500 to-emerald-800",
  "bg-gradient-to-r from-orange-500 to-emerald-900",
  "bg-gradient-to-r from-orange-500 to-emerald-950",
  "bg-gradient-to-r from-orange-500 to-teal-300",
  "bg-gradient-to-r from-orange-500 to-teal-400",
  "bg-gradient-to-r from-orange-500 to-teal-500",
  "bg-gradient-to-r from-orange-500 to-teal-600",
  "bg-gradient-to-r from-orange-500 to-teal-700",
  "bg-gradient-to-r from-orange-500 to-teal-800",
  "bg-gradient-to-r from-orange-500 to-teal-900",
  "bg-gradient-to-r from-orange-500 to-teal-950",
  "bg-gradient-to-r from-orange-500 to-cyan-300",
  "bg-gradient-to-r from-orange-500 to-cyan-400",
  "bg-gradient-to-r from-orange-500 to-cyan-500",
  "bg-gradient-to-r from-orange-500 to-cyan-600",
  "bg-gradient-to-r from-orange-500 to-cyan-700",
  "bg-gradient-to-r from-orange-500 to-cyan-800",
  "bg-gradient-to-r from-orange-500 to-cyan-900",
  "bg-gradient-to-r from-orange-500 to-cyan-950",
  "bg-gradient-to-r from-orange-500 to-sky-300",
  "bg-gradient-to-r from-orange-500 to-sky-400",
  "bg-gradient-to-r from-orange-500 to-sky-500",
  "bg-gradient-to-r from-orange-500 to-sky-600",
  "bg-gradient-to-r from-orange-500 to-sky-700",
  "bg-gradient-to-r from-orange-500 to-sky-800",
  "bg-gradient-to-r from-orange-500 to-sky-900",
  "bg-gradient-to-r from-orange-500 to-sky-950",
  "bg-gradient-to-r from-orange-500 to-blue-300",
  "bg-gradient-to-r from-orange-500 to-blue-400",
  "bg-gradient-to-r from-orange-500 to-blue-500",
  "bg-gradient-to-r from-orange-500 to-blue-600",
  "bg-gradient-to-r from-orange-500 to-blue-700",
  "bg-gradient-to-r from-orange-500 to-blue-800",
  "bg-gradient-to-r from-orange-500 to-blue-900",
  "bg-gradient-to-r from-orange-500 to-blue-950",
  "bg-gradient-to-r from-orange-500 to-indigo-300",
  "bg-gradient-to-r from-orange-500 to-indigo-400",
  "bg-gradient-to-r from-orange-500 to-indigo-500",
  "bg-gradient-to-r from-orange-500 to-indigo-600",
  "bg-gradient-to-r from-orange-500 to-indigo-700",
  "bg-gradient-to-r from-orange-500 to-indigo-800",
  "bg-gradient-to-r from-orange-500 to-indigo-900",
  "bg-gradient-to-r from-orange-500 to-indigo-950",
  "bg-gradient-to-r from-orange-500 to-violet-300",
  "bg-gradient-to-r from-orange-500 to-violet-400",
  "bg-gradient-to-r from-orange-500 to-violet-500",
  "bg-gradient-to-r from-orange-500 to-violet-600",
  "bg-gradient-to-r from-orange-500 to-violet-700",
  "bg-gradient-to-r from-orange-500 to-violet-800",
  "bg-gradient-to-r from-orange-500 to-violet-900",
  "bg-gradient-to-r from-orange-500 to-violet-950",
  "bg-gradient-to-r from-orange-500 to-purple-300",
  "bg-gradient-to-r from-orange-500 to-purple-400",
  "bg-gradient-to-r from-orange-500 to-purple-500",
  "bg-gradient-to-r from-orange-500 to-purple-600",
  "bg-gradient-to-r from-orange-500 to-purple-700",
  "bg-gradient-to-r from-orange-500 to-purple-800",
  "bg-gradient-to-r from-orange-500 to-purple-900",
  "bg-gradient-to-r from-orange-500 to-purple-950",
  "bg-gradient-to-r from-orange-500 to-fuchsia-300",
  "bg-gradient-to-r from-orange-500 to-fuchsia-400",
  "bg-gradient-to-r from-orange-500 to-fuchsia-500",
  "bg-gradient-to-r from-orange-500 to-fuchsia-600",
  "bg-gradient-to-r from-orange-500 to-fuchsia-700",
  "bg-gradient-to-r from-orange-500 to-fuchsia-800",
  "bg-gradient-to-r from-orange-500 to-fuchsia-900",
  "bg-gradient-to-r from-orange-500 to-fuchsia-950",
  "bg-gradient-to-r from-orange-500 to-pink-300",
  "bg-gradient-to-r from-orange-500 to-pink-400",
  "bg-gradient-to-r from-orange-500 to-pink-500",
  "bg-gradient-to-r from-orange-500 to-pink-600",
  "bg-gradient-to-r from-orange-500 to-pink-700",
  "bg-gradient-to-r from-orange-500 to-pink-800",
  "bg-gradient-to-r from-orange-500 to-pink-900",
  "bg-gradient-to-r from-orange-500 to-pink-950",
  "bg-gradient-to-r from-orange-500 to-rose-300",
  "bg-gradient-to-r from-orange-500 to-rose-400",
  "bg-gradient-to-r from-orange-500 to-rose-500",
  "bg-gradient-to-r from-orange-500 to-rose-600",
  "bg-gradient-to-r from-orange-500 to-rose-700",
  "bg-gradient-to-r from-orange-500 to-rose-800",
  "bg-gradient-to-r from-orange-500 to-rose-900",
  "bg-gradient-to-r from-orange-500 to-rose-950",
  "bg-gradient-to-r from-orange-600 to-red-300",
  "bg-gradient-to-r from-orange-600 to-red-400",
  "bg-gradient-to-r from-orange-600 to-red-500",
  "bg-gradient-to-r from-orange-600 to-red-600",
  "bg-gradient-to-r from-orange-600 to-red-700",
  "bg-gradient-to-r from-orange-600 to-red-800",
  "bg-gradient-to-r from-orange-600 to-red-900",
  "bg-gradient-to-r from-orange-600 to-red-950",
  "bg-gradient-to-r from-orange-600 to-amber-300",
  "bg-gradient-to-r from-orange-600 to-amber-400",
  "bg-gradient-to-r from-orange-600 to-amber-500",
  "bg-gradient-to-r from-orange-600 to-amber-600",
  "bg-gradient-to-r from-orange-600 to-amber-700",
  "bg-gradient-to-r from-orange-600 to-amber-800",
  "bg-gradient-to-r from-orange-600 to-amber-900",
  "bg-gradient-to-r from-orange-600 to-amber-950",
  "bg-gradient-to-r from-orange-600 to-yellow-300",
  "bg-gradient-to-r from-orange-600 to-yellow-400",
  "bg-gradient-to-r from-orange-600 to-yellow-500",
  "bg-gradient-to-r from-orange-600 to-yellow-600",
  "bg-gradient-to-r from-orange-600 to-yellow-700",
  "bg-gradient-to-r from-orange-600 to-yellow-800",
  "bg-gradient-to-r from-orange-600 to-yellow-900",
  "bg-gradient-to-r from-orange-600 to-yellow-950",
  "bg-gradient-to-r from-orange-600 to-lime-300",
  "bg-gradient-to-r from-orange-600 to-lime-400",
  "bg-gradient-to-r from-orange-600 to-lime-500",
  "bg-gradient-to-r from-orange-600 to-lime-600",
  "bg-gradient-to-r from-orange-600 to-lime-700",
  "bg-gradient-to-r from-orange-600 to-lime-800",
  "bg-gradient-to-r from-orange-600 to-lime-900",
  "bg-gradient-to-r from-orange-600 to-lime-950",
  "bg-gradient-to-r from-orange-600 to-green-300",
  "bg-gradient-to-r from-orange-600 to-green-400",
  "bg-gradient-to-r from-orange-600 to-green-500",
  "bg-gradient-to-r from-orange-600 to-green-600",
  "bg-gradient-to-r from-orange-600 to-green-700",
  "bg-gradient-to-r from-orange-600 to-green-800",
  "bg-gradient-to-r from-orange-600 to-green-900",
  "bg-gradient-to-r from-orange-600 to-green-950",
  "bg-gradient-to-r from-orange-600 to-emerald-300",
  "bg-gradient-to-r from-orange-600 to-emerald-400",
  "bg-gradient-to-r from-orange-600 to-emerald-500",
  "bg-gradient-to-r from-orange-600 to-emerald-600",
  "bg-gradient-to-r from-orange-600 to-emerald-700",
  "bg-gradient-to-r from-orange-600 to-emerald-800",
  "bg-gradient-to-r from-orange-600 to-emerald-900",
  "bg-gradient-to-r from-orange-600 to-emerald-950",
  "bg-gradient-to-r from-orange-600 to-teal-300",
  "bg-gradient-to-r from-orange-600 to-teal-400",
  "bg-gradient-to-r from-orange-600 to-teal-500",
  "bg-gradient-to-r from-orange-600 to-teal-600",
  "bg-gradient-to-r from-orange-600 to-teal-700",
  "bg-gradient-to-r from-orange-600 to-teal-800",
  "bg-gradient-to-r from-orange-600 to-teal-900",
  "bg-gradient-to-r from-orange-600 to-teal-950",
  "bg-gradient-to-r from-orange-600 to-cyan-300",
  "bg-gradient-to-r from-orange-600 to-cyan-400",
  "bg-gradient-to-r from-orange-600 to-cyan-500",
  "bg-gradient-to-r from-orange-600 to-cyan-600",
  "bg-gradient-to-r from-orange-600 to-cyan-700",
  "bg-gradient-to-r from-orange-600 to-cyan-800",
  "bg-gradient-to-r from-orange-600 to-cyan-900",
  "bg-gradient-to-r from-orange-600 to-cyan-950",
  "bg-gradient-to-r from-orange-600 to-sky-300",
  "bg-gradient-to-r from-orange-600 to-sky-400",
  "bg-gradient-to-r from-orange-600 to-sky-500",
  "bg-gradient-to-r from-orange-600 to-sky-600",
  "bg-gradient-to-r from-orange-600 to-sky-700",
  "bg-gradient-to-r from-orange-600 to-sky-800",
  "bg-gradient-to-r from-orange-600 to-sky-900",
  "bg-gradient-to-r from-orange-600 to-sky-950",
  "bg-gradient-to-r from-orange-600 to-blue-300",
  "bg-gradient-to-r from-orange-600 to-blue-400",
  "bg-gradient-to-r from-orange-600 to-blue-500",
  "bg-gradient-to-r from-orange-600 to-blue-600",
  "bg-gradient-to-r from-orange-600 to-blue-700",
  "bg-gradient-to-r from-orange-600 to-blue-800",
  "bg-gradient-to-r from-orange-600 to-blue-900",
  "bg-gradient-to-r from-orange-600 to-blue-950",
  "bg-gradient-to-r from-orange-600 to-indigo-300",
  "bg-gradient-to-r from-orange-600 to-indigo-400",
  "bg-gradient-to-r from-orange-600 to-indigo-500",
  "bg-gradient-to-r from-orange-600 to-indigo-600",
  "bg-gradient-to-r from-orange-600 to-indigo-700",
  "bg-gradient-to-r from-orange-600 to-indigo-800",
  "bg-gradient-to-r from-orange-600 to-indigo-900",
  "bg-gradient-to-r from-orange-600 to-indigo-950",
  "bg-gradient-to-r from-orange-600 to-violet-300",
  "bg-gradient-to-r from-orange-600 to-violet-400",
  "bg-gradient-to-r from-orange-600 to-violet-500",
  "bg-gradient-to-r from-orange-600 to-violet-600",
  "bg-gradient-to-r from-orange-600 to-violet-700",
  "bg-gradient-to-r from-orange-600 to-violet-800",
  "bg-gradient-to-r from-orange-600 to-violet-900",
  "bg-gradient-to-r from-orange-600 to-violet-950",
  "bg-gradient-to-r from-orange-600 to-purple-300",
  "bg-gradient-to-r from-orange-600 to-purple-400",
  "bg-gradient-to-r from-orange-600 to-purple-500",
  "bg-gradient-to-r from-orange-600 to-purple-600",
  "bg-gradient-to-r from-orange-600 to-purple-700",
  "bg-gradient-to-r from-orange-600 to-purple-800",
  "bg-gradient-to-r from-orange-600 to-purple-900",
  "bg-gradient-to-r from-orange-600 to-purple-950",
  "bg-gradient-to-r from-orange-600 to-fuchsia-300",
  "bg-gradient-to-r from-orange-600 to-fuchsia-400",
  "bg-gradient-to-r from-orange-600 to-fuchsia-500",
  "bg-gradient-to-r from-orange-600 to-fuchsia-600",
  "bg-gradient-to-r from-orange-600 to-fuchsia-700",
  "bg-gradient-to-r from-orange-600 to-fuchsia-800",
  "bg-gradient-to-r from-orange-600 to-fuchsia-900",
  "bg-gradient-to-r from-orange-600 to-fuchsia-950",
  "bg-gradient-to-r from-orange-600 to-pink-300",
  "bg-gradient-to-r from-orange-600 to-pink-400",
  "bg-gradient-to-r from-orange-600 to-pink-500",
  "bg-gradient-to-r from-orange-600 to-pink-600",
  "bg-gradient-to-r from-orange-600 to-pink-700",
  "bg-gradient-to-r from-orange-600 to-pink-800",
  "bg-gradient-to-r from-orange-600 to-pink-900",
  "bg-gradient-to-r from-orange-600 to-pink-950",
  "bg-gradient-to-r from-orange-600 to-rose-300",
  "bg-gradient-to-r from-orange-600 to-rose-400",
  "bg-gradient-to-r from-orange-600 to-rose-500",
  "bg-gradient-to-r from-orange-600 to-rose-600",
  "bg-gradient-to-r from-orange-600 to-rose-700",
  "bg-gradient-to-r from-orange-600 to-rose-800",
  "bg-gradient-to-r from-orange-600 to-rose-900",
  "bg-gradient-to-r from-orange-600 to-rose-950",
  "bg-gradient-to-r from-orange-700 to-red-300",
  "bg-gradient-to-r from-orange-700 to-red-400",
  "bg-gradient-to-r from-orange-700 to-red-500",
  "bg-gradient-to-r from-orange-700 to-red-600",
  "bg-gradient-to-r from-orange-700 to-red-700",
  "bg-gradient-to-r from-orange-700 to-red-800",
  "bg-gradient-to-r from-orange-700 to-red-900",
  "bg-gradient-to-r from-orange-700 to-red-950",
  "bg-gradient-to-r from-orange-700 to-amber-300",
  "bg-gradient-to-r from-orange-700 to-amber-400",
  "bg-gradient-to-r from-orange-700 to-amber-500",
  "bg-gradient-to-r from-orange-700 to-amber-600",
  "bg-gradient-to-r from-orange-700 to-amber-700",
  "bg-gradient-to-r from-orange-700 to-amber-800",
  "bg-gradient-to-r from-orange-700 to-amber-900",
  "bg-gradient-to-r from-orange-700 to-amber-950",
  "bg-gradient-to-r from-orange-700 to-yellow-300",
  "bg-gradient-to-r from-orange-700 to-yellow-400",
  "bg-gradient-to-r from-orange-700 to-yellow-500",
  "bg-gradient-to-r from-orange-700 to-yellow-600",
  "bg-gradient-to-r from-orange-700 to-yellow-700",
  "bg-gradient-to-r from-orange-700 to-yellow-800",
  "bg-gradient-to-r from-orange-700 to-yellow-900",
  "bg-gradient-to-r from-orange-700 to-yellow-950",
  "bg-gradient-to-r from-orange-700 to-lime-300",
  "bg-gradient-to-r from-orange-700 to-lime-400",
  "bg-gradient-to-r from-orange-700 to-lime-500",
  "bg-gradient-to-r from-orange-700 to-lime-600",
  "bg-gradient-to-r from-orange-700 to-lime-700",
  "bg-gradient-to-r from-orange-700 to-lime-800",
  "bg-gradient-to-r from-orange-700 to-lime-900",
  "bg-gradient-to-r from-orange-700 to-lime-950",
  "bg-gradient-to-r from-orange-700 to-green-300",
  "bg-gradient-to-r from-orange-700 to-green-400",
  "bg-gradient-to-r from-orange-700 to-green-500",
  "bg-gradient-to-r from-orange-700 to-green-600",
  "bg-gradient-to-r from-orange-700 to-green-700",
  "bg-gradient-to-r from-orange-700 to-green-800",
  "bg-gradient-to-r from-orange-700 to-green-900",
  "bg-gradient-to-r from-orange-700 to-green-950",
  "bg-gradient-to-r from-orange-700 to-emerald-300",
  "bg-gradient-to-r from-orange-700 to-emerald-400",
  "bg-gradient-to-r from-orange-700 to-emerald-500",
  "bg-gradient-to-r from-orange-700 to-emerald-600",
  "bg-gradient-to-r from-orange-700 to-emerald-700",
  "bg-gradient-to-r from-orange-700 to-emerald-800",
  "bg-gradient-to-r from-orange-700 to-emerald-900",
  "bg-gradient-to-r from-orange-700 to-emerald-950",
  "bg-gradient-to-r from-orange-700 to-teal-300",
  "bg-gradient-to-r from-orange-700 to-teal-400",
  "bg-gradient-to-r from-orange-700 to-teal-500",
  "bg-gradient-to-r from-orange-700 to-teal-600",
  "bg-gradient-to-r from-orange-700 to-teal-700",
  "bg-gradient-to-r from-orange-700 to-teal-800",
  "bg-gradient-to-r from-orange-700 to-teal-900",
  "bg-gradient-to-r from-orange-700 to-teal-950",
  "bg-gradient-to-r from-orange-700 to-cyan-300",
  "bg-gradient-to-r from-orange-700 to-cyan-400",
  "bg-gradient-to-r from-orange-700 to-cyan-500",
  "bg-gradient-to-r from-orange-700 to-cyan-600",
  "bg-gradient-to-r from-orange-700 to-cyan-700",
  "bg-gradient-to-r from-orange-700 to-cyan-800",
  "bg-gradient-to-r from-orange-700 to-cyan-900",
  "bg-gradient-to-r from-orange-700 to-cyan-950",
  "bg-gradient-to-r from-orange-700 to-sky-300",
  "bg-gradient-to-r from-orange-700 to-sky-400",
  "bg-gradient-to-r from-orange-700 to-sky-500",
  "bg-gradient-to-r from-orange-700 to-sky-600",
  "bg-gradient-to-r from-orange-700 to-sky-700",
  "bg-gradient-to-r from-orange-700 to-sky-800",
  "bg-gradient-to-r from-orange-700 to-sky-900",
  "bg-gradient-to-r from-orange-700 to-sky-950",
  "bg-gradient-to-r from-orange-700 to-blue-300",
  "bg-gradient-to-r from-orange-700 to-blue-400",
  "bg-gradient-to-r from-orange-700 to-blue-500",
  "bg-gradient-to-r from-orange-700 to-blue-600",
  "bg-gradient-to-r from-orange-700 to-blue-700",
  "bg-gradient-to-r from-orange-700 to-blue-800",
  "bg-gradient-to-r from-orange-700 to-blue-900",
  "bg-gradient-to-r from-orange-700 to-blue-950",
  "bg-gradient-to-r from-orange-700 to-indigo-300",
  "bg-gradient-to-r from-orange-700 to-indigo-400",
  "bg-gradient-to-r from-orange-700 to-indigo-500",
  "bg-gradient-to-r from-orange-700 to-indigo-600",
  "bg-gradient-to-r from-orange-700 to-indigo-700",
  "bg-gradient-to-r from-orange-700 to-indigo-800",
  "bg-gradient-to-r from-orange-700 to-indigo-900",
  "bg-gradient-to-r from-orange-700 to-indigo-950",
  "bg-gradient-to-r from-orange-700 to-violet-300",
  "bg-gradient-to-r from-orange-700 to-violet-400",
  "bg-gradient-to-r from-orange-700 to-violet-500",
  "bg-gradient-to-r from-orange-700 to-violet-600",
  "bg-gradient-to-r from-orange-700 to-violet-700",
  "bg-gradient-to-r from-orange-700 to-violet-800",
  "bg-gradient-to-r from-orange-700 to-violet-900",
  "bg-gradient-to-r from-orange-700 to-violet-950",
  "bg-gradient-to-r from-orange-700 to-purple-300",
  "bg-gradient-to-r from-orange-700 to-purple-400",
  "bg-gradient-to-r from-orange-700 to-purple-500",
  "bg-gradient-to-r from-orange-700 to-purple-600",
  "bg-gradient-to-r from-orange-700 to-purple-700",
  "bg-gradient-to-r from-orange-700 to-purple-800",
  "bg-gradient-to-r from-orange-700 to-purple-900",
  "bg-gradient-to-r from-orange-700 to-purple-950",
  "bg-gradient-to-r from-orange-700 to-fuchsia-300",
  "bg-gradient-to-r from-orange-700 to-fuchsia-400",
  "bg-gradient-to-r from-orange-700 to-fuchsia-500",
  "bg-gradient-to-r from-orange-700 to-fuchsia-600",
  "bg-gradient-to-r from-orange-700 to-fuchsia-700",
  "bg-gradient-to-r from-orange-700 to-fuchsia-800",
  "bg-gradient-to-r from-orange-700 to-fuchsia-900",
  "bg-gradient-to-r from-orange-700 to-fuchsia-950",
  "bg-gradient-to-r from-orange-700 to-pink-300",
  "bg-gradient-to-r from-orange-700 to-pink-400",
  "bg-gradient-to-r from-orange-700 to-pink-500",
  "bg-gradient-to-r from-orange-700 to-pink-600",
  "bg-gradient-to-r from-orange-700 to-pink-700",
  "bg-gradient-to-r from-orange-700 to-pink-800",
  "bg-gradient-to-r from-orange-700 to-pink-900",
  "bg-gradient-to-r from-orange-700 to-pink-950",
  "bg-gradient-to-r from-orange-700 to-rose-300",
  "bg-gradient-to-r from-orange-700 to-rose-400",
  "bg-gradient-to-r from-orange-700 to-rose-500",
  "bg-gradient-to-r from-orange-700 to-rose-600",
  "bg-gradient-to-r from-orange-700 to-rose-700",
  "bg-gradient-to-r from-orange-700 to-rose-800",
  "bg-gradient-to-r from-orange-700 to-rose-900",
  "bg-gradient-to-r from-orange-700 to-rose-950",
  "bg-gradient-to-r from-orange-800 to-red-300",
  "bg-gradient-to-r from-orange-800 to-red-400",
  "bg-gradient-to-r from-orange-800 to-red-500",
  "bg-gradient-to-r from-orange-800 to-red-600",
  "bg-gradient-to-r from-orange-800 to-red-700",
  "bg-gradient-to-r from-orange-800 to-red-800",
  "bg-gradient-to-r from-orange-800 to-red-900",
  "bg-gradient-to-r from-orange-800 to-red-950",
  "bg-gradient-to-r from-orange-800 to-amber-300",
  "bg-gradient-to-r from-orange-800 to-amber-400",
  "bg-gradient-to-r from-orange-800 to-amber-500",
  "bg-gradient-to-r from-orange-800 to-amber-600",
  "bg-gradient-to-r from-orange-800 to-amber-700",
  "bg-gradient-to-r from-orange-800 to-amber-800",
  "bg-gradient-to-r from-orange-800 to-amber-900",
  "bg-gradient-to-r from-orange-800 to-amber-950",
  "bg-gradient-to-r from-orange-800 to-yellow-300",
  "bg-gradient-to-r from-orange-800 to-yellow-400",
  "bg-gradient-to-r from-orange-800 to-yellow-500",
  "bg-gradient-to-r from-orange-800 to-yellow-600",
  "bg-gradient-to-r from-orange-800 to-yellow-700",
  "bg-gradient-to-r from-orange-800 to-yellow-800",
  "bg-gradient-to-r from-orange-800 to-yellow-900",
  "bg-gradient-to-r from-orange-800 to-yellow-950",
  "bg-gradient-to-r from-orange-800 to-lime-300",
  "bg-gradient-to-r from-orange-800 to-lime-400",
  "bg-gradient-to-r from-orange-800 to-lime-500",
  "bg-gradient-to-r from-orange-800 to-lime-600",
  "bg-gradient-to-r from-orange-800 to-lime-700",
  "bg-gradient-to-r from-orange-800 to-lime-800",
  "bg-gradient-to-r from-orange-800 to-lime-900",
  "bg-gradient-to-r from-orange-800 to-lime-950",
  "bg-gradient-to-r from-orange-800 to-green-300",
  "bg-gradient-to-r from-orange-800 to-green-400",
  "bg-gradient-to-r from-orange-800 to-green-500",
  "bg-gradient-to-r from-orange-800 to-green-600",
  "bg-gradient-to-r from-orange-800 to-green-700",
  "bg-gradient-to-r from-orange-800 to-green-800",
  "bg-gradient-to-r from-orange-800 to-green-900",
  "bg-gradient-to-r from-orange-800 to-green-950",
  "bg-gradient-to-r from-orange-800 to-emerald-300",
  "bg-gradient-to-r from-orange-800 to-emerald-400",
  "bg-gradient-to-r from-orange-800 to-emerald-500",
  "bg-gradient-to-r from-orange-800 to-emerald-600",
  "bg-gradient-to-r from-orange-800 to-emerald-700",
  "bg-gradient-to-r from-orange-800 to-emerald-800",
  "bg-gradient-to-r from-orange-800 to-emerald-900",
  "bg-gradient-to-r from-orange-800 to-emerald-950",
  "bg-gradient-to-r from-orange-800 to-teal-300",
  "bg-gradient-to-r from-orange-800 to-teal-400",
  "bg-gradient-to-r from-orange-800 to-teal-500",
  "bg-gradient-to-r from-orange-800 to-teal-600",
  "bg-gradient-to-r from-orange-800 to-teal-700",
  "bg-gradient-to-r from-orange-800 to-teal-800",
  "bg-gradient-to-r from-orange-800 to-teal-900",
  "bg-gradient-to-r from-orange-800 to-teal-950",
  "bg-gradient-to-r from-orange-800 to-cyan-300",
  "bg-gradient-to-r from-orange-800 to-cyan-400",
  "bg-gradient-to-r from-orange-800 to-cyan-500",
  "bg-gradient-to-r from-orange-800 to-cyan-600",
  "bg-gradient-to-r from-orange-800 to-cyan-700",
  "bg-gradient-to-r from-orange-800 to-cyan-800",
  "bg-gradient-to-r from-orange-800 to-cyan-900",
  "bg-gradient-to-r from-orange-800 to-cyan-950",
  "bg-gradient-to-r from-orange-800 to-sky-300",
  "bg-gradient-to-r from-orange-800 to-sky-400",
  "bg-gradient-to-r from-orange-800 to-sky-500",
  "bg-gradient-to-r from-orange-800 to-sky-600",
  "bg-gradient-to-r from-orange-800 to-sky-700",
  "bg-gradient-to-r from-orange-800 to-sky-800",
  "bg-gradient-to-r from-orange-800 to-sky-900",
  "bg-gradient-to-r from-orange-800 to-sky-950",
  "bg-gradient-to-r from-orange-800 to-blue-300",
  "bg-gradient-to-r from-orange-800 to-blue-400",
  "bg-gradient-to-r from-orange-800 to-blue-500",
  "bg-gradient-to-r from-orange-800 to-blue-600",
  "bg-gradient-to-r from-orange-800 to-blue-700",
  "bg-gradient-to-r from-orange-800 to-blue-800",
  "bg-gradient-to-r from-orange-800 to-blue-900",
  "bg-gradient-to-r from-orange-800 to-blue-950",
  "bg-gradient-to-r from-orange-800 to-indigo-300",
  "bg-gradient-to-r from-orange-800 to-indigo-400",
  "bg-gradient-to-r from-orange-800 to-indigo-500",
  "bg-gradient-to-r from-orange-800 to-indigo-600",
  "bg-gradient-to-r from-orange-800 to-indigo-700",
  "bg-gradient-to-r from-orange-800 to-indigo-800",
  "bg-gradient-to-r from-orange-800 to-indigo-900",
  "bg-gradient-to-r from-orange-800 to-indigo-950",
  "bg-gradient-to-r from-orange-800 to-violet-300",
  "bg-gradient-to-r from-orange-800 to-violet-400",
  "bg-gradient-to-r from-orange-800 to-violet-500",
  "bg-gradient-to-r from-orange-800 to-violet-600",
  "bg-gradient-to-r from-orange-800 to-violet-700",
  "bg-gradient-to-r from-orange-800 to-violet-800",
  "bg-gradient-to-r from-orange-800 to-violet-900",
  "bg-gradient-to-r from-orange-800 to-violet-950",
  "bg-gradient-to-r from-orange-800 to-purple-300",
  "bg-gradient-to-r from-orange-800 to-purple-400",
  "bg-gradient-to-r from-orange-800 to-purple-500",
  "bg-gradient-to-r from-orange-800 to-purple-600",
  "bg-gradient-to-r from-orange-800 to-purple-700",
  "bg-gradient-to-r from-orange-800 to-purple-800",
  "bg-gradient-to-r from-orange-800 to-purple-900",
  "bg-gradient-to-r from-orange-800 to-purple-950",
  "bg-gradient-to-r from-orange-800 to-fuchsia-300",
  "bg-gradient-to-r from-orange-800 to-fuchsia-400",
  "bg-gradient-to-r from-orange-800 to-fuchsia-500",
  "bg-gradient-to-r from-orange-800 to-fuchsia-600",
  "bg-gradient-to-r from-orange-800 to-fuchsia-700",
  "bg-gradient-to-r from-orange-800 to-fuchsia-800",
  "bg-gradient-to-r from-orange-800 to-fuchsia-900",
  "bg-gradient-to-r from-orange-800 to-fuchsia-950",
  "bg-gradient-to-r from-orange-800 to-pink-300",
  "bg-gradient-to-r from-orange-800 to-pink-400",
  "bg-gradient-to-r from-orange-800 to-pink-500",
  "bg-gradient-to-r from-orange-800 to-pink-600",
  "bg-gradient-to-r from-orange-800 to-pink-700",
  "bg-gradient-to-r from-orange-800 to-pink-800",
  "bg-gradient-to-r from-orange-800 to-pink-900",
  "bg-gradient-to-r from-orange-800 to-pink-950",
  "bg-gradient-to-r from-orange-800 to-rose-300",
  "bg-gradient-to-r from-orange-800 to-rose-400",
  "bg-gradient-to-r from-orange-800 to-rose-500",
  "bg-gradient-to-r from-orange-800 to-rose-600",
  "bg-gradient-to-r from-orange-800 to-rose-700",
  "bg-gradient-to-r from-orange-800 to-rose-800",
  "bg-gradient-to-r from-orange-800 to-rose-900",
  "bg-gradient-to-r from-orange-800 to-rose-950",
  "bg-gradient-to-r from-orange-900 to-red-300",
  "bg-gradient-to-r from-orange-900 to-red-400",
  "bg-gradient-to-r from-orange-900 to-red-500",
  "bg-gradient-to-r from-orange-900 to-red-600",
  "bg-gradient-to-r from-orange-900 to-red-700",
  "bg-gradient-to-r from-orange-900 to-red-800",
  "bg-gradient-to-r from-orange-900 to-red-900",
  "bg-gradient-to-r from-orange-900 to-red-950",
  "bg-gradient-to-r from-orange-900 to-amber-300",
  "bg-gradient-to-r from-orange-900 to-amber-400",
  "bg-gradient-to-r from-orange-900 to-amber-500",
  "bg-gradient-to-r from-orange-900 to-amber-600",
  "bg-gradient-to-r from-orange-900 to-amber-700",
  "bg-gradient-to-r from-orange-900 to-amber-800",
  "bg-gradient-to-r from-orange-900 to-amber-900",
  "bg-gradient-to-r from-orange-900 to-amber-950",
  "bg-gradient-to-r from-orange-900 to-yellow-300",
  "bg-gradient-to-r from-orange-900 to-yellow-400",
  "bg-gradient-to-r from-orange-900 to-yellow-500",
  "bg-gradient-to-r from-orange-900 to-yellow-600",
  "bg-gradient-to-r from-orange-900 to-yellow-700",
  "bg-gradient-to-r from-orange-900 to-yellow-800",
  "bg-gradient-to-r from-orange-900 to-yellow-900",
  "bg-gradient-to-r from-orange-900 to-yellow-950",
  "bg-gradient-to-r from-orange-900 to-lime-300",
  "bg-gradient-to-r from-orange-900 to-lime-400",
  "bg-gradient-to-r from-orange-900 to-lime-500",
  "bg-gradient-to-r from-orange-900 to-lime-600",
  "bg-gradient-to-r from-orange-900 to-lime-700",
  "bg-gradient-to-r from-orange-900 to-lime-800",
  "bg-gradient-to-r from-orange-900 to-lime-900",
  "bg-gradient-to-r from-orange-900 to-lime-950",
  "bg-gradient-to-r from-orange-900 to-green-300",
  "bg-gradient-to-r from-orange-900 to-green-400",
  "bg-gradient-to-r from-orange-900 to-green-500",
  "bg-gradient-to-r from-orange-900 to-green-600",
  "bg-gradient-to-r from-orange-900 to-green-700",
  "bg-gradient-to-r from-orange-900 to-green-800",
  "bg-gradient-to-r from-orange-900 to-green-900",
  "bg-gradient-to-r from-orange-900 to-green-950",
  "bg-gradient-to-r from-orange-900 to-emerald-300",
  "bg-gradient-to-r from-orange-900 to-emerald-400",
  "bg-gradient-to-r from-orange-900 to-emerald-500",
  "bg-gradient-to-r from-orange-900 to-emerald-600",
  "bg-gradient-to-r from-orange-900 to-emerald-700",
  "bg-gradient-to-r from-orange-900 to-emerald-800",
  "bg-gradient-to-r from-orange-900 to-emerald-900",
  "bg-gradient-to-r from-orange-900 to-emerald-950",
  "bg-gradient-to-r from-orange-900 to-teal-300",
  "bg-gradient-to-r from-orange-900 to-teal-400",
  "bg-gradient-to-r from-orange-900 to-teal-500",
  "bg-gradient-to-r from-orange-900 to-teal-600",
  "bg-gradient-to-r from-orange-900 to-teal-700",
  "bg-gradient-to-r from-orange-900 to-teal-800",
  "bg-gradient-to-r from-orange-900 to-teal-900",
  "bg-gradient-to-r from-orange-900 to-teal-950",
  "bg-gradient-to-r from-orange-900 to-cyan-300",
  "bg-gradient-to-r from-orange-900 to-cyan-400",
  "bg-gradient-to-r from-orange-900 to-cyan-500",
  "bg-gradient-to-r from-orange-900 to-cyan-600",
  "bg-gradient-to-r from-orange-900 to-cyan-700",
  "bg-gradient-to-r from-orange-900 to-cyan-800",
  "bg-gradient-to-r from-orange-900 to-cyan-900",
  "bg-gradient-to-r from-orange-900 to-cyan-950",
  "bg-gradient-to-r from-orange-900 to-sky-300",
  "bg-gradient-to-r from-orange-900 to-sky-400",
  "bg-gradient-to-r from-orange-900 to-sky-500",
  "bg-gradient-to-r from-orange-900 to-sky-600",
  "bg-gradient-to-r from-orange-900 to-sky-700",
  "bg-gradient-to-r from-orange-900 to-sky-800",
  "bg-gradient-to-r from-orange-900 to-sky-900",
  "bg-gradient-to-r from-orange-900 to-sky-950",
  "bg-gradient-to-r from-orange-900 to-blue-300",
  "bg-gradient-to-r from-orange-900 to-blue-400",
  "bg-gradient-to-r from-orange-900 to-blue-500",
  "bg-gradient-to-r from-orange-900 to-blue-600",
  "bg-gradient-to-r from-orange-900 to-blue-700",
  "bg-gradient-to-r from-orange-900 to-blue-800",
  "bg-gradient-to-r from-orange-900 to-blue-900",
  "bg-gradient-to-r from-orange-900 to-blue-950",
  "bg-gradient-to-r from-orange-900 to-indigo-300",
  "bg-gradient-to-r from-orange-900 to-indigo-400",
  "bg-gradient-to-r from-orange-900 to-indigo-500",
  "bg-gradient-to-r from-orange-900 to-indigo-600",
  "bg-gradient-to-r from-orange-900 to-indigo-700",
  "bg-gradient-to-r from-orange-900 to-indigo-800",
  "bg-gradient-to-r from-orange-900 to-indigo-900",
  "bg-gradient-to-r from-orange-900 to-indigo-950",
  "bg-gradient-to-r from-orange-900 to-violet-300",
  "bg-gradient-to-r from-orange-900 to-violet-400",
  "bg-gradient-to-r from-orange-900 to-violet-500",
  "bg-gradient-to-r from-orange-900 to-violet-600",
  "bg-gradient-to-r from-orange-900 to-violet-700",
  "bg-gradient-to-r from-orange-900 to-violet-800",
  "bg-gradient-to-r from-orange-900 to-violet-900",
  "bg-gradient-to-r from-orange-900 to-violet-950",
  "bg-gradient-to-r from-orange-900 to-purple-300",
  "bg-gradient-to-r from-orange-900 to-purple-400",
  "bg-gradient-to-r from-orange-900 to-purple-500",
  "bg-gradient-to-r from-orange-900 to-purple-600",
  "bg-gradient-to-r from-orange-900 to-purple-700",
  "bg-gradient-to-r from-orange-900 to-purple-800",
  "bg-gradient-to-r from-orange-900 to-purple-900",
  "bg-gradient-to-r from-orange-900 to-purple-950",
  "bg-gradient-to-r from-orange-900 to-fuchsia-300",
  "bg-gradient-to-r from-orange-900 to-fuchsia-400",
  "bg-gradient-to-r from-orange-900 to-fuchsia-500",
  "bg-gradient-to-r from-orange-900 to-fuchsia-600",
  "bg-gradient-to-r from-orange-900 to-fuchsia-700",
  "bg-gradient-to-r from-orange-900 to-fuchsia-800",
  "bg-gradient-to-r from-orange-900 to-fuchsia-900",
  "bg-gradient-to-r from-orange-900 to-fuchsia-950",
  "bg-gradient-to-r from-orange-900 to-pink-300",
  "bg-gradient-to-r from-orange-900 to-pink-400",
  "bg-gradient-to-r from-orange-900 to-pink-500",
  "bg-gradient-to-r from-orange-900 to-pink-600",
  "bg-gradient-to-r from-orange-900 to-pink-700",
  "bg-gradient-to-r from-orange-900 to-pink-800",
  "bg-gradient-to-r from-orange-900 to-pink-900",
  "bg-gradient-to-r from-orange-900 to-pink-950",
  "bg-gradient-to-r from-orange-900 to-rose-300",
  "bg-gradient-to-r from-orange-900 to-rose-400",
  "bg-gradient-to-r from-orange-900 to-rose-500",
  "bg-gradient-to-r from-orange-900 to-rose-600",
  "bg-gradient-to-r from-orange-900 to-rose-700",
  "bg-gradient-to-r from-orange-900 to-rose-800",
  "bg-gradient-to-r from-orange-900 to-rose-900",
  "bg-gradient-to-r from-orange-900 to-rose-950",
  "bg-gradient-to-r from-orange-950 to-red-300",
  "bg-gradient-to-r from-orange-950 to-red-400",
  "bg-gradient-to-r from-orange-950 to-red-500",
  "bg-gradient-to-r from-orange-950 to-red-600",
  "bg-gradient-to-r from-orange-950 to-red-700",
  "bg-gradient-to-r from-orange-950 to-red-800",
  "bg-gradient-to-r from-orange-950 to-red-900",
  "bg-gradient-to-r from-orange-950 to-red-950",
  "bg-gradient-to-r from-orange-950 to-amber-300",
  "bg-gradient-to-r from-orange-950 to-amber-400",
  "bg-gradient-to-r from-orange-950 to-amber-500",
  "bg-gradient-to-r from-orange-950 to-amber-600",
  "bg-gradient-to-r from-orange-950 to-amber-700",
  "bg-gradient-to-r from-orange-950 to-amber-800",
  "bg-gradient-to-r from-orange-950 to-amber-900",
  "bg-gradient-to-r from-orange-950 to-amber-950",
  "bg-gradient-to-r from-orange-950 to-yellow-300",
  "bg-gradient-to-r from-orange-950 to-yellow-400",
  "bg-gradient-to-r from-orange-950 to-yellow-500",
  "bg-gradient-to-r from-orange-950 to-yellow-600",
  "bg-gradient-to-r from-orange-950 to-yellow-700",
  "bg-gradient-to-r from-orange-950 to-yellow-800",
  "bg-gradient-to-r from-orange-950 to-yellow-900",
  "bg-gradient-to-r from-orange-950 to-yellow-950",
  "bg-gradient-to-r from-orange-950 to-lime-300",
  "bg-gradient-to-r from-orange-950 to-lime-400",
  "bg-gradient-to-r from-orange-950 to-lime-500",
  "bg-gradient-to-r from-orange-950 to-lime-600",
  "bg-gradient-to-r from-orange-950 to-lime-700",
  "bg-gradient-to-r from-orange-950 to-lime-800",
  "bg-gradient-to-r from-orange-950 to-lime-900",
  "bg-gradient-to-r from-orange-950 to-lime-950",
  "bg-gradient-to-r from-orange-950 to-green-300",
  "bg-gradient-to-r from-orange-950 to-green-400",
  "bg-gradient-to-r from-orange-950 to-green-500",
  "bg-gradient-to-r from-orange-950 to-green-600",
  "bg-gradient-to-r from-orange-950 to-green-700",
  "bg-gradient-to-r from-orange-950 to-green-800",
  "bg-gradient-to-r from-orange-950 to-green-900",
  "bg-gradient-to-r from-orange-950 to-green-950",
  "bg-gradient-to-r from-orange-950 to-emerald-300",
  "bg-gradient-to-r from-orange-950 to-emerald-400",
  "bg-gradient-to-r from-orange-950 to-emerald-500",
  "bg-gradient-to-r from-orange-950 to-emerald-600",
  "bg-gradient-to-r from-orange-950 to-emerald-700",
  "bg-gradient-to-r from-orange-950 to-emerald-800",
  "bg-gradient-to-r from-orange-950 to-emerald-900",
  "bg-gradient-to-r from-orange-950 to-emerald-950",
  "bg-gradient-to-r from-orange-950 to-teal-300",
  "bg-gradient-to-r from-orange-950 to-teal-400",
  "bg-gradient-to-r from-orange-950 to-teal-500",
  "bg-gradient-to-r from-orange-950 to-teal-600",
  "bg-gradient-to-r from-orange-950 to-teal-700",
  "bg-gradient-to-r from-orange-950 to-teal-800",
  "bg-gradient-to-r from-orange-950 to-teal-900",
  "bg-gradient-to-r from-orange-950 to-teal-950",
  "bg-gradient-to-r from-orange-950 to-cyan-300",
  "bg-gradient-to-r from-orange-950 to-cyan-400",
  "bg-gradient-to-r from-orange-950 to-cyan-500",
  "bg-gradient-to-r from-orange-950 to-cyan-600",
  "bg-gradient-to-r from-orange-950 to-cyan-700",
  "bg-gradient-to-r from-orange-950 to-cyan-800",
  "bg-gradient-to-r from-orange-950 to-cyan-900",
  "bg-gradient-to-r from-orange-950 to-cyan-950",
  "bg-gradient-to-r from-orange-950 to-sky-300",
  "bg-gradient-to-r from-orange-950 to-sky-400",
  "bg-gradient-to-r from-orange-950 to-sky-500",
  "bg-gradient-to-r from-orange-950 to-sky-600",
  "bg-gradient-to-r from-orange-950 to-sky-700",
  "bg-gradient-to-r from-orange-950 to-sky-800",
  "bg-gradient-to-r from-orange-950 to-sky-900",
  "bg-gradient-to-r from-orange-950 to-sky-950",
  "bg-gradient-to-r from-orange-950 to-blue-300",
  "bg-gradient-to-r from-orange-950 to-blue-400",
  "bg-gradient-to-r from-orange-950 to-blue-500",
  "bg-gradient-to-r from-orange-950 to-blue-600",
  "bg-gradient-to-r from-orange-950 to-blue-700",
  "bg-gradient-to-r from-orange-950 to-blue-800",
  "bg-gradient-to-r from-orange-950 to-blue-900",
  "bg-gradient-to-r from-orange-950 to-blue-950",
  "bg-gradient-to-r from-orange-950 to-indigo-300",
  "bg-gradient-to-r from-orange-950 to-indigo-400",
  "bg-gradient-to-r from-orange-950 to-indigo-500",
  "bg-gradient-to-r from-orange-950 to-indigo-600",
  "bg-gradient-to-r from-orange-950 to-indigo-700",
  "bg-gradient-to-r from-orange-950 to-indigo-800",
  "bg-gradient-to-r from-orange-950 to-indigo-900",
  "bg-gradient-to-r from-orange-950 to-indigo-950",
  "bg-gradient-to-r from-orange-950 to-violet-300",
  "bg-gradient-to-r from-orange-950 to-violet-400",
  "bg-gradient-to-r from-orange-950 to-violet-500",
  "bg-gradient-to-r from-orange-950 to-violet-600",
  "bg-gradient-to-r from-orange-950 to-violet-700",
  "bg-gradient-to-r from-orange-950 to-violet-800",
  "bg-gradient-to-r from-orange-950 to-violet-900",
  "bg-gradient-to-r from-orange-950 to-violet-950",
  "bg-gradient-to-r from-orange-950 to-purple-300",
  "bg-gradient-to-r from-orange-950 to-purple-400",
  "bg-gradient-to-r from-orange-950 to-purple-500",
  "bg-gradient-to-r from-orange-950 to-purple-600",
  "bg-gradient-to-r from-orange-950 to-purple-700",
  "bg-gradient-to-r from-orange-950 to-purple-800",
  "bg-gradient-to-r from-orange-950 to-purple-900",
  "bg-gradient-to-r from-orange-950 to-purple-950",
  "bg-gradient-to-r from-orange-950 to-fuchsia-300",
  "bg-gradient-to-r from-orange-950 to-fuchsia-400",
  "bg-gradient-to-r from-orange-950 to-fuchsia-500",
  "bg-gradient-to-r from-orange-950 to-fuchsia-600",
  "bg-gradient-to-r from-orange-950 to-fuchsia-700",
  "bg-gradient-to-r from-orange-950 to-fuchsia-800",
  "bg-gradient-to-r from-orange-950 to-fuchsia-900",
  "bg-gradient-to-r from-orange-950 to-fuchsia-950",
  "bg-gradient-to-r from-orange-950 to-pink-300",
  "bg-gradient-to-r from-orange-950 to-pink-400",
  "bg-gradient-to-r from-orange-950 to-pink-500",
  "bg-gradient-to-r from-orange-950 to-pink-600",
  "bg-gradient-to-r from-orange-950 to-pink-700",
  "bg-gradient-to-r from-orange-950 to-pink-800",
  "bg-gradient-to-r from-orange-950 to-pink-900",
  "bg-gradient-to-r from-orange-950 to-pink-950",
  "bg-gradient-to-r from-orange-950 to-rose-300",
  "bg-gradient-to-r from-orange-950 to-rose-400",
  "bg-gradient-to-r from-orange-950 to-rose-500",
  "bg-gradient-to-r from-orange-950 to-rose-600",
  "bg-gradient-to-r from-orange-950 to-rose-700",
  "bg-gradient-to-r from-orange-950 to-rose-800",
  "bg-gradient-to-r from-orange-950 to-rose-900",
  "bg-gradient-to-r from-orange-950 to-rose-950",
  "bg-gradient-to-r from-amber-300 to-red-300",
  "bg-gradient-to-r from-amber-300 to-red-400",
  "bg-gradient-to-r from-amber-300 to-red-500",
  "bg-gradient-to-r from-amber-300 to-red-600",
  "bg-gradient-to-r from-amber-300 to-red-700",
  "bg-gradient-to-r from-amber-300 to-red-800",
  "bg-gradient-to-r from-amber-300 to-red-900",
  "bg-gradient-to-r from-amber-300 to-red-950",
  "bg-gradient-to-r from-amber-300 to-orange-300",
  "bg-gradient-to-r from-amber-300 to-orange-400",
  "bg-gradient-to-r from-amber-300 to-orange-500",
  "bg-gradient-to-r from-amber-300 to-orange-600",
  "bg-gradient-to-r from-amber-300 to-orange-700",
  "bg-gradient-to-r from-amber-300 to-orange-800",
  "bg-gradient-to-r from-amber-300 to-orange-900",
  "bg-gradient-to-r from-amber-300 to-orange-950",
  "bg-gradient-to-r from-amber-300 to-yellow-300",
  "bg-gradient-to-r from-amber-300 to-yellow-400",
  "bg-gradient-to-r from-amber-300 to-yellow-500",
  "bg-gradient-to-r from-amber-300 to-yellow-600",
  "bg-gradient-to-r from-amber-300 to-yellow-700",
  "bg-gradient-to-r from-amber-300 to-yellow-800",
  "bg-gradient-to-r from-amber-300 to-yellow-900",
  "bg-gradient-to-r from-amber-300 to-yellow-950",
  "bg-gradient-to-r from-amber-300 to-lime-300",
  "bg-gradient-to-r from-amber-300 to-lime-400",
  "bg-gradient-to-r from-amber-300 to-lime-500",
  "bg-gradient-to-r from-amber-300 to-lime-600",
  "bg-gradient-to-r from-amber-300 to-lime-700",
  "bg-gradient-to-r from-amber-300 to-lime-800",
  "bg-gradient-to-r from-amber-300 to-lime-900",
  "bg-gradient-to-r from-amber-300 to-lime-950",
  "bg-gradient-to-r from-amber-300 to-green-300",
  "bg-gradient-to-r from-amber-300 to-green-400",
  "bg-gradient-to-r from-amber-300 to-green-500",
  "bg-gradient-to-r from-amber-300 to-green-600",
  "bg-gradient-to-r from-amber-300 to-green-700",
  "bg-gradient-to-r from-amber-300 to-green-800",
  "bg-gradient-to-r from-amber-300 to-green-900",
  "bg-gradient-to-r from-amber-300 to-green-950",
  "bg-gradient-to-r from-amber-300 to-emerald-300",
  "bg-gradient-to-r from-amber-300 to-emerald-400",
  "bg-gradient-to-r from-amber-300 to-emerald-500",
  "bg-gradient-to-r from-amber-300 to-emerald-600",
  "bg-gradient-to-r from-amber-300 to-emerald-700",
  "bg-gradient-to-r from-amber-300 to-emerald-800",
  "bg-gradient-to-r from-amber-300 to-emerald-900",
  "bg-gradient-to-r from-amber-300 to-emerald-950",
  "bg-gradient-to-r from-amber-300 to-teal-300",
  "bg-gradient-to-r from-amber-300 to-teal-400",
  "bg-gradient-to-r from-amber-300 to-teal-500",
  "bg-gradient-to-r from-amber-300 to-teal-600",
  "bg-gradient-to-r from-amber-300 to-teal-700",
  "bg-gradient-to-r from-amber-300 to-teal-800",
  "bg-gradient-to-r from-amber-300 to-teal-900",
  "bg-gradient-to-r from-amber-300 to-teal-950",
  "bg-gradient-to-r from-amber-300 to-cyan-300",
  "bg-gradient-to-r from-amber-300 to-cyan-400",
  "bg-gradient-to-r from-amber-300 to-cyan-500",
  "bg-gradient-to-r from-amber-300 to-cyan-600",
  "bg-gradient-to-r from-amber-300 to-cyan-700",
  "bg-gradient-to-r from-amber-300 to-cyan-800",
  "bg-gradient-to-r from-amber-300 to-cyan-900",
  "bg-gradient-to-r from-amber-300 to-cyan-950",
  "bg-gradient-to-r from-amber-300 to-sky-300",
  "bg-gradient-to-r from-amber-300 to-sky-400",
  "bg-gradient-to-r from-amber-300 to-sky-500",
  "bg-gradient-to-r from-amber-300 to-sky-600",
  "bg-gradient-to-r from-amber-300 to-sky-700",
  "bg-gradient-to-r from-amber-300 to-sky-800",
  "bg-gradient-to-r from-amber-300 to-sky-900",
  "bg-gradient-to-r from-amber-300 to-sky-950",
  "bg-gradient-to-r from-amber-300 to-blue-300",
  "bg-gradient-to-r from-amber-300 to-blue-400",
  "bg-gradient-to-r from-amber-300 to-blue-500",
  "bg-gradient-to-r from-amber-300 to-blue-600",
  "bg-gradient-to-r from-amber-300 to-blue-700",
  "bg-gradient-to-r from-amber-300 to-blue-800",
  "bg-gradient-to-r from-amber-300 to-blue-900",
  "bg-gradient-to-r from-amber-300 to-blue-950",
  "bg-gradient-to-r from-amber-300 to-indigo-300",
  "bg-gradient-to-r from-amber-300 to-indigo-400",
  "bg-gradient-to-r from-amber-300 to-indigo-500",
  "bg-gradient-to-r from-amber-300 to-indigo-600",
  "bg-gradient-to-r from-amber-300 to-indigo-700",
  "bg-gradient-to-r from-amber-300 to-indigo-800",
  "bg-gradient-to-r from-amber-300 to-indigo-900",
  "bg-gradient-to-r from-amber-300 to-indigo-950",
  "bg-gradient-to-r from-amber-300 to-violet-300",
  "bg-gradient-to-r from-amber-300 to-violet-400",
  "bg-gradient-to-r from-amber-300 to-violet-500",
  "bg-gradient-to-r from-amber-300 to-violet-600",
  "bg-gradient-to-r from-amber-300 to-violet-700",
  "bg-gradient-to-r from-amber-300 to-violet-800",
  "bg-gradient-to-r from-amber-300 to-violet-900",
  "bg-gradient-to-r from-amber-300 to-violet-950",
  "bg-gradient-to-r from-amber-300 to-purple-300",
  "bg-gradient-to-r from-amber-300 to-purple-400",
  "bg-gradient-to-r from-amber-300 to-purple-500",
  "bg-gradient-to-r from-amber-300 to-purple-600",
  "bg-gradient-to-r from-amber-300 to-purple-700",
  "bg-gradient-to-r from-amber-300 to-purple-800",
  "bg-gradient-to-r from-amber-300 to-purple-900",
  "bg-gradient-to-r from-amber-300 to-purple-950",
  "bg-gradient-to-r from-amber-300 to-fuchsia-300",
  "bg-gradient-to-r from-amber-300 to-fuchsia-400",
  "bg-gradient-to-r from-amber-300 to-fuchsia-500",
  "bg-gradient-to-r from-amber-300 to-fuchsia-600",
  "bg-gradient-to-r from-amber-300 to-fuchsia-700",
  "bg-gradient-to-r from-amber-300 to-fuchsia-800",
  "bg-gradient-to-r from-amber-300 to-fuchsia-900",
  "bg-gradient-to-r from-amber-300 to-fuchsia-950",
  "bg-gradient-to-r from-amber-300 to-pink-300",
  "bg-gradient-to-r from-amber-300 to-pink-400",
  "bg-gradient-to-r from-amber-300 to-pink-500",
  "bg-gradient-to-r from-amber-300 to-pink-600",
  "bg-gradient-to-r from-amber-300 to-pink-700",
  "bg-gradient-to-r from-amber-300 to-pink-800",
  "bg-gradient-to-r from-amber-300 to-pink-900",
  "bg-gradient-to-r from-amber-300 to-pink-950",
  "bg-gradient-to-r from-amber-300 to-rose-300",
  "bg-gradient-to-r from-amber-300 to-rose-400",
  "bg-gradient-to-r from-amber-300 to-rose-500",
  "bg-gradient-to-r from-amber-300 to-rose-600",
  "bg-gradient-to-r from-amber-300 to-rose-700",
  "bg-gradient-to-r from-amber-300 to-rose-800",
  "bg-gradient-to-r from-amber-300 to-rose-900",
  "bg-gradient-to-r from-amber-300 to-rose-950",
  "bg-gradient-to-r from-amber-400 to-red-300",
  "bg-gradient-to-r from-amber-400 to-red-400",
  "bg-gradient-to-r from-amber-400 to-red-500",
  "bg-gradient-to-r from-amber-400 to-red-600",
  "bg-gradient-to-r from-amber-400 to-red-700",
  "bg-gradient-to-r from-amber-400 to-red-800",
  "bg-gradient-to-r from-amber-400 to-red-900",
  "bg-gradient-to-r from-amber-400 to-red-950",
  "bg-gradient-to-r from-amber-400 to-orange-300",
  "bg-gradient-to-r from-amber-400 to-orange-400",
  "bg-gradient-to-r from-amber-400 to-orange-500",
  "bg-gradient-to-r from-amber-400 to-orange-600",
  "bg-gradient-to-r from-amber-400 to-orange-700",
  "bg-gradient-to-r from-amber-400 to-orange-800",
  "bg-gradient-to-r from-amber-400 to-orange-900",
  "bg-gradient-to-r from-amber-400 to-orange-950",
  "bg-gradient-to-r from-amber-400 to-yellow-300",
  "bg-gradient-to-r from-amber-400 to-yellow-400",
  "bg-gradient-to-r from-amber-400 to-yellow-500",
  "bg-gradient-to-r from-amber-400 to-yellow-600",
  "bg-gradient-to-r from-amber-400 to-yellow-700",
  "bg-gradient-to-r from-amber-400 to-yellow-800",
  "bg-gradient-to-r from-amber-400 to-yellow-900",
  "bg-gradient-to-r from-amber-400 to-yellow-950",
  "bg-gradient-to-r from-amber-400 to-lime-300",
  "bg-gradient-to-r from-amber-400 to-lime-400",
  "bg-gradient-to-r from-amber-400 to-lime-500",
  "bg-gradient-to-r from-amber-400 to-lime-600",
  "bg-gradient-to-r from-amber-400 to-lime-700",
  "bg-gradient-to-r from-amber-400 to-lime-800",
  "bg-gradient-to-r from-amber-400 to-lime-900",
  "bg-gradient-to-r from-amber-400 to-lime-950",
  "bg-gradient-to-r from-amber-400 to-green-300",
  "bg-gradient-to-r from-amber-400 to-green-400",
  "bg-gradient-to-r from-amber-400 to-green-500",
  "bg-gradient-to-r from-amber-400 to-green-600",
  "bg-gradient-to-r from-amber-400 to-green-700",
  "bg-gradient-to-r from-amber-400 to-green-800",
  "bg-gradient-to-r from-amber-400 to-green-900",
  "bg-gradient-to-r from-amber-400 to-green-950",
  "bg-gradient-to-r from-amber-400 to-emerald-300",
  "bg-gradient-to-r from-amber-400 to-emerald-400",
  "bg-gradient-to-r from-amber-400 to-emerald-500",
  "bg-gradient-to-r from-amber-400 to-emerald-600",
  "bg-gradient-to-r from-amber-400 to-emerald-700",
  "bg-gradient-to-r from-amber-400 to-emerald-800",
  "bg-gradient-to-r from-amber-400 to-emerald-900",
  "bg-gradient-to-r from-amber-400 to-emerald-950",
  "bg-gradient-to-r from-amber-400 to-teal-300",
  "bg-gradient-to-r from-amber-400 to-teal-400",
  "bg-gradient-to-r from-amber-400 to-teal-500",
  "bg-gradient-to-r from-amber-400 to-teal-600",
  "bg-gradient-to-r from-amber-400 to-teal-700",
  "bg-gradient-to-r from-amber-400 to-teal-800",
  "bg-gradient-to-r from-amber-400 to-teal-900",
  "bg-gradient-to-r from-amber-400 to-teal-950",
  "bg-gradient-to-r from-amber-400 to-cyan-300",
  "bg-gradient-to-r from-amber-400 to-cyan-400",
  "bg-gradient-to-r from-amber-400 to-cyan-500",
  "bg-gradient-to-r from-amber-400 to-cyan-600",
  "bg-gradient-to-r from-amber-400 to-cyan-700",
  "bg-gradient-to-r from-amber-400 to-cyan-800",
  "bg-gradient-to-r from-amber-400 to-cyan-900",
  "bg-gradient-to-r from-amber-400 to-cyan-950",
  "bg-gradient-to-r from-amber-400 to-sky-300",
  "bg-gradient-to-r from-amber-400 to-sky-400",
  "bg-gradient-to-r from-amber-400 to-sky-500",
  "bg-gradient-to-r from-amber-400 to-sky-600",
  "bg-gradient-to-r from-amber-400 to-sky-700",
  "bg-gradient-to-r from-amber-400 to-sky-800",
  "bg-gradient-to-r from-amber-400 to-sky-900",
  "bg-gradient-to-r from-amber-400 to-sky-950",
  "bg-gradient-to-r from-amber-400 to-blue-300",
  "bg-gradient-to-r from-amber-400 to-blue-400",
  "bg-gradient-to-r from-amber-400 to-blue-500",
  "bg-gradient-to-r from-amber-400 to-blue-600",
  "bg-gradient-to-r from-amber-400 to-blue-700",
  "bg-gradient-to-r from-amber-400 to-blue-800",
  "bg-gradient-to-r from-amber-400 to-blue-900",
  "bg-gradient-to-r from-amber-400 to-blue-950",
  "bg-gradient-to-r from-amber-400 to-indigo-300",
  "bg-gradient-to-r from-amber-400 to-indigo-400",
  "bg-gradient-to-r from-amber-400 to-indigo-500",
  "bg-gradient-to-r from-amber-400 to-indigo-600",
  "bg-gradient-to-r from-amber-400 to-indigo-700",
  "bg-gradient-to-r from-amber-400 to-indigo-800",
  "bg-gradient-to-r from-amber-400 to-indigo-900",
  "bg-gradient-to-r from-amber-400 to-indigo-950",
  "bg-gradient-to-r from-amber-400 to-violet-300",
  "bg-gradient-to-r from-amber-400 to-violet-400",
  "bg-gradient-to-r from-amber-400 to-violet-500",
  "bg-gradient-to-r from-amber-400 to-violet-600",
  "bg-gradient-to-r from-amber-400 to-violet-700",
  "bg-gradient-to-r from-amber-400 to-violet-800",
  "bg-gradient-to-r from-amber-400 to-violet-900",
  "bg-gradient-to-r from-amber-400 to-violet-950",
  "bg-gradient-to-r from-amber-400 to-purple-300",
  "bg-gradient-to-r from-amber-400 to-purple-400",
  "bg-gradient-to-r from-amber-400 to-purple-500",
  "bg-gradient-to-r from-amber-400 to-purple-600",
  "bg-gradient-to-r from-amber-400 to-purple-700",
  "bg-gradient-to-r from-amber-400 to-purple-800",
  "bg-gradient-to-r from-amber-400 to-purple-900",
  "bg-gradient-to-r from-amber-400 to-purple-950",
  "bg-gradient-to-r from-amber-400 to-fuchsia-300",
  "bg-gradient-to-r from-amber-400 to-fuchsia-400",
  "bg-gradient-to-r from-amber-400 to-fuchsia-500",
  "bg-gradient-to-r from-amber-400 to-fuchsia-600",
  "bg-gradient-to-r from-amber-400 to-fuchsia-700",
  "bg-gradient-to-r from-amber-400 to-fuchsia-800",
  "bg-gradient-to-r from-amber-400 to-fuchsia-900",
  "bg-gradient-to-r from-amber-400 to-fuchsia-950",
  "bg-gradient-to-r from-amber-400 to-pink-300",
  "bg-gradient-to-r from-amber-400 to-pink-400",
  "bg-gradient-to-r from-amber-400 to-pink-500",
  "bg-gradient-to-r from-amber-400 to-pink-600",
  "bg-gradient-to-r from-amber-400 to-pink-700",
  "bg-gradient-to-r from-amber-400 to-pink-800",
  "bg-gradient-to-r from-amber-400 to-pink-900",
  "bg-gradient-to-r from-amber-400 to-pink-950",
  "bg-gradient-to-r from-amber-400 to-rose-300",
  "bg-gradient-to-r from-amber-400 to-rose-400",
  "bg-gradient-to-r from-amber-400 to-rose-500",
  "bg-gradient-to-r from-amber-400 to-rose-600",
  "bg-gradient-to-r from-amber-400 to-rose-700",
  "bg-gradient-to-r from-amber-400 to-rose-800",
  "bg-gradient-to-r from-amber-400 to-rose-900",
  "bg-gradient-to-r from-amber-400 to-rose-950",
  "bg-gradient-to-r from-amber-500 to-red-300",
  "bg-gradient-to-r from-amber-500 to-red-400",
  "bg-gradient-to-r from-amber-500 to-red-500",
  "bg-gradient-to-r from-amber-500 to-red-600",
  "bg-gradient-to-r from-amber-500 to-red-700",
  "bg-gradient-to-r from-amber-500 to-red-800",
  "bg-gradient-to-r from-amber-500 to-red-900",
  "bg-gradient-to-r from-amber-500 to-red-950",
  "bg-gradient-to-r from-amber-500 to-orange-300",
  "bg-gradient-to-r from-amber-500 to-orange-400",
  "bg-gradient-to-r from-amber-500 to-orange-500",
  "bg-gradient-to-r from-amber-500 to-orange-600",
  "bg-gradient-to-r from-amber-500 to-orange-700",
  "bg-gradient-to-r from-amber-500 to-orange-800",
  "bg-gradient-to-r from-amber-500 to-orange-900",
  "bg-gradient-to-r from-amber-500 to-orange-950",
  "bg-gradient-to-r from-amber-500 to-yellow-300",
  "bg-gradient-to-r from-amber-500 to-yellow-400",
  "bg-gradient-to-r from-amber-500 to-yellow-500",
  "bg-gradient-to-r from-amber-500 to-yellow-600",
  "bg-gradient-to-r from-amber-500 to-yellow-700",
  "bg-gradient-to-r from-amber-500 to-yellow-800",
  "bg-gradient-to-r from-amber-500 to-yellow-900",
  "bg-gradient-to-r from-amber-500 to-yellow-950",
  "bg-gradient-to-r from-amber-500 to-lime-300",
  "bg-gradient-to-r from-amber-500 to-lime-400",
  "bg-gradient-to-r from-amber-500 to-lime-500",
  "bg-gradient-to-r from-amber-500 to-lime-600",
  "bg-gradient-to-r from-amber-500 to-lime-700",
  "bg-gradient-to-r from-amber-500 to-lime-800",
  "bg-gradient-to-r from-amber-500 to-lime-900",
  "bg-gradient-to-r from-amber-500 to-lime-950",
  "bg-gradient-to-r from-amber-500 to-green-300",
  "bg-gradient-to-r from-amber-500 to-green-400",
  "bg-gradient-to-r from-amber-500 to-green-500",
  "bg-gradient-to-r from-amber-500 to-green-600",
  "bg-gradient-to-r from-amber-500 to-green-700",
  "bg-gradient-to-r from-amber-500 to-green-800",
  "bg-gradient-to-r from-amber-500 to-green-900",
  "bg-gradient-to-r from-amber-500 to-green-950",
  "bg-gradient-to-r from-amber-500 to-emerald-300",
  "bg-gradient-to-r from-amber-500 to-emerald-400",
  "bg-gradient-to-r from-amber-500 to-emerald-500",
  "bg-gradient-to-r from-amber-500 to-emerald-600",
  "bg-gradient-to-r from-amber-500 to-emerald-700",
  "bg-gradient-to-r from-amber-500 to-emerald-800",
  "bg-gradient-to-r from-amber-500 to-emerald-900",
  "bg-gradient-to-r from-amber-500 to-emerald-950",
  "bg-gradient-to-r from-amber-500 to-teal-300",
  "bg-gradient-to-r from-amber-500 to-teal-400",
  "bg-gradient-to-r from-amber-500 to-teal-500",
  "bg-gradient-to-r from-amber-500 to-teal-600",
  "bg-gradient-to-r from-amber-500 to-teal-700",
  "bg-gradient-to-r from-amber-500 to-teal-800",
  "bg-gradient-to-r from-amber-500 to-teal-900",
  "bg-gradient-to-r from-amber-500 to-teal-950",
  "bg-gradient-to-r from-amber-500 to-cyan-300",
  "bg-gradient-to-r from-amber-500 to-cyan-400",
  "bg-gradient-to-r from-amber-500 to-cyan-500",
  "bg-gradient-to-r from-amber-500 to-cyan-600",
  "bg-gradient-to-r from-amber-500 to-cyan-700",
  "bg-gradient-to-r from-amber-500 to-cyan-800",
  "bg-gradient-to-r from-amber-500 to-cyan-900",
  "bg-gradient-to-r from-amber-500 to-cyan-950",
  "bg-gradient-to-r from-amber-500 to-sky-300",
  "bg-gradient-to-r from-amber-500 to-sky-400",
  "bg-gradient-to-r from-amber-500 to-sky-500",
  "bg-gradient-to-r from-amber-500 to-sky-600",
  "bg-gradient-to-r from-amber-500 to-sky-700",
  "bg-gradient-to-r from-amber-500 to-sky-800",
  "bg-gradient-to-r from-amber-500 to-sky-900",
  "bg-gradient-to-r from-amber-500 to-sky-950",
  "bg-gradient-to-r from-amber-500 to-blue-300",
  "bg-gradient-to-r from-amber-500 to-blue-400",
  "bg-gradient-to-r from-amber-500 to-blue-500",
  "bg-gradient-to-r from-amber-500 to-blue-600",
  "bg-gradient-to-r from-amber-500 to-blue-700",
  "bg-gradient-to-r from-amber-500 to-blue-800",
  "bg-gradient-to-r from-amber-500 to-blue-900",
  "bg-gradient-to-r from-amber-500 to-blue-950",
  "bg-gradient-to-r from-amber-500 to-indigo-300",
  "bg-gradient-to-r from-amber-500 to-indigo-400",
  "bg-gradient-to-r from-amber-500 to-indigo-500",
  "bg-gradient-to-r from-amber-500 to-indigo-600",
  "bg-gradient-to-r from-amber-500 to-indigo-700",
  "bg-gradient-to-r from-amber-500 to-indigo-800",
  "bg-gradient-to-r from-amber-500 to-indigo-900",
  "bg-gradient-to-r from-amber-500 to-indigo-950",
  "bg-gradient-to-r from-amber-500 to-violet-300",
  "bg-gradient-to-r from-amber-500 to-violet-400",
  "bg-gradient-to-r from-amber-500 to-violet-500",
  "bg-gradient-to-r from-amber-500 to-violet-600",
  "bg-gradient-to-r from-amber-500 to-violet-700",
  "bg-gradient-to-r from-amber-500 to-violet-800",
  "bg-gradient-to-r from-amber-500 to-violet-900",
  "bg-gradient-to-r from-amber-500 to-violet-950",
  "bg-gradient-to-r from-amber-500 to-purple-300",
  "bg-gradient-to-r from-amber-500 to-purple-400",
  "bg-gradient-to-r from-amber-500 to-purple-500",
  "bg-gradient-to-r from-amber-500 to-purple-600",
  "bg-gradient-to-r from-amber-500 to-purple-700",
  "bg-gradient-to-r from-amber-500 to-purple-800",
  "bg-gradient-to-r from-amber-500 to-purple-900",
  "bg-gradient-to-r from-amber-500 to-purple-950",
  "bg-gradient-to-r from-amber-500 to-fuchsia-300",
  "bg-gradient-to-r from-amber-500 to-fuchsia-400",
  "bg-gradient-to-r from-amber-500 to-fuchsia-500",
  "bg-gradient-to-r from-amber-500 to-fuchsia-600",
  "bg-gradient-to-r from-amber-500 to-fuchsia-700",
  "bg-gradient-to-r from-amber-500 to-fuchsia-800",
  "bg-gradient-to-r from-amber-500 to-fuchsia-900",
  "bg-gradient-to-r from-amber-500 to-fuchsia-950",
  "bg-gradient-to-r from-amber-500 to-pink-300",
  "bg-gradient-to-r from-amber-500 to-pink-400",
  "bg-gradient-to-r from-amber-500 to-pink-500",
  "bg-gradient-to-r from-amber-500 to-pink-600",
  "bg-gradient-to-r from-amber-500 to-pink-700",
  "bg-gradient-to-r from-amber-500 to-pink-800",
  "bg-gradient-to-r from-amber-500 to-pink-900",
  "bg-gradient-to-r from-amber-500 to-pink-950",
  "bg-gradient-to-r from-amber-500 to-rose-300",
  "bg-gradient-to-r from-amber-500 to-rose-400",
  "bg-gradient-to-r from-amber-500 to-rose-500",
  "bg-gradient-to-r from-amber-500 to-rose-600",
  "bg-gradient-to-r from-amber-500 to-rose-700",
  "bg-gradient-to-r from-amber-500 to-rose-800",
  "bg-gradient-to-r from-amber-500 to-rose-900",
  "bg-gradient-to-r from-amber-500 to-rose-950",
  "bg-gradient-to-r from-amber-600 to-red-300",
  "bg-gradient-to-r from-amber-600 to-red-400",
  "bg-gradient-to-r from-amber-600 to-red-500",
  "bg-gradient-to-r from-amber-600 to-red-600",
  "bg-gradient-to-r from-amber-600 to-red-700",
  "bg-gradient-to-r from-amber-600 to-red-800",
  "bg-gradient-to-r from-amber-600 to-red-900",
  "bg-gradient-to-r from-amber-600 to-red-950",
  "bg-gradient-to-r from-amber-600 to-orange-300",
  "bg-gradient-to-r from-amber-600 to-orange-400",
  "bg-gradient-to-r from-amber-600 to-orange-500",
  "bg-gradient-to-r from-amber-600 to-orange-600",
  "bg-gradient-to-r from-amber-600 to-orange-700",
  "bg-gradient-to-r from-amber-600 to-orange-800",
  "bg-gradient-to-r from-amber-600 to-orange-900",
  "bg-gradient-to-r from-amber-600 to-orange-950",
  "bg-gradient-to-r from-amber-600 to-yellow-300",
  "bg-gradient-to-r from-amber-600 to-yellow-400",
  "bg-gradient-to-r from-amber-600 to-yellow-500",
  "bg-gradient-to-r from-amber-600 to-yellow-600",
  "bg-gradient-to-r from-amber-600 to-yellow-700",
  "bg-gradient-to-r from-amber-600 to-yellow-800",
  "bg-gradient-to-r from-amber-600 to-yellow-900",
  "bg-gradient-to-r from-amber-600 to-yellow-950",
  "bg-gradient-to-r from-amber-600 to-lime-300",
  "bg-gradient-to-r from-amber-600 to-lime-400",
  "bg-gradient-to-r from-amber-600 to-lime-500",
  "bg-gradient-to-r from-amber-600 to-lime-600",
  "bg-gradient-to-r from-amber-600 to-lime-700",
  "bg-gradient-to-r from-amber-600 to-lime-800",
  "bg-gradient-to-r from-amber-600 to-lime-900",
  "bg-gradient-to-r from-amber-600 to-lime-950",
  "bg-gradient-to-r from-amber-600 to-green-300",
  "bg-gradient-to-r from-amber-600 to-green-400",
  "bg-gradient-to-r from-amber-600 to-green-500",
  "bg-gradient-to-r from-amber-600 to-green-600",
  "bg-gradient-to-r from-amber-600 to-green-700",
  "bg-gradient-to-r from-amber-600 to-green-800",
  "bg-gradient-to-r from-amber-600 to-green-900",
  "bg-gradient-to-r from-amber-600 to-green-950",
  "bg-gradient-to-r from-amber-600 to-emerald-300",
  "bg-gradient-to-r from-amber-600 to-emerald-400",
  "bg-gradient-to-r from-amber-600 to-emerald-500",
  "bg-gradient-to-r from-amber-600 to-emerald-600",
  "bg-gradient-to-r from-amber-600 to-emerald-700",
  "bg-gradient-to-r from-amber-600 to-emerald-800",
  "bg-gradient-to-r from-amber-600 to-emerald-900",
  "bg-gradient-to-r from-amber-600 to-emerald-950",
  "bg-gradient-to-r from-amber-600 to-teal-300",
  "bg-gradient-to-r from-amber-600 to-teal-400",
  "bg-gradient-to-r from-amber-600 to-teal-500",
  "bg-gradient-to-r from-amber-600 to-teal-600",
  "bg-gradient-to-r from-amber-600 to-teal-700",
  "bg-gradient-to-r from-amber-600 to-teal-800",
  "bg-gradient-to-r from-amber-600 to-teal-900",
  "bg-gradient-to-r from-amber-600 to-teal-950",
  "bg-gradient-to-r from-amber-600 to-cyan-300",
  "bg-gradient-to-r from-amber-600 to-cyan-400",
  "bg-gradient-to-r from-amber-600 to-cyan-500",
  "bg-gradient-to-r from-amber-600 to-cyan-600",
  "bg-gradient-to-r from-amber-600 to-cyan-700",
  "bg-gradient-to-r from-amber-600 to-cyan-800",
  "bg-gradient-to-r from-amber-600 to-cyan-900",
  "bg-gradient-to-r from-amber-600 to-cyan-950",
  "bg-gradient-to-r from-amber-600 to-sky-300",
  "bg-gradient-to-r from-amber-600 to-sky-400",
  "bg-gradient-to-r from-amber-600 to-sky-500",
  "bg-gradient-to-r from-amber-600 to-sky-600",
  "bg-gradient-to-r from-amber-600 to-sky-700",
  "bg-gradient-to-r from-amber-600 to-sky-800",
  "bg-gradient-to-r from-amber-600 to-sky-900",
  "bg-gradient-to-r from-amber-600 to-sky-950",
  "bg-gradient-to-r from-amber-600 to-blue-300",
  "bg-gradient-to-r from-amber-600 to-blue-400",
  "bg-gradient-to-r from-amber-600 to-blue-500",
  "bg-gradient-to-r from-amber-600 to-blue-600",
  "bg-gradient-to-r from-amber-600 to-blue-700",
  "bg-gradient-to-r from-amber-600 to-blue-800",
  "bg-gradient-to-r from-amber-600 to-blue-900",
  "bg-gradient-to-r from-amber-600 to-blue-950",
  "bg-gradient-to-r from-amber-600 to-indigo-300",
  "bg-gradient-to-r from-amber-600 to-indigo-400",
  "bg-gradient-to-r from-amber-600 to-indigo-500",
  "bg-gradient-to-r from-amber-600 to-indigo-600",
  "bg-gradient-to-r from-amber-600 to-indigo-700",
  "bg-gradient-to-r from-amber-600 to-indigo-800",
  "bg-gradient-to-r from-amber-600 to-indigo-900",
  "bg-gradient-to-r from-amber-600 to-indigo-950",
  "bg-gradient-to-r from-amber-600 to-violet-300",
  "bg-gradient-to-r from-amber-600 to-violet-400",
  "bg-gradient-to-r from-amber-600 to-violet-500",
  "bg-gradient-to-r from-amber-600 to-violet-600",
  "bg-gradient-to-r from-amber-600 to-violet-700",
  "bg-gradient-to-r from-amber-600 to-violet-800",
  "bg-gradient-to-r from-amber-600 to-violet-900",
  "bg-gradient-to-r from-amber-600 to-violet-950",
  "bg-gradient-to-r from-amber-600 to-purple-300",
  "bg-gradient-to-r from-amber-600 to-purple-400",
  "bg-gradient-to-r from-amber-600 to-purple-500",
  "bg-gradient-to-r from-amber-600 to-purple-600",
  "bg-gradient-to-r from-amber-600 to-purple-700",
  "bg-gradient-to-r from-amber-600 to-purple-800",
  "bg-gradient-to-r from-amber-600 to-purple-900",
  "bg-gradient-to-r from-amber-600 to-purple-950",
  "bg-gradient-to-r from-amber-600 to-fuchsia-300",
  "bg-gradient-to-r from-amber-600 to-fuchsia-400",
  "bg-gradient-to-r from-amber-600 to-fuchsia-500",
  "bg-gradient-to-r from-amber-600 to-fuchsia-600",
  "bg-gradient-to-r from-amber-600 to-fuchsia-700",
  "bg-gradient-to-r from-amber-600 to-fuchsia-800",
  "bg-gradient-to-r from-amber-600 to-fuchsia-900",
  "bg-gradient-to-r from-amber-600 to-fuchsia-950",
  "bg-gradient-to-r from-amber-600 to-pink-300",
  "bg-gradient-to-r from-amber-600 to-pink-400",
  "bg-gradient-to-r from-amber-600 to-pink-500",
  "bg-gradient-to-r from-amber-600 to-pink-600",
  "bg-gradient-to-r from-amber-600 to-pink-700",
  "bg-gradient-to-r from-amber-600 to-pink-800",
  "bg-gradient-to-r from-amber-600 to-pink-900",
  "bg-gradient-to-r from-amber-600 to-pink-950",
  "bg-gradient-to-r from-amber-600 to-rose-300",
  "bg-gradient-to-r from-amber-600 to-rose-400",
  "bg-gradient-to-r from-amber-600 to-rose-500",
  "bg-gradient-to-r from-amber-600 to-rose-600",
  "bg-gradient-to-r from-amber-600 to-rose-700",
  "bg-gradient-to-r from-amber-600 to-rose-800",
  "bg-gradient-to-r from-amber-600 to-rose-900",
  "bg-gradient-to-r from-amber-600 to-rose-950",
  "bg-gradient-to-r from-amber-700 to-red-300",
  "bg-gradient-to-r from-amber-700 to-red-400",
  "bg-gradient-to-r from-amber-700 to-red-500",
  "bg-gradient-to-r from-amber-700 to-red-600",
  "bg-gradient-to-r from-amber-700 to-red-700",
  "bg-gradient-to-r from-amber-700 to-red-800",
  "bg-gradient-to-r from-amber-700 to-red-900",
  "bg-gradient-to-r from-amber-700 to-red-950",
  "bg-gradient-to-r from-amber-700 to-orange-300",
  "bg-gradient-to-r from-amber-700 to-orange-400",
  "bg-gradient-to-r from-amber-700 to-orange-500",
  "bg-gradient-to-r from-amber-700 to-orange-600",
  "bg-gradient-to-r from-amber-700 to-orange-700",
  "bg-gradient-to-r from-amber-700 to-orange-800",
  "bg-gradient-to-r from-amber-700 to-orange-900",
  "bg-gradient-to-r from-amber-700 to-orange-950",
  "bg-gradient-to-r from-amber-700 to-yellow-300",
  "bg-gradient-to-r from-amber-700 to-yellow-400",
  "bg-gradient-to-r from-amber-700 to-yellow-500",
  "bg-gradient-to-r from-amber-700 to-yellow-600",
  "bg-gradient-to-r from-amber-700 to-yellow-700",
  "bg-gradient-to-r from-amber-700 to-yellow-800",
  "bg-gradient-to-r from-amber-700 to-yellow-900",
  "bg-gradient-to-r from-amber-700 to-yellow-950",
  "bg-gradient-to-r from-amber-700 to-lime-300",
  "bg-gradient-to-r from-amber-700 to-lime-400",
  "bg-gradient-to-r from-amber-700 to-lime-500",
  "bg-gradient-to-r from-amber-700 to-lime-600",
  "bg-gradient-to-r from-amber-700 to-lime-700",
  "bg-gradient-to-r from-amber-700 to-lime-800",
  "bg-gradient-to-r from-amber-700 to-lime-900",
  "bg-gradient-to-r from-amber-700 to-lime-950",
  "bg-gradient-to-r from-amber-700 to-green-300",
  "bg-gradient-to-r from-amber-700 to-green-400",
  "bg-gradient-to-r from-amber-700 to-green-500",
  "bg-gradient-to-r from-amber-700 to-green-600",
  "bg-gradient-to-r from-amber-700 to-green-700",
  "bg-gradient-to-r from-amber-700 to-green-800",
  "bg-gradient-to-r from-amber-700 to-green-900",
  "bg-gradient-to-r from-amber-700 to-green-950",
  "bg-gradient-to-r from-amber-700 to-emerald-300",
  "bg-gradient-to-r from-amber-700 to-emerald-400",
  "bg-gradient-to-r from-amber-700 to-emerald-500",
  "bg-gradient-to-r from-amber-700 to-emerald-600",
  "bg-gradient-to-r from-amber-700 to-emerald-700",
  "bg-gradient-to-r from-amber-700 to-emerald-800",
  "bg-gradient-to-r from-amber-700 to-emerald-900",
  "bg-gradient-to-r from-amber-700 to-emerald-950",
  "bg-gradient-to-r from-amber-700 to-teal-300",
  "bg-gradient-to-r from-amber-700 to-teal-400",
  "bg-gradient-to-r from-amber-700 to-teal-500",
  "bg-gradient-to-r from-amber-700 to-teal-600",
  "bg-gradient-to-r from-amber-700 to-teal-700",
  "bg-gradient-to-r from-amber-700 to-teal-800",
  "bg-gradient-to-r from-amber-700 to-teal-900",
  "bg-gradient-to-r from-amber-700 to-teal-950",
  "bg-gradient-to-r from-amber-700 to-cyan-300",
  "bg-gradient-to-r from-amber-700 to-cyan-400",
  "bg-gradient-to-r from-amber-700 to-cyan-500",
  "bg-gradient-to-r from-amber-700 to-cyan-600",
  "bg-gradient-to-r from-amber-700 to-cyan-700",
  "bg-gradient-to-r from-amber-700 to-cyan-800",
  "bg-gradient-to-r from-amber-700 to-cyan-900",
  "bg-gradient-to-r from-amber-700 to-cyan-950",
  "bg-gradient-to-r from-amber-700 to-sky-300",
  "bg-gradient-to-r from-amber-700 to-sky-400",
  "bg-gradient-to-r from-amber-700 to-sky-500",
  "bg-gradient-to-r from-amber-700 to-sky-600",
  "bg-gradient-to-r from-amber-700 to-sky-700",
  "bg-gradient-to-r from-amber-700 to-sky-800",
  "bg-gradient-to-r from-amber-700 to-sky-900",
  "bg-gradient-to-r from-amber-700 to-sky-950",
  "bg-gradient-to-r from-amber-700 to-blue-300",
  "bg-gradient-to-r from-amber-700 to-blue-400",
  "bg-gradient-to-r from-amber-700 to-blue-500",
  "bg-gradient-to-r from-amber-700 to-blue-600",
  "bg-gradient-to-r from-amber-700 to-blue-700",
  "bg-gradient-to-r from-amber-700 to-blue-800",
  "bg-gradient-to-r from-amber-700 to-blue-900",
  "bg-gradient-to-r from-amber-700 to-blue-950",
  "bg-gradient-to-r from-amber-700 to-indigo-300",
  "bg-gradient-to-r from-amber-700 to-indigo-400",
  "bg-gradient-to-r from-amber-700 to-indigo-500",
  "bg-gradient-to-r from-amber-700 to-indigo-600",
  "bg-gradient-to-r from-amber-700 to-indigo-700",
  "bg-gradient-to-r from-amber-700 to-indigo-800",
  "bg-gradient-to-r from-amber-700 to-indigo-900",
  "bg-gradient-to-r from-amber-700 to-indigo-950",
  "bg-gradient-to-r from-amber-700 to-violet-300",
  "bg-gradient-to-r from-amber-700 to-violet-400",
  "bg-gradient-to-r from-amber-700 to-violet-500",
  "bg-gradient-to-r from-amber-700 to-violet-600",
  "bg-gradient-to-r from-amber-700 to-violet-700",
  "bg-gradient-to-r from-amber-700 to-violet-800",
  "bg-gradient-to-r from-amber-700 to-violet-900",
  "bg-gradient-to-r from-amber-700 to-violet-950",
  "bg-gradient-to-r from-amber-700 to-purple-300",
  "bg-gradient-to-r from-amber-700 to-purple-400",
  "bg-gradient-to-r from-amber-700 to-purple-500",
  "bg-gradient-to-r from-amber-700 to-purple-600",
  "bg-gradient-to-r from-amber-700 to-purple-700",
  "bg-gradient-to-r from-amber-700 to-purple-800",
  "bg-gradient-to-r from-amber-700 to-purple-900",
  "bg-gradient-to-r from-amber-700 to-purple-950",
  "bg-gradient-to-r from-amber-700 to-fuchsia-300",
  "bg-gradient-to-r from-amber-700 to-fuchsia-400",
  "bg-gradient-to-r from-amber-700 to-fuchsia-500",
  "bg-gradient-to-r from-amber-700 to-fuchsia-600",
  "bg-gradient-to-r from-amber-700 to-fuchsia-700",
  "bg-gradient-to-r from-amber-700 to-fuchsia-800",
  "bg-gradient-to-r from-amber-700 to-fuchsia-900",
  "bg-gradient-to-r from-amber-700 to-fuchsia-950",
  "bg-gradient-to-r from-amber-700 to-pink-300",
  "bg-gradient-to-r from-amber-700 to-pink-400",
  "bg-gradient-to-r from-amber-700 to-pink-500",
  "bg-gradient-to-r from-amber-700 to-pink-600",
  "bg-gradient-to-r from-amber-700 to-pink-700",
  "bg-gradient-to-r from-amber-700 to-pink-800",
  "bg-gradient-to-r from-amber-700 to-pink-900",
  "bg-gradient-to-r from-amber-700 to-pink-950",
  "bg-gradient-to-r from-amber-700 to-rose-300",
  "bg-gradient-to-r from-amber-700 to-rose-400",
  "bg-gradient-to-r from-amber-700 to-rose-500",
  "bg-gradient-to-r from-amber-700 to-rose-600",
  "bg-gradient-to-r from-amber-700 to-rose-700",
  "bg-gradient-to-r from-amber-700 to-rose-800",
  "bg-gradient-to-r from-amber-700 to-rose-900",
  "bg-gradient-to-r from-amber-700 to-rose-950",
  "bg-gradient-to-r from-amber-800 to-red-300",
  "bg-gradient-to-r from-amber-800 to-red-400",
  "bg-gradient-to-r from-amber-800 to-red-500",
  "bg-gradient-to-r from-amber-800 to-red-600",
  "bg-gradient-to-r from-amber-800 to-red-700",
  "bg-gradient-to-r from-amber-800 to-red-800",
  "bg-gradient-to-r from-amber-800 to-red-900",
  "bg-gradient-to-r from-amber-800 to-red-950",
  "bg-gradient-to-r from-amber-800 to-orange-300",
  "bg-gradient-to-r from-amber-800 to-orange-400",
  "bg-gradient-to-r from-amber-800 to-orange-500",
  "bg-gradient-to-r from-amber-800 to-orange-600",
  "bg-gradient-to-r from-amber-800 to-orange-700",
  "bg-gradient-to-r from-amber-800 to-orange-800",
  "bg-gradient-to-r from-amber-800 to-orange-900",
  "bg-gradient-to-r from-amber-800 to-orange-950",
  "bg-gradient-to-r from-amber-800 to-yellow-300",
  "bg-gradient-to-r from-amber-800 to-yellow-400",
  "bg-gradient-to-r from-amber-800 to-yellow-500",
  "bg-gradient-to-r from-amber-800 to-yellow-600",
  "bg-gradient-to-r from-amber-800 to-yellow-700",
  "bg-gradient-to-r from-amber-800 to-yellow-800",
  "bg-gradient-to-r from-amber-800 to-yellow-900",
  "bg-gradient-to-r from-amber-800 to-yellow-950",
  "bg-gradient-to-r from-amber-800 to-lime-300",
  "bg-gradient-to-r from-amber-800 to-lime-400",
  "bg-gradient-to-r from-amber-800 to-lime-500",
  "bg-gradient-to-r from-amber-800 to-lime-600",
  "bg-gradient-to-r from-amber-800 to-lime-700",
  "bg-gradient-to-r from-amber-800 to-lime-800",
  "bg-gradient-to-r from-amber-800 to-lime-900",
  "bg-gradient-to-r from-amber-800 to-lime-950",
  "bg-gradient-to-r from-amber-800 to-green-300",
  "bg-gradient-to-r from-amber-800 to-green-400",
  "bg-gradient-to-r from-amber-800 to-green-500",
  "bg-gradient-to-r from-amber-800 to-green-600",
  "bg-gradient-to-r from-amber-800 to-green-700",
  "bg-gradient-to-r from-amber-800 to-green-800",
  "bg-gradient-to-r from-amber-800 to-green-900",
  "bg-gradient-to-r from-amber-800 to-green-950",
  "bg-gradient-to-r from-amber-800 to-emerald-300",
  "bg-gradient-to-r from-amber-800 to-emerald-400",
  "bg-gradient-to-r from-amber-800 to-emerald-500",
  "bg-gradient-to-r from-amber-800 to-emerald-600",
  "bg-gradient-to-r from-amber-800 to-emerald-700",
  "bg-gradient-to-r from-amber-800 to-emerald-800",
  "bg-gradient-to-r from-amber-800 to-emerald-900",
  "bg-gradient-to-r from-amber-800 to-emerald-950",
  "bg-gradient-to-r from-amber-800 to-teal-300",
  "bg-gradient-to-r from-amber-800 to-teal-400",
  "bg-gradient-to-r from-amber-800 to-teal-500",
  "bg-gradient-to-r from-amber-800 to-teal-600",
  "bg-gradient-to-r from-amber-800 to-teal-700",
  "bg-gradient-to-r from-amber-800 to-teal-800",
  "bg-gradient-to-r from-amber-800 to-teal-900",
  "bg-gradient-to-r from-amber-800 to-teal-950",
  "bg-gradient-to-r from-amber-800 to-cyan-300",
  "bg-gradient-to-r from-amber-800 to-cyan-400",
  "bg-gradient-to-r from-amber-800 to-cyan-500",
  "bg-gradient-to-r from-amber-800 to-cyan-600",
  "bg-gradient-to-r from-amber-800 to-cyan-700",
  "bg-gradient-to-r from-amber-800 to-cyan-800",
  "bg-gradient-to-r from-amber-800 to-cyan-900",
  "bg-gradient-to-r from-amber-800 to-cyan-950",
  "bg-gradient-to-r from-amber-800 to-sky-300",
  "bg-gradient-to-r from-amber-800 to-sky-400",
  "bg-gradient-to-r from-amber-800 to-sky-500",
  "bg-gradient-to-r from-amber-800 to-sky-600",
  "bg-gradient-to-r from-amber-800 to-sky-700",
  "bg-gradient-to-r from-amber-800 to-sky-800",
  "bg-gradient-to-r from-amber-800 to-sky-900",
  "bg-gradient-to-r from-amber-800 to-sky-950",
  "bg-gradient-to-r from-amber-800 to-blue-300",
  "bg-gradient-to-r from-amber-800 to-blue-400",
  "bg-gradient-to-r from-amber-800 to-blue-500",
  "bg-gradient-to-r from-amber-800 to-blue-600",
  "bg-gradient-to-r from-amber-800 to-blue-700",
  "bg-gradient-to-r from-amber-800 to-blue-800",
  "bg-gradient-to-r from-amber-800 to-blue-900",
  "bg-gradient-to-r from-amber-800 to-blue-950",
  "bg-gradient-to-r from-amber-800 to-indigo-300",
  "bg-gradient-to-r from-amber-800 to-indigo-400",
  "bg-gradient-to-r from-amber-800 to-indigo-500",
  "bg-gradient-to-r from-amber-800 to-indigo-600",
  "bg-gradient-to-r from-amber-800 to-indigo-700",
  "bg-gradient-to-r from-amber-800 to-indigo-800",
  "bg-gradient-to-r from-amber-800 to-indigo-900",
  "bg-gradient-to-r from-amber-800 to-indigo-950",
  "bg-gradient-to-r from-amber-800 to-violet-300",
  "bg-gradient-to-r from-amber-800 to-violet-400",
  "bg-gradient-to-r from-amber-800 to-violet-500",
  "bg-gradient-to-r from-amber-800 to-violet-600",
  "bg-gradient-to-r from-amber-800 to-violet-700",
  "bg-gradient-to-r from-amber-800 to-violet-800",
  "bg-gradient-to-r from-amber-800 to-violet-900",
  "bg-gradient-to-r from-amber-800 to-violet-950",
  "bg-gradient-to-r from-amber-800 to-purple-300",
  "bg-gradient-to-r from-amber-800 to-purple-400",
  "bg-gradient-to-r from-amber-800 to-purple-500",
  "bg-gradient-to-r from-amber-800 to-purple-600",
  "bg-gradient-to-r from-amber-800 to-purple-700",
  "bg-gradient-to-r from-amber-800 to-purple-800",
  "bg-gradient-to-r from-amber-800 to-purple-900",
  "bg-gradient-to-r from-amber-800 to-purple-950",
  "bg-gradient-to-r from-amber-800 to-fuchsia-300",
  "bg-gradient-to-r from-amber-800 to-fuchsia-400",
  "bg-gradient-to-r from-amber-800 to-fuchsia-500",
  "bg-gradient-to-r from-amber-800 to-fuchsia-600",
  "bg-gradient-to-r from-amber-800 to-fuchsia-700",
  "bg-gradient-to-r from-amber-800 to-fuchsia-800",
  "bg-gradient-to-r from-amber-800 to-fuchsia-900",
  "bg-gradient-to-r from-amber-800 to-fuchsia-950",
  "bg-gradient-to-r from-amber-800 to-pink-300",
  "bg-gradient-to-r from-amber-800 to-pink-400",
  "bg-gradient-to-r from-amber-800 to-pink-500",
  "bg-gradient-to-r from-amber-800 to-pink-600",
  "bg-gradient-to-r from-amber-800 to-pink-700",
  "bg-gradient-to-r from-amber-800 to-pink-800",
  "bg-gradient-to-r from-amber-800 to-pink-900",
  "bg-gradient-to-r from-amber-800 to-pink-950",
  "bg-gradient-to-r from-amber-800 to-rose-300",
  "bg-gradient-to-r from-amber-800 to-rose-400",
  "bg-gradient-to-r from-amber-800 to-rose-500",
  "bg-gradient-to-r from-amber-800 to-rose-600",
  "bg-gradient-to-r from-amber-800 to-rose-700",
  "bg-gradient-to-r from-amber-800 to-rose-800",
  "bg-gradient-to-r from-amber-800 to-rose-900",
  "bg-gradient-to-r from-amber-800 to-rose-950",
  "bg-gradient-to-r from-amber-900 to-red-300",
  "bg-gradient-to-r from-amber-900 to-red-400",
  "bg-gradient-to-r from-amber-900 to-red-500",
  "bg-gradient-to-r from-amber-900 to-red-600",
  "bg-gradient-to-r from-amber-900 to-red-700",
  "bg-gradient-to-r from-amber-900 to-red-800",
  "bg-gradient-to-r from-amber-900 to-red-900",
  "bg-gradient-to-r from-amber-900 to-red-950",
  "bg-gradient-to-r from-amber-900 to-orange-300",
  "bg-gradient-to-r from-amber-900 to-orange-400",
  "bg-gradient-to-r from-amber-900 to-orange-500",
  "bg-gradient-to-r from-amber-900 to-orange-600",
  "bg-gradient-to-r from-amber-900 to-orange-700",
  "bg-gradient-to-r from-amber-900 to-orange-800",
  "bg-gradient-to-r from-amber-900 to-orange-900",
  "bg-gradient-to-r from-amber-900 to-orange-950",
  "bg-gradient-to-r from-amber-900 to-yellow-300",
  "bg-gradient-to-r from-amber-900 to-yellow-400",
  "bg-gradient-to-r from-amber-900 to-yellow-500",
  "bg-gradient-to-r from-amber-900 to-yellow-600",
  "bg-gradient-to-r from-amber-900 to-yellow-700",
  "bg-gradient-to-r from-amber-900 to-yellow-800",
  "bg-gradient-to-r from-amber-900 to-yellow-900",
  "bg-gradient-to-r from-amber-900 to-yellow-950",
  "bg-gradient-to-r from-amber-900 to-lime-300",
  "bg-gradient-to-r from-amber-900 to-lime-400",
  "bg-gradient-to-r from-amber-900 to-lime-500",
  "bg-gradient-to-r from-amber-900 to-lime-600",
  "bg-gradient-to-r from-amber-900 to-lime-700",
  "bg-gradient-to-r from-amber-900 to-lime-800",
  "bg-gradient-to-r from-amber-900 to-lime-900",
  "bg-gradient-to-r from-amber-900 to-lime-950",
  "bg-gradient-to-r from-amber-900 to-green-300",
  "bg-gradient-to-r from-amber-900 to-green-400",
  "bg-gradient-to-r from-amber-900 to-green-500",
  "bg-gradient-to-r from-amber-900 to-green-600",
  "bg-gradient-to-r from-amber-900 to-green-700",
  "bg-gradient-to-r from-amber-900 to-green-800",
  "bg-gradient-to-r from-amber-900 to-green-900",
  "bg-gradient-to-r from-amber-900 to-green-950",
  "bg-gradient-to-r from-amber-900 to-emerald-300",
  "bg-gradient-to-r from-amber-900 to-emerald-400",
  "bg-gradient-to-r from-amber-900 to-emerald-500",
  "bg-gradient-to-r from-amber-900 to-emerald-600",
  "bg-gradient-to-r from-amber-900 to-emerald-700",
  "bg-gradient-to-r from-amber-900 to-emerald-800",
  "bg-gradient-to-r from-amber-900 to-emerald-900",
  "bg-gradient-to-r from-amber-900 to-emerald-950",
  "bg-gradient-to-r from-amber-900 to-teal-300",
  "bg-gradient-to-r from-amber-900 to-teal-400",
  "bg-gradient-to-r from-amber-900 to-teal-500",
  "bg-gradient-to-r from-amber-900 to-teal-600",
  "bg-gradient-to-r from-amber-900 to-teal-700",
  "bg-gradient-to-r from-amber-900 to-teal-800",
  "bg-gradient-to-r from-amber-900 to-teal-900",
  "bg-gradient-to-r from-amber-900 to-teal-950",
  "bg-gradient-to-r from-amber-900 to-cyan-300",
  "bg-gradient-to-r from-amber-900 to-cyan-400",
  "bg-gradient-to-r from-amber-900 to-cyan-500",
  "bg-gradient-to-r from-amber-900 to-cyan-600",
  "bg-gradient-to-r from-amber-900 to-cyan-700",
  "bg-gradient-to-r from-amber-900 to-cyan-800",
  "bg-gradient-to-r from-amber-900 to-cyan-900",
  "bg-gradient-to-r from-amber-900 to-cyan-950",
  "bg-gradient-to-r from-amber-900 to-sky-300",
  "bg-gradient-to-r from-amber-900 to-sky-400",
  "bg-gradient-to-r from-amber-900 to-sky-500",
  "bg-gradient-to-r from-amber-900 to-sky-600",
  "bg-gradient-to-r from-amber-900 to-sky-700",
  "bg-gradient-to-r from-amber-900 to-sky-800",
  "bg-gradient-to-r from-amber-900 to-sky-900",
  "bg-gradient-to-r from-amber-900 to-sky-950",
  "bg-gradient-to-r from-amber-900 to-blue-300",
  "bg-gradient-to-r from-amber-900 to-blue-400",
  "bg-gradient-to-r from-amber-900 to-blue-500",
  "bg-gradient-to-r from-amber-900 to-blue-600",
  "bg-gradient-to-r from-amber-900 to-blue-700",
  "bg-gradient-to-r from-amber-900 to-blue-800",
  "bg-gradient-to-r from-amber-900 to-blue-900",
  "bg-gradient-to-r from-amber-900 to-blue-950",
  "bg-gradient-to-r from-amber-900 to-indigo-300",
  "bg-gradient-to-r from-amber-900 to-indigo-400",
  "bg-gradient-to-r from-amber-900 to-indigo-500",
  "bg-gradient-to-r from-amber-900 to-indigo-600",
  "bg-gradient-to-r from-amber-900 to-indigo-700",
  "bg-gradient-to-r from-amber-900 to-indigo-800",
  "bg-gradient-to-r from-amber-900 to-indigo-900",
  "bg-gradient-to-r from-amber-900 to-indigo-950",
  "bg-gradient-to-r from-amber-900 to-violet-300",
  "bg-gradient-to-r from-amber-900 to-violet-400",
  "bg-gradient-to-r from-amber-900 to-violet-500",
  "bg-gradient-to-r from-amber-900 to-violet-600",
  "bg-gradient-to-r from-amber-900 to-violet-700",
  "bg-gradient-to-r from-amber-900 to-violet-800",
  "bg-gradient-to-r from-amber-900 to-violet-900",
  "bg-gradient-to-r from-amber-900 to-violet-950",
  "bg-gradient-to-r from-amber-900 to-purple-300",
  "bg-gradient-to-r from-amber-900 to-purple-400",
  "bg-gradient-to-r from-amber-900 to-purple-500",
  "bg-gradient-to-r from-amber-900 to-purple-600",
  "bg-gradient-to-r from-amber-900 to-purple-700",
  "bg-gradient-to-r from-amber-900 to-purple-800",
  "bg-gradient-to-r from-amber-900 to-purple-900",
  "bg-gradient-to-r from-amber-900 to-purple-950",
  "bg-gradient-to-r from-amber-900 to-fuchsia-300",
  "bg-gradient-to-r from-amber-900 to-fuchsia-400",
  "bg-gradient-to-r from-amber-900 to-fuchsia-500",
  "bg-gradient-to-r from-amber-900 to-fuchsia-600",
  "bg-gradient-to-r from-amber-900 to-fuchsia-700",
  "bg-gradient-to-r from-amber-900 to-fuchsia-800",
  "bg-gradient-to-r from-amber-900 to-fuchsia-900",
  "bg-gradient-to-r from-amber-900 to-fuchsia-950",
  "bg-gradient-to-r from-amber-900 to-pink-300",
  "bg-gradient-to-r from-amber-900 to-pink-400",
  "bg-gradient-to-r from-amber-900 to-pink-500",
  "bg-gradient-to-r from-amber-900 to-pink-600",
  "bg-gradient-to-r from-amber-900 to-pink-700",
  "bg-gradient-to-r from-amber-900 to-pink-800",
  "bg-gradient-to-r from-amber-900 to-pink-900",
  "bg-gradient-to-r from-amber-900 to-pink-950",
  "bg-gradient-to-r from-amber-900 to-rose-300",
  "bg-gradient-to-r from-amber-900 to-rose-400",
  "bg-gradient-to-r from-amber-900 to-rose-500",
  "bg-gradient-to-r from-amber-900 to-rose-600",
  "bg-gradient-to-r from-amber-900 to-rose-700",
  "bg-gradient-to-r from-amber-900 to-rose-800",
  "bg-gradient-to-r from-amber-900 to-rose-900",
  "bg-gradient-to-r from-amber-900 to-rose-950",
  "bg-gradient-to-r from-amber-950 to-red-300",
  "bg-gradient-to-r from-amber-950 to-red-400",
  "bg-gradient-to-r from-amber-950 to-red-500",
  "bg-gradient-to-r from-amber-950 to-red-600",
  "bg-gradient-to-r from-amber-950 to-red-700",
  "bg-gradient-to-r from-amber-950 to-red-800",
  "bg-gradient-to-r from-amber-950 to-red-900",
  "bg-gradient-to-r from-amber-950 to-red-950",
  "bg-gradient-to-r from-amber-950 to-orange-300",
  "bg-gradient-to-r from-amber-950 to-orange-400",
  "bg-gradient-to-r from-amber-950 to-orange-500",
  "bg-gradient-to-r from-amber-950 to-orange-600",
  "bg-gradient-to-r from-amber-950 to-orange-700",
  "bg-gradient-to-r from-amber-950 to-orange-800",
  "bg-gradient-to-r from-amber-950 to-orange-900",
  "bg-gradient-to-r from-amber-950 to-orange-950",
  "bg-gradient-to-r from-amber-950 to-yellow-300",
  "bg-gradient-to-r from-amber-950 to-yellow-400",
  "bg-gradient-to-r from-amber-950 to-yellow-500",
  "bg-gradient-to-r from-amber-950 to-yellow-600",
  "bg-gradient-to-r from-amber-950 to-yellow-700",
  "bg-gradient-to-r from-amber-950 to-yellow-800",
  "bg-gradient-to-r from-amber-950 to-yellow-900",
  "bg-gradient-to-r from-amber-950 to-yellow-950",
  "bg-gradient-to-r from-amber-950 to-lime-300",
  "bg-gradient-to-r from-amber-950 to-lime-400",
  "bg-gradient-to-r from-amber-950 to-lime-500",
  "bg-gradient-to-r from-amber-950 to-lime-600",
  "bg-gradient-to-r from-amber-950 to-lime-700",
  "bg-gradient-to-r from-amber-950 to-lime-800",
  "bg-gradient-to-r from-amber-950 to-lime-900",
  "bg-gradient-to-r from-amber-950 to-lime-950",
  "bg-gradient-to-r from-amber-950 to-green-300",
  "bg-gradient-to-r from-amber-950 to-green-400",
  "bg-gradient-to-r from-amber-950 to-green-500",
  "bg-gradient-to-r from-amber-950 to-green-600",
  "bg-gradient-to-r from-amber-950 to-green-700",
  "bg-gradient-to-r from-amber-950 to-green-800",
  "bg-gradient-to-r from-amber-950 to-green-900",
  "bg-gradient-to-r from-amber-950 to-green-950",
  "bg-gradient-to-r from-amber-950 to-emerald-300",
  "bg-gradient-to-r from-amber-950 to-emerald-400",
  "bg-gradient-to-r from-amber-950 to-emerald-500",
  "bg-gradient-to-r from-amber-950 to-emerald-600",
  "bg-gradient-to-r from-amber-950 to-emerald-700",
  "bg-gradient-to-r from-amber-950 to-emerald-800",
  "bg-gradient-to-r from-amber-950 to-emerald-900",
  "bg-gradient-to-r from-amber-950 to-emerald-950",
  "bg-gradient-to-r from-amber-950 to-teal-300",
  "bg-gradient-to-r from-amber-950 to-teal-400",
  "bg-gradient-to-r from-amber-950 to-teal-500",
  "bg-gradient-to-r from-amber-950 to-teal-600",
  "bg-gradient-to-r from-amber-950 to-teal-700",
  "bg-gradient-to-r from-amber-950 to-teal-800",
  "bg-gradient-to-r from-amber-950 to-teal-900",
  "bg-gradient-to-r from-amber-950 to-teal-950",
  "bg-gradient-to-r from-amber-950 to-cyan-300",
  "bg-gradient-to-r from-amber-950 to-cyan-400",
  "bg-gradient-to-r from-amber-950 to-cyan-500",
  "bg-gradient-to-r from-amber-950 to-cyan-600",
  "bg-gradient-to-r from-amber-950 to-cyan-700",
  "bg-gradient-to-r from-amber-950 to-cyan-800",
  "bg-gradient-to-r from-amber-950 to-cyan-900",
  "bg-gradient-to-r from-amber-950 to-cyan-950",
  "bg-gradient-to-r from-amber-950 to-sky-300",
  "bg-gradient-to-r from-amber-950 to-sky-400",
  "bg-gradient-to-r from-amber-950 to-sky-500",
  "bg-gradient-to-r from-amber-950 to-sky-600",
  "bg-gradient-to-r from-amber-950 to-sky-700",
  "bg-gradient-to-r from-amber-950 to-sky-800",
  "bg-gradient-to-r from-amber-950 to-sky-900",
  "bg-gradient-to-r from-amber-950 to-sky-950",
  "bg-gradient-to-r from-amber-950 to-blue-300",
  "bg-gradient-to-r from-amber-950 to-blue-400",
  "bg-gradient-to-r from-amber-950 to-blue-500",
  "bg-gradient-to-r from-amber-950 to-blue-600",
  "bg-gradient-to-r from-amber-950 to-blue-700",
  "bg-gradient-to-r from-amber-950 to-blue-800",
  "bg-gradient-to-r from-amber-950 to-blue-900",
  "bg-gradient-to-r from-amber-950 to-blue-950",
  "bg-gradient-to-r from-amber-950 to-indigo-300",
  "bg-gradient-to-r from-amber-950 to-indigo-400",
  "bg-gradient-to-r from-amber-950 to-indigo-500",
  "bg-gradient-to-r from-amber-950 to-indigo-600",
  "bg-gradient-to-r from-amber-950 to-indigo-700",
  "bg-gradient-to-r from-amber-950 to-indigo-800",
  "bg-gradient-to-r from-amber-950 to-indigo-900",
  "bg-gradient-to-r from-amber-950 to-indigo-950",
  "bg-gradient-to-r from-amber-950 to-violet-300",
  "bg-gradient-to-r from-amber-950 to-violet-400",
  "bg-gradient-to-r from-amber-950 to-violet-500",
  "bg-gradient-to-r from-amber-950 to-violet-600",
  "bg-gradient-to-r from-amber-950 to-violet-700",
  "bg-gradient-to-r from-amber-950 to-violet-800",
  "bg-gradient-to-r from-amber-950 to-violet-900",
  "bg-gradient-to-r from-amber-950 to-violet-950",
  "bg-gradient-to-r from-amber-950 to-purple-300",
  "bg-gradient-to-r from-amber-950 to-purple-400",
  "bg-gradient-to-r from-amber-950 to-purple-500",
  "bg-gradient-to-r from-amber-950 to-purple-600",
  "bg-gradient-to-r from-amber-950 to-purple-700",
  "bg-gradient-to-r from-amber-950 to-purple-800",
  "bg-gradient-to-r from-amber-950 to-purple-900",
  "bg-gradient-to-r from-amber-950 to-purple-950",
  "bg-gradient-to-r from-amber-950 to-fuchsia-300",
  "bg-gradient-to-r from-amber-950 to-fuchsia-400",
  "bg-gradient-to-r from-amber-950 to-fuchsia-500",
  "bg-gradient-to-r from-amber-950 to-fuchsia-600",
  "bg-gradient-to-r from-amber-950 to-fuchsia-700",
  "bg-gradient-to-r from-amber-950 to-fuchsia-800",
  "bg-gradient-to-r from-amber-950 to-fuchsia-900",
  "bg-gradient-to-r from-amber-950 to-fuchsia-950",
  "bg-gradient-to-r from-amber-950 to-pink-300",
  "bg-gradient-to-r from-amber-950 to-pink-400",
  "bg-gradient-to-r from-amber-950 to-pink-500",
  "bg-gradient-to-r from-amber-950 to-pink-600",
  "bg-gradient-to-r from-amber-950 to-pink-700",
  "bg-gradient-to-r from-amber-950 to-pink-800",
  "bg-gradient-to-r from-amber-950 to-pink-900",
  "bg-gradient-to-r from-amber-950 to-pink-950",
  "bg-gradient-to-r from-amber-950 to-rose-300",
  "bg-gradient-to-r from-amber-950 to-rose-400",
  "bg-gradient-to-r from-amber-950 to-rose-500",
  "bg-gradient-to-r from-amber-950 to-rose-600",
  "bg-gradient-to-r from-amber-950 to-rose-700",
  "bg-gradient-to-r from-amber-950 to-rose-800",
  "bg-gradient-to-r from-amber-950 to-rose-900",
  "bg-gradient-to-r from-amber-950 to-rose-950",
  "bg-gradient-to-r from-yellow-300 to-red-300",
  "bg-gradient-to-r from-yellow-300 to-red-400",
  "bg-gradient-to-r from-yellow-300 to-red-500",
  "bg-gradient-to-r from-yellow-300 to-red-600",
  "bg-gradient-to-r from-yellow-300 to-red-700",
  "bg-gradient-to-r from-yellow-300 to-red-800",
  "bg-gradient-to-r from-yellow-300 to-red-900",
  "bg-gradient-to-r from-yellow-300 to-red-950",
  "bg-gradient-to-r from-yellow-300 to-orange-300",
  "bg-gradient-to-r from-yellow-300 to-orange-400",
  "bg-gradient-to-r from-yellow-300 to-orange-500",
  "bg-gradient-to-r from-yellow-300 to-orange-600",
  "bg-gradient-to-r from-yellow-300 to-orange-700",
  "bg-gradient-to-r from-yellow-300 to-orange-800",
  "bg-gradient-to-r from-yellow-300 to-orange-900",
  "bg-gradient-to-r from-yellow-300 to-orange-950",
  "bg-gradient-to-r from-yellow-300 to-amber-300",
  "bg-gradient-to-r from-yellow-300 to-amber-400",
  "bg-gradient-to-r from-yellow-300 to-amber-500",
  "bg-gradient-to-r from-yellow-300 to-amber-600",
  "bg-gradient-to-r from-yellow-300 to-amber-700",
  "bg-gradient-to-r from-yellow-300 to-amber-800",
  "bg-gradient-to-r from-yellow-300 to-amber-900",
  "bg-gradient-to-r from-yellow-300 to-amber-950",
  "bg-gradient-to-r from-yellow-300 to-lime-300",
  "bg-gradient-to-r from-yellow-300 to-lime-400",
  "bg-gradient-to-r from-yellow-300 to-lime-500",
  "bg-gradient-to-r from-yellow-300 to-lime-600",
  "bg-gradient-to-r from-yellow-300 to-lime-700",
  "bg-gradient-to-r from-yellow-300 to-lime-800",
  "bg-gradient-to-r from-yellow-300 to-lime-900",
  "bg-gradient-to-r from-yellow-300 to-lime-950",
  "bg-gradient-to-r from-yellow-300 to-green-300",
  "bg-gradient-to-r from-yellow-300 to-green-400",
  "bg-gradient-to-r from-yellow-300 to-green-500",
  "bg-gradient-to-r from-yellow-300 to-green-600",
  "bg-gradient-to-r from-yellow-300 to-green-700",
  "bg-gradient-to-r from-yellow-300 to-green-800",
  "bg-gradient-to-r from-yellow-300 to-green-900",
  "bg-gradient-to-r from-yellow-300 to-green-950",
  "bg-gradient-to-r from-yellow-300 to-emerald-300",
  "bg-gradient-to-r from-yellow-300 to-emerald-400",
  "bg-gradient-to-r from-yellow-300 to-emerald-500",
  "bg-gradient-to-r from-yellow-300 to-emerald-600",
  "bg-gradient-to-r from-yellow-300 to-emerald-700",
  "bg-gradient-to-r from-yellow-300 to-emerald-800",
  "bg-gradient-to-r from-yellow-300 to-emerald-900",
  "bg-gradient-to-r from-yellow-300 to-emerald-950",
  "bg-gradient-to-r from-yellow-300 to-teal-300",
  "bg-gradient-to-r from-yellow-300 to-teal-400",
  "bg-gradient-to-r from-yellow-300 to-teal-500",
  "bg-gradient-to-r from-yellow-300 to-teal-600",
  "bg-gradient-to-r from-yellow-300 to-teal-700",
  "bg-gradient-to-r from-yellow-300 to-teal-800",
  "bg-gradient-to-r from-yellow-300 to-teal-900",
  "bg-gradient-to-r from-yellow-300 to-teal-950",
  "bg-gradient-to-r from-yellow-300 to-cyan-300",
  "bg-gradient-to-r from-yellow-300 to-cyan-400",
  "bg-gradient-to-r from-yellow-300 to-cyan-500",
  "bg-gradient-to-r from-yellow-300 to-cyan-600",
  "bg-gradient-to-r from-yellow-300 to-cyan-700",
  "bg-gradient-to-r from-yellow-300 to-cyan-800",
  "bg-gradient-to-r from-yellow-300 to-cyan-900",
  "bg-gradient-to-r from-yellow-300 to-cyan-950",
  "bg-gradient-to-r from-yellow-300 to-sky-300",
  "bg-gradient-to-r from-yellow-300 to-sky-400",
  "bg-gradient-to-r from-yellow-300 to-sky-500",
  "bg-gradient-to-r from-yellow-300 to-sky-600",
  "bg-gradient-to-r from-yellow-300 to-sky-700",
  "bg-gradient-to-r from-yellow-300 to-sky-800",
  "bg-gradient-to-r from-yellow-300 to-sky-900",
  "bg-gradient-to-r from-yellow-300 to-sky-950",
  "bg-gradient-to-r from-yellow-300 to-blue-300",
  "bg-gradient-to-r from-yellow-300 to-blue-400",
  "bg-gradient-to-r from-yellow-300 to-blue-500",
  "bg-gradient-to-r from-yellow-300 to-blue-600",
  "bg-gradient-to-r from-yellow-300 to-blue-700",
  "bg-gradient-to-r from-yellow-300 to-blue-800",
  "bg-gradient-to-r from-yellow-300 to-blue-900",
  "bg-gradient-to-r from-yellow-300 to-blue-950",
  "bg-gradient-to-r from-yellow-300 to-indigo-300",
  "bg-gradient-to-r from-yellow-300 to-indigo-400",
  "bg-gradient-to-r from-yellow-300 to-indigo-500",
  "bg-gradient-to-r from-yellow-300 to-indigo-600",
  "bg-gradient-to-r from-yellow-300 to-indigo-700",
  "bg-gradient-to-r from-yellow-300 to-indigo-800",
  "bg-gradient-to-r from-yellow-300 to-indigo-900",
  "bg-gradient-to-r from-yellow-300 to-indigo-950",
  "bg-gradient-to-r from-yellow-300 to-violet-300",
  "bg-gradient-to-r from-yellow-300 to-violet-400",
  "bg-gradient-to-r from-yellow-300 to-violet-500",
  "bg-gradient-to-r from-yellow-300 to-violet-600",
  "bg-gradient-to-r from-yellow-300 to-violet-700",
  "bg-gradient-to-r from-yellow-300 to-violet-800",
  "bg-gradient-to-r from-yellow-300 to-violet-900",
  "bg-gradient-to-r from-yellow-300 to-violet-950",
  "bg-gradient-to-r from-yellow-300 to-purple-300",
  "bg-gradient-to-r from-yellow-300 to-purple-400",
  "bg-gradient-to-r from-yellow-300 to-purple-500",
  "bg-gradient-to-r from-yellow-300 to-purple-600",
  "bg-gradient-to-r from-yellow-300 to-purple-700",
  "bg-gradient-to-r from-yellow-300 to-purple-800",
  "bg-gradient-to-r from-yellow-300 to-purple-900",
  "bg-gradient-to-r from-yellow-300 to-purple-950",
  "bg-gradient-to-r from-yellow-300 to-fuchsia-300",
  "bg-gradient-to-r from-yellow-300 to-fuchsia-400",
  "bg-gradient-to-r from-yellow-300 to-fuchsia-500",
  "bg-gradient-to-r from-yellow-300 to-fuchsia-600",
  "bg-gradient-to-r from-yellow-300 to-fuchsia-700",
  "bg-gradient-to-r from-yellow-300 to-fuchsia-800",
  "bg-gradient-to-r from-yellow-300 to-fuchsia-900",
  "bg-gradient-to-r from-yellow-300 to-fuchsia-950",
  "bg-gradient-to-r from-yellow-300 to-pink-300",
  "bg-gradient-to-r from-yellow-300 to-pink-400",
  "bg-gradient-to-r from-yellow-300 to-pink-500",
  "bg-gradient-to-r from-yellow-300 to-pink-600",
  "bg-gradient-to-r from-yellow-300 to-pink-700",
  "bg-gradient-to-r from-yellow-300 to-pink-800",
  "bg-gradient-to-r from-yellow-300 to-pink-900",
  "bg-gradient-to-r from-yellow-300 to-pink-950",
  "bg-gradient-to-r from-yellow-300 to-rose-300",
  "bg-gradient-to-r from-yellow-300 to-rose-400",
  "bg-gradient-to-r from-yellow-300 to-rose-500",
  "bg-gradient-to-r from-yellow-300 to-rose-600",
  "bg-gradient-to-r from-yellow-300 to-rose-700",
  "bg-gradient-to-r from-yellow-300 to-rose-800",
  "bg-gradient-to-r from-yellow-300 to-rose-900",
  "bg-gradient-to-r from-yellow-300 to-rose-950",
  "bg-gradient-to-r from-yellow-400 to-red-300",
  "bg-gradient-to-r from-yellow-400 to-red-400",
  "bg-gradient-to-r from-yellow-400 to-red-500",
  "bg-gradient-to-r from-yellow-400 to-red-600",
  "bg-gradient-to-r from-yellow-400 to-red-700",
  "bg-gradient-to-r from-yellow-400 to-red-800",
  "bg-gradient-to-r from-yellow-400 to-red-900",
  "bg-gradient-to-r from-yellow-400 to-red-950",
  "bg-gradient-to-r from-yellow-400 to-orange-300",
  "bg-gradient-to-r from-yellow-400 to-orange-400",
  "bg-gradient-to-r from-yellow-400 to-orange-500",
  "bg-gradient-to-r from-yellow-400 to-orange-600",
  "bg-gradient-to-r from-yellow-400 to-orange-700",
  "bg-gradient-to-r from-yellow-400 to-orange-800",
  "bg-gradient-to-r from-yellow-400 to-orange-900",
  "bg-gradient-to-r from-yellow-400 to-orange-950",
  "bg-gradient-to-r from-yellow-400 to-amber-300",
  "bg-gradient-to-r from-yellow-400 to-amber-400",
  "bg-gradient-to-r from-yellow-400 to-amber-500",
  "bg-gradient-to-r from-yellow-400 to-amber-600",
  "bg-gradient-to-r from-yellow-400 to-amber-700",
  "bg-gradient-to-r from-yellow-400 to-amber-800",
  "bg-gradient-to-r from-yellow-400 to-amber-900",
  "bg-gradient-to-r from-yellow-400 to-amber-950",
  "bg-gradient-to-r from-yellow-400 to-lime-300",
  "bg-gradient-to-r from-yellow-400 to-lime-400",
  "bg-gradient-to-r from-yellow-400 to-lime-500",
  "bg-gradient-to-r from-yellow-400 to-lime-600",
  "bg-gradient-to-r from-yellow-400 to-lime-700",
  "bg-gradient-to-r from-yellow-400 to-lime-800",
  "bg-gradient-to-r from-yellow-400 to-lime-900",
  "bg-gradient-to-r from-yellow-400 to-lime-950",
  "bg-gradient-to-r from-yellow-400 to-green-300",
  "bg-gradient-to-r from-yellow-400 to-green-400",
  "bg-gradient-to-r from-yellow-400 to-green-500",
  "bg-gradient-to-r from-yellow-400 to-green-600",
  "bg-gradient-to-r from-yellow-400 to-green-700",
  "bg-gradient-to-r from-yellow-400 to-green-800",
  "bg-gradient-to-r from-yellow-400 to-green-900",
  "bg-gradient-to-r from-yellow-400 to-green-950",
  "bg-gradient-to-r from-yellow-400 to-emerald-300",
  "bg-gradient-to-r from-yellow-400 to-emerald-400",
  "bg-gradient-to-r from-yellow-400 to-emerald-500",
  "bg-gradient-to-r from-yellow-400 to-emerald-600",
  "bg-gradient-to-r from-yellow-400 to-emerald-700",
  "bg-gradient-to-r from-yellow-400 to-emerald-800",
  "bg-gradient-to-r from-yellow-400 to-emerald-900",
  "bg-gradient-to-r from-yellow-400 to-emerald-950",
  "bg-gradient-to-r from-yellow-400 to-teal-300",
  "bg-gradient-to-r from-yellow-400 to-teal-400",
  "bg-gradient-to-r from-yellow-400 to-teal-500",
  "bg-gradient-to-r from-yellow-400 to-teal-600",
  "bg-gradient-to-r from-yellow-400 to-teal-700",
  "bg-gradient-to-r from-yellow-400 to-teal-800",
  "bg-gradient-to-r from-yellow-400 to-teal-900",
  "bg-gradient-to-r from-yellow-400 to-teal-950",
  "bg-gradient-to-r from-yellow-400 to-cyan-300",
  "bg-gradient-to-r from-yellow-400 to-cyan-400",
  "bg-gradient-to-r from-yellow-400 to-cyan-500",
  "bg-gradient-to-r from-yellow-400 to-cyan-600",
  "bg-gradient-to-r from-yellow-400 to-cyan-700",
  "bg-gradient-to-r from-yellow-400 to-cyan-800",
  "bg-gradient-to-r from-yellow-400 to-cyan-900",
  "bg-gradient-to-r from-yellow-400 to-cyan-950",
  "bg-gradient-to-r from-yellow-400 to-sky-300",
  "bg-gradient-to-r from-yellow-400 to-sky-400",
  "bg-gradient-to-r from-yellow-400 to-sky-500",
  "bg-gradient-to-r from-yellow-400 to-sky-600",
  "bg-gradient-to-r from-yellow-400 to-sky-700",
  "bg-gradient-to-r from-yellow-400 to-sky-800",
  "bg-gradient-to-r from-yellow-400 to-sky-900",
  "bg-gradient-to-r from-yellow-400 to-sky-950",
  "bg-gradient-to-r from-yellow-400 to-blue-300",
  "bg-gradient-to-r from-yellow-400 to-blue-400",
  "bg-gradient-to-r from-yellow-400 to-blue-500",
  "bg-gradient-to-r from-yellow-400 to-blue-600",
  "bg-gradient-to-r from-yellow-400 to-blue-700",
  "bg-gradient-to-r from-yellow-400 to-blue-800",
  "bg-gradient-to-r from-yellow-400 to-blue-900",
  "bg-gradient-to-r from-yellow-400 to-blue-950",
  "bg-gradient-to-r from-yellow-400 to-indigo-300",
  "bg-gradient-to-r from-yellow-400 to-indigo-400",
  "bg-gradient-to-r from-yellow-400 to-indigo-500",
  "bg-gradient-to-r from-yellow-400 to-indigo-600",
  "bg-gradient-to-r from-yellow-400 to-indigo-700",
  "bg-gradient-to-r from-yellow-400 to-indigo-800",
  "bg-gradient-to-r from-yellow-400 to-indigo-900",
  "bg-gradient-to-r from-yellow-400 to-indigo-950",
  "bg-gradient-to-r from-yellow-400 to-violet-300",
  "bg-gradient-to-r from-yellow-400 to-violet-400",
  "bg-gradient-to-r from-yellow-400 to-violet-500",
  "bg-gradient-to-r from-yellow-400 to-violet-600",
  "bg-gradient-to-r from-yellow-400 to-violet-700",
  "bg-gradient-to-r from-yellow-400 to-violet-800",
  "bg-gradient-to-r from-yellow-400 to-violet-900",
  "bg-gradient-to-r from-yellow-400 to-violet-950",
  "bg-gradient-to-r from-yellow-400 to-purple-300",
  "bg-gradient-to-r from-yellow-400 to-purple-400",
  "bg-gradient-to-r from-yellow-400 to-purple-500",
  "bg-gradient-to-r from-yellow-400 to-purple-600",
  "bg-gradient-to-r from-yellow-400 to-purple-700",
  "bg-gradient-to-r from-yellow-400 to-purple-800",
  "bg-gradient-to-r from-yellow-400 to-purple-900",
  "bg-gradient-to-r from-yellow-400 to-purple-950",
  "bg-gradient-to-r from-yellow-400 to-fuchsia-300",
  "bg-gradient-to-r from-yellow-400 to-fuchsia-400",
  "bg-gradient-to-r from-yellow-400 to-fuchsia-500",
  "bg-gradient-to-r from-yellow-400 to-fuchsia-600",
  "bg-gradient-to-r from-yellow-400 to-fuchsia-700",
  "bg-gradient-to-r from-yellow-400 to-fuchsia-800",
  "bg-gradient-to-r from-yellow-400 to-fuchsia-900",
  "bg-gradient-to-r from-yellow-400 to-fuchsia-950",
  "bg-gradient-to-r from-yellow-400 to-pink-300",
  "bg-gradient-to-r from-yellow-400 to-pink-400",
  "bg-gradient-to-r from-yellow-400 to-pink-500",
  "bg-gradient-to-r from-yellow-400 to-pink-600",
  "bg-gradient-to-r from-yellow-400 to-pink-700",
  "bg-gradient-to-r from-yellow-400 to-pink-800",
  "bg-gradient-to-r from-yellow-400 to-pink-900",
  "bg-gradient-to-r from-yellow-400 to-pink-950",
  "bg-gradient-to-r from-yellow-400 to-rose-300",
  "bg-gradient-to-r from-yellow-400 to-rose-400",
  "bg-gradient-to-r from-yellow-400 to-rose-500",
  "bg-gradient-to-r from-yellow-400 to-rose-600",
  "bg-gradient-to-r from-yellow-400 to-rose-700",
  "bg-gradient-to-r from-yellow-400 to-rose-800",
  "bg-gradient-to-r from-yellow-400 to-rose-900",
  "bg-gradient-to-r from-yellow-400 to-rose-950",
  "bg-gradient-to-r from-yellow-500 to-red-300",
  "bg-gradient-to-r from-yellow-500 to-red-400",
  "bg-gradient-to-r from-yellow-500 to-red-500",
  "bg-gradient-to-r from-yellow-500 to-red-600",
  "bg-gradient-to-r from-yellow-500 to-red-700",
  "bg-gradient-to-r from-yellow-500 to-red-800",
  "bg-gradient-to-r from-yellow-500 to-red-900",
  "bg-gradient-to-r from-yellow-500 to-red-950",
  "bg-gradient-to-r from-yellow-500 to-orange-300",
  "bg-gradient-to-r from-yellow-500 to-orange-400",
  "bg-gradient-to-r from-yellow-500 to-orange-500",
  "bg-gradient-to-r from-yellow-500 to-orange-600",
  "bg-gradient-to-r from-yellow-500 to-orange-700",
  "bg-gradient-to-r from-yellow-500 to-orange-800",
  "bg-gradient-to-r from-yellow-500 to-orange-900",
  "bg-gradient-to-r from-yellow-500 to-orange-950",
  "bg-gradient-to-r from-yellow-500 to-amber-300",
  "bg-gradient-to-r from-yellow-500 to-amber-400",
  "bg-gradient-to-r from-yellow-500 to-amber-500",
  "bg-gradient-to-r from-yellow-500 to-amber-600",
  "bg-gradient-to-r from-yellow-500 to-amber-700",
  "bg-gradient-to-r from-yellow-500 to-amber-800",
  "bg-gradient-to-r from-yellow-500 to-amber-900",
  "bg-gradient-to-r from-yellow-500 to-amber-950",
  "bg-gradient-to-r from-yellow-500 to-lime-300",
  "bg-gradient-to-r from-yellow-500 to-lime-400",
  "bg-gradient-to-r from-yellow-500 to-lime-500",
  "bg-gradient-to-r from-yellow-500 to-lime-600",
  "bg-gradient-to-r from-yellow-500 to-lime-700",
  "bg-gradient-to-r from-yellow-500 to-lime-800",
  "bg-gradient-to-r from-yellow-500 to-lime-900",
  "bg-gradient-to-r from-yellow-500 to-lime-950",
  "bg-gradient-to-r from-yellow-500 to-green-300",
  "bg-gradient-to-r from-yellow-500 to-green-400",
  "bg-gradient-to-r from-yellow-500 to-green-500",
  "bg-gradient-to-r from-yellow-500 to-green-600",
  "bg-gradient-to-r from-yellow-500 to-green-700",
  "bg-gradient-to-r from-yellow-500 to-green-800",
  "bg-gradient-to-r from-yellow-500 to-green-900",
  "bg-gradient-to-r from-yellow-500 to-green-950",
  "bg-gradient-to-r from-yellow-500 to-emerald-300",
  "bg-gradient-to-r from-yellow-500 to-emerald-400",
  "bg-gradient-to-r from-yellow-500 to-emerald-500",
  "bg-gradient-to-r from-yellow-500 to-emerald-600",
  "bg-gradient-to-r from-yellow-500 to-emerald-700",
  "bg-gradient-to-r from-yellow-500 to-emerald-800",
  "bg-gradient-to-r from-yellow-500 to-emerald-900",
  "bg-gradient-to-r from-yellow-500 to-emerald-950",
  "bg-gradient-to-r from-yellow-500 to-teal-300",
  "bg-gradient-to-r from-yellow-500 to-teal-400",
  "bg-gradient-to-r from-yellow-500 to-teal-500",
  "bg-gradient-to-r from-yellow-500 to-teal-600",
  "bg-gradient-to-r from-yellow-500 to-teal-700",
  "bg-gradient-to-r from-yellow-500 to-teal-800",
  "bg-gradient-to-r from-yellow-500 to-teal-900",
  "bg-gradient-to-r from-yellow-500 to-teal-950",
  "bg-gradient-to-r from-yellow-500 to-cyan-300",
  "bg-gradient-to-r from-yellow-500 to-cyan-400",
  "bg-gradient-to-r from-yellow-500 to-cyan-500",
  "bg-gradient-to-r from-yellow-500 to-cyan-600",
  "bg-gradient-to-r from-yellow-500 to-cyan-700",
  "bg-gradient-to-r from-yellow-500 to-cyan-800",
  "bg-gradient-to-r from-yellow-500 to-cyan-900",
  "bg-gradient-to-r from-yellow-500 to-cyan-950",
  "bg-gradient-to-r from-yellow-500 to-sky-300",
  "bg-gradient-to-r from-yellow-500 to-sky-400",
  "bg-gradient-to-r from-yellow-500 to-sky-500",
  "bg-gradient-to-r from-yellow-500 to-sky-600",
  "bg-gradient-to-r from-yellow-500 to-sky-700",
  "bg-gradient-to-r from-yellow-500 to-sky-800",
  "bg-gradient-to-r from-yellow-500 to-sky-900",
  "bg-gradient-to-r from-yellow-500 to-sky-950",
  "bg-gradient-to-r from-yellow-500 to-blue-300",
  "bg-gradient-to-r from-yellow-500 to-blue-400",
  "bg-gradient-to-r from-yellow-500 to-blue-500",
  "bg-gradient-to-r from-yellow-500 to-blue-600",
  "bg-gradient-to-r from-yellow-500 to-blue-700",
  "bg-gradient-to-r from-yellow-500 to-blue-800",
  "bg-gradient-to-r from-yellow-500 to-blue-900",
  "bg-gradient-to-r from-yellow-500 to-blue-950",
  "bg-gradient-to-r from-yellow-500 to-indigo-300",
  "bg-gradient-to-r from-yellow-500 to-indigo-400",
  "bg-gradient-to-r from-yellow-500 to-indigo-500",
  "bg-gradient-to-r from-yellow-500 to-indigo-600",
  "bg-gradient-to-r from-yellow-500 to-indigo-700",
  "bg-gradient-to-r from-yellow-500 to-indigo-800",
  "bg-gradient-to-r from-yellow-500 to-indigo-900",
  "bg-gradient-to-r from-yellow-500 to-indigo-950",
  "bg-gradient-to-r from-yellow-500 to-violet-300",
  "bg-gradient-to-r from-yellow-500 to-violet-400",
  "bg-gradient-to-r from-yellow-500 to-violet-500",
  "bg-gradient-to-r from-yellow-500 to-violet-600",
  "bg-gradient-to-r from-yellow-500 to-violet-700",
  "bg-gradient-to-r from-yellow-500 to-violet-800",
  "bg-gradient-to-r from-yellow-500 to-violet-900",
  "bg-gradient-to-r from-yellow-500 to-violet-950",
  "bg-gradient-to-r from-yellow-500 to-purple-300",
  "bg-gradient-to-r from-yellow-500 to-purple-400",
  "bg-gradient-to-r from-yellow-500 to-purple-500",
  "bg-gradient-to-r from-yellow-500 to-purple-600",
  "bg-gradient-to-r from-yellow-500 to-purple-700",
  "bg-gradient-to-r from-yellow-500 to-purple-800",
  "bg-gradient-to-r from-yellow-500 to-purple-900",
  "bg-gradient-to-r from-yellow-500 to-purple-950",
  "bg-gradient-to-r from-yellow-500 to-fuchsia-300",
  "bg-gradient-to-r from-yellow-500 to-fuchsia-400",
  "bg-gradient-to-r from-yellow-500 to-fuchsia-500",
  "bg-gradient-to-r from-yellow-500 to-fuchsia-600",
  "bg-gradient-to-r from-yellow-500 to-fuchsia-700",
  "bg-gradient-to-r from-yellow-500 to-fuchsia-800",
  "bg-gradient-to-r from-yellow-500 to-fuchsia-900",
  "bg-gradient-to-r from-yellow-500 to-fuchsia-950",
  "bg-gradient-to-r from-yellow-500 to-pink-300",
  "bg-gradient-to-r from-yellow-500 to-pink-400",
  "bg-gradient-to-r from-yellow-500 to-pink-500",
  "bg-gradient-to-r from-yellow-500 to-pink-600",
  "bg-gradient-to-r from-yellow-500 to-pink-700",
  "bg-gradient-to-r from-yellow-500 to-pink-800",
  "bg-gradient-to-r from-yellow-500 to-pink-900",
  "bg-gradient-to-r from-yellow-500 to-pink-950",
  "bg-gradient-to-r from-yellow-500 to-rose-300",
  "bg-gradient-to-r from-yellow-500 to-rose-400",
  "bg-gradient-to-r from-yellow-500 to-rose-500",
  "bg-gradient-to-r from-yellow-500 to-rose-600",
  "bg-gradient-to-r from-yellow-500 to-rose-700",
  "bg-gradient-to-r from-yellow-500 to-rose-800",
  "bg-gradient-to-r from-yellow-500 to-rose-900",
  "bg-gradient-to-r from-yellow-500 to-rose-950",
  "bg-gradient-to-r from-yellow-600 to-red-300",
  "bg-gradient-to-r from-yellow-600 to-red-400",
  "bg-gradient-to-r from-yellow-600 to-red-500",
  "bg-gradient-to-r from-yellow-600 to-red-600",
  "bg-gradient-to-r from-yellow-600 to-red-700",
  "bg-gradient-to-r from-yellow-600 to-red-800",
  "bg-gradient-to-r from-yellow-600 to-red-900",
  "bg-gradient-to-r from-yellow-600 to-red-950",
  "bg-gradient-to-r from-yellow-600 to-orange-300",
  "bg-gradient-to-r from-yellow-600 to-orange-400",
  "bg-gradient-to-r from-yellow-600 to-orange-500",
  "bg-gradient-to-r from-yellow-600 to-orange-600",
  "bg-gradient-to-r from-yellow-600 to-orange-700",
  "bg-gradient-to-r from-yellow-600 to-orange-800",
  "bg-gradient-to-r from-yellow-600 to-orange-900",
  "bg-gradient-to-r from-yellow-600 to-orange-950",
  "bg-gradient-to-r from-yellow-600 to-amber-300",
  "bg-gradient-to-r from-yellow-600 to-amber-400",
  "bg-gradient-to-r from-yellow-600 to-amber-500",
  "bg-gradient-to-r from-yellow-600 to-amber-600",
  "bg-gradient-to-r from-yellow-600 to-amber-700",
  "bg-gradient-to-r from-yellow-600 to-amber-800",
  "bg-gradient-to-r from-yellow-600 to-amber-900",
  "bg-gradient-to-r from-yellow-600 to-amber-950",
  "bg-gradient-to-r from-yellow-600 to-lime-300",
  "bg-gradient-to-r from-yellow-600 to-lime-400",
  "bg-gradient-to-r from-yellow-600 to-lime-500",
  "bg-gradient-to-r from-yellow-600 to-lime-600",
  "bg-gradient-to-r from-yellow-600 to-lime-700",
  "bg-gradient-to-r from-yellow-600 to-lime-800",
  "bg-gradient-to-r from-yellow-600 to-lime-900",
  "bg-gradient-to-r from-yellow-600 to-lime-950",
  "bg-gradient-to-r from-yellow-600 to-green-300",
  "bg-gradient-to-r from-yellow-600 to-green-400",
  "bg-gradient-to-r from-yellow-600 to-green-500",
  "bg-gradient-to-r from-yellow-600 to-green-600",
  "bg-gradient-to-r from-yellow-600 to-green-700",
  "bg-gradient-to-r from-yellow-600 to-green-800",
  "bg-gradient-to-r from-yellow-600 to-green-900",
  "bg-gradient-to-r from-yellow-600 to-green-950",
  "bg-gradient-to-r from-yellow-600 to-emerald-300",
  "bg-gradient-to-r from-yellow-600 to-emerald-400",
  "bg-gradient-to-r from-yellow-600 to-emerald-500",
  "bg-gradient-to-r from-yellow-600 to-emerald-600",
  "bg-gradient-to-r from-yellow-600 to-emerald-700",
  "bg-gradient-to-r from-yellow-600 to-emerald-800",
  "bg-gradient-to-r from-yellow-600 to-emerald-900",
  "bg-gradient-to-r from-yellow-600 to-emerald-950",
  "bg-gradient-to-r from-yellow-600 to-teal-300",
  "bg-gradient-to-r from-yellow-600 to-teal-400",
  "bg-gradient-to-r from-yellow-600 to-teal-500",
  "bg-gradient-to-r from-yellow-600 to-teal-600",
  "bg-gradient-to-r from-yellow-600 to-teal-700",
  "bg-gradient-to-r from-yellow-600 to-teal-800",
  "bg-gradient-to-r from-yellow-600 to-teal-900",
  "bg-gradient-to-r from-yellow-600 to-teal-950",
  "bg-gradient-to-r from-yellow-600 to-cyan-300",
  "bg-gradient-to-r from-yellow-600 to-cyan-400",
  "bg-gradient-to-r from-yellow-600 to-cyan-500",
  "bg-gradient-to-r from-yellow-600 to-cyan-600",
  "bg-gradient-to-r from-yellow-600 to-cyan-700",
  "bg-gradient-to-r from-yellow-600 to-cyan-800",
  "bg-gradient-to-r from-yellow-600 to-cyan-900",
  "bg-gradient-to-r from-yellow-600 to-cyan-950",
  "bg-gradient-to-r from-yellow-600 to-sky-300",
  "bg-gradient-to-r from-yellow-600 to-sky-400",
  "bg-gradient-to-r from-yellow-600 to-sky-500",
  "bg-gradient-to-r from-yellow-600 to-sky-600",
  "bg-gradient-to-r from-yellow-600 to-sky-700",
  "bg-gradient-to-r from-yellow-600 to-sky-800",
  "bg-gradient-to-r from-yellow-600 to-sky-900",
  "bg-gradient-to-r from-yellow-600 to-sky-950",
  "bg-gradient-to-r from-yellow-600 to-blue-300",
  "bg-gradient-to-r from-yellow-600 to-blue-400",
  "bg-gradient-to-r from-yellow-600 to-blue-500",
  "bg-gradient-to-r from-yellow-600 to-blue-600",
  "bg-gradient-to-r from-yellow-600 to-blue-700",
  "bg-gradient-to-r from-yellow-600 to-blue-800",
  "bg-gradient-to-r from-yellow-600 to-blue-900",
  "bg-gradient-to-r from-yellow-600 to-blue-950",
  "bg-gradient-to-r from-yellow-600 to-indigo-300",
  "bg-gradient-to-r from-yellow-600 to-indigo-400",
  "bg-gradient-to-r from-yellow-600 to-indigo-500",
  "bg-gradient-to-r from-yellow-600 to-indigo-600",
  "bg-gradient-to-r from-yellow-600 to-indigo-700",
  "bg-gradient-to-r from-yellow-600 to-indigo-800",
  "bg-gradient-to-r from-yellow-600 to-indigo-900",
  "bg-gradient-to-r from-yellow-600 to-indigo-950",
  "bg-gradient-to-r from-yellow-600 to-violet-300",
  "bg-gradient-to-r from-yellow-600 to-violet-400",
  "bg-gradient-to-r from-yellow-600 to-violet-500",
  "bg-gradient-to-r from-yellow-600 to-violet-600",
  "bg-gradient-to-r from-yellow-600 to-violet-700",
  "bg-gradient-to-r from-yellow-600 to-violet-800",
  "bg-gradient-to-r from-yellow-600 to-violet-900",
  "bg-gradient-to-r from-yellow-600 to-violet-950",
  "bg-gradient-to-r from-yellow-600 to-purple-300",
  "bg-gradient-to-r from-yellow-600 to-purple-400",
  "bg-gradient-to-r from-yellow-600 to-purple-500",
  "bg-gradient-to-r from-yellow-600 to-purple-600",
  "bg-gradient-to-r from-yellow-600 to-purple-700",
  "bg-gradient-to-r from-yellow-600 to-purple-800",
  "bg-gradient-to-r from-yellow-600 to-purple-900",
  "bg-gradient-to-r from-yellow-600 to-purple-950",
  "bg-gradient-to-r from-yellow-600 to-fuchsia-300",
  "bg-gradient-to-r from-yellow-600 to-fuchsia-400",
  "bg-gradient-to-r from-yellow-600 to-fuchsia-500",
  "bg-gradient-to-r from-yellow-600 to-fuchsia-600",
  "bg-gradient-to-r from-yellow-600 to-fuchsia-700",
  "bg-gradient-to-r from-yellow-600 to-fuchsia-800",
  "bg-gradient-to-r from-yellow-600 to-fuchsia-900",
  "bg-gradient-to-r from-yellow-600 to-fuchsia-950",
  "bg-gradient-to-r from-yellow-600 to-pink-300",
  "bg-gradient-to-r from-yellow-600 to-pink-400",
  "bg-gradient-to-r from-yellow-600 to-pink-500",
  "bg-gradient-to-r from-yellow-600 to-pink-600",
  "bg-gradient-to-r from-yellow-600 to-pink-700",
  "bg-gradient-to-r from-yellow-600 to-pink-800",
  "bg-gradient-to-r from-yellow-600 to-pink-900",
  "bg-gradient-to-r from-yellow-600 to-pink-950",
  "bg-gradient-to-r from-yellow-600 to-rose-300",
  "bg-gradient-to-r from-yellow-600 to-rose-400",
  "bg-gradient-to-r from-yellow-600 to-rose-500",
  "bg-gradient-to-r from-yellow-600 to-rose-600",
  "bg-gradient-to-r from-yellow-600 to-rose-700",
  "bg-gradient-to-r from-yellow-600 to-rose-800",
  "bg-gradient-to-r from-yellow-600 to-rose-900",
  "bg-gradient-to-r from-yellow-600 to-rose-950",
  "bg-gradient-to-r from-yellow-700 to-red-300",
  "bg-gradient-to-r from-yellow-700 to-red-400",
  "bg-gradient-to-r from-yellow-700 to-red-500",
  "bg-gradient-to-r from-yellow-700 to-red-600",
  "bg-gradient-to-r from-yellow-700 to-red-700",
  "bg-gradient-to-r from-yellow-700 to-red-800",
  "bg-gradient-to-r from-yellow-700 to-red-900",
  "bg-gradient-to-r from-yellow-700 to-red-950",
  "bg-gradient-to-r from-yellow-700 to-orange-300",
  "bg-gradient-to-r from-yellow-700 to-orange-400",
  "bg-gradient-to-r from-yellow-700 to-orange-500",
  "bg-gradient-to-r from-yellow-700 to-orange-600",
  "bg-gradient-to-r from-yellow-700 to-orange-700",
  "bg-gradient-to-r from-yellow-700 to-orange-800",
  "bg-gradient-to-r from-yellow-700 to-orange-900",
  "bg-gradient-to-r from-yellow-700 to-orange-950",
  "bg-gradient-to-r from-yellow-700 to-amber-300",
  "bg-gradient-to-r from-yellow-700 to-amber-400",
  "bg-gradient-to-r from-yellow-700 to-amber-500",
  "bg-gradient-to-r from-yellow-700 to-amber-600",
  "bg-gradient-to-r from-yellow-700 to-amber-700",
  "bg-gradient-to-r from-yellow-700 to-amber-800",
  "bg-gradient-to-r from-yellow-700 to-amber-900",
  "bg-gradient-to-r from-yellow-700 to-amber-950",
  "bg-gradient-to-r from-yellow-700 to-lime-300",
  "bg-gradient-to-r from-yellow-700 to-lime-400",
  "bg-gradient-to-r from-yellow-700 to-lime-500",
  "bg-gradient-to-r from-yellow-700 to-lime-600",
  "bg-gradient-to-r from-yellow-700 to-lime-700",
  "bg-gradient-to-r from-yellow-700 to-lime-800",
  "bg-gradient-to-r from-yellow-700 to-lime-900",
  "bg-gradient-to-r from-yellow-700 to-lime-950",
  "bg-gradient-to-r from-yellow-700 to-green-300",
  "bg-gradient-to-r from-yellow-700 to-green-400",
  "bg-gradient-to-r from-yellow-700 to-green-500",
  "bg-gradient-to-r from-yellow-700 to-green-600",
  "bg-gradient-to-r from-yellow-700 to-green-700",
  "bg-gradient-to-r from-yellow-700 to-green-800",
  "bg-gradient-to-r from-yellow-700 to-green-900",
  "bg-gradient-to-r from-yellow-700 to-green-950",
  "bg-gradient-to-r from-yellow-700 to-emerald-300",
  "bg-gradient-to-r from-yellow-700 to-emerald-400",
  "bg-gradient-to-r from-yellow-700 to-emerald-500",
  "bg-gradient-to-r from-yellow-700 to-emerald-600",
  "bg-gradient-to-r from-yellow-700 to-emerald-700",
  "bg-gradient-to-r from-yellow-700 to-emerald-800",
  "bg-gradient-to-r from-yellow-700 to-emerald-900",
  "bg-gradient-to-r from-yellow-700 to-emerald-950",
  "bg-gradient-to-r from-yellow-700 to-teal-300",
  "bg-gradient-to-r from-yellow-700 to-teal-400",
  "bg-gradient-to-r from-yellow-700 to-teal-500",
  "bg-gradient-to-r from-yellow-700 to-teal-600",
  "bg-gradient-to-r from-yellow-700 to-teal-700",
  "bg-gradient-to-r from-yellow-700 to-teal-800",
  "bg-gradient-to-r from-yellow-700 to-teal-900",
  "bg-gradient-to-r from-yellow-700 to-teal-950",
  "bg-gradient-to-r from-yellow-700 to-cyan-300",
  "bg-gradient-to-r from-yellow-700 to-cyan-400",
  "bg-gradient-to-r from-yellow-700 to-cyan-500",
  "bg-gradient-to-r from-yellow-700 to-cyan-600",
  "bg-gradient-to-r from-yellow-700 to-cyan-700",
  "bg-gradient-to-r from-yellow-700 to-cyan-800",
  "bg-gradient-to-r from-yellow-700 to-cyan-900",
  "bg-gradient-to-r from-yellow-700 to-cyan-950",
  "bg-gradient-to-r from-yellow-700 to-sky-300",
  "bg-gradient-to-r from-yellow-700 to-sky-400",
  "bg-gradient-to-r from-yellow-700 to-sky-500",
  "bg-gradient-to-r from-yellow-700 to-sky-600",
  "bg-gradient-to-r from-yellow-700 to-sky-700",
  "bg-gradient-to-r from-yellow-700 to-sky-800",
  "bg-gradient-to-r from-yellow-700 to-sky-900",
  "bg-gradient-to-r from-yellow-700 to-sky-950",
  "bg-gradient-to-r from-yellow-700 to-blue-300",
  "bg-gradient-to-r from-yellow-700 to-blue-400",
  "bg-gradient-to-r from-yellow-700 to-blue-500",
  "bg-gradient-to-r from-yellow-700 to-blue-600",
  "bg-gradient-to-r from-yellow-700 to-blue-700",
  "bg-gradient-to-r from-yellow-700 to-blue-800",
  "bg-gradient-to-r from-yellow-700 to-blue-900",
  "bg-gradient-to-r from-yellow-700 to-blue-950",
  "bg-gradient-to-r from-yellow-700 to-indigo-300",
  "bg-gradient-to-r from-yellow-700 to-indigo-400",
  "bg-gradient-to-r from-yellow-700 to-indigo-500",
  "bg-gradient-to-r from-yellow-700 to-indigo-600",
  "bg-gradient-to-r from-yellow-700 to-indigo-700",
  "bg-gradient-to-r from-yellow-700 to-indigo-800",
  "bg-gradient-to-r from-yellow-700 to-indigo-900",
  "bg-gradient-to-r from-yellow-700 to-indigo-950",
  "bg-gradient-to-r from-yellow-700 to-violet-300",
  "bg-gradient-to-r from-yellow-700 to-violet-400",
  "bg-gradient-to-r from-yellow-700 to-violet-500",
  "bg-gradient-to-r from-yellow-700 to-violet-600",
  "bg-gradient-to-r from-yellow-700 to-violet-700",
  "bg-gradient-to-r from-yellow-700 to-violet-800",
  "bg-gradient-to-r from-yellow-700 to-violet-900",
  "bg-gradient-to-r from-yellow-700 to-violet-950",
  "bg-gradient-to-r from-yellow-700 to-purple-300",
  "bg-gradient-to-r from-yellow-700 to-purple-400",
  "bg-gradient-to-r from-yellow-700 to-purple-500",
  "bg-gradient-to-r from-yellow-700 to-purple-600",
  "bg-gradient-to-r from-yellow-700 to-purple-700",
  "bg-gradient-to-r from-yellow-700 to-purple-800",
  "bg-gradient-to-r from-yellow-700 to-purple-900",
  "bg-gradient-to-r from-yellow-700 to-purple-950",
  "bg-gradient-to-r from-yellow-700 to-fuchsia-300",
  "bg-gradient-to-r from-yellow-700 to-fuchsia-400",
  "bg-gradient-to-r from-yellow-700 to-fuchsia-500",
  "bg-gradient-to-r from-yellow-700 to-fuchsia-600",
  "bg-gradient-to-r from-yellow-700 to-fuchsia-700",
  "bg-gradient-to-r from-yellow-700 to-fuchsia-800",
  "bg-gradient-to-r from-yellow-700 to-fuchsia-900",
  "bg-gradient-to-r from-yellow-700 to-fuchsia-950",
  "bg-gradient-to-r from-yellow-700 to-pink-300",
  "bg-gradient-to-r from-yellow-700 to-pink-400",
  "bg-gradient-to-r from-yellow-700 to-pink-500",
  "bg-gradient-to-r from-yellow-700 to-pink-600",
  "bg-gradient-to-r from-yellow-700 to-pink-700",
  "bg-gradient-to-r from-yellow-700 to-pink-800",
  "bg-gradient-to-r from-yellow-700 to-pink-900",
  "bg-gradient-to-r from-yellow-700 to-pink-950",
  "bg-gradient-to-r from-yellow-700 to-rose-300",
  "bg-gradient-to-r from-yellow-700 to-rose-400",
  "bg-gradient-to-r from-yellow-700 to-rose-500",
  "bg-gradient-to-r from-yellow-700 to-rose-600",
  "bg-gradient-to-r from-yellow-700 to-rose-700",
  "bg-gradient-to-r from-yellow-700 to-rose-800",
  "bg-gradient-to-r from-yellow-700 to-rose-900",
  "bg-gradient-to-r from-yellow-700 to-rose-950",
  "bg-gradient-to-r from-yellow-800 to-red-300",
  "bg-gradient-to-r from-yellow-800 to-red-400",
  "bg-gradient-to-r from-yellow-800 to-red-500",
  "bg-gradient-to-r from-yellow-800 to-red-600",
  "bg-gradient-to-r from-yellow-800 to-red-700",
  "bg-gradient-to-r from-yellow-800 to-red-800",
  "bg-gradient-to-r from-yellow-800 to-red-900",
  "bg-gradient-to-r from-yellow-800 to-red-950",
  "bg-gradient-to-r from-yellow-800 to-orange-300",
  "bg-gradient-to-r from-yellow-800 to-orange-400",
  "bg-gradient-to-r from-yellow-800 to-orange-500",
  "bg-gradient-to-r from-yellow-800 to-orange-600",
  "bg-gradient-to-r from-yellow-800 to-orange-700",
  "bg-gradient-to-r from-yellow-800 to-orange-800",
  "bg-gradient-to-r from-yellow-800 to-orange-900",
  "bg-gradient-to-r from-yellow-800 to-orange-950",
  "bg-gradient-to-r from-yellow-800 to-amber-300",
  "bg-gradient-to-r from-yellow-800 to-amber-400",
  "bg-gradient-to-r from-yellow-800 to-amber-500",
  "bg-gradient-to-r from-yellow-800 to-amber-600",
  "bg-gradient-to-r from-yellow-800 to-amber-700",
  "bg-gradient-to-r from-yellow-800 to-amber-800",
  "bg-gradient-to-r from-yellow-800 to-amber-900",
  "bg-gradient-to-r from-yellow-800 to-amber-950",
  "bg-gradient-to-r from-yellow-800 to-lime-300",
  "bg-gradient-to-r from-yellow-800 to-lime-400",
  "bg-gradient-to-r from-yellow-800 to-lime-500",
  "bg-gradient-to-r from-yellow-800 to-lime-600",
  "bg-gradient-to-r from-yellow-800 to-lime-700",
  "bg-gradient-to-r from-yellow-800 to-lime-800",
  "bg-gradient-to-r from-yellow-800 to-lime-900",
  "bg-gradient-to-r from-yellow-800 to-lime-950",
  "bg-gradient-to-r from-yellow-800 to-green-300",
  "bg-gradient-to-r from-yellow-800 to-green-400",
  "bg-gradient-to-r from-yellow-800 to-green-500",
  "bg-gradient-to-r from-yellow-800 to-green-600",
  "bg-gradient-to-r from-yellow-800 to-green-700",
  "bg-gradient-to-r from-yellow-800 to-green-800",
  "bg-gradient-to-r from-yellow-800 to-green-900",
  "bg-gradient-to-r from-yellow-800 to-green-950",
  "bg-gradient-to-r from-yellow-800 to-emerald-300",
  "bg-gradient-to-r from-yellow-800 to-emerald-400",
  "bg-gradient-to-r from-yellow-800 to-emerald-500",
  "bg-gradient-to-r from-yellow-800 to-emerald-600",
  "bg-gradient-to-r from-yellow-800 to-emerald-700",
  "bg-gradient-to-r from-yellow-800 to-emerald-800",
  "bg-gradient-to-r from-yellow-800 to-emerald-900",
  "bg-gradient-to-r from-yellow-800 to-emerald-950",
  "bg-gradient-to-r from-yellow-800 to-teal-300",
  "bg-gradient-to-r from-yellow-800 to-teal-400",
  "bg-gradient-to-r from-yellow-800 to-teal-500",
  "bg-gradient-to-r from-yellow-800 to-teal-600",
  "bg-gradient-to-r from-yellow-800 to-teal-700",
  "bg-gradient-to-r from-yellow-800 to-teal-800",
  "bg-gradient-to-r from-yellow-800 to-teal-900",
  "bg-gradient-to-r from-yellow-800 to-teal-950",
  "bg-gradient-to-r from-yellow-800 to-cyan-300",
  "bg-gradient-to-r from-yellow-800 to-cyan-400",
  "bg-gradient-to-r from-yellow-800 to-cyan-500",
  "bg-gradient-to-r from-yellow-800 to-cyan-600",
  "bg-gradient-to-r from-yellow-800 to-cyan-700",
  "bg-gradient-to-r from-yellow-800 to-cyan-800",
  "bg-gradient-to-r from-yellow-800 to-cyan-900",
  "bg-gradient-to-r from-yellow-800 to-cyan-950",
  "bg-gradient-to-r from-yellow-800 to-sky-300",
  "bg-gradient-to-r from-yellow-800 to-sky-400",
  "bg-gradient-to-r from-yellow-800 to-sky-500",
  "bg-gradient-to-r from-yellow-800 to-sky-600",
  "bg-gradient-to-r from-yellow-800 to-sky-700",
  "bg-gradient-to-r from-yellow-800 to-sky-800",
  "bg-gradient-to-r from-yellow-800 to-sky-900",
  "bg-gradient-to-r from-yellow-800 to-sky-950",
  "bg-gradient-to-r from-yellow-800 to-blue-300",
  "bg-gradient-to-r from-yellow-800 to-blue-400",
  "bg-gradient-to-r from-yellow-800 to-blue-500",
  "bg-gradient-to-r from-yellow-800 to-blue-600",
  "bg-gradient-to-r from-yellow-800 to-blue-700",
  "bg-gradient-to-r from-yellow-800 to-blue-800",
  "bg-gradient-to-r from-yellow-800 to-blue-900",
  "bg-gradient-to-r from-yellow-800 to-blue-950",
  "bg-gradient-to-r from-yellow-800 to-indigo-300",
  "bg-gradient-to-r from-yellow-800 to-indigo-400",
  "bg-gradient-to-r from-yellow-800 to-indigo-500",
  "bg-gradient-to-r from-yellow-800 to-indigo-600",
  "bg-gradient-to-r from-yellow-800 to-indigo-700",
  "bg-gradient-to-r from-yellow-800 to-indigo-800",
  "bg-gradient-to-r from-yellow-800 to-indigo-900",
  "bg-gradient-to-r from-yellow-800 to-indigo-950",
  "bg-gradient-to-r from-yellow-800 to-violet-300",
  "bg-gradient-to-r from-yellow-800 to-violet-400",
  "bg-gradient-to-r from-yellow-800 to-violet-500",
  "bg-gradient-to-r from-yellow-800 to-violet-600",
  "bg-gradient-to-r from-yellow-800 to-violet-700",
  "bg-gradient-to-r from-yellow-800 to-violet-800",
  "bg-gradient-to-r from-yellow-800 to-violet-900",
  "bg-gradient-to-r from-yellow-800 to-violet-950",
  "bg-gradient-to-r from-yellow-800 to-purple-300",
  "bg-gradient-to-r from-yellow-800 to-purple-400",
  "bg-gradient-to-r from-yellow-800 to-purple-500",
  "bg-gradient-to-r from-yellow-800 to-purple-600",
  "bg-gradient-to-r from-yellow-800 to-purple-700",
  "bg-gradient-to-r from-yellow-800 to-purple-800",
  "bg-gradient-to-r from-yellow-800 to-purple-900",
  "bg-gradient-to-r from-yellow-800 to-purple-950",
  "bg-gradient-to-r from-yellow-800 to-fuchsia-300",
  "bg-gradient-to-r from-yellow-800 to-fuchsia-400",
  "bg-gradient-to-r from-yellow-800 to-fuchsia-500",
  "bg-gradient-to-r from-yellow-800 to-fuchsia-600",
  "bg-gradient-to-r from-yellow-800 to-fuchsia-700",
  "bg-gradient-to-r from-yellow-800 to-fuchsia-800",
  "bg-gradient-to-r from-yellow-800 to-fuchsia-900",
  "bg-gradient-to-r from-yellow-800 to-fuchsia-950",
  "bg-gradient-to-r from-yellow-800 to-pink-300",
  "bg-gradient-to-r from-yellow-800 to-pink-400",
  "bg-gradient-to-r from-yellow-800 to-pink-500",
  "bg-gradient-to-r from-yellow-800 to-pink-600",
  "bg-gradient-to-r from-yellow-800 to-pink-700",
  "bg-gradient-to-r from-yellow-800 to-pink-800",
  "bg-gradient-to-r from-yellow-800 to-pink-900",
  "bg-gradient-to-r from-yellow-800 to-pink-950",
  "bg-gradient-to-r from-yellow-800 to-rose-300",
  "bg-gradient-to-r from-yellow-800 to-rose-400",
  "bg-gradient-to-r from-yellow-800 to-rose-500",
  "bg-gradient-to-r from-yellow-800 to-rose-600",
  "bg-gradient-to-r from-yellow-800 to-rose-700",
  "bg-gradient-to-r from-yellow-800 to-rose-800",
  "bg-gradient-to-r from-yellow-800 to-rose-900",
  "bg-gradient-to-r from-yellow-800 to-rose-950",
  "bg-gradient-to-r from-yellow-900 to-red-300",
  "bg-gradient-to-r from-yellow-900 to-red-400",
  "bg-gradient-to-r from-yellow-900 to-red-500",
  "bg-gradient-to-r from-yellow-900 to-red-600",
  "bg-gradient-to-r from-yellow-900 to-red-700",
  "bg-gradient-to-r from-yellow-900 to-red-800",
  "bg-gradient-to-r from-yellow-900 to-red-900",
  "bg-gradient-to-r from-yellow-900 to-red-950",
  "bg-gradient-to-r from-yellow-900 to-orange-300",
  "bg-gradient-to-r from-yellow-900 to-orange-400",
  "bg-gradient-to-r from-yellow-900 to-orange-500",
  "bg-gradient-to-r from-yellow-900 to-orange-600",
  "bg-gradient-to-r from-yellow-900 to-orange-700",
  "bg-gradient-to-r from-yellow-900 to-orange-800",
  "bg-gradient-to-r from-yellow-900 to-orange-900",
  "bg-gradient-to-r from-yellow-900 to-orange-950",
  "bg-gradient-to-r from-yellow-900 to-amber-300",
  "bg-gradient-to-r from-yellow-900 to-amber-400",
  "bg-gradient-to-r from-yellow-900 to-amber-500",
  "bg-gradient-to-r from-yellow-900 to-amber-600",
  "bg-gradient-to-r from-yellow-900 to-amber-700",
  "bg-gradient-to-r from-yellow-900 to-amber-800",
  "bg-gradient-to-r from-yellow-900 to-amber-900",
  "bg-gradient-to-r from-yellow-900 to-amber-950",
  "bg-gradient-to-r from-yellow-900 to-lime-300",
  "bg-gradient-to-r from-yellow-900 to-lime-400",
  "bg-gradient-to-r from-yellow-900 to-lime-500",
  "bg-gradient-to-r from-yellow-900 to-lime-600",
  "bg-gradient-to-r from-yellow-900 to-lime-700",
  "bg-gradient-to-r from-yellow-900 to-lime-800",
  "bg-gradient-to-r from-yellow-900 to-lime-900",
  "bg-gradient-to-r from-yellow-900 to-lime-950",
  "bg-gradient-to-r from-yellow-900 to-green-300",
  "bg-gradient-to-r from-yellow-900 to-green-400",
  "bg-gradient-to-r from-yellow-900 to-green-500",
  "bg-gradient-to-r from-yellow-900 to-green-600",
  "bg-gradient-to-r from-yellow-900 to-green-700",
  "bg-gradient-to-r from-yellow-900 to-green-800",
  "bg-gradient-to-r from-yellow-900 to-green-900",
  "bg-gradient-to-r from-yellow-900 to-green-950",
  "bg-gradient-to-r from-yellow-900 to-emerald-300",
  "bg-gradient-to-r from-yellow-900 to-emerald-400",
  "bg-gradient-to-r from-yellow-900 to-emerald-500",
  "bg-gradient-to-r from-yellow-900 to-emerald-600",
  "bg-gradient-to-r from-yellow-900 to-emerald-700",
  "bg-gradient-to-r from-yellow-900 to-emerald-800",
  "bg-gradient-to-r from-yellow-900 to-emerald-900",
  "bg-gradient-to-r from-yellow-900 to-emerald-950",
  "bg-gradient-to-r from-yellow-900 to-teal-300",
  "bg-gradient-to-r from-yellow-900 to-teal-400",
  "bg-gradient-to-r from-yellow-900 to-teal-500",
  "bg-gradient-to-r from-yellow-900 to-teal-600",
  "bg-gradient-to-r from-yellow-900 to-teal-700",
  "bg-gradient-to-r from-yellow-900 to-teal-800",
  "bg-gradient-to-r from-yellow-900 to-teal-900",
  "bg-gradient-to-r from-yellow-900 to-teal-950",
  "bg-gradient-to-r from-yellow-900 to-cyan-300",
  "bg-gradient-to-r from-yellow-900 to-cyan-400",
  "bg-gradient-to-r from-yellow-900 to-cyan-500",
  "bg-gradient-to-r from-yellow-900 to-cyan-600",
  "bg-gradient-to-r from-yellow-900 to-cyan-700",
  "bg-gradient-to-r from-yellow-900 to-cyan-800",
  "bg-gradient-to-r from-yellow-900 to-cyan-900",
  "bg-gradient-to-r from-yellow-900 to-cyan-950",
  "bg-gradient-to-r from-yellow-900 to-sky-300",
  "bg-gradient-to-r from-yellow-900 to-sky-400",
  "bg-gradient-to-r from-yellow-900 to-sky-500",
  "bg-gradient-to-r from-yellow-900 to-sky-600",
  "bg-gradient-to-r from-yellow-900 to-sky-700",
  "bg-gradient-to-r from-yellow-900 to-sky-800",
  "bg-gradient-to-r from-yellow-900 to-sky-900",
  "bg-gradient-to-r from-yellow-900 to-sky-950",
  "bg-gradient-to-r from-yellow-900 to-blue-300",
  "bg-gradient-to-r from-yellow-900 to-blue-400",
  "bg-gradient-to-r from-yellow-900 to-blue-500",
  "bg-gradient-to-r from-yellow-900 to-blue-600",
  "bg-gradient-to-r from-yellow-900 to-blue-700",
  "bg-gradient-to-r from-yellow-900 to-blue-800",
  "bg-gradient-to-r from-yellow-900 to-blue-900",
  "bg-gradient-to-r from-yellow-900 to-blue-950",
  "bg-gradient-to-r from-yellow-900 to-indigo-300",
  "bg-gradient-to-r from-yellow-900 to-indigo-400",
  "bg-gradient-to-r from-yellow-900 to-indigo-500",
  "bg-gradient-to-r from-yellow-900 to-indigo-600",
  "bg-gradient-to-r from-yellow-900 to-indigo-700",
  "bg-gradient-to-r from-yellow-900 to-indigo-800",
  "bg-gradient-to-r from-yellow-900 to-indigo-900",
  "bg-gradient-to-r from-yellow-900 to-indigo-950",
  "bg-gradient-to-r from-yellow-900 to-violet-300",
  "bg-gradient-to-r from-yellow-900 to-violet-400",
  "bg-gradient-to-r from-yellow-900 to-violet-500",
  "bg-gradient-to-r from-yellow-900 to-violet-600",
  "bg-gradient-to-r from-yellow-900 to-violet-700",
  "bg-gradient-to-r from-yellow-900 to-violet-800",
  "bg-gradient-to-r from-yellow-900 to-violet-900",
  "bg-gradient-to-r from-yellow-900 to-violet-950",
  "bg-gradient-to-r from-yellow-900 to-purple-300",
  "bg-gradient-to-r from-yellow-900 to-purple-400",
  "bg-gradient-to-r from-yellow-900 to-purple-500",
  "bg-gradient-to-r from-yellow-900 to-purple-600",
  "bg-gradient-to-r from-yellow-900 to-purple-700",
  "bg-gradient-to-r from-yellow-900 to-purple-800",
  "bg-gradient-to-r from-yellow-900 to-purple-900",
  "bg-gradient-to-r from-yellow-900 to-purple-950",
  "bg-gradient-to-r from-yellow-900 to-fuchsia-300",
  "bg-gradient-to-r from-yellow-900 to-fuchsia-400",
  "bg-gradient-to-r from-yellow-900 to-fuchsia-500",
  "bg-gradient-to-r from-yellow-900 to-fuchsia-600",
  "bg-gradient-to-r from-yellow-900 to-fuchsia-700",
  "bg-gradient-to-r from-yellow-900 to-fuchsia-800",
  "bg-gradient-to-r from-yellow-900 to-fuchsia-900",
  "bg-gradient-to-r from-yellow-900 to-fuchsia-950",
  "bg-gradient-to-r from-yellow-900 to-pink-300",
  "bg-gradient-to-r from-yellow-900 to-pink-400",
  "bg-gradient-to-r from-yellow-900 to-pink-500",
  "bg-gradient-to-r from-yellow-900 to-pink-600",
  "bg-gradient-to-r from-yellow-900 to-pink-700",
  "bg-gradient-to-r from-yellow-900 to-pink-800",
  "bg-gradient-to-r from-yellow-900 to-pink-900",
  "bg-gradient-to-r from-yellow-900 to-pink-950",
  "bg-gradient-to-r from-yellow-900 to-rose-300",
  "bg-gradient-to-r from-yellow-900 to-rose-400",
  "bg-gradient-to-r from-yellow-900 to-rose-500",
  "bg-gradient-to-r from-yellow-900 to-rose-600",
  "bg-gradient-to-r from-yellow-900 to-rose-700",
  "bg-gradient-to-r from-yellow-900 to-rose-800",
  "bg-gradient-to-r from-yellow-900 to-rose-900",
  "bg-gradient-to-r from-yellow-900 to-rose-950",
  "bg-gradient-to-r from-yellow-950 to-red-300",
  "bg-gradient-to-r from-yellow-950 to-red-400",
  "bg-gradient-to-r from-yellow-950 to-red-500",
  "bg-gradient-to-r from-yellow-950 to-red-600",
  "bg-gradient-to-r from-yellow-950 to-red-700",
  "bg-gradient-to-r from-yellow-950 to-red-800",
  "bg-gradient-to-r from-yellow-950 to-red-900",
  "bg-gradient-to-r from-yellow-950 to-red-950",
  "bg-gradient-to-r from-yellow-950 to-orange-300",
  "bg-gradient-to-r from-yellow-950 to-orange-400",
  "bg-gradient-to-r from-yellow-950 to-orange-500",
  "bg-gradient-to-r from-yellow-950 to-orange-600",
  "bg-gradient-to-r from-yellow-950 to-orange-700",
  "bg-gradient-to-r from-yellow-950 to-orange-800",
  "bg-gradient-to-r from-yellow-950 to-orange-900",
  "bg-gradient-to-r from-yellow-950 to-orange-950",
  "bg-gradient-to-r from-yellow-950 to-amber-300",
  "bg-gradient-to-r from-yellow-950 to-amber-400",
  "bg-gradient-to-r from-yellow-950 to-amber-500",
  "bg-gradient-to-r from-yellow-950 to-amber-600",
  "bg-gradient-to-r from-yellow-950 to-amber-700",
  "bg-gradient-to-r from-yellow-950 to-amber-800",
  "bg-gradient-to-r from-yellow-950 to-amber-900",
  "bg-gradient-to-r from-yellow-950 to-amber-950",
  "bg-gradient-to-r from-yellow-950 to-lime-300",
  "bg-gradient-to-r from-yellow-950 to-lime-400",
  "bg-gradient-to-r from-yellow-950 to-lime-500",
  "bg-gradient-to-r from-yellow-950 to-lime-600",
  "bg-gradient-to-r from-yellow-950 to-lime-700",
  "bg-gradient-to-r from-yellow-950 to-lime-800",
  "bg-gradient-to-r from-yellow-950 to-lime-900",
  "bg-gradient-to-r from-yellow-950 to-lime-950",
  "bg-gradient-to-r from-yellow-950 to-green-300",
  "bg-gradient-to-r from-yellow-950 to-green-400",
  "bg-gradient-to-r from-yellow-950 to-green-500",
  "bg-gradient-to-r from-yellow-950 to-green-600",
  "bg-gradient-to-r from-yellow-950 to-green-700",
  "bg-gradient-to-r from-yellow-950 to-green-800",
  "bg-gradient-to-r from-yellow-950 to-green-900",
  "bg-gradient-to-r from-yellow-950 to-green-950",
  "bg-gradient-to-r from-yellow-950 to-emerald-300",
  "bg-gradient-to-r from-yellow-950 to-emerald-400",
  "bg-gradient-to-r from-yellow-950 to-emerald-500",
  "bg-gradient-to-r from-yellow-950 to-emerald-600",
  "bg-gradient-to-r from-yellow-950 to-emerald-700",
  "bg-gradient-to-r from-yellow-950 to-emerald-800",
  "bg-gradient-to-r from-yellow-950 to-emerald-900",
  "bg-gradient-to-r from-yellow-950 to-emerald-950",
  "bg-gradient-to-r from-yellow-950 to-teal-300",
  "bg-gradient-to-r from-yellow-950 to-teal-400",
  "bg-gradient-to-r from-yellow-950 to-teal-500",
  "bg-gradient-to-r from-yellow-950 to-teal-600",
  "bg-gradient-to-r from-yellow-950 to-teal-700",
  "bg-gradient-to-r from-yellow-950 to-teal-800",
  "bg-gradient-to-r from-yellow-950 to-teal-900",
  "bg-gradient-to-r from-yellow-950 to-teal-950",
  "bg-gradient-to-r from-yellow-950 to-cyan-300",
  "bg-gradient-to-r from-yellow-950 to-cyan-400",
  "bg-gradient-to-r from-yellow-950 to-cyan-500",
  "bg-gradient-to-r from-yellow-950 to-cyan-600",
  "bg-gradient-to-r from-yellow-950 to-cyan-700",
  "bg-gradient-to-r from-yellow-950 to-cyan-800",
  "bg-gradient-to-r from-yellow-950 to-cyan-900",
  "bg-gradient-to-r from-yellow-950 to-cyan-950",
  "bg-gradient-to-r from-yellow-950 to-sky-300",
  "bg-gradient-to-r from-yellow-950 to-sky-400",
  "bg-gradient-to-r from-yellow-950 to-sky-500",
  "bg-gradient-to-r from-yellow-950 to-sky-600",
  "bg-gradient-to-r from-yellow-950 to-sky-700",
  "bg-gradient-to-r from-yellow-950 to-sky-800",
  "bg-gradient-to-r from-yellow-950 to-sky-900",
  "bg-gradient-to-r from-yellow-950 to-sky-950",
  "bg-gradient-to-r from-yellow-950 to-blue-300",
  "bg-gradient-to-r from-yellow-950 to-blue-400",
  "bg-gradient-to-r from-yellow-950 to-blue-500",
  "bg-gradient-to-r from-yellow-950 to-blue-600",
  "bg-gradient-to-r from-yellow-950 to-blue-700",
  "bg-gradient-to-r from-yellow-950 to-blue-800",
  "bg-gradient-to-r from-yellow-950 to-blue-900",
  "bg-gradient-to-r from-yellow-950 to-blue-950",
  "bg-gradient-to-r from-yellow-950 to-indigo-300",
  "bg-gradient-to-r from-yellow-950 to-indigo-400",
  "bg-gradient-to-r from-yellow-950 to-indigo-500",
  "bg-gradient-to-r from-yellow-950 to-indigo-600",
  "bg-gradient-to-r from-yellow-950 to-indigo-700",
  "bg-gradient-to-r from-yellow-950 to-indigo-800",
  "bg-gradient-to-r from-yellow-950 to-indigo-900",
  "bg-gradient-to-r from-yellow-950 to-indigo-950",
  "bg-gradient-to-r from-yellow-950 to-violet-300",
  "bg-gradient-to-r from-yellow-950 to-violet-400",
  "bg-gradient-to-r from-yellow-950 to-violet-500",
  "bg-gradient-to-r from-yellow-950 to-violet-600",
  "bg-gradient-to-r from-yellow-950 to-violet-700",
  "bg-gradient-to-r from-yellow-950 to-violet-800",
  "bg-gradient-to-r from-yellow-950 to-violet-900",
  "bg-gradient-to-r from-yellow-950 to-violet-950",
  "bg-gradient-to-r from-yellow-950 to-purple-300",
  "bg-gradient-to-r from-yellow-950 to-purple-400",
  "bg-gradient-to-r from-yellow-950 to-purple-500",
  "bg-gradient-to-r from-yellow-950 to-purple-600",
  "bg-gradient-to-r from-yellow-950 to-purple-700",
  "bg-gradient-to-r from-yellow-950 to-purple-800",
  "bg-gradient-to-r from-yellow-950 to-purple-900",
  "bg-gradient-to-r from-yellow-950 to-purple-950",
  "bg-gradient-to-r from-yellow-950 to-fuchsia-300",
  "bg-gradient-to-r from-yellow-950 to-fuchsia-400",
  "bg-gradient-to-r from-yellow-950 to-fuchsia-500",
  "bg-gradient-to-r from-yellow-950 to-fuchsia-600",
  "bg-gradient-to-r from-yellow-950 to-fuchsia-700",
  "bg-gradient-to-r from-yellow-950 to-fuchsia-800",
  "bg-gradient-to-r from-yellow-950 to-fuchsia-900",
  "bg-gradient-to-r from-yellow-950 to-fuchsia-950",
  "bg-gradient-to-r from-yellow-950 to-pink-300",
  "bg-gradient-to-r from-yellow-950 to-pink-400",
  "bg-gradient-to-r from-yellow-950 to-pink-500",
  "bg-gradient-to-r from-yellow-950 to-pink-600",
  "bg-gradient-to-r from-yellow-950 to-pink-700",
  "bg-gradient-to-r from-yellow-950 to-pink-800",
  "bg-gradient-to-r from-yellow-950 to-pink-900",
  "bg-gradient-to-r from-yellow-950 to-pink-950",
  "bg-gradient-to-r from-yellow-950 to-rose-300",
  "bg-gradient-to-r from-yellow-950 to-rose-400",
  "bg-gradient-to-r from-yellow-950 to-rose-500",
  "bg-gradient-to-r from-yellow-950 to-rose-600",
  "bg-gradient-to-r from-yellow-950 to-rose-700",
  "bg-gradient-to-r from-yellow-950 to-rose-800",
  "bg-gradient-to-r from-yellow-950 to-rose-900",
  "bg-gradient-to-r from-yellow-950 to-rose-950",
  "bg-gradient-to-r from-lime-300 to-red-300",
  "bg-gradient-to-r from-lime-300 to-red-400",
  "bg-gradient-to-r from-lime-300 to-red-500",
  "bg-gradient-to-r from-lime-300 to-red-600",
  "bg-gradient-to-r from-lime-300 to-red-700",
  "bg-gradient-to-r from-lime-300 to-red-800",
  "bg-gradient-to-r from-lime-300 to-red-900",
  "bg-gradient-to-r from-lime-300 to-red-950",
  "bg-gradient-to-r from-lime-300 to-orange-300",
  "bg-gradient-to-r from-lime-300 to-orange-400",
  "bg-gradient-to-r from-lime-300 to-orange-500",
  "bg-gradient-to-r from-lime-300 to-orange-600",
  "bg-gradient-to-r from-lime-300 to-orange-700",
  "bg-gradient-to-r from-lime-300 to-orange-800",
  "bg-gradient-to-r from-lime-300 to-orange-900",
  "bg-gradient-to-r from-lime-300 to-orange-950",
  "bg-gradient-to-r from-lime-300 to-amber-300",
  "bg-gradient-to-r from-lime-300 to-amber-400",
  "bg-gradient-to-r from-lime-300 to-amber-500",
  "bg-gradient-to-r from-lime-300 to-amber-600",
  "bg-gradient-to-r from-lime-300 to-amber-700",
  "bg-gradient-to-r from-lime-300 to-amber-800",
  "bg-gradient-to-r from-lime-300 to-amber-900",
  "bg-gradient-to-r from-lime-300 to-amber-950",
  "bg-gradient-to-r from-lime-300 to-yellow-300",
  "bg-gradient-to-r from-lime-300 to-yellow-400",
  "bg-gradient-to-r from-lime-300 to-yellow-500",
  "bg-gradient-to-r from-lime-300 to-yellow-600",
  "bg-gradient-to-r from-lime-300 to-yellow-700",
  "bg-gradient-to-r from-lime-300 to-yellow-800",
  "bg-gradient-to-r from-lime-300 to-yellow-900",
  "bg-gradient-to-r from-lime-300 to-yellow-950",
  "bg-gradient-to-r from-lime-300 to-green-300",
  "bg-gradient-to-r from-lime-300 to-green-400",
  "bg-gradient-to-r from-lime-300 to-green-500",
  "bg-gradient-to-r from-lime-300 to-green-600",
  "bg-gradient-to-r from-lime-300 to-green-700",
  "bg-gradient-to-r from-lime-300 to-green-800",
  "bg-gradient-to-r from-lime-300 to-green-900",
  "bg-gradient-to-r from-lime-300 to-green-950",
  "bg-gradient-to-r from-lime-300 to-emerald-300",
  "bg-gradient-to-r from-lime-300 to-emerald-400",
  "bg-gradient-to-r from-lime-300 to-emerald-500",
  "bg-gradient-to-r from-lime-300 to-emerald-600",
  "bg-gradient-to-r from-lime-300 to-emerald-700",
  "bg-gradient-to-r from-lime-300 to-emerald-800",
  "bg-gradient-to-r from-lime-300 to-emerald-900",
  "bg-gradient-to-r from-lime-300 to-emerald-950",
  "bg-gradient-to-r from-lime-300 to-teal-300",
  "bg-gradient-to-r from-lime-300 to-teal-400",
  "bg-gradient-to-r from-lime-300 to-teal-500",
  "bg-gradient-to-r from-lime-300 to-teal-600",
  "bg-gradient-to-r from-lime-300 to-teal-700",
  "bg-gradient-to-r from-lime-300 to-teal-800",
  "bg-gradient-to-r from-lime-300 to-teal-900",
  "bg-gradient-to-r from-lime-300 to-teal-950",
  "bg-gradient-to-r from-lime-300 to-cyan-300",
  "bg-gradient-to-r from-lime-300 to-cyan-400",
  "bg-gradient-to-r from-lime-300 to-cyan-500",
  "bg-gradient-to-r from-lime-300 to-cyan-600",
  "bg-gradient-to-r from-lime-300 to-cyan-700",
  "bg-gradient-to-r from-lime-300 to-cyan-800",
  "bg-gradient-to-r from-lime-300 to-cyan-900",
  "bg-gradient-to-r from-lime-300 to-cyan-950",
  "bg-gradient-to-r from-lime-300 to-sky-300",
  "bg-gradient-to-r from-lime-300 to-sky-400",
  "bg-gradient-to-r from-lime-300 to-sky-500",
  "bg-gradient-to-r from-lime-300 to-sky-600",
  "bg-gradient-to-r from-lime-300 to-sky-700",
  "bg-gradient-to-r from-lime-300 to-sky-800",
  "bg-gradient-to-r from-lime-300 to-sky-900",
  "bg-gradient-to-r from-lime-300 to-sky-950",
  "bg-gradient-to-r from-lime-300 to-blue-300",
  "bg-gradient-to-r from-lime-300 to-blue-400",
  "bg-gradient-to-r from-lime-300 to-blue-500",
  "bg-gradient-to-r from-lime-300 to-blue-600",
  "bg-gradient-to-r from-lime-300 to-blue-700",
  "bg-gradient-to-r from-lime-300 to-blue-800",
  "bg-gradient-to-r from-lime-300 to-blue-900",
  "bg-gradient-to-r from-lime-300 to-blue-950",
  "bg-gradient-to-r from-lime-300 to-indigo-300",
  "bg-gradient-to-r from-lime-300 to-indigo-400",
  "bg-gradient-to-r from-lime-300 to-indigo-500",
  "bg-gradient-to-r from-lime-300 to-indigo-600",
  "bg-gradient-to-r from-lime-300 to-indigo-700",
  "bg-gradient-to-r from-lime-300 to-indigo-800",
  "bg-gradient-to-r from-lime-300 to-indigo-900",
  "bg-gradient-to-r from-lime-300 to-indigo-950",
  "bg-gradient-to-r from-lime-300 to-violet-300",
  "bg-gradient-to-r from-lime-300 to-violet-400",
  "bg-gradient-to-r from-lime-300 to-violet-500",
  "bg-gradient-to-r from-lime-300 to-violet-600",
  "bg-gradient-to-r from-lime-300 to-violet-700",
  "bg-gradient-to-r from-lime-300 to-violet-800",
  "bg-gradient-to-r from-lime-300 to-violet-900",
  "bg-gradient-to-r from-lime-300 to-violet-950",
  "bg-gradient-to-r from-lime-300 to-purple-300",
  "bg-gradient-to-r from-lime-300 to-purple-400",
  "bg-gradient-to-r from-lime-300 to-purple-500",
  "bg-gradient-to-r from-lime-300 to-purple-600",
  "bg-gradient-to-r from-lime-300 to-purple-700",
  "bg-gradient-to-r from-lime-300 to-purple-800",
  "bg-gradient-to-r from-lime-300 to-purple-900",
  "bg-gradient-to-r from-lime-300 to-purple-950",
  "bg-gradient-to-r from-lime-300 to-fuchsia-300",
  "bg-gradient-to-r from-lime-300 to-fuchsia-400",
  "bg-gradient-to-r from-lime-300 to-fuchsia-500",
  "bg-gradient-to-r from-lime-300 to-fuchsia-600",
  "bg-gradient-to-r from-lime-300 to-fuchsia-700",
  "bg-gradient-to-r from-lime-300 to-fuchsia-800",
  "bg-gradient-to-r from-lime-300 to-fuchsia-900",
  "bg-gradient-to-r from-lime-300 to-fuchsia-950",
  "bg-gradient-to-r from-lime-300 to-pink-300",
  "bg-gradient-to-r from-lime-300 to-pink-400",
  "bg-gradient-to-r from-lime-300 to-pink-500",
  "bg-gradient-to-r from-lime-300 to-pink-600",
  "bg-gradient-to-r from-lime-300 to-pink-700",
  "bg-gradient-to-r from-lime-300 to-pink-800",
  "bg-gradient-to-r from-lime-300 to-pink-900",
  "bg-gradient-to-r from-lime-300 to-pink-950",
  "bg-gradient-to-r from-lime-300 to-rose-300",
  "bg-gradient-to-r from-lime-300 to-rose-400",
  "bg-gradient-to-r from-lime-300 to-rose-500",
  "bg-gradient-to-r from-lime-300 to-rose-600",
  "bg-gradient-to-r from-lime-300 to-rose-700",
  "bg-gradient-to-r from-lime-300 to-rose-800",
  "bg-gradient-to-r from-lime-300 to-rose-900",
  "bg-gradient-to-r from-lime-300 to-rose-950",
  "bg-gradient-to-r from-lime-400 to-red-300",
  "bg-gradient-to-r from-lime-400 to-red-400",
  "bg-gradient-to-r from-lime-400 to-red-500",
  "bg-gradient-to-r from-lime-400 to-red-600",
  "bg-gradient-to-r from-lime-400 to-red-700",
  "bg-gradient-to-r from-lime-400 to-red-800",
  "bg-gradient-to-r from-lime-400 to-red-900",
  "bg-gradient-to-r from-lime-400 to-red-950",
  "bg-gradient-to-r from-lime-400 to-orange-300",
  "bg-gradient-to-r from-lime-400 to-orange-400",
  "bg-gradient-to-r from-lime-400 to-orange-500",
  "bg-gradient-to-r from-lime-400 to-orange-600",
  "bg-gradient-to-r from-lime-400 to-orange-700",
  "bg-gradient-to-r from-lime-400 to-orange-800",
  "bg-gradient-to-r from-lime-400 to-orange-900",
  "bg-gradient-to-r from-lime-400 to-orange-950",
  "bg-gradient-to-r from-lime-400 to-amber-300",
  "bg-gradient-to-r from-lime-400 to-amber-400",
  "bg-gradient-to-r from-lime-400 to-amber-500",
  "bg-gradient-to-r from-lime-400 to-amber-600",
  "bg-gradient-to-r from-lime-400 to-amber-700",
  "bg-gradient-to-r from-lime-400 to-amber-800",
  "bg-gradient-to-r from-lime-400 to-amber-900",
  "bg-gradient-to-r from-lime-400 to-amber-950",
  "bg-gradient-to-r from-lime-400 to-yellow-300",
  "bg-gradient-to-r from-lime-400 to-yellow-400",
  "bg-gradient-to-r from-lime-400 to-yellow-500",
  "bg-gradient-to-r from-lime-400 to-yellow-600",
  "bg-gradient-to-r from-lime-400 to-yellow-700",
  "bg-gradient-to-r from-lime-400 to-yellow-800",
  "bg-gradient-to-r from-lime-400 to-yellow-900",
  "bg-gradient-to-r from-lime-400 to-yellow-950",
  "bg-gradient-to-r from-lime-400 to-green-300",
  "bg-gradient-to-r from-lime-400 to-green-400",
  "bg-gradient-to-r from-lime-400 to-green-500",
  "bg-gradient-to-r from-lime-400 to-green-600",
  "bg-gradient-to-r from-lime-400 to-green-700",
  "bg-gradient-to-r from-lime-400 to-green-800",
  "bg-gradient-to-r from-lime-400 to-green-900",
  "bg-gradient-to-r from-lime-400 to-green-950",
  "bg-gradient-to-r from-lime-400 to-emerald-300",
  "bg-gradient-to-r from-lime-400 to-emerald-400",
  "bg-gradient-to-r from-lime-400 to-emerald-500",
  "bg-gradient-to-r from-lime-400 to-emerald-600",
  "bg-gradient-to-r from-lime-400 to-emerald-700",
  "bg-gradient-to-r from-lime-400 to-emerald-800",
  "bg-gradient-to-r from-lime-400 to-emerald-900",
  "bg-gradient-to-r from-lime-400 to-emerald-950",
  "bg-gradient-to-r from-lime-400 to-teal-300",
  "bg-gradient-to-r from-lime-400 to-teal-400",
  "bg-gradient-to-r from-lime-400 to-teal-500",
  "bg-gradient-to-r from-lime-400 to-teal-600",
  "bg-gradient-to-r from-lime-400 to-teal-700",
  "bg-gradient-to-r from-lime-400 to-teal-800",
  "bg-gradient-to-r from-lime-400 to-teal-900",
  "bg-gradient-to-r from-lime-400 to-teal-950",
  "bg-gradient-to-r from-lime-400 to-cyan-300",
  "bg-gradient-to-r from-lime-400 to-cyan-400",
  "bg-gradient-to-r from-lime-400 to-cyan-500",
  "bg-gradient-to-r from-lime-400 to-cyan-600",
  "bg-gradient-to-r from-lime-400 to-cyan-700",
  "bg-gradient-to-r from-lime-400 to-cyan-800",
  "bg-gradient-to-r from-lime-400 to-cyan-900",
  "bg-gradient-to-r from-lime-400 to-cyan-950",
  "bg-gradient-to-r from-lime-400 to-sky-300",
  "bg-gradient-to-r from-lime-400 to-sky-400",
  "bg-gradient-to-r from-lime-400 to-sky-500",
  "bg-gradient-to-r from-lime-400 to-sky-600",
  "bg-gradient-to-r from-lime-400 to-sky-700",
  "bg-gradient-to-r from-lime-400 to-sky-800",
  "bg-gradient-to-r from-lime-400 to-sky-900",
  "bg-gradient-to-r from-lime-400 to-sky-950",
  "bg-gradient-to-r from-lime-400 to-blue-300",
  "bg-gradient-to-r from-lime-400 to-blue-400",
  "bg-gradient-to-r from-lime-400 to-blue-500",
  "bg-gradient-to-r from-lime-400 to-blue-600",
  "bg-gradient-to-r from-lime-400 to-blue-700",
  "bg-gradient-to-r from-lime-400 to-blue-800",
  "bg-gradient-to-r from-lime-400 to-blue-900",
  "bg-gradient-to-r from-lime-400 to-blue-950",
  "bg-gradient-to-r from-lime-400 to-indigo-300",
  "bg-gradient-to-r from-lime-400 to-indigo-400",
  "bg-gradient-to-r from-lime-400 to-indigo-500",
  "bg-gradient-to-r from-lime-400 to-indigo-600",
  "bg-gradient-to-r from-lime-400 to-indigo-700",
  "bg-gradient-to-r from-lime-400 to-indigo-800",
  "bg-gradient-to-r from-lime-400 to-indigo-900",
  "bg-gradient-to-r from-lime-400 to-indigo-950",
  "bg-gradient-to-r from-lime-400 to-violet-300",
  "bg-gradient-to-r from-lime-400 to-violet-400",
  "bg-gradient-to-r from-lime-400 to-violet-500",
  "bg-gradient-to-r from-lime-400 to-violet-600",
  "bg-gradient-to-r from-lime-400 to-violet-700",
  "bg-gradient-to-r from-lime-400 to-violet-800",
  "bg-gradient-to-r from-lime-400 to-violet-900",
  "bg-gradient-to-r from-lime-400 to-violet-950",
  "bg-gradient-to-r from-lime-400 to-purple-300",
  "bg-gradient-to-r from-lime-400 to-purple-400",
  "bg-gradient-to-r from-lime-400 to-purple-500",
  "bg-gradient-to-r from-lime-400 to-purple-600",
  "bg-gradient-to-r from-lime-400 to-purple-700",
  "bg-gradient-to-r from-lime-400 to-purple-800",
  "bg-gradient-to-r from-lime-400 to-purple-900",
  "bg-gradient-to-r from-lime-400 to-purple-950",
  "bg-gradient-to-r from-lime-400 to-fuchsia-300",
  "bg-gradient-to-r from-lime-400 to-fuchsia-400",
  "bg-gradient-to-r from-lime-400 to-fuchsia-500",
  "bg-gradient-to-r from-lime-400 to-fuchsia-600",
  "bg-gradient-to-r from-lime-400 to-fuchsia-700",
  "bg-gradient-to-r from-lime-400 to-fuchsia-800",
  "bg-gradient-to-r from-lime-400 to-fuchsia-900",
  "bg-gradient-to-r from-lime-400 to-fuchsia-950",
  "bg-gradient-to-r from-lime-400 to-pink-300",
  "bg-gradient-to-r from-lime-400 to-pink-400",
  "bg-gradient-to-r from-lime-400 to-pink-500",
  "bg-gradient-to-r from-lime-400 to-pink-600",
  "bg-gradient-to-r from-lime-400 to-pink-700",
  "bg-gradient-to-r from-lime-400 to-pink-800",
  "bg-gradient-to-r from-lime-400 to-pink-900",
  "bg-gradient-to-r from-lime-400 to-pink-950",
  "bg-gradient-to-r from-lime-400 to-rose-300",
  "bg-gradient-to-r from-lime-400 to-rose-400",
  "bg-gradient-to-r from-lime-400 to-rose-500",
  "bg-gradient-to-r from-lime-400 to-rose-600",
  "bg-gradient-to-r from-lime-400 to-rose-700",
  "bg-gradient-to-r from-lime-400 to-rose-800",
  "bg-gradient-to-r from-lime-400 to-rose-900",
  "bg-gradient-to-r from-lime-400 to-rose-950",
  "bg-gradient-to-r from-lime-500 to-red-300",
  "bg-gradient-to-r from-lime-500 to-red-400",
  "bg-gradient-to-r from-lime-500 to-red-500",
  "bg-gradient-to-r from-lime-500 to-red-600",
  "bg-gradient-to-r from-lime-500 to-red-700",
  "bg-gradient-to-r from-lime-500 to-red-800",
  "bg-gradient-to-r from-lime-500 to-red-900",
  "bg-gradient-to-r from-lime-500 to-red-950",
  "bg-gradient-to-r from-lime-500 to-orange-300",
  "bg-gradient-to-r from-lime-500 to-orange-400",
  "bg-gradient-to-r from-lime-500 to-orange-500",
  "bg-gradient-to-r from-lime-500 to-orange-600",
  "bg-gradient-to-r from-lime-500 to-orange-700",
  "bg-gradient-to-r from-lime-500 to-orange-800",
  "bg-gradient-to-r from-lime-500 to-orange-900",
  "bg-gradient-to-r from-lime-500 to-orange-950",
  "bg-gradient-to-r from-lime-500 to-amber-300",
  "bg-gradient-to-r from-lime-500 to-amber-400",
  "bg-gradient-to-r from-lime-500 to-amber-500",
  "bg-gradient-to-r from-lime-500 to-amber-600",
  "bg-gradient-to-r from-lime-500 to-amber-700",
  "bg-gradient-to-r from-lime-500 to-amber-800",
  "bg-gradient-to-r from-lime-500 to-amber-900",
  "bg-gradient-to-r from-lime-500 to-amber-950",
  "bg-gradient-to-r from-lime-500 to-yellow-300",
  "bg-gradient-to-r from-lime-500 to-yellow-400",
  "bg-gradient-to-r from-lime-500 to-yellow-500",
  "bg-gradient-to-r from-lime-500 to-yellow-600",
  "bg-gradient-to-r from-lime-500 to-yellow-700",
  "bg-gradient-to-r from-lime-500 to-yellow-800",
  "bg-gradient-to-r from-lime-500 to-yellow-900",
  "bg-gradient-to-r from-lime-500 to-yellow-950",
  "bg-gradient-to-r from-lime-500 to-green-300",
  "bg-gradient-to-r from-lime-500 to-green-400",
  "bg-gradient-to-r from-lime-500 to-green-500",
  "bg-gradient-to-r from-lime-500 to-green-600",
  "bg-gradient-to-r from-lime-500 to-green-700",
  "bg-gradient-to-r from-lime-500 to-green-800",
  "bg-gradient-to-r from-lime-500 to-green-900",
  "bg-gradient-to-r from-lime-500 to-green-950",
  "bg-gradient-to-r from-lime-500 to-emerald-300",
  "bg-gradient-to-r from-lime-500 to-emerald-400",
  "bg-gradient-to-r from-lime-500 to-emerald-500",
  "bg-gradient-to-r from-lime-500 to-emerald-600",
  "bg-gradient-to-r from-lime-500 to-emerald-700",
  "bg-gradient-to-r from-lime-500 to-emerald-800",
  "bg-gradient-to-r from-lime-500 to-emerald-900",
  "bg-gradient-to-r from-lime-500 to-emerald-950",
  "bg-gradient-to-r from-lime-500 to-teal-300",
  "bg-gradient-to-r from-lime-500 to-teal-400",
  "bg-gradient-to-r from-lime-500 to-teal-500",
  "bg-gradient-to-r from-lime-500 to-teal-600",
  "bg-gradient-to-r from-lime-500 to-teal-700",
  "bg-gradient-to-r from-lime-500 to-teal-800",
  "bg-gradient-to-r from-lime-500 to-teal-900",
  "bg-gradient-to-r from-lime-500 to-teal-950",
  "bg-gradient-to-r from-lime-500 to-cyan-300",
  "bg-gradient-to-r from-lime-500 to-cyan-400",
  "bg-gradient-to-r from-lime-500 to-cyan-500",
  "bg-gradient-to-r from-lime-500 to-cyan-600",
  "bg-gradient-to-r from-lime-500 to-cyan-700",
  "bg-gradient-to-r from-lime-500 to-cyan-800",
  "bg-gradient-to-r from-lime-500 to-cyan-900",
  "bg-gradient-to-r from-lime-500 to-cyan-950",
  "bg-gradient-to-r from-lime-500 to-sky-300",
  "bg-gradient-to-r from-lime-500 to-sky-400",
  "bg-gradient-to-r from-lime-500 to-sky-500",
  "bg-gradient-to-r from-lime-500 to-sky-600",
  "bg-gradient-to-r from-lime-500 to-sky-700",
  "bg-gradient-to-r from-lime-500 to-sky-800",
  "bg-gradient-to-r from-lime-500 to-sky-900",
  "bg-gradient-to-r from-lime-500 to-sky-950",
  "bg-gradient-to-r from-lime-500 to-blue-300",
  "bg-gradient-to-r from-lime-500 to-blue-400",
  "bg-gradient-to-r from-lime-500 to-blue-500",
  "bg-gradient-to-r from-lime-500 to-blue-600",
  "bg-gradient-to-r from-lime-500 to-blue-700",
  "bg-gradient-to-r from-lime-500 to-blue-800",
  "bg-gradient-to-r from-lime-500 to-blue-900",
  "bg-gradient-to-r from-lime-500 to-blue-950",
  "bg-gradient-to-r from-lime-500 to-indigo-300",
  "bg-gradient-to-r from-lime-500 to-indigo-400",
  "bg-gradient-to-r from-lime-500 to-indigo-500",
  "bg-gradient-to-r from-lime-500 to-indigo-600",
  "bg-gradient-to-r from-lime-500 to-indigo-700",
  "bg-gradient-to-r from-lime-500 to-indigo-800",
  "bg-gradient-to-r from-lime-500 to-indigo-900",
  "bg-gradient-to-r from-lime-500 to-indigo-950",
  "bg-gradient-to-r from-lime-500 to-violet-300",
  "bg-gradient-to-r from-lime-500 to-violet-400",
  "bg-gradient-to-r from-lime-500 to-violet-500",
  "bg-gradient-to-r from-lime-500 to-violet-600",
  "bg-gradient-to-r from-lime-500 to-violet-700",
  "bg-gradient-to-r from-lime-500 to-violet-800",
  "bg-gradient-to-r from-lime-500 to-violet-900",
  "bg-gradient-to-r from-lime-500 to-violet-950",
  "bg-gradient-to-r from-lime-500 to-purple-300",
  "bg-gradient-to-r from-lime-500 to-purple-400",
  "bg-gradient-to-r from-lime-500 to-purple-500",
  "bg-gradient-to-r from-lime-500 to-purple-600",
  "bg-gradient-to-r from-lime-500 to-purple-700",
  "bg-gradient-to-r from-lime-500 to-purple-800",
  "bg-gradient-to-r from-lime-500 to-purple-900",
  "bg-gradient-to-r from-lime-500 to-purple-950",
  "bg-gradient-to-r from-lime-500 to-fuchsia-300",
  "bg-gradient-to-r from-lime-500 to-fuchsia-400",
  "bg-gradient-to-r from-lime-500 to-fuchsia-500",
  "bg-gradient-to-r from-lime-500 to-fuchsia-600",
  "bg-gradient-to-r from-lime-500 to-fuchsia-700",
  "bg-gradient-to-r from-lime-500 to-fuchsia-800",
  "bg-gradient-to-r from-lime-500 to-fuchsia-900",
  "bg-gradient-to-r from-lime-500 to-fuchsia-950",
  "bg-gradient-to-r from-lime-500 to-pink-300",
  "bg-gradient-to-r from-lime-500 to-pink-400",
  "bg-gradient-to-r from-lime-500 to-pink-500",
  "bg-gradient-to-r from-lime-500 to-pink-600",
  "bg-gradient-to-r from-lime-500 to-pink-700",
  "bg-gradient-to-r from-lime-500 to-pink-800",
  "bg-gradient-to-r from-lime-500 to-pink-900",
  "bg-gradient-to-r from-lime-500 to-pink-950",
  "bg-gradient-to-r from-lime-500 to-rose-300",
  "bg-gradient-to-r from-lime-500 to-rose-400",
  "bg-gradient-to-r from-lime-500 to-rose-500",
  "bg-gradient-to-r from-lime-500 to-rose-600",
  "bg-gradient-to-r from-lime-500 to-rose-700",
  "bg-gradient-to-r from-lime-500 to-rose-800",
  "bg-gradient-to-r from-lime-500 to-rose-900",
  "bg-gradient-to-r from-lime-500 to-rose-950",
  "bg-gradient-to-r from-lime-600 to-red-300",
  "bg-gradient-to-r from-lime-600 to-red-400",
  "bg-gradient-to-r from-lime-600 to-red-500",
  "bg-gradient-to-r from-lime-600 to-red-600",
  "bg-gradient-to-r from-lime-600 to-red-700",
  "bg-gradient-to-r from-lime-600 to-red-800",
  "bg-gradient-to-r from-lime-600 to-red-900",
  "bg-gradient-to-r from-lime-600 to-red-950",
  "bg-gradient-to-r from-lime-600 to-orange-300",
  "bg-gradient-to-r from-lime-600 to-orange-400",
  "bg-gradient-to-r from-lime-600 to-orange-500",
  "bg-gradient-to-r from-lime-600 to-orange-600",
  "bg-gradient-to-r from-lime-600 to-orange-700",
  "bg-gradient-to-r from-lime-600 to-orange-800",
  "bg-gradient-to-r from-lime-600 to-orange-900",
  "bg-gradient-to-r from-lime-600 to-orange-950",
  "bg-gradient-to-r from-lime-600 to-amber-300",
  "bg-gradient-to-r from-lime-600 to-amber-400",
  "bg-gradient-to-r from-lime-600 to-amber-500",
  "bg-gradient-to-r from-lime-600 to-amber-600",
  "bg-gradient-to-r from-lime-600 to-amber-700",
  "bg-gradient-to-r from-lime-600 to-amber-800",
  "bg-gradient-to-r from-lime-600 to-amber-900",
  "bg-gradient-to-r from-lime-600 to-amber-950",
  "bg-gradient-to-r from-lime-600 to-yellow-300",
  "bg-gradient-to-r from-lime-600 to-yellow-400",
  "bg-gradient-to-r from-lime-600 to-yellow-500",
  "bg-gradient-to-r from-lime-600 to-yellow-600",
  "bg-gradient-to-r from-lime-600 to-yellow-700",
  "bg-gradient-to-r from-lime-600 to-yellow-800",
  "bg-gradient-to-r from-lime-600 to-yellow-900",
  "bg-gradient-to-r from-lime-600 to-yellow-950",
  "bg-gradient-to-r from-lime-600 to-green-300",
  "bg-gradient-to-r from-lime-600 to-green-400",
  "bg-gradient-to-r from-lime-600 to-green-500",
  "bg-gradient-to-r from-lime-600 to-green-600",
  "bg-gradient-to-r from-lime-600 to-green-700",
  "bg-gradient-to-r from-lime-600 to-green-800",
  "bg-gradient-to-r from-lime-600 to-green-900",
  "bg-gradient-to-r from-lime-600 to-green-950",
  "bg-gradient-to-r from-lime-600 to-emerald-300",
  "bg-gradient-to-r from-lime-600 to-emerald-400",
  "bg-gradient-to-r from-lime-600 to-emerald-500",
  "bg-gradient-to-r from-lime-600 to-emerald-600",
  "bg-gradient-to-r from-lime-600 to-emerald-700",
  "bg-gradient-to-r from-lime-600 to-emerald-800",
  "bg-gradient-to-r from-lime-600 to-emerald-900",
  "bg-gradient-to-r from-lime-600 to-emerald-950",
  "bg-gradient-to-r from-lime-600 to-teal-300",
  "bg-gradient-to-r from-lime-600 to-teal-400",
  "bg-gradient-to-r from-lime-600 to-teal-500",
  "bg-gradient-to-r from-lime-600 to-teal-600",
  "bg-gradient-to-r from-lime-600 to-teal-700",
  "bg-gradient-to-r from-lime-600 to-teal-800",
  "bg-gradient-to-r from-lime-600 to-teal-900",
  "bg-gradient-to-r from-lime-600 to-teal-950",
  "bg-gradient-to-r from-lime-600 to-cyan-300",
  "bg-gradient-to-r from-lime-600 to-cyan-400",
  "bg-gradient-to-r from-lime-600 to-cyan-500",
  "bg-gradient-to-r from-lime-600 to-cyan-600",
  "bg-gradient-to-r from-lime-600 to-cyan-700",
  "bg-gradient-to-r from-lime-600 to-cyan-800",
  "bg-gradient-to-r from-lime-600 to-cyan-900",
  "bg-gradient-to-r from-lime-600 to-cyan-950",
  "bg-gradient-to-r from-lime-600 to-sky-300",
  "bg-gradient-to-r from-lime-600 to-sky-400",
  "bg-gradient-to-r from-lime-600 to-sky-500",
  "bg-gradient-to-r from-lime-600 to-sky-600",
  "bg-gradient-to-r from-lime-600 to-sky-700",
  "bg-gradient-to-r from-lime-600 to-sky-800",
  "bg-gradient-to-r from-lime-600 to-sky-900",
  "bg-gradient-to-r from-lime-600 to-sky-950",
  "bg-gradient-to-r from-lime-600 to-blue-300",
  "bg-gradient-to-r from-lime-600 to-blue-400",
  "bg-gradient-to-r from-lime-600 to-blue-500",
  "bg-gradient-to-r from-lime-600 to-blue-600",
  "bg-gradient-to-r from-lime-600 to-blue-700",
  "bg-gradient-to-r from-lime-600 to-blue-800",
  "bg-gradient-to-r from-lime-600 to-blue-900",
  "bg-gradient-to-r from-lime-600 to-blue-950",
  "bg-gradient-to-r from-lime-600 to-indigo-300",
  "bg-gradient-to-r from-lime-600 to-indigo-400",
  "bg-gradient-to-r from-lime-600 to-indigo-500",
  "bg-gradient-to-r from-lime-600 to-indigo-600",
  "bg-gradient-to-r from-lime-600 to-indigo-700",
  "bg-gradient-to-r from-lime-600 to-indigo-800",
  "bg-gradient-to-r from-lime-600 to-indigo-900",
  "bg-gradient-to-r from-lime-600 to-indigo-950",
  "bg-gradient-to-r from-lime-600 to-violet-300",
  "bg-gradient-to-r from-lime-600 to-violet-400",
  "bg-gradient-to-r from-lime-600 to-violet-500",
  "bg-gradient-to-r from-lime-600 to-violet-600",
  "bg-gradient-to-r from-lime-600 to-violet-700",
  "bg-gradient-to-r from-lime-600 to-violet-800",
  "bg-gradient-to-r from-lime-600 to-violet-900",
  "bg-gradient-to-r from-lime-600 to-violet-950",
  "bg-gradient-to-r from-lime-600 to-purple-300",
  "bg-gradient-to-r from-lime-600 to-purple-400",
  "bg-gradient-to-r from-lime-600 to-purple-500",
  "bg-gradient-to-r from-lime-600 to-purple-600",
  "bg-gradient-to-r from-lime-600 to-purple-700",
  "bg-gradient-to-r from-lime-600 to-purple-800",
  "bg-gradient-to-r from-lime-600 to-purple-900",
  "bg-gradient-to-r from-lime-600 to-purple-950",
  "bg-gradient-to-r from-lime-600 to-fuchsia-300",
  "bg-gradient-to-r from-lime-600 to-fuchsia-400",
  "bg-gradient-to-r from-lime-600 to-fuchsia-500",
  "bg-gradient-to-r from-lime-600 to-fuchsia-600",
  "bg-gradient-to-r from-lime-600 to-fuchsia-700",
  "bg-gradient-to-r from-lime-600 to-fuchsia-800",
  "bg-gradient-to-r from-lime-600 to-fuchsia-900",
  "bg-gradient-to-r from-lime-600 to-fuchsia-950",
  "bg-gradient-to-r from-lime-600 to-pink-300",
  "bg-gradient-to-r from-lime-600 to-pink-400",
  "bg-gradient-to-r from-lime-600 to-pink-500",
  "bg-gradient-to-r from-lime-600 to-pink-600",
  "bg-gradient-to-r from-lime-600 to-pink-700",
  "bg-gradient-to-r from-lime-600 to-pink-800",
  "bg-gradient-to-r from-lime-600 to-pink-900",
  "bg-gradient-to-r from-lime-600 to-pink-950",
  "bg-gradient-to-r from-lime-600 to-rose-300",
  "bg-gradient-to-r from-lime-600 to-rose-400",
  "bg-gradient-to-r from-lime-600 to-rose-500",
  "bg-gradient-to-r from-lime-600 to-rose-600",
  "bg-gradient-to-r from-lime-600 to-rose-700",
  "bg-gradient-to-r from-lime-600 to-rose-800",
  "bg-gradient-to-r from-lime-600 to-rose-900",
  "bg-gradient-to-r from-lime-600 to-rose-950",
  "bg-gradient-to-r from-lime-700 to-red-300",
  "bg-gradient-to-r from-lime-700 to-red-400",
  "bg-gradient-to-r from-lime-700 to-red-500",
  "bg-gradient-to-r from-lime-700 to-red-600",
  "bg-gradient-to-r from-lime-700 to-red-700",
  "bg-gradient-to-r from-lime-700 to-red-800",
  "bg-gradient-to-r from-lime-700 to-red-900",
  "bg-gradient-to-r from-lime-700 to-red-950",
  "bg-gradient-to-r from-lime-700 to-orange-300",
  "bg-gradient-to-r from-lime-700 to-orange-400",
  "bg-gradient-to-r from-lime-700 to-orange-500",
  "bg-gradient-to-r from-lime-700 to-orange-600",
  "bg-gradient-to-r from-lime-700 to-orange-700",
  "bg-gradient-to-r from-lime-700 to-orange-800",
  "bg-gradient-to-r from-lime-700 to-orange-900",
  "bg-gradient-to-r from-lime-700 to-orange-950",
  "bg-gradient-to-r from-lime-700 to-amber-300",
  "bg-gradient-to-r from-lime-700 to-amber-400",
  "bg-gradient-to-r from-lime-700 to-amber-500",
  "bg-gradient-to-r from-lime-700 to-amber-600",
  "bg-gradient-to-r from-lime-700 to-amber-700",
  "bg-gradient-to-r from-lime-700 to-amber-800",
  "bg-gradient-to-r from-lime-700 to-amber-900",
  "bg-gradient-to-r from-lime-700 to-amber-950",
  "bg-gradient-to-r from-lime-700 to-yellow-300",
  "bg-gradient-to-r from-lime-700 to-yellow-400",
  "bg-gradient-to-r from-lime-700 to-yellow-500",
  "bg-gradient-to-r from-lime-700 to-yellow-600",
  "bg-gradient-to-r from-lime-700 to-yellow-700",
  "bg-gradient-to-r from-lime-700 to-yellow-800",
  "bg-gradient-to-r from-lime-700 to-yellow-900",
  "bg-gradient-to-r from-lime-700 to-yellow-950",
  "bg-gradient-to-r from-lime-700 to-green-300",
  "bg-gradient-to-r from-lime-700 to-green-400",
  "bg-gradient-to-r from-lime-700 to-green-500",
  "bg-gradient-to-r from-lime-700 to-green-600",
  "bg-gradient-to-r from-lime-700 to-green-700",
  "bg-gradient-to-r from-lime-700 to-green-800",
  "bg-gradient-to-r from-lime-700 to-green-900",
  "bg-gradient-to-r from-lime-700 to-green-950",
  "bg-gradient-to-r from-lime-700 to-emerald-300",
  "bg-gradient-to-r from-lime-700 to-emerald-400",
  "bg-gradient-to-r from-lime-700 to-emerald-500",
  "bg-gradient-to-r from-lime-700 to-emerald-600",
  "bg-gradient-to-r from-lime-700 to-emerald-700",
  "bg-gradient-to-r from-lime-700 to-emerald-800",
  "bg-gradient-to-r from-lime-700 to-emerald-900",
  "bg-gradient-to-r from-lime-700 to-emerald-950",
  "bg-gradient-to-r from-lime-700 to-teal-300",
  "bg-gradient-to-r from-lime-700 to-teal-400",
  "bg-gradient-to-r from-lime-700 to-teal-500",
  "bg-gradient-to-r from-lime-700 to-teal-600",
  "bg-gradient-to-r from-lime-700 to-teal-700",
  "bg-gradient-to-r from-lime-700 to-teal-800",
  "bg-gradient-to-r from-lime-700 to-teal-900",
  "bg-gradient-to-r from-lime-700 to-teal-950",
  "bg-gradient-to-r from-lime-700 to-cyan-300",
  "bg-gradient-to-r from-lime-700 to-cyan-400",
  "bg-gradient-to-r from-lime-700 to-cyan-500",
  "bg-gradient-to-r from-lime-700 to-cyan-600",
  "bg-gradient-to-r from-lime-700 to-cyan-700",
  "bg-gradient-to-r from-lime-700 to-cyan-800",
  "bg-gradient-to-r from-lime-700 to-cyan-900",
  "bg-gradient-to-r from-lime-700 to-cyan-950",
  "bg-gradient-to-r from-lime-700 to-sky-300",
  "bg-gradient-to-r from-lime-700 to-sky-400",
  "bg-gradient-to-r from-lime-700 to-sky-500",
  "bg-gradient-to-r from-lime-700 to-sky-600",
  "bg-gradient-to-r from-lime-700 to-sky-700",
  "bg-gradient-to-r from-lime-700 to-sky-800",
  "bg-gradient-to-r from-lime-700 to-sky-900",
  "bg-gradient-to-r from-lime-700 to-sky-950",
  "bg-gradient-to-r from-lime-700 to-blue-300",
  "bg-gradient-to-r from-lime-700 to-blue-400",
  "bg-gradient-to-r from-lime-700 to-blue-500",
  "bg-gradient-to-r from-lime-700 to-blue-600",
  "bg-gradient-to-r from-lime-700 to-blue-700",
  "bg-gradient-to-r from-lime-700 to-blue-800",
  "bg-gradient-to-r from-lime-700 to-blue-900",
  "bg-gradient-to-r from-lime-700 to-blue-950",
  "bg-gradient-to-r from-lime-700 to-indigo-300",
  "bg-gradient-to-r from-lime-700 to-indigo-400",
  "bg-gradient-to-r from-lime-700 to-indigo-500",
  "bg-gradient-to-r from-lime-700 to-indigo-600",
  "bg-gradient-to-r from-lime-700 to-indigo-700",
  "bg-gradient-to-r from-lime-700 to-indigo-800",
  "bg-gradient-to-r from-lime-700 to-indigo-900",
  "bg-gradient-to-r from-lime-700 to-indigo-950",
  "bg-gradient-to-r from-lime-700 to-violet-300",
  "bg-gradient-to-r from-lime-700 to-violet-400",
  "bg-gradient-to-r from-lime-700 to-violet-500",
  "bg-gradient-to-r from-lime-700 to-violet-600",
  "bg-gradient-to-r from-lime-700 to-violet-700",
  "bg-gradient-to-r from-lime-700 to-violet-800",
  "bg-gradient-to-r from-lime-700 to-violet-900",
  "bg-gradient-to-r from-lime-700 to-violet-950",
  "bg-gradient-to-r from-lime-700 to-purple-300",
  "bg-gradient-to-r from-lime-700 to-purple-400",
  "bg-gradient-to-r from-lime-700 to-purple-500",
  "bg-gradient-to-r from-lime-700 to-purple-600",
  "bg-gradient-to-r from-lime-700 to-purple-700",
  "bg-gradient-to-r from-lime-700 to-purple-800",
  "bg-gradient-to-r from-lime-700 to-purple-900",
  "bg-gradient-to-r from-lime-700 to-purple-950",
  "bg-gradient-to-r from-lime-700 to-fuchsia-300",
  "bg-gradient-to-r from-lime-700 to-fuchsia-400",
  "bg-gradient-to-r from-lime-700 to-fuchsia-500",
  "bg-gradient-to-r from-lime-700 to-fuchsia-600",
  "bg-gradient-to-r from-lime-700 to-fuchsia-700",
  "bg-gradient-to-r from-lime-700 to-fuchsia-800",
  "bg-gradient-to-r from-lime-700 to-fuchsia-900",
  "bg-gradient-to-r from-lime-700 to-fuchsia-950",
  "bg-gradient-to-r from-lime-700 to-pink-300",
  "bg-gradient-to-r from-lime-700 to-pink-400",
  "bg-gradient-to-r from-lime-700 to-pink-500",
  "bg-gradient-to-r from-lime-700 to-pink-600",
  "bg-gradient-to-r from-lime-700 to-pink-700",
  "bg-gradient-to-r from-lime-700 to-pink-800",
  "bg-gradient-to-r from-lime-700 to-pink-900",
  "bg-gradient-to-r from-lime-700 to-pink-950",
  "bg-gradient-to-r from-lime-700 to-rose-300",
  "bg-gradient-to-r from-lime-700 to-rose-400",
  "bg-gradient-to-r from-lime-700 to-rose-500",
  "bg-gradient-to-r from-lime-700 to-rose-600",
  "bg-gradient-to-r from-lime-700 to-rose-700",
  "bg-gradient-to-r from-lime-700 to-rose-800",
  "bg-gradient-to-r from-lime-700 to-rose-900",
  "bg-gradient-to-r from-lime-700 to-rose-950",
  "bg-gradient-to-r from-lime-800 to-red-300",
  "bg-gradient-to-r from-lime-800 to-red-400",
  "bg-gradient-to-r from-lime-800 to-red-500",
  "bg-gradient-to-r from-lime-800 to-red-600",
  "bg-gradient-to-r from-lime-800 to-red-700",
  "bg-gradient-to-r from-lime-800 to-red-800",
  "bg-gradient-to-r from-lime-800 to-red-900",
  "bg-gradient-to-r from-lime-800 to-red-950",
  "bg-gradient-to-r from-lime-800 to-orange-300",
  "bg-gradient-to-r from-lime-800 to-orange-400",
  "bg-gradient-to-r from-lime-800 to-orange-500",
  "bg-gradient-to-r from-lime-800 to-orange-600",
  "bg-gradient-to-r from-lime-800 to-orange-700",
  "bg-gradient-to-r from-lime-800 to-orange-800",
  "bg-gradient-to-r from-lime-800 to-orange-900",
  "bg-gradient-to-r from-lime-800 to-orange-950",
  "bg-gradient-to-r from-lime-800 to-amber-300",
  "bg-gradient-to-r from-lime-800 to-amber-400",
  "bg-gradient-to-r from-lime-800 to-amber-500",
  "bg-gradient-to-r from-lime-800 to-amber-600",
  "bg-gradient-to-r from-lime-800 to-amber-700",
  "bg-gradient-to-r from-lime-800 to-amber-800",
  "bg-gradient-to-r from-lime-800 to-amber-900",
  "bg-gradient-to-r from-lime-800 to-amber-950",
  "bg-gradient-to-r from-lime-800 to-yellow-300",
  "bg-gradient-to-r from-lime-800 to-yellow-400",
  "bg-gradient-to-r from-lime-800 to-yellow-500",
  "bg-gradient-to-r from-lime-800 to-yellow-600",
  "bg-gradient-to-r from-lime-800 to-yellow-700",
  "bg-gradient-to-r from-lime-800 to-yellow-800",
  "bg-gradient-to-r from-lime-800 to-yellow-900",
  "bg-gradient-to-r from-lime-800 to-yellow-950",
  "bg-gradient-to-r from-lime-800 to-green-300",
  "bg-gradient-to-r from-lime-800 to-green-400",
  "bg-gradient-to-r from-lime-800 to-green-500",
  "bg-gradient-to-r from-lime-800 to-green-600",
  "bg-gradient-to-r from-lime-800 to-green-700",
  "bg-gradient-to-r from-lime-800 to-green-800",
  "bg-gradient-to-r from-lime-800 to-green-900",
  "bg-gradient-to-r from-lime-800 to-green-950",
  "bg-gradient-to-r from-lime-800 to-emerald-300",
  "bg-gradient-to-r from-lime-800 to-emerald-400",
  "bg-gradient-to-r from-lime-800 to-emerald-500",
  "bg-gradient-to-r from-lime-800 to-emerald-600",
  "bg-gradient-to-r from-lime-800 to-emerald-700",
  "bg-gradient-to-r from-lime-800 to-emerald-800",
  "bg-gradient-to-r from-lime-800 to-emerald-900",
  "bg-gradient-to-r from-lime-800 to-emerald-950",
  "bg-gradient-to-r from-lime-800 to-teal-300",
  "bg-gradient-to-r from-lime-800 to-teal-400",
  "bg-gradient-to-r from-lime-800 to-teal-500",
  "bg-gradient-to-r from-lime-800 to-teal-600",
  "bg-gradient-to-r from-lime-800 to-teal-700",
  "bg-gradient-to-r from-lime-800 to-teal-800",
  "bg-gradient-to-r from-lime-800 to-teal-900",
  "bg-gradient-to-r from-lime-800 to-teal-950",
  "bg-gradient-to-r from-lime-800 to-cyan-300",
  "bg-gradient-to-r from-lime-800 to-cyan-400",
  "bg-gradient-to-r from-lime-800 to-cyan-500",
  "bg-gradient-to-r from-lime-800 to-cyan-600",
  "bg-gradient-to-r from-lime-800 to-cyan-700",
  "bg-gradient-to-r from-lime-800 to-cyan-800",
  "bg-gradient-to-r from-lime-800 to-cyan-900",
  "bg-gradient-to-r from-lime-800 to-cyan-950",
  "bg-gradient-to-r from-lime-800 to-sky-300",
  "bg-gradient-to-r from-lime-800 to-sky-400",
  "bg-gradient-to-r from-lime-800 to-sky-500",
  "bg-gradient-to-r from-lime-800 to-sky-600",
  "bg-gradient-to-r from-lime-800 to-sky-700",
  "bg-gradient-to-r from-lime-800 to-sky-800",
  "bg-gradient-to-r from-lime-800 to-sky-900",
  "bg-gradient-to-r from-lime-800 to-sky-950",
  "bg-gradient-to-r from-lime-800 to-blue-300",
  "bg-gradient-to-r from-lime-800 to-blue-400",
  "bg-gradient-to-r from-lime-800 to-blue-500",
  "bg-gradient-to-r from-lime-800 to-blue-600",
  "bg-gradient-to-r from-lime-800 to-blue-700",
  "bg-gradient-to-r from-lime-800 to-blue-800",
  "bg-gradient-to-r from-lime-800 to-blue-900",
  "bg-gradient-to-r from-lime-800 to-blue-950",
  "bg-gradient-to-r from-lime-800 to-indigo-300",
  "bg-gradient-to-r from-lime-800 to-indigo-400",
  "bg-gradient-to-r from-lime-800 to-indigo-500",
  "bg-gradient-to-r from-lime-800 to-indigo-600",
  "bg-gradient-to-r from-lime-800 to-indigo-700",
  "bg-gradient-to-r from-lime-800 to-indigo-800",
  "bg-gradient-to-r from-lime-800 to-indigo-900",
  "bg-gradient-to-r from-lime-800 to-indigo-950",
  "bg-gradient-to-r from-lime-800 to-violet-300",
  "bg-gradient-to-r from-lime-800 to-violet-400",
  "bg-gradient-to-r from-lime-800 to-violet-500",
  "bg-gradient-to-r from-lime-800 to-violet-600",
  "bg-gradient-to-r from-lime-800 to-violet-700",
  "bg-gradient-to-r from-lime-800 to-violet-800",
  "bg-gradient-to-r from-lime-800 to-violet-900",
  "bg-gradient-to-r from-lime-800 to-violet-950",
  "bg-gradient-to-r from-lime-800 to-purple-300",
  "bg-gradient-to-r from-lime-800 to-purple-400",
  "bg-gradient-to-r from-lime-800 to-purple-500",
  "bg-gradient-to-r from-lime-800 to-purple-600",
  "bg-gradient-to-r from-lime-800 to-purple-700",
  "bg-gradient-to-r from-lime-800 to-purple-800",
  "bg-gradient-to-r from-lime-800 to-purple-900",
  "bg-gradient-to-r from-lime-800 to-purple-950",
  "bg-gradient-to-r from-lime-800 to-fuchsia-300",
  "bg-gradient-to-r from-lime-800 to-fuchsia-400",
  "bg-gradient-to-r from-lime-800 to-fuchsia-500",
  "bg-gradient-to-r from-lime-800 to-fuchsia-600",
  "bg-gradient-to-r from-lime-800 to-fuchsia-700",
  "bg-gradient-to-r from-lime-800 to-fuchsia-800",
  "bg-gradient-to-r from-lime-800 to-fuchsia-900",
  "bg-gradient-to-r from-lime-800 to-fuchsia-950",
  "bg-gradient-to-r from-lime-800 to-pink-300",
  "bg-gradient-to-r from-lime-800 to-pink-400",
  "bg-gradient-to-r from-lime-800 to-pink-500",
  "bg-gradient-to-r from-lime-800 to-pink-600",
  "bg-gradient-to-r from-lime-800 to-pink-700",
  "bg-gradient-to-r from-lime-800 to-pink-800",
  "bg-gradient-to-r from-lime-800 to-pink-900",
  "bg-gradient-to-r from-lime-800 to-pink-950",
  "bg-gradient-to-r from-lime-800 to-rose-300",
  "bg-gradient-to-r from-lime-800 to-rose-400",
  "bg-gradient-to-r from-lime-800 to-rose-500",
  "bg-gradient-to-r from-lime-800 to-rose-600",
  "bg-gradient-to-r from-lime-800 to-rose-700",
  "bg-gradient-to-r from-lime-800 to-rose-800",
  "bg-gradient-to-r from-lime-800 to-rose-900",
  "bg-gradient-to-r from-lime-800 to-rose-950",
  "bg-gradient-to-r from-lime-900 to-red-300",
  "bg-gradient-to-r from-lime-900 to-red-400",
  "bg-gradient-to-r from-lime-900 to-red-500",
  "bg-gradient-to-r from-lime-900 to-red-600",
  "bg-gradient-to-r from-lime-900 to-red-700",
  "bg-gradient-to-r from-lime-900 to-red-800",
  "bg-gradient-to-r from-lime-900 to-red-900",
  "bg-gradient-to-r from-lime-900 to-red-950",
  "bg-gradient-to-r from-lime-900 to-orange-300",
  "bg-gradient-to-r from-lime-900 to-orange-400",
  "bg-gradient-to-r from-lime-900 to-orange-500",
  "bg-gradient-to-r from-lime-900 to-orange-600",
  "bg-gradient-to-r from-lime-900 to-orange-700",
  "bg-gradient-to-r from-lime-900 to-orange-800",
  "bg-gradient-to-r from-lime-900 to-orange-900",
  "bg-gradient-to-r from-lime-900 to-orange-950",
  "bg-gradient-to-r from-lime-900 to-amber-300",
  "bg-gradient-to-r from-lime-900 to-amber-400",
  "bg-gradient-to-r from-lime-900 to-amber-500",
  "bg-gradient-to-r from-lime-900 to-amber-600",
  "bg-gradient-to-r from-lime-900 to-amber-700",
  "bg-gradient-to-r from-lime-900 to-amber-800",
  "bg-gradient-to-r from-lime-900 to-amber-900",
  "bg-gradient-to-r from-lime-900 to-amber-950",
  "bg-gradient-to-r from-lime-900 to-yellow-300",
  "bg-gradient-to-r from-lime-900 to-yellow-400",
  "bg-gradient-to-r from-lime-900 to-yellow-500",
  "bg-gradient-to-r from-lime-900 to-yellow-600",
  "bg-gradient-to-r from-lime-900 to-yellow-700",
  "bg-gradient-to-r from-lime-900 to-yellow-800",
  "bg-gradient-to-r from-lime-900 to-yellow-900",
  "bg-gradient-to-r from-lime-900 to-yellow-950",
  "bg-gradient-to-r from-lime-900 to-green-300",
  "bg-gradient-to-r from-lime-900 to-green-400",
  "bg-gradient-to-r from-lime-900 to-green-500",
  "bg-gradient-to-r from-lime-900 to-green-600",
  "bg-gradient-to-r from-lime-900 to-green-700",
  "bg-gradient-to-r from-lime-900 to-green-800",
  "bg-gradient-to-r from-lime-900 to-green-900",
  "bg-gradient-to-r from-lime-900 to-green-950",
  "bg-gradient-to-r from-lime-900 to-emerald-300",
  "bg-gradient-to-r from-lime-900 to-emerald-400",
  "bg-gradient-to-r from-lime-900 to-emerald-500",
  "bg-gradient-to-r from-lime-900 to-emerald-600",
  "bg-gradient-to-r from-lime-900 to-emerald-700",
  "bg-gradient-to-r from-lime-900 to-emerald-800",
  "bg-gradient-to-r from-lime-900 to-emerald-900",
  "bg-gradient-to-r from-lime-900 to-emerald-950",
  "bg-gradient-to-r from-lime-900 to-teal-300",
  "bg-gradient-to-r from-lime-900 to-teal-400",
  "bg-gradient-to-r from-lime-900 to-teal-500",
  "bg-gradient-to-r from-lime-900 to-teal-600",
  "bg-gradient-to-r from-lime-900 to-teal-700",
  "bg-gradient-to-r from-lime-900 to-teal-800",
  "bg-gradient-to-r from-lime-900 to-teal-900",
  "bg-gradient-to-r from-lime-900 to-teal-950",
  "bg-gradient-to-r from-lime-900 to-cyan-300",
  "bg-gradient-to-r from-lime-900 to-cyan-400",
  "bg-gradient-to-r from-lime-900 to-cyan-500",
  "bg-gradient-to-r from-lime-900 to-cyan-600",
  "bg-gradient-to-r from-lime-900 to-cyan-700",
  "bg-gradient-to-r from-lime-900 to-cyan-800",
  "bg-gradient-to-r from-lime-900 to-cyan-900",
  "bg-gradient-to-r from-lime-900 to-cyan-950",
  "bg-gradient-to-r from-lime-900 to-sky-300",
  "bg-gradient-to-r from-lime-900 to-sky-400",
  "bg-gradient-to-r from-lime-900 to-sky-500",
  "bg-gradient-to-r from-lime-900 to-sky-600",
  "bg-gradient-to-r from-lime-900 to-sky-700",
  "bg-gradient-to-r from-lime-900 to-sky-800",
  "bg-gradient-to-r from-lime-900 to-sky-900",
  "bg-gradient-to-r from-lime-900 to-sky-950",
  "bg-gradient-to-r from-lime-900 to-blue-300",
  "bg-gradient-to-r from-lime-900 to-blue-400",
  "bg-gradient-to-r from-lime-900 to-blue-500",
  "bg-gradient-to-r from-lime-900 to-blue-600",
  "bg-gradient-to-r from-lime-900 to-blue-700",
  "bg-gradient-to-r from-lime-900 to-blue-800",
  "bg-gradient-to-r from-lime-900 to-blue-900",
  "bg-gradient-to-r from-lime-900 to-blue-950",
  "bg-gradient-to-r from-lime-900 to-indigo-300",
  "bg-gradient-to-r from-lime-900 to-indigo-400",
  "bg-gradient-to-r from-lime-900 to-indigo-500",
  "bg-gradient-to-r from-lime-900 to-indigo-600",
  "bg-gradient-to-r from-lime-900 to-indigo-700",
  "bg-gradient-to-r from-lime-900 to-indigo-800",
  "bg-gradient-to-r from-lime-900 to-indigo-900",
  "bg-gradient-to-r from-lime-900 to-indigo-950",
  "bg-gradient-to-r from-lime-900 to-violet-300",
  "bg-gradient-to-r from-lime-900 to-violet-400",
  "bg-gradient-to-r from-lime-900 to-violet-500",
  "bg-gradient-to-r from-lime-900 to-violet-600",
  "bg-gradient-to-r from-lime-900 to-violet-700",
  "bg-gradient-to-r from-lime-900 to-violet-800",
  "bg-gradient-to-r from-lime-900 to-violet-900",
  "bg-gradient-to-r from-lime-900 to-violet-950",
  "bg-gradient-to-r from-lime-900 to-purple-300",
  "bg-gradient-to-r from-lime-900 to-purple-400",
  "bg-gradient-to-r from-lime-900 to-purple-500",
  "bg-gradient-to-r from-lime-900 to-purple-600",
  "bg-gradient-to-r from-lime-900 to-purple-700",
  "bg-gradient-to-r from-lime-900 to-purple-800",
  "bg-gradient-to-r from-lime-900 to-purple-900",
  "bg-gradient-to-r from-lime-900 to-purple-950",
  "bg-gradient-to-r from-lime-900 to-fuchsia-300",
  "bg-gradient-to-r from-lime-900 to-fuchsia-400",
  "bg-gradient-to-r from-lime-900 to-fuchsia-500",
  "bg-gradient-to-r from-lime-900 to-fuchsia-600",
  "bg-gradient-to-r from-lime-900 to-fuchsia-700",
  "bg-gradient-to-r from-lime-900 to-fuchsia-800",
  "bg-gradient-to-r from-lime-900 to-fuchsia-900",
  "bg-gradient-to-r from-lime-900 to-fuchsia-950",
  "bg-gradient-to-r from-lime-900 to-pink-300",
  "bg-gradient-to-r from-lime-900 to-pink-400",
  "bg-gradient-to-r from-lime-900 to-pink-500",
  "bg-gradient-to-r from-lime-900 to-pink-600",
  "bg-gradient-to-r from-lime-900 to-pink-700",
  "bg-gradient-to-r from-lime-900 to-pink-800",
  "bg-gradient-to-r from-lime-900 to-pink-900",
  "bg-gradient-to-r from-lime-900 to-pink-950",
  "bg-gradient-to-r from-lime-900 to-rose-300",
  "bg-gradient-to-r from-lime-900 to-rose-400",
  "bg-gradient-to-r from-lime-900 to-rose-500",
  "bg-gradient-to-r from-lime-900 to-rose-600",
  "bg-gradient-to-r from-lime-900 to-rose-700",
  "bg-gradient-to-r from-lime-900 to-rose-800",
  "bg-gradient-to-r from-lime-900 to-rose-900",
  "bg-gradient-to-r from-lime-900 to-rose-950",
  "bg-gradient-to-r from-lime-950 to-red-300",
  "bg-gradient-to-r from-lime-950 to-red-400",
  "bg-gradient-to-r from-lime-950 to-red-500",
  "bg-gradient-to-r from-lime-950 to-red-600",
  "bg-gradient-to-r from-lime-950 to-red-700",
  "bg-gradient-to-r from-lime-950 to-red-800",
  "bg-gradient-to-r from-lime-950 to-red-900",
  "bg-gradient-to-r from-lime-950 to-red-950",
  "bg-gradient-to-r from-lime-950 to-orange-300",
  "bg-gradient-to-r from-lime-950 to-orange-400",
  "bg-gradient-to-r from-lime-950 to-orange-500",
  "bg-gradient-to-r from-lime-950 to-orange-600",
  "bg-gradient-to-r from-lime-950 to-orange-700",
  "bg-gradient-to-r from-lime-950 to-orange-800",
  "bg-gradient-to-r from-lime-950 to-orange-900",
  "bg-gradient-to-r from-lime-950 to-orange-950",
  "bg-gradient-to-r from-lime-950 to-amber-300",
  "bg-gradient-to-r from-lime-950 to-amber-400",
  "bg-gradient-to-r from-lime-950 to-amber-500",
  "bg-gradient-to-r from-lime-950 to-amber-600",
  "bg-gradient-to-r from-lime-950 to-amber-700",
  "bg-gradient-to-r from-lime-950 to-amber-800",
  "bg-gradient-to-r from-lime-950 to-amber-900",
  "bg-gradient-to-r from-lime-950 to-amber-950",
  "bg-gradient-to-r from-lime-950 to-yellow-300",
  "bg-gradient-to-r from-lime-950 to-yellow-400",
  "bg-gradient-to-r from-lime-950 to-yellow-500",
  "bg-gradient-to-r from-lime-950 to-yellow-600",
  "bg-gradient-to-r from-lime-950 to-yellow-700",
  "bg-gradient-to-r from-lime-950 to-yellow-800",
  "bg-gradient-to-r from-lime-950 to-yellow-900",
  "bg-gradient-to-r from-lime-950 to-yellow-950",
  "bg-gradient-to-r from-lime-950 to-green-300",
  "bg-gradient-to-r from-lime-950 to-green-400",
  "bg-gradient-to-r from-lime-950 to-green-500",
  "bg-gradient-to-r from-lime-950 to-green-600",
  "bg-gradient-to-r from-lime-950 to-green-700",
  "bg-gradient-to-r from-lime-950 to-green-800",
  "bg-gradient-to-r from-lime-950 to-green-900",
  "bg-gradient-to-r from-lime-950 to-green-950",
  "bg-gradient-to-r from-lime-950 to-emerald-300",
  "bg-gradient-to-r from-lime-950 to-emerald-400",
  "bg-gradient-to-r from-lime-950 to-emerald-500",
  "bg-gradient-to-r from-lime-950 to-emerald-600",
  "bg-gradient-to-r from-lime-950 to-emerald-700",
  "bg-gradient-to-r from-lime-950 to-emerald-800",
  "bg-gradient-to-r from-lime-950 to-emerald-900",
  "bg-gradient-to-r from-lime-950 to-emerald-950",
  "bg-gradient-to-r from-lime-950 to-teal-300",
  "bg-gradient-to-r from-lime-950 to-teal-400",
  "bg-gradient-to-r from-lime-950 to-teal-500",
  "bg-gradient-to-r from-lime-950 to-teal-600",
  "bg-gradient-to-r from-lime-950 to-teal-700",
  "bg-gradient-to-r from-lime-950 to-teal-800",
  "bg-gradient-to-r from-lime-950 to-teal-900",
  "bg-gradient-to-r from-lime-950 to-teal-950",
  "bg-gradient-to-r from-lime-950 to-cyan-300",
  "bg-gradient-to-r from-lime-950 to-cyan-400",
  "bg-gradient-to-r from-lime-950 to-cyan-500",
  "bg-gradient-to-r from-lime-950 to-cyan-600",
  "bg-gradient-to-r from-lime-950 to-cyan-700",
  "bg-gradient-to-r from-lime-950 to-cyan-800",
  "bg-gradient-to-r from-lime-950 to-cyan-900",
  "bg-gradient-to-r from-lime-950 to-cyan-950",
  "bg-gradient-to-r from-lime-950 to-sky-300",
  "bg-gradient-to-r from-lime-950 to-sky-400",
  "bg-gradient-to-r from-lime-950 to-sky-500",
  "bg-gradient-to-r from-lime-950 to-sky-600",
  "bg-gradient-to-r from-lime-950 to-sky-700",
  "bg-gradient-to-r from-lime-950 to-sky-800",
  "bg-gradient-to-r from-lime-950 to-sky-900",
  "bg-gradient-to-r from-lime-950 to-sky-950",
  "bg-gradient-to-r from-lime-950 to-blue-300",
  "bg-gradient-to-r from-lime-950 to-blue-400",
  "bg-gradient-to-r from-lime-950 to-blue-500",
  "bg-gradient-to-r from-lime-950 to-blue-600",
  "bg-gradient-to-r from-lime-950 to-blue-700",
  "bg-gradient-to-r from-lime-950 to-blue-800",
  "bg-gradient-to-r from-lime-950 to-blue-900",
  "bg-gradient-to-r from-lime-950 to-blue-950",
  "bg-gradient-to-r from-lime-950 to-indigo-300",
  "bg-gradient-to-r from-lime-950 to-indigo-400",
  "bg-gradient-to-r from-lime-950 to-indigo-500",
  "bg-gradient-to-r from-lime-950 to-indigo-600",
  "bg-gradient-to-r from-lime-950 to-indigo-700",
  "bg-gradient-to-r from-lime-950 to-indigo-800",
  "bg-gradient-to-r from-lime-950 to-indigo-900",
  "bg-gradient-to-r from-lime-950 to-indigo-950",
  "bg-gradient-to-r from-lime-950 to-violet-300",
  "bg-gradient-to-r from-lime-950 to-violet-400",
  "bg-gradient-to-r from-lime-950 to-violet-500",
  "bg-gradient-to-r from-lime-950 to-violet-600",
  "bg-gradient-to-r from-lime-950 to-violet-700",
  "bg-gradient-to-r from-lime-950 to-violet-800",
  "bg-gradient-to-r from-lime-950 to-violet-900",
  "bg-gradient-to-r from-lime-950 to-violet-950",
  "bg-gradient-to-r from-lime-950 to-purple-300",
  "bg-gradient-to-r from-lime-950 to-purple-400",
  "bg-gradient-to-r from-lime-950 to-purple-500",
  "bg-gradient-to-r from-lime-950 to-purple-600",
  "bg-gradient-to-r from-lime-950 to-purple-700",
  "bg-gradient-to-r from-lime-950 to-purple-800",
  "bg-gradient-to-r from-lime-950 to-purple-900",
  "bg-gradient-to-r from-lime-950 to-purple-950",
  "bg-gradient-to-r from-lime-950 to-fuchsia-300",
  "bg-gradient-to-r from-lime-950 to-fuchsia-400",
  "bg-gradient-to-r from-lime-950 to-fuchsia-500",
  "bg-gradient-to-r from-lime-950 to-fuchsia-600",
  "bg-gradient-to-r from-lime-950 to-fuchsia-700",
  "bg-gradient-to-r from-lime-950 to-fuchsia-800",
  "bg-gradient-to-r from-lime-950 to-fuchsia-900",
  "bg-gradient-to-r from-lime-950 to-fuchsia-950",
  "bg-gradient-to-r from-lime-950 to-pink-300",
  "bg-gradient-to-r from-lime-950 to-pink-400",
  "bg-gradient-to-r from-lime-950 to-pink-500",
  "bg-gradient-to-r from-lime-950 to-pink-600",
  "bg-gradient-to-r from-lime-950 to-pink-700",
  "bg-gradient-to-r from-lime-950 to-pink-800",
  "bg-gradient-to-r from-lime-950 to-pink-900",
  "bg-gradient-to-r from-lime-950 to-pink-950",
  "bg-gradient-to-r from-lime-950 to-rose-300",
  "bg-gradient-to-r from-lime-950 to-rose-400",
  "bg-gradient-to-r from-lime-950 to-rose-500",
  "bg-gradient-to-r from-lime-950 to-rose-600",
  "bg-gradient-to-r from-lime-950 to-rose-700",
  "bg-gradient-to-r from-lime-950 to-rose-800",
  "bg-gradient-to-r from-lime-950 to-rose-900",
  "bg-gradient-to-r from-lime-950 to-rose-950",
  "bg-gradient-to-r from-green-300 to-red-300",
  "bg-gradient-to-r from-green-300 to-red-400",
  "bg-gradient-to-r from-green-300 to-red-500",
  "bg-gradient-to-r from-green-300 to-red-600",
  "bg-gradient-to-r from-green-300 to-red-700",
  "bg-gradient-to-r from-green-300 to-red-800",
  "bg-gradient-to-r from-green-300 to-red-900",
  "bg-gradient-to-r from-green-300 to-red-950",
  "bg-gradient-to-r from-green-300 to-orange-300",
  "bg-gradient-to-r from-green-300 to-orange-400",
  "bg-gradient-to-r from-green-300 to-orange-500",
  "bg-gradient-to-r from-green-300 to-orange-600",
  "bg-gradient-to-r from-green-300 to-orange-700",
  "bg-gradient-to-r from-green-300 to-orange-800",
  "bg-gradient-to-r from-green-300 to-orange-900",
  "bg-gradient-to-r from-green-300 to-orange-950",
  "bg-gradient-to-r from-green-300 to-amber-300",
  "bg-gradient-to-r from-green-300 to-amber-400",
  "bg-gradient-to-r from-green-300 to-amber-500",
  "bg-gradient-to-r from-green-300 to-amber-600",
  "bg-gradient-to-r from-green-300 to-amber-700",
  "bg-gradient-to-r from-green-300 to-amber-800",
  "bg-gradient-to-r from-green-300 to-amber-900",
  "bg-gradient-to-r from-green-300 to-amber-950",
  "bg-gradient-to-r from-green-300 to-yellow-300",
  "bg-gradient-to-r from-green-300 to-yellow-400",
  "bg-gradient-to-r from-green-300 to-yellow-500",
  "bg-gradient-to-r from-green-300 to-yellow-600",
  "bg-gradient-to-r from-green-300 to-yellow-700",
  "bg-gradient-to-r from-green-300 to-yellow-800",
  "bg-gradient-to-r from-green-300 to-yellow-900",
  "bg-gradient-to-r from-green-300 to-yellow-950",
  "bg-gradient-to-r from-green-300 to-lime-300",
  "bg-gradient-to-r from-green-300 to-lime-400",
  "bg-gradient-to-r from-green-300 to-lime-500",
  "bg-gradient-to-r from-green-300 to-lime-600",
  "bg-gradient-to-r from-green-300 to-lime-700",
  "bg-gradient-to-r from-green-300 to-lime-800",
  "bg-gradient-to-r from-green-300 to-lime-900",
  "bg-gradient-to-r from-green-300 to-lime-950",
  "bg-gradient-to-r from-green-300 to-emerald-300",
  "bg-gradient-to-r from-green-300 to-emerald-400",
  "bg-gradient-to-r from-green-300 to-emerald-500",
  "bg-gradient-to-r from-green-300 to-emerald-600",
  "bg-gradient-to-r from-green-300 to-emerald-700",
  "bg-gradient-to-r from-green-300 to-emerald-800",
  "bg-gradient-to-r from-green-300 to-emerald-900",
  "bg-gradient-to-r from-green-300 to-emerald-950",
  "bg-gradient-to-r from-green-300 to-teal-300",
  "bg-gradient-to-r from-green-300 to-teal-400",
  "bg-gradient-to-r from-green-300 to-teal-500",
  "bg-gradient-to-r from-green-300 to-teal-600",
  "bg-gradient-to-r from-green-300 to-teal-700",
  "bg-gradient-to-r from-green-300 to-teal-800",
  "bg-gradient-to-r from-green-300 to-teal-900",
  "bg-gradient-to-r from-green-300 to-teal-950",
  "bg-gradient-to-r from-green-300 to-cyan-300",
  "bg-gradient-to-r from-green-300 to-cyan-400",
  "bg-gradient-to-r from-green-300 to-cyan-500",
  "bg-gradient-to-r from-green-300 to-cyan-600",
  "bg-gradient-to-r from-green-300 to-cyan-700",
  "bg-gradient-to-r from-green-300 to-cyan-800",
  "bg-gradient-to-r from-green-300 to-cyan-900",
  "bg-gradient-to-r from-green-300 to-cyan-950",
  "bg-gradient-to-r from-green-300 to-sky-300",
  "bg-gradient-to-r from-green-300 to-sky-400",
  "bg-gradient-to-r from-green-300 to-sky-500",
  "bg-gradient-to-r from-green-300 to-sky-600",
  "bg-gradient-to-r from-green-300 to-sky-700",
  "bg-gradient-to-r from-green-300 to-sky-800",
  "bg-gradient-to-r from-green-300 to-sky-900",
  "bg-gradient-to-r from-green-300 to-sky-950",
  "bg-gradient-to-r from-green-300 to-blue-300",
  "bg-gradient-to-r from-green-300 to-blue-400",
  "bg-gradient-to-r from-green-300 to-blue-500",
  "bg-gradient-to-r from-green-300 to-blue-600",
  "bg-gradient-to-r from-green-300 to-blue-700",
  "bg-gradient-to-r from-green-300 to-blue-800",
  "bg-gradient-to-r from-green-300 to-blue-900",
  "bg-gradient-to-r from-green-300 to-blue-950",
  "bg-gradient-to-r from-green-300 to-indigo-300",
  "bg-gradient-to-r from-green-300 to-indigo-400",
  "bg-gradient-to-r from-green-300 to-indigo-500",
  "bg-gradient-to-r from-green-300 to-indigo-600",
  "bg-gradient-to-r from-green-300 to-indigo-700",
  "bg-gradient-to-r from-green-300 to-indigo-800",
  "bg-gradient-to-r from-green-300 to-indigo-900",
  "bg-gradient-to-r from-green-300 to-indigo-950",
  "bg-gradient-to-r from-green-300 to-violet-300",
  "bg-gradient-to-r from-green-300 to-violet-400",
  "bg-gradient-to-r from-green-300 to-violet-500",
  "bg-gradient-to-r from-green-300 to-violet-600",
  "bg-gradient-to-r from-green-300 to-violet-700",
  "bg-gradient-to-r from-green-300 to-violet-800",
  "bg-gradient-to-r from-green-300 to-violet-900",
  "bg-gradient-to-r from-green-300 to-violet-950",
  "bg-gradient-to-r from-green-300 to-purple-300",
  "bg-gradient-to-r from-green-300 to-purple-400",
  "bg-gradient-to-r from-green-300 to-purple-500",
  "bg-gradient-to-r from-green-300 to-purple-600",
  "bg-gradient-to-r from-green-300 to-purple-700",
  "bg-gradient-to-r from-green-300 to-purple-800",
  "bg-gradient-to-r from-green-300 to-purple-900",
  "bg-gradient-to-r from-green-300 to-purple-950",
  "bg-gradient-to-r from-green-300 to-fuchsia-300",
  "bg-gradient-to-r from-green-300 to-fuchsia-400",
  "bg-gradient-to-r from-green-300 to-fuchsia-500",
  "bg-gradient-to-r from-green-300 to-fuchsia-600",
  "bg-gradient-to-r from-green-300 to-fuchsia-700",
  "bg-gradient-to-r from-green-300 to-fuchsia-800",
  "bg-gradient-to-r from-green-300 to-fuchsia-900",
  "bg-gradient-to-r from-green-300 to-fuchsia-950",
  "bg-gradient-to-r from-green-300 to-pink-300",
  "bg-gradient-to-r from-green-300 to-pink-400",
  "bg-gradient-to-r from-green-300 to-pink-500",
  "bg-gradient-to-r from-green-300 to-pink-600",
  "bg-gradient-to-r from-green-300 to-pink-700",
  "bg-gradient-to-r from-green-300 to-pink-800",
  "bg-gradient-to-r from-green-300 to-pink-900",
  "bg-gradient-to-r from-green-300 to-pink-950",
  "bg-gradient-to-r from-green-300 to-rose-300",
  "bg-gradient-to-r from-green-300 to-rose-400",
  "bg-gradient-to-r from-green-300 to-rose-500",
  "bg-gradient-to-r from-green-300 to-rose-600",
  "bg-gradient-to-r from-green-300 to-rose-700",
  "bg-gradient-to-r from-green-300 to-rose-800",
  "bg-gradient-to-r from-green-300 to-rose-900",
  "bg-gradient-to-r from-green-300 to-rose-950",
  "bg-gradient-to-r from-green-400 to-red-300",
  "bg-gradient-to-r from-green-400 to-red-400",
  "bg-gradient-to-r from-green-400 to-red-500",
  "bg-gradient-to-r from-green-400 to-red-600",
  "bg-gradient-to-r from-green-400 to-red-700",
  "bg-gradient-to-r from-green-400 to-red-800",
  "bg-gradient-to-r from-green-400 to-red-900",
  "bg-gradient-to-r from-green-400 to-red-950",
  "bg-gradient-to-r from-green-400 to-orange-300",
  "bg-gradient-to-r from-green-400 to-orange-400",
  "bg-gradient-to-r from-green-400 to-orange-500",
  "bg-gradient-to-r from-green-400 to-orange-600",
  "bg-gradient-to-r from-green-400 to-orange-700",
  "bg-gradient-to-r from-green-400 to-orange-800",
  "bg-gradient-to-r from-green-400 to-orange-900",
  "bg-gradient-to-r from-green-400 to-orange-950",
  "bg-gradient-to-r from-green-400 to-amber-300",
  "bg-gradient-to-r from-green-400 to-amber-400",
  "bg-gradient-to-r from-green-400 to-amber-500",
  "bg-gradient-to-r from-green-400 to-amber-600",
  "bg-gradient-to-r from-green-400 to-amber-700",
  "bg-gradient-to-r from-green-400 to-amber-800",
  "bg-gradient-to-r from-green-400 to-amber-900",
  "bg-gradient-to-r from-green-400 to-amber-950",
  "bg-gradient-to-r from-green-400 to-yellow-300",
  "bg-gradient-to-r from-green-400 to-yellow-400",
  "bg-gradient-to-r from-green-400 to-yellow-500",
  "bg-gradient-to-r from-green-400 to-yellow-600",
  "bg-gradient-to-r from-green-400 to-yellow-700",
  "bg-gradient-to-r from-green-400 to-yellow-800",
  "bg-gradient-to-r from-green-400 to-yellow-900",
  "bg-gradient-to-r from-green-400 to-yellow-950",
  "bg-gradient-to-r from-green-400 to-lime-300",
  "bg-gradient-to-r from-green-400 to-lime-400",
  "bg-gradient-to-r from-green-400 to-lime-500",
  "bg-gradient-to-r from-green-400 to-lime-600",
  "bg-gradient-to-r from-green-400 to-lime-700",
  "bg-gradient-to-r from-green-400 to-lime-800",
  "bg-gradient-to-r from-green-400 to-lime-900",
  "bg-gradient-to-r from-green-400 to-lime-950",
  "bg-gradient-to-r from-green-400 to-emerald-300",
  "bg-gradient-to-r from-green-400 to-emerald-400",
  "bg-gradient-to-r from-green-400 to-emerald-500",
  "bg-gradient-to-r from-green-400 to-emerald-600",
  "bg-gradient-to-r from-green-400 to-emerald-700",
  "bg-gradient-to-r from-green-400 to-emerald-800",
  "bg-gradient-to-r from-green-400 to-emerald-900",
  "bg-gradient-to-r from-green-400 to-emerald-950",
  "bg-gradient-to-r from-green-400 to-teal-300",
  "bg-gradient-to-r from-green-400 to-teal-400",
  "bg-gradient-to-r from-green-400 to-teal-500",
  "bg-gradient-to-r from-green-400 to-teal-600",
  "bg-gradient-to-r from-green-400 to-teal-700",
  "bg-gradient-to-r from-green-400 to-teal-800",
  "bg-gradient-to-r from-green-400 to-teal-900",
  "bg-gradient-to-r from-green-400 to-teal-950",
  "bg-gradient-to-r from-green-400 to-cyan-300",
  "bg-gradient-to-r from-green-400 to-cyan-400",
  "bg-gradient-to-r from-green-400 to-cyan-500",
  "bg-gradient-to-r from-green-400 to-cyan-600",
  "bg-gradient-to-r from-green-400 to-cyan-700",
  "bg-gradient-to-r from-green-400 to-cyan-800",
  "bg-gradient-to-r from-green-400 to-cyan-900",
  "bg-gradient-to-r from-green-400 to-cyan-950",
  "bg-gradient-to-r from-green-400 to-sky-300",
  "bg-gradient-to-r from-green-400 to-sky-400",
  "bg-gradient-to-r from-green-400 to-sky-500",
  "bg-gradient-to-r from-green-400 to-sky-600",
  "bg-gradient-to-r from-green-400 to-sky-700",
  "bg-gradient-to-r from-green-400 to-sky-800",
  "bg-gradient-to-r from-green-400 to-sky-900",
  "bg-gradient-to-r from-green-400 to-sky-950",
  "bg-gradient-to-r from-green-400 to-blue-300",
  "bg-gradient-to-r from-green-400 to-blue-400",
  "bg-gradient-to-r from-green-400 to-blue-500",
  "bg-gradient-to-r from-green-400 to-blue-600",
  "bg-gradient-to-r from-green-400 to-blue-700",
  "bg-gradient-to-r from-green-400 to-blue-800",
  "bg-gradient-to-r from-green-400 to-blue-900",
  "bg-gradient-to-r from-green-400 to-blue-950",
  "bg-gradient-to-r from-green-400 to-indigo-300",
  "bg-gradient-to-r from-green-400 to-indigo-400",
  "bg-gradient-to-r from-green-400 to-indigo-500",
  "bg-gradient-to-r from-green-400 to-indigo-600",
  "bg-gradient-to-r from-green-400 to-indigo-700",
  "bg-gradient-to-r from-green-400 to-indigo-800",
  "bg-gradient-to-r from-green-400 to-indigo-900",
  "bg-gradient-to-r from-green-400 to-indigo-950",
  "bg-gradient-to-r from-green-400 to-violet-300",
  "bg-gradient-to-r from-green-400 to-violet-400",
  "bg-gradient-to-r from-green-400 to-violet-500",
  "bg-gradient-to-r from-green-400 to-violet-600",
  "bg-gradient-to-r from-green-400 to-violet-700",
  "bg-gradient-to-r from-green-400 to-violet-800",
  "bg-gradient-to-r from-green-400 to-violet-900",
  "bg-gradient-to-r from-green-400 to-violet-950",
  "bg-gradient-to-r from-green-400 to-purple-300",
  "bg-gradient-to-r from-green-400 to-purple-400",
  "bg-gradient-to-r from-green-400 to-purple-500",
  "bg-gradient-to-r from-green-400 to-purple-600",
  "bg-gradient-to-r from-green-400 to-purple-700",
  "bg-gradient-to-r from-green-400 to-purple-800",
  "bg-gradient-to-r from-green-400 to-purple-900",
  "bg-gradient-to-r from-green-400 to-purple-950",
  "bg-gradient-to-r from-green-400 to-fuchsia-300",
  "bg-gradient-to-r from-green-400 to-fuchsia-400",
  "bg-gradient-to-r from-green-400 to-fuchsia-500",
  "bg-gradient-to-r from-green-400 to-fuchsia-600",
  "bg-gradient-to-r from-green-400 to-fuchsia-700",
  "bg-gradient-to-r from-green-400 to-fuchsia-800",
  "bg-gradient-to-r from-green-400 to-fuchsia-900",
  "bg-gradient-to-r from-green-400 to-fuchsia-950",
  "bg-gradient-to-r from-green-400 to-pink-300",
  "bg-gradient-to-r from-green-400 to-pink-400",
  "bg-gradient-to-r from-green-400 to-pink-500",
  "bg-gradient-to-r from-green-400 to-pink-600",
  "bg-gradient-to-r from-green-400 to-pink-700",
  "bg-gradient-to-r from-green-400 to-pink-800",
  "bg-gradient-to-r from-green-400 to-pink-900",
  "bg-gradient-to-r from-green-400 to-pink-950",
  "bg-gradient-to-r from-green-400 to-rose-300",
  "bg-gradient-to-r from-green-400 to-rose-400",
  "bg-gradient-to-r from-green-400 to-rose-500",
  "bg-gradient-to-r from-green-400 to-rose-600",
  "bg-gradient-to-r from-green-400 to-rose-700",
  "bg-gradient-to-r from-green-400 to-rose-800",
  "bg-gradient-to-r from-green-400 to-rose-900",
  "bg-gradient-to-r from-green-400 to-rose-950",
  "bg-gradient-to-r from-green-500 to-red-300",
  "bg-gradient-to-r from-green-500 to-red-400",
  "bg-gradient-to-r from-green-500 to-red-500",
  "bg-gradient-to-r from-green-500 to-red-600",
  "bg-gradient-to-r from-green-500 to-red-700",
  "bg-gradient-to-r from-green-500 to-red-800",
  "bg-gradient-to-r from-green-500 to-red-900",
  "bg-gradient-to-r from-green-500 to-red-950",
  "bg-gradient-to-r from-green-500 to-orange-300",
  "bg-gradient-to-r from-green-500 to-orange-400",
  "bg-gradient-to-r from-green-500 to-orange-500",
  "bg-gradient-to-r from-green-500 to-orange-600",
  "bg-gradient-to-r from-green-500 to-orange-700",
  "bg-gradient-to-r from-green-500 to-orange-800",
  "bg-gradient-to-r from-green-500 to-orange-900",
  "bg-gradient-to-r from-green-500 to-orange-950",
  "bg-gradient-to-r from-green-500 to-amber-300",
  "bg-gradient-to-r from-green-500 to-amber-400",
  "bg-gradient-to-r from-green-500 to-amber-500",
  "bg-gradient-to-r from-green-500 to-amber-600",
  "bg-gradient-to-r from-green-500 to-amber-700",
  "bg-gradient-to-r from-green-500 to-amber-800",
  "bg-gradient-to-r from-green-500 to-amber-900",
  "bg-gradient-to-r from-green-500 to-amber-950",
  "bg-gradient-to-r from-green-500 to-yellow-300",
  "bg-gradient-to-r from-green-500 to-yellow-400",
  "bg-gradient-to-r from-green-500 to-yellow-500",
  "bg-gradient-to-r from-green-500 to-yellow-600",
  "bg-gradient-to-r from-green-500 to-yellow-700",
  "bg-gradient-to-r from-green-500 to-yellow-800",
  "bg-gradient-to-r from-green-500 to-yellow-900",
  "bg-gradient-to-r from-green-500 to-yellow-950",
  "bg-gradient-to-r from-green-500 to-lime-300",
  "bg-gradient-to-r from-green-500 to-lime-400",
  "bg-gradient-to-r from-green-500 to-lime-500",
  "bg-gradient-to-r from-green-500 to-lime-600",
  "bg-gradient-to-r from-green-500 to-lime-700",
  "bg-gradient-to-r from-green-500 to-lime-800",
  "bg-gradient-to-r from-green-500 to-lime-900",
  "bg-gradient-to-r from-green-500 to-lime-950",
  "bg-gradient-to-r from-green-500 to-emerald-300",
  "bg-gradient-to-r from-green-500 to-emerald-400",
  "bg-gradient-to-r from-green-500 to-emerald-500",
  "bg-gradient-to-r from-green-500 to-emerald-600",
  "bg-gradient-to-r from-green-500 to-emerald-700",
  "bg-gradient-to-r from-green-500 to-emerald-800",
  "bg-gradient-to-r from-green-500 to-emerald-900",
  "bg-gradient-to-r from-green-500 to-emerald-950",
  "bg-gradient-to-r from-green-500 to-teal-300",
  "bg-gradient-to-r from-green-500 to-teal-400",
  "bg-gradient-to-r from-green-500 to-teal-500",
  "bg-gradient-to-r from-green-500 to-teal-600",
  "bg-gradient-to-r from-green-500 to-teal-700",
  "bg-gradient-to-r from-green-500 to-teal-800",
  "bg-gradient-to-r from-green-500 to-teal-900",
  "bg-gradient-to-r from-green-500 to-teal-950",
  "bg-gradient-to-r from-green-500 to-cyan-300",
  "bg-gradient-to-r from-green-500 to-cyan-400",
  "bg-gradient-to-r from-green-500 to-cyan-500",
  "bg-gradient-to-r from-green-500 to-cyan-600",
  "bg-gradient-to-r from-green-500 to-cyan-700",
  "bg-gradient-to-r from-green-500 to-cyan-800",
  "bg-gradient-to-r from-green-500 to-cyan-900",
  "bg-gradient-to-r from-green-500 to-cyan-950",
  "bg-gradient-to-r from-green-500 to-sky-300",
  "bg-gradient-to-r from-green-500 to-sky-400",
  "bg-gradient-to-r from-green-500 to-sky-500",
  "bg-gradient-to-r from-green-500 to-sky-600",
  "bg-gradient-to-r from-green-500 to-sky-700",
  "bg-gradient-to-r from-green-500 to-sky-800",
  "bg-gradient-to-r from-green-500 to-sky-900",
  "bg-gradient-to-r from-green-500 to-sky-950",
  "bg-gradient-to-r from-green-500 to-blue-300",
  "bg-gradient-to-r from-green-500 to-blue-400",
  "bg-gradient-to-r from-green-500 to-blue-500",
  "bg-gradient-to-r from-green-500 to-blue-600",
  "bg-gradient-to-r from-green-500 to-blue-700",
  "bg-gradient-to-r from-green-500 to-blue-800",
  "bg-gradient-to-r from-green-500 to-blue-900",
  "bg-gradient-to-r from-green-500 to-blue-950",
  "bg-gradient-to-r from-green-500 to-indigo-300",
  "bg-gradient-to-r from-green-500 to-indigo-400",
  "bg-gradient-to-r from-green-500 to-indigo-500",
  "bg-gradient-to-r from-green-500 to-indigo-600",
  "bg-gradient-to-r from-green-500 to-indigo-700",
  "bg-gradient-to-r from-green-500 to-indigo-800",
  "bg-gradient-to-r from-green-500 to-indigo-900",
  "bg-gradient-to-r from-green-500 to-indigo-950",
  "bg-gradient-to-r from-green-500 to-violet-300",
  "bg-gradient-to-r from-green-500 to-violet-400",
  "bg-gradient-to-r from-green-500 to-violet-500",
  "bg-gradient-to-r from-green-500 to-violet-600",
  "bg-gradient-to-r from-green-500 to-violet-700",
  "bg-gradient-to-r from-green-500 to-violet-800",
  "bg-gradient-to-r from-green-500 to-violet-900",
  "bg-gradient-to-r from-green-500 to-violet-950",
  "bg-gradient-to-r from-green-500 to-purple-300",
  "bg-gradient-to-r from-green-500 to-purple-400",
  "bg-gradient-to-r from-green-500 to-purple-500",
  "bg-gradient-to-r from-green-500 to-purple-600",
  "bg-gradient-to-r from-green-500 to-purple-700",
  "bg-gradient-to-r from-green-500 to-purple-800",
  "bg-gradient-to-r from-green-500 to-purple-900",
  "bg-gradient-to-r from-green-500 to-purple-950",
  "bg-gradient-to-r from-green-500 to-fuchsia-300",
  "bg-gradient-to-r from-green-500 to-fuchsia-400",
  "bg-gradient-to-r from-green-500 to-fuchsia-500",
  "bg-gradient-to-r from-green-500 to-fuchsia-600",
  "bg-gradient-to-r from-green-500 to-fuchsia-700",
  "bg-gradient-to-r from-green-500 to-fuchsia-800",
  "bg-gradient-to-r from-green-500 to-fuchsia-900",
  "bg-gradient-to-r from-green-500 to-fuchsia-950",
  "bg-gradient-to-r from-green-500 to-pink-300",
  "bg-gradient-to-r from-green-500 to-pink-400",
  "bg-gradient-to-r from-green-500 to-pink-500",
  "bg-gradient-to-r from-green-500 to-pink-600",
  "bg-gradient-to-r from-green-500 to-pink-700",
  "bg-gradient-to-r from-green-500 to-pink-800",
  "bg-gradient-to-r from-green-500 to-pink-900",
  "bg-gradient-to-r from-green-500 to-pink-950",
  "bg-gradient-to-r from-green-500 to-rose-300",
  "bg-gradient-to-r from-green-500 to-rose-400",
  "bg-gradient-to-r from-green-500 to-rose-500",
  "bg-gradient-to-r from-green-500 to-rose-600",
  "bg-gradient-to-r from-green-500 to-rose-700",
  "bg-gradient-to-r from-green-500 to-rose-800",
  "bg-gradient-to-r from-green-500 to-rose-900",
  "bg-gradient-to-r from-green-500 to-rose-950",
  "bg-gradient-to-r from-green-600 to-red-300",
  "bg-gradient-to-r from-green-600 to-red-400",
  "bg-gradient-to-r from-green-600 to-red-500",
  "bg-gradient-to-r from-green-600 to-red-600",
  "bg-gradient-to-r from-green-600 to-red-700",
  "bg-gradient-to-r from-green-600 to-red-800",
  "bg-gradient-to-r from-green-600 to-red-900",
  "bg-gradient-to-r from-green-600 to-red-950",
  "bg-gradient-to-r from-green-600 to-orange-300",
  "bg-gradient-to-r from-green-600 to-orange-400",
  "bg-gradient-to-r from-green-600 to-orange-500",
  "bg-gradient-to-r from-green-600 to-orange-600",
  "bg-gradient-to-r from-green-600 to-orange-700",
  "bg-gradient-to-r from-green-600 to-orange-800",
  "bg-gradient-to-r from-green-600 to-orange-900",
  "bg-gradient-to-r from-green-600 to-orange-950",
  "bg-gradient-to-r from-green-600 to-amber-300",
  "bg-gradient-to-r from-green-600 to-amber-400",
  "bg-gradient-to-r from-green-600 to-amber-500",
  "bg-gradient-to-r from-green-600 to-amber-600",
  "bg-gradient-to-r from-green-600 to-amber-700",
  "bg-gradient-to-r from-green-600 to-amber-800",
  "bg-gradient-to-r from-green-600 to-amber-900",
  "bg-gradient-to-r from-green-600 to-amber-950",
  "bg-gradient-to-r from-green-600 to-yellow-300",
  "bg-gradient-to-r from-green-600 to-yellow-400",
  "bg-gradient-to-r from-green-600 to-yellow-500",
  "bg-gradient-to-r from-green-600 to-yellow-600",
  "bg-gradient-to-r from-green-600 to-yellow-700",
  "bg-gradient-to-r from-green-600 to-yellow-800",
  "bg-gradient-to-r from-green-600 to-yellow-900",
  "bg-gradient-to-r from-green-600 to-yellow-950",
  "bg-gradient-to-r from-green-600 to-lime-300",
  "bg-gradient-to-r from-green-600 to-lime-400",
  "bg-gradient-to-r from-green-600 to-lime-500",
  "bg-gradient-to-r from-green-600 to-lime-600",
  "bg-gradient-to-r from-green-600 to-lime-700",
  "bg-gradient-to-r from-green-600 to-lime-800",
  "bg-gradient-to-r from-green-600 to-lime-900",
  "bg-gradient-to-r from-green-600 to-lime-950",
  "bg-gradient-to-r from-green-600 to-emerald-300",
  "bg-gradient-to-r from-green-600 to-emerald-400",
  "bg-gradient-to-r from-green-600 to-emerald-500",
  "bg-gradient-to-r from-green-600 to-emerald-600",
  "bg-gradient-to-r from-green-600 to-emerald-700",
  "bg-gradient-to-r from-green-600 to-emerald-800",
  "bg-gradient-to-r from-green-600 to-emerald-900",
  "bg-gradient-to-r from-green-600 to-emerald-950",
  "bg-gradient-to-r from-green-600 to-teal-300",
  "bg-gradient-to-r from-green-600 to-teal-400",
  "bg-gradient-to-r from-green-600 to-teal-500",
  "bg-gradient-to-r from-green-600 to-teal-600",
  "bg-gradient-to-r from-green-600 to-teal-700",
  "bg-gradient-to-r from-green-600 to-teal-800",
  "bg-gradient-to-r from-green-600 to-teal-900",
  "bg-gradient-to-r from-green-600 to-teal-950",
  "bg-gradient-to-r from-green-600 to-cyan-300",
  "bg-gradient-to-r from-green-600 to-cyan-400",
  "bg-gradient-to-r from-green-600 to-cyan-500",
  "bg-gradient-to-r from-green-600 to-cyan-600",
  "bg-gradient-to-r from-green-600 to-cyan-700",
  "bg-gradient-to-r from-green-600 to-cyan-800",
  "bg-gradient-to-r from-green-600 to-cyan-900",
  "bg-gradient-to-r from-green-600 to-cyan-950",
  "bg-gradient-to-r from-green-600 to-sky-300",
  "bg-gradient-to-r from-green-600 to-sky-400",
  "bg-gradient-to-r from-green-600 to-sky-500",
  "bg-gradient-to-r from-green-600 to-sky-600",
  "bg-gradient-to-r from-green-600 to-sky-700",
  "bg-gradient-to-r from-green-600 to-sky-800",
  "bg-gradient-to-r from-green-600 to-sky-900",
  "bg-gradient-to-r from-green-600 to-sky-950",
  "bg-gradient-to-r from-green-600 to-blue-300",
  "bg-gradient-to-r from-green-600 to-blue-400",
  "bg-gradient-to-r from-green-600 to-blue-500",
  "bg-gradient-to-r from-green-600 to-blue-600",
  "bg-gradient-to-r from-green-600 to-blue-700",
  "bg-gradient-to-r from-green-600 to-blue-800",
  "bg-gradient-to-r from-green-600 to-blue-900",
  "bg-gradient-to-r from-green-600 to-blue-950",
  "bg-gradient-to-r from-green-600 to-indigo-300",
  "bg-gradient-to-r from-green-600 to-indigo-400",
  "bg-gradient-to-r from-green-600 to-indigo-500",
  "bg-gradient-to-r from-green-600 to-indigo-600",
  "bg-gradient-to-r from-green-600 to-indigo-700",
  "bg-gradient-to-r from-green-600 to-indigo-800",
  "bg-gradient-to-r from-green-600 to-indigo-900",
  "bg-gradient-to-r from-green-600 to-indigo-950",
  "bg-gradient-to-r from-green-600 to-violet-300",
  "bg-gradient-to-r from-green-600 to-violet-400",
  "bg-gradient-to-r from-green-600 to-violet-500",
  "bg-gradient-to-r from-green-600 to-violet-600",
  "bg-gradient-to-r from-green-600 to-violet-700",
  "bg-gradient-to-r from-green-600 to-violet-800",
  "bg-gradient-to-r from-green-600 to-violet-900",
  "bg-gradient-to-r from-green-600 to-violet-950",
  "bg-gradient-to-r from-green-600 to-purple-300",
  "bg-gradient-to-r from-green-600 to-purple-400",
  "bg-gradient-to-r from-green-600 to-purple-500",
  "bg-gradient-to-r from-green-600 to-purple-600",
  "bg-gradient-to-r from-green-600 to-purple-700",
  "bg-gradient-to-r from-green-600 to-purple-800",
  "bg-gradient-to-r from-green-600 to-purple-900",
  "bg-gradient-to-r from-green-600 to-purple-950",
  "bg-gradient-to-r from-green-600 to-fuchsia-300",
  "bg-gradient-to-r from-green-600 to-fuchsia-400",
  "bg-gradient-to-r from-green-600 to-fuchsia-500",
  "bg-gradient-to-r from-green-600 to-fuchsia-600",
  "bg-gradient-to-r from-green-600 to-fuchsia-700",
  "bg-gradient-to-r from-green-600 to-fuchsia-800",
  "bg-gradient-to-r from-green-600 to-fuchsia-900",
  "bg-gradient-to-r from-green-600 to-fuchsia-950",
  "bg-gradient-to-r from-green-600 to-pink-300",
  "bg-gradient-to-r from-green-600 to-pink-400",
  "bg-gradient-to-r from-green-600 to-pink-500",
  "bg-gradient-to-r from-green-600 to-pink-600",
  "bg-gradient-to-r from-green-600 to-pink-700",
  "bg-gradient-to-r from-green-600 to-pink-800",
  "bg-gradient-to-r from-green-600 to-pink-900",
  "bg-gradient-to-r from-green-600 to-pink-950",
  "bg-gradient-to-r from-green-600 to-rose-300",
  "bg-gradient-to-r from-green-600 to-rose-400",
  "bg-gradient-to-r from-green-600 to-rose-500",
  "bg-gradient-to-r from-green-600 to-rose-600",
  "bg-gradient-to-r from-green-600 to-rose-700",
  "bg-gradient-to-r from-green-600 to-rose-800",
  "bg-gradient-to-r from-green-600 to-rose-900",
  "bg-gradient-to-r from-green-600 to-rose-950",
  "bg-gradient-to-r from-green-700 to-red-300",
  "bg-gradient-to-r from-green-700 to-red-400",
  "bg-gradient-to-r from-green-700 to-red-500",
  "bg-gradient-to-r from-green-700 to-red-600",
  "bg-gradient-to-r from-green-700 to-red-700",
  "bg-gradient-to-r from-green-700 to-red-800",
  "bg-gradient-to-r from-green-700 to-red-900",
  "bg-gradient-to-r from-green-700 to-red-950",
  "bg-gradient-to-r from-green-700 to-orange-300",
  "bg-gradient-to-r from-green-700 to-orange-400",
  "bg-gradient-to-r from-green-700 to-orange-500",
  "bg-gradient-to-r from-green-700 to-orange-600",
  "bg-gradient-to-r from-green-700 to-orange-700",
  "bg-gradient-to-r from-green-700 to-orange-800",
  "bg-gradient-to-r from-green-700 to-orange-900",
  "bg-gradient-to-r from-green-700 to-orange-950",
  "bg-gradient-to-r from-green-700 to-amber-300",
  "bg-gradient-to-r from-green-700 to-amber-400",
  "bg-gradient-to-r from-green-700 to-amber-500",
  "bg-gradient-to-r from-green-700 to-amber-600",
  "bg-gradient-to-r from-green-700 to-amber-700",
  "bg-gradient-to-r from-green-700 to-amber-800",
  "bg-gradient-to-r from-green-700 to-amber-900",
  "bg-gradient-to-r from-green-700 to-amber-950",
  "bg-gradient-to-r from-green-700 to-yellow-300",
  "bg-gradient-to-r from-green-700 to-yellow-400",
  "bg-gradient-to-r from-green-700 to-yellow-500",
  "bg-gradient-to-r from-green-700 to-yellow-600",
  "bg-gradient-to-r from-green-700 to-yellow-700",
  "bg-gradient-to-r from-green-700 to-yellow-800",
  "bg-gradient-to-r from-green-700 to-yellow-900",
  "bg-gradient-to-r from-green-700 to-yellow-950",
  "bg-gradient-to-r from-green-700 to-lime-300",
  "bg-gradient-to-r from-green-700 to-lime-400",
  "bg-gradient-to-r from-green-700 to-lime-500",
  "bg-gradient-to-r from-green-700 to-lime-600",
  "bg-gradient-to-r from-green-700 to-lime-700",
  "bg-gradient-to-r from-green-700 to-lime-800",
  "bg-gradient-to-r from-green-700 to-lime-900",
  "bg-gradient-to-r from-green-700 to-lime-950",
  "bg-gradient-to-r from-green-700 to-emerald-300",
  "bg-gradient-to-r from-green-700 to-emerald-400",
  "bg-gradient-to-r from-green-700 to-emerald-500",
  "bg-gradient-to-r from-green-700 to-emerald-600",
  "bg-gradient-to-r from-green-700 to-emerald-700",
  "bg-gradient-to-r from-green-700 to-emerald-800",
  "bg-gradient-to-r from-green-700 to-emerald-900",
  "bg-gradient-to-r from-green-700 to-emerald-950",
  "bg-gradient-to-r from-green-700 to-teal-300",
  "bg-gradient-to-r from-green-700 to-teal-400",
  "bg-gradient-to-r from-green-700 to-teal-500",
  "bg-gradient-to-r from-green-700 to-teal-600",
  "bg-gradient-to-r from-green-700 to-teal-700",
  "bg-gradient-to-r from-green-700 to-teal-800",
  "bg-gradient-to-r from-green-700 to-teal-900",
  "bg-gradient-to-r from-green-700 to-teal-950",
  "bg-gradient-to-r from-green-700 to-cyan-300",
  "bg-gradient-to-r from-green-700 to-cyan-400",
  "bg-gradient-to-r from-green-700 to-cyan-500",
  "bg-gradient-to-r from-green-700 to-cyan-600",
  "bg-gradient-to-r from-green-700 to-cyan-700",
  "bg-gradient-to-r from-green-700 to-cyan-800",
  "bg-gradient-to-r from-green-700 to-cyan-900",
  "bg-gradient-to-r from-green-700 to-cyan-950",
  "bg-gradient-to-r from-green-700 to-sky-300",
  "bg-gradient-to-r from-green-700 to-sky-400",
  "bg-gradient-to-r from-green-700 to-sky-500",
  "bg-gradient-to-r from-green-700 to-sky-600",
  "bg-gradient-to-r from-green-700 to-sky-700",
  "bg-gradient-to-r from-green-700 to-sky-800",
  "bg-gradient-to-r from-green-700 to-sky-900",
  "bg-gradient-to-r from-green-700 to-sky-950",
  "bg-gradient-to-r from-green-700 to-blue-300",
  "bg-gradient-to-r from-green-700 to-blue-400",
  "bg-gradient-to-r from-green-700 to-blue-500",
  "bg-gradient-to-r from-green-700 to-blue-600",
  "bg-gradient-to-r from-green-700 to-blue-700",
  "bg-gradient-to-r from-green-700 to-blue-800",
  "bg-gradient-to-r from-green-700 to-blue-900",
  "bg-gradient-to-r from-green-700 to-blue-950",
  "bg-gradient-to-r from-green-700 to-indigo-300",
  "bg-gradient-to-r from-green-700 to-indigo-400",
  "bg-gradient-to-r from-green-700 to-indigo-500",
  "bg-gradient-to-r from-green-700 to-indigo-600",
  "bg-gradient-to-r from-green-700 to-indigo-700",
  "bg-gradient-to-r from-green-700 to-indigo-800",
  "bg-gradient-to-r from-green-700 to-indigo-900",
  "bg-gradient-to-r from-green-700 to-indigo-950",
  "bg-gradient-to-r from-green-700 to-violet-300",
  "bg-gradient-to-r from-green-700 to-violet-400",
  "bg-gradient-to-r from-green-700 to-violet-500",
  "bg-gradient-to-r from-green-700 to-violet-600",
  "bg-gradient-to-r from-green-700 to-violet-700",
  "bg-gradient-to-r from-green-700 to-violet-800",
  "bg-gradient-to-r from-green-700 to-violet-900",
  "bg-gradient-to-r from-green-700 to-violet-950",
  "bg-gradient-to-r from-green-700 to-purple-300",
  "bg-gradient-to-r from-green-700 to-purple-400",
  "bg-gradient-to-r from-green-700 to-purple-500",
  "bg-gradient-to-r from-green-700 to-purple-600",
  "bg-gradient-to-r from-green-700 to-purple-700",
  "bg-gradient-to-r from-green-700 to-purple-800",
  "bg-gradient-to-r from-green-700 to-purple-900",
  "bg-gradient-to-r from-green-700 to-purple-950",
  "bg-gradient-to-r from-green-700 to-fuchsia-300",
  "bg-gradient-to-r from-green-700 to-fuchsia-400",
  "bg-gradient-to-r from-green-700 to-fuchsia-500",
  "bg-gradient-to-r from-green-700 to-fuchsia-600",
  "bg-gradient-to-r from-green-700 to-fuchsia-700",
  "bg-gradient-to-r from-green-700 to-fuchsia-800",
  "bg-gradient-to-r from-green-700 to-fuchsia-900",
  "bg-gradient-to-r from-green-700 to-fuchsia-950",
  "bg-gradient-to-r from-green-700 to-pink-300",
  "bg-gradient-to-r from-green-700 to-pink-400",
  "bg-gradient-to-r from-green-700 to-pink-500",
  "bg-gradient-to-r from-green-700 to-pink-600",
  "bg-gradient-to-r from-green-700 to-pink-700",
  "bg-gradient-to-r from-green-700 to-pink-800",
  "bg-gradient-to-r from-green-700 to-pink-900",
  "bg-gradient-to-r from-green-700 to-pink-950",
  "bg-gradient-to-r from-green-700 to-rose-300",
  "bg-gradient-to-r from-green-700 to-rose-400",
  "bg-gradient-to-r from-green-700 to-rose-500",
  "bg-gradient-to-r from-green-700 to-rose-600",
  "bg-gradient-to-r from-green-700 to-rose-700",
  "bg-gradient-to-r from-green-700 to-rose-800",
  "bg-gradient-to-r from-green-700 to-rose-900",
  "bg-gradient-to-r from-green-700 to-rose-950",
  "bg-gradient-to-r from-green-800 to-red-300",
  "bg-gradient-to-r from-green-800 to-red-400",
  "bg-gradient-to-r from-green-800 to-red-500",
  "bg-gradient-to-r from-green-800 to-red-600",
  "bg-gradient-to-r from-green-800 to-red-700",
  "bg-gradient-to-r from-green-800 to-red-800",
  "bg-gradient-to-r from-green-800 to-red-900",
  "bg-gradient-to-r from-green-800 to-red-950",
  "bg-gradient-to-r from-green-800 to-orange-300",
  "bg-gradient-to-r from-green-800 to-orange-400",
  "bg-gradient-to-r from-green-800 to-orange-500",
  "bg-gradient-to-r from-green-800 to-orange-600",
  "bg-gradient-to-r from-green-800 to-orange-700",
  "bg-gradient-to-r from-green-800 to-orange-800",
  "bg-gradient-to-r from-green-800 to-orange-900",
  "bg-gradient-to-r from-green-800 to-orange-950",
  "bg-gradient-to-r from-green-800 to-amber-300",
  "bg-gradient-to-r from-green-800 to-amber-400",
  "bg-gradient-to-r from-green-800 to-amber-500",
  "bg-gradient-to-r from-green-800 to-amber-600",
  "bg-gradient-to-r from-green-800 to-amber-700",
  "bg-gradient-to-r from-green-800 to-amber-800",
  "bg-gradient-to-r from-green-800 to-amber-900",
  "bg-gradient-to-r from-green-800 to-amber-950",
  "bg-gradient-to-r from-green-800 to-yellow-300",
  "bg-gradient-to-r from-green-800 to-yellow-400",
  "bg-gradient-to-r from-green-800 to-yellow-500",
  "bg-gradient-to-r from-green-800 to-yellow-600",
  "bg-gradient-to-r from-green-800 to-yellow-700",
  "bg-gradient-to-r from-green-800 to-yellow-800",
  "bg-gradient-to-r from-green-800 to-yellow-900",
  "bg-gradient-to-r from-green-800 to-yellow-950",
  "bg-gradient-to-r from-green-800 to-lime-300",
  "bg-gradient-to-r from-green-800 to-lime-400",
  "bg-gradient-to-r from-green-800 to-lime-500",
  "bg-gradient-to-r from-green-800 to-lime-600",
  "bg-gradient-to-r from-green-800 to-lime-700",
  "bg-gradient-to-r from-green-800 to-lime-800",
  "bg-gradient-to-r from-green-800 to-lime-900",
  "bg-gradient-to-r from-green-800 to-lime-950",
  "bg-gradient-to-r from-green-800 to-emerald-300",
  "bg-gradient-to-r from-green-800 to-emerald-400",
  "bg-gradient-to-r from-green-800 to-emerald-500",
  "bg-gradient-to-r from-green-800 to-emerald-600",
  "bg-gradient-to-r from-green-800 to-emerald-700",
  "bg-gradient-to-r from-green-800 to-emerald-800",
  "bg-gradient-to-r from-green-800 to-emerald-900",
  "bg-gradient-to-r from-green-800 to-emerald-950",
  "bg-gradient-to-r from-green-800 to-teal-300",
  "bg-gradient-to-r from-green-800 to-teal-400",
  "bg-gradient-to-r from-green-800 to-teal-500",
  "bg-gradient-to-r from-green-800 to-teal-600",
  "bg-gradient-to-r from-green-800 to-teal-700",
  "bg-gradient-to-r from-green-800 to-teal-800",
  "bg-gradient-to-r from-green-800 to-teal-900",
  "bg-gradient-to-r from-green-800 to-teal-950",
  "bg-gradient-to-r from-green-800 to-cyan-300",
  "bg-gradient-to-r from-green-800 to-cyan-400",
  "bg-gradient-to-r from-green-800 to-cyan-500",
  "bg-gradient-to-r from-green-800 to-cyan-600",
  "bg-gradient-to-r from-green-800 to-cyan-700",
  "bg-gradient-to-r from-green-800 to-cyan-800",
  "bg-gradient-to-r from-green-800 to-cyan-900",
  "bg-gradient-to-r from-green-800 to-cyan-950",
  "bg-gradient-to-r from-green-800 to-sky-300",
  "bg-gradient-to-r from-green-800 to-sky-400",
  "bg-gradient-to-r from-green-800 to-sky-500",
  "bg-gradient-to-r from-green-800 to-sky-600",
  "bg-gradient-to-r from-green-800 to-sky-700",
  "bg-gradient-to-r from-green-800 to-sky-800",
  "bg-gradient-to-r from-green-800 to-sky-900",
  "bg-gradient-to-r from-green-800 to-sky-950",
  "bg-gradient-to-r from-green-800 to-blue-300",
  "bg-gradient-to-r from-green-800 to-blue-400",
  "bg-gradient-to-r from-green-800 to-blue-500",
  "bg-gradient-to-r from-green-800 to-blue-600",
  "bg-gradient-to-r from-green-800 to-blue-700",
  "bg-gradient-to-r from-green-800 to-blue-800",
  "bg-gradient-to-r from-green-800 to-blue-900",
  "bg-gradient-to-r from-green-800 to-blue-950",
  "bg-gradient-to-r from-green-800 to-indigo-300",
  "bg-gradient-to-r from-green-800 to-indigo-400",
  "bg-gradient-to-r from-green-800 to-indigo-500",
  "bg-gradient-to-r from-green-800 to-indigo-600",
  "bg-gradient-to-r from-green-800 to-indigo-700",
  "bg-gradient-to-r from-green-800 to-indigo-800",
  "bg-gradient-to-r from-green-800 to-indigo-900",
  "bg-gradient-to-r from-green-800 to-indigo-950",
  "bg-gradient-to-r from-green-800 to-violet-300",
  "bg-gradient-to-r from-green-800 to-violet-400",
  "bg-gradient-to-r from-green-800 to-violet-500",
  "bg-gradient-to-r from-green-800 to-violet-600",
  "bg-gradient-to-r from-green-800 to-violet-700",
  "bg-gradient-to-r from-green-800 to-violet-800",
  "bg-gradient-to-r from-green-800 to-violet-900",
  "bg-gradient-to-r from-green-800 to-violet-950",
  "bg-gradient-to-r from-green-800 to-purple-300",
  "bg-gradient-to-r from-green-800 to-purple-400",
  "bg-gradient-to-r from-green-800 to-purple-500",
  "bg-gradient-to-r from-green-800 to-purple-600",
  "bg-gradient-to-r from-green-800 to-purple-700",
  "bg-gradient-to-r from-green-800 to-purple-800",
  "bg-gradient-to-r from-green-800 to-purple-900",
  "bg-gradient-to-r from-green-800 to-purple-950",
  "bg-gradient-to-r from-green-800 to-fuchsia-300",
  "bg-gradient-to-r from-green-800 to-fuchsia-400",
  "bg-gradient-to-r from-green-800 to-fuchsia-500",
  "bg-gradient-to-r from-green-800 to-fuchsia-600",
  "bg-gradient-to-r from-green-800 to-fuchsia-700",
  "bg-gradient-to-r from-green-800 to-fuchsia-800",
  "bg-gradient-to-r from-green-800 to-fuchsia-900",
  "bg-gradient-to-r from-green-800 to-fuchsia-950",
  "bg-gradient-to-r from-green-800 to-pink-300",
  "bg-gradient-to-r from-green-800 to-pink-400",
  "bg-gradient-to-r from-green-800 to-pink-500",
  "bg-gradient-to-r from-green-800 to-pink-600",
  "bg-gradient-to-r from-green-800 to-pink-700",
  "bg-gradient-to-r from-green-800 to-pink-800",
  "bg-gradient-to-r from-green-800 to-pink-900",
  "bg-gradient-to-r from-green-800 to-pink-950",
  "bg-gradient-to-r from-green-800 to-rose-300",
  "bg-gradient-to-r from-green-800 to-rose-400",
  "bg-gradient-to-r from-green-800 to-rose-500",
  "bg-gradient-to-r from-green-800 to-rose-600",
  "bg-gradient-to-r from-green-800 to-rose-700",
  "bg-gradient-to-r from-green-800 to-rose-800",
  "bg-gradient-to-r from-green-800 to-rose-900",
  "bg-gradient-to-r from-green-800 to-rose-950",
  "bg-gradient-to-r from-green-900 to-red-300",
  "bg-gradient-to-r from-green-900 to-red-400",
  "bg-gradient-to-r from-green-900 to-red-500",
  "bg-gradient-to-r from-green-900 to-red-600",
  "bg-gradient-to-r from-green-900 to-red-700",
  "bg-gradient-to-r from-green-900 to-red-800",
  "bg-gradient-to-r from-green-900 to-red-900",
  "bg-gradient-to-r from-green-900 to-red-950",
  "bg-gradient-to-r from-green-900 to-orange-300",
  "bg-gradient-to-r from-green-900 to-orange-400",
  "bg-gradient-to-r from-green-900 to-orange-500",
  "bg-gradient-to-r from-green-900 to-orange-600",
  "bg-gradient-to-r from-green-900 to-orange-700",
  "bg-gradient-to-r from-green-900 to-orange-800",
  "bg-gradient-to-r from-green-900 to-orange-900",
  "bg-gradient-to-r from-green-900 to-orange-950",
  "bg-gradient-to-r from-green-900 to-amber-300",
  "bg-gradient-to-r from-green-900 to-amber-400",
  "bg-gradient-to-r from-green-900 to-amber-500",
  "bg-gradient-to-r from-green-900 to-amber-600",
  "bg-gradient-to-r from-green-900 to-amber-700",
  "bg-gradient-to-r from-green-900 to-amber-800",
  "bg-gradient-to-r from-green-900 to-amber-900",
  "bg-gradient-to-r from-green-900 to-amber-950",
  "bg-gradient-to-r from-green-900 to-yellow-300",
  "bg-gradient-to-r from-green-900 to-yellow-400",
  "bg-gradient-to-r from-green-900 to-yellow-500",
  "bg-gradient-to-r from-green-900 to-yellow-600",
  "bg-gradient-to-r from-green-900 to-yellow-700",
  "bg-gradient-to-r from-green-900 to-yellow-800",
  "bg-gradient-to-r from-green-900 to-yellow-900",
  "bg-gradient-to-r from-green-900 to-yellow-950",
  "bg-gradient-to-r from-green-900 to-lime-300",
  "bg-gradient-to-r from-green-900 to-lime-400",
  "bg-gradient-to-r from-green-900 to-lime-500",
  "bg-gradient-to-r from-green-900 to-lime-600",
  "bg-gradient-to-r from-green-900 to-lime-700",
  "bg-gradient-to-r from-green-900 to-lime-800",
  "bg-gradient-to-r from-green-900 to-lime-900",
  "bg-gradient-to-r from-green-900 to-lime-950",
  "bg-gradient-to-r from-green-900 to-emerald-300",
  "bg-gradient-to-r from-green-900 to-emerald-400",
  "bg-gradient-to-r from-green-900 to-emerald-500",
  "bg-gradient-to-r from-green-900 to-emerald-600",
  "bg-gradient-to-r from-green-900 to-emerald-700",
  "bg-gradient-to-r from-green-900 to-emerald-800",
  "bg-gradient-to-r from-green-900 to-emerald-900",
  "bg-gradient-to-r from-green-900 to-emerald-950",
  "bg-gradient-to-r from-green-900 to-teal-300",
  "bg-gradient-to-r from-green-900 to-teal-400",
  "bg-gradient-to-r from-green-900 to-teal-500",
  "bg-gradient-to-r from-green-900 to-teal-600",
  "bg-gradient-to-r from-green-900 to-teal-700",
  "bg-gradient-to-r from-green-900 to-teal-800",
  "bg-gradient-to-r from-green-900 to-teal-900",
  "bg-gradient-to-r from-green-900 to-teal-950",
  "bg-gradient-to-r from-green-900 to-cyan-300",
  "bg-gradient-to-r from-green-900 to-cyan-400",
  "bg-gradient-to-r from-green-900 to-cyan-500",
  "bg-gradient-to-r from-green-900 to-cyan-600",
  "bg-gradient-to-r from-green-900 to-cyan-700",
  "bg-gradient-to-r from-green-900 to-cyan-800",
  "bg-gradient-to-r from-green-900 to-cyan-900",
  "bg-gradient-to-r from-green-900 to-cyan-950",
  "bg-gradient-to-r from-green-900 to-sky-300",
  "bg-gradient-to-r from-green-900 to-sky-400",
  "bg-gradient-to-r from-green-900 to-sky-500",
  "bg-gradient-to-r from-green-900 to-sky-600",
  "bg-gradient-to-r from-green-900 to-sky-700",
  "bg-gradient-to-r from-green-900 to-sky-800",
  "bg-gradient-to-r from-green-900 to-sky-900",
  "bg-gradient-to-r from-green-900 to-sky-950",
  "bg-gradient-to-r from-green-900 to-blue-300",
  "bg-gradient-to-r from-green-900 to-blue-400",
  "bg-gradient-to-r from-green-900 to-blue-500",
  "bg-gradient-to-r from-green-900 to-blue-600",
  "bg-gradient-to-r from-green-900 to-blue-700",
  "bg-gradient-to-r from-green-900 to-blue-800",
  "bg-gradient-to-r from-green-900 to-blue-900",
  "bg-gradient-to-r from-green-900 to-blue-950",
  "bg-gradient-to-r from-green-900 to-indigo-300",
  "bg-gradient-to-r from-green-900 to-indigo-400",
  "bg-gradient-to-r from-green-900 to-indigo-500",
  "bg-gradient-to-r from-green-900 to-indigo-600",
  "bg-gradient-to-r from-green-900 to-indigo-700",
  "bg-gradient-to-r from-green-900 to-indigo-800",
  "bg-gradient-to-r from-green-900 to-indigo-900",
  "bg-gradient-to-r from-green-900 to-indigo-950",
  "bg-gradient-to-r from-green-900 to-violet-300",
  "bg-gradient-to-r from-green-900 to-violet-400",
  "bg-gradient-to-r from-green-900 to-violet-500",
  "bg-gradient-to-r from-green-900 to-violet-600",
  "bg-gradient-to-r from-green-900 to-violet-700",
  "bg-gradient-to-r from-green-900 to-violet-800",
  "bg-gradient-to-r from-green-900 to-violet-900",
  "bg-gradient-to-r from-green-900 to-violet-950",
  "bg-gradient-to-r from-green-900 to-purple-300",
  "bg-gradient-to-r from-green-900 to-purple-400",
  "bg-gradient-to-r from-green-900 to-purple-500",
  "bg-gradient-to-r from-green-900 to-purple-600",
  "bg-gradient-to-r from-green-900 to-purple-700",
  "bg-gradient-to-r from-green-900 to-purple-800",
  "bg-gradient-to-r from-green-900 to-purple-900",
  "bg-gradient-to-r from-green-900 to-purple-950",
  "bg-gradient-to-r from-green-900 to-fuchsia-300",
  "bg-gradient-to-r from-green-900 to-fuchsia-400",
  "bg-gradient-to-r from-green-900 to-fuchsia-500",
  "bg-gradient-to-r from-green-900 to-fuchsia-600",
  "bg-gradient-to-r from-green-900 to-fuchsia-700",
  "bg-gradient-to-r from-green-900 to-fuchsia-800",
  "bg-gradient-to-r from-green-900 to-fuchsia-900",
  "bg-gradient-to-r from-green-900 to-fuchsia-950",
  "bg-gradient-to-r from-green-900 to-pink-300",
  "bg-gradient-to-r from-green-900 to-pink-400",
  "bg-gradient-to-r from-green-900 to-pink-500",
  "bg-gradient-to-r from-green-900 to-pink-600",
  "bg-gradient-to-r from-green-900 to-pink-700",
  "bg-gradient-to-r from-green-900 to-pink-800",
  "bg-gradient-to-r from-green-900 to-pink-900",
  "bg-gradient-to-r from-green-900 to-pink-950",
  "bg-gradient-to-r from-green-900 to-rose-300",
  "bg-gradient-to-r from-green-900 to-rose-400",
  "bg-gradient-to-r from-green-900 to-rose-500",
  "bg-gradient-to-r from-green-900 to-rose-600",
  "bg-gradient-to-r from-green-900 to-rose-700",
  "bg-gradient-to-r from-green-900 to-rose-800",
  "bg-gradient-to-r from-green-900 to-rose-900",
  "bg-gradient-to-r from-green-900 to-rose-950",
  "bg-gradient-to-r from-green-950 to-red-300",
  "bg-gradient-to-r from-green-950 to-red-400",
  "bg-gradient-to-r from-green-950 to-red-500",
  "bg-gradient-to-r from-green-950 to-red-600",
  "bg-gradient-to-r from-green-950 to-red-700",
  "bg-gradient-to-r from-green-950 to-red-800",
  "bg-gradient-to-r from-green-950 to-red-900",
  "bg-gradient-to-r from-green-950 to-red-950",
  "bg-gradient-to-r from-green-950 to-orange-300",
  "bg-gradient-to-r from-green-950 to-orange-400",
  "bg-gradient-to-r from-green-950 to-orange-500",
  "bg-gradient-to-r from-green-950 to-orange-600",
  "bg-gradient-to-r from-green-950 to-orange-700",
  "bg-gradient-to-r from-green-950 to-orange-800",
  "bg-gradient-to-r from-green-950 to-orange-900",
  "bg-gradient-to-r from-green-950 to-orange-950",
  "bg-gradient-to-r from-green-950 to-amber-300",
  "bg-gradient-to-r from-green-950 to-amber-400",
  "bg-gradient-to-r from-green-950 to-amber-500",
  "bg-gradient-to-r from-green-950 to-amber-600",
  "bg-gradient-to-r from-green-950 to-amber-700",
  "bg-gradient-to-r from-green-950 to-amber-800",
  "bg-gradient-to-r from-green-950 to-amber-900",
  "bg-gradient-to-r from-green-950 to-amber-950",
  "bg-gradient-to-r from-green-950 to-yellow-300",
  "bg-gradient-to-r from-green-950 to-yellow-400",
  "bg-gradient-to-r from-green-950 to-yellow-500",
  "bg-gradient-to-r from-green-950 to-yellow-600",
  "bg-gradient-to-r from-green-950 to-yellow-700",
  "bg-gradient-to-r from-green-950 to-yellow-800",
  "bg-gradient-to-r from-green-950 to-yellow-900",
  "bg-gradient-to-r from-green-950 to-yellow-950",
  "bg-gradient-to-r from-green-950 to-lime-300",
  "bg-gradient-to-r from-green-950 to-lime-400",
  "bg-gradient-to-r from-green-950 to-lime-500",
  "bg-gradient-to-r from-green-950 to-lime-600",
  "bg-gradient-to-r from-green-950 to-lime-700",
  "bg-gradient-to-r from-green-950 to-lime-800",
  "bg-gradient-to-r from-green-950 to-lime-900",
  "bg-gradient-to-r from-green-950 to-lime-950",
  "bg-gradient-to-r from-green-950 to-emerald-300",
  "bg-gradient-to-r from-green-950 to-emerald-400",
  "bg-gradient-to-r from-green-950 to-emerald-500",
  "bg-gradient-to-r from-green-950 to-emerald-600",
  "bg-gradient-to-r from-green-950 to-emerald-700",
  "bg-gradient-to-r from-green-950 to-emerald-800",
  "bg-gradient-to-r from-green-950 to-emerald-900",
  "bg-gradient-to-r from-green-950 to-emerald-950",
  "bg-gradient-to-r from-green-950 to-teal-300",
  "bg-gradient-to-r from-green-950 to-teal-400",
  "bg-gradient-to-r from-green-950 to-teal-500",
  "bg-gradient-to-r from-green-950 to-teal-600",
  "bg-gradient-to-r from-green-950 to-teal-700",
  "bg-gradient-to-r from-green-950 to-teal-800",
  "bg-gradient-to-r from-green-950 to-teal-900",
  "bg-gradient-to-r from-green-950 to-teal-950",
  "bg-gradient-to-r from-green-950 to-cyan-300",
  "bg-gradient-to-r from-green-950 to-cyan-400",
  "bg-gradient-to-r from-green-950 to-cyan-500",
  "bg-gradient-to-r from-green-950 to-cyan-600",
  "bg-gradient-to-r from-green-950 to-cyan-700",
  "bg-gradient-to-r from-green-950 to-cyan-800",
  "bg-gradient-to-r from-green-950 to-cyan-900",
  "bg-gradient-to-r from-green-950 to-cyan-950",
  "bg-gradient-to-r from-green-950 to-sky-300",
  "bg-gradient-to-r from-green-950 to-sky-400",
  "bg-gradient-to-r from-green-950 to-sky-500",
  "bg-gradient-to-r from-green-950 to-sky-600",
  "bg-gradient-to-r from-green-950 to-sky-700",
  "bg-gradient-to-r from-green-950 to-sky-800",
  "bg-gradient-to-r from-green-950 to-sky-900",
  "bg-gradient-to-r from-green-950 to-sky-950",
  "bg-gradient-to-r from-green-950 to-blue-300",
  "bg-gradient-to-r from-green-950 to-blue-400",
  "bg-gradient-to-r from-green-950 to-blue-500",
  "bg-gradient-to-r from-green-950 to-blue-600",
  "bg-gradient-to-r from-green-950 to-blue-700",
  "bg-gradient-to-r from-green-950 to-blue-800",
  "bg-gradient-to-r from-green-950 to-blue-900",
  "bg-gradient-to-r from-green-950 to-blue-950",
  "bg-gradient-to-r from-green-950 to-indigo-300",
  "bg-gradient-to-r from-green-950 to-indigo-400",
  "bg-gradient-to-r from-green-950 to-indigo-500",
  "bg-gradient-to-r from-green-950 to-indigo-600",
  "bg-gradient-to-r from-green-950 to-indigo-700",
  "bg-gradient-to-r from-green-950 to-indigo-800",
  "bg-gradient-to-r from-green-950 to-indigo-900",
  "bg-gradient-to-r from-green-950 to-indigo-950",
  "bg-gradient-to-r from-green-950 to-violet-300",
  "bg-gradient-to-r from-green-950 to-violet-400",
  "bg-gradient-to-r from-green-950 to-violet-500",
  "bg-gradient-to-r from-green-950 to-violet-600",
  "bg-gradient-to-r from-green-950 to-violet-700",
  "bg-gradient-to-r from-green-950 to-violet-800",
  "bg-gradient-to-r from-green-950 to-violet-900",
  "bg-gradient-to-r from-green-950 to-violet-950",
  "bg-gradient-to-r from-green-950 to-purple-300",
  "bg-gradient-to-r from-green-950 to-purple-400",
  "bg-gradient-to-r from-green-950 to-purple-500",
  "bg-gradient-to-r from-green-950 to-purple-600",
  "bg-gradient-to-r from-green-950 to-purple-700",
  "bg-gradient-to-r from-green-950 to-purple-800",
  "bg-gradient-to-r from-green-950 to-purple-900",
  "bg-gradient-to-r from-green-950 to-purple-950",
  "bg-gradient-to-r from-green-950 to-fuchsia-300",
  "bg-gradient-to-r from-green-950 to-fuchsia-400",
  "bg-gradient-to-r from-green-950 to-fuchsia-500",
  "bg-gradient-to-r from-green-950 to-fuchsia-600",
  "bg-gradient-to-r from-green-950 to-fuchsia-700",
  "bg-gradient-to-r from-green-950 to-fuchsia-800",
  "bg-gradient-to-r from-green-950 to-fuchsia-900",
  "bg-gradient-to-r from-green-950 to-fuchsia-950",
  "bg-gradient-to-r from-green-950 to-pink-300",
  "bg-gradient-to-r from-green-950 to-pink-400",
  "bg-gradient-to-r from-green-950 to-pink-500",
  "bg-gradient-to-r from-green-950 to-pink-600",
  "bg-gradient-to-r from-green-950 to-pink-700",
  "bg-gradient-to-r from-green-950 to-pink-800",
  "bg-gradient-to-r from-green-950 to-pink-900",
  "bg-gradient-to-r from-green-950 to-pink-950",
  "bg-gradient-to-r from-green-950 to-rose-300",
  "bg-gradient-to-r from-green-950 to-rose-400",
  "bg-gradient-to-r from-green-950 to-rose-500",
  "bg-gradient-to-r from-green-950 to-rose-600",
  "bg-gradient-to-r from-green-950 to-rose-700",
  "bg-gradient-to-r from-green-950 to-rose-800",
  "bg-gradient-to-r from-green-950 to-rose-900",
  "bg-gradient-to-r from-green-950 to-rose-950",
  "bg-gradient-to-r from-emerald-300 to-red-300",
  "bg-gradient-to-r from-emerald-300 to-red-400",
  "bg-gradient-to-r from-emerald-300 to-red-500",
  "bg-gradient-to-r from-emerald-300 to-red-600",
  "bg-gradient-to-r from-emerald-300 to-red-700",
  "bg-gradient-to-r from-emerald-300 to-red-800",
  "bg-gradient-to-r from-emerald-300 to-red-900",
  "bg-gradient-to-r from-emerald-300 to-red-950",
  "bg-gradient-to-r from-emerald-300 to-orange-300",
  "bg-gradient-to-r from-emerald-300 to-orange-400",
  "bg-gradient-to-r from-emerald-300 to-orange-500",
  "bg-gradient-to-r from-emerald-300 to-orange-600",
  "bg-gradient-to-r from-emerald-300 to-orange-700",
  "bg-gradient-to-r from-emerald-300 to-orange-800",
  "bg-gradient-to-r from-emerald-300 to-orange-900",
  "bg-gradient-to-r from-emerald-300 to-orange-950",
  "bg-gradient-to-r from-emerald-300 to-amber-300",
  "bg-gradient-to-r from-emerald-300 to-amber-400",
  "bg-gradient-to-r from-emerald-300 to-amber-500",
  "bg-gradient-to-r from-emerald-300 to-amber-600",
  "bg-gradient-to-r from-emerald-300 to-amber-700",
  "bg-gradient-to-r from-emerald-300 to-amber-800",
  "bg-gradient-to-r from-emerald-300 to-amber-900",
  "bg-gradient-to-r from-emerald-300 to-amber-950",
  "bg-gradient-to-r from-emerald-300 to-yellow-300",
  "bg-gradient-to-r from-emerald-300 to-yellow-400",
  "bg-gradient-to-r from-emerald-300 to-yellow-500",
  "bg-gradient-to-r from-emerald-300 to-yellow-600",
  "bg-gradient-to-r from-emerald-300 to-yellow-700",
  "bg-gradient-to-r from-emerald-300 to-yellow-800",
  "bg-gradient-to-r from-emerald-300 to-yellow-900",
  "bg-gradient-to-r from-emerald-300 to-yellow-950",
  "bg-gradient-to-r from-emerald-300 to-lime-300",
  "bg-gradient-to-r from-emerald-300 to-lime-400",
  "bg-gradient-to-r from-emerald-300 to-lime-500",
  "bg-gradient-to-r from-emerald-300 to-lime-600",
  "bg-gradient-to-r from-emerald-300 to-lime-700",
  "bg-gradient-to-r from-emerald-300 to-lime-800",
  "bg-gradient-to-r from-emerald-300 to-lime-900",
  "bg-gradient-to-r from-emerald-300 to-lime-950",
  "bg-gradient-to-r from-emerald-300 to-green-300",
  "bg-gradient-to-r from-emerald-300 to-green-400",
  "bg-gradient-to-r from-emerald-300 to-green-500",
  "bg-gradient-to-r from-emerald-300 to-green-600",
  "bg-gradient-to-r from-emerald-300 to-green-700",
  "bg-gradient-to-r from-emerald-300 to-green-800",
  "bg-gradient-to-r from-emerald-300 to-green-900",
  "bg-gradient-to-r from-emerald-300 to-green-950",
  "bg-gradient-to-r from-emerald-300 to-teal-300",
  "bg-gradient-to-r from-emerald-300 to-teal-400",
  "bg-gradient-to-r from-emerald-300 to-teal-500",
  "bg-gradient-to-r from-emerald-300 to-teal-600",
  "bg-gradient-to-r from-emerald-300 to-teal-700",
  "bg-gradient-to-r from-emerald-300 to-teal-800",
  "bg-gradient-to-r from-emerald-300 to-teal-900",
  "bg-gradient-to-r from-emerald-300 to-teal-950",
  "bg-gradient-to-r from-emerald-300 to-cyan-300",
  "bg-gradient-to-r from-emerald-300 to-cyan-400",
  "bg-gradient-to-r from-emerald-300 to-cyan-500",
  "bg-gradient-to-r from-emerald-300 to-cyan-600",
  "bg-gradient-to-r from-emerald-300 to-cyan-700",
  "bg-gradient-to-r from-emerald-300 to-cyan-800",
  "bg-gradient-to-r from-emerald-300 to-cyan-900",
  "bg-gradient-to-r from-emerald-300 to-cyan-950",
  "bg-gradient-to-r from-emerald-300 to-sky-300",
  "bg-gradient-to-r from-emerald-300 to-sky-400",
  "bg-gradient-to-r from-emerald-300 to-sky-500",
  "bg-gradient-to-r from-emerald-300 to-sky-600",
  "bg-gradient-to-r from-emerald-300 to-sky-700",
  "bg-gradient-to-r from-emerald-300 to-sky-800",
  "bg-gradient-to-r from-emerald-300 to-sky-900",
  "bg-gradient-to-r from-emerald-300 to-sky-950",
  "bg-gradient-to-r from-emerald-300 to-blue-300",
  "bg-gradient-to-r from-emerald-300 to-blue-400",
  "bg-gradient-to-r from-emerald-300 to-blue-500",
  "bg-gradient-to-r from-emerald-300 to-blue-600",
  "bg-gradient-to-r from-emerald-300 to-blue-700",
  "bg-gradient-to-r from-emerald-300 to-blue-800",
  "bg-gradient-to-r from-emerald-300 to-blue-900",
  "bg-gradient-to-r from-emerald-300 to-blue-950",
  "bg-gradient-to-r from-emerald-300 to-indigo-300",
  "bg-gradient-to-r from-emerald-300 to-indigo-400",
  "bg-gradient-to-r from-emerald-300 to-indigo-500",
  "bg-gradient-to-r from-emerald-300 to-indigo-600",
  "bg-gradient-to-r from-emerald-300 to-indigo-700",
  "bg-gradient-to-r from-emerald-300 to-indigo-800",
  "bg-gradient-to-r from-emerald-300 to-indigo-900",
  "bg-gradient-to-r from-emerald-300 to-indigo-950",
  "bg-gradient-to-r from-emerald-300 to-violet-300",
  "bg-gradient-to-r from-emerald-300 to-violet-400",
  "bg-gradient-to-r from-emerald-300 to-violet-500",
  "bg-gradient-to-r from-emerald-300 to-violet-600",
  "bg-gradient-to-r from-emerald-300 to-violet-700",
  "bg-gradient-to-r from-emerald-300 to-violet-800",
  "bg-gradient-to-r from-emerald-300 to-violet-900",
  "bg-gradient-to-r from-emerald-300 to-violet-950",
  "bg-gradient-to-r from-emerald-300 to-purple-300",
  "bg-gradient-to-r from-emerald-300 to-purple-400",
  "bg-gradient-to-r from-emerald-300 to-purple-500",
  "bg-gradient-to-r from-emerald-300 to-purple-600",
  "bg-gradient-to-r from-emerald-300 to-purple-700",
  "bg-gradient-to-r from-emerald-300 to-purple-800",
  "bg-gradient-to-r from-emerald-300 to-purple-900",
  "bg-gradient-to-r from-emerald-300 to-purple-950",
  "bg-gradient-to-r from-emerald-300 to-fuchsia-300",
  "bg-gradient-to-r from-emerald-300 to-fuchsia-400",
  "bg-gradient-to-r from-emerald-300 to-fuchsia-500",
  "bg-gradient-to-r from-emerald-300 to-fuchsia-600",
  "bg-gradient-to-r from-emerald-300 to-fuchsia-700",
  "bg-gradient-to-r from-emerald-300 to-fuchsia-800",
  "bg-gradient-to-r from-emerald-300 to-fuchsia-900",
  "bg-gradient-to-r from-emerald-300 to-fuchsia-950",
  "bg-gradient-to-r from-emerald-300 to-pink-300",
  "bg-gradient-to-r from-emerald-300 to-pink-400",
  "bg-gradient-to-r from-emerald-300 to-pink-500",
  "bg-gradient-to-r from-emerald-300 to-pink-600",
  "bg-gradient-to-r from-emerald-300 to-pink-700",
  "bg-gradient-to-r from-emerald-300 to-pink-800",
  "bg-gradient-to-r from-emerald-300 to-pink-900",
  "bg-gradient-to-r from-emerald-300 to-pink-950",
  "bg-gradient-to-r from-emerald-300 to-rose-300",
  "bg-gradient-to-r from-emerald-300 to-rose-400",
  "bg-gradient-to-r from-emerald-300 to-rose-500",
  "bg-gradient-to-r from-emerald-300 to-rose-600",
  "bg-gradient-to-r from-emerald-300 to-rose-700",
  "bg-gradient-to-r from-emerald-300 to-rose-800",
  "bg-gradient-to-r from-emerald-300 to-rose-900",
  "bg-gradient-to-r from-emerald-300 to-rose-950",
  "bg-gradient-to-r from-emerald-400 to-red-300",
  "bg-gradient-to-r from-emerald-400 to-red-400",
  "bg-gradient-to-r from-emerald-400 to-red-500",
  "bg-gradient-to-r from-emerald-400 to-red-600",
  "bg-gradient-to-r from-emerald-400 to-red-700",
  "bg-gradient-to-r from-emerald-400 to-red-800",
  "bg-gradient-to-r from-emerald-400 to-red-900",
  "bg-gradient-to-r from-emerald-400 to-red-950",
  "bg-gradient-to-r from-emerald-400 to-orange-300",
  "bg-gradient-to-r from-emerald-400 to-orange-400",
  "bg-gradient-to-r from-emerald-400 to-orange-500",
  "bg-gradient-to-r from-emerald-400 to-orange-600",
  "bg-gradient-to-r from-emerald-400 to-orange-700",
  "bg-gradient-to-r from-emerald-400 to-orange-800",
  "bg-gradient-to-r from-emerald-400 to-orange-900",
  "bg-gradient-to-r from-emerald-400 to-orange-950",
  "bg-gradient-to-r from-emerald-400 to-amber-300",
  "bg-gradient-to-r from-emerald-400 to-amber-400",
  "bg-gradient-to-r from-emerald-400 to-amber-500",
  "bg-gradient-to-r from-emerald-400 to-amber-600",
  "bg-gradient-to-r from-emerald-400 to-amber-700",
  "bg-gradient-to-r from-emerald-400 to-amber-800",
  "bg-gradient-to-r from-emerald-400 to-amber-900",
  "bg-gradient-to-r from-emerald-400 to-amber-950",
  "bg-gradient-to-r from-emerald-400 to-yellow-300",
  "bg-gradient-to-r from-emerald-400 to-yellow-400",
  "bg-gradient-to-r from-emerald-400 to-yellow-500",
  "bg-gradient-to-r from-emerald-400 to-yellow-600",
  "bg-gradient-to-r from-emerald-400 to-yellow-700",
  "bg-gradient-to-r from-emerald-400 to-yellow-800",
  "bg-gradient-to-r from-emerald-400 to-yellow-900",
  "bg-gradient-to-r from-emerald-400 to-yellow-950",
  "bg-gradient-to-r from-emerald-400 to-lime-300",
  "bg-gradient-to-r from-emerald-400 to-lime-400",
  "bg-gradient-to-r from-emerald-400 to-lime-500",
  "bg-gradient-to-r from-emerald-400 to-lime-600",
  "bg-gradient-to-r from-emerald-400 to-lime-700",
  "bg-gradient-to-r from-emerald-400 to-lime-800",
  "bg-gradient-to-r from-emerald-400 to-lime-900",
  "bg-gradient-to-r from-emerald-400 to-lime-950",
  "bg-gradient-to-r from-emerald-400 to-green-300",
  "bg-gradient-to-r from-emerald-400 to-green-400",
  "bg-gradient-to-r from-emerald-400 to-green-500",
  "bg-gradient-to-r from-emerald-400 to-green-600",
  "bg-gradient-to-r from-emerald-400 to-green-700",
  "bg-gradient-to-r from-emerald-400 to-green-800",
  "bg-gradient-to-r from-emerald-400 to-green-900",
  "bg-gradient-to-r from-emerald-400 to-green-950",
  "bg-gradient-to-r from-emerald-400 to-teal-300",
  "bg-gradient-to-r from-emerald-400 to-teal-400",
  "bg-gradient-to-r from-emerald-400 to-teal-500",
  "bg-gradient-to-r from-emerald-400 to-teal-600",
  "bg-gradient-to-r from-emerald-400 to-teal-700",
  "bg-gradient-to-r from-emerald-400 to-teal-800",
  "bg-gradient-to-r from-emerald-400 to-teal-900",
  "bg-gradient-to-r from-emerald-400 to-teal-950",
  "bg-gradient-to-r from-emerald-400 to-cyan-300",
  "bg-gradient-to-r from-emerald-400 to-cyan-400",
  "bg-gradient-to-r from-emerald-400 to-cyan-500",
  "bg-gradient-to-r from-emerald-400 to-cyan-600",
  "bg-gradient-to-r from-emerald-400 to-cyan-700",
  "bg-gradient-to-r from-emerald-400 to-cyan-800",
  "bg-gradient-to-r from-emerald-400 to-cyan-900",
  "bg-gradient-to-r from-emerald-400 to-cyan-950",
  "bg-gradient-to-r from-emerald-400 to-sky-300",
  "bg-gradient-to-r from-emerald-400 to-sky-400",
  "bg-gradient-to-r from-emerald-400 to-sky-500",
  "bg-gradient-to-r from-emerald-400 to-sky-600",
  "bg-gradient-to-r from-emerald-400 to-sky-700",
  "bg-gradient-to-r from-emerald-400 to-sky-800",
  "bg-gradient-to-r from-emerald-400 to-sky-900",
  "bg-gradient-to-r from-emerald-400 to-sky-950",
  "bg-gradient-to-r from-emerald-400 to-blue-300",
  "bg-gradient-to-r from-emerald-400 to-blue-400",
  "bg-gradient-to-r from-emerald-400 to-blue-500",
  "bg-gradient-to-r from-emerald-400 to-blue-600",
  "bg-gradient-to-r from-emerald-400 to-blue-700",
  "bg-gradient-to-r from-emerald-400 to-blue-800",
  "bg-gradient-to-r from-emerald-400 to-blue-900",
  "bg-gradient-to-r from-emerald-400 to-blue-950",
  "bg-gradient-to-r from-emerald-400 to-indigo-300",
  "bg-gradient-to-r from-emerald-400 to-indigo-400",
  "bg-gradient-to-r from-emerald-400 to-indigo-500",
  "bg-gradient-to-r from-emerald-400 to-indigo-600",
  "bg-gradient-to-r from-emerald-400 to-indigo-700",
  "bg-gradient-to-r from-emerald-400 to-indigo-800",
  "bg-gradient-to-r from-emerald-400 to-indigo-900",
  "bg-gradient-to-r from-emerald-400 to-indigo-950",
  "bg-gradient-to-r from-emerald-400 to-violet-300",
  "bg-gradient-to-r from-emerald-400 to-violet-400",
  "bg-gradient-to-r from-emerald-400 to-violet-500",
  "bg-gradient-to-r from-emerald-400 to-violet-600",
  "bg-gradient-to-r from-emerald-400 to-violet-700",
  "bg-gradient-to-r from-emerald-400 to-violet-800",
  "bg-gradient-to-r from-emerald-400 to-violet-900",
  "bg-gradient-to-r from-emerald-400 to-violet-950",
  "bg-gradient-to-r from-emerald-400 to-purple-300",
  "bg-gradient-to-r from-emerald-400 to-purple-400",
  "bg-gradient-to-r from-emerald-400 to-purple-500",
  "bg-gradient-to-r from-emerald-400 to-purple-600",
  "bg-gradient-to-r from-emerald-400 to-purple-700",
  "bg-gradient-to-r from-emerald-400 to-purple-800",
  "bg-gradient-to-r from-emerald-400 to-purple-900",
  "bg-gradient-to-r from-emerald-400 to-purple-950",
  "bg-gradient-to-r from-emerald-400 to-fuchsia-300",
  "bg-gradient-to-r from-emerald-400 to-fuchsia-400",
  "bg-gradient-to-r from-emerald-400 to-fuchsia-500",
  "bg-gradient-to-r from-emerald-400 to-fuchsia-600",
  "bg-gradient-to-r from-emerald-400 to-fuchsia-700",
  "bg-gradient-to-r from-emerald-400 to-fuchsia-800",
  "bg-gradient-to-r from-emerald-400 to-fuchsia-900",
  "bg-gradient-to-r from-emerald-400 to-fuchsia-950",
  "bg-gradient-to-r from-emerald-400 to-pink-300",
  "bg-gradient-to-r from-emerald-400 to-pink-400",
  "bg-gradient-to-r from-emerald-400 to-pink-500",
  "bg-gradient-to-r from-emerald-400 to-pink-600",
  "bg-gradient-to-r from-emerald-400 to-pink-700",
  "bg-gradient-to-r from-emerald-400 to-pink-800",
  "bg-gradient-to-r from-emerald-400 to-pink-900",
  "bg-gradient-to-r from-emerald-400 to-pink-950",
  "bg-gradient-to-r from-emerald-400 to-rose-300",
  "bg-gradient-to-r from-emerald-400 to-rose-400",
  "bg-gradient-to-r from-emerald-400 to-rose-500",
  "bg-gradient-to-r from-emerald-400 to-rose-600",
  "bg-gradient-to-r from-emerald-400 to-rose-700",
  "bg-gradient-to-r from-emerald-400 to-rose-800",
  "bg-gradient-to-r from-emerald-400 to-rose-900",
  "bg-gradient-to-r from-emerald-400 to-rose-950",
  "bg-gradient-to-r from-emerald-500 to-red-300",
  "bg-gradient-to-r from-emerald-500 to-red-400",
  "bg-gradient-to-r from-emerald-500 to-red-500",
  "bg-gradient-to-r from-emerald-500 to-red-600",
  "bg-gradient-to-r from-emerald-500 to-red-700",
  "bg-gradient-to-r from-emerald-500 to-red-800",
  "bg-gradient-to-r from-emerald-500 to-red-900",
  "bg-gradient-to-r from-emerald-500 to-red-950",
  "bg-gradient-to-r from-emerald-500 to-orange-300",
  "bg-gradient-to-r from-emerald-500 to-orange-400",
  "bg-gradient-to-r from-emerald-500 to-orange-500",
  "bg-gradient-to-r from-emerald-500 to-orange-600",
  "bg-gradient-to-r from-emerald-500 to-orange-700",
  "bg-gradient-to-r from-emerald-500 to-orange-800",
  "bg-gradient-to-r from-emerald-500 to-orange-900",
  "bg-gradient-to-r from-emerald-500 to-orange-950",
  "bg-gradient-to-r from-emerald-500 to-amber-300",
  "bg-gradient-to-r from-emerald-500 to-amber-400",
  "bg-gradient-to-r from-emerald-500 to-amber-500",
  "bg-gradient-to-r from-emerald-500 to-amber-600",
  "bg-gradient-to-r from-emerald-500 to-amber-700",
  "bg-gradient-to-r from-emerald-500 to-amber-800",
  "bg-gradient-to-r from-emerald-500 to-amber-900",
  "bg-gradient-to-r from-emerald-500 to-amber-950",
  "bg-gradient-to-r from-emerald-500 to-yellow-300",
  "bg-gradient-to-r from-emerald-500 to-yellow-400",
  "bg-gradient-to-r from-emerald-500 to-yellow-500",
  "bg-gradient-to-r from-emerald-500 to-yellow-600",
  "bg-gradient-to-r from-emerald-500 to-yellow-700",
  "bg-gradient-to-r from-emerald-500 to-yellow-800",
  "bg-gradient-to-r from-emerald-500 to-yellow-900",
  "bg-gradient-to-r from-emerald-500 to-yellow-950",
  "bg-gradient-to-r from-emerald-500 to-lime-300",
  "bg-gradient-to-r from-emerald-500 to-lime-400",
  "bg-gradient-to-r from-emerald-500 to-lime-500",
  "bg-gradient-to-r from-emerald-500 to-lime-600",
  "bg-gradient-to-r from-emerald-500 to-lime-700",
  "bg-gradient-to-r from-emerald-500 to-lime-800",
  "bg-gradient-to-r from-emerald-500 to-lime-900",
  "bg-gradient-to-r from-emerald-500 to-lime-950",
  "bg-gradient-to-r from-emerald-500 to-green-300",
  "bg-gradient-to-r from-emerald-500 to-green-400",
  "bg-gradient-to-r from-emerald-500 to-green-500",
  "bg-gradient-to-r from-emerald-500 to-green-600",
  "bg-gradient-to-r from-emerald-500 to-green-700",
  "bg-gradient-to-r from-emerald-500 to-green-800",
  "bg-gradient-to-r from-emerald-500 to-green-900",
  "bg-gradient-to-r from-emerald-500 to-green-950",
  "bg-gradient-to-r from-emerald-500 to-teal-300",
  "bg-gradient-to-r from-emerald-500 to-teal-400",
  "bg-gradient-to-r from-emerald-500 to-teal-500",
  "bg-gradient-to-r from-emerald-500 to-teal-600",
  "bg-gradient-to-r from-emerald-500 to-teal-700",
  "bg-gradient-to-r from-emerald-500 to-teal-800",
  "bg-gradient-to-r from-emerald-500 to-teal-900",
  "bg-gradient-to-r from-emerald-500 to-teal-950",
  "bg-gradient-to-r from-emerald-500 to-cyan-300",
  "bg-gradient-to-r from-emerald-500 to-cyan-400",
  "bg-gradient-to-r from-emerald-500 to-cyan-500",
  "bg-gradient-to-r from-emerald-500 to-cyan-600",
  "bg-gradient-to-r from-emerald-500 to-cyan-700",
  "bg-gradient-to-r from-emerald-500 to-cyan-800",
  "bg-gradient-to-r from-emerald-500 to-cyan-900",
  "bg-gradient-to-r from-emerald-500 to-cyan-950",
  "bg-gradient-to-r from-emerald-500 to-sky-300",
  "bg-gradient-to-r from-emerald-500 to-sky-400",
  "bg-gradient-to-r from-emerald-500 to-sky-500",
  "bg-gradient-to-r from-emerald-500 to-sky-600",
  "bg-gradient-to-r from-emerald-500 to-sky-700",
  "bg-gradient-to-r from-emerald-500 to-sky-800",
  "bg-gradient-to-r from-emerald-500 to-sky-900",
  "bg-gradient-to-r from-emerald-500 to-sky-950",
  "bg-gradient-to-r from-emerald-500 to-blue-300",
  "bg-gradient-to-r from-emerald-500 to-blue-400",
  "bg-gradient-to-r from-emerald-500 to-blue-500",
  "bg-gradient-to-r from-emerald-500 to-blue-600",
  "bg-gradient-to-r from-emerald-500 to-blue-700",
  "bg-gradient-to-r from-emerald-500 to-blue-800",
  "bg-gradient-to-r from-emerald-500 to-blue-900",
  "bg-gradient-to-r from-emerald-500 to-blue-950",
  "bg-gradient-to-r from-emerald-500 to-indigo-300",
  "bg-gradient-to-r from-emerald-500 to-indigo-400",
  "bg-gradient-to-r from-emerald-500 to-indigo-500",
  "bg-gradient-to-r from-emerald-500 to-indigo-600",
  "bg-gradient-to-r from-emerald-500 to-indigo-700",
  "bg-gradient-to-r from-emerald-500 to-indigo-800",
  "bg-gradient-to-r from-emerald-500 to-indigo-900",
  "bg-gradient-to-r from-emerald-500 to-indigo-950",
  "bg-gradient-to-r from-emerald-500 to-violet-300",
  "bg-gradient-to-r from-emerald-500 to-violet-400",
  "bg-gradient-to-r from-emerald-500 to-violet-500",
  "bg-gradient-to-r from-emerald-500 to-violet-600",
  "bg-gradient-to-r from-emerald-500 to-violet-700",
  "bg-gradient-to-r from-emerald-500 to-violet-800",
  "bg-gradient-to-r from-emerald-500 to-violet-900",
  "bg-gradient-to-r from-emerald-500 to-violet-950",
  "bg-gradient-to-r from-emerald-500 to-purple-300",
  "bg-gradient-to-r from-emerald-500 to-purple-400",
  "bg-gradient-to-r from-emerald-500 to-purple-500",
  "bg-gradient-to-r from-emerald-500 to-purple-600",
  "bg-gradient-to-r from-emerald-500 to-purple-700",
  "bg-gradient-to-r from-emerald-500 to-purple-800",
  "bg-gradient-to-r from-emerald-500 to-purple-900",
  "bg-gradient-to-r from-emerald-500 to-purple-950",
  "bg-gradient-to-r from-emerald-500 to-fuchsia-300",
  "bg-gradient-to-r from-emerald-500 to-fuchsia-400",
  "bg-gradient-to-r from-emerald-500 to-fuchsia-500",
  "bg-gradient-to-r from-emerald-500 to-fuchsia-600",
  "bg-gradient-to-r from-emerald-500 to-fuchsia-700",
  "bg-gradient-to-r from-emerald-500 to-fuchsia-800",
  "bg-gradient-to-r from-emerald-500 to-fuchsia-900",
  "bg-gradient-to-r from-emerald-500 to-fuchsia-950",
  "bg-gradient-to-r from-emerald-500 to-pink-300",
  "bg-gradient-to-r from-emerald-500 to-pink-400",
  "bg-gradient-to-r from-emerald-500 to-pink-500",
  "bg-gradient-to-r from-emerald-500 to-pink-600",
  "bg-gradient-to-r from-emerald-500 to-pink-700",
  "bg-gradient-to-r from-emerald-500 to-pink-800",
  "bg-gradient-to-r from-emerald-500 to-pink-900",
  "bg-gradient-to-r from-emerald-500 to-pink-950",
  "bg-gradient-to-r from-emerald-500 to-rose-300",
  "bg-gradient-to-r from-emerald-500 to-rose-400",
  "bg-gradient-to-r from-emerald-500 to-rose-500",
  "bg-gradient-to-r from-emerald-500 to-rose-600",
  "bg-gradient-to-r from-emerald-500 to-rose-700",
  "bg-gradient-to-r from-emerald-500 to-rose-800",
  "bg-gradient-to-r from-emerald-500 to-rose-900",
  "bg-gradient-to-r from-emerald-500 to-rose-950",
  "bg-gradient-to-r from-emerald-600 to-red-300",
  "bg-gradient-to-r from-emerald-600 to-red-400",
  "bg-gradient-to-r from-emerald-600 to-red-500",
  "bg-gradient-to-r from-emerald-600 to-red-600",
  "bg-gradient-to-r from-emerald-600 to-red-700",
  "bg-gradient-to-r from-emerald-600 to-red-800",
  "bg-gradient-to-r from-emerald-600 to-red-900",
  "bg-gradient-to-r from-emerald-600 to-red-950",
  "bg-gradient-to-r from-emerald-600 to-orange-300",
  "bg-gradient-to-r from-emerald-600 to-orange-400",
  "bg-gradient-to-r from-emerald-600 to-orange-500",
  "bg-gradient-to-r from-emerald-600 to-orange-600",
  "bg-gradient-to-r from-emerald-600 to-orange-700",
  "bg-gradient-to-r from-emerald-600 to-orange-800",
  "bg-gradient-to-r from-emerald-600 to-orange-900",
  "bg-gradient-to-r from-emerald-600 to-orange-950",
  "bg-gradient-to-r from-emerald-600 to-amber-300",
  "bg-gradient-to-r from-emerald-600 to-amber-400",
  "bg-gradient-to-r from-emerald-600 to-amber-500",
  "bg-gradient-to-r from-emerald-600 to-amber-600",
  "bg-gradient-to-r from-emerald-600 to-amber-700",
  "bg-gradient-to-r from-emerald-600 to-amber-800",
  "bg-gradient-to-r from-emerald-600 to-amber-900",
  "bg-gradient-to-r from-emerald-600 to-amber-950",
  "bg-gradient-to-r from-emerald-600 to-yellow-300",
  "bg-gradient-to-r from-emerald-600 to-yellow-400",
  "bg-gradient-to-r from-emerald-600 to-yellow-500",
  "bg-gradient-to-r from-emerald-600 to-yellow-600",
  "bg-gradient-to-r from-emerald-600 to-yellow-700",
  "bg-gradient-to-r from-emerald-600 to-yellow-800",
  "bg-gradient-to-r from-emerald-600 to-yellow-900",
  "bg-gradient-to-r from-emerald-600 to-yellow-950",
  "bg-gradient-to-r from-emerald-600 to-lime-300",
  "bg-gradient-to-r from-emerald-600 to-lime-400",
  "bg-gradient-to-r from-emerald-600 to-lime-500",
  "bg-gradient-to-r from-emerald-600 to-lime-600",
  "bg-gradient-to-r from-emerald-600 to-lime-700",
  "bg-gradient-to-r from-emerald-600 to-lime-800",
  "bg-gradient-to-r from-emerald-600 to-lime-900",
  "bg-gradient-to-r from-emerald-600 to-lime-950",
  "bg-gradient-to-r from-emerald-600 to-green-300",
  "bg-gradient-to-r from-emerald-600 to-green-400",
  "bg-gradient-to-r from-emerald-600 to-green-500",
  "bg-gradient-to-r from-emerald-600 to-green-600",
  "bg-gradient-to-r from-emerald-600 to-green-700",
  "bg-gradient-to-r from-emerald-600 to-green-800",
  "bg-gradient-to-r from-emerald-600 to-green-900",
  "bg-gradient-to-r from-emerald-600 to-green-950",
  "bg-gradient-to-r from-emerald-600 to-teal-300",
  "bg-gradient-to-r from-emerald-600 to-teal-400",
  "bg-gradient-to-r from-emerald-600 to-teal-500",
  "bg-gradient-to-r from-emerald-600 to-teal-600",
  "bg-gradient-to-r from-emerald-600 to-teal-700",
  "bg-gradient-to-r from-emerald-600 to-teal-800",
  "bg-gradient-to-r from-emerald-600 to-teal-900",
  "bg-gradient-to-r from-emerald-600 to-teal-950",
  "bg-gradient-to-r from-emerald-600 to-cyan-300",
  "bg-gradient-to-r from-emerald-600 to-cyan-400",
  "bg-gradient-to-r from-emerald-600 to-cyan-500",
  "bg-gradient-to-r from-emerald-600 to-cyan-600",
  "bg-gradient-to-r from-emerald-600 to-cyan-700",
  "bg-gradient-to-r from-emerald-600 to-cyan-800",
  "bg-gradient-to-r from-emerald-600 to-cyan-900",
  "bg-gradient-to-r from-emerald-600 to-cyan-950",
  "bg-gradient-to-r from-emerald-600 to-sky-300",
  "bg-gradient-to-r from-emerald-600 to-sky-400",
  "bg-gradient-to-r from-emerald-600 to-sky-500",
  "bg-gradient-to-r from-emerald-600 to-sky-600",
  "bg-gradient-to-r from-emerald-600 to-sky-700",
  "bg-gradient-to-r from-emerald-600 to-sky-800",
  "bg-gradient-to-r from-emerald-600 to-sky-900",
  "bg-gradient-to-r from-emerald-600 to-sky-950",
  "bg-gradient-to-r from-emerald-600 to-blue-300",
  "bg-gradient-to-r from-emerald-600 to-blue-400",
  "bg-gradient-to-r from-emerald-600 to-blue-500",
  "bg-gradient-to-r from-emerald-600 to-blue-600",
  "bg-gradient-to-r from-emerald-600 to-blue-700",
  "bg-gradient-to-r from-emerald-600 to-blue-800",
  "bg-gradient-to-r from-emerald-600 to-blue-900",
  "bg-gradient-to-r from-emerald-600 to-blue-950",
  "bg-gradient-to-r from-emerald-600 to-indigo-300",
  "bg-gradient-to-r from-emerald-600 to-indigo-400",
  "bg-gradient-to-r from-emerald-600 to-indigo-500",
  "bg-gradient-to-r from-emerald-600 to-indigo-600",
  "bg-gradient-to-r from-emerald-600 to-indigo-700",
  "bg-gradient-to-r from-emerald-600 to-indigo-800",
  "bg-gradient-to-r from-emerald-600 to-indigo-900",
  "bg-gradient-to-r from-emerald-600 to-indigo-950",
  "bg-gradient-to-r from-emerald-600 to-violet-300",
  "bg-gradient-to-r from-emerald-600 to-violet-400",
  "bg-gradient-to-r from-emerald-600 to-violet-500",
  "bg-gradient-to-r from-emerald-600 to-violet-600",
  "bg-gradient-to-r from-emerald-600 to-violet-700",
  "bg-gradient-to-r from-emerald-600 to-violet-800",
  "bg-gradient-to-r from-emerald-600 to-violet-900",
  "bg-gradient-to-r from-emerald-600 to-violet-950",
  "bg-gradient-to-r from-emerald-600 to-purple-300",
  "bg-gradient-to-r from-emerald-600 to-purple-400",
  "bg-gradient-to-r from-emerald-600 to-purple-500",
  "bg-gradient-to-r from-emerald-600 to-purple-600",
  "bg-gradient-to-r from-emerald-600 to-purple-700",
  "bg-gradient-to-r from-emerald-600 to-purple-800",
  "bg-gradient-to-r from-emerald-600 to-purple-900",
  "bg-gradient-to-r from-emerald-600 to-purple-950",
  "bg-gradient-to-r from-emerald-600 to-fuchsia-300",
  "bg-gradient-to-r from-emerald-600 to-fuchsia-400",
  "bg-gradient-to-r from-emerald-600 to-fuchsia-500",
  "bg-gradient-to-r from-emerald-600 to-fuchsia-600",
  "bg-gradient-to-r from-emerald-600 to-fuchsia-700",
  "bg-gradient-to-r from-emerald-600 to-fuchsia-800",
  "bg-gradient-to-r from-emerald-600 to-fuchsia-900",
  "bg-gradient-to-r from-emerald-600 to-fuchsia-950",
  "bg-gradient-to-r from-emerald-600 to-pink-300",
  "bg-gradient-to-r from-emerald-600 to-pink-400",
  "bg-gradient-to-r from-emerald-600 to-pink-500",
  "bg-gradient-to-r from-emerald-600 to-pink-600",
  "bg-gradient-to-r from-emerald-600 to-pink-700",
  "bg-gradient-to-r from-emerald-600 to-pink-800",
  "bg-gradient-to-r from-emerald-600 to-pink-900",
  "bg-gradient-to-r from-emerald-600 to-pink-950",
  "bg-gradient-to-r from-emerald-600 to-rose-300",
  "bg-gradient-to-r from-emerald-600 to-rose-400",
  "bg-gradient-to-r from-emerald-600 to-rose-500",
  "bg-gradient-to-r from-emerald-600 to-rose-600",
  "bg-gradient-to-r from-emerald-600 to-rose-700",
  "bg-gradient-to-r from-emerald-600 to-rose-800",
  "bg-gradient-to-r from-emerald-600 to-rose-900",
  "bg-gradient-to-r from-emerald-600 to-rose-950",
  "bg-gradient-to-r from-emerald-700 to-red-300",
  "bg-gradient-to-r from-emerald-700 to-red-400",
  "bg-gradient-to-r from-emerald-700 to-red-500",
  "bg-gradient-to-r from-emerald-700 to-red-600",
  "bg-gradient-to-r from-emerald-700 to-red-700",
  "bg-gradient-to-r from-emerald-700 to-red-800",
  "bg-gradient-to-r from-emerald-700 to-red-900",
  "bg-gradient-to-r from-emerald-700 to-red-950",
  "bg-gradient-to-r from-emerald-700 to-orange-300",
  "bg-gradient-to-r from-emerald-700 to-orange-400",
  "bg-gradient-to-r from-emerald-700 to-orange-500",
  "bg-gradient-to-r from-emerald-700 to-orange-600",
  "bg-gradient-to-r from-emerald-700 to-orange-700",
  "bg-gradient-to-r from-emerald-700 to-orange-800",
  "bg-gradient-to-r from-emerald-700 to-orange-900",
  "bg-gradient-to-r from-emerald-700 to-orange-950",
  "bg-gradient-to-r from-emerald-700 to-amber-300",
  "bg-gradient-to-r from-emerald-700 to-amber-400",
  "bg-gradient-to-r from-emerald-700 to-amber-500",
  "bg-gradient-to-r from-emerald-700 to-amber-600",
  "bg-gradient-to-r from-emerald-700 to-amber-700",
  "bg-gradient-to-r from-emerald-700 to-amber-800",
  "bg-gradient-to-r from-emerald-700 to-amber-900",
  "bg-gradient-to-r from-emerald-700 to-amber-950",
  "bg-gradient-to-r from-emerald-700 to-yellow-300",
  "bg-gradient-to-r from-emerald-700 to-yellow-400",
  "bg-gradient-to-r from-emerald-700 to-yellow-500",
  "bg-gradient-to-r from-emerald-700 to-yellow-600",
  "bg-gradient-to-r from-emerald-700 to-yellow-700",
  "bg-gradient-to-r from-emerald-700 to-yellow-800",
  "bg-gradient-to-r from-emerald-700 to-yellow-900",
  "bg-gradient-to-r from-emerald-700 to-yellow-950",
  "bg-gradient-to-r from-emerald-700 to-lime-300",
  "bg-gradient-to-r from-emerald-700 to-lime-400",
  "bg-gradient-to-r from-emerald-700 to-lime-500",
  "bg-gradient-to-r from-emerald-700 to-lime-600",
  "bg-gradient-to-r from-emerald-700 to-lime-700",
  "bg-gradient-to-r from-emerald-700 to-lime-800",
  "bg-gradient-to-r from-emerald-700 to-lime-900",
  "bg-gradient-to-r from-emerald-700 to-lime-950",
  "bg-gradient-to-r from-emerald-700 to-green-300",
  "bg-gradient-to-r from-emerald-700 to-green-400",
  "bg-gradient-to-r from-emerald-700 to-green-500",
  "bg-gradient-to-r from-emerald-700 to-green-600",
  "bg-gradient-to-r from-emerald-700 to-green-700",
  "bg-gradient-to-r from-emerald-700 to-green-800",
  "bg-gradient-to-r from-emerald-700 to-green-900",
  "bg-gradient-to-r from-emerald-700 to-green-950",
  "bg-gradient-to-r from-emerald-700 to-teal-300",
  "bg-gradient-to-r from-emerald-700 to-teal-400",
  "bg-gradient-to-r from-emerald-700 to-teal-500",
  "bg-gradient-to-r from-emerald-700 to-teal-600",
  "bg-gradient-to-r from-emerald-700 to-teal-700",
  "bg-gradient-to-r from-emerald-700 to-teal-800",
  "bg-gradient-to-r from-emerald-700 to-teal-900",
  "bg-gradient-to-r from-emerald-700 to-teal-950",
  "bg-gradient-to-r from-emerald-700 to-cyan-300",
  "bg-gradient-to-r from-emerald-700 to-cyan-400",
  "bg-gradient-to-r from-emerald-700 to-cyan-500",
  "bg-gradient-to-r from-emerald-700 to-cyan-600",
  "bg-gradient-to-r from-emerald-700 to-cyan-700",
  "bg-gradient-to-r from-emerald-700 to-cyan-800",
  "bg-gradient-to-r from-emerald-700 to-cyan-900",
  "bg-gradient-to-r from-emerald-700 to-cyan-950",
  "bg-gradient-to-r from-emerald-700 to-sky-300",
  "bg-gradient-to-r from-emerald-700 to-sky-400",
  "bg-gradient-to-r from-emerald-700 to-sky-500",
  "bg-gradient-to-r from-emerald-700 to-sky-600",
  "bg-gradient-to-r from-emerald-700 to-sky-700",
  "bg-gradient-to-r from-emerald-700 to-sky-800",
  "bg-gradient-to-r from-emerald-700 to-sky-900",
  "bg-gradient-to-r from-emerald-700 to-sky-950",
  "bg-gradient-to-r from-emerald-700 to-blue-300",
  "bg-gradient-to-r from-emerald-700 to-blue-400",
  "bg-gradient-to-r from-emerald-700 to-blue-500",
  "bg-gradient-to-r from-emerald-700 to-blue-600",
  "bg-gradient-to-r from-emerald-700 to-blue-700",
  "bg-gradient-to-r from-emerald-700 to-blue-800",
  "bg-gradient-to-r from-emerald-700 to-blue-900",
  "bg-gradient-to-r from-emerald-700 to-blue-950",
  "bg-gradient-to-r from-emerald-700 to-indigo-300",
  "bg-gradient-to-r from-emerald-700 to-indigo-400",
  "bg-gradient-to-r from-emerald-700 to-indigo-500",
  "bg-gradient-to-r from-emerald-700 to-indigo-600",
  "bg-gradient-to-r from-emerald-700 to-indigo-700",
  "bg-gradient-to-r from-emerald-700 to-indigo-800",
  "bg-gradient-to-r from-emerald-700 to-indigo-900",
  "bg-gradient-to-r from-emerald-700 to-indigo-950",
  "bg-gradient-to-r from-emerald-700 to-violet-300",
  "bg-gradient-to-r from-emerald-700 to-violet-400",
  "bg-gradient-to-r from-emerald-700 to-violet-500",
  "bg-gradient-to-r from-emerald-700 to-violet-600",
  "bg-gradient-to-r from-emerald-700 to-violet-700",
  "bg-gradient-to-r from-emerald-700 to-violet-800",
  "bg-gradient-to-r from-emerald-700 to-violet-900",
  "bg-gradient-to-r from-emerald-700 to-violet-950",
  "bg-gradient-to-r from-emerald-700 to-purple-300",
  "bg-gradient-to-r from-emerald-700 to-purple-400",
  "bg-gradient-to-r from-emerald-700 to-purple-500",
  "bg-gradient-to-r from-emerald-700 to-purple-600",
  "bg-gradient-to-r from-emerald-700 to-purple-700",
  "bg-gradient-to-r from-emerald-700 to-purple-800",
  "bg-gradient-to-r from-emerald-700 to-purple-900",
  "bg-gradient-to-r from-emerald-700 to-purple-950",
  "bg-gradient-to-r from-emerald-700 to-fuchsia-300",
  "bg-gradient-to-r from-emerald-700 to-fuchsia-400",
  "bg-gradient-to-r from-emerald-700 to-fuchsia-500",
  "bg-gradient-to-r from-emerald-700 to-fuchsia-600",
  "bg-gradient-to-r from-emerald-700 to-fuchsia-700",
  "bg-gradient-to-r from-emerald-700 to-fuchsia-800",
  "bg-gradient-to-r from-emerald-700 to-fuchsia-900",
  "bg-gradient-to-r from-emerald-700 to-fuchsia-950",
  "bg-gradient-to-r from-emerald-700 to-pink-300",
  "bg-gradient-to-r from-emerald-700 to-pink-400",
  "bg-gradient-to-r from-emerald-700 to-pink-500",
  "bg-gradient-to-r from-emerald-700 to-pink-600",
  "bg-gradient-to-r from-emerald-700 to-pink-700",
  "bg-gradient-to-r from-emerald-700 to-pink-800",
  "bg-gradient-to-r from-emerald-700 to-pink-900",
  "bg-gradient-to-r from-emerald-700 to-pink-950",
  "bg-gradient-to-r from-emerald-700 to-rose-300",
  "bg-gradient-to-r from-emerald-700 to-rose-400",
  "bg-gradient-to-r from-emerald-700 to-rose-500",
  "bg-gradient-to-r from-emerald-700 to-rose-600",
  "bg-gradient-to-r from-emerald-700 to-rose-700",
  "bg-gradient-to-r from-emerald-700 to-rose-800",
  "bg-gradient-to-r from-emerald-700 to-rose-900",
  "bg-gradient-to-r from-emerald-700 to-rose-950",
  "bg-gradient-to-r from-emerald-800 to-red-300",
  "bg-gradient-to-r from-emerald-800 to-red-400",
  "bg-gradient-to-r from-emerald-800 to-red-500",
  "bg-gradient-to-r from-emerald-800 to-red-600",
  "bg-gradient-to-r from-emerald-800 to-red-700",
  "bg-gradient-to-r from-emerald-800 to-red-800",
  "bg-gradient-to-r from-emerald-800 to-red-900",
  "bg-gradient-to-r from-emerald-800 to-red-950",
  "bg-gradient-to-r from-emerald-800 to-orange-300",
  "bg-gradient-to-r from-emerald-800 to-orange-400",
  "bg-gradient-to-r from-emerald-800 to-orange-500",
  "bg-gradient-to-r from-emerald-800 to-orange-600",
  "bg-gradient-to-r from-emerald-800 to-orange-700",
  "bg-gradient-to-r from-emerald-800 to-orange-800",
  "bg-gradient-to-r from-emerald-800 to-orange-900",
  "bg-gradient-to-r from-emerald-800 to-orange-950",
  "bg-gradient-to-r from-emerald-800 to-amber-300",
  "bg-gradient-to-r from-emerald-800 to-amber-400",
  "bg-gradient-to-r from-emerald-800 to-amber-500",
  "bg-gradient-to-r from-emerald-800 to-amber-600",
  "bg-gradient-to-r from-emerald-800 to-amber-700",
  "bg-gradient-to-r from-emerald-800 to-amber-800",
  "bg-gradient-to-r from-emerald-800 to-amber-900",
  "bg-gradient-to-r from-emerald-800 to-amber-950",
  "bg-gradient-to-r from-emerald-800 to-yellow-300",
  "bg-gradient-to-r from-emerald-800 to-yellow-400",
  "bg-gradient-to-r from-emerald-800 to-yellow-500",
  "bg-gradient-to-r from-emerald-800 to-yellow-600",
  "bg-gradient-to-r from-emerald-800 to-yellow-700",
  "bg-gradient-to-r from-emerald-800 to-yellow-800",
  "bg-gradient-to-r from-emerald-800 to-yellow-900",
  "bg-gradient-to-r from-emerald-800 to-yellow-950",
  "bg-gradient-to-r from-emerald-800 to-lime-300",
  "bg-gradient-to-r from-emerald-800 to-lime-400",
  "bg-gradient-to-r from-emerald-800 to-lime-500",
  "bg-gradient-to-r from-emerald-800 to-lime-600",
  "bg-gradient-to-r from-emerald-800 to-lime-700",
  "bg-gradient-to-r from-emerald-800 to-lime-800",
  "bg-gradient-to-r from-emerald-800 to-lime-900",
  "bg-gradient-to-r from-emerald-800 to-lime-950",
  "bg-gradient-to-r from-emerald-800 to-green-300",
  "bg-gradient-to-r from-emerald-800 to-green-400",
  "bg-gradient-to-r from-emerald-800 to-green-500",
  "bg-gradient-to-r from-emerald-800 to-green-600",
  "bg-gradient-to-r from-emerald-800 to-green-700",
  "bg-gradient-to-r from-emerald-800 to-green-800",
  "bg-gradient-to-r from-emerald-800 to-green-900",
  "bg-gradient-to-r from-emerald-800 to-green-950",
  "bg-gradient-to-r from-emerald-800 to-teal-300",
  "bg-gradient-to-r from-emerald-800 to-teal-400",
  "bg-gradient-to-r from-emerald-800 to-teal-500",
  "bg-gradient-to-r from-emerald-800 to-teal-600",
  "bg-gradient-to-r from-emerald-800 to-teal-700",
  "bg-gradient-to-r from-emerald-800 to-teal-800",
  "bg-gradient-to-r from-emerald-800 to-teal-900",
  "bg-gradient-to-r from-emerald-800 to-teal-950",
  "bg-gradient-to-r from-emerald-800 to-cyan-300",
  "bg-gradient-to-r from-emerald-800 to-cyan-400",
  "bg-gradient-to-r from-emerald-800 to-cyan-500",
  "bg-gradient-to-r from-emerald-800 to-cyan-600",
  "bg-gradient-to-r from-emerald-800 to-cyan-700",
  "bg-gradient-to-r from-emerald-800 to-cyan-800",
  "bg-gradient-to-r from-emerald-800 to-cyan-900",
  "bg-gradient-to-r from-emerald-800 to-cyan-950",
  "bg-gradient-to-r from-emerald-800 to-sky-300",
  "bg-gradient-to-r from-emerald-800 to-sky-400",
  "bg-gradient-to-r from-emerald-800 to-sky-500",
  "bg-gradient-to-r from-emerald-800 to-sky-600",
  "bg-gradient-to-r from-emerald-800 to-sky-700",
  "bg-gradient-to-r from-emerald-800 to-sky-800",
  "bg-gradient-to-r from-emerald-800 to-sky-900",
  "bg-gradient-to-r from-emerald-800 to-sky-950",
  "bg-gradient-to-r from-emerald-800 to-blue-300",
  "bg-gradient-to-r from-emerald-800 to-blue-400",
  "bg-gradient-to-r from-emerald-800 to-blue-500",
  "bg-gradient-to-r from-emerald-800 to-blue-600",
  "bg-gradient-to-r from-emerald-800 to-blue-700",
  "bg-gradient-to-r from-emerald-800 to-blue-800",
  "bg-gradient-to-r from-emerald-800 to-blue-900",
  "bg-gradient-to-r from-emerald-800 to-blue-950",
  "bg-gradient-to-r from-emerald-800 to-indigo-300",
  "bg-gradient-to-r from-emerald-800 to-indigo-400",
  "bg-gradient-to-r from-emerald-800 to-indigo-500",
  "bg-gradient-to-r from-emerald-800 to-indigo-600",
  "bg-gradient-to-r from-emerald-800 to-indigo-700",
  "bg-gradient-to-r from-emerald-800 to-indigo-800",
  "bg-gradient-to-r from-emerald-800 to-indigo-900",
  "bg-gradient-to-r from-emerald-800 to-indigo-950",
  "bg-gradient-to-r from-emerald-800 to-violet-300",
  "bg-gradient-to-r from-emerald-800 to-violet-400",
  "bg-gradient-to-r from-emerald-800 to-violet-500",
  "bg-gradient-to-r from-emerald-800 to-violet-600",
  "bg-gradient-to-r from-emerald-800 to-violet-700",
  "bg-gradient-to-r from-emerald-800 to-violet-800",
  "bg-gradient-to-r from-emerald-800 to-violet-900",
  "bg-gradient-to-r from-emerald-800 to-violet-950",
  "bg-gradient-to-r from-emerald-800 to-purple-300",
  "bg-gradient-to-r from-emerald-800 to-purple-400",
  "bg-gradient-to-r from-emerald-800 to-purple-500",
  "bg-gradient-to-r from-emerald-800 to-purple-600",
  "bg-gradient-to-r from-emerald-800 to-purple-700",
  "bg-gradient-to-r from-emerald-800 to-purple-800",
  "bg-gradient-to-r from-emerald-800 to-purple-900",
  "bg-gradient-to-r from-emerald-800 to-purple-950",
  "bg-gradient-to-r from-emerald-800 to-fuchsia-300",
  "bg-gradient-to-r from-emerald-800 to-fuchsia-400",
  "bg-gradient-to-r from-emerald-800 to-fuchsia-500",
  "bg-gradient-to-r from-emerald-800 to-fuchsia-600",
  "bg-gradient-to-r from-emerald-800 to-fuchsia-700",
  "bg-gradient-to-r from-emerald-800 to-fuchsia-800",
  "bg-gradient-to-r from-emerald-800 to-fuchsia-900",
  "bg-gradient-to-r from-emerald-800 to-fuchsia-950",
  "bg-gradient-to-r from-emerald-800 to-pink-300",
  "bg-gradient-to-r from-emerald-800 to-pink-400",
  "bg-gradient-to-r from-emerald-800 to-pink-500",
  "bg-gradient-to-r from-emerald-800 to-pink-600",
  "bg-gradient-to-r from-emerald-800 to-pink-700",
  "bg-gradient-to-r from-emerald-800 to-pink-800",
  "bg-gradient-to-r from-emerald-800 to-pink-900",
  "bg-gradient-to-r from-emerald-800 to-pink-950",
  "bg-gradient-to-r from-emerald-800 to-rose-300",
  "bg-gradient-to-r from-emerald-800 to-rose-400",
  "bg-gradient-to-r from-emerald-800 to-rose-500",
  "bg-gradient-to-r from-emerald-800 to-rose-600",
  "bg-gradient-to-r from-emerald-800 to-rose-700",
  "bg-gradient-to-r from-emerald-800 to-rose-800",
  "bg-gradient-to-r from-emerald-800 to-rose-900",
  "bg-gradient-to-r from-emerald-800 to-rose-950",
  "bg-gradient-to-r from-emerald-900 to-red-300",
  "bg-gradient-to-r from-emerald-900 to-red-400",
  "bg-gradient-to-r from-emerald-900 to-red-500",
  "bg-gradient-to-r from-emerald-900 to-red-600",
  "bg-gradient-to-r from-emerald-900 to-red-700",
  "bg-gradient-to-r from-emerald-900 to-red-800",
  "bg-gradient-to-r from-emerald-900 to-red-900",
  "bg-gradient-to-r from-emerald-900 to-red-950",
  "bg-gradient-to-r from-emerald-900 to-orange-300",
  "bg-gradient-to-r from-emerald-900 to-orange-400",
  "bg-gradient-to-r from-emerald-900 to-orange-500",
  "bg-gradient-to-r from-emerald-900 to-orange-600",
  "bg-gradient-to-r from-emerald-900 to-orange-700",
  "bg-gradient-to-r from-emerald-900 to-orange-800",
  "bg-gradient-to-r from-emerald-900 to-orange-900",
  "bg-gradient-to-r from-emerald-900 to-orange-950",
  "bg-gradient-to-r from-emerald-900 to-amber-300",
  "bg-gradient-to-r from-emerald-900 to-amber-400",
  "bg-gradient-to-r from-emerald-900 to-amber-500",
  "bg-gradient-to-r from-emerald-900 to-amber-600",
  "bg-gradient-to-r from-emerald-900 to-amber-700",
  "bg-gradient-to-r from-emerald-900 to-amber-800",
  "bg-gradient-to-r from-emerald-900 to-amber-900",
  "bg-gradient-to-r from-emerald-900 to-amber-950",
  "bg-gradient-to-r from-emerald-900 to-yellow-300",
  "bg-gradient-to-r from-emerald-900 to-yellow-400",
  "bg-gradient-to-r from-emerald-900 to-yellow-500",
  "bg-gradient-to-r from-emerald-900 to-yellow-600",
  "bg-gradient-to-r from-emerald-900 to-yellow-700",
  "bg-gradient-to-r from-emerald-900 to-yellow-800",
  "bg-gradient-to-r from-emerald-900 to-yellow-900",
  "bg-gradient-to-r from-emerald-900 to-yellow-950",
  "bg-gradient-to-r from-emerald-900 to-lime-300",
  "bg-gradient-to-r from-emerald-900 to-lime-400",
  "bg-gradient-to-r from-emerald-900 to-lime-500",
  "bg-gradient-to-r from-emerald-900 to-lime-600",
  "bg-gradient-to-r from-emerald-900 to-lime-700",
  "bg-gradient-to-r from-emerald-900 to-lime-800",
  "bg-gradient-to-r from-emerald-900 to-lime-900",
  "bg-gradient-to-r from-emerald-900 to-lime-950",
  "bg-gradient-to-r from-emerald-900 to-green-300",
  "bg-gradient-to-r from-emerald-900 to-green-400",
  "bg-gradient-to-r from-emerald-900 to-green-500",
  "bg-gradient-to-r from-emerald-900 to-green-600",
  "bg-gradient-to-r from-emerald-900 to-green-700",
  "bg-gradient-to-r from-emerald-900 to-green-800",
  "bg-gradient-to-r from-emerald-900 to-green-900",
  "bg-gradient-to-r from-emerald-900 to-green-950",
  "bg-gradient-to-r from-emerald-900 to-teal-300",
  "bg-gradient-to-r from-emerald-900 to-teal-400",
  "bg-gradient-to-r from-emerald-900 to-teal-500",
  "bg-gradient-to-r from-emerald-900 to-teal-600",
  "bg-gradient-to-r from-emerald-900 to-teal-700",
  "bg-gradient-to-r from-emerald-900 to-teal-800",
  "bg-gradient-to-r from-emerald-900 to-teal-900",
  "bg-gradient-to-r from-emerald-900 to-teal-950",
  "bg-gradient-to-r from-emerald-900 to-cyan-300",
  "bg-gradient-to-r from-emerald-900 to-cyan-400",
  "bg-gradient-to-r from-emerald-900 to-cyan-500",
  "bg-gradient-to-r from-emerald-900 to-cyan-600",
  "bg-gradient-to-r from-emerald-900 to-cyan-700",
  "bg-gradient-to-r from-emerald-900 to-cyan-800",
  "bg-gradient-to-r from-emerald-900 to-cyan-900",
  "bg-gradient-to-r from-emerald-900 to-cyan-950",
  "bg-gradient-to-r from-emerald-900 to-sky-300",
  "bg-gradient-to-r from-emerald-900 to-sky-400",
  "bg-gradient-to-r from-emerald-900 to-sky-500",
  "bg-gradient-to-r from-emerald-900 to-sky-600",
  "bg-gradient-to-r from-emerald-900 to-sky-700",
  "bg-gradient-to-r from-emerald-900 to-sky-800",
  "bg-gradient-to-r from-emerald-900 to-sky-900",
  "bg-gradient-to-r from-emerald-900 to-sky-950",
  "bg-gradient-to-r from-emerald-900 to-blue-300",
  "bg-gradient-to-r from-emerald-900 to-blue-400",
  "bg-gradient-to-r from-emerald-900 to-blue-500",
  "bg-gradient-to-r from-emerald-900 to-blue-600",
  "bg-gradient-to-r from-emerald-900 to-blue-700",
  "bg-gradient-to-r from-emerald-900 to-blue-800",
  "bg-gradient-to-r from-emerald-900 to-blue-900",
  "bg-gradient-to-r from-emerald-900 to-blue-950",
  "bg-gradient-to-r from-emerald-900 to-indigo-300",
  "bg-gradient-to-r from-emerald-900 to-indigo-400",
  "bg-gradient-to-r from-emerald-900 to-indigo-500",
  "bg-gradient-to-r from-emerald-900 to-indigo-600",
  "bg-gradient-to-r from-emerald-900 to-indigo-700",
  "bg-gradient-to-r from-emerald-900 to-indigo-800",
  "bg-gradient-to-r from-emerald-900 to-indigo-900",
  "bg-gradient-to-r from-emerald-900 to-indigo-950",
  "bg-gradient-to-r from-emerald-900 to-violet-300",
  "bg-gradient-to-r from-emerald-900 to-violet-400",
  "bg-gradient-to-r from-emerald-900 to-violet-500",
  "bg-gradient-to-r from-emerald-900 to-violet-600",
  "bg-gradient-to-r from-emerald-900 to-violet-700",
  "bg-gradient-to-r from-emerald-900 to-violet-800",
  "bg-gradient-to-r from-emerald-900 to-violet-900",
  "bg-gradient-to-r from-emerald-900 to-violet-950",
  "bg-gradient-to-r from-emerald-900 to-purple-300",
  "bg-gradient-to-r from-emerald-900 to-purple-400",
  "bg-gradient-to-r from-emerald-900 to-purple-500",
  "bg-gradient-to-r from-emerald-900 to-purple-600",
  "bg-gradient-to-r from-emerald-900 to-purple-700",
  "bg-gradient-to-r from-emerald-900 to-purple-800",
  "bg-gradient-to-r from-emerald-900 to-purple-900",
  "bg-gradient-to-r from-emerald-900 to-purple-950",
  "bg-gradient-to-r from-emerald-900 to-fuchsia-300",
  "bg-gradient-to-r from-emerald-900 to-fuchsia-400",
  "bg-gradient-to-r from-emerald-900 to-fuchsia-500",
  "bg-gradient-to-r from-emerald-900 to-fuchsia-600",
  "bg-gradient-to-r from-emerald-900 to-fuchsia-700",
  "bg-gradient-to-r from-emerald-900 to-fuchsia-800",
  "bg-gradient-to-r from-emerald-900 to-fuchsia-900",
  "bg-gradient-to-r from-emerald-900 to-fuchsia-950",
  "bg-gradient-to-r from-emerald-900 to-pink-300",
  "bg-gradient-to-r from-emerald-900 to-pink-400",
  "bg-gradient-to-r from-emerald-900 to-pink-500",
  "bg-gradient-to-r from-emerald-900 to-pink-600",
  "bg-gradient-to-r from-emerald-900 to-pink-700",
  "bg-gradient-to-r from-emerald-900 to-pink-800",
  "bg-gradient-to-r from-emerald-900 to-pink-900",
  "bg-gradient-to-r from-emerald-900 to-pink-950",
  "bg-gradient-to-r from-emerald-900 to-rose-300",
  "bg-gradient-to-r from-emerald-900 to-rose-400",
  "bg-gradient-to-r from-emerald-900 to-rose-500",
  "bg-gradient-to-r from-emerald-900 to-rose-600",
  "bg-gradient-to-r from-emerald-900 to-rose-700",
  "bg-gradient-to-r from-emerald-900 to-rose-800",
  "bg-gradient-to-r from-emerald-900 to-rose-900",
  "bg-gradient-to-r from-emerald-900 to-rose-950",
  "bg-gradient-to-r from-emerald-950 to-red-300",
  "bg-gradient-to-r from-emerald-950 to-red-400",
  "bg-gradient-to-r from-emerald-950 to-red-500",
  "bg-gradient-to-r from-emerald-950 to-red-600",
  "bg-gradient-to-r from-emerald-950 to-red-700",
  "bg-gradient-to-r from-emerald-950 to-red-800",
  "bg-gradient-to-r from-emerald-950 to-red-900",
  "bg-gradient-to-r from-emerald-950 to-red-950",
  "bg-gradient-to-r from-emerald-950 to-orange-300",
  "bg-gradient-to-r from-emerald-950 to-orange-400",
  "bg-gradient-to-r from-emerald-950 to-orange-500",
  "bg-gradient-to-r from-emerald-950 to-orange-600",
  "bg-gradient-to-r from-emerald-950 to-orange-700",
  "bg-gradient-to-r from-emerald-950 to-orange-800",
  "bg-gradient-to-r from-emerald-950 to-orange-900",
  "bg-gradient-to-r from-emerald-950 to-orange-950",
  "bg-gradient-to-r from-emerald-950 to-amber-300",
  "bg-gradient-to-r from-emerald-950 to-amber-400",
  "bg-gradient-to-r from-emerald-950 to-amber-500",
  "bg-gradient-to-r from-emerald-950 to-amber-600",
  "bg-gradient-to-r from-emerald-950 to-amber-700",
  "bg-gradient-to-r from-emerald-950 to-amber-800",
  "bg-gradient-to-r from-emerald-950 to-amber-900",
  "bg-gradient-to-r from-emerald-950 to-amber-950",
  "bg-gradient-to-r from-emerald-950 to-yellow-300",
  "bg-gradient-to-r from-emerald-950 to-yellow-400",
  "bg-gradient-to-r from-emerald-950 to-yellow-500",
  "bg-gradient-to-r from-emerald-950 to-yellow-600",
  "bg-gradient-to-r from-emerald-950 to-yellow-700",
  "bg-gradient-to-r from-emerald-950 to-yellow-800",
  "bg-gradient-to-r from-emerald-950 to-yellow-900",
  "bg-gradient-to-r from-emerald-950 to-yellow-950",
  "bg-gradient-to-r from-emerald-950 to-lime-300",
  "bg-gradient-to-r from-emerald-950 to-lime-400",
  "bg-gradient-to-r from-emerald-950 to-lime-500",
  "bg-gradient-to-r from-emerald-950 to-lime-600",
  "bg-gradient-to-r from-emerald-950 to-lime-700",
  "bg-gradient-to-r from-emerald-950 to-lime-800",
  "bg-gradient-to-r from-emerald-950 to-lime-900",
  "bg-gradient-to-r from-emerald-950 to-lime-950",
  "bg-gradient-to-r from-emerald-950 to-green-300",
  "bg-gradient-to-r from-emerald-950 to-green-400",
  "bg-gradient-to-r from-emerald-950 to-green-500",
  "bg-gradient-to-r from-emerald-950 to-green-600",
  "bg-gradient-to-r from-emerald-950 to-green-700",
  "bg-gradient-to-r from-emerald-950 to-green-800",
  "bg-gradient-to-r from-emerald-950 to-green-900",
  "bg-gradient-to-r from-emerald-950 to-green-950",
  "bg-gradient-to-r from-emerald-950 to-teal-300",
  "bg-gradient-to-r from-emerald-950 to-teal-400",
  "bg-gradient-to-r from-emerald-950 to-teal-500",
  "bg-gradient-to-r from-emerald-950 to-teal-600",
  "bg-gradient-to-r from-emerald-950 to-teal-700",
  "bg-gradient-to-r from-emerald-950 to-teal-800",
  "bg-gradient-to-r from-emerald-950 to-teal-900",
  "bg-gradient-to-r from-emerald-950 to-teal-950",
  "bg-gradient-to-r from-emerald-950 to-cyan-300",
  "bg-gradient-to-r from-emerald-950 to-cyan-400",
  "bg-gradient-to-r from-emerald-950 to-cyan-500",
  "bg-gradient-to-r from-emerald-950 to-cyan-600",
  "bg-gradient-to-r from-emerald-950 to-cyan-700",
  "bg-gradient-to-r from-emerald-950 to-cyan-800",
  "bg-gradient-to-r from-emerald-950 to-cyan-900",
  "bg-gradient-to-r from-emerald-950 to-cyan-950",
  "bg-gradient-to-r from-emerald-950 to-sky-300",
  "bg-gradient-to-r from-emerald-950 to-sky-400",
  "bg-gradient-to-r from-emerald-950 to-sky-500",
  "bg-gradient-to-r from-emerald-950 to-sky-600",
  "bg-gradient-to-r from-emerald-950 to-sky-700",
  "bg-gradient-to-r from-emerald-950 to-sky-800",
  "bg-gradient-to-r from-emerald-950 to-sky-900",
  "bg-gradient-to-r from-emerald-950 to-sky-950",
  "bg-gradient-to-r from-emerald-950 to-blue-300",
  "bg-gradient-to-r from-emerald-950 to-blue-400",
  "bg-gradient-to-r from-emerald-950 to-blue-500",
  "bg-gradient-to-r from-emerald-950 to-blue-600",
  "bg-gradient-to-r from-emerald-950 to-blue-700",
  "bg-gradient-to-r from-emerald-950 to-blue-800",
  "bg-gradient-to-r from-emerald-950 to-blue-900",
  "bg-gradient-to-r from-emerald-950 to-blue-950",
  "bg-gradient-to-r from-emerald-950 to-indigo-300",
  "bg-gradient-to-r from-emerald-950 to-indigo-400",
  "bg-gradient-to-r from-emerald-950 to-indigo-500",
  "bg-gradient-to-r from-emerald-950 to-indigo-600",
  "bg-gradient-to-r from-emerald-950 to-indigo-700",
  "bg-gradient-to-r from-emerald-950 to-indigo-800",
  "bg-gradient-to-r from-emerald-950 to-indigo-900",
  "bg-gradient-to-r from-emerald-950 to-indigo-950",
  "bg-gradient-to-r from-emerald-950 to-violet-300",
  "bg-gradient-to-r from-emerald-950 to-violet-400",
  "bg-gradient-to-r from-emerald-950 to-violet-500",
  "bg-gradient-to-r from-emerald-950 to-violet-600",
  "bg-gradient-to-r from-emerald-950 to-violet-700",
  "bg-gradient-to-r from-emerald-950 to-violet-800",
  "bg-gradient-to-r from-emerald-950 to-violet-900",
  "bg-gradient-to-r from-emerald-950 to-violet-950",
  "bg-gradient-to-r from-emerald-950 to-purple-300",
  "bg-gradient-to-r from-emerald-950 to-purple-400",
  "bg-gradient-to-r from-emerald-950 to-purple-500",
  "bg-gradient-to-r from-emerald-950 to-purple-600",
  "bg-gradient-to-r from-emerald-950 to-purple-700",
  "bg-gradient-to-r from-emerald-950 to-purple-800",
  "bg-gradient-to-r from-emerald-950 to-purple-900",
  "bg-gradient-to-r from-emerald-950 to-purple-950",
  "bg-gradient-to-r from-emerald-950 to-fuchsia-300",
  "bg-gradient-to-r from-emerald-950 to-fuchsia-400",
  "bg-gradient-to-r from-emerald-950 to-fuchsia-500",
  "bg-gradient-to-r from-emerald-950 to-fuchsia-600",
  "bg-gradient-to-r from-emerald-950 to-fuchsia-700",
  "bg-gradient-to-r from-emerald-950 to-fuchsia-800",
  "bg-gradient-to-r from-emerald-950 to-fuchsia-900",
  "bg-gradient-to-r from-emerald-950 to-fuchsia-950",
  "bg-gradient-to-r from-emerald-950 to-pink-300",
  "bg-gradient-to-r from-emerald-950 to-pink-400",
  "bg-gradient-to-r from-emerald-950 to-pink-500",
  "bg-gradient-to-r from-emerald-950 to-pink-600",
  "bg-gradient-to-r from-emerald-950 to-pink-700",
  "bg-gradient-to-r from-emerald-950 to-pink-800",
  "bg-gradient-to-r from-emerald-950 to-pink-900",
  "bg-gradient-to-r from-emerald-950 to-pink-950",
  "bg-gradient-to-r from-emerald-950 to-rose-300",
  "bg-gradient-to-r from-emerald-950 to-rose-400",
  "bg-gradient-to-r from-emerald-950 to-rose-500",
  "bg-gradient-to-r from-emerald-950 to-rose-600",
  "bg-gradient-to-r from-emerald-950 to-rose-700",
  "bg-gradient-to-r from-emerald-950 to-rose-800",
  "bg-gradient-to-r from-emerald-950 to-rose-900",
  "bg-gradient-to-r from-emerald-950 to-rose-950",
  "bg-gradient-to-r from-teal-300 to-red-300",
  "bg-gradient-to-r from-teal-300 to-red-400",
  "bg-gradient-to-r from-teal-300 to-red-500",
  "bg-gradient-to-r from-teal-300 to-red-600",
  "bg-gradient-to-r from-teal-300 to-red-700",
  "bg-gradient-to-r from-teal-300 to-red-800",
  "bg-gradient-to-r from-teal-300 to-red-900",
  "bg-gradient-to-r from-teal-300 to-red-950",
  "bg-gradient-to-r from-teal-300 to-orange-300",
  "bg-gradient-to-r from-teal-300 to-orange-400",
  "bg-gradient-to-r from-teal-300 to-orange-500",
  "bg-gradient-to-r from-teal-300 to-orange-600",
  "bg-gradient-to-r from-teal-300 to-orange-700",
  "bg-gradient-to-r from-teal-300 to-orange-800",
  "bg-gradient-to-r from-teal-300 to-orange-900",
  "bg-gradient-to-r from-teal-300 to-orange-950",
  "bg-gradient-to-r from-teal-300 to-amber-300",
  "bg-gradient-to-r from-teal-300 to-amber-400",
  "bg-gradient-to-r from-teal-300 to-amber-500",
  "bg-gradient-to-r from-teal-300 to-amber-600",
  "bg-gradient-to-r from-teal-300 to-amber-700",
  "bg-gradient-to-r from-teal-300 to-amber-800",
  "bg-gradient-to-r from-teal-300 to-amber-900",
  "bg-gradient-to-r from-teal-300 to-amber-950",
  "bg-gradient-to-r from-teal-300 to-yellow-300",
  "bg-gradient-to-r from-teal-300 to-yellow-400",
  "bg-gradient-to-r from-teal-300 to-yellow-500",
  "bg-gradient-to-r from-teal-300 to-yellow-600",
  "bg-gradient-to-r from-teal-300 to-yellow-700",
  "bg-gradient-to-r from-teal-300 to-yellow-800",
  "bg-gradient-to-r from-teal-300 to-yellow-900",
  "bg-gradient-to-r from-teal-300 to-yellow-950",
  "bg-gradient-to-r from-teal-300 to-lime-300",
  "bg-gradient-to-r from-teal-300 to-lime-400",
  "bg-gradient-to-r from-teal-300 to-lime-500",
  "bg-gradient-to-r from-teal-300 to-lime-600",
  "bg-gradient-to-r from-teal-300 to-lime-700",
  "bg-gradient-to-r from-teal-300 to-lime-800",
  "bg-gradient-to-r from-teal-300 to-lime-900",
  "bg-gradient-to-r from-teal-300 to-lime-950",
  "bg-gradient-to-r from-teal-300 to-green-300",
  "bg-gradient-to-r from-teal-300 to-green-400",
  "bg-gradient-to-r from-teal-300 to-green-500",
  "bg-gradient-to-r from-teal-300 to-green-600",
  "bg-gradient-to-r from-teal-300 to-green-700",
  "bg-gradient-to-r from-teal-300 to-green-800",
  "bg-gradient-to-r from-teal-300 to-green-900",
  "bg-gradient-to-r from-teal-300 to-green-950",
  "bg-gradient-to-r from-teal-300 to-emerald-300",
  "bg-gradient-to-r from-teal-300 to-emerald-400",
  "bg-gradient-to-r from-teal-300 to-emerald-500",
  "bg-gradient-to-r from-teal-300 to-emerald-600",
  "bg-gradient-to-r from-teal-300 to-emerald-700",
  "bg-gradient-to-r from-teal-300 to-emerald-800",
  "bg-gradient-to-r from-teal-300 to-emerald-900",
  "bg-gradient-to-r from-teal-300 to-emerald-950",
  "bg-gradient-to-r from-teal-300 to-cyan-300",
  "bg-gradient-to-r from-teal-300 to-cyan-400",
  "bg-gradient-to-r from-teal-300 to-cyan-500",
  "bg-gradient-to-r from-teal-300 to-cyan-600",
  "bg-gradient-to-r from-teal-300 to-cyan-700",
  "bg-gradient-to-r from-teal-300 to-cyan-800",
  "bg-gradient-to-r from-teal-300 to-cyan-900",
  "bg-gradient-to-r from-teal-300 to-cyan-950",
  "bg-gradient-to-r from-teal-300 to-sky-300",
  "bg-gradient-to-r from-teal-300 to-sky-400",
  "bg-gradient-to-r from-teal-300 to-sky-500",
  "bg-gradient-to-r from-teal-300 to-sky-600",
  "bg-gradient-to-r from-teal-300 to-sky-700",
  "bg-gradient-to-r from-teal-300 to-sky-800",
  "bg-gradient-to-r from-teal-300 to-sky-900",
  "bg-gradient-to-r from-teal-300 to-sky-950",
  "bg-gradient-to-r from-teal-300 to-blue-300",
  "bg-gradient-to-r from-teal-300 to-blue-400",
  "bg-gradient-to-r from-teal-300 to-blue-500",
  "bg-gradient-to-r from-teal-300 to-blue-600",
  "bg-gradient-to-r from-teal-300 to-blue-700",
  "bg-gradient-to-r from-teal-300 to-blue-800",
  "bg-gradient-to-r from-teal-300 to-blue-900",
  "bg-gradient-to-r from-teal-300 to-blue-950",
  "bg-gradient-to-r from-teal-300 to-indigo-300",
  "bg-gradient-to-r from-teal-300 to-indigo-400",
  "bg-gradient-to-r from-teal-300 to-indigo-500",
  "bg-gradient-to-r from-teal-300 to-indigo-600",
  "bg-gradient-to-r from-teal-300 to-indigo-700",
  "bg-gradient-to-r from-teal-300 to-indigo-800",
  "bg-gradient-to-r from-teal-300 to-indigo-900",
  "bg-gradient-to-r from-teal-300 to-indigo-950",
  "bg-gradient-to-r from-teal-300 to-violet-300",
  "bg-gradient-to-r from-teal-300 to-violet-400",
  "bg-gradient-to-r from-teal-300 to-violet-500",
  "bg-gradient-to-r from-teal-300 to-violet-600",
  "bg-gradient-to-r from-teal-300 to-violet-700",
  "bg-gradient-to-r from-teal-300 to-violet-800",
  "bg-gradient-to-r from-teal-300 to-violet-900",
  "bg-gradient-to-r from-teal-300 to-violet-950",
  "bg-gradient-to-r from-teal-300 to-purple-300",
  "bg-gradient-to-r from-teal-300 to-purple-400",
  "bg-gradient-to-r from-teal-300 to-purple-500",
  "bg-gradient-to-r from-teal-300 to-purple-600",
  "bg-gradient-to-r from-teal-300 to-purple-700",
  "bg-gradient-to-r from-teal-300 to-purple-800",
  "bg-gradient-to-r from-teal-300 to-purple-900",
  "bg-gradient-to-r from-teal-300 to-purple-950",
  "bg-gradient-to-r from-teal-300 to-fuchsia-300",
  "bg-gradient-to-r from-teal-300 to-fuchsia-400",
  "bg-gradient-to-r from-teal-300 to-fuchsia-500",
  "bg-gradient-to-r from-teal-300 to-fuchsia-600",
  "bg-gradient-to-r from-teal-300 to-fuchsia-700",
  "bg-gradient-to-r from-teal-300 to-fuchsia-800",
  "bg-gradient-to-r from-teal-300 to-fuchsia-900",
  "bg-gradient-to-r from-teal-300 to-fuchsia-950",
  "bg-gradient-to-r from-teal-300 to-pink-300",
  "bg-gradient-to-r from-teal-300 to-pink-400",
  "bg-gradient-to-r from-teal-300 to-pink-500",
  "bg-gradient-to-r from-teal-300 to-pink-600",
  "bg-gradient-to-r from-teal-300 to-pink-700",
  "bg-gradient-to-r from-teal-300 to-pink-800",
  "bg-gradient-to-r from-teal-300 to-pink-900",
  "bg-gradient-to-r from-teal-300 to-pink-950",
  "bg-gradient-to-r from-teal-300 to-rose-300",
  "bg-gradient-to-r from-teal-300 to-rose-400",
  "bg-gradient-to-r from-teal-300 to-rose-500",
  "bg-gradient-to-r from-teal-300 to-rose-600",
  "bg-gradient-to-r from-teal-300 to-rose-700",
  "bg-gradient-to-r from-teal-300 to-rose-800",
  "bg-gradient-to-r from-teal-300 to-rose-900",
  "bg-gradient-to-r from-teal-300 to-rose-950",
  "bg-gradient-to-r from-teal-400 to-red-300",
  "bg-gradient-to-r from-teal-400 to-red-400",
  "bg-gradient-to-r from-teal-400 to-red-500",
  "bg-gradient-to-r from-teal-400 to-red-600",
  "bg-gradient-to-r from-teal-400 to-red-700",
  "bg-gradient-to-r from-teal-400 to-red-800",
  "bg-gradient-to-r from-teal-400 to-red-900",
  "bg-gradient-to-r from-teal-400 to-red-950",
  "bg-gradient-to-r from-teal-400 to-orange-300",
  "bg-gradient-to-r from-teal-400 to-orange-400",
  "bg-gradient-to-r from-teal-400 to-orange-500",
  "bg-gradient-to-r from-teal-400 to-orange-600",
  "bg-gradient-to-r from-teal-400 to-orange-700",
  "bg-gradient-to-r from-teal-400 to-orange-800",
  "bg-gradient-to-r from-teal-400 to-orange-900",
  "bg-gradient-to-r from-teal-400 to-orange-950",
  "bg-gradient-to-r from-teal-400 to-amber-300",
  "bg-gradient-to-r from-teal-400 to-amber-400",
  "bg-gradient-to-r from-teal-400 to-amber-500",
  "bg-gradient-to-r from-teal-400 to-amber-600",
  "bg-gradient-to-r from-teal-400 to-amber-700",
  "bg-gradient-to-r from-teal-400 to-amber-800",
  "bg-gradient-to-r from-teal-400 to-amber-900",
  "bg-gradient-to-r from-teal-400 to-amber-950",
  "bg-gradient-to-r from-teal-400 to-yellow-300",
  "bg-gradient-to-r from-teal-400 to-yellow-400",
  "bg-gradient-to-r from-teal-400 to-yellow-500",
  "bg-gradient-to-r from-teal-400 to-yellow-600",
  "bg-gradient-to-r from-teal-400 to-yellow-700",
  "bg-gradient-to-r from-teal-400 to-yellow-800",
  "bg-gradient-to-r from-teal-400 to-yellow-900",
  "bg-gradient-to-r from-teal-400 to-yellow-950",
  "bg-gradient-to-r from-teal-400 to-lime-300",
  "bg-gradient-to-r from-teal-400 to-lime-400",
  "bg-gradient-to-r from-teal-400 to-lime-500",
  "bg-gradient-to-r from-teal-400 to-lime-600",
  "bg-gradient-to-r from-teal-400 to-lime-700",
  "bg-gradient-to-r from-teal-400 to-lime-800",
  "bg-gradient-to-r from-teal-400 to-lime-900",
  "bg-gradient-to-r from-teal-400 to-lime-950",
  "bg-gradient-to-r from-teal-400 to-green-300",
  "bg-gradient-to-r from-teal-400 to-green-400",
  "bg-gradient-to-r from-teal-400 to-green-500",
  "bg-gradient-to-r from-teal-400 to-green-600",
  "bg-gradient-to-r from-teal-400 to-green-700",
  "bg-gradient-to-r from-teal-400 to-green-800",
  "bg-gradient-to-r from-teal-400 to-green-900",
  "bg-gradient-to-r from-teal-400 to-green-950",
  "bg-gradient-to-r from-teal-400 to-emerald-300",
  "bg-gradient-to-r from-teal-400 to-emerald-400",
  "bg-gradient-to-r from-teal-400 to-emerald-500",
  "bg-gradient-to-r from-teal-400 to-emerald-600",
  "bg-gradient-to-r from-teal-400 to-emerald-700",
  "bg-gradient-to-r from-teal-400 to-emerald-800",
  "bg-gradient-to-r from-teal-400 to-emerald-900",
  "bg-gradient-to-r from-teal-400 to-emerald-950",
  "bg-gradient-to-r from-teal-400 to-cyan-300",
  "bg-gradient-to-r from-teal-400 to-cyan-400",
  "bg-gradient-to-r from-teal-400 to-cyan-500",
  "bg-gradient-to-r from-teal-400 to-cyan-600",
  "bg-gradient-to-r from-teal-400 to-cyan-700",
  "bg-gradient-to-r from-teal-400 to-cyan-800",
  "bg-gradient-to-r from-teal-400 to-cyan-900",
  "bg-gradient-to-r from-teal-400 to-cyan-950",
  "bg-gradient-to-r from-teal-400 to-sky-300",
  "bg-gradient-to-r from-teal-400 to-sky-400",
  "bg-gradient-to-r from-teal-400 to-sky-500",
  "bg-gradient-to-r from-teal-400 to-sky-600",
  "bg-gradient-to-r from-teal-400 to-sky-700",
  "bg-gradient-to-r from-teal-400 to-sky-800",
  "bg-gradient-to-r from-teal-400 to-sky-900",
  "bg-gradient-to-r from-teal-400 to-sky-950",
  "bg-gradient-to-r from-teal-400 to-blue-300",
  "bg-gradient-to-r from-teal-400 to-blue-400",
  "bg-gradient-to-r from-teal-400 to-blue-500",
  "bg-gradient-to-r from-teal-400 to-blue-600",
  "bg-gradient-to-r from-teal-400 to-blue-700",
  "bg-gradient-to-r from-teal-400 to-blue-800",
  "bg-gradient-to-r from-teal-400 to-blue-900",
  "bg-gradient-to-r from-teal-400 to-blue-950",
  "bg-gradient-to-r from-teal-400 to-indigo-300",
  "bg-gradient-to-r from-teal-400 to-indigo-400",
  "bg-gradient-to-r from-teal-400 to-indigo-500",
  "bg-gradient-to-r from-teal-400 to-indigo-600",
  "bg-gradient-to-r from-teal-400 to-indigo-700",
  "bg-gradient-to-r from-teal-400 to-indigo-800",
  "bg-gradient-to-r from-teal-400 to-indigo-900",
  "bg-gradient-to-r from-teal-400 to-indigo-950",
  "bg-gradient-to-r from-teal-400 to-violet-300",
  "bg-gradient-to-r from-teal-400 to-violet-400",
  "bg-gradient-to-r from-teal-400 to-violet-500",
  "bg-gradient-to-r from-teal-400 to-violet-600",
  "bg-gradient-to-r from-teal-400 to-violet-700",
  "bg-gradient-to-r from-teal-400 to-violet-800",
  "bg-gradient-to-r from-teal-400 to-violet-900",
  "bg-gradient-to-r from-teal-400 to-violet-950",
  "bg-gradient-to-r from-teal-400 to-purple-300",
  "bg-gradient-to-r from-teal-400 to-purple-400",
  "bg-gradient-to-r from-teal-400 to-purple-500",
  "bg-gradient-to-r from-teal-400 to-purple-600",
  "bg-gradient-to-r from-teal-400 to-purple-700",
  "bg-gradient-to-r from-teal-400 to-purple-800",
  "bg-gradient-to-r from-teal-400 to-purple-900",
  "bg-gradient-to-r from-teal-400 to-purple-950",
  "bg-gradient-to-r from-teal-400 to-fuchsia-300",
  "bg-gradient-to-r from-teal-400 to-fuchsia-400",
  "bg-gradient-to-r from-teal-400 to-fuchsia-500",
  "bg-gradient-to-r from-teal-400 to-fuchsia-600",
  "bg-gradient-to-r from-teal-400 to-fuchsia-700",
  "bg-gradient-to-r from-teal-400 to-fuchsia-800",
  "bg-gradient-to-r from-teal-400 to-fuchsia-900",
  "bg-gradient-to-r from-teal-400 to-fuchsia-950",
  "bg-gradient-to-r from-teal-400 to-pink-300",
  "bg-gradient-to-r from-teal-400 to-pink-400",
  "bg-gradient-to-r from-teal-400 to-pink-500",
  "bg-gradient-to-r from-teal-400 to-pink-600",
  "bg-gradient-to-r from-teal-400 to-pink-700",
  "bg-gradient-to-r from-teal-400 to-pink-800",
  "bg-gradient-to-r from-teal-400 to-pink-900",
  "bg-gradient-to-r from-teal-400 to-pink-950",
  "bg-gradient-to-r from-teal-400 to-rose-300",
  "bg-gradient-to-r from-teal-400 to-rose-400",
  "bg-gradient-to-r from-teal-400 to-rose-500",
  "bg-gradient-to-r from-teal-400 to-rose-600",
  "bg-gradient-to-r from-teal-400 to-rose-700",
  "bg-gradient-to-r from-teal-400 to-rose-800",
  "bg-gradient-to-r from-teal-400 to-rose-900",
  "bg-gradient-to-r from-teal-400 to-rose-950",
  "bg-gradient-to-r from-teal-500 to-red-300",
  "bg-gradient-to-r from-teal-500 to-red-400",
  "bg-gradient-to-r from-teal-500 to-red-500",
  "bg-gradient-to-r from-teal-500 to-red-600",
  "bg-gradient-to-r from-teal-500 to-red-700",
  "bg-gradient-to-r from-teal-500 to-red-800",
  "bg-gradient-to-r from-teal-500 to-red-900",
  "bg-gradient-to-r from-teal-500 to-red-950",
  "bg-gradient-to-r from-teal-500 to-orange-300",
  "bg-gradient-to-r from-teal-500 to-orange-400",
  "bg-gradient-to-r from-teal-500 to-orange-500",
  "bg-gradient-to-r from-teal-500 to-orange-600",
  "bg-gradient-to-r from-teal-500 to-orange-700",
  "bg-gradient-to-r from-teal-500 to-orange-800",
  "bg-gradient-to-r from-teal-500 to-orange-900",
  "bg-gradient-to-r from-teal-500 to-orange-950",
  "bg-gradient-to-r from-teal-500 to-amber-300",
  "bg-gradient-to-r from-teal-500 to-amber-400",
  "bg-gradient-to-r from-teal-500 to-amber-500",
  "bg-gradient-to-r from-teal-500 to-amber-600",
  "bg-gradient-to-r from-teal-500 to-amber-700",
  "bg-gradient-to-r from-teal-500 to-amber-800",
  "bg-gradient-to-r from-teal-500 to-amber-900",
  "bg-gradient-to-r from-teal-500 to-amber-950",
  "bg-gradient-to-r from-teal-500 to-yellow-300",
  "bg-gradient-to-r from-teal-500 to-yellow-400",
  "bg-gradient-to-r from-teal-500 to-yellow-500",
  "bg-gradient-to-r from-teal-500 to-yellow-600",
  "bg-gradient-to-r from-teal-500 to-yellow-700",
  "bg-gradient-to-r from-teal-500 to-yellow-800",
  "bg-gradient-to-r from-teal-500 to-yellow-900",
  "bg-gradient-to-r from-teal-500 to-yellow-950",
  "bg-gradient-to-r from-teal-500 to-lime-300",
  "bg-gradient-to-r from-teal-500 to-lime-400",
  "bg-gradient-to-r from-teal-500 to-lime-500",
  "bg-gradient-to-r from-teal-500 to-lime-600",
  "bg-gradient-to-r from-teal-500 to-lime-700",
  "bg-gradient-to-r from-teal-500 to-lime-800",
  "bg-gradient-to-r from-teal-500 to-lime-900",
  "bg-gradient-to-r from-teal-500 to-lime-950",
  "bg-gradient-to-r from-teal-500 to-green-300",
  "bg-gradient-to-r from-teal-500 to-green-400",
  "bg-gradient-to-r from-teal-500 to-green-500",
  "bg-gradient-to-r from-teal-500 to-green-600",
  "bg-gradient-to-r from-teal-500 to-green-700",
  "bg-gradient-to-r from-teal-500 to-green-800",
  "bg-gradient-to-r from-teal-500 to-green-900",
  "bg-gradient-to-r from-teal-500 to-green-950",
  "bg-gradient-to-r from-teal-500 to-emerald-300",
  "bg-gradient-to-r from-teal-500 to-emerald-400",
  "bg-gradient-to-r from-teal-500 to-emerald-500",
  "bg-gradient-to-r from-teal-500 to-emerald-600",
  "bg-gradient-to-r from-teal-500 to-emerald-700",
  "bg-gradient-to-r from-teal-500 to-emerald-800",
  "bg-gradient-to-r from-teal-500 to-emerald-900",
  "bg-gradient-to-r from-teal-500 to-emerald-950",
  "bg-gradient-to-r from-teal-500 to-cyan-300",
  "bg-gradient-to-r from-teal-500 to-cyan-400",
  "bg-gradient-to-r from-teal-500 to-cyan-500",
  "bg-gradient-to-r from-teal-500 to-cyan-600",
  "bg-gradient-to-r from-teal-500 to-cyan-700",
  "bg-gradient-to-r from-teal-500 to-cyan-800",
  "bg-gradient-to-r from-teal-500 to-cyan-900",
  "bg-gradient-to-r from-teal-500 to-cyan-950",
  "bg-gradient-to-r from-teal-500 to-sky-300",
  "bg-gradient-to-r from-teal-500 to-sky-400",
  "bg-gradient-to-r from-teal-500 to-sky-500",
  "bg-gradient-to-r from-teal-500 to-sky-600",
  "bg-gradient-to-r from-teal-500 to-sky-700",
  "bg-gradient-to-r from-teal-500 to-sky-800",
  "bg-gradient-to-r from-teal-500 to-sky-900",
  "bg-gradient-to-r from-teal-500 to-sky-950",
  "bg-gradient-to-r from-teal-500 to-blue-300",
  "bg-gradient-to-r from-teal-500 to-blue-400",
  "bg-gradient-to-r from-teal-500 to-blue-500",
  "bg-gradient-to-r from-teal-500 to-blue-600",
  "bg-gradient-to-r from-teal-500 to-blue-700",
  "bg-gradient-to-r from-teal-500 to-blue-800",
  "bg-gradient-to-r from-teal-500 to-blue-900",
  "bg-gradient-to-r from-teal-500 to-blue-950",
  "bg-gradient-to-r from-teal-500 to-indigo-300",
  "bg-gradient-to-r from-teal-500 to-indigo-400",
  "bg-gradient-to-r from-teal-500 to-indigo-500",
  "bg-gradient-to-r from-teal-500 to-indigo-600",
  "bg-gradient-to-r from-teal-500 to-indigo-700",
  "bg-gradient-to-r from-teal-500 to-indigo-800",
  "bg-gradient-to-r from-teal-500 to-indigo-900",
  "bg-gradient-to-r from-teal-500 to-indigo-950",
  "bg-gradient-to-r from-teal-500 to-violet-300",
  "bg-gradient-to-r from-teal-500 to-violet-400",
  "bg-gradient-to-r from-teal-500 to-violet-500",
  "bg-gradient-to-r from-teal-500 to-violet-600",
  "bg-gradient-to-r from-teal-500 to-violet-700",
  "bg-gradient-to-r from-teal-500 to-violet-800",
  "bg-gradient-to-r from-teal-500 to-violet-900",
  "bg-gradient-to-r from-teal-500 to-violet-950",
  "bg-gradient-to-r from-teal-500 to-purple-300",
  "bg-gradient-to-r from-teal-500 to-purple-400",
  "bg-gradient-to-r from-teal-500 to-purple-500",
  "bg-gradient-to-r from-teal-500 to-purple-600",
  "bg-gradient-to-r from-teal-500 to-purple-700",
  "bg-gradient-to-r from-teal-500 to-purple-800",
  "bg-gradient-to-r from-teal-500 to-purple-900",
  "bg-gradient-to-r from-teal-500 to-purple-950",
  "bg-gradient-to-r from-teal-500 to-fuchsia-300",
  "bg-gradient-to-r from-teal-500 to-fuchsia-400",
  "bg-gradient-to-r from-teal-500 to-fuchsia-500",
  "bg-gradient-to-r from-teal-500 to-fuchsia-600",
  "bg-gradient-to-r from-teal-500 to-fuchsia-700",
  "bg-gradient-to-r from-teal-500 to-fuchsia-800",
  "bg-gradient-to-r from-teal-500 to-fuchsia-900",
  "bg-gradient-to-r from-teal-500 to-fuchsia-950",
  "bg-gradient-to-r from-teal-500 to-pink-300",
  "bg-gradient-to-r from-teal-500 to-pink-400",
  "bg-gradient-to-r from-teal-500 to-pink-500",
  "bg-gradient-to-r from-teal-500 to-pink-600",
  "bg-gradient-to-r from-teal-500 to-pink-700",
  "bg-gradient-to-r from-teal-500 to-pink-800",
  "bg-gradient-to-r from-teal-500 to-pink-900",
  "bg-gradient-to-r from-teal-500 to-pink-950",
  "bg-gradient-to-r from-teal-500 to-rose-300",
  "bg-gradient-to-r from-teal-500 to-rose-400",
  "bg-gradient-to-r from-teal-500 to-rose-500",
  "bg-gradient-to-r from-teal-500 to-rose-600",
  "bg-gradient-to-r from-teal-500 to-rose-700",
  "bg-gradient-to-r from-teal-500 to-rose-800",
  "bg-gradient-to-r from-teal-500 to-rose-900",
  "bg-gradient-to-r from-teal-500 to-rose-950",
  "bg-gradient-to-r from-teal-600 to-red-300",
  "bg-gradient-to-r from-teal-600 to-red-400",
  "bg-gradient-to-r from-teal-600 to-red-500",
  "bg-gradient-to-r from-teal-600 to-red-600",
  "bg-gradient-to-r from-teal-600 to-red-700",
  "bg-gradient-to-r from-teal-600 to-red-800",
  "bg-gradient-to-r from-teal-600 to-red-900",
  "bg-gradient-to-r from-teal-600 to-red-950",
  "bg-gradient-to-r from-teal-600 to-orange-300",
  "bg-gradient-to-r from-teal-600 to-orange-400",
  "bg-gradient-to-r from-teal-600 to-orange-500",
  "bg-gradient-to-r from-teal-600 to-orange-600",
  "bg-gradient-to-r from-teal-600 to-orange-700",
  "bg-gradient-to-r from-teal-600 to-orange-800",
  "bg-gradient-to-r from-teal-600 to-orange-900",
  "bg-gradient-to-r from-teal-600 to-orange-950",
  "bg-gradient-to-r from-teal-600 to-amber-300",
  "bg-gradient-to-r from-teal-600 to-amber-400",
  "bg-gradient-to-r from-teal-600 to-amber-500",
  "bg-gradient-to-r from-teal-600 to-amber-600",
  "bg-gradient-to-r from-teal-600 to-amber-700",
  "bg-gradient-to-r from-teal-600 to-amber-800",
  "bg-gradient-to-r from-teal-600 to-amber-900",
  "bg-gradient-to-r from-teal-600 to-amber-950",
  "bg-gradient-to-r from-teal-600 to-yellow-300",
  "bg-gradient-to-r from-teal-600 to-yellow-400",
  "bg-gradient-to-r from-teal-600 to-yellow-500",
  "bg-gradient-to-r from-teal-600 to-yellow-600",
  "bg-gradient-to-r from-teal-600 to-yellow-700",
  "bg-gradient-to-r from-teal-600 to-yellow-800",
  "bg-gradient-to-r from-teal-600 to-yellow-900",
  "bg-gradient-to-r from-teal-600 to-yellow-950",
  "bg-gradient-to-r from-teal-600 to-lime-300",
  "bg-gradient-to-r from-teal-600 to-lime-400",
  "bg-gradient-to-r from-teal-600 to-lime-500",
  "bg-gradient-to-r from-teal-600 to-lime-600",
  "bg-gradient-to-r from-teal-600 to-lime-700",
  "bg-gradient-to-r from-teal-600 to-lime-800",
  "bg-gradient-to-r from-teal-600 to-lime-900",
  "bg-gradient-to-r from-teal-600 to-lime-950",
  "bg-gradient-to-r from-teal-600 to-green-300",
  "bg-gradient-to-r from-teal-600 to-green-400",
  "bg-gradient-to-r from-teal-600 to-green-500",
  "bg-gradient-to-r from-teal-600 to-green-600",
  "bg-gradient-to-r from-teal-600 to-green-700",
  "bg-gradient-to-r from-teal-600 to-green-800",
  "bg-gradient-to-r from-teal-600 to-green-900",
  "bg-gradient-to-r from-teal-600 to-green-950",
  "bg-gradient-to-r from-teal-600 to-emerald-300",
  "bg-gradient-to-r from-teal-600 to-emerald-400",
  "bg-gradient-to-r from-teal-600 to-emerald-500",
  "bg-gradient-to-r from-teal-600 to-emerald-600",
  "bg-gradient-to-r from-teal-600 to-emerald-700",
  "bg-gradient-to-r from-teal-600 to-emerald-800",
  "bg-gradient-to-r from-teal-600 to-emerald-900",
  "bg-gradient-to-r from-teal-600 to-emerald-950",
  "bg-gradient-to-r from-teal-600 to-cyan-300",
  "bg-gradient-to-r from-teal-600 to-cyan-400",
  "bg-gradient-to-r from-teal-600 to-cyan-500",
  "bg-gradient-to-r from-teal-600 to-cyan-600",
  "bg-gradient-to-r from-teal-600 to-cyan-700",
  "bg-gradient-to-r from-teal-600 to-cyan-800",
  "bg-gradient-to-r from-teal-600 to-cyan-900",
  "bg-gradient-to-r from-teal-600 to-cyan-950",
  "bg-gradient-to-r from-teal-600 to-sky-300",
  "bg-gradient-to-r from-teal-600 to-sky-400",
  "bg-gradient-to-r from-teal-600 to-sky-500",
  "bg-gradient-to-r from-teal-600 to-sky-600",
  "bg-gradient-to-r from-teal-600 to-sky-700",
  "bg-gradient-to-r from-teal-600 to-sky-800",
  "bg-gradient-to-r from-teal-600 to-sky-900",
  "bg-gradient-to-r from-teal-600 to-sky-950",
  "bg-gradient-to-r from-teal-600 to-blue-300",
  "bg-gradient-to-r from-teal-600 to-blue-400",
  "bg-gradient-to-r from-teal-600 to-blue-500",
  "bg-gradient-to-r from-teal-600 to-blue-600",
  "bg-gradient-to-r from-teal-600 to-blue-700",
  "bg-gradient-to-r from-teal-600 to-blue-800",
  "bg-gradient-to-r from-teal-600 to-blue-900",
  "bg-gradient-to-r from-teal-600 to-blue-950",
  "bg-gradient-to-r from-teal-600 to-indigo-300",
  "bg-gradient-to-r from-teal-600 to-indigo-400",
  "bg-gradient-to-r from-teal-600 to-indigo-500",
  "bg-gradient-to-r from-teal-600 to-indigo-600",
  "bg-gradient-to-r from-teal-600 to-indigo-700",
  "bg-gradient-to-r from-teal-600 to-indigo-800",
  "bg-gradient-to-r from-teal-600 to-indigo-900",
  "bg-gradient-to-r from-teal-600 to-indigo-950",
  "bg-gradient-to-r from-teal-600 to-violet-300",
  "bg-gradient-to-r from-teal-600 to-violet-400",
  "bg-gradient-to-r from-teal-600 to-violet-500",
  "bg-gradient-to-r from-teal-600 to-violet-600",
  "bg-gradient-to-r from-teal-600 to-violet-700",
  "bg-gradient-to-r from-teal-600 to-violet-800",
  "bg-gradient-to-r from-teal-600 to-violet-900",
  "bg-gradient-to-r from-teal-600 to-violet-950",
  "bg-gradient-to-r from-teal-600 to-purple-300",
  "bg-gradient-to-r from-teal-600 to-purple-400",
  "bg-gradient-to-r from-teal-600 to-purple-500",
  "bg-gradient-to-r from-teal-600 to-purple-600",
  "bg-gradient-to-r from-teal-600 to-purple-700",
  "bg-gradient-to-r from-teal-600 to-purple-800",
  "bg-gradient-to-r from-teal-600 to-purple-900",
  "bg-gradient-to-r from-teal-600 to-purple-950",
  "bg-gradient-to-r from-teal-600 to-fuchsia-300",
  "bg-gradient-to-r from-teal-600 to-fuchsia-400",
  "bg-gradient-to-r from-teal-600 to-fuchsia-500",
  "bg-gradient-to-r from-teal-600 to-fuchsia-600",
  "bg-gradient-to-r from-teal-600 to-fuchsia-700",
  "bg-gradient-to-r from-teal-600 to-fuchsia-800",
  "bg-gradient-to-r from-teal-600 to-fuchsia-900",
  "bg-gradient-to-r from-teal-600 to-fuchsia-950",
  "bg-gradient-to-r from-teal-600 to-pink-300",
  "bg-gradient-to-r from-teal-600 to-pink-400",
  "bg-gradient-to-r from-teal-600 to-pink-500",
  "bg-gradient-to-r from-teal-600 to-pink-600",
  "bg-gradient-to-r from-teal-600 to-pink-700",
  "bg-gradient-to-r from-teal-600 to-pink-800",
  "bg-gradient-to-r from-teal-600 to-pink-900",
  "bg-gradient-to-r from-teal-600 to-pink-950",
  "bg-gradient-to-r from-teal-600 to-rose-300",
  "bg-gradient-to-r from-teal-600 to-rose-400",
  "bg-gradient-to-r from-teal-600 to-rose-500",
  "bg-gradient-to-r from-teal-600 to-rose-600",
  "bg-gradient-to-r from-teal-600 to-rose-700",
  "bg-gradient-to-r from-teal-600 to-rose-800",
  "bg-gradient-to-r from-teal-600 to-rose-900",
  "bg-gradient-to-r from-teal-600 to-rose-950",
  "bg-gradient-to-r from-teal-700 to-red-300",
  "bg-gradient-to-r from-teal-700 to-red-400",
  "bg-gradient-to-r from-teal-700 to-red-500",
  "bg-gradient-to-r from-teal-700 to-red-600",
  "bg-gradient-to-r from-teal-700 to-red-700",
  "bg-gradient-to-r from-teal-700 to-red-800",
  "bg-gradient-to-r from-teal-700 to-red-900",
  "bg-gradient-to-r from-teal-700 to-red-950",
  "bg-gradient-to-r from-teal-700 to-orange-300",
  "bg-gradient-to-r from-teal-700 to-orange-400",
  "bg-gradient-to-r from-teal-700 to-orange-500",
  "bg-gradient-to-r from-teal-700 to-orange-600",
  "bg-gradient-to-r from-teal-700 to-orange-700",
  "bg-gradient-to-r from-teal-700 to-orange-800",
  "bg-gradient-to-r from-teal-700 to-orange-900",
  "bg-gradient-to-r from-teal-700 to-orange-950",
  "bg-gradient-to-r from-teal-700 to-amber-300",
  "bg-gradient-to-r from-teal-700 to-amber-400",
  "bg-gradient-to-r from-teal-700 to-amber-500",
  "bg-gradient-to-r from-teal-700 to-amber-600",
  "bg-gradient-to-r from-teal-700 to-amber-700",
  "bg-gradient-to-r from-teal-700 to-amber-800",
  "bg-gradient-to-r from-teal-700 to-amber-900",
  "bg-gradient-to-r from-teal-700 to-amber-950",
  "bg-gradient-to-r from-teal-700 to-yellow-300",
  "bg-gradient-to-r from-teal-700 to-yellow-400",
  "bg-gradient-to-r from-teal-700 to-yellow-500",
  "bg-gradient-to-r from-teal-700 to-yellow-600",
  "bg-gradient-to-r from-teal-700 to-yellow-700",
  "bg-gradient-to-r from-teal-700 to-yellow-800",
  "bg-gradient-to-r from-teal-700 to-yellow-900",
  "bg-gradient-to-r from-teal-700 to-yellow-950",
  "bg-gradient-to-r from-teal-700 to-lime-300",
  "bg-gradient-to-r from-teal-700 to-lime-400",
  "bg-gradient-to-r from-teal-700 to-lime-500",
  "bg-gradient-to-r from-teal-700 to-lime-600",
  "bg-gradient-to-r from-teal-700 to-lime-700",
  "bg-gradient-to-r from-teal-700 to-lime-800",
  "bg-gradient-to-r from-teal-700 to-lime-900",
  "bg-gradient-to-r from-teal-700 to-lime-950",
  "bg-gradient-to-r from-teal-700 to-green-300",
  "bg-gradient-to-r from-teal-700 to-green-400",
  "bg-gradient-to-r from-teal-700 to-green-500",
  "bg-gradient-to-r from-teal-700 to-green-600",
  "bg-gradient-to-r from-teal-700 to-green-700",
  "bg-gradient-to-r from-teal-700 to-green-800",
  "bg-gradient-to-r from-teal-700 to-green-900",
  "bg-gradient-to-r from-teal-700 to-green-950",
  "bg-gradient-to-r from-teal-700 to-emerald-300",
  "bg-gradient-to-r from-teal-700 to-emerald-400",
  "bg-gradient-to-r from-teal-700 to-emerald-500",
  "bg-gradient-to-r from-teal-700 to-emerald-600",
  "bg-gradient-to-r from-teal-700 to-emerald-700",
  "bg-gradient-to-r from-teal-700 to-emerald-800",
  "bg-gradient-to-r from-teal-700 to-emerald-900",
  "bg-gradient-to-r from-teal-700 to-emerald-950",
  "bg-gradient-to-r from-teal-700 to-cyan-300",
  "bg-gradient-to-r from-teal-700 to-cyan-400",
  "bg-gradient-to-r from-teal-700 to-cyan-500",
  "bg-gradient-to-r from-teal-700 to-cyan-600",
  "bg-gradient-to-r from-teal-700 to-cyan-700",
  "bg-gradient-to-r from-teal-700 to-cyan-800",
  "bg-gradient-to-r from-teal-700 to-cyan-900",
  "bg-gradient-to-r from-teal-700 to-cyan-950",
  "bg-gradient-to-r from-teal-700 to-sky-300",
  "bg-gradient-to-r from-teal-700 to-sky-400",
  "bg-gradient-to-r from-teal-700 to-sky-500",
  "bg-gradient-to-r from-teal-700 to-sky-600",
  "bg-gradient-to-r from-teal-700 to-sky-700",
  "bg-gradient-to-r from-teal-700 to-sky-800",
  "bg-gradient-to-r from-teal-700 to-sky-900",
  "bg-gradient-to-r from-teal-700 to-sky-950",
  "bg-gradient-to-r from-teal-700 to-blue-300",
  "bg-gradient-to-r from-teal-700 to-blue-400",
  "bg-gradient-to-r from-teal-700 to-blue-500",
  "bg-gradient-to-r from-teal-700 to-blue-600",
  "bg-gradient-to-r from-teal-700 to-blue-700",
  "bg-gradient-to-r from-teal-700 to-blue-800",
  "bg-gradient-to-r from-teal-700 to-blue-900",
  "bg-gradient-to-r from-teal-700 to-blue-950",
  "bg-gradient-to-r from-teal-700 to-indigo-300",
  "bg-gradient-to-r from-teal-700 to-indigo-400",
  "bg-gradient-to-r from-teal-700 to-indigo-500",
  "bg-gradient-to-r from-teal-700 to-indigo-600",
  "bg-gradient-to-r from-teal-700 to-indigo-700",
  "bg-gradient-to-r from-teal-700 to-indigo-800",
  "bg-gradient-to-r from-teal-700 to-indigo-900",
  "bg-gradient-to-r from-teal-700 to-indigo-950",
  "bg-gradient-to-r from-teal-700 to-violet-300",
  "bg-gradient-to-r from-teal-700 to-violet-400",
  "bg-gradient-to-r from-teal-700 to-violet-500",
  "bg-gradient-to-r from-teal-700 to-violet-600",
  "bg-gradient-to-r from-teal-700 to-violet-700",
  "bg-gradient-to-r from-teal-700 to-violet-800",
  "bg-gradient-to-r from-teal-700 to-violet-900",
  "bg-gradient-to-r from-teal-700 to-violet-950",
  "bg-gradient-to-r from-teal-700 to-purple-300",
  "bg-gradient-to-r from-teal-700 to-purple-400",
  "bg-gradient-to-r from-teal-700 to-purple-500",
  "bg-gradient-to-r from-teal-700 to-purple-600",
  "bg-gradient-to-r from-teal-700 to-purple-700",
  "bg-gradient-to-r from-teal-700 to-purple-800",
  "bg-gradient-to-r from-teal-700 to-purple-900",
  "bg-gradient-to-r from-teal-700 to-purple-950",
  "bg-gradient-to-r from-teal-700 to-fuchsia-300",
  "bg-gradient-to-r from-teal-700 to-fuchsia-400",
  "bg-gradient-to-r from-teal-700 to-fuchsia-500",
  "bg-gradient-to-r from-teal-700 to-fuchsia-600",
  "bg-gradient-to-r from-teal-700 to-fuchsia-700",
  "bg-gradient-to-r from-teal-700 to-fuchsia-800",
  "bg-gradient-to-r from-teal-700 to-fuchsia-900",
  "bg-gradient-to-r from-teal-700 to-fuchsia-950",
  "bg-gradient-to-r from-teal-700 to-pink-300",
  "bg-gradient-to-r from-teal-700 to-pink-400",
  "bg-gradient-to-r from-teal-700 to-pink-500",
  "bg-gradient-to-r from-teal-700 to-pink-600",
  "bg-gradient-to-r from-teal-700 to-pink-700",
  "bg-gradient-to-r from-teal-700 to-pink-800",
  "bg-gradient-to-r from-teal-700 to-pink-900",
  "bg-gradient-to-r from-teal-700 to-pink-950",
  "bg-gradient-to-r from-teal-700 to-rose-300",
  "bg-gradient-to-r from-teal-700 to-rose-400",
  "bg-gradient-to-r from-teal-700 to-rose-500",
  "bg-gradient-to-r from-teal-700 to-rose-600",
  "bg-gradient-to-r from-teal-700 to-rose-700",
  "bg-gradient-to-r from-teal-700 to-rose-800",
  "bg-gradient-to-r from-teal-700 to-rose-900",
  "bg-gradient-to-r from-teal-700 to-rose-950",
  "bg-gradient-to-r from-teal-800 to-red-300",
  "bg-gradient-to-r from-teal-800 to-red-400",
  "bg-gradient-to-r from-teal-800 to-red-500",
  "bg-gradient-to-r from-teal-800 to-red-600",
  "bg-gradient-to-r from-teal-800 to-red-700",
  "bg-gradient-to-r from-teal-800 to-red-800",
  "bg-gradient-to-r from-teal-800 to-red-900",
  "bg-gradient-to-r from-teal-800 to-red-950",
  "bg-gradient-to-r from-teal-800 to-orange-300",
  "bg-gradient-to-r from-teal-800 to-orange-400",
  "bg-gradient-to-r from-teal-800 to-orange-500",
  "bg-gradient-to-r from-teal-800 to-orange-600",
  "bg-gradient-to-r from-teal-800 to-orange-700",
  "bg-gradient-to-r from-teal-800 to-orange-800",
  "bg-gradient-to-r from-teal-800 to-orange-900",
  "bg-gradient-to-r from-teal-800 to-orange-950",
  "bg-gradient-to-r from-teal-800 to-amber-300",
  "bg-gradient-to-r from-teal-800 to-amber-400",
  "bg-gradient-to-r from-teal-800 to-amber-500",
  "bg-gradient-to-r from-teal-800 to-amber-600",
  "bg-gradient-to-r from-teal-800 to-amber-700",
  "bg-gradient-to-r from-teal-800 to-amber-800",
  "bg-gradient-to-r from-teal-800 to-amber-900",
  "bg-gradient-to-r from-teal-800 to-amber-950",
  "bg-gradient-to-r from-teal-800 to-yellow-300",
  "bg-gradient-to-r from-teal-800 to-yellow-400",
  "bg-gradient-to-r from-teal-800 to-yellow-500",
  "bg-gradient-to-r from-teal-800 to-yellow-600",
  "bg-gradient-to-r from-teal-800 to-yellow-700",
  "bg-gradient-to-r from-teal-800 to-yellow-800",
  "bg-gradient-to-r from-teal-800 to-yellow-900",
  "bg-gradient-to-r from-teal-800 to-yellow-950",
  "bg-gradient-to-r from-teal-800 to-lime-300",
  "bg-gradient-to-r from-teal-800 to-lime-400",
  "bg-gradient-to-r from-teal-800 to-lime-500",
  "bg-gradient-to-r from-teal-800 to-lime-600",
  "bg-gradient-to-r from-teal-800 to-lime-700",
  "bg-gradient-to-r from-teal-800 to-lime-800",
  "bg-gradient-to-r from-teal-800 to-lime-900",
  "bg-gradient-to-r from-teal-800 to-lime-950",
  "bg-gradient-to-r from-teal-800 to-green-300",
  "bg-gradient-to-r from-teal-800 to-green-400",
  "bg-gradient-to-r from-teal-800 to-green-500",
  "bg-gradient-to-r from-teal-800 to-green-600",
  "bg-gradient-to-r from-teal-800 to-green-700",
  "bg-gradient-to-r from-teal-800 to-green-800",
  "bg-gradient-to-r from-teal-800 to-green-900",
  "bg-gradient-to-r from-teal-800 to-green-950",
  "bg-gradient-to-r from-teal-800 to-emerald-300",
  "bg-gradient-to-r from-teal-800 to-emerald-400",
  "bg-gradient-to-r from-teal-800 to-emerald-500",
  "bg-gradient-to-r from-teal-800 to-emerald-600",
  "bg-gradient-to-r from-teal-800 to-emerald-700",
  "bg-gradient-to-r from-teal-800 to-emerald-800",
  "bg-gradient-to-r from-teal-800 to-emerald-900",
  "bg-gradient-to-r from-teal-800 to-emerald-950",
  "bg-gradient-to-r from-teal-800 to-cyan-300",
  "bg-gradient-to-r from-teal-800 to-cyan-400",
  "bg-gradient-to-r from-teal-800 to-cyan-500",
  "bg-gradient-to-r from-teal-800 to-cyan-600",
  "bg-gradient-to-r from-teal-800 to-cyan-700",
  "bg-gradient-to-r from-teal-800 to-cyan-800",
  "bg-gradient-to-r from-teal-800 to-cyan-900",
  "bg-gradient-to-r from-teal-800 to-cyan-950",
  "bg-gradient-to-r from-teal-800 to-sky-300",
  "bg-gradient-to-r from-teal-800 to-sky-400",
  "bg-gradient-to-r from-teal-800 to-sky-500",
  "bg-gradient-to-r from-teal-800 to-sky-600",
  "bg-gradient-to-r from-teal-800 to-sky-700",
  "bg-gradient-to-r from-teal-800 to-sky-800",
  "bg-gradient-to-r from-teal-800 to-sky-900",
  "bg-gradient-to-r from-teal-800 to-sky-950",
  "bg-gradient-to-r from-teal-800 to-blue-300",
  "bg-gradient-to-r from-teal-800 to-blue-400",
  "bg-gradient-to-r from-teal-800 to-blue-500",
  "bg-gradient-to-r from-teal-800 to-blue-600",
  "bg-gradient-to-r from-teal-800 to-blue-700",
  "bg-gradient-to-r from-teal-800 to-blue-800",
  "bg-gradient-to-r from-teal-800 to-blue-900",
  "bg-gradient-to-r from-teal-800 to-blue-950",
  "bg-gradient-to-r from-teal-800 to-indigo-300",
  "bg-gradient-to-r from-teal-800 to-indigo-400",
  "bg-gradient-to-r from-teal-800 to-indigo-500",
  "bg-gradient-to-r from-teal-800 to-indigo-600",
  "bg-gradient-to-r from-teal-800 to-indigo-700",
  "bg-gradient-to-r from-teal-800 to-indigo-800",
  "bg-gradient-to-r from-teal-800 to-indigo-900",
  "bg-gradient-to-r from-teal-800 to-indigo-950",
  "bg-gradient-to-r from-teal-800 to-violet-300",
  "bg-gradient-to-r from-teal-800 to-violet-400",
  "bg-gradient-to-r from-teal-800 to-violet-500",
  "bg-gradient-to-r from-teal-800 to-violet-600",
  "bg-gradient-to-r from-teal-800 to-violet-700",
  "bg-gradient-to-r from-teal-800 to-violet-800",
  "bg-gradient-to-r from-teal-800 to-violet-900",
  "bg-gradient-to-r from-teal-800 to-violet-950",
  "bg-gradient-to-r from-teal-800 to-purple-300",
  "bg-gradient-to-r from-teal-800 to-purple-400",
  "bg-gradient-to-r from-teal-800 to-purple-500",
  "bg-gradient-to-r from-teal-800 to-purple-600",
  "bg-gradient-to-r from-teal-800 to-purple-700",
  "bg-gradient-to-r from-teal-800 to-purple-800",
  "bg-gradient-to-r from-teal-800 to-purple-900",
  "bg-gradient-to-r from-teal-800 to-purple-950",
  "bg-gradient-to-r from-teal-800 to-fuchsia-300",
  "bg-gradient-to-r from-teal-800 to-fuchsia-400",
  "bg-gradient-to-r from-teal-800 to-fuchsia-500",
  "bg-gradient-to-r from-teal-800 to-fuchsia-600",
  "bg-gradient-to-r from-teal-800 to-fuchsia-700",
  "bg-gradient-to-r from-teal-800 to-fuchsia-800",
  "bg-gradient-to-r from-teal-800 to-fuchsia-900",
  "bg-gradient-to-r from-teal-800 to-fuchsia-950",
  "bg-gradient-to-r from-teal-800 to-pink-300",
  "bg-gradient-to-r from-teal-800 to-pink-400",
  "bg-gradient-to-r from-teal-800 to-pink-500",
  "bg-gradient-to-r from-teal-800 to-pink-600",
  "bg-gradient-to-r from-teal-800 to-pink-700",
  "bg-gradient-to-r from-teal-800 to-pink-800",
  "bg-gradient-to-r from-teal-800 to-pink-900",
  "bg-gradient-to-r from-teal-800 to-pink-950",
  "bg-gradient-to-r from-teal-800 to-rose-300",
  "bg-gradient-to-r from-teal-800 to-rose-400",
  "bg-gradient-to-r from-teal-800 to-rose-500",
  "bg-gradient-to-r from-teal-800 to-rose-600",
  "bg-gradient-to-r from-teal-800 to-rose-700",
  "bg-gradient-to-r from-teal-800 to-rose-800",
  "bg-gradient-to-r from-teal-800 to-rose-900",
  "bg-gradient-to-r from-teal-800 to-rose-950",
  "bg-gradient-to-r from-teal-900 to-red-300",
  "bg-gradient-to-r from-teal-900 to-red-400",
  "bg-gradient-to-r from-teal-900 to-red-500",
  "bg-gradient-to-r from-teal-900 to-red-600",
  "bg-gradient-to-r from-teal-900 to-red-700",
  "bg-gradient-to-r from-teal-900 to-red-800",
  "bg-gradient-to-r from-teal-900 to-red-900",
  "bg-gradient-to-r from-teal-900 to-red-950",
  "bg-gradient-to-r from-teal-900 to-orange-300",
  "bg-gradient-to-r from-teal-900 to-orange-400",
  "bg-gradient-to-r from-teal-900 to-orange-500",
  "bg-gradient-to-r from-teal-900 to-orange-600",
  "bg-gradient-to-r from-teal-900 to-orange-700",
  "bg-gradient-to-r from-teal-900 to-orange-800",
  "bg-gradient-to-r from-teal-900 to-orange-900",
  "bg-gradient-to-r from-teal-900 to-orange-950",
  "bg-gradient-to-r from-teal-900 to-amber-300",
  "bg-gradient-to-r from-teal-900 to-amber-400",
  "bg-gradient-to-r from-teal-900 to-amber-500",
  "bg-gradient-to-r from-teal-900 to-amber-600",
  "bg-gradient-to-r from-teal-900 to-amber-700",
  "bg-gradient-to-r from-teal-900 to-amber-800",
  "bg-gradient-to-r from-teal-900 to-amber-900",
  "bg-gradient-to-r from-teal-900 to-amber-950",
  "bg-gradient-to-r from-teal-900 to-yellow-300",
  "bg-gradient-to-r from-teal-900 to-yellow-400",
  "bg-gradient-to-r from-teal-900 to-yellow-500",
  "bg-gradient-to-r from-teal-900 to-yellow-600",
  "bg-gradient-to-r from-teal-900 to-yellow-700",
  "bg-gradient-to-r from-teal-900 to-yellow-800",
  "bg-gradient-to-r from-teal-900 to-yellow-900",
  "bg-gradient-to-r from-teal-900 to-yellow-950",
  "bg-gradient-to-r from-teal-900 to-lime-300",
  "bg-gradient-to-r from-teal-900 to-lime-400",
  "bg-gradient-to-r from-teal-900 to-lime-500",
  "bg-gradient-to-r from-teal-900 to-lime-600",
  "bg-gradient-to-r from-teal-900 to-lime-700",
  "bg-gradient-to-r from-teal-900 to-lime-800",
  "bg-gradient-to-r from-teal-900 to-lime-900",
  "bg-gradient-to-r from-teal-900 to-lime-950",
  "bg-gradient-to-r from-teal-900 to-green-300",
  "bg-gradient-to-r from-teal-900 to-green-400",
  "bg-gradient-to-r from-teal-900 to-green-500",
  "bg-gradient-to-r from-teal-900 to-green-600",
  "bg-gradient-to-r from-teal-900 to-green-700",
  "bg-gradient-to-r from-teal-900 to-green-800",
  "bg-gradient-to-r from-teal-900 to-green-900",
  "bg-gradient-to-r from-teal-900 to-green-950",
  "bg-gradient-to-r from-teal-900 to-emerald-300",
  "bg-gradient-to-r from-teal-900 to-emerald-400",
  "bg-gradient-to-r from-teal-900 to-emerald-500",
  "bg-gradient-to-r from-teal-900 to-emerald-600",
  "bg-gradient-to-r from-teal-900 to-emerald-700",
  "bg-gradient-to-r from-teal-900 to-emerald-800",
  "bg-gradient-to-r from-teal-900 to-emerald-900",
  "bg-gradient-to-r from-teal-900 to-emerald-950",
  "bg-gradient-to-r from-teal-900 to-cyan-300",
  "bg-gradient-to-r from-teal-900 to-cyan-400",
  "bg-gradient-to-r from-teal-900 to-cyan-500",
  "bg-gradient-to-r from-teal-900 to-cyan-600",
  "bg-gradient-to-r from-teal-900 to-cyan-700",
  "bg-gradient-to-r from-teal-900 to-cyan-800",
  "bg-gradient-to-r from-teal-900 to-cyan-900",
  "bg-gradient-to-r from-teal-900 to-cyan-950",
  "bg-gradient-to-r from-teal-900 to-sky-300",
  "bg-gradient-to-r from-teal-900 to-sky-400",
  "bg-gradient-to-r from-teal-900 to-sky-500",
  "bg-gradient-to-r from-teal-900 to-sky-600",
  "bg-gradient-to-r from-teal-900 to-sky-700",
  "bg-gradient-to-r from-teal-900 to-sky-800",
  "bg-gradient-to-r from-teal-900 to-sky-900",
  "bg-gradient-to-r from-teal-900 to-sky-950",
  "bg-gradient-to-r from-teal-900 to-blue-300",
  "bg-gradient-to-r from-teal-900 to-blue-400",
  "bg-gradient-to-r from-teal-900 to-blue-500",
  "bg-gradient-to-r from-teal-900 to-blue-600",
  "bg-gradient-to-r from-teal-900 to-blue-700",
  "bg-gradient-to-r from-teal-900 to-blue-800",
  "bg-gradient-to-r from-teal-900 to-blue-900",
  "bg-gradient-to-r from-teal-900 to-blue-950",
  "bg-gradient-to-r from-teal-900 to-indigo-300",
  "bg-gradient-to-r from-teal-900 to-indigo-400",
  "bg-gradient-to-r from-teal-900 to-indigo-500",
  "bg-gradient-to-r from-teal-900 to-indigo-600",
  "bg-gradient-to-r from-teal-900 to-indigo-700",
  "bg-gradient-to-r from-teal-900 to-indigo-800",
  "bg-gradient-to-r from-teal-900 to-indigo-900",
  "bg-gradient-to-r from-teal-900 to-indigo-950",
  "bg-gradient-to-r from-teal-900 to-violet-300",
  "bg-gradient-to-r from-teal-900 to-violet-400",
  "bg-gradient-to-r from-teal-900 to-violet-500",
  "bg-gradient-to-r from-teal-900 to-violet-600",
  "bg-gradient-to-r from-teal-900 to-violet-700",
  "bg-gradient-to-r from-teal-900 to-violet-800",
  "bg-gradient-to-r from-teal-900 to-violet-900",
  "bg-gradient-to-r from-teal-900 to-violet-950",
  "bg-gradient-to-r from-teal-900 to-purple-300",
  "bg-gradient-to-r from-teal-900 to-purple-400",
  "bg-gradient-to-r from-teal-900 to-purple-500",
  "bg-gradient-to-r from-teal-900 to-purple-600",
  "bg-gradient-to-r from-teal-900 to-purple-700",
  "bg-gradient-to-r from-teal-900 to-purple-800",
  "bg-gradient-to-r from-teal-900 to-purple-900",
  "bg-gradient-to-r from-teal-900 to-purple-950",
  "bg-gradient-to-r from-teal-900 to-fuchsia-300",
  "bg-gradient-to-r from-teal-900 to-fuchsia-400",
  "bg-gradient-to-r from-teal-900 to-fuchsia-500",
  "bg-gradient-to-r from-teal-900 to-fuchsia-600",
  "bg-gradient-to-r from-teal-900 to-fuchsia-700",
  "bg-gradient-to-r from-teal-900 to-fuchsia-800",
  "bg-gradient-to-r from-teal-900 to-fuchsia-900",
  "bg-gradient-to-r from-teal-900 to-fuchsia-950",
  "bg-gradient-to-r from-teal-900 to-pink-300",
  "bg-gradient-to-r from-teal-900 to-pink-400",
  "bg-gradient-to-r from-teal-900 to-pink-500",
  "bg-gradient-to-r from-teal-900 to-pink-600",
  "bg-gradient-to-r from-teal-900 to-pink-700",
  "bg-gradient-to-r from-teal-900 to-pink-800",
  "bg-gradient-to-r from-teal-900 to-pink-900",
  "bg-gradient-to-r from-teal-900 to-pink-950",
  "bg-gradient-to-r from-teal-900 to-rose-300",
  "bg-gradient-to-r from-teal-900 to-rose-400",
  "bg-gradient-to-r from-teal-900 to-rose-500",
  "bg-gradient-to-r from-teal-900 to-rose-600",
  "bg-gradient-to-r from-teal-900 to-rose-700",
  "bg-gradient-to-r from-teal-900 to-rose-800",
  "bg-gradient-to-r from-teal-900 to-rose-900",
  "bg-gradient-to-r from-teal-900 to-rose-950",
  "bg-gradient-to-r from-teal-950 to-red-300",
  "bg-gradient-to-r from-teal-950 to-red-400",
  "bg-gradient-to-r from-teal-950 to-red-500",
  "bg-gradient-to-r from-teal-950 to-red-600",
  "bg-gradient-to-r from-teal-950 to-red-700",
  "bg-gradient-to-r from-teal-950 to-red-800",
  "bg-gradient-to-r from-teal-950 to-red-900",
  "bg-gradient-to-r from-teal-950 to-red-950",
  "bg-gradient-to-r from-teal-950 to-orange-300",
  "bg-gradient-to-r from-teal-950 to-orange-400",
  "bg-gradient-to-r from-teal-950 to-orange-500",
  "bg-gradient-to-r from-teal-950 to-orange-600",
  "bg-gradient-to-r from-teal-950 to-orange-700",
  "bg-gradient-to-r from-teal-950 to-orange-800",
  "bg-gradient-to-r from-teal-950 to-orange-900",
  "bg-gradient-to-r from-teal-950 to-orange-950",
  "bg-gradient-to-r from-teal-950 to-amber-300",
  "bg-gradient-to-r from-teal-950 to-amber-400",
  "bg-gradient-to-r from-teal-950 to-amber-500",
  "bg-gradient-to-r from-teal-950 to-amber-600",
  "bg-gradient-to-r from-teal-950 to-amber-700",
  "bg-gradient-to-r from-teal-950 to-amber-800",
  "bg-gradient-to-r from-teal-950 to-amber-900",
  "bg-gradient-to-r from-teal-950 to-amber-950",
  "bg-gradient-to-r from-teal-950 to-yellow-300",
  "bg-gradient-to-r from-teal-950 to-yellow-400",
  "bg-gradient-to-r from-teal-950 to-yellow-500",
  "bg-gradient-to-r from-teal-950 to-yellow-600",
  "bg-gradient-to-r from-teal-950 to-yellow-700",
  "bg-gradient-to-r from-teal-950 to-yellow-800",
  "bg-gradient-to-r from-teal-950 to-yellow-900",
  "bg-gradient-to-r from-teal-950 to-yellow-950",
  "bg-gradient-to-r from-teal-950 to-lime-300",
  "bg-gradient-to-r from-teal-950 to-lime-400",
  "bg-gradient-to-r from-teal-950 to-lime-500",
  "bg-gradient-to-r from-teal-950 to-lime-600",
  "bg-gradient-to-r from-teal-950 to-lime-700",
  "bg-gradient-to-r from-teal-950 to-lime-800",
  "bg-gradient-to-r from-teal-950 to-lime-900",
  "bg-gradient-to-r from-teal-950 to-lime-950",
  "bg-gradient-to-r from-teal-950 to-green-300",
  "bg-gradient-to-r from-teal-950 to-green-400",
  "bg-gradient-to-r from-teal-950 to-green-500",
  "bg-gradient-to-r from-teal-950 to-green-600",
  "bg-gradient-to-r from-teal-950 to-green-700",
  "bg-gradient-to-r from-teal-950 to-green-800",
  "bg-gradient-to-r from-teal-950 to-green-900",
  "bg-gradient-to-r from-teal-950 to-green-950",
  "bg-gradient-to-r from-teal-950 to-emerald-300",
  "bg-gradient-to-r from-teal-950 to-emerald-400",
  "bg-gradient-to-r from-teal-950 to-emerald-500",
  "bg-gradient-to-r from-teal-950 to-emerald-600",
  "bg-gradient-to-r from-teal-950 to-emerald-700",
  "bg-gradient-to-r from-teal-950 to-emerald-800",
  "bg-gradient-to-r from-teal-950 to-emerald-900",
  "bg-gradient-to-r from-teal-950 to-emerald-950",
  "bg-gradient-to-r from-teal-950 to-cyan-300",
  "bg-gradient-to-r from-teal-950 to-cyan-400",
  "bg-gradient-to-r from-teal-950 to-cyan-500",
  "bg-gradient-to-r from-teal-950 to-cyan-600",
  "bg-gradient-to-r from-teal-950 to-cyan-700",
  "bg-gradient-to-r from-teal-950 to-cyan-800",
  "bg-gradient-to-r from-teal-950 to-cyan-900",
  "bg-gradient-to-r from-teal-950 to-cyan-950",
  "bg-gradient-to-r from-teal-950 to-sky-300",
  "bg-gradient-to-r from-teal-950 to-sky-400",
  "bg-gradient-to-r from-teal-950 to-sky-500",
  "bg-gradient-to-r from-teal-950 to-sky-600",
  "bg-gradient-to-r from-teal-950 to-sky-700",
  "bg-gradient-to-r from-teal-950 to-sky-800",
  "bg-gradient-to-r from-teal-950 to-sky-900",
  "bg-gradient-to-r from-teal-950 to-sky-950",
  "bg-gradient-to-r from-teal-950 to-blue-300",
  "bg-gradient-to-r from-teal-950 to-blue-400",
  "bg-gradient-to-r from-teal-950 to-blue-500",
  "bg-gradient-to-r from-teal-950 to-blue-600",
  "bg-gradient-to-r from-teal-950 to-blue-700",
  "bg-gradient-to-r from-teal-950 to-blue-800",
  "bg-gradient-to-r from-teal-950 to-blue-900",
  "bg-gradient-to-r from-teal-950 to-blue-950",
  "bg-gradient-to-r from-teal-950 to-indigo-300",
  "bg-gradient-to-r from-teal-950 to-indigo-400",
  "bg-gradient-to-r from-teal-950 to-indigo-500",
  "bg-gradient-to-r from-teal-950 to-indigo-600",
  "bg-gradient-to-r from-teal-950 to-indigo-700",
  "bg-gradient-to-r from-teal-950 to-indigo-800",
  "bg-gradient-to-r from-teal-950 to-indigo-900",
  "bg-gradient-to-r from-teal-950 to-indigo-950",
  "bg-gradient-to-r from-teal-950 to-violet-300",
  "bg-gradient-to-r from-teal-950 to-violet-400",
  "bg-gradient-to-r from-teal-950 to-violet-500",
  "bg-gradient-to-r from-teal-950 to-violet-600",
  "bg-gradient-to-r from-teal-950 to-violet-700",
  "bg-gradient-to-r from-teal-950 to-violet-800",
  "bg-gradient-to-r from-teal-950 to-violet-900",
  "bg-gradient-to-r from-teal-950 to-violet-950",
  "bg-gradient-to-r from-teal-950 to-purple-300",
  "bg-gradient-to-r from-teal-950 to-purple-400",
  "bg-gradient-to-r from-teal-950 to-purple-500",
  "bg-gradient-to-r from-teal-950 to-purple-600",
  "bg-gradient-to-r from-teal-950 to-purple-700",
  "bg-gradient-to-r from-teal-950 to-purple-800",
  "bg-gradient-to-r from-teal-950 to-purple-900",
  "bg-gradient-to-r from-teal-950 to-purple-950",
  "bg-gradient-to-r from-teal-950 to-fuchsia-300",
  "bg-gradient-to-r from-teal-950 to-fuchsia-400",
  "bg-gradient-to-r from-teal-950 to-fuchsia-500",
  "bg-gradient-to-r from-teal-950 to-fuchsia-600",
  "bg-gradient-to-r from-teal-950 to-fuchsia-700",
  "bg-gradient-to-r from-teal-950 to-fuchsia-800",
  "bg-gradient-to-r from-teal-950 to-fuchsia-900",
  "bg-gradient-to-r from-teal-950 to-fuchsia-950",
  "bg-gradient-to-r from-teal-950 to-pink-300",
  "bg-gradient-to-r from-teal-950 to-pink-400",
  "bg-gradient-to-r from-teal-950 to-pink-500",
  "bg-gradient-to-r from-teal-950 to-pink-600",
  "bg-gradient-to-r from-teal-950 to-pink-700",
  "bg-gradient-to-r from-teal-950 to-pink-800",
  "bg-gradient-to-r from-teal-950 to-pink-900",
  "bg-gradient-to-r from-teal-950 to-pink-950",
  "bg-gradient-to-r from-teal-950 to-rose-300",
  "bg-gradient-to-r from-teal-950 to-rose-400",
  "bg-gradient-to-r from-teal-950 to-rose-500",
  "bg-gradient-to-r from-teal-950 to-rose-600",
  "bg-gradient-to-r from-teal-950 to-rose-700",
  "bg-gradient-to-r from-teal-950 to-rose-800",
  "bg-gradient-to-r from-teal-950 to-rose-900",
  "bg-gradient-to-r from-teal-950 to-rose-950",
  "bg-gradient-to-r from-cyan-300 to-red-300",
  "bg-gradient-to-r from-cyan-300 to-red-400",
  "bg-gradient-to-r from-cyan-300 to-red-500",
  "bg-gradient-to-r from-cyan-300 to-red-600",
  "bg-gradient-to-r from-cyan-300 to-red-700",
  "bg-gradient-to-r from-cyan-300 to-red-800",
  "bg-gradient-to-r from-cyan-300 to-red-900",
  "bg-gradient-to-r from-cyan-300 to-red-950",
  "bg-gradient-to-r from-cyan-300 to-orange-300",
  "bg-gradient-to-r from-cyan-300 to-orange-400",
  "bg-gradient-to-r from-cyan-300 to-orange-500",
  "bg-gradient-to-r from-cyan-300 to-orange-600",
  "bg-gradient-to-r from-cyan-300 to-orange-700",
  "bg-gradient-to-r from-cyan-300 to-orange-800",
  "bg-gradient-to-r from-cyan-300 to-orange-900",
  "bg-gradient-to-r from-cyan-300 to-orange-950",
  "bg-gradient-to-r from-cyan-300 to-amber-300",
  "bg-gradient-to-r from-cyan-300 to-amber-400",
  "bg-gradient-to-r from-cyan-300 to-amber-500",
  "bg-gradient-to-r from-cyan-300 to-amber-600",
  "bg-gradient-to-r from-cyan-300 to-amber-700",
  "bg-gradient-to-r from-cyan-300 to-amber-800",
  "bg-gradient-to-r from-cyan-300 to-amber-900",
  "bg-gradient-to-r from-cyan-300 to-amber-950",
  "bg-gradient-to-r from-cyan-300 to-yellow-300",
  "bg-gradient-to-r from-cyan-300 to-yellow-400",
  "bg-gradient-to-r from-cyan-300 to-yellow-500",
  "bg-gradient-to-r from-cyan-300 to-yellow-600",
  "bg-gradient-to-r from-cyan-300 to-yellow-700",
  "bg-gradient-to-r from-cyan-300 to-yellow-800",
  "bg-gradient-to-r from-cyan-300 to-yellow-900",
  "bg-gradient-to-r from-cyan-300 to-yellow-950",
  "bg-gradient-to-r from-cyan-300 to-lime-300",
  "bg-gradient-to-r from-cyan-300 to-lime-400",
  "bg-gradient-to-r from-cyan-300 to-lime-500",
  "bg-gradient-to-r from-cyan-300 to-lime-600",
  "bg-gradient-to-r from-cyan-300 to-lime-700",
  "bg-gradient-to-r from-cyan-300 to-lime-800",
  "bg-gradient-to-r from-cyan-300 to-lime-900",
  "bg-gradient-to-r from-cyan-300 to-lime-950",
  "bg-gradient-to-r from-cyan-300 to-green-300",
  "bg-gradient-to-r from-cyan-300 to-green-400",
  "bg-gradient-to-r from-cyan-300 to-green-500",
  "bg-gradient-to-r from-cyan-300 to-green-600",
  "bg-gradient-to-r from-cyan-300 to-green-700",
  "bg-gradient-to-r from-cyan-300 to-green-800",
  "bg-gradient-to-r from-cyan-300 to-green-900",
  "bg-gradient-to-r from-cyan-300 to-green-950",
  "bg-gradient-to-r from-cyan-300 to-emerald-300",
  "bg-gradient-to-r from-cyan-300 to-emerald-400",
  "bg-gradient-to-r from-cyan-300 to-emerald-500",
  "bg-gradient-to-r from-cyan-300 to-emerald-600",
  "bg-gradient-to-r from-cyan-300 to-emerald-700",
  "bg-gradient-to-r from-cyan-300 to-emerald-800",
  "bg-gradient-to-r from-cyan-300 to-emerald-900",
  "bg-gradient-to-r from-cyan-300 to-emerald-950",
  "bg-gradient-to-r from-cyan-300 to-teal-300",
  "bg-gradient-to-r from-cyan-300 to-teal-400",
  "bg-gradient-to-r from-cyan-300 to-teal-500",
  "bg-gradient-to-r from-cyan-300 to-teal-600",
  "bg-gradient-to-r from-cyan-300 to-teal-700",
  "bg-gradient-to-r from-cyan-300 to-teal-800",
  "bg-gradient-to-r from-cyan-300 to-teal-900",
  "bg-gradient-to-r from-cyan-300 to-teal-950",
  "bg-gradient-to-r from-cyan-300 to-sky-300",
  "bg-gradient-to-r from-cyan-300 to-sky-400",
  "bg-gradient-to-r from-cyan-300 to-sky-500",
  "bg-gradient-to-r from-cyan-300 to-sky-600",
  "bg-gradient-to-r from-cyan-300 to-sky-700",
  "bg-gradient-to-r from-cyan-300 to-sky-800",
  "bg-gradient-to-r from-cyan-300 to-sky-900",
  "bg-gradient-to-r from-cyan-300 to-sky-950",
  "bg-gradient-to-r from-cyan-300 to-blue-300",
  "bg-gradient-to-r from-cyan-300 to-blue-400",
  "bg-gradient-to-r from-cyan-300 to-blue-500",
  "bg-gradient-to-r from-cyan-300 to-blue-600",
  "bg-gradient-to-r from-cyan-300 to-blue-700",
  "bg-gradient-to-r from-cyan-300 to-blue-800",
  "bg-gradient-to-r from-cyan-300 to-blue-900",
  "bg-gradient-to-r from-cyan-300 to-blue-950",
  "bg-gradient-to-r from-cyan-300 to-indigo-300",
  "bg-gradient-to-r from-cyan-300 to-indigo-400",
  "bg-gradient-to-r from-cyan-300 to-indigo-500",
  "bg-gradient-to-r from-cyan-300 to-indigo-600",
  "bg-gradient-to-r from-cyan-300 to-indigo-700",
  "bg-gradient-to-r from-cyan-300 to-indigo-800",
  "bg-gradient-to-r from-cyan-300 to-indigo-900",
  "bg-gradient-to-r from-cyan-300 to-indigo-950",
  "bg-gradient-to-r from-cyan-300 to-violet-300",
  "bg-gradient-to-r from-cyan-300 to-violet-400",
  "bg-gradient-to-r from-cyan-300 to-violet-500",
  "bg-gradient-to-r from-cyan-300 to-violet-600",
  "bg-gradient-to-r from-cyan-300 to-violet-700",
  "bg-gradient-to-r from-cyan-300 to-violet-800",
  "bg-gradient-to-r from-cyan-300 to-violet-900",
  "bg-gradient-to-r from-cyan-300 to-violet-950",
  "bg-gradient-to-r from-cyan-300 to-purple-300",
  "bg-gradient-to-r from-cyan-300 to-purple-400",
  "bg-gradient-to-r from-cyan-300 to-purple-500",
  "bg-gradient-to-r from-cyan-300 to-purple-600",
  "bg-gradient-to-r from-cyan-300 to-purple-700",
  "bg-gradient-to-r from-cyan-300 to-purple-800",
  "bg-gradient-to-r from-cyan-300 to-purple-900",
  "bg-gradient-to-r from-cyan-300 to-purple-950",
  "bg-gradient-to-r from-cyan-300 to-fuchsia-300",
  "bg-gradient-to-r from-cyan-300 to-fuchsia-400",
  "bg-gradient-to-r from-cyan-300 to-fuchsia-500",
  "bg-gradient-to-r from-cyan-300 to-fuchsia-600",
  "bg-gradient-to-r from-cyan-300 to-fuchsia-700",
  "bg-gradient-to-r from-cyan-300 to-fuchsia-800",
  "bg-gradient-to-r from-cyan-300 to-fuchsia-900",
  "bg-gradient-to-r from-cyan-300 to-fuchsia-950",
  "bg-gradient-to-r from-cyan-300 to-pink-300",
  "bg-gradient-to-r from-cyan-300 to-pink-400",
  "bg-gradient-to-r from-cyan-300 to-pink-500",
  "bg-gradient-to-r from-cyan-300 to-pink-600",
  "bg-gradient-to-r from-cyan-300 to-pink-700",
  "bg-gradient-to-r from-cyan-300 to-pink-800",
  "bg-gradient-to-r from-cyan-300 to-pink-900",
  "bg-gradient-to-r from-cyan-300 to-pink-950",
  "bg-gradient-to-r from-cyan-300 to-rose-300",
  "bg-gradient-to-r from-cyan-300 to-rose-400",
  "bg-gradient-to-r from-cyan-300 to-rose-500",
  "bg-gradient-to-r from-cyan-300 to-rose-600",
  "bg-gradient-to-r from-cyan-300 to-rose-700",
  "bg-gradient-to-r from-cyan-300 to-rose-800",
  "bg-gradient-to-r from-cyan-300 to-rose-900",
  "bg-gradient-to-r from-cyan-300 to-rose-950",
  "bg-gradient-to-r from-cyan-400 to-red-300",
  "bg-gradient-to-r from-cyan-400 to-red-400",
  "bg-gradient-to-r from-cyan-400 to-red-500",
  "bg-gradient-to-r from-cyan-400 to-red-600",
  "bg-gradient-to-r from-cyan-400 to-red-700",
  "bg-gradient-to-r from-cyan-400 to-red-800",
  "bg-gradient-to-r from-cyan-400 to-red-900",
  "bg-gradient-to-r from-cyan-400 to-red-950",
  "bg-gradient-to-r from-cyan-400 to-orange-300",
  "bg-gradient-to-r from-cyan-400 to-orange-400",
  "bg-gradient-to-r from-cyan-400 to-orange-500",
  "bg-gradient-to-r from-cyan-400 to-orange-600",
  "bg-gradient-to-r from-cyan-400 to-orange-700",
  "bg-gradient-to-r from-cyan-400 to-orange-800",
  "bg-gradient-to-r from-cyan-400 to-orange-900",
  "bg-gradient-to-r from-cyan-400 to-orange-950",
  "bg-gradient-to-r from-cyan-400 to-amber-300",
  "bg-gradient-to-r from-cyan-400 to-amber-400",
  "bg-gradient-to-r from-cyan-400 to-amber-500",
  "bg-gradient-to-r from-cyan-400 to-amber-600",
  "bg-gradient-to-r from-cyan-400 to-amber-700",
  "bg-gradient-to-r from-cyan-400 to-amber-800",
  "bg-gradient-to-r from-cyan-400 to-amber-900",
  "bg-gradient-to-r from-cyan-400 to-amber-950",
  "bg-gradient-to-r from-cyan-400 to-yellow-300",
  "bg-gradient-to-r from-cyan-400 to-yellow-400",
  "bg-gradient-to-r from-cyan-400 to-yellow-500",
  "bg-gradient-to-r from-cyan-400 to-yellow-600",
  "bg-gradient-to-r from-cyan-400 to-yellow-700",
  "bg-gradient-to-r from-cyan-400 to-yellow-800",
  "bg-gradient-to-r from-cyan-400 to-yellow-900",
  "bg-gradient-to-r from-cyan-400 to-yellow-950",
  "bg-gradient-to-r from-cyan-400 to-lime-300",
  "bg-gradient-to-r from-cyan-400 to-lime-400",
  "bg-gradient-to-r from-cyan-400 to-lime-500",
  "bg-gradient-to-r from-cyan-400 to-lime-600",
  "bg-gradient-to-r from-cyan-400 to-lime-700",
  "bg-gradient-to-r from-cyan-400 to-lime-800",
  "bg-gradient-to-r from-cyan-400 to-lime-900",
  "bg-gradient-to-r from-cyan-400 to-lime-950",
  "bg-gradient-to-r from-cyan-400 to-green-300",
  "bg-gradient-to-r from-cyan-400 to-green-400",
  "bg-gradient-to-r from-cyan-400 to-green-500",
  "bg-gradient-to-r from-cyan-400 to-green-600",
  "bg-gradient-to-r from-cyan-400 to-green-700",
  "bg-gradient-to-r from-cyan-400 to-green-800",
  "bg-gradient-to-r from-cyan-400 to-green-900",
  "bg-gradient-to-r from-cyan-400 to-green-950",
  "bg-gradient-to-r from-cyan-400 to-emerald-300",
  "bg-gradient-to-r from-cyan-400 to-emerald-400",
  "bg-gradient-to-r from-cyan-400 to-emerald-500",
  "bg-gradient-to-r from-cyan-400 to-emerald-600",
  "bg-gradient-to-r from-cyan-400 to-emerald-700",
  "bg-gradient-to-r from-cyan-400 to-emerald-800",
  "bg-gradient-to-r from-cyan-400 to-emerald-900",
  "bg-gradient-to-r from-cyan-400 to-emerald-950",
  "bg-gradient-to-r from-cyan-400 to-teal-300",
  "bg-gradient-to-r from-cyan-400 to-teal-400",
  "bg-gradient-to-r from-cyan-400 to-teal-500",
  "bg-gradient-to-r from-cyan-400 to-teal-600",
  "bg-gradient-to-r from-cyan-400 to-teal-700",
  "bg-gradient-to-r from-cyan-400 to-teal-800",
  "bg-gradient-to-r from-cyan-400 to-teal-900",
  "bg-gradient-to-r from-cyan-400 to-teal-950",
  "bg-gradient-to-r from-cyan-400 to-sky-300",
  "bg-gradient-to-r from-cyan-400 to-sky-400",
  "bg-gradient-to-r from-cyan-400 to-sky-500",
  "bg-gradient-to-r from-cyan-400 to-sky-600",
  "bg-gradient-to-r from-cyan-400 to-sky-700",
  "bg-gradient-to-r from-cyan-400 to-sky-800",
  "bg-gradient-to-r from-cyan-400 to-sky-900",
  "bg-gradient-to-r from-cyan-400 to-sky-950",
  "bg-gradient-to-r from-cyan-400 to-blue-300",
  "bg-gradient-to-r from-cyan-400 to-blue-400",
  "bg-gradient-to-r from-cyan-400 to-blue-500",
  "bg-gradient-to-r from-cyan-400 to-blue-600",
  "bg-gradient-to-r from-cyan-400 to-blue-700",
  "bg-gradient-to-r from-cyan-400 to-blue-800",
  "bg-gradient-to-r from-cyan-400 to-blue-900",
  "bg-gradient-to-r from-cyan-400 to-blue-950",
  "bg-gradient-to-r from-cyan-400 to-indigo-300",
  "bg-gradient-to-r from-cyan-400 to-indigo-400",
  "bg-gradient-to-r from-cyan-400 to-indigo-500",
  "bg-gradient-to-r from-cyan-400 to-indigo-600",
  "bg-gradient-to-r from-cyan-400 to-indigo-700",
  "bg-gradient-to-r from-cyan-400 to-indigo-800",
  "bg-gradient-to-r from-cyan-400 to-indigo-900",
  "bg-gradient-to-r from-cyan-400 to-indigo-950",
  "bg-gradient-to-r from-cyan-400 to-violet-300",
  "bg-gradient-to-r from-cyan-400 to-violet-400",
  "bg-gradient-to-r from-cyan-400 to-violet-500",
  "bg-gradient-to-r from-cyan-400 to-violet-600",
  "bg-gradient-to-r from-cyan-400 to-violet-700",
  "bg-gradient-to-r from-cyan-400 to-violet-800",
  "bg-gradient-to-r from-cyan-400 to-violet-900",
  "bg-gradient-to-r from-cyan-400 to-violet-950",
  "bg-gradient-to-r from-cyan-400 to-purple-300",
  "bg-gradient-to-r from-cyan-400 to-purple-400",
  "bg-gradient-to-r from-cyan-400 to-purple-500",
  "bg-gradient-to-r from-cyan-400 to-purple-600",
  "bg-gradient-to-r from-cyan-400 to-purple-700",
  "bg-gradient-to-r from-cyan-400 to-purple-800",
  "bg-gradient-to-r from-cyan-400 to-purple-900",
  "bg-gradient-to-r from-cyan-400 to-purple-950",
  "bg-gradient-to-r from-cyan-400 to-fuchsia-300",
  "bg-gradient-to-r from-cyan-400 to-fuchsia-400",
  "bg-gradient-to-r from-cyan-400 to-fuchsia-500",
  "bg-gradient-to-r from-cyan-400 to-fuchsia-600",
  "bg-gradient-to-r from-cyan-400 to-fuchsia-700",
  "bg-gradient-to-r from-cyan-400 to-fuchsia-800",
  "bg-gradient-to-r from-cyan-400 to-fuchsia-900",
  "bg-gradient-to-r from-cyan-400 to-fuchsia-950",
  "bg-gradient-to-r from-cyan-400 to-pink-300",
  "bg-gradient-to-r from-cyan-400 to-pink-400",
  "bg-gradient-to-r from-cyan-400 to-pink-500",
  "bg-gradient-to-r from-cyan-400 to-pink-600",
  "bg-gradient-to-r from-cyan-400 to-pink-700",
  "bg-gradient-to-r from-cyan-400 to-pink-800",
  "bg-gradient-to-r from-cyan-400 to-pink-900",
  "bg-gradient-to-r from-cyan-400 to-pink-950",
  "bg-gradient-to-r from-cyan-400 to-rose-300",
  "bg-gradient-to-r from-cyan-400 to-rose-400",
  "bg-gradient-to-r from-cyan-400 to-rose-500",
  "bg-gradient-to-r from-cyan-400 to-rose-600",
  "bg-gradient-to-r from-cyan-400 to-rose-700",
  "bg-gradient-to-r from-cyan-400 to-rose-800",
  "bg-gradient-to-r from-cyan-400 to-rose-900",
  "bg-gradient-to-r from-cyan-400 to-rose-950",
  "bg-gradient-to-r from-cyan-500 to-red-300",
  "bg-gradient-to-r from-cyan-500 to-red-400",
  "bg-gradient-to-r from-cyan-500 to-red-500",
  "bg-gradient-to-r from-cyan-500 to-red-600",
  "bg-gradient-to-r from-cyan-500 to-red-700",
  "bg-gradient-to-r from-cyan-500 to-red-800",
  "bg-gradient-to-r from-cyan-500 to-red-900",
  "bg-gradient-to-r from-cyan-500 to-red-950",
  "bg-gradient-to-r from-cyan-500 to-orange-300",
  "bg-gradient-to-r from-cyan-500 to-orange-400",
  "bg-gradient-to-r from-cyan-500 to-orange-500",
  "bg-gradient-to-r from-cyan-500 to-orange-600",
  "bg-gradient-to-r from-cyan-500 to-orange-700",
  "bg-gradient-to-r from-cyan-500 to-orange-800",
  "bg-gradient-to-r from-cyan-500 to-orange-900",
  "bg-gradient-to-r from-cyan-500 to-orange-950",
  "bg-gradient-to-r from-cyan-500 to-amber-300",
  "bg-gradient-to-r from-cyan-500 to-amber-400",
  "bg-gradient-to-r from-cyan-500 to-amber-500",
  "bg-gradient-to-r from-cyan-500 to-amber-600",
  "bg-gradient-to-r from-cyan-500 to-amber-700",
  "bg-gradient-to-r from-cyan-500 to-amber-800",
  "bg-gradient-to-r from-cyan-500 to-amber-900",
  "bg-gradient-to-r from-cyan-500 to-amber-950",
  "bg-gradient-to-r from-cyan-500 to-yellow-300",
  "bg-gradient-to-r from-cyan-500 to-yellow-400",
  "bg-gradient-to-r from-cyan-500 to-yellow-500",
  "bg-gradient-to-r from-cyan-500 to-yellow-600",
  "bg-gradient-to-r from-cyan-500 to-yellow-700",
  "bg-gradient-to-r from-cyan-500 to-yellow-800",
  "bg-gradient-to-r from-cyan-500 to-yellow-900",
  "bg-gradient-to-r from-cyan-500 to-yellow-950",
  "bg-gradient-to-r from-cyan-500 to-lime-300",
  "bg-gradient-to-r from-cyan-500 to-lime-400",
  "bg-gradient-to-r from-cyan-500 to-lime-500",
  "bg-gradient-to-r from-cyan-500 to-lime-600",
  "bg-gradient-to-r from-cyan-500 to-lime-700",
  "bg-gradient-to-r from-cyan-500 to-lime-800",
  "bg-gradient-to-r from-cyan-500 to-lime-900",
  "bg-gradient-to-r from-cyan-500 to-lime-950",
  "bg-gradient-to-r from-cyan-500 to-green-300",
  "bg-gradient-to-r from-cyan-500 to-green-400",
  "bg-gradient-to-r from-cyan-500 to-green-500",
  "bg-gradient-to-r from-cyan-500 to-green-600",
  "bg-gradient-to-r from-cyan-500 to-green-700",
  "bg-gradient-to-r from-cyan-500 to-green-800",
  "bg-gradient-to-r from-cyan-500 to-green-900",
  "bg-gradient-to-r from-cyan-500 to-green-950",
  "bg-gradient-to-r from-cyan-500 to-emerald-300",
  "bg-gradient-to-r from-cyan-500 to-emerald-400",
  "bg-gradient-to-r from-cyan-500 to-emerald-500",
  "bg-gradient-to-r from-cyan-500 to-emerald-600",
  "bg-gradient-to-r from-cyan-500 to-emerald-700",
  "bg-gradient-to-r from-cyan-500 to-emerald-800",
  "bg-gradient-to-r from-cyan-500 to-emerald-900",
  "bg-gradient-to-r from-cyan-500 to-emerald-950",
  "bg-gradient-to-r from-cyan-500 to-teal-300",
  "bg-gradient-to-r from-cyan-500 to-teal-400",
  "bg-gradient-to-r from-cyan-500 to-teal-500",
  "bg-gradient-to-r from-cyan-500 to-teal-600",
  "bg-gradient-to-r from-cyan-500 to-teal-700",
  "bg-gradient-to-r from-cyan-500 to-teal-800",
  "bg-gradient-to-r from-cyan-500 to-teal-900",
  "bg-gradient-to-r from-cyan-500 to-teal-950",
  "bg-gradient-to-r from-cyan-500 to-sky-300",
  "bg-gradient-to-r from-cyan-500 to-sky-400",
  "bg-gradient-to-r from-cyan-500 to-sky-500",
  "bg-gradient-to-r from-cyan-500 to-sky-600",
  "bg-gradient-to-r from-cyan-500 to-sky-700",
  "bg-gradient-to-r from-cyan-500 to-sky-800",
  "bg-gradient-to-r from-cyan-500 to-sky-900",
  "bg-gradient-to-r from-cyan-500 to-sky-950",
  "bg-gradient-to-r from-cyan-500 to-blue-300",
  "bg-gradient-to-r from-cyan-500 to-blue-400",
  "bg-gradient-to-r from-cyan-500 to-blue-500",
  "bg-gradient-to-r from-cyan-500 to-blue-600",
  "bg-gradient-to-r from-cyan-500 to-blue-700",
  "bg-gradient-to-r from-cyan-500 to-blue-800",
  "bg-gradient-to-r from-cyan-500 to-blue-900",
  "bg-gradient-to-r from-cyan-500 to-blue-950",
  "bg-gradient-to-r from-cyan-500 to-indigo-300",
  "bg-gradient-to-r from-cyan-500 to-indigo-400",
  "bg-gradient-to-r from-cyan-500 to-indigo-500",
  "bg-gradient-to-r from-cyan-500 to-indigo-600",
  "bg-gradient-to-r from-cyan-500 to-indigo-700",
  "bg-gradient-to-r from-cyan-500 to-indigo-800",
  "bg-gradient-to-r from-cyan-500 to-indigo-900",
  "bg-gradient-to-r from-cyan-500 to-indigo-950",
  "bg-gradient-to-r from-cyan-500 to-violet-300",
  "bg-gradient-to-r from-cyan-500 to-violet-400",
  "bg-gradient-to-r from-cyan-500 to-violet-500",
  "bg-gradient-to-r from-cyan-500 to-violet-600",
  "bg-gradient-to-r from-cyan-500 to-violet-700",
  "bg-gradient-to-r from-cyan-500 to-violet-800",
  "bg-gradient-to-r from-cyan-500 to-violet-900",
  "bg-gradient-to-r from-cyan-500 to-violet-950",
  "bg-gradient-to-r from-cyan-500 to-purple-300",
  "bg-gradient-to-r from-cyan-500 to-purple-400",
  "bg-gradient-to-r from-cyan-500 to-purple-500",
  "bg-gradient-to-r from-cyan-500 to-purple-600",
  "bg-gradient-to-r from-cyan-500 to-purple-700",
  "bg-gradient-to-r from-cyan-500 to-purple-800",
  "bg-gradient-to-r from-cyan-500 to-purple-900",
  "bg-gradient-to-r from-cyan-500 to-purple-950",
  "bg-gradient-to-r from-cyan-500 to-fuchsia-300",
  "bg-gradient-to-r from-cyan-500 to-fuchsia-400",
  "bg-gradient-to-r from-cyan-500 to-fuchsia-500",
  "bg-gradient-to-r from-cyan-500 to-fuchsia-600",
  "bg-gradient-to-r from-cyan-500 to-fuchsia-700",
  "bg-gradient-to-r from-cyan-500 to-fuchsia-800",
  "bg-gradient-to-r from-cyan-500 to-fuchsia-900",
  "bg-gradient-to-r from-cyan-500 to-fuchsia-950",
  "bg-gradient-to-r from-cyan-500 to-pink-300",
  "bg-gradient-to-r from-cyan-500 to-pink-400",
  "bg-gradient-to-r from-cyan-500 to-pink-500",
  "bg-gradient-to-r from-cyan-500 to-pink-600",
  "bg-gradient-to-r from-cyan-500 to-pink-700",
  "bg-gradient-to-r from-cyan-500 to-pink-800",
  "bg-gradient-to-r from-cyan-500 to-pink-900",
  "bg-gradient-to-r from-cyan-500 to-pink-950",
  "bg-gradient-to-r from-cyan-500 to-rose-300",
  "bg-gradient-to-r from-cyan-500 to-rose-400",
  "bg-gradient-to-r from-cyan-500 to-rose-500",
  "bg-gradient-to-r from-cyan-500 to-rose-600",
  "bg-gradient-to-r from-cyan-500 to-rose-700",
  "bg-gradient-to-r from-cyan-500 to-rose-800",
  "bg-gradient-to-r from-cyan-500 to-rose-900",
  "bg-gradient-to-r from-cyan-500 to-rose-950",
  "bg-gradient-to-r from-cyan-600 to-red-300",
  "bg-gradient-to-r from-cyan-600 to-red-400",
  "bg-gradient-to-r from-cyan-600 to-red-500",
  "bg-gradient-to-r from-cyan-600 to-red-600",
  "bg-gradient-to-r from-cyan-600 to-red-700",
  "bg-gradient-to-r from-cyan-600 to-red-800",
  "bg-gradient-to-r from-cyan-600 to-red-900",
  "bg-gradient-to-r from-cyan-600 to-red-950",
  "bg-gradient-to-r from-cyan-600 to-orange-300",
  "bg-gradient-to-r from-cyan-600 to-orange-400",
  "bg-gradient-to-r from-cyan-600 to-orange-500",
  "bg-gradient-to-r from-cyan-600 to-orange-600",
  "bg-gradient-to-r from-cyan-600 to-orange-700",
  "bg-gradient-to-r from-cyan-600 to-orange-800",
  "bg-gradient-to-r from-cyan-600 to-orange-900",
  "bg-gradient-to-r from-cyan-600 to-orange-950",
  "bg-gradient-to-r from-cyan-600 to-amber-300",
  "bg-gradient-to-r from-cyan-600 to-amber-400",
  "bg-gradient-to-r from-cyan-600 to-amber-500",
  "bg-gradient-to-r from-cyan-600 to-amber-600",
  "bg-gradient-to-r from-cyan-600 to-amber-700",
  "bg-gradient-to-r from-cyan-600 to-amber-800",
  "bg-gradient-to-r from-cyan-600 to-amber-900",
  "bg-gradient-to-r from-cyan-600 to-amber-950",
  "bg-gradient-to-r from-cyan-600 to-yellow-300",
  "bg-gradient-to-r from-cyan-600 to-yellow-400",
  "bg-gradient-to-r from-cyan-600 to-yellow-500",
  "bg-gradient-to-r from-cyan-600 to-yellow-600",
  "bg-gradient-to-r from-cyan-600 to-yellow-700",
  "bg-gradient-to-r from-cyan-600 to-yellow-800",
  "bg-gradient-to-r from-cyan-600 to-yellow-900",
  "bg-gradient-to-r from-cyan-600 to-yellow-950",
  "bg-gradient-to-r from-cyan-600 to-lime-300",
  "bg-gradient-to-r from-cyan-600 to-lime-400",
  "bg-gradient-to-r from-cyan-600 to-lime-500",
  "bg-gradient-to-r from-cyan-600 to-lime-600",
  "bg-gradient-to-r from-cyan-600 to-lime-700",
  "bg-gradient-to-r from-cyan-600 to-lime-800",
  "bg-gradient-to-r from-cyan-600 to-lime-900",
  "bg-gradient-to-r from-cyan-600 to-lime-950",
  "bg-gradient-to-r from-cyan-600 to-green-300",
  "bg-gradient-to-r from-cyan-600 to-green-400",
  "bg-gradient-to-r from-cyan-600 to-green-500",
  "bg-gradient-to-r from-cyan-600 to-green-600",
  "bg-gradient-to-r from-cyan-600 to-green-700",
  "bg-gradient-to-r from-cyan-600 to-green-800",
  "bg-gradient-to-r from-cyan-600 to-green-900",
  "bg-gradient-to-r from-cyan-600 to-green-950",
  "bg-gradient-to-r from-cyan-600 to-emerald-300",
  "bg-gradient-to-r from-cyan-600 to-emerald-400",
  "bg-gradient-to-r from-cyan-600 to-emerald-500",
  "bg-gradient-to-r from-cyan-600 to-emerald-600",
  "bg-gradient-to-r from-cyan-600 to-emerald-700",
  "bg-gradient-to-r from-cyan-600 to-emerald-800",
  "bg-gradient-to-r from-cyan-600 to-emerald-900",
  "bg-gradient-to-r from-cyan-600 to-emerald-950",
  "bg-gradient-to-r from-cyan-600 to-teal-300",
  "bg-gradient-to-r from-cyan-600 to-teal-400",
  "bg-gradient-to-r from-cyan-600 to-teal-500",
  "bg-gradient-to-r from-cyan-600 to-teal-600",
  "bg-gradient-to-r from-cyan-600 to-teal-700",
  "bg-gradient-to-r from-cyan-600 to-teal-800",
  "bg-gradient-to-r from-cyan-600 to-teal-900",
  "bg-gradient-to-r from-cyan-600 to-teal-950",
  "bg-gradient-to-r from-cyan-600 to-sky-300",
  "bg-gradient-to-r from-cyan-600 to-sky-400",
  "bg-gradient-to-r from-cyan-600 to-sky-500",
  "bg-gradient-to-r from-cyan-600 to-sky-600",
  "bg-gradient-to-r from-cyan-600 to-sky-700",
  "bg-gradient-to-r from-cyan-600 to-sky-800",
  "bg-gradient-to-r from-cyan-600 to-sky-900",
  "bg-gradient-to-r from-cyan-600 to-sky-950",
  "bg-gradient-to-r from-cyan-600 to-blue-300",
  "bg-gradient-to-r from-cyan-600 to-blue-400",
  "bg-gradient-to-r from-cyan-600 to-blue-500",
  "bg-gradient-to-r from-cyan-600 to-blue-600",
  "bg-gradient-to-r from-cyan-600 to-blue-700",
  "bg-gradient-to-r from-cyan-600 to-blue-800",
  "bg-gradient-to-r from-cyan-600 to-blue-900",
  "bg-gradient-to-r from-cyan-600 to-blue-950",
  "bg-gradient-to-r from-cyan-600 to-indigo-300",
  "bg-gradient-to-r from-cyan-600 to-indigo-400",
  "bg-gradient-to-r from-cyan-600 to-indigo-500",
  "bg-gradient-to-r from-cyan-600 to-indigo-600",
  "bg-gradient-to-r from-cyan-600 to-indigo-700",
  "bg-gradient-to-r from-cyan-600 to-indigo-800",
  "bg-gradient-to-r from-cyan-600 to-indigo-900",
  "bg-gradient-to-r from-cyan-600 to-indigo-950",
  "bg-gradient-to-r from-cyan-600 to-violet-300",
  "bg-gradient-to-r from-cyan-600 to-violet-400",
  "bg-gradient-to-r from-cyan-600 to-violet-500",
  "bg-gradient-to-r from-cyan-600 to-violet-600",
  "bg-gradient-to-r from-cyan-600 to-violet-700",
  "bg-gradient-to-r from-cyan-600 to-violet-800",
  "bg-gradient-to-r from-cyan-600 to-violet-900",
  "bg-gradient-to-r from-cyan-600 to-violet-950",
  "bg-gradient-to-r from-cyan-600 to-purple-300",
  "bg-gradient-to-r from-cyan-600 to-purple-400",
  "bg-gradient-to-r from-cyan-600 to-purple-500",
  "bg-gradient-to-r from-cyan-600 to-purple-600",
  "bg-gradient-to-r from-cyan-600 to-purple-700",
  "bg-gradient-to-r from-cyan-600 to-purple-800",
  "bg-gradient-to-r from-cyan-600 to-purple-900",
  "bg-gradient-to-r from-cyan-600 to-purple-950",
  "bg-gradient-to-r from-cyan-600 to-fuchsia-300",
  "bg-gradient-to-r from-cyan-600 to-fuchsia-400",
  "bg-gradient-to-r from-cyan-600 to-fuchsia-500",
  "bg-gradient-to-r from-cyan-600 to-fuchsia-600",
  "bg-gradient-to-r from-cyan-600 to-fuchsia-700",
  "bg-gradient-to-r from-cyan-600 to-fuchsia-800",
  "bg-gradient-to-r from-cyan-600 to-fuchsia-900",
  "bg-gradient-to-r from-cyan-600 to-fuchsia-950",
  "bg-gradient-to-r from-cyan-600 to-pink-300",
  "bg-gradient-to-r from-cyan-600 to-pink-400",
  "bg-gradient-to-r from-cyan-600 to-pink-500",
  "bg-gradient-to-r from-cyan-600 to-pink-600",
  "bg-gradient-to-r from-cyan-600 to-pink-700",
  "bg-gradient-to-r from-cyan-600 to-pink-800",
  "bg-gradient-to-r from-cyan-600 to-pink-900",
  "bg-gradient-to-r from-cyan-600 to-pink-950",
  "bg-gradient-to-r from-cyan-600 to-rose-300",
  "bg-gradient-to-r from-cyan-600 to-rose-400",
  "bg-gradient-to-r from-cyan-600 to-rose-500",
  "bg-gradient-to-r from-cyan-600 to-rose-600",
  "bg-gradient-to-r from-cyan-600 to-rose-700",
  "bg-gradient-to-r from-cyan-600 to-rose-800",
  "bg-gradient-to-r from-cyan-600 to-rose-900",
  "bg-gradient-to-r from-cyan-600 to-rose-950",
  "bg-gradient-to-r from-cyan-700 to-red-300",
  "bg-gradient-to-r from-cyan-700 to-red-400",
  "bg-gradient-to-r from-cyan-700 to-red-500",
  "bg-gradient-to-r from-cyan-700 to-red-600",
  "bg-gradient-to-r from-cyan-700 to-red-700",
  "bg-gradient-to-r from-cyan-700 to-red-800",
  "bg-gradient-to-r from-cyan-700 to-red-900",
  "bg-gradient-to-r from-cyan-700 to-red-950",
  "bg-gradient-to-r from-cyan-700 to-orange-300",
  "bg-gradient-to-r from-cyan-700 to-orange-400",
  "bg-gradient-to-r from-cyan-700 to-orange-500",
  "bg-gradient-to-r from-cyan-700 to-orange-600",
  "bg-gradient-to-r from-cyan-700 to-orange-700",
  "bg-gradient-to-r from-cyan-700 to-orange-800",
  "bg-gradient-to-r from-cyan-700 to-orange-900",
  "bg-gradient-to-r from-cyan-700 to-orange-950",
  "bg-gradient-to-r from-cyan-700 to-amber-300",
  "bg-gradient-to-r from-cyan-700 to-amber-400",
  "bg-gradient-to-r from-cyan-700 to-amber-500",
  "bg-gradient-to-r from-cyan-700 to-amber-600",
  "bg-gradient-to-r from-cyan-700 to-amber-700",
  "bg-gradient-to-r from-cyan-700 to-amber-800",
  "bg-gradient-to-r from-cyan-700 to-amber-900",
  "bg-gradient-to-r from-cyan-700 to-amber-950",
  "bg-gradient-to-r from-cyan-700 to-yellow-300",
  "bg-gradient-to-r from-cyan-700 to-yellow-400",
  "bg-gradient-to-r from-cyan-700 to-yellow-500",
  "bg-gradient-to-r from-cyan-700 to-yellow-600",
  "bg-gradient-to-r from-cyan-700 to-yellow-700",
  "bg-gradient-to-r from-cyan-700 to-yellow-800",
  "bg-gradient-to-r from-cyan-700 to-yellow-900",
  "bg-gradient-to-r from-cyan-700 to-yellow-950",
  "bg-gradient-to-r from-cyan-700 to-lime-300",
  "bg-gradient-to-r from-cyan-700 to-lime-400",
  "bg-gradient-to-r from-cyan-700 to-lime-500",
  "bg-gradient-to-r from-cyan-700 to-lime-600",
  "bg-gradient-to-r from-cyan-700 to-lime-700",
  "bg-gradient-to-r from-cyan-700 to-lime-800",
  "bg-gradient-to-r from-cyan-700 to-lime-900",
  "bg-gradient-to-r from-cyan-700 to-lime-950",
  "bg-gradient-to-r from-cyan-700 to-green-300",
  "bg-gradient-to-r from-cyan-700 to-green-400",
  "bg-gradient-to-r from-cyan-700 to-green-500",
  "bg-gradient-to-r from-cyan-700 to-green-600",
  "bg-gradient-to-r from-cyan-700 to-green-700",
  "bg-gradient-to-r from-cyan-700 to-green-800",
  "bg-gradient-to-r from-cyan-700 to-green-900",
  "bg-gradient-to-r from-cyan-700 to-green-950",
  "bg-gradient-to-r from-cyan-700 to-emerald-300",
  "bg-gradient-to-r from-cyan-700 to-emerald-400",
  "bg-gradient-to-r from-cyan-700 to-emerald-500",
  "bg-gradient-to-r from-cyan-700 to-emerald-600",
  "bg-gradient-to-r from-cyan-700 to-emerald-700",
  "bg-gradient-to-r from-cyan-700 to-emerald-800",
  "bg-gradient-to-r from-cyan-700 to-emerald-900",
  "bg-gradient-to-r from-cyan-700 to-emerald-950",
  "bg-gradient-to-r from-cyan-700 to-teal-300",
  "bg-gradient-to-r from-cyan-700 to-teal-400",
  "bg-gradient-to-r from-cyan-700 to-teal-500",
  "bg-gradient-to-r from-cyan-700 to-teal-600",
  "bg-gradient-to-r from-cyan-700 to-teal-700",
  "bg-gradient-to-r from-cyan-700 to-teal-800",
  "bg-gradient-to-r from-cyan-700 to-teal-900",
  "bg-gradient-to-r from-cyan-700 to-teal-950",
  "bg-gradient-to-r from-cyan-700 to-sky-300",
  "bg-gradient-to-r from-cyan-700 to-sky-400",
  "bg-gradient-to-r from-cyan-700 to-sky-500",
  "bg-gradient-to-r from-cyan-700 to-sky-600",
  "bg-gradient-to-r from-cyan-700 to-sky-700",
  "bg-gradient-to-r from-cyan-700 to-sky-800",
  "bg-gradient-to-r from-cyan-700 to-sky-900",
  "bg-gradient-to-r from-cyan-700 to-sky-950",
  "bg-gradient-to-r from-cyan-700 to-blue-300",
  "bg-gradient-to-r from-cyan-700 to-blue-400",
  "bg-gradient-to-r from-cyan-700 to-blue-500",
  "bg-gradient-to-r from-cyan-700 to-blue-600",
  "bg-gradient-to-r from-cyan-700 to-blue-700",
  "bg-gradient-to-r from-cyan-700 to-blue-800",
  "bg-gradient-to-r from-cyan-700 to-blue-900",
  "bg-gradient-to-r from-cyan-700 to-blue-950",
  "bg-gradient-to-r from-cyan-700 to-indigo-300",
  "bg-gradient-to-r from-cyan-700 to-indigo-400",
  "bg-gradient-to-r from-cyan-700 to-indigo-500",
  "bg-gradient-to-r from-cyan-700 to-indigo-600",
  "bg-gradient-to-r from-cyan-700 to-indigo-700",
  "bg-gradient-to-r from-cyan-700 to-indigo-800",
  "bg-gradient-to-r from-cyan-700 to-indigo-900",
  "bg-gradient-to-r from-cyan-700 to-indigo-950",
  "bg-gradient-to-r from-cyan-700 to-violet-300",
  "bg-gradient-to-r from-cyan-700 to-violet-400",
  "bg-gradient-to-r from-cyan-700 to-violet-500",
  "bg-gradient-to-r from-cyan-700 to-violet-600",
  "bg-gradient-to-r from-cyan-700 to-violet-700",
  "bg-gradient-to-r from-cyan-700 to-violet-800",
  "bg-gradient-to-r from-cyan-700 to-violet-900",
  "bg-gradient-to-r from-cyan-700 to-violet-950",
  "bg-gradient-to-r from-cyan-700 to-purple-300",
  "bg-gradient-to-r from-cyan-700 to-purple-400",
  "bg-gradient-to-r from-cyan-700 to-purple-500",
  "bg-gradient-to-r from-cyan-700 to-purple-600",
  "bg-gradient-to-r from-cyan-700 to-purple-700",
  "bg-gradient-to-r from-cyan-700 to-purple-800",
  "bg-gradient-to-r from-cyan-700 to-purple-900",
  "bg-gradient-to-r from-cyan-700 to-purple-950",
  "bg-gradient-to-r from-cyan-700 to-fuchsia-300",
  "bg-gradient-to-r from-cyan-700 to-fuchsia-400",
  "bg-gradient-to-r from-cyan-700 to-fuchsia-500",
  "bg-gradient-to-r from-cyan-700 to-fuchsia-600",
  "bg-gradient-to-r from-cyan-700 to-fuchsia-700",
  "bg-gradient-to-r from-cyan-700 to-fuchsia-800",
  "bg-gradient-to-r from-cyan-700 to-fuchsia-900",
  "bg-gradient-to-r from-cyan-700 to-fuchsia-950",
  "bg-gradient-to-r from-cyan-700 to-pink-300",
  "bg-gradient-to-r from-cyan-700 to-pink-400",
  "bg-gradient-to-r from-cyan-700 to-pink-500",
  "bg-gradient-to-r from-cyan-700 to-pink-600",
  "bg-gradient-to-r from-cyan-700 to-pink-700",
  "bg-gradient-to-r from-cyan-700 to-pink-800",
  "bg-gradient-to-r from-cyan-700 to-pink-900",
  "bg-gradient-to-r from-cyan-700 to-pink-950",
  "bg-gradient-to-r from-cyan-700 to-rose-300",
  "bg-gradient-to-r from-cyan-700 to-rose-400",
  "bg-gradient-to-r from-cyan-700 to-rose-500",
  "bg-gradient-to-r from-cyan-700 to-rose-600",
  "bg-gradient-to-r from-cyan-700 to-rose-700",
  "bg-gradient-to-r from-cyan-700 to-rose-800",
  "bg-gradient-to-r from-cyan-700 to-rose-900",
  "bg-gradient-to-r from-cyan-700 to-rose-950",
  "bg-gradient-to-r from-cyan-800 to-red-300",
  "bg-gradient-to-r from-cyan-800 to-red-400",
  "bg-gradient-to-r from-cyan-800 to-red-500",
  "bg-gradient-to-r from-cyan-800 to-red-600",
  "bg-gradient-to-r from-cyan-800 to-red-700",
  "bg-gradient-to-r from-cyan-800 to-red-800",
  "bg-gradient-to-r from-cyan-800 to-red-900",
  "bg-gradient-to-r from-cyan-800 to-red-950",
  "bg-gradient-to-r from-cyan-800 to-orange-300",
  "bg-gradient-to-r from-cyan-800 to-orange-400",
  "bg-gradient-to-r from-cyan-800 to-orange-500",
  "bg-gradient-to-r from-cyan-800 to-orange-600",
  "bg-gradient-to-r from-cyan-800 to-orange-700",
  "bg-gradient-to-r from-cyan-800 to-orange-800",
  "bg-gradient-to-r from-cyan-800 to-orange-900",
  "bg-gradient-to-r from-cyan-800 to-orange-950",
  "bg-gradient-to-r from-cyan-800 to-amber-300",
  "bg-gradient-to-r from-cyan-800 to-amber-400",
  "bg-gradient-to-r from-cyan-800 to-amber-500",
  "bg-gradient-to-r from-cyan-800 to-amber-600",
  "bg-gradient-to-r from-cyan-800 to-amber-700",
  "bg-gradient-to-r from-cyan-800 to-amber-800",
  "bg-gradient-to-r from-cyan-800 to-amber-900",
  "bg-gradient-to-r from-cyan-800 to-amber-950",
  "bg-gradient-to-r from-cyan-800 to-yellow-300",
  "bg-gradient-to-r from-cyan-800 to-yellow-400",
  "bg-gradient-to-r from-cyan-800 to-yellow-500",
  "bg-gradient-to-r from-cyan-800 to-yellow-600",
  "bg-gradient-to-r from-cyan-800 to-yellow-700",
  "bg-gradient-to-r from-cyan-800 to-yellow-800",
  "bg-gradient-to-r from-cyan-800 to-yellow-900",
  "bg-gradient-to-r from-cyan-800 to-yellow-950",
  "bg-gradient-to-r from-cyan-800 to-lime-300",
  "bg-gradient-to-r from-cyan-800 to-lime-400",
  "bg-gradient-to-r from-cyan-800 to-lime-500",
  "bg-gradient-to-r from-cyan-800 to-lime-600",
  "bg-gradient-to-r from-cyan-800 to-lime-700",
  "bg-gradient-to-r from-cyan-800 to-lime-800",
  "bg-gradient-to-r from-cyan-800 to-lime-900",
  "bg-gradient-to-r from-cyan-800 to-lime-950",
  "bg-gradient-to-r from-cyan-800 to-green-300",
  "bg-gradient-to-r from-cyan-800 to-green-400",
  "bg-gradient-to-r from-cyan-800 to-green-500",
  "bg-gradient-to-r from-cyan-800 to-green-600",
  "bg-gradient-to-r from-cyan-800 to-green-700",
  "bg-gradient-to-r from-cyan-800 to-green-800",
  "bg-gradient-to-r from-cyan-800 to-green-900",
  "bg-gradient-to-r from-cyan-800 to-green-950",
  "bg-gradient-to-r from-cyan-800 to-emerald-300",
  "bg-gradient-to-r from-cyan-800 to-emerald-400",
  "bg-gradient-to-r from-cyan-800 to-emerald-500",
  "bg-gradient-to-r from-cyan-800 to-emerald-600",
  "bg-gradient-to-r from-cyan-800 to-emerald-700",
  "bg-gradient-to-r from-cyan-800 to-emerald-800",
  "bg-gradient-to-r from-cyan-800 to-emerald-900",
  "bg-gradient-to-r from-cyan-800 to-emerald-950",
  "bg-gradient-to-r from-cyan-800 to-teal-300",
  "bg-gradient-to-r from-cyan-800 to-teal-400",
  "bg-gradient-to-r from-cyan-800 to-teal-500",
  "bg-gradient-to-r from-cyan-800 to-teal-600",
  "bg-gradient-to-r from-cyan-800 to-teal-700",
  "bg-gradient-to-r from-cyan-800 to-teal-800",
  "bg-gradient-to-r from-cyan-800 to-teal-900",
  "bg-gradient-to-r from-cyan-800 to-teal-950",
  "bg-gradient-to-r from-cyan-800 to-sky-300",
  "bg-gradient-to-r from-cyan-800 to-sky-400",
  "bg-gradient-to-r from-cyan-800 to-sky-500",
  "bg-gradient-to-r from-cyan-800 to-sky-600",
  "bg-gradient-to-r from-cyan-800 to-sky-700",
  "bg-gradient-to-r from-cyan-800 to-sky-800",
  "bg-gradient-to-r from-cyan-800 to-sky-900",
  "bg-gradient-to-r from-cyan-800 to-sky-950",
  "bg-gradient-to-r from-cyan-800 to-blue-300",
  "bg-gradient-to-r from-cyan-800 to-blue-400",
  "bg-gradient-to-r from-cyan-800 to-blue-500",
  "bg-gradient-to-r from-cyan-800 to-blue-600",
  "bg-gradient-to-r from-cyan-800 to-blue-700",
  "bg-gradient-to-r from-cyan-800 to-blue-800",
  "bg-gradient-to-r from-cyan-800 to-blue-900",
  "bg-gradient-to-r from-cyan-800 to-blue-950",
  "bg-gradient-to-r from-cyan-800 to-indigo-300",
  "bg-gradient-to-r from-cyan-800 to-indigo-400",
  "bg-gradient-to-r from-cyan-800 to-indigo-500",
  "bg-gradient-to-r from-cyan-800 to-indigo-600",
  "bg-gradient-to-r from-cyan-800 to-indigo-700",
  "bg-gradient-to-r from-cyan-800 to-indigo-800",
  "bg-gradient-to-r from-cyan-800 to-indigo-900",
  "bg-gradient-to-r from-cyan-800 to-indigo-950",
  "bg-gradient-to-r from-cyan-800 to-violet-300",
  "bg-gradient-to-r from-cyan-800 to-violet-400",
  "bg-gradient-to-r from-cyan-800 to-violet-500",
  "bg-gradient-to-r from-cyan-800 to-violet-600",
  "bg-gradient-to-r from-cyan-800 to-violet-700",
  "bg-gradient-to-r from-cyan-800 to-violet-800",
  "bg-gradient-to-r from-cyan-800 to-violet-900",
  "bg-gradient-to-r from-cyan-800 to-violet-950",
  "bg-gradient-to-r from-cyan-800 to-purple-300",
  "bg-gradient-to-r from-cyan-800 to-purple-400",
  "bg-gradient-to-r from-cyan-800 to-purple-500",
  "bg-gradient-to-r from-cyan-800 to-purple-600",
  "bg-gradient-to-r from-cyan-800 to-purple-700",
  "bg-gradient-to-r from-cyan-800 to-purple-800",
  "bg-gradient-to-r from-cyan-800 to-purple-900",
  "bg-gradient-to-r from-cyan-800 to-purple-950",
  "bg-gradient-to-r from-cyan-800 to-fuchsia-300",
  "bg-gradient-to-r from-cyan-800 to-fuchsia-400",
  "bg-gradient-to-r from-cyan-800 to-fuchsia-500",
  "bg-gradient-to-r from-cyan-800 to-fuchsia-600",
  "bg-gradient-to-r from-cyan-800 to-fuchsia-700",
  "bg-gradient-to-r from-cyan-800 to-fuchsia-800",
  "bg-gradient-to-r from-cyan-800 to-fuchsia-900",
  "bg-gradient-to-r from-cyan-800 to-fuchsia-950",
  "bg-gradient-to-r from-cyan-800 to-pink-300",
  "bg-gradient-to-r from-cyan-800 to-pink-400",
  "bg-gradient-to-r from-cyan-800 to-pink-500",
  "bg-gradient-to-r from-cyan-800 to-pink-600",
  "bg-gradient-to-r from-cyan-800 to-pink-700",
  "bg-gradient-to-r from-cyan-800 to-pink-800",
  "bg-gradient-to-r from-cyan-800 to-pink-900",
  "bg-gradient-to-r from-cyan-800 to-pink-950",
  "bg-gradient-to-r from-cyan-800 to-rose-300",
  "bg-gradient-to-r from-cyan-800 to-rose-400",
  "bg-gradient-to-r from-cyan-800 to-rose-500",
  "bg-gradient-to-r from-cyan-800 to-rose-600",
  "bg-gradient-to-r from-cyan-800 to-rose-700",
  "bg-gradient-to-r from-cyan-800 to-rose-800",
  "bg-gradient-to-r from-cyan-800 to-rose-900",
  "bg-gradient-to-r from-cyan-800 to-rose-950",
  "bg-gradient-to-r from-cyan-900 to-red-300",
  "bg-gradient-to-r from-cyan-900 to-red-400",
  "bg-gradient-to-r from-cyan-900 to-red-500",
  "bg-gradient-to-r from-cyan-900 to-red-600",
  "bg-gradient-to-r from-cyan-900 to-red-700",
  "bg-gradient-to-r from-cyan-900 to-red-800",
  "bg-gradient-to-r from-cyan-900 to-red-900",
  "bg-gradient-to-r from-cyan-900 to-red-950",
  "bg-gradient-to-r from-cyan-900 to-orange-300",
  "bg-gradient-to-r from-cyan-900 to-orange-400",
  "bg-gradient-to-r from-cyan-900 to-orange-500",
  "bg-gradient-to-r from-cyan-900 to-orange-600",
  "bg-gradient-to-r from-cyan-900 to-orange-700",
  "bg-gradient-to-r from-cyan-900 to-orange-800",
  "bg-gradient-to-r from-cyan-900 to-orange-900",
  "bg-gradient-to-r from-cyan-900 to-orange-950",
  "bg-gradient-to-r from-cyan-900 to-amber-300",
  "bg-gradient-to-r from-cyan-900 to-amber-400",
  "bg-gradient-to-r from-cyan-900 to-amber-500",
  "bg-gradient-to-r from-cyan-900 to-amber-600",
  "bg-gradient-to-r from-cyan-900 to-amber-700",
  "bg-gradient-to-r from-cyan-900 to-amber-800",
  "bg-gradient-to-r from-cyan-900 to-amber-900",
  "bg-gradient-to-r from-cyan-900 to-amber-950",
  "bg-gradient-to-r from-cyan-900 to-yellow-300",
  "bg-gradient-to-r from-cyan-900 to-yellow-400",
  "bg-gradient-to-r from-cyan-900 to-yellow-500",
  "bg-gradient-to-r from-cyan-900 to-yellow-600",
  "bg-gradient-to-r from-cyan-900 to-yellow-700",
  "bg-gradient-to-r from-cyan-900 to-yellow-800",
  "bg-gradient-to-r from-cyan-900 to-yellow-900",
  "bg-gradient-to-r from-cyan-900 to-yellow-950",
  "bg-gradient-to-r from-cyan-900 to-lime-300",
  "bg-gradient-to-r from-cyan-900 to-lime-400",
  "bg-gradient-to-r from-cyan-900 to-lime-500",
  "bg-gradient-to-r from-cyan-900 to-lime-600",
  "bg-gradient-to-r from-cyan-900 to-lime-700",
  "bg-gradient-to-r from-cyan-900 to-lime-800",
  "bg-gradient-to-r from-cyan-900 to-lime-900",
  "bg-gradient-to-r from-cyan-900 to-lime-950",
  "bg-gradient-to-r from-cyan-900 to-green-300",
  "bg-gradient-to-r from-cyan-900 to-green-400",
  "bg-gradient-to-r from-cyan-900 to-green-500",
  "bg-gradient-to-r from-cyan-900 to-green-600",
  "bg-gradient-to-r from-cyan-900 to-green-700",
  "bg-gradient-to-r from-cyan-900 to-green-800",
  "bg-gradient-to-r from-cyan-900 to-green-900",
  "bg-gradient-to-r from-cyan-900 to-green-950",
  "bg-gradient-to-r from-cyan-900 to-emerald-300",
  "bg-gradient-to-r from-cyan-900 to-emerald-400",
  "bg-gradient-to-r from-cyan-900 to-emerald-500",
  "bg-gradient-to-r from-cyan-900 to-emerald-600",
  "bg-gradient-to-r from-cyan-900 to-emerald-700",
  "bg-gradient-to-r from-cyan-900 to-emerald-800",
  "bg-gradient-to-r from-cyan-900 to-emerald-900",
  "bg-gradient-to-r from-cyan-900 to-emerald-950",
  "bg-gradient-to-r from-cyan-900 to-teal-300",
  "bg-gradient-to-r from-cyan-900 to-teal-400",
  "bg-gradient-to-r from-cyan-900 to-teal-500",
  "bg-gradient-to-r from-cyan-900 to-teal-600",
  "bg-gradient-to-r from-cyan-900 to-teal-700",
  "bg-gradient-to-r from-cyan-900 to-teal-800",
  "bg-gradient-to-r from-cyan-900 to-teal-900",
  "bg-gradient-to-r from-cyan-900 to-teal-950",
  "bg-gradient-to-r from-cyan-900 to-sky-300",
  "bg-gradient-to-r from-cyan-900 to-sky-400",
  "bg-gradient-to-r from-cyan-900 to-sky-500",
  "bg-gradient-to-r from-cyan-900 to-sky-600",
  "bg-gradient-to-r from-cyan-900 to-sky-700",
  "bg-gradient-to-r from-cyan-900 to-sky-800",
  "bg-gradient-to-r from-cyan-900 to-sky-900",
  "bg-gradient-to-r from-cyan-900 to-sky-950",
  "bg-gradient-to-r from-cyan-900 to-blue-300",
  "bg-gradient-to-r from-cyan-900 to-blue-400",
  "bg-gradient-to-r from-cyan-900 to-blue-500",
  "bg-gradient-to-r from-cyan-900 to-blue-600",
  "bg-gradient-to-r from-cyan-900 to-blue-700",
  "bg-gradient-to-r from-cyan-900 to-blue-800",
  "bg-gradient-to-r from-cyan-900 to-blue-900",
  "bg-gradient-to-r from-cyan-900 to-blue-950",
  "bg-gradient-to-r from-cyan-900 to-indigo-300",
  "bg-gradient-to-r from-cyan-900 to-indigo-400",
  "bg-gradient-to-r from-cyan-900 to-indigo-500",
  "bg-gradient-to-r from-cyan-900 to-indigo-600",
  "bg-gradient-to-r from-cyan-900 to-indigo-700",
  "bg-gradient-to-r from-cyan-900 to-indigo-800",
  "bg-gradient-to-r from-cyan-900 to-indigo-900",
  "bg-gradient-to-r from-cyan-900 to-indigo-950",
  "bg-gradient-to-r from-cyan-900 to-violet-300",
  "bg-gradient-to-r from-cyan-900 to-violet-400",
  "bg-gradient-to-r from-cyan-900 to-violet-500",
  "bg-gradient-to-r from-cyan-900 to-violet-600",
  "bg-gradient-to-r from-cyan-900 to-violet-700",
  "bg-gradient-to-r from-cyan-900 to-violet-800",
  "bg-gradient-to-r from-cyan-900 to-violet-900",
  "bg-gradient-to-r from-cyan-900 to-violet-950",
  "bg-gradient-to-r from-cyan-900 to-purple-300",
  "bg-gradient-to-r from-cyan-900 to-purple-400",
  "bg-gradient-to-r from-cyan-900 to-purple-500",
  "bg-gradient-to-r from-cyan-900 to-purple-600",
  "bg-gradient-to-r from-cyan-900 to-purple-700",
  "bg-gradient-to-r from-cyan-900 to-purple-800",
  "bg-gradient-to-r from-cyan-900 to-purple-900",
  "bg-gradient-to-r from-cyan-900 to-purple-950",
  "bg-gradient-to-r from-cyan-900 to-fuchsia-300",
  "bg-gradient-to-r from-cyan-900 to-fuchsia-400",
  "bg-gradient-to-r from-cyan-900 to-fuchsia-500",
  "bg-gradient-to-r from-cyan-900 to-fuchsia-600",
  "bg-gradient-to-r from-cyan-900 to-fuchsia-700",
  "bg-gradient-to-r from-cyan-900 to-fuchsia-800",
  "bg-gradient-to-r from-cyan-900 to-fuchsia-900",
  "bg-gradient-to-r from-cyan-900 to-fuchsia-950",
  "bg-gradient-to-r from-cyan-900 to-pink-300",
  "bg-gradient-to-r from-cyan-900 to-pink-400",
  "bg-gradient-to-r from-cyan-900 to-pink-500",
  "bg-gradient-to-r from-cyan-900 to-pink-600",
  "bg-gradient-to-r from-cyan-900 to-pink-700",
  "bg-gradient-to-r from-cyan-900 to-pink-800",
  "bg-gradient-to-r from-cyan-900 to-pink-900",
  "bg-gradient-to-r from-cyan-900 to-pink-950",
  "bg-gradient-to-r from-cyan-900 to-rose-300",
  "bg-gradient-to-r from-cyan-900 to-rose-400",
  "bg-gradient-to-r from-cyan-900 to-rose-500",
  "bg-gradient-to-r from-cyan-900 to-rose-600",
  "bg-gradient-to-r from-cyan-900 to-rose-700",
  "bg-gradient-to-r from-cyan-900 to-rose-800",
  "bg-gradient-to-r from-cyan-900 to-rose-900",
  "bg-gradient-to-r from-cyan-900 to-rose-950",
  "bg-gradient-to-r from-cyan-950 to-red-300",
  "bg-gradient-to-r from-cyan-950 to-red-400",
  "bg-gradient-to-r from-cyan-950 to-red-500",
  "bg-gradient-to-r from-cyan-950 to-red-600",
  "bg-gradient-to-r from-cyan-950 to-red-700",
  "bg-gradient-to-r from-cyan-950 to-red-800",
  "bg-gradient-to-r from-cyan-950 to-red-900",
  "bg-gradient-to-r from-cyan-950 to-red-950",
  "bg-gradient-to-r from-cyan-950 to-orange-300",
  "bg-gradient-to-r from-cyan-950 to-orange-400",
  "bg-gradient-to-r from-cyan-950 to-orange-500",
  "bg-gradient-to-r from-cyan-950 to-orange-600",
  "bg-gradient-to-r from-cyan-950 to-orange-700",
  "bg-gradient-to-r from-cyan-950 to-orange-800",
  "bg-gradient-to-r from-cyan-950 to-orange-900",
  "bg-gradient-to-r from-cyan-950 to-orange-950",
  "bg-gradient-to-r from-cyan-950 to-amber-300",
  "bg-gradient-to-r from-cyan-950 to-amber-400",
  "bg-gradient-to-r from-cyan-950 to-amber-500",
  "bg-gradient-to-r from-cyan-950 to-amber-600",
  "bg-gradient-to-r from-cyan-950 to-amber-700",
  "bg-gradient-to-r from-cyan-950 to-amber-800",
  "bg-gradient-to-r from-cyan-950 to-amber-900",
  "bg-gradient-to-r from-cyan-950 to-amber-950",
  "bg-gradient-to-r from-cyan-950 to-yellow-300",
  "bg-gradient-to-r from-cyan-950 to-yellow-400",
  "bg-gradient-to-r from-cyan-950 to-yellow-500",
  "bg-gradient-to-r from-cyan-950 to-yellow-600",
  "bg-gradient-to-r from-cyan-950 to-yellow-700",
  "bg-gradient-to-r from-cyan-950 to-yellow-800",
  "bg-gradient-to-r from-cyan-950 to-yellow-900",
  "bg-gradient-to-r from-cyan-950 to-yellow-950",
  "bg-gradient-to-r from-cyan-950 to-lime-300",
  "bg-gradient-to-r from-cyan-950 to-lime-400",
  "bg-gradient-to-r from-cyan-950 to-lime-500",
  "bg-gradient-to-r from-cyan-950 to-lime-600",
  "bg-gradient-to-r from-cyan-950 to-lime-700",
  "bg-gradient-to-r from-cyan-950 to-lime-800",
  "bg-gradient-to-r from-cyan-950 to-lime-900",
  "bg-gradient-to-r from-cyan-950 to-lime-950",
  "bg-gradient-to-r from-cyan-950 to-green-300",
  "bg-gradient-to-r from-cyan-950 to-green-400",
  "bg-gradient-to-r from-cyan-950 to-green-500",
  "bg-gradient-to-r from-cyan-950 to-green-600",
  "bg-gradient-to-r from-cyan-950 to-green-700",
  "bg-gradient-to-r from-cyan-950 to-green-800",
  "bg-gradient-to-r from-cyan-950 to-green-900",
  "bg-gradient-to-r from-cyan-950 to-green-950",
  "bg-gradient-to-r from-cyan-950 to-emerald-300",
  "bg-gradient-to-r from-cyan-950 to-emerald-400",
  "bg-gradient-to-r from-cyan-950 to-emerald-500",
  "bg-gradient-to-r from-cyan-950 to-emerald-600",
  "bg-gradient-to-r from-cyan-950 to-emerald-700",
  "bg-gradient-to-r from-cyan-950 to-emerald-800",
  "bg-gradient-to-r from-cyan-950 to-emerald-900",
  "bg-gradient-to-r from-cyan-950 to-emerald-950",
  "bg-gradient-to-r from-cyan-950 to-teal-300",
  "bg-gradient-to-r from-cyan-950 to-teal-400",
  "bg-gradient-to-r from-cyan-950 to-teal-500",
  "bg-gradient-to-r from-cyan-950 to-teal-600",
  "bg-gradient-to-r from-cyan-950 to-teal-700",
  "bg-gradient-to-r from-cyan-950 to-teal-800",
  "bg-gradient-to-r from-cyan-950 to-teal-900",
  "bg-gradient-to-r from-cyan-950 to-teal-950",
  "bg-gradient-to-r from-cyan-950 to-sky-300",
  "bg-gradient-to-r from-cyan-950 to-sky-400",
  "bg-gradient-to-r from-cyan-950 to-sky-500",
  "bg-gradient-to-r from-cyan-950 to-sky-600",
  "bg-gradient-to-r from-cyan-950 to-sky-700",
  "bg-gradient-to-r from-cyan-950 to-sky-800",
  "bg-gradient-to-r from-cyan-950 to-sky-900",
  "bg-gradient-to-r from-cyan-950 to-sky-950",
  "bg-gradient-to-r from-cyan-950 to-blue-300",
  "bg-gradient-to-r from-cyan-950 to-blue-400",
  "bg-gradient-to-r from-cyan-950 to-blue-500",
  "bg-gradient-to-r from-cyan-950 to-blue-600",
  "bg-gradient-to-r from-cyan-950 to-blue-700",
  "bg-gradient-to-r from-cyan-950 to-blue-800",
  "bg-gradient-to-r from-cyan-950 to-blue-900",
  "bg-gradient-to-r from-cyan-950 to-blue-950",
  "bg-gradient-to-r from-cyan-950 to-indigo-300",
  "bg-gradient-to-r from-cyan-950 to-indigo-400",
  "bg-gradient-to-r from-cyan-950 to-indigo-500",
  "bg-gradient-to-r from-cyan-950 to-indigo-600",
  "bg-gradient-to-r from-cyan-950 to-indigo-700",
  "bg-gradient-to-r from-cyan-950 to-indigo-800",
  "bg-gradient-to-r from-cyan-950 to-indigo-900",
  "bg-gradient-to-r from-cyan-950 to-indigo-950",
  "bg-gradient-to-r from-cyan-950 to-violet-300",
  "bg-gradient-to-r from-cyan-950 to-violet-400",
  "bg-gradient-to-r from-cyan-950 to-violet-500",
  "bg-gradient-to-r from-cyan-950 to-violet-600",
  "bg-gradient-to-r from-cyan-950 to-violet-700",
  "bg-gradient-to-r from-cyan-950 to-violet-800",
  "bg-gradient-to-r from-cyan-950 to-violet-900",
  "bg-gradient-to-r from-cyan-950 to-violet-950",
  "bg-gradient-to-r from-cyan-950 to-purple-300",
  "bg-gradient-to-r from-cyan-950 to-purple-400",
  "bg-gradient-to-r from-cyan-950 to-purple-500",
  "bg-gradient-to-r from-cyan-950 to-purple-600",
  "bg-gradient-to-r from-cyan-950 to-purple-700",
  "bg-gradient-to-r from-cyan-950 to-purple-800",
  "bg-gradient-to-r from-cyan-950 to-purple-900",
  "bg-gradient-to-r from-cyan-950 to-purple-950",
  "bg-gradient-to-r from-cyan-950 to-fuchsia-300",
  "bg-gradient-to-r from-cyan-950 to-fuchsia-400",
  "bg-gradient-to-r from-cyan-950 to-fuchsia-500",
  "bg-gradient-to-r from-cyan-950 to-fuchsia-600",
  "bg-gradient-to-r from-cyan-950 to-fuchsia-700",
  "bg-gradient-to-r from-cyan-950 to-fuchsia-800",
  "bg-gradient-to-r from-cyan-950 to-fuchsia-900",
  "bg-gradient-to-r from-cyan-950 to-fuchsia-950",
  "bg-gradient-to-r from-cyan-950 to-pink-300",
  "bg-gradient-to-r from-cyan-950 to-pink-400",
  "bg-gradient-to-r from-cyan-950 to-pink-500",
  "bg-gradient-to-r from-cyan-950 to-pink-600",
  "bg-gradient-to-r from-cyan-950 to-pink-700",
  "bg-gradient-to-r from-cyan-950 to-pink-800",
  "bg-gradient-to-r from-cyan-950 to-pink-900",
  "bg-gradient-to-r from-cyan-950 to-pink-950",
  "bg-gradient-to-r from-cyan-950 to-rose-300",
  "bg-gradient-to-r from-cyan-950 to-rose-400",
  "bg-gradient-to-r from-cyan-950 to-rose-500",
  "bg-gradient-to-r from-cyan-950 to-rose-600",
  "bg-gradient-to-r from-cyan-950 to-rose-700",
  "bg-gradient-to-r from-cyan-950 to-rose-800",
  "bg-gradient-to-r from-cyan-950 to-rose-900",
  "bg-gradient-to-r from-cyan-950 to-rose-950",
  "bg-gradient-to-r from-sky-300 to-red-300",
  "bg-gradient-to-r from-sky-300 to-red-400",
  "bg-gradient-to-r from-sky-300 to-red-500",
  "bg-gradient-to-r from-sky-300 to-red-600",
  "bg-gradient-to-r from-sky-300 to-red-700",
  "bg-gradient-to-r from-sky-300 to-red-800",
  "bg-gradient-to-r from-sky-300 to-red-900",
  "bg-gradient-to-r from-sky-300 to-red-950",
  "bg-gradient-to-r from-sky-300 to-orange-300",
  "bg-gradient-to-r from-sky-300 to-orange-400",
  "bg-gradient-to-r from-sky-300 to-orange-500",
  "bg-gradient-to-r from-sky-300 to-orange-600",
  "bg-gradient-to-r from-sky-300 to-orange-700",
  "bg-gradient-to-r from-sky-300 to-orange-800",
  "bg-gradient-to-r from-sky-300 to-orange-900",
  "bg-gradient-to-r from-sky-300 to-orange-950",
  "bg-gradient-to-r from-sky-300 to-amber-300",
  "bg-gradient-to-r from-sky-300 to-amber-400",
  "bg-gradient-to-r from-sky-300 to-amber-500",
  "bg-gradient-to-r from-sky-300 to-amber-600",
  "bg-gradient-to-r from-sky-300 to-amber-700",
  "bg-gradient-to-r from-sky-300 to-amber-800",
  "bg-gradient-to-r from-sky-300 to-amber-900",
  "bg-gradient-to-r from-sky-300 to-amber-950",
  "bg-gradient-to-r from-sky-300 to-yellow-300",
  "bg-gradient-to-r from-sky-300 to-yellow-400",
  "bg-gradient-to-r from-sky-300 to-yellow-500",
  "bg-gradient-to-r from-sky-300 to-yellow-600",
  "bg-gradient-to-r from-sky-300 to-yellow-700",
  "bg-gradient-to-r from-sky-300 to-yellow-800",
  "bg-gradient-to-r from-sky-300 to-yellow-900",
  "bg-gradient-to-r from-sky-300 to-yellow-950",
  "bg-gradient-to-r from-sky-300 to-lime-300",
  "bg-gradient-to-r from-sky-300 to-lime-400",
  "bg-gradient-to-r from-sky-300 to-lime-500",
  "bg-gradient-to-r from-sky-300 to-lime-600",
  "bg-gradient-to-r from-sky-300 to-lime-700",
  "bg-gradient-to-r from-sky-300 to-lime-800",
  "bg-gradient-to-r from-sky-300 to-lime-900",
  "bg-gradient-to-r from-sky-300 to-lime-950",
  "bg-gradient-to-r from-sky-300 to-green-300",
  "bg-gradient-to-r from-sky-300 to-green-400",
  "bg-gradient-to-r from-sky-300 to-green-500",
  "bg-gradient-to-r from-sky-300 to-green-600",
  "bg-gradient-to-r from-sky-300 to-green-700",
  "bg-gradient-to-r from-sky-300 to-green-800",
  "bg-gradient-to-r from-sky-300 to-green-900",
  "bg-gradient-to-r from-sky-300 to-green-950",
  "bg-gradient-to-r from-sky-300 to-emerald-300",
  "bg-gradient-to-r from-sky-300 to-emerald-400",
  "bg-gradient-to-r from-sky-300 to-emerald-500",
  "bg-gradient-to-r from-sky-300 to-emerald-600",
  "bg-gradient-to-r from-sky-300 to-emerald-700",
  "bg-gradient-to-r from-sky-300 to-emerald-800",
  "bg-gradient-to-r from-sky-300 to-emerald-900",
  "bg-gradient-to-r from-sky-300 to-emerald-950",
  "bg-gradient-to-r from-sky-300 to-teal-300",
  "bg-gradient-to-r from-sky-300 to-teal-400",
  "bg-gradient-to-r from-sky-300 to-teal-500",
  "bg-gradient-to-r from-sky-300 to-teal-600",
  "bg-gradient-to-r from-sky-300 to-teal-700",
  "bg-gradient-to-r from-sky-300 to-teal-800",
  "bg-gradient-to-r from-sky-300 to-teal-900",
  "bg-gradient-to-r from-sky-300 to-teal-950",
  "bg-gradient-to-r from-sky-300 to-cyan-300",
  "bg-gradient-to-r from-sky-300 to-cyan-400",
  "bg-gradient-to-r from-sky-300 to-cyan-500",
  "bg-gradient-to-r from-sky-300 to-cyan-600",
  "bg-gradient-to-r from-sky-300 to-cyan-700",
  "bg-gradient-to-r from-sky-300 to-cyan-800",
  "bg-gradient-to-r from-sky-300 to-cyan-900",
  "bg-gradient-to-r from-sky-300 to-cyan-950",
  "bg-gradient-to-r from-sky-300 to-blue-300",
  "bg-gradient-to-r from-sky-300 to-blue-400",
  "bg-gradient-to-r from-sky-300 to-blue-500",
  "bg-gradient-to-r from-sky-300 to-blue-600",
  "bg-gradient-to-r from-sky-300 to-blue-700",
  "bg-gradient-to-r from-sky-300 to-blue-800",
  "bg-gradient-to-r from-sky-300 to-blue-900",
  "bg-gradient-to-r from-sky-300 to-blue-950",
  "bg-gradient-to-r from-sky-300 to-indigo-300",
  "bg-gradient-to-r from-sky-300 to-indigo-400",
  "bg-gradient-to-r from-sky-300 to-indigo-500",
  "bg-gradient-to-r from-sky-300 to-indigo-600",
  "bg-gradient-to-r from-sky-300 to-indigo-700",
  "bg-gradient-to-r from-sky-300 to-indigo-800",
  "bg-gradient-to-r from-sky-300 to-indigo-900",
  "bg-gradient-to-r from-sky-300 to-indigo-950",
  "bg-gradient-to-r from-sky-300 to-violet-300",
  "bg-gradient-to-r from-sky-300 to-violet-400",
  "bg-gradient-to-r from-sky-300 to-violet-500",
  "bg-gradient-to-r from-sky-300 to-violet-600",
  "bg-gradient-to-r from-sky-300 to-violet-700",
  "bg-gradient-to-r from-sky-300 to-violet-800",
  "bg-gradient-to-r from-sky-300 to-violet-900",
  "bg-gradient-to-r from-sky-300 to-violet-950",
  "bg-gradient-to-r from-sky-300 to-purple-300",
  "bg-gradient-to-r from-sky-300 to-purple-400",
  "bg-gradient-to-r from-sky-300 to-purple-500",
  "bg-gradient-to-r from-sky-300 to-purple-600",
  "bg-gradient-to-r from-sky-300 to-purple-700",
  "bg-gradient-to-r from-sky-300 to-purple-800",
  "bg-gradient-to-r from-sky-300 to-purple-900",
  "bg-gradient-to-r from-sky-300 to-purple-950",
  "bg-gradient-to-r from-sky-300 to-fuchsia-300",
  "bg-gradient-to-r from-sky-300 to-fuchsia-400",
  "bg-gradient-to-r from-sky-300 to-fuchsia-500",
  "bg-gradient-to-r from-sky-300 to-fuchsia-600",
  "bg-gradient-to-r from-sky-300 to-fuchsia-700",
  "bg-gradient-to-r from-sky-300 to-fuchsia-800",
  "bg-gradient-to-r from-sky-300 to-fuchsia-900",
  "bg-gradient-to-r from-sky-300 to-fuchsia-950",
  "bg-gradient-to-r from-sky-300 to-pink-300",
  "bg-gradient-to-r from-sky-300 to-pink-400",
  "bg-gradient-to-r from-sky-300 to-pink-500",
  "bg-gradient-to-r from-sky-300 to-pink-600",
  "bg-gradient-to-r from-sky-300 to-pink-700",
  "bg-gradient-to-r from-sky-300 to-pink-800",
  "bg-gradient-to-r from-sky-300 to-pink-900",
  "bg-gradient-to-r from-sky-300 to-pink-950",
  "bg-gradient-to-r from-sky-300 to-rose-300",
  "bg-gradient-to-r from-sky-300 to-rose-400",
  "bg-gradient-to-r from-sky-300 to-rose-500",
  "bg-gradient-to-r from-sky-300 to-rose-600",
  "bg-gradient-to-r from-sky-300 to-rose-700",
  "bg-gradient-to-r from-sky-300 to-rose-800",
  "bg-gradient-to-r from-sky-300 to-rose-900",
  "bg-gradient-to-r from-sky-300 to-rose-950",
  "bg-gradient-to-r from-sky-400 to-red-300",
  "bg-gradient-to-r from-sky-400 to-red-400",
  "bg-gradient-to-r from-sky-400 to-red-500",
  "bg-gradient-to-r from-sky-400 to-red-600",
  "bg-gradient-to-r from-sky-400 to-red-700",
  "bg-gradient-to-r from-sky-400 to-red-800",
  "bg-gradient-to-r from-sky-400 to-red-900",
  "bg-gradient-to-r from-sky-400 to-red-950",
  "bg-gradient-to-r from-sky-400 to-orange-300",
  "bg-gradient-to-r from-sky-400 to-orange-400",
  "bg-gradient-to-r from-sky-400 to-orange-500",
  "bg-gradient-to-r from-sky-400 to-orange-600",
  "bg-gradient-to-r from-sky-400 to-orange-700",
  "bg-gradient-to-r from-sky-400 to-orange-800",
  "bg-gradient-to-r from-sky-400 to-orange-900",
  "bg-gradient-to-r from-sky-400 to-orange-950",
  "bg-gradient-to-r from-sky-400 to-amber-300",
  "bg-gradient-to-r from-sky-400 to-amber-400",
  "bg-gradient-to-r from-sky-400 to-amber-500",
  "bg-gradient-to-r from-sky-400 to-amber-600",
  "bg-gradient-to-r from-sky-400 to-amber-700",
  "bg-gradient-to-r from-sky-400 to-amber-800",
  "bg-gradient-to-r from-sky-400 to-amber-900",
  "bg-gradient-to-r from-sky-400 to-amber-950",
  "bg-gradient-to-r from-sky-400 to-yellow-300",
  "bg-gradient-to-r from-sky-400 to-yellow-400",
  "bg-gradient-to-r from-sky-400 to-yellow-500",
  "bg-gradient-to-r from-sky-400 to-yellow-600",
  "bg-gradient-to-r from-sky-400 to-yellow-700",
  "bg-gradient-to-r from-sky-400 to-yellow-800",
  "bg-gradient-to-r from-sky-400 to-yellow-900",
  "bg-gradient-to-r from-sky-400 to-yellow-950",
  "bg-gradient-to-r from-sky-400 to-lime-300",
  "bg-gradient-to-r from-sky-400 to-lime-400",
  "bg-gradient-to-r from-sky-400 to-lime-500",
  "bg-gradient-to-r from-sky-400 to-lime-600",
  "bg-gradient-to-r from-sky-400 to-lime-700",
  "bg-gradient-to-r from-sky-400 to-lime-800",
  "bg-gradient-to-r from-sky-400 to-lime-900",
  "bg-gradient-to-r from-sky-400 to-lime-950",
  "bg-gradient-to-r from-sky-400 to-green-300",
  "bg-gradient-to-r from-sky-400 to-green-400",
  "bg-gradient-to-r from-sky-400 to-green-500",
  "bg-gradient-to-r from-sky-400 to-green-600",
  "bg-gradient-to-r from-sky-400 to-green-700",
  "bg-gradient-to-r from-sky-400 to-green-800",
  "bg-gradient-to-r from-sky-400 to-green-900",
  "bg-gradient-to-r from-sky-400 to-green-950",
  "bg-gradient-to-r from-sky-400 to-emerald-300",
  "bg-gradient-to-r from-sky-400 to-emerald-400",
  "bg-gradient-to-r from-sky-400 to-emerald-500",
  "bg-gradient-to-r from-sky-400 to-emerald-600",
  "bg-gradient-to-r from-sky-400 to-emerald-700",
  "bg-gradient-to-r from-sky-400 to-emerald-800",
  "bg-gradient-to-r from-sky-400 to-emerald-900",
  "bg-gradient-to-r from-sky-400 to-emerald-950",
  "bg-gradient-to-r from-sky-400 to-teal-300",
  "bg-gradient-to-r from-sky-400 to-teal-400",
  "bg-gradient-to-r from-sky-400 to-teal-500",
  "bg-gradient-to-r from-sky-400 to-teal-600",
  "bg-gradient-to-r from-sky-400 to-teal-700",
  "bg-gradient-to-r from-sky-400 to-teal-800",
  "bg-gradient-to-r from-sky-400 to-teal-900",
  "bg-gradient-to-r from-sky-400 to-teal-950",
  "bg-gradient-to-r from-sky-400 to-cyan-300",
  "bg-gradient-to-r from-sky-400 to-cyan-400",
  "bg-gradient-to-r from-sky-400 to-cyan-500",
  "bg-gradient-to-r from-sky-400 to-cyan-600",
  "bg-gradient-to-r from-sky-400 to-cyan-700",
  "bg-gradient-to-r from-sky-400 to-cyan-800",
  "bg-gradient-to-r from-sky-400 to-cyan-900",
  "bg-gradient-to-r from-sky-400 to-cyan-950",
  "bg-gradient-to-r from-sky-400 to-blue-300",
  "bg-gradient-to-r from-sky-400 to-blue-400",
  "bg-gradient-to-r from-sky-400 to-blue-500",
  "bg-gradient-to-r from-sky-400 to-blue-600",
  "bg-gradient-to-r from-sky-400 to-blue-700",
  "bg-gradient-to-r from-sky-400 to-blue-800",
  "bg-gradient-to-r from-sky-400 to-blue-900",
  "bg-gradient-to-r from-sky-400 to-blue-950",
  "bg-gradient-to-r from-sky-400 to-indigo-300",
  "bg-gradient-to-r from-sky-400 to-indigo-400",
  "bg-gradient-to-r from-sky-400 to-indigo-500",
  "bg-gradient-to-r from-sky-400 to-indigo-600",
  "bg-gradient-to-r from-sky-400 to-indigo-700",
  "bg-gradient-to-r from-sky-400 to-indigo-800",
  "bg-gradient-to-r from-sky-400 to-indigo-900",
  "bg-gradient-to-r from-sky-400 to-indigo-950",
  "bg-gradient-to-r from-sky-400 to-violet-300",
  "bg-gradient-to-r from-sky-400 to-violet-400",
  "bg-gradient-to-r from-sky-400 to-violet-500",
  "bg-gradient-to-r from-sky-400 to-violet-600",
  "bg-gradient-to-r from-sky-400 to-violet-700",
  "bg-gradient-to-r from-sky-400 to-violet-800",
  "bg-gradient-to-r from-sky-400 to-violet-900",
  "bg-gradient-to-r from-sky-400 to-violet-950",
  "bg-gradient-to-r from-sky-400 to-purple-300",
  "bg-gradient-to-r from-sky-400 to-purple-400",
  "bg-gradient-to-r from-sky-400 to-purple-500",
  "bg-gradient-to-r from-sky-400 to-purple-600",
  "bg-gradient-to-r from-sky-400 to-purple-700",
  "bg-gradient-to-r from-sky-400 to-purple-800",
  "bg-gradient-to-r from-sky-400 to-purple-900",
  "bg-gradient-to-r from-sky-400 to-purple-950",
  "bg-gradient-to-r from-sky-400 to-fuchsia-300",
  "bg-gradient-to-r from-sky-400 to-fuchsia-400",
  "bg-gradient-to-r from-sky-400 to-fuchsia-500",
  "bg-gradient-to-r from-sky-400 to-fuchsia-600",
  "bg-gradient-to-r from-sky-400 to-fuchsia-700",
  "bg-gradient-to-r from-sky-400 to-fuchsia-800",
  "bg-gradient-to-r from-sky-400 to-fuchsia-900",
  "bg-gradient-to-r from-sky-400 to-fuchsia-950",
  "bg-gradient-to-r from-sky-400 to-pink-300",
  "bg-gradient-to-r from-sky-400 to-pink-400",
  "bg-gradient-to-r from-sky-400 to-pink-500",
  "bg-gradient-to-r from-sky-400 to-pink-600",
  "bg-gradient-to-r from-sky-400 to-pink-700",
  "bg-gradient-to-r from-sky-400 to-pink-800",
  "bg-gradient-to-r from-sky-400 to-pink-900",
  "bg-gradient-to-r from-sky-400 to-pink-950",
  "bg-gradient-to-r from-sky-400 to-rose-300",
  "bg-gradient-to-r from-sky-400 to-rose-400",
  "bg-gradient-to-r from-sky-400 to-rose-500",
  "bg-gradient-to-r from-sky-400 to-rose-600",
  "bg-gradient-to-r from-sky-400 to-rose-700",
  "bg-gradient-to-r from-sky-400 to-rose-800",
  "bg-gradient-to-r from-sky-400 to-rose-900",
  "bg-gradient-to-r from-sky-400 to-rose-950",
  "bg-gradient-to-r from-sky-500 to-red-300",
  "bg-gradient-to-r from-sky-500 to-red-400",
  "bg-gradient-to-r from-sky-500 to-red-500",
  "bg-gradient-to-r from-sky-500 to-red-600",
  "bg-gradient-to-r from-sky-500 to-red-700",
  "bg-gradient-to-r from-sky-500 to-red-800",
  "bg-gradient-to-r from-sky-500 to-red-900",
  "bg-gradient-to-r from-sky-500 to-red-950",
  "bg-gradient-to-r from-sky-500 to-orange-300",
  "bg-gradient-to-r from-sky-500 to-orange-400",
  "bg-gradient-to-r from-sky-500 to-orange-500",
  "bg-gradient-to-r from-sky-500 to-orange-600",
  "bg-gradient-to-r from-sky-500 to-orange-700",
  "bg-gradient-to-r from-sky-500 to-orange-800",
  "bg-gradient-to-r from-sky-500 to-orange-900",
  "bg-gradient-to-r from-sky-500 to-orange-950",
  "bg-gradient-to-r from-sky-500 to-amber-300",
  "bg-gradient-to-r from-sky-500 to-amber-400",
  "bg-gradient-to-r from-sky-500 to-amber-500",
  "bg-gradient-to-r from-sky-500 to-amber-600",
  "bg-gradient-to-r from-sky-500 to-amber-700",
  "bg-gradient-to-r from-sky-500 to-amber-800",
  "bg-gradient-to-r from-sky-500 to-amber-900",
  "bg-gradient-to-r from-sky-500 to-amber-950",
  "bg-gradient-to-r from-sky-500 to-yellow-300",
  "bg-gradient-to-r from-sky-500 to-yellow-400",
  "bg-gradient-to-r from-sky-500 to-yellow-500",
  "bg-gradient-to-r from-sky-500 to-yellow-600",
  "bg-gradient-to-r from-sky-500 to-yellow-700",
  "bg-gradient-to-r from-sky-500 to-yellow-800",
  "bg-gradient-to-r from-sky-500 to-yellow-900",
  "bg-gradient-to-r from-sky-500 to-yellow-950",
  "bg-gradient-to-r from-sky-500 to-lime-300",
  "bg-gradient-to-r from-sky-500 to-lime-400",
  "bg-gradient-to-r from-sky-500 to-lime-500",
  "bg-gradient-to-r from-sky-500 to-lime-600",
  "bg-gradient-to-r from-sky-500 to-lime-700",
  "bg-gradient-to-r from-sky-500 to-lime-800",
  "bg-gradient-to-r from-sky-500 to-lime-900",
  "bg-gradient-to-r from-sky-500 to-lime-950",
  "bg-gradient-to-r from-sky-500 to-green-300",
  "bg-gradient-to-r from-sky-500 to-green-400",
  "bg-gradient-to-r from-sky-500 to-green-500",
  "bg-gradient-to-r from-sky-500 to-green-600",
  "bg-gradient-to-r from-sky-500 to-green-700",
  "bg-gradient-to-r from-sky-500 to-green-800",
  "bg-gradient-to-r from-sky-500 to-green-900",
  "bg-gradient-to-r from-sky-500 to-green-950",
  "bg-gradient-to-r from-sky-500 to-emerald-300",
  "bg-gradient-to-r from-sky-500 to-emerald-400",
  "bg-gradient-to-r from-sky-500 to-emerald-500",
  "bg-gradient-to-r from-sky-500 to-emerald-600",
  "bg-gradient-to-r from-sky-500 to-emerald-700",
  "bg-gradient-to-r from-sky-500 to-emerald-800",
  "bg-gradient-to-r from-sky-500 to-emerald-900",
  "bg-gradient-to-r from-sky-500 to-emerald-950",
  "bg-gradient-to-r from-sky-500 to-teal-300",
  "bg-gradient-to-r from-sky-500 to-teal-400",
  "bg-gradient-to-r from-sky-500 to-teal-500",
  "bg-gradient-to-r from-sky-500 to-teal-600",
  "bg-gradient-to-r from-sky-500 to-teal-700",
  "bg-gradient-to-r from-sky-500 to-teal-800",
  "bg-gradient-to-r from-sky-500 to-teal-900",
  "bg-gradient-to-r from-sky-500 to-teal-950",
  "bg-gradient-to-r from-sky-500 to-cyan-300",
  "bg-gradient-to-r from-sky-500 to-cyan-400",
  "bg-gradient-to-r from-sky-500 to-cyan-500",
  "bg-gradient-to-r from-sky-500 to-cyan-600",
  "bg-gradient-to-r from-sky-500 to-cyan-700",
  "bg-gradient-to-r from-sky-500 to-cyan-800",
  "bg-gradient-to-r from-sky-500 to-cyan-900",
  "bg-gradient-to-r from-sky-500 to-cyan-950",
  "bg-gradient-to-r from-sky-500 to-blue-300",
  "bg-gradient-to-r from-sky-500 to-blue-400",
  "bg-gradient-to-r from-sky-500 to-blue-500",
  "bg-gradient-to-r from-sky-500 to-blue-600",
  "bg-gradient-to-r from-sky-500 to-blue-700",
  "bg-gradient-to-r from-sky-500 to-blue-800",
  "bg-gradient-to-r from-sky-500 to-blue-900",
  "bg-gradient-to-r from-sky-500 to-blue-950",
  "bg-gradient-to-r from-sky-500 to-indigo-300",
  "bg-gradient-to-r from-sky-500 to-indigo-400",
  "bg-gradient-to-r from-sky-500 to-indigo-500",
  "bg-gradient-to-r from-sky-500 to-indigo-600",
  "bg-gradient-to-r from-sky-500 to-indigo-700",
  "bg-gradient-to-r from-sky-500 to-indigo-800",
  "bg-gradient-to-r from-sky-500 to-indigo-900",
  "bg-gradient-to-r from-sky-500 to-indigo-950",
  "bg-gradient-to-r from-sky-500 to-violet-300",
  "bg-gradient-to-r from-sky-500 to-violet-400",
  "bg-gradient-to-r from-sky-500 to-violet-500",
  "bg-gradient-to-r from-sky-500 to-violet-600",
  "bg-gradient-to-r from-sky-500 to-violet-700",
  "bg-gradient-to-r from-sky-500 to-violet-800",
  "bg-gradient-to-r from-sky-500 to-violet-900",
  "bg-gradient-to-r from-sky-500 to-violet-950",
  "bg-gradient-to-r from-sky-500 to-purple-300",
  "bg-gradient-to-r from-sky-500 to-purple-400",
  "bg-gradient-to-r from-sky-500 to-purple-500",
  "bg-gradient-to-r from-sky-500 to-purple-600",
  "bg-gradient-to-r from-sky-500 to-purple-700",
  "bg-gradient-to-r from-sky-500 to-purple-800",
  "bg-gradient-to-r from-sky-500 to-purple-900",
  "bg-gradient-to-r from-sky-500 to-purple-950",
  "bg-gradient-to-r from-sky-500 to-fuchsia-300",
  "bg-gradient-to-r from-sky-500 to-fuchsia-400",
  "bg-gradient-to-r from-sky-500 to-fuchsia-500",
  "bg-gradient-to-r from-sky-500 to-fuchsia-600",
  "bg-gradient-to-r from-sky-500 to-fuchsia-700",
  "bg-gradient-to-r from-sky-500 to-fuchsia-800",
  "bg-gradient-to-r from-sky-500 to-fuchsia-900",
  "bg-gradient-to-r from-sky-500 to-fuchsia-950",
  "bg-gradient-to-r from-sky-500 to-pink-300",
  "bg-gradient-to-r from-sky-500 to-pink-400",
  "bg-gradient-to-r from-sky-500 to-pink-500",
  "bg-gradient-to-r from-sky-500 to-pink-600",
  "bg-gradient-to-r from-sky-500 to-pink-700",
  "bg-gradient-to-r from-sky-500 to-pink-800",
  "bg-gradient-to-r from-sky-500 to-pink-900",
  "bg-gradient-to-r from-sky-500 to-pink-950",
  "bg-gradient-to-r from-sky-500 to-rose-300",
  "bg-gradient-to-r from-sky-500 to-rose-400",
  "bg-gradient-to-r from-sky-500 to-rose-500",
  "bg-gradient-to-r from-sky-500 to-rose-600",
  "bg-gradient-to-r from-sky-500 to-rose-700",
  "bg-gradient-to-r from-sky-500 to-rose-800",
  "bg-gradient-to-r from-sky-500 to-rose-900",
  "bg-gradient-to-r from-sky-500 to-rose-950",
  "bg-gradient-to-r from-sky-600 to-red-300",
  "bg-gradient-to-r from-sky-600 to-red-400",
  "bg-gradient-to-r from-sky-600 to-red-500",
  "bg-gradient-to-r from-sky-600 to-red-600",
  "bg-gradient-to-r from-sky-600 to-red-700",
  "bg-gradient-to-r from-sky-600 to-red-800",
  "bg-gradient-to-r from-sky-600 to-red-900",
  "bg-gradient-to-r from-sky-600 to-red-950",
  "bg-gradient-to-r from-sky-600 to-orange-300",
  "bg-gradient-to-r from-sky-600 to-orange-400",
  "bg-gradient-to-r from-sky-600 to-orange-500",
  "bg-gradient-to-r from-sky-600 to-orange-600",
  "bg-gradient-to-r from-sky-600 to-orange-700",
  "bg-gradient-to-r from-sky-600 to-orange-800",
  "bg-gradient-to-r from-sky-600 to-orange-900",
  "bg-gradient-to-r from-sky-600 to-orange-950",
  "bg-gradient-to-r from-sky-600 to-amber-300",
  "bg-gradient-to-r from-sky-600 to-amber-400",
  "bg-gradient-to-r from-sky-600 to-amber-500",
  "bg-gradient-to-r from-sky-600 to-amber-600",
  "bg-gradient-to-r from-sky-600 to-amber-700",
  "bg-gradient-to-r from-sky-600 to-amber-800",
  "bg-gradient-to-r from-sky-600 to-amber-900",
  "bg-gradient-to-r from-sky-600 to-amber-950",
  "bg-gradient-to-r from-sky-600 to-yellow-300",
  "bg-gradient-to-r from-sky-600 to-yellow-400",
  "bg-gradient-to-r from-sky-600 to-yellow-500",
  "bg-gradient-to-r from-sky-600 to-yellow-600",
  "bg-gradient-to-r from-sky-600 to-yellow-700",
  "bg-gradient-to-r from-sky-600 to-yellow-800",
  "bg-gradient-to-r from-sky-600 to-yellow-900",
  "bg-gradient-to-r from-sky-600 to-yellow-950",
  "bg-gradient-to-r from-sky-600 to-lime-300",
  "bg-gradient-to-r from-sky-600 to-lime-400",
  "bg-gradient-to-r from-sky-600 to-lime-500",
  "bg-gradient-to-r from-sky-600 to-lime-600",
  "bg-gradient-to-r from-sky-600 to-lime-700",
  "bg-gradient-to-r from-sky-600 to-lime-800",
  "bg-gradient-to-r from-sky-600 to-lime-900",
  "bg-gradient-to-r from-sky-600 to-lime-950",
  "bg-gradient-to-r from-sky-600 to-green-300",
  "bg-gradient-to-r from-sky-600 to-green-400",
  "bg-gradient-to-r from-sky-600 to-green-500",
  "bg-gradient-to-r from-sky-600 to-green-600",
  "bg-gradient-to-r from-sky-600 to-green-700",
  "bg-gradient-to-r from-sky-600 to-green-800",
  "bg-gradient-to-r from-sky-600 to-green-900",
  "bg-gradient-to-r from-sky-600 to-green-950",
  "bg-gradient-to-r from-sky-600 to-emerald-300",
  "bg-gradient-to-r from-sky-600 to-emerald-400",
  "bg-gradient-to-r from-sky-600 to-emerald-500",
  "bg-gradient-to-r from-sky-600 to-emerald-600",
  "bg-gradient-to-r from-sky-600 to-emerald-700",
  "bg-gradient-to-r from-sky-600 to-emerald-800",
  "bg-gradient-to-r from-sky-600 to-emerald-900",
  "bg-gradient-to-r from-sky-600 to-emerald-950",
  "bg-gradient-to-r from-sky-600 to-teal-300",
  "bg-gradient-to-r from-sky-600 to-teal-400",
  "bg-gradient-to-r from-sky-600 to-teal-500",
  "bg-gradient-to-r from-sky-600 to-teal-600",
  "bg-gradient-to-r from-sky-600 to-teal-700",
  "bg-gradient-to-r from-sky-600 to-teal-800",
  "bg-gradient-to-r from-sky-600 to-teal-900",
  "bg-gradient-to-r from-sky-600 to-teal-950",
  "bg-gradient-to-r from-sky-600 to-cyan-300",
  "bg-gradient-to-r from-sky-600 to-cyan-400",
  "bg-gradient-to-r from-sky-600 to-cyan-500",
  "bg-gradient-to-r from-sky-600 to-cyan-600",
  "bg-gradient-to-r from-sky-600 to-cyan-700",
  "bg-gradient-to-r from-sky-600 to-cyan-800",
  "bg-gradient-to-r from-sky-600 to-cyan-900",
  "bg-gradient-to-r from-sky-600 to-cyan-950",
  "bg-gradient-to-r from-sky-600 to-blue-300",
  "bg-gradient-to-r from-sky-600 to-blue-400",
  "bg-gradient-to-r from-sky-600 to-blue-500",
  "bg-gradient-to-r from-sky-600 to-blue-600",
  "bg-gradient-to-r from-sky-600 to-blue-700",
  "bg-gradient-to-r from-sky-600 to-blue-800",
  "bg-gradient-to-r from-sky-600 to-blue-900",
  "bg-gradient-to-r from-sky-600 to-blue-950",
  "bg-gradient-to-r from-sky-600 to-indigo-300",
  "bg-gradient-to-r from-sky-600 to-indigo-400",
  "bg-gradient-to-r from-sky-600 to-indigo-500",
  "bg-gradient-to-r from-sky-600 to-indigo-600",
  "bg-gradient-to-r from-sky-600 to-indigo-700",
  "bg-gradient-to-r from-sky-600 to-indigo-800",
  "bg-gradient-to-r from-sky-600 to-indigo-900",
  "bg-gradient-to-r from-sky-600 to-indigo-950",
  "bg-gradient-to-r from-sky-600 to-violet-300",
  "bg-gradient-to-r from-sky-600 to-violet-400",
  "bg-gradient-to-r from-sky-600 to-violet-500",
  "bg-gradient-to-r from-sky-600 to-violet-600",
  "bg-gradient-to-r from-sky-600 to-violet-700",
  "bg-gradient-to-r from-sky-600 to-violet-800",
  "bg-gradient-to-r from-sky-600 to-violet-900",
  "bg-gradient-to-r from-sky-600 to-violet-950",
  "bg-gradient-to-r from-sky-600 to-purple-300",
  "bg-gradient-to-r from-sky-600 to-purple-400",
  "bg-gradient-to-r from-sky-600 to-purple-500",
  "bg-gradient-to-r from-sky-600 to-purple-600",
  "bg-gradient-to-r from-sky-600 to-purple-700",
  "bg-gradient-to-r from-sky-600 to-purple-800",
  "bg-gradient-to-r from-sky-600 to-purple-900",
  "bg-gradient-to-r from-sky-600 to-purple-950",
  "bg-gradient-to-r from-sky-600 to-fuchsia-300",
  "bg-gradient-to-r from-sky-600 to-fuchsia-400",
  "bg-gradient-to-r from-sky-600 to-fuchsia-500",
  "bg-gradient-to-r from-sky-600 to-fuchsia-600",
  "bg-gradient-to-r from-sky-600 to-fuchsia-700",
  "bg-gradient-to-r from-sky-600 to-fuchsia-800",
  "bg-gradient-to-r from-sky-600 to-fuchsia-900",
  "bg-gradient-to-r from-sky-600 to-fuchsia-950",
  "bg-gradient-to-r from-sky-600 to-pink-300",
  "bg-gradient-to-r from-sky-600 to-pink-400",
  "bg-gradient-to-r from-sky-600 to-pink-500",
  "bg-gradient-to-r from-sky-600 to-pink-600",
  "bg-gradient-to-r from-sky-600 to-pink-700",
  "bg-gradient-to-r from-sky-600 to-pink-800",
  "bg-gradient-to-r from-sky-600 to-pink-900",
  "bg-gradient-to-r from-sky-600 to-pink-950",
  "bg-gradient-to-r from-sky-600 to-rose-300",
  "bg-gradient-to-r from-sky-600 to-rose-400",
  "bg-gradient-to-r from-sky-600 to-rose-500",
  "bg-gradient-to-r from-sky-600 to-rose-600",
  "bg-gradient-to-r from-sky-600 to-rose-700",
  "bg-gradient-to-r from-sky-600 to-rose-800",
  "bg-gradient-to-r from-sky-600 to-rose-900",
  "bg-gradient-to-r from-sky-600 to-rose-950",
  "bg-gradient-to-r from-sky-700 to-red-300",
  "bg-gradient-to-r from-sky-700 to-red-400",
  "bg-gradient-to-r from-sky-700 to-red-500",
  "bg-gradient-to-r from-sky-700 to-red-600",
  "bg-gradient-to-r from-sky-700 to-red-700",
  "bg-gradient-to-r from-sky-700 to-red-800",
  "bg-gradient-to-r from-sky-700 to-red-900",
  "bg-gradient-to-r from-sky-700 to-red-950",
  "bg-gradient-to-r from-sky-700 to-orange-300",
  "bg-gradient-to-r from-sky-700 to-orange-400",
  "bg-gradient-to-r from-sky-700 to-orange-500",
  "bg-gradient-to-r from-sky-700 to-orange-600",
  "bg-gradient-to-r from-sky-700 to-orange-700",
  "bg-gradient-to-r from-sky-700 to-orange-800",
  "bg-gradient-to-r from-sky-700 to-orange-900",
  "bg-gradient-to-r from-sky-700 to-orange-950",
  "bg-gradient-to-r from-sky-700 to-amber-300",
  "bg-gradient-to-r from-sky-700 to-amber-400",
  "bg-gradient-to-r from-sky-700 to-amber-500",
  "bg-gradient-to-r from-sky-700 to-amber-600",
  "bg-gradient-to-r from-sky-700 to-amber-700",
  "bg-gradient-to-r from-sky-700 to-amber-800",
  "bg-gradient-to-r from-sky-700 to-amber-900",
  "bg-gradient-to-r from-sky-700 to-amber-950",
  "bg-gradient-to-r from-sky-700 to-yellow-300",
  "bg-gradient-to-r from-sky-700 to-yellow-400",
  "bg-gradient-to-r from-sky-700 to-yellow-500",
  "bg-gradient-to-r from-sky-700 to-yellow-600",
  "bg-gradient-to-r from-sky-700 to-yellow-700",
  "bg-gradient-to-r from-sky-700 to-yellow-800",
  "bg-gradient-to-r from-sky-700 to-yellow-900",
  "bg-gradient-to-r from-sky-700 to-yellow-950",
  "bg-gradient-to-r from-sky-700 to-lime-300",
  "bg-gradient-to-r from-sky-700 to-lime-400",
  "bg-gradient-to-r from-sky-700 to-lime-500",
  "bg-gradient-to-r from-sky-700 to-lime-600",
  "bg-gradient-to-r from-sky-700 to-lime-700",
  "bg-gradient-to-r from-sky-700 to-lime-800",
  "bg-gradient-to-r from-sky-700 to-lime-900",
  "bg-gradient-to-r from-sky-700 to-lime-950",
  "bg-gradient-to-r from-sky-700 to-green-300",
  "bg-gradient-to-r from-sky-700 to-green-400",
  "bg-gradient-to-r from-sky-700 to-green-500",
  "bg-gradient-to-r from-sky-700 to-green-600",
  "bg-gradient-to-r from-sky-700 to-green-700",
  "bg-gradient-to-r from-sky-700 to-green-800",
  "bg-gradient-to-r from-sky-700 to-green-900",
  "bg-gradient-to-r from-sky-700 to-green-950",
  "bg-gradient-to-r from-sky-700 to-emerald-300",
  "bg-gradient-to-r from-sky-700 to-emerald-400",
  "bg-gradient-to-r from-sky-700 to-emerald-500",
  "bg-gradient-to-r from-sky-700 to-emerald-600",
  "bg-gradient-to-r from-sky-700 to-emerald-700",
  "bg-gradient-to-r from-sky-700 to-emerald-800",
  "bg-gradient-to-r from-sky-700 to-emerald-900",
  "bg-gradient-to-r from-sky-700 to-emerald-950",
  "bg-gradient-to-r from-sky-700 to-teal-300",
  "bg-gradient-to-r from-sky-700 to-teal-400",
  "bg-gradient-to-r from-sky-700 to-teal-500",
  "bg-gradient-to-r from-sky-700 to-teal-600",
  "bg-gradient-to-r from-sky-700 to-teal-700",
  "bg-gradient-to-r from-sky-700 to-teal-800",
  "bg-gradient-to-r from-sky-700 to-teal-900",
  "bg-gradient-to-r from-sky-700 to-teal-950",
  "bg-gradient-to-r from-sky-700 to-cyan-300",
  "bg-gradient-to-r from-sky-700 to-cyan-400",
  "bg-gradient-to-r from-sky-700 to-cyan-500",
  "bg-gradient-to-r from-sky-700 to-cyan-600",
  "bg-gradient-to-r from-sky-700 to-cyan-700",
  "bg-gradient-to-r from-sky-700 to-cyan-800",
  "bg-gradient-to-r from-sky-700 to-cyan-900",
  "bg-gradient-to-r from-sky-700 to-cyan-950",
  "bg-gradient-to-r from-sky-700 to-blue-300",
  "bg-gradient-to-r from-sky-700 to-blue-400",
  "bg-gradient-to-r from-sky-700 to-blue-500",
  "bg-gradient-to-r from-sky-700 to-blue-600",
  "bg-gradient-to-r from-sky-700 to-blue-700",
  "bg-gradient-to-r from-sky-700 to-blue-800",
  "bg-gradient-to-r from-sky-700 to-blue-900",
  "bg-gradient-to-r from-sky-700 to-blue-950",
  "bg-gradient-to-r from-sky-700 to-indigo-300",
  "bg-gradient-to-r from-sky-700 to-indigo-400",
  "bg-gradient-to-r from-sky-700 to-indigo-500",
  "bg-gradient-to-r from-sky-700 to-indigo-600",
  "bg-gradient-to-r from-sky-700 to-indigo-700",
  "bg-gradient-to-r from-sky-700 to-indigo-800",
  "bg-gradient-to-r from-sky-700 to-indigo-900",
  "bg-gradient-to-r from-sky-700 to-indigo-950",
  "bg-gradient-to-r from-sky-700 to-violet-300",
  "bg-gradient-to-r from-sky-700 to-violet-400",
  "bg-gradient-to-r from-sky-700 to-violet-500",
  "bg-gradient-to-r from-sky-700 to-violet-600",
  "bg-gradient-to-r from-sky-700 to-violet-700",
  "bg-gradient-to-r from-sky-700 to-violet-800",
  "bg-gradient-to-r from-sky-700 to-violet-900",
  "bg-gradient-to-r from-sky-700 to-violet-950",
  "bg-gradient-to-r from-sky-700 to-purple-300",
  "bg-gradient-to-r from-sky-700 to-purple-400",
  "bg-gradient-to-r from-sky-700 to-purple-500",
  "bg-gradient-to-r from-sky-700 to-purple-600",
  "bg-gradient-to-r from-sky-700 to-purple-700",
  "bg-gradient-to-r from-sky-700 to-purple-800",
  "bg-gradient-to-r from-sky-700 to-purple-900",
  "bg-gradient-to-r from-sky-700 to-purple-950",
  "bg-gradient-to-r from-sky-700 to-fuchsia-300",
  "bg-gradient-to-r from-sky-700 to-fuchsia-400",
  "bg-gradient-to-r from-sky-700 to-fuchsia-500",
  "bg-gradient-to-r from-sky-700 to-fuchsia-600",
  "bg-gradient-to-r from-sky-700 to-fuchsia-700",
  "bg-gradient-to-r from-sky-700 to-fuchsia-800",
  "bg-gradient-to-r from-sky-700 to-fuchsia-900",
  "bg-gradient-to-r from-sky-700 to-fuchsia-950",
  "bg-gradient-to-r from-sky-700 to-pink-300",
  "bg-gradient-to-r from-sky-700 to-pink-400",
  "bg-gradient-to-r from-sky-700 to-pink-500",
  "bg-gradient-to-r from-sky-700 to-pink-600",
  "bg-gradient-to-r from-sky-700 to-pink-700",
  "bg-gradient-to-r from-sky-700 to-pink-800",
  "bg-gradient-to-r from-sky-700 to-pink-900",
  "bg-gradient-to-r from-sky-700 to-pink-950",
  "bg-gradient-to-r from-sky-700 to-rose-300",
  "bg-gradient-to-r from-sky-700 to-rose-400",
  "bg-gradient-to-r from-sky-700 to-rose-500",
  "bg-gradient-to-r from-sky-700 to-rose-600",
  "bg-gradient-to-r from-sky-700 to-rose-700",
  "bg-gradient-to-r from-sky-700 to-rose-800",
  "bg-gradient-to-r from-sky-700 to-rose-900",
  "bg-gradient-to-r from-sky-700 to-rose-950",
  "bg-gradient-to-r from-sky-800 to-red-300",
  "bg-gradient-to-r from-sky-800 to-red-400",
  "bg-gradient-to-r from-sky-800 to-red-500",
  "bg-gradient-to-r from-sky-800 to-red-600",
  "bg-gradient-to-r from-sky-800 to-red-700",
  "bg-gradient-to-r from-sky-800 to-red-800",
  "bg-gradient-to-r from-sky-800 to-red-900",
  "bg-gradient-to-r from-sky-800 to-red-950",
  "bg-gradient-to-r from-sky-800 to-orange-300",
  "bg-gradient-to-r from-sky-800 to-orange-400",
  "bg-gradient-to-r from-sky-800 to-orange-500",
  "bg-gradient-to-r from-sky-800 to-orange-600",
  "bg-gradient-to-r from-sky-800 to-orange-700",
  "bg-gradient-to-r from-sky-800 to-orange-800",
  "bg-gradient-to-r from-sky-800 to-orange-900",
  "bg-gradient-to-r from-sky-800 to-orange-950",
  "bg-gradient-to-r from-sky-800 to-amber-300",
  "bg-gradient-to-r from-sky-800 to-amber-400",
  "bg-gradient-to-r from-sky-800 to-amber-500",
  "bg-gradient-to-r from-sky-800 to-amber-600",
  "bg-gradient-to-r from-sky-800 to-amber-700",
  "bg-gradient-to-r from-sky-800 to-amber-800",
  "bg-gradient-to-r from-sky-800 to-amber-900",
  "bg-gradient-to-r from-sky-800 to-amber-950",
  "bg-gradient-to-r from-sky-800 to-yellow-300",
  "bg-gradient-to-r from-sky-800 to-yellow-400",
  "bg-gradient-to-r from-sky-800 to-yellow-500",
  "bg-gradient-to-r from-sky-800 to-yellow-600",
  "bg-gradient-to-r from-sky-800 to-yellow-700",
  "bg-gradient-to-r from-sky-800 to-yellow-800",
  "bg-gradient-to-r from-sky-800 to-yellow-900",
  "bg-gradient-to-r from-sky-800 to-yellow-950",
  "bg-gradient-to-r from-sky-800 to-lime-300",
  "bg-gradient-to-r from-sky-800 to-lime-400",
  "bg-gradient-to-r from-sky-800 to-lime-500",
  "bg-gradient-to-r from-sky-800 to-lime-600",
  "bg-gradient-to-r from-sky-800 to-lime-700",
  "bg-gradient-to-r from-sky-800 to-lime-800",
  "bg-gradient-to-r from-sky-800 to-lime-900",
  "bg-gradient-to-r from-sky-800 to-lime-950",
  "bg-gradient-to-r from-sky-800 to-green-300",
  "bg-gradient-to-r from-sky-800 to-green-400",
  "bg-gradient-to-r from-sky-800 to-green-500",
  "bg-gradient-to-r from-sky-800 to-green-600",
  "bg-gradient-to-r from-sky-800 to-green-700",
  "bg-gradient-to-r from-sky-800 to-green-800",
  "bg-gradient-to-r from-sky-800 to-green-900",
  "bg-gradient-to-r from-sky-800 to-green-950",
  "bg-gradient-to-r from-sky-800 to-emerald-300",
  "bg-gradient-to-r from-sky-800 to-emerald-400",
  "bg-gradient-to-r from-sky-800 to-emerald-500",
  "bg-gradient-to-r from-sky-800 to-emerald-600",
  "bg-gradient-to-r from-sky-800 to-emerald-700",
  "bg-gradient-to-r from-sky-800 to-emerald-800",
  "bg-gradient-to-r from-sky-800 to-emerald-900",
  "bg-gradient-to-r from-sky-800 to-emerald-950",
  "bg-gradient-to-r from-sky-800 to-teal-300",
  "bg-gradient-to-r from-sky-800 to-teal-400",
  "bg-gradient-to-r from-sky-800 to-teal-500",
  "bg-gradient-to-r from-sky-800 to-teal-600",
  "bg-gradient-to-r from-sky-800 to-teal-700",
  "bg-gradient-to-r from-sky-800 to-teal-800",
  "bg-gradient-to-r from-sky-800 to-teal-900",
  "bg-gradient-to-r from-sky-800 to-teal-950",
  "bg-gradient-to-r from-sky-800 to-cyan-300",
  "bg-gradient-to-r from-sky-800 to-cyan-400",
  "bg-gradient-to-r from-sky-800 to-cyan-500",
  "bg-gradient-to-r from-sky-800 to-cyan-600",
  "bg-gradient-to-r from-sky-800 to-cyan-700",
  "bg-gradient-to-r from-sky-800 to-cyan-800",
  "bg-gradient-to-r from-sky-800 to-cyan-900",
  "bg-gradient-to-r from-sky-800 to-cyan-950",
  "bg-gradient-to-r from-sky-800 to-blue-300",
  "bg-gradient-to-r from-sky-800 to-blue-400",
  "bg-gradient-to-r from-sky-800 to-blue-500",
  "bg-gradient-to-r from-sky-800 to-blue-600",
  "bg-gradient-to-r from-sky-800 to-blue-700",
  "bg-gradient-to-r from-sky-800 to-blue-800",
  "bg-gradient-to-r from-sky-800 to-blue-900",
  "bg-gradient-to-r from-sky-800 to-blue-950",
  "bg-gradient-to-r from-sky-800 to-indigo-300",
  "bg-gradient-to-r from-sky-800 to-indigo-400",
  "bg-gradient-to-r from-sky-800 to-indigo-500",
  "bg-gradient-to-r from-sky-800 to-indigo-600",
  "bg-gradient-to-r from-sky-800 to-indigo-700",
  "bg-gradient-to-r from-sky-800 to-indigo-800",
  "bg-gradient-to-r from-sky-800 to-indigo-900",
  "bg-gradient-to-r from-sky-800 to-indigo-950",
  "bg-gradient-to-r from-sky-800 to-violet-300",
  "bg-gradient-to-r from-sky-800 to-violet-400",
  "bg-gradient-to-r from-sky-800 to-violet-500",
  "bg-gradient-to-r from-sky-800 to-violet-600",
  "bg-gradient-to-r from-sky-800 to-violet-700",
  "bg-gradient-to-r from-sky-800 to-violet-800",
  "bg-gradient-to-r from-sky-800 to-violet-900",
  "bg-gradient-to-r from-sky-800 to-violet-950",
  "bg-gradient-to-r from-sky-800 to-purple-300",
  "bg-gradient-to-r from-sky-800 to-purple-400",
  "bg-gradient-to-r from-sky-800 to-purple-500",
  "bg-gradient-to-r from-sky-800 to-purple-600",
  "bg-gradient-to-r from-sky-800 to-purple-700",
  "bg-gradient-to-r from-sky-800 to-purple-800",
  "bg-gradient-to-r from-sky-800 to-purple-900",
  "bg-gradient-to-r from-sky-800 to-purple-950",
  "bg-gradient-to-r from-sky-800 to-fuchsia-300",
  "bg-gradient-to-r from-sky-800 to-fuchsia-400",
  "bg-gradient-to-r from-sky-800 to-fuchsia-500",
  "bg-gradient-to-r from-sky-800 to-fuchsia-600",
  "bg-gradient-to-r from-sky-800 to-fuchsia-700",
  "bg-gradient-to-r from-sky-800 to-fuchsia-800",
  "bg-gradient-to-r from-sky-800 to-fuchsia-900",
  "bg-gradient-to-r from-sky-800 to-fuchsia-950",
  "bg-gradient-to-r from-sky-800 to-pink-300",
  "bg-gradient-to-r from-sky-800 to-pink-400",
  "bg-gradient-to-r from-sky-800 to-pink-500",
  "bg-gradient-to-r from-sky-800 to-pink-600",
  "bg-gradient-to-r from-sky-800 to-pink-700",
  "bg-gradient-to-r from-sky-800 to-pink-800",
  "bg-gradient-to-r from-sky-800 to-pink-900",
  "bg-gradient-to-r from-sky-800 to-pink-950",
  "bg-gradient-to-r from-sky-800 to-rose-300",
  "bg-gradient-to-r from-sky-800 to-rose-400",
  "bg-gradient-to-r from-sky-800 to-rose-500",
  "bg-gradient-to-r from-sky-800 to-rose-600",
  "bg-gradient-to-r from-sky-800 to-rose-700",
  "bg-gradient-to-r from-sky-800 to-rose-800",
  "bg-gradient-to-r from-sky-800 to-rose-900",
  "bg-gradient-to-r from-sky-800 to-rose-950",
  "bg-gradient-to-r from-sky-900 to-red-300",
  "bg-gradient-to-r from-sky-900 to-red-400",
  "bg-gradient-to-r from-sky-900 to-red-500",
  "bg-gradient-to-r from-sky-900 to-red-600",
  "bg-gradient-to-r from-sky-900 to-red-700",
  "bg-gradient-to-r from-sky-900 to-red-800",
  "bg-gradient-to-r from-sky-900 to-red-900",
  "bg-gradient-to-r from-sky-900 to-red-950",
  "bg-gradient-to-r from-sky-900 to-orange-300",
  "bg-gradient-to-r from-sky-900 to-orange-400",
  "bg-gradient-to-r from-sky-900 to-orange-500",
  "bg-gradient-to-r from-sky-900 to-orange-600",
  "bg-gradient-to-r from-sky-900 to-orange-700",
  "bg-gradient-to-r from-sky-900 to-orange-800",
  "bg-gradient-to-r from-sky-900 to-orange-900",
  "bg-gradient-to-r from-sky-900 to-orange-950",
  "bg-gradient-to-r from-sky-900 to-amber-300",
  "bg-gradient-to-r from-sky-900 to-amber-400",
  "bg-gradient-to-r from-sky-900 to-amber-500",
  "bg-gradient-to-r from-sky-900 to-amber-600",
  "bg-gradient-to-r from-sky-900 to-amber-700",
  "bg-gradient-to-r from-sky-900 to-amber-800",
  "bg-gradient-to-r from-sky-900 to-amber-900",
  "bg-gradient-to-r from-sky-900 to-amber-950",
  "bg-gradient-to-r from-sky-900 to-yellow-300",
  "bg-gradient-to-r from-sky-900 to-yellow-400",
  "bg-gradient-to-r from-sky-900 to-yellow-500",
  "bg-gradient-to-r from-sky-900 to-yellow-600",
  "bg-gradient-to-r from-sky-900 to-yellow-700",
  "bg-gradient-to-r from-sky-900 to-yellow-800",
  "bg-gradient-to-r from-sky-900 to-yellow-900",
  "bg-gradient-to-r from-sky-900 to-yellow-950",
  "bg-gradient-to-r from-sky-900 to-lime-300",
  "bg-gradient-to-r from-sky-900 to-lime-400",
  "bg-gradient-to-r from-sky-900 to-lime-500",
  "bg-gradient-to-r from-sky-900 to-lime-600",
  "bg-gradient-to-r from-sky-900 to-lime-700",
  "bg-gradient-to-r from-sky-900 to-lime-800",
  "bg-gradient-to-r from-sky-900 to-lime-900",
  "bg-gradient-to-r from-sky-900 to-lime-950",
  "bg-gradient-to-r from-sky-900 to-green-300",
  "bg-gradient-to-r from-sky-900 to-green-400",
  "bg-gradient-to-r from-sky-900 to-green-500",
  "bg-gradient-to-r from-sky-900 to-green-600",
  "bg-gradient-to-r from-sky-900 to-green-700",
  "bg-gradient-to-r from-sky-900 to-green-800",
  "bg-gradient-to-r from-sky-900 to-green-900",
  "bg-gradient-to-r from-sky-900 to-green-950",
  "bg-gradient-to-r from-sky-900 to-emerald-300",
  "bg-gradient-to-r from-sky-900 to-emerald-400",
  "bg-gradient-to-r from-sky-900 to-emerald-500",
  "bg-gradient-to-r from-sky-900 to-emerald-600",
  "bg-gradient-to-r from-sky-900 to-emerald-700",
  "bg-gradient-to-r from-sky-900 to-emerald-800",
  "bg-gradient-to-r from-sky-900 to-emerald-900",
  "bg-gradient-to-r from-sky-900 to-emerald-950",
  "bg-gradient-to-r from-sky-900 to-teal-300",
  "bg-gradient-to-r from-sky-900 to-teal-400",
  "bg-gradient-to-r from-sky-900 to-teal-500",
  "bg-gradient-to-r from-sky-900 to-teal-600",
  "bg-gradient-to-r from-sky-900 to-teal-700",
  "bg-gradient-to-r from-sky-900 to-teal-800",
  "bg-gradient-to-r from-sky-900 to-teal-900",
  "bg-gradient-to-r from-sky-900 to-teal-950",
  "bg-gradient-to-r from-sky-900 to-cyan-300",
  "bg-gradient-to-r from-sky-900 to-cyan-400",
  "bg-gradient-to-r from-sky-900 to-cyan-500",
  "bg-gradient-to-r from-sky-900 to-cyan-600",
  "bg-gradient-to-r from-sky-900 to-cyan-700",
  "bg-gradient-to-r from-sky-900 to-cyan-800",
  "bg-gradient-to-r from-sky-900 to-cyan-900",
  "bg-gradient-to-r from-sky-900 to-cyan-950",
  "bg-gradient-to-r from-sky-900 to-blue-300",
  "bg-gradient-to-r from-sky-900 to-blue-400",
  "bg-gradient-to-r from-sky-900 to-blue-500",
  "bg-gradient-to-r from-sky-900 to-blue-600",
  "bg-gradient-to-r from-sky-900 to-blue-700",
  "bg-gradient-to-r from-sky-900 to-blue-800",
  "bg-gradient-to-r from-sky-900 to-blue-900",
  "bg-gradient-to-r from-sky-900 to-blue-950",
  "bg-gradient-to-r from-sky-900 to-indigo-300",
  "bg-gradient-to-r from-sky-900 to-indigo-400",
  "bg-gradient-to-r from-sky-900 to-indigo-500",
  "bg-gradient-to-r from-sky-900 to-indigo-600",
  "bg-gradient-to-r from-sky-900 to-indigo-700",
  "bg-gradient-to-r from-sky-900 to-indigo-800",
  "bg-gradient-to-r from-sky-900 to-indigo-900",
  "bg-gradient-to-r from-sky-900 to-indigo-950",
  "bg-gradient-to-r from-sky-900 to-violet-300",
  "bg-gradient-to-r from-sky-900 to-violet-400",
  "bg-gradient-to-r from-sky-900 to-violet-500",
  "bg-gradient-to-r from-sky-900 to-violet-600",
  "bg-gradient-to-r from-sky-900 to-violet-700",
  "bg-gradient-to-r from-sky-900 to-violet-800",
  "bg-gradient-to-r from-sky-900 to-violet-900",
  "bg-gradient-to-r from-sky-900 to-violet-950",
  "bg-gradient-to-r from-sky-900 to-purple-300",
  "bg-gradient-to-r from-sky-900 to-purple-400",
  "bg-gradient-to-r from-sky-900 to-purple-500",
  "bg-gradient-to-r from-sky-900 to-purple-600",
  "bg-gradient-to-r from-sky-900 to-purple-700",
  "bg-gradient-to-r from-sky-900 to-purple-800",
  "bg-gradient-to-r from-sky-900 to-purple-900",
  "bg-gradient-to-r from-sky-900 to-purple-950",
  "bg-gradient-to-r from-sky-900 to-fuchsia-300",
  "bg-gradient-to-r from-sky-900 to-fuchsia-400",
  "bg-gradient-to-r from-sky-900 to-fuchsia-500",
  "bg-gradient-to-r from-sky-900 to-fuchsia-600",
  "bg-gradient-to-r from-sky-900 to-fuchsia-700",
  "bg-gradient-to-r from-sky-900 to-fuchsia-800",
  "bg-gradient-to-r from-sky-900 to-fuchsia-900",
  "bg-gradient-to-r from-sky-900 to-fuchsia-950",
  "bg-gradient-to-r from-sky-900 to-pink-300",
  "bg-gradient-to-r from-sky-900 to-pink-400",
  "bg-gradient-to-r from-sky-900 to-pink-500",
  "bg-gradient-to-r from-sky-900 to-pink-600",
  "bg-gradient-to-r from-sky-900 to-pink-700",
  "bg-gradient-to-r from-sky-900 to-pink-800",
  "bg-gradient-to-r from-sky-900 to-pink-900",
  "bg-gradient-to-r from-sky-900 to-pink-950",
  "bg-gradient-to-r from-sky-900 to-rose-300",
  "bg-gradient-to-r from-sky-900 to-rose-400",
  "bg-gradient-to-r from-sky-900 to-rose-500",
  "bg-gradient-to-r from-sky-900 to-rose-600",
  "bg-gradient-to-r from-sky-900 to-rose-700",
  "bg-gradient-to-r from-sky-900 to-rose-800",
  "bg-gradient-to-r from-sky-900 to-rose-900",
  "bg-gradient-to-r from-sky-900 to-rose-950",
  "bg-gradient-to-r from-sky-950 to-red-300",
  "bg-gradient-to-r from-sky-950 to-red-400",
  "bg-gradient-to-r from-sky-950 to-red-500",
  "bg-gradient-to-r from-sky-950 to-red-600",
  "bg-gradient-to-r from-sky-950 to-red-700",
  "bg-gradient-to-r from-sky-950 to-red-800",
  "bg-gradient-to-r from-sky-950 to-red-900",
  "bg-gradient-to-r from-sky-950 to-red-950",
  "bg-gradient-to-r from-sky-950 to-orange-300",
  "bg-gradient-to-r from-sky-950 to-orange-400",
  "bg-gradient-to-r from-sky-950 to-orange-500",
  "bg-gradient-to-r from-sky-950 to-orange-600",
  "bg-gradient-to-r from-sky-950 to-orange-700",
  "bg-gradient-to-r from-sky-950 to-orange-800",
  "bg-gradient-to-r from-sky-950 to-orange-900",
  "bg-gradient-to-r from-sky-950 to-orange-950",
  "bg-gradient-to-r from-sky-950 to-amber-300",
  "bg-gradient-to-r from-sky-950 to-amber-400",
  "bg-gradient-to-r from-sky-950 to-amber-500",
  "bg-gradient-to-r from-sky-950 to-amber-600",
  "bg-gradient-to-r from-sky-950 to-amber-700",
  "bg-gradient-to-r from-sky-950 to-amber-800",
  "bg-gradient-to-r from-sky-950 to-amber-900",
  "bg-gradient-to-r from-sky-950 to-amber-950",
  "bg-gradient-to-r from-sky-950 to-yellow-300",
  "bg-gradient-to-r from-sky-950 to-yellow-400",
  "bg-gradient-to-r from-sky-950 to-yellow-500",
  "bg-gradient-to-r from-sky-950 to-yellow-600",
  "bg-gradient-to-r from-sky-950 to-yellow-700",
  "bg-gradient-to-r from-sky-950 to-yellow-800",
  "bg-gradient-to-r from-sky-950 to-yellow-900",
  "bg-gradient-to-r from-sky-950 to-yellow-950",
  "bg-gradient-to-r from-sky-950 to-lime-300",
  "bg-gradient-to-r from-sky-950 to-lime-400",
  "bg-gradient-to-r from-sky-950 to-lime-500",
  "bg-gradient-to-r from-sky-950 to-lime-600",
  "bg-gradient-to-r from-sky-950 to-lime-700",
  "bg-gradient-to-r from-sky-950 to-lime-800",
  "bg-gradient-to-r from-sky-950 to-lime-900",
  "bg-gradient-to-r from-sky-950 to-lime-950",
  "bg-gradient-to-r from-sky-950 to-green-300",
  "bg-gradient-to-r from-sky-950 to-green-400",
  "bg-gradient-to-r from-sky-950 to-green-500",
  "bg-gradient-to-r from-sky-950 to-green-600",
  "bg-gradient-to-r from-sky-950 to-green-700",
  "bg-gradient-to-r from-sky-950 to-green-800",
  "bg-gradient-to-r from-sky-950 to-green-900",
  "bg-gradient-to-r from-sky-950 to-green-950",
  "bg-gradient-to-r from-sky-950 to-emerald-300",
  "bg-gradient-to-r from-sky-950 to-emerald-400",
  "bg-gradient-to-r from-sky-950 to-emerald-500",
  "bg-gradient-to-r from-sky-950 to-emerald-600",
  "bg-gradient-to-r from-sky-950 to-emerald-700",
  "bg-gradient-to-r from-sky-950 to-emerald-800",
  "bg-gradient-to-r from-sky-950 to-emerald-900",
  "bg-gradient-to-r from-sky-950 to-emerald-950",
  "bg-gradient-to-r from-sky-950 to-teal-300",
  "bg-gradient-to-r from-sky-950 to-teal-400",
  "bg-gradient-to-r from-sky-950 to-teal-500",
  "bg-gradient-to-r from-sky-950 to-teal-600",
  "bg-gradient-to-r from-sky-950 to-teal-700",
  "bg-gradient-to-r from-sky-950 to-teal-800",
  "bg-gradient-to-r from-sky-950 to-teal-900",
  "bg-gradient-to-r from-sky-950 to-teal-950",
  "bg-gradient-to-r from-sky-950 to-cyan-300",
  "bg-gradient-to-r from-sky-950 to-cyan-400",
  "bg-gradient-to-r from-sky-950 to-cyan-500",
  "bg-gradient-to-r from-sky-950 to-cyan-600",
  "bg-gradient-to-r from-sky-950 to-cyan-700",
  "bg-gradient-to-r from-sky-950 to-cyan-800",
  "bg-gradient-to-r from-sky-950 to-cyan-900",
  "bg-gradient-to-r from-sky-950 to-cyan-950",
  "bg-gradient-to-r from-sky-950 to-blue-300",
  "bg-gradient-to-r from-sky-950 to-blue-400",
  "bg-gradient-to-r from-sky-950 to-blue-500",
  "bg-gradient-to-r from-sky-950 to-blue-600",
  "bg-gradient-to-r from-sky-950 to-blue-700",
  "bg-gradient-to-r from-sky-950 to-blue-800",
  "bg-gradient-to-r from-sky-950 to-blue-900",
  "bg-gradient-to-r from-sky-950 to-blue-950",
  "bg-gradient-to-r from-sky-950 to-indigo-300",
  "bg-gradient-to-r from-sky-950 to-indigo-400",
  "bg-gradient-to-r from-sky-950 to-indigo-500",
  "bg-gradient-to-r from-sky-950 to-indigo-600",
  "bg-gradient-to-r from-sky-950 to-indigo-700",
  "bg-gradient-to-r from-sky-950 to-indigo-800",
  "bg-gradient-to-r from-sky-950 to-indigo-900",
  "bg-gradient-to-r from-sky-950 to-indigo-950",
  "bg-gradient-to-r from-sky-950 to-violet-300",
  "bg-gradient-to-r from-sky-950 to-violet-400",
  "bg-gradient-to-r from-sky-950 to-violet-500",
  "bg-gradient-to-r from-sky-950 to-violet-600",
  "bg-gradient-to-r from-sky-950 to-violet-700",
  "bg-gradient-to-r from-sky-950 to-violet-800",
  "bg-gradient-to-r from-sky-950 to-violet-900",
  "bg-gradient-to-r from-sky-950 to-violet-950",
  "bg-gradient-to-r from-sky-950 to-purple-300",
  "bg-gradient-to-r from-sky-950 to-purple-400",
  "bg-gradient-to-r from-sky-950 to-purple-500",
  "bg-gradient-to-r from-sky-950 to-purple-600",
  "bg-gradient-to-r from-sky-950 to-purple-700",
  "bg-gradient-to-r from-sky-950 to-purple-800",
  "bg-gradient-to-r from-sky-950 to-purple-900",
  "bg-gradient-to-r from-sky-950 to-purple-950",
  "bg-gradient-to-r from-sky-950 to-fuchsia-300",
  "bg-gradient-to-r from-sky-950 to-fuchsia-400",
  "bg-gradient-to-r from-sky-950 to-fuchsia-500",
  "bg-gradient-to-r from-sky-950 to-fuchsia-600",
  "bg-gradient-to-r from-sky-950 to-fuchsia-700",
  "bg-gradient-to-r from-sky-950 to-fuchsia-800",
  "bg-gradient-to-r from-sky-950 to-fuchsia-900",
  "bg-gradient-to-r from-sky-950 to-fuchsia-950",
  "bg-gradient-to-r from-sky-950 to-pink-300",
  "bg-gradient-to-r from-sky-950 to-pink-400",
  "bg-gradient-to-r from-sky-950 to-pink-500",
  "bg-gradient-to-r from-sky-950 to-pink-600",
  "bg-gradient-to-r from-sky-950 to-pink-700",
  "bg-gradient-to-r from-sky-950 to-pink-800",
  "bg-gradient-to-r from-sky-950 to-pink-900",
  "bg-gradient-to-r from-sky-950 to-pink-950",
  "bg-gradient-to-r from-sky-950 to-rose-300",
  "bg-gradient-to-r from-sky-950 to-rose-400",
  "bg-gradient-to-r from-sky-950 to-rose-500",
  "bg-gradient-to-r from-sky-950 to-rose-600",
  "bg-gradient-to-r from-sky-950 to-rose-700",
  "bg-gradient-to-r from-sky-950 to-rose-800",
  "bg-gradient-to-r from-sky-950 to-rose-900",
  "bg-gradient-to-r from-sky-950 to-rose-950",
  "bg-gradient-to-r from-blue-300 to-red-300",
  "bg-gradient-to-r from-blue-300 to-red-400",
  "bg-gradient-to-r from-blue-300 to-red-500",
  "bg-gradient-to-r from-blue-300 to-red-600",
  "bg-gradient-to-r from-blue-300 to-red-700",
  "bg-gradient-to-r from-blue-300 to-red-800",
  "bg-gradient-to-r from-blue-300 to-red-900",
  "bg-gradient-to-r from-blue-300 to-red-950",
  "bg-gradient-to-r from-blue-300 to-orange-300",
  "bg-gradient-to-r from-blue-300 to-orange-400",
  "bg-gradient-to-r from-blue-300 to-orange-500",
  "bg-gradient-to-r from-blue-300 to-orange-600",
  "bg-gradient-to-r from-blue-300 to-orange-700",
  "bg-gradient-to-r from-blue-300 to-orange-800",
  "bg-gradient-to-r from-blue-300 to-orange-900",
  "bg-gradient-to-r from-blue-300 to-orange-950",
  "bg-gradient-to-r from-blue-300 to-amber-300",
  "bg-gradient-to-r from-blue-300 to-amber-400",
  "bg-gradient-to-r from-blue-300 to-amber-500",
  "bg-gradient-to-r from-blue-300 to-amber-600",
  "bg-gradient-to-r from-blue-300 to-amber-700",
  "bg-gradient-to-r from-blue-300 to-amber-800",
  "bg-gradient-to-r from-blue-300 to-amber-900",
  "bg-gradient-to-r from-blue-300 to-amber-950",
  "bg-gradient-to-r from-blue-300 to-yellow-300",
  "bg-gradient-to-r from-blue-300 to-yellow-400",
  "bg-gradient-to-r from-blue-300 to-yellow-500",
  "bg-gradient-to-r from-blue-300 to-yellow-600",
  "bg-gradient-to-r from-blue-300 to-yellow-700",
  "bg-gradient-to-r from-blue-300 to-yellow-800",
  "bg-gradient-to-r from-blue-300 to-yellow-900",
  "bg-gradient-to-r from-blue-300 to-yellow-950",
  "bg-gradient-to-r from-blue-300 to-lime-300",
  "bg-gradient-to-r from-blue-300 to-lime-400",
  "bg-gradient-to-r from-blue-300 to-lime-500",
  "bg-gradient-to-r from-blue-300 to-lime-600",
  "bg-gradient-to-r from-blue-300 to-lime-700",
  "bg-gradient-to-r from-blue-300 to-lime-800",
  "bg-gradient-to-r from-blue-300 to-lime-900",
  "bg-gradient-to-r from-blue-300 to-lime-950",
  "bg-gradient-to-r from-blue-300 to-green-300",
  "bg-gradient-to-r from-blue-300 to-green-400",
  "bg-gradient-to-r from-blue-300 to-green-500",
  "bg-gradient-to-r from-blue-300 to-green-600",
  "bg-gradient-to-r from-blue-300 to-green-700",
  "bg-gradient-to-r from-blue-300 to-green-800",
  "bg-gradient-to-r from-blue-300 to-green-900",
  "bg-gradient-to-r from-blue-300 to-green-950",
  "bg-gradient-to-r from-blue-300 to-emerald-300",
  "bg-gradient-to-r from-blue-300 to-emerald-400",
  "bg-gradient-to-r from-blue-300 to-emerald-500",
  "bg-gradient-to-r from-blue-300 to-emerald-600",
  "bg-gradient-to-r from-blue-300 to-emerald-700",
  "bg-gradient-to-r from-blue-300 to-emerald-800",
  "bg-gradient-to-r from-blue-300 to-emerald-900",
  "bg-gradient-to-r from-blue-300 to-emerald-950",
  "bg-gradient-to-r from-blue-300 to-teal-300",
  "bg-gradient-to-r from-blue-300 to-teal-400",
  "bg-gradient-to-r from-blue-300 to-teal-500",
  "bg-gradient-to-r from-blue-300 to-teal-600",
  "bg-gradient-to-r from-blue-300 to-teal-700",
  "bg-gradient-to-r from-blue-300 to-teal-800",
  "bg-gradient-to-r from-blue-300 to-teal-900",
  "bg-gradient-to-r from-blue-300 to-teal-950",
  "bg-gradient-to-r from-blue-300 to-cyan-300",
  "bg-gradient-to-r from-blue-300 to-cyan-400",
  "bg-gradient-to-r from-blue-300 to-cyan-500",
  "bg-gradient-to-r from-blue-300 to-cyan-600",
  "bg-gradient-to-r from-blue-300 to-cyan-700",
  "bg-gradient-to-r from-blue-300 to-cyan-800",
  "bg-gradient-to-r from-blue-300 to-cyan-900",
  "bg-gradient-to-r from-blue-300 to-cyan-950",
  "bg-gradient-to-r from-blue-300 to-sky-300",
  "bg-gradient-to-r from-blue-300 to-sky-400",
  "bg-gradient-to-r from-blue-300 to-sky-500",
  "bg-gradient-to-r from-blue-300 to-sky-600",
  "bg-gradient-to-r from-blue-300 to-sky-700",
  "bg-gradient-to-r from-blue-300 to-sky-800",
  "bg-gradient-to-r from-blue-300 to-sky-900",
  "bg-gradient-to-r from-blue-300 to-sky-950",
  "bg-gradient-to-r from-blue-300 to-indigo-300",
  "bg-gradient-to-r from-blue-300 to-indigo-400",
  "bg-gradient-to-r from-blue-300 to-indigo-500",
  "bg-gradient-to-r from-blue-300 to-indigo-600",
  "bg-gradient-to-r from-blue-300 to-indigo-700",
  "bg-gradient-to-r from-blue-300 to-indigo-800",
  "bg-gradient-to-r from-blue-300 to-indigo-900",
  "bg-gradient-to-r from-blue-300 to-indigo-950",
  "bg-gradient-to-r from-blue-300 to-violet-300",
  "bg-gradient-to-r from-blue-300 to-violet-400",
  "bg-gradient-to-r from-blue-300 to-violet-500",
  "bg-gradient-to-r from-blue-300 to-violet-600",
  "bg-gradient-to-r from-blue-300 to-violet-700",
  "bg-gradient-to-r from-blue-300 to-violet-800",
  "bg-gradient-to-r from-blue-300 to-violet-900",
  "bg-gradient-to-r from-blue-300 to-violet-950",
  "bg-gradient-to-r from-blue-300 to-purple-300",
  "bg-gradient-to-r from-blue-300 to-purple-400",
  "bg-gradient-to-r from-blue-300 to-purple-500",
  "bg-gradient-to-r from-blue-300 to-purple-600",
  "bg-gradient-to-r from-blue-300 to-purple-700",
  "bg-gradient-to-r from-blue-300 to-purple-800",
  "bg-gradient-to-r from-blue-300 to-purple-900",
  "bg-gradient-to-r from-blue-300 to-purple-950",
  "bg-gradient-to-r from-blue-300 to-fuchsia-300",
  "bg-gradient-to-r from-blue-300 to-fuchsia-400",
  "bg-gradient-to-r from-blue-300 to-fuchsia-500",
  "bg-gradient-to-r from-blue-300 to-fuchsia-600",
  "bg-gradient-to-r from-blue-300 to-fuchsia-700",
  "bg-gradient-to-r from-blue-300 to-fuchsia-800",
  "bg-gradient-to-r from-blue-300 to-fuchsia-900",
  "bg-gradient-to-r from-blue-300 to-fuchsia-950",
  "bg-gradient-to-r from-blue-300 to-pink-300",
  "bg-gradient-to-r from-blue-300 to-pink-400",
  "bg-gradient-to-r from-blue-300 to-pink-500",
  "bg-gradient-to-r from-blue-300 to-pink-600",
  "bg-gradient-to-r from-blue-300 to-pink-700",
  "bg-gradient-to-r from-blue-300 to-pink-800",
  "bg-gradient-to-r from-blue-300 to-pink-900",
  "bg-gradient-to-r from-blue-300 to-pink-950",
  "bg-gradient-to-r from-blue-300 to-rose-300",
  "bg-gradient-to-r from-blue-300 to-rose-400",
  "bg-gradient-to-r from-blue-300 to-rose-500",
  "bg-gradient-to-r from-blue-300 to-rose-600",
  "bg-gradient-to-r from-blue-300 to-rose-700",
  "bg-gradient-to-r from-blue-300 to-rose-800",
  "bg-gradient-to-r from-blue-300 to-rose-900",
  "bg-gradient-to-r from-blue-300 to-rose-950",
  "bg-gradient-to-r from-blue-400 to-red-300",
  "bg-gradient-to-r from-blue-400 to-red-400",
  "bg-gradient-to-r from-blue-400 to-red-500",
  "bg-gradient-to-r from-blue-400 to-red-600",
  "bg-gradient-to-r from-blue-400 to-red-700",
  "bg-gradient-to-r from-blue-400 to-red-800",
  "bg-gradient-to-r from-blue-400 to-red-900",
  "bg-gradient-to-r from-blue-400 to-red-950",
  "bg-gradient-to-r from-blue-400 to-orange-300",
  "bg-gradient-to-r from-blue-400 to-orange-400",
  "bg-gradient-to-r from-blue-400 to-orange-500",
  "bg-gradient-to-r from-blue-400 to-orange-600",
  "bg-gradient-to-r from-blue-400 to-orange-700",
  "bg-gradient-to-r from-blue-400 to-orange-800",
  "bg-gradient-to-r from-blue-400 to-orange-900",
  "bg-gradient-to-r from-blue-400 to-orange-950",
  "bg-gradient-to-r from-blue-400 to-amber-300",
  "bg-gradient-to-r from-blue-400 to-amber-400",
  "bg-gradient-to-r from-blue-400 to-amber-500",
  "bg-gradient-to-r from-blue-400 to-amber-600",
  "bg-gradient-to-r from-blue-400 to-amber-700",
  "bg-gradient-to-r from-blue-400 to-amber-800",
  "bg-gradient-to-r from-blue-400 to-amber-900",
  "bg-gradient-to-r from-blue-400 to-amber-950",
  "bg-gradient-to-r from-blue-400 to-yellow-300",
  "bg-gradient-to-r from-blue-400 to-yellow-400",
  "bg-gradient-to-r from-blue-400 to-yellow-500",
  "bg-gradient-to-r from-blue-400 to-yellow-600",
  "bg-gradient-to-r from-blue-400 to-yellow-700",
  "bg-gradient-to-r from-blue-400 to-yellow-800",
  "bg-gradient-to-r from-blue-400 to-yellow-900",
  "bg-gradient-to-r from-blue-400 to-yellow-950",
  "bg-gradient-to-r from-blue-400 to-lime-300",
  "bg-gradient-to-r from-blue-400 to-lime-400",
  "bg-gradient-to-r from-blue-400 to-lime-500",
  "bg-gradient-to-r from-blue-400 to-lime-600",
  "bg-gradient-to-r from-blue-400 to-lime-700",
  "bg-gradient-to-r from-blue-400 to-lime-800",
  "bg-gradient-to-r from-blue-400 to-lime-900",
  "bg-gradient-to-r from-blue-400 to-lime-950",
  "bg-gradient-to-r from-blue-400 to-green-300",
  "bg-gradient-to-r from-blue-400 to-green-400",
  "bg-gradient-to-r from-blue-400 to-green-500",
  "bg-gradient-to-r from-blue-400 to-green-600",
  "bg-gradient-to-r from-blue-400 to-green-700",
  "bg-gradient-to-r from-blue-400 to-green-800",
  "bg-gradient-to-r from-blue-400 to-green-900",
  "bg-gradient-to-r from-blue-400 to-green-950",
  "bg-gradient-to-r from-blue-400 to-emerald-300",
  "bg-gradient-to-r from-blue-400 to-emerald-400",
  "bg-gradient-to-r from-blue-400 to-emerald-500",
  "bg-gradient-to-r from-blue-400 to-emerald-600",
  "bg-gradient-to-r from-blue-400 to-emerald-700",
  "bg-gradient-to-r from-blue-400 to-emerald-800",
  "bg-gradient-to-r from-blue-400 to-emerald-900",
  "bg-gradient-to-r from-blue-400 to-emerald-950",
  "bg-gradient-to-r from-blue-400 to-teal-300",
  "bg-gradient-to-r from-blue-400 to-teal-400",
  "bg-gradient-to-r from-blue-400 to-teal-500",
  "bg-gradient-to-r from-blue-400 to-teal-600",
  "bg-gradient-to-r from-blue-400 to-teal-700",
  "bg-gradient-to-r from-blue-400 to-teal-800",
  "bg-gradient-to-r from-blue-400 to-teal-900",
  "bg-gradient-to-r from-blue-400 to-teal-950",
  "bg-gradient-to-r from-blue-400 to-cyan-300",
  "bg-gradient-to-r from-blue-400 to-cyan-400",
  "bg-gradient-to-r from-blue-400 to-cyan-500",
  "bg-gradient-to-r from-blue-400 to-cyan-600",
  "bg-gradient-to-r from-blue-400 to-cyan-700",
  "bg-gradient-to-r from-blue-400 to-cyan-800",
  "bg-gradient-to-r from-blue-400 to-cyan-900",
  "bg-gradient-to-r from-blue-400 to-cyan-950",
  "bg-gradient-to-r from-blue-400 to-sky-300",
  "bg-gradient-to-r from-blue-400 to-sky-400",
  "bg-gradient-to-r from-blue-400 to-sky-500",
  "bg-gradient-to-r from-blue-400 to-sky-600",
  "bg-gradient-to-r from-blue-400 to-sky-700",
  "bg-gradient-to-r from-blue-400 to-sky-800",
  "bg-gradient-to-r from-blue-400 to-sky-900",
  "bg-gradient-to-r from-blue-400 to-sky-950",
  "bg-gradient-to-r from-blue-400 to-indigo-300",
  "bg-gradient-to-r from-blue-400 to-indigo-400",
  "bg-gradient-to-r from-blue-400 to-indigo-500",
  "bg-gradient-to-r from-blue-400 to-indigo-600",
  "bg-gradient-to-r from-blue-400 to-indigo-700",
  "bg-gradient-to-r from-blue-400 to-indigo-800",
  "bg-gradient-to-r from-blue-400 to-indigo-900",
  "bg-gradient-to-r from-blue-400 to-indigo-950",
  "bg-gradient-to-r from-blue-400 to-violet-300",
  "bg-gradient-to-r from-blue-400 to-violet-400",
  "bg-gradient-to-r from-blue-400 to-violet-500",
  "bg-gradient-to-r from-blue-400 to-violet-600",
  "bg-gradient-to-r from-blue-400 to-violet-700",
  "bg-gradient-to-r from-blue-400 to-violet-800",
  "bg-gradient-to-r from-blue-400 to-violet-900",
  "bg-gradient-to-r from-blue-400 to-violet-950",
  "bg-gradient-to-r from-blue-400 to-purple-300",
  "bg-gradient-to-r from-blue-400 to-purple-400",
  "bg-gradient-to-r from-blue-400 to-purple-500",
  "bg-gradient-to-r from-blue-400 to-purple-600",
  "bg-gradient-to-r from-blue-400 to-purple-700",
  "bg-gradient-to-r from-blue-400 to-purple-800",
  "bg-gradient-to-r from-blue-400 to-purple-900",
  "bg-gradient-to-r from-blue-400 to-purple-950",
  "bg-gradient-to-r from-blue-400 to-fuchsia-300",
  "bg-gradient-to-r from-blue-400 to-fuchsia-400",
  "bg-gradient-to-r from-blue-400 to-fuchsia-500",
  "bg-gradient-to-r from-blue-400 to-fuchsia-600",
  "bg-gradient-to-r from-blue-400 to-fuchsia-700",
  "bg-gradient-to-r from-blue-400 to-fuchsia-800",
  "bg-gradient-to-r from-blue-400 to-fuchsia-900",
  "bg-gradient-to-r from-blue-400 to-fuchsia-950",
  "bg-gradient-to-r from-blue-400 to-pink-300",
  "bg-gradient-to-r from-blue-400 to-pink-400",
  "bg-gradient-to-r from-blue-400 to-pink-500",
  "bg-gradient-to-r from-blue-400 to-pink-600",
  "bg-gradient-to-r from-blue-400 to-pink-700",
  "bg-gradient-to-r from-blue-400 to-pink-800",
  "bg-gradient-to-r from-blue-400 to-pink-900",
  "bg-gradient-to-r from-blue-400 to-pink-950",
  "bg-gradient-to-r from-blue-400 to-rose-300",
  "bg-gradient-to-r from-blue-400 to-rose-400",
  "bg-gradient-to-r from-blue-400 to-rose-500",
  "bg-gradient-to-r from-blue-400 to-rose-600",
  "bg-gradient-to-r from-blue-400 to-rose-700",
  "bg-gradient-to-r from-blue-400 to-rose-800",
  "bg-gradient-to-r from-blue-400 to-rose-900",
  "bg-gradient-to-r from-blue-400 to-rose-950",
  "bg-gradient-to-r from-blue-500 to-red-300",
  "bg-gradient-to-r from-blue-500 to-red-400",
  "bg-gradient-to-r from-blue-500 to-red-500",
  "bg-gradient-to-r from-blue-500 to-red-600",
  "bg-gradient-to-r from-blue-500 to-red-700",
  "bg-gradient-to-r from-blue-500 to-red-800",
  "bg-gradient-to-r from-blue-500 to-red-900",
  "bg-gradient-to-r from-blue-500 to-red-950",
  "bg-gradient-to-r from-blue-500 to-orange-300",
  "bg-gradient-to-r from-blue-500 to-orange-400",
  "bg-gradient-to-r from-blue-500 to-orange-500",
  "bg-gradient-to-r from-blue-500 to-orange-600",
  "bg-gradient-to-r from-blue-500 to-orange-700",
  "bg-gradient-to-r from-blue-500 to-orange-800",
  "bg-gradient-to-r from-blue-500 to-orange-900",
  "bg-gradient-to-r from-blue-500 to-orange-950",
  "bg-gradient-to-r from-blue-500 to-amber-300",
  "bg-gradient-to-r from-blue-500 to-amber-400",
  "bg-gradient-to-r from-blue-500 to-amber-500",
  "bg-gradient-to-r from-blue-500 to-amber-600",
  "bg-gradient-to-r from-blue-500 to-amber-700",
  "bg-gradient-to-r from-blue-500 to-amber-800",
  "bg-gradient-to-r from-blue-500 to-amber-900",
  "bg-gradient-to-r from-blue-500 to-amber-950",
  "bg-gradient-to-r from-blue-500 to-yellow-300",
  "bg-gradient-to-r from-blue-500 to-yellow-400",
  "bg-gradient-to-r from-blue-500 to-yellow-500",
  "bg-gradient-to-r from-blue-500 to-yellow-600",
  "bg-gradient-to-r from-blue-500 to-yellow-700",
  "bg-gradient-to-r from-blue-500 to-yellow-800",
  "bg-gradient-to-r from-blue-500 to-yellow-900",
  "bg-gradient-to-r from-blue-500 to-yellow-950",
  "bg-gradient-to-r from-blue-500 to-lime-300",
  "bg-gradient-to-r from-blue-500 to-lime-400",
  "bg-gradient-to-r from-blue-500 to-lime-500",
  "bg-gradient-to-r from-blue-500 to-lime-600",
  "bg-gradient-to-r from-blue-500 to-lime-700",
  "bg-gradient-to-r from-blue-500 to-lime-800",
  "bg-gradient-to-r from-blue-500 to-lime-900",
  "bg-gradient-to-r from-blue-500 to-lime-950",
  "bg-gradient-to-r from-blue-500 to-green-300",
  "bg-gradient-to-r from-blue-500 to-green-400",
  "bg-gradient-to-r from-blue-500 to-green-500",
  "bg-gradient-to-r from-blue-500 to-green-600",
  "bg-gradient-to-r from-blue-500 to-green-700",
  "bg-gradient-to-r from-blue-500 to-green-800",
  "bg-gradient-to-r from-blue-500 to-green-900",
  "bg-gradient-to-r from-blue-500 to-green-950",
  "bg-gradient-to-r from-blue-500 to-emerald-300",
  "bg-gradient-to-r from-blue-500 to-emerald-400",
  "bg-gradient-to-r from-blue-500 to-emerald-500",
  "bg-gradient-to-r from-blue-500 to-emerald-600",
  "bg-gradient-to-r from-blue-500 to-emerald-700",
  "bg-gradient-to-r from-blue-500 to-emerald-800",
  "bg-gradient-to-r from-blue-500 to-emerald-900",
  "bg-gradient-to-r from-blue-500 to-emerald-950",
  "bg-gradient-to-r from-blue-500 to-teal-300",
  "bg-gradient-to-r from-blue-500 to-teal-400",
  "bg-gradient-to-r from-blue-500 to-teal-500",
  "bg-gradient-to-r from-blue-500 to-teal-600",
  "bg-gradient-to-r from-blue-500 to-teal-700",
  "bg-gradient-to-r from-blue-500 to-teal-800",
  "bg-gradient-to-r from-blue-500 to-teal-900",
  "bg-gradient-to-r from-blue-500 to-teal-950",
  "bg-gradient-to-r from-blue-500 to-cyan-300",
  "bg-gradient-to-r from-blue-500 to-cyan-400",
  "bg-gradient-to-r from-blue-500 to-cyan-500",
  "bg-gradient-to-r from-blue-500 to-cyan-600",
  "bg-gradient-to-r from-blue-500 to-cyan-700",
  "bg-gradient-to-r from-blue-500 to-cyan-800",
  "bg-gradient-to-r from-blue-500 to-cyan-900",
  "bg-gradient-to-r from-blue-500 to-cyan-950",
  "bg-gradient-to-r from-blue-500 to-sky-300",
  "bg-gradient-to-r from-blue-500 to-sky-400",
  "bg-gradient-to-r from-blue-500 to-sky-500",
  "bg-gradient-to-r from-blue-500 to-sky-600",
  "bg-gradient-to-r from-blue-500 to-sky-700",
  "bg-gradient-to-r from-blue-500 to-sky-800",
  "bg-gradient-to-r from-blue-500 to-sky-900",
  "bg-gradient-to-r from-blue-500 to-sky-950",
  "bg-gradient-to-r from-blue-500 to-indigo-300",
  "bg-gradient-to-r from-blue-500 to-indigo-400",
  "bg-gradient-to-r from-blue-500 to-indigo-500",
  "bg-gradient-to-r from-blue-500 to-indigo-600",
  "bg-gradient-to-r from-blue-500 to-indigo-700",
  "bg-gradient-to-r from-blue-500 to-indigo-800",
  "bg-gradient-to-r from-blue-500 to-indigo-900",
  "bg-gradient-to-r from-blue-500 to-indigo-950",
  "bg-gradient-to-r from-blue-500 to-violet-300",
  "bg-gradient-to-r from-blue-500 to-violet-400",
  "bg-gradient-to-r from-blue-500 to-violet-500",
  "bg-gradient-to-r from-blue-500 to-violet-600",
  "bg-gradient-to-r from-blue-500 to-violet-700",
  "bg-gradient-to-r from-blue-500 to-violet-800",
  "bg-gradient-to-r from-blue-500 to-violet-900",
  "bg-gradient-to-r from-blue-500 to-violet-950",
  "bg-gradient-to-r from-blue-500 to-purple-300",
  "bg-gradient-to-r from-blue-500 to-purple-400",
  "bg-gradient-to-r from-blue-500 to-purple-500",
  "bg-gradient-to-r from-blue-500 to-purple-600",
  "bg-gradient-to-r from-blue-500 to-purple-700",
  "bg-gradient-to-r from-blue-500 to-purple-800",
  "bg-gradient-to-r from-blue-500 to-purple-900",
  "bg-gradient-to-r from-blue-500 to-purple-950",
  "bg-gradient-to-r from-blue-500 to-fuchsia-300",
  "bg-gradient-to-r from-blue-500 to-fuchsia-400",
  "bg-gradient-to-r from-blue-500 to-fuchsia-500",
  "bg-gradient-to-r from-blue-500 to-fuchsia-600",
  "bg-gradient-to-r from-blue-500 to-fuchsia-700",
  "bg-gradient-to-r from-blue-500 to-fuchsia-800",
  "bg-gradient-to-r from-blue-500 to-fuchsia-900",
  "bg-gradient-to-r from-blue-500 to-fuchsia-950",
  "bg-gradient-to-r from-blue-500 to-pink-300",
  "bg-gradient-to-r from-blue-500 to-pink-400",
  "bg-gradient-to-r from-blue-500 to-pink-500",
  "bg-gradient-to-r from-blue-500 to-pink-600",
  "bg-gradient-to-r from-blue-500 to-pink-700",
  "bg-gradient-to-r from-blue-500 to-pink-800",
  "bg-gradient-to-r from-blue-500 to-pink-900",
  "bg-gradient-to-r from-blue-500 to-pink-950",
  "bg-gradient-to-r from-blue-500 to-rose-300",
  "bg-gradient-to-r from-blue-500 to-rose-400",
  "bg-gradient-to-r from-blue-500 to-rose-500",
  "bg-gradient-to-r from-blue-500 to-rose-600",
  "bg-gradient-to-r from-blue-500 to-rose-700",
  "bg-gradient-to-r from-blue-500 to-rose-800",
  "bg-gradient-to-r from-blue-500 to-rose-900",
  "bg-gradient-to-r from-blue-500 to-rose-950",
  "bg-gradient-to-r from-blue-600 to-red-300",
  "bg-gradient-to-r from-blue-600 to-red-400",
  "bg-gradient-to-r from-blue-600 to-red-500",
  "bg-gradient-to-r from-blue-600 to-red-600",
  "bg-gradient-to-r from-blue-600 to-red-700",
  "bg-gradient-to-r from-blue-600 to-red-800",
  "bg-gradient-to-r from-blue-600 to-red-900",
  "bg-gradient-to-r from-blue-600 to-red-950",
  "bg-gradient-to-r from-blue-600 to-orange-300",
  "bg-gradient-to-r from-blue-600 to-orange-400",
  "bg-gradient-to-r from-blue-600 to-orange-500",
  "bg-gradient-to-r from-blue-600 to-orange-600",
  "bg-gradient-to-r from-blue-600 to-orange-700",
  "bg-gradient-to-r from-blue-600 to-orange-800",
  "bg-gradient-to-r from-blue-600 to-orange-900",
  "bg-gradient-to-r from-blue-600 to-orange-950",
  "bg-gradient-to-r from-blue-600 to-amber-300",
  "bg-gradient-to-r from-blue-600 to-amber-400",
  "bg-gradient-to-r from-blue-600 to-amber-500",
  "bg-gradient-to-r from-blue-600 to-amber-600",
  "bg-gradient-to-r from-blue-600 to-amber-700",
  "bg-gradient-to-r from-blue-600 to-amber-800",
  "bg-gradient-to-r from-blue-600 to-amber-900",
  "bg-gradient-to-r from-blue-600 to-amber-950",
  "bg-gradient-to-r from-blue-600 to-yellow-300",
  "bg-gradient-to-r from-blue-600 to-yellow-400",
  "bg-gradient-to-r from-blue-600 to-yellow-500",
  "bg-gradient-to-r from-blue-600 to-yellow-600",
  "bg-gradient-to-r from-blue-600 to-yellow-700",
  "bg-gradient-to-r from-blue-600 to-yellow-800",
  "bg-gradient-to-r from-blue-600 to-yellow-900",
  "bg-gradient-to-r from-blue-600 to-yellow-950",
  "bg-gradient-to-r from-blue-600 to-lime-300",
  "bg-gradient-to-r from-blue-600 to-lime-400",
  "bg-gradient-to-r from-blue-600 to-lime-500",
  "bg-gradient-to-r from-blue-600 to-lime-600",
  "bg-gradient-to-r from-blue-600 to-lime-700",
  "bg-gradient-to-r from-blue-600 to-lime-800",
  "bg-gradient-to-r from-blue-600 to-lime-900",
  "bg-gradient-to-r from-blue-600 to-lime-950",
  "bg-gradient-to-r from-blue-600 to-green-300",
  "bg-gradient-to-r from-blue-600 to-green-400",
  "bg-gradient-to-r from-blue-600 to-green-500",
  "bg-gradient-to-r from-blue-600 to-green-600",
  "bg-gradient-to-r from-blue-600 to-green-700",
  "bg-gradient-to-r from-blue-600 to-green-800",
  "bg-gradient-to-r from-blue-600 to-green-900",
  "bg-gradient-to-r from-blue-600 to-green-950",
  "bg-gradient-to-r from-blue-600 to-emerald-300",
  "bg-gradient-to-r from-blue-600 to-emerald-400",
  "bg-gradient-to-r from-blue-600 to-emerald-500",
  "bg-gradient-to-r from-blue-600 to-emerald-600",
  "bg-gradient-to-r from-blue-600 to-emerald-700",
  "bg-gradient-to-r from-blue-600 to-emerald-800",
  "bg-gradient-to-r from-blue-600 to-emerald-900",
  "bg-gradient-to-r from-blue-600 to-emerald-950",
  "bg-gradient-to-r from-blue-600 to-teal-300",
  "bg-gradient-to-r from-blue-600 to-teal-400",
  "bg-gradient-to-r from-blue-600 to-teal-500",
  "bg-gradient-to-r from-blue-600 to-teal-600",
  "bg-gradient-to-r from-blue-600 to-teal-700",
  "bg-gradient-to-r from-blue-600 to-teal-800",
  "bg-gradient-to-r from-blue-600 to-teal-900",
  "bg-gradient-to-r from-blue-600 to-teal-950",
  "bg-gradient-to-r from-blue-600 to-cyan-300",
  "bg-gradient-to-r from-blue-600 to-cyan-400",
  "bg-gradient-to-r from-blue-600 to-cyan-500",
  "bg-gradient-to-r from-blue-600 to-cyan-600",
  "bg-gradient-to-r from-blue-600 to-cyan-700",
  "bg-gradient-to-r from-blue-600 to-cyan-800",
  "bg-gradient-to-r from-blue-600 to-cyan-900",
  "bg-gradient-to-r from-blue-600 to-cyan-950",
  "bg-gradient-to-r from-blue-600 to-sky-300",
  "bg-gradient-to-r from-blue-600 to-sky-400",
  "bg-gradient-to-r from-blue-600 to-sky-500",
  "bg-gradient-to-r from-blue-600 to-sky-600",
  "bg-gradient-to-r from-blue-600 to-sky-700",
  "bg-gradient-to-r from-blue-600 to-sky-800",
  "bg-gradient-to-r from-blue-600 to-sky-900",
  "bg-gradient-to-r from-blue-600 to-sky-950",
  "bg-gradient-to-r from-blue-600 to-indigo-300",
  "bg-gradient-to-r from-blue-600 to-indigo-400",
  "bg-gradient-to-r from-blue-600 to-indigo-500",
  "bg-gradient-to-r from-blue-600 to-indigo-600",
  "bg-gradient-to-r from-blue-600 to-indigo-700",
  "bg-gradient-to-r from-blue-600 to-indigo-800",
  "bg-gradient-to-r from-blue-600 to-indigo-900",
  "bg-gradient-to-r from-blue-600 to-indigo-950",
  "bg-gradient-to-r from-blue-600 to-violet-300",
  "bg-gradient-to-r from-blue-600 to-violet-400",
  "bg-gradient-to-r from-blue-600 to-violet-500",
  "bg-gradient-to-r from-blue-600 to-violet-600",
  "bg-gradient-to-r from-blue-600 to-violet-700",
  "bg-gradient-to-r from-blue-600 to-violet-800",
  "bg-gradient-to-r from-blue-600 to-violet-900",
  "bg-gradient-to-r from-blue-600 to-violet-950",
  "bg-gradient-to-r from-blue-600 to-purple-300",
  "bg-gradient-to-r from-blue-600 to-purple-400",
  "bg-gradient-to-r from-blue-600 to-purple-500",
  "bg-gradient-to-r from-blue-600 to-purple-600",
  "bg-gradient-to-r from-blue-600 to-purple-700",
  "bg-gradient-to-r from-blue-600 to-purple-800",
  "bg-gradient-to-r from-blue-600 to-purple-900",
  "bg-gradient-to-r from-blue-600 to-purple-950",
  "bg-gradient-to-r from-blue-600 to-fuchsia-300",
  "bg-gradient-to-r from-blue-600 to-fuchsia-400",
  "bg-gradient-to-r from-blue-600 to-fuchsia-500",
  "bg-gradient-to-r from-blue-600 to-fuchsia-600",
  "bg-gradient-to-r from-blue-600 to-fuchsia-700",
  "bg-gradient-to-r from-blue-600 to-fuchsia-800",
  "bg-gradient-to-r from-blue-600 to-fuchsia-900",
  "bg-gradient-to-r from-blue-600 to-fuchsia-950",
  "bg-gradient-to-r from-blue-600 to-pink-300",
  "bg-gradient-to-r from-blue-600 to-pink-400",
  "bg-gradient-to-r from-blue-600 to-pink-500",
  "bg-gradient-to-r from-blue-600 to-pink-600",
  "bg-gradient-to-r from-blue-600 to-pink-700",
  "bg-gradient-to-r from-blue-600 to-pink-800",
  "bg-gradient-to-r from-blue-600 to-pink-900",
  "bg-gradient-to-r from-blue-600 to-pink-950",
  "bg-gradient-to-r from-blue-600 to-rose-300",
  "bg-gradient-to-r from-blue-600 to-rose-400",
  "bg-gradient-to-r from-blue-600 to-rose-500",
  "bg-gradient-to-r from-blue-600 to-rose-600",
  "bg-gradient-to-r from-blue-600 to-rose-700",
  "bg-gradient-to-r from-blue-600 to-rose-800",
  "bg-gradient-to-r from-blue-600 to-rose-900",
  "bg-gradient-to-r from-blue-600 to-rose-950",
  "bg-gradient-to-r from-blue-700 to-red-300",
  "bg-gradient-to-r from-blue-700 to-red-400",
  "bg-gradient-to-r from-blue-700 to-red-500",
  "bg-gradient-to-r from-blue-700 to-red-600",
  "bg-gradient-to-r from-blue-700 to-red-700",
  "bg-gradient-to-r from-blue-700 to-red-800",
  "bg-gradient-to-r from-blue-700 to-red-900",
  "bg-gradient-to-r from-blue-700 to-red-950",
  "bg-gradient-to-r from-blue-700 to-orange-300",
  "bg-gradient-to-r from-blue-700 to-orange-400",
  "bg-gradient-to-r from-blue-700 to-orange-500",
  "bg-gradient-to-r from-blue-700 to-orange-600",
  "bg-gradient-to-r from-blue-700 to-orange-700",
  "bg-gradient-to-r from-blue-700 to-orange-800",
  "bg-gradient-to-r from-blue-700 to-orange-900",
  "bg-gradient-to-r from-blue-700 to-orange-950",
  "bg-gradient-to-r from-blue-700 to-amber-300",
  "bg-gradient-to-r from-blue-700 to-amber-400",
  "bg-gradient-to-r from-blue-700 to-amber-500",
  "bg-gradient-to-r from-blue-700 to-amber-600",
  "bg-gradient-to-r from-blue-700 to-amber-700",
  "bg-gradient-to-r from-blue-700 to-amber-800",
  "bg-gradient-to-r from-blue-700 to-amber-900",
  "bg-gradient-to-r from-blue-700 to-amber-950",
  "bg-gradient-to-r from-blue-700 to-yellow-300",
  "bg-gradient-to-r from-blue-700 to-yellow-400",
  "bg-gradient-to-r from-blue-700 to-yellow-500",
  "bg-gradient-to-r from-blue-700 to-yellow-600",
  "bg-gradient-to-r from-blue-700 to-yellow-700",
  "bg-gradient-to-r from-blue-700 to-yellow-800",
  "bg-gradient-to-r from-blue-700 to-yellow-900",
  "bg-gradient-to-r from-blue-700 to-yellow-950",
  "bg-gradient-to-r from-blue-700 to-lime-300",
  "bg-gradient-to-r from-blue-700 to-lime-400",
  "bg-gradient-to-r from-blue-700 to-lime-500",
  "bg-gradient-to-r from-blue-700 to-lime-600",
  "bg-gradient-to-r from-blue-700 to-lime-700",
  "bg-gradient-to-r from-blue-700 to-lime-800",
  "bg-gradient-to-r from-blue-700 to-lime-900",
  "bg-gradient-to-r from-blue-700 to-lime-950",
  "bg-gradient-to-r from-blue-700 to-green-300",
  "bg-gradient-to-r from-blue-700 to-green-400",
  "bg-gradient-to-r from-blue-700 to-green-500",
  "bg-gradient-to-r from-blue-700 to-green-600",
  "bg-gradient-to-r from-blue-700 to-green-700",
  "bg-gradient-to-r from-blue-700 to-green-800",
  "bg-gradient-to-r from-blue-700 to-green-900",
  "bg-gradient-to-r from-blue-700 to-green-950",
  "bg-gradient-to-r from-blue-700 to-emerald-300",
  "bg-gradient-to-r from-blue-700 to-emerald-400",
  "bg-gradient-to-r from-blue-700 to-emerald-500",
  "bg-gradient-to-r from-blue-700 to-emerald-600",
  "bg-gradient-to-r from-blue-700 to-emerald-700",
  "bg-gradient-to-r from-blue-700 to-emerald-800",
  "bg-gradient-to-r from-blue-700 to-emerald-900",
  "bg-gradient-to-r from-blue-700 to-emerald-950",
  "bg-gradient-to-r from-blue-700 to-teal-300",
  "bg-gradient-to-r from-blue-700 to-teal-400",
  "bg-gradient-to-r from-blue-700 to-teal-500",
  "bg-gradient-to-r from-blue-700 to-teal-600",
  "bg-gradient-to-r from-blue-700 to-teal-700",
  "bg-gradient-to-r from-blue-700 to-teal-800",
  "bg-gradient-to-r from-blue-700 to-teal-900",
  "bg-gradient-to-r from-blue-700 to-teal-950",
  "bg-gradient-to-r from-blue-700 to-cyan-300",
  "bg-gradient-to-r from-blue-700 to-cyan-400",
  "bg-gradient-to-r from-blue-700 to-cyan-500",
  "bg-gradient-to-r from-blue-700 to-cyan-600",
  "bg-gradient-to-r from-blue-700 to-cyan-700",
  "bg-gradient-to-r from-blue-700 to-cyan-800",
  "bg-gradient-to-r from-blue-700 to-cyan-900",
  "bg-gradient-to-r from-blue-700 to-cyan-950",
  "bg-gradient-to-r from-blue-700 to-sky-300",
  "bg-gradient-to-r from-blue-700 to-sky-400",
  "bg-gradient-to-r from-blue-700 to-sky-500",
  "bg-gradient-to-r from-blue-700 to-sky-600",
  "bg-gradient-to-r from-blue-700 to-sky-700",
  "bg-gradient-to-r from-blue-700 to-sky-800",
  "bg-gradient-to-r from-blue-700 to-sky-900",
  "bg-gradient-to-r from-blue-700 to-sky-950",
  "bg-gradient-to-r from-blue-700 to-indigo-300",
  "bg-gradient-to-r from-blue-700 to-indigo-400",
  "bg-gradient-to-r from-blue-700 to-indigo-500",
  "bg-gradient-to-r from-blue-700 to-indigo-600",
  "bg-gradient-to-r from-blue-700 to-indigo-700",
  "bg-gradient-to-r from-blue-700 to-indigo-800",
  "bg-gradient-to-r from-blue-700 to-indigo-900",
  "bg-gradient-to-r from-blue-700 to-indigo-950",
  "bg-gradient-to-r from-blue-700 to-violet-300",
  "bg-gradient-to-r from-blue-700 to-violet-400",
  "bg-gradient-to-r from-blue-700 to-violet-500",
  "bg-gradient-to-r from-blue-700 to-violet-600",
  "bg-gradient-to-r from-blue-700 to-violet-700",
  "bg-gradient-to-r from-blue-700 to-violet-800",
  "bg-gradient-to-r from-blue-700 to-violet-900",
  "bg-gradient-to-r from-blue-700 to-violet-950",
  "bg-gradient-to-r from-blue-700 to-purple-300",
  "bg-gradient-to-r from-blue-700 to-purple-400",
  "bg-gradient-to-r from-blue-700 to-purple-500",
  "bg-gradient-to-r from-blue-700 to-purple-600",
  "bg-gradient-to-r from-blue-700 to-purple-700",
  "bg-gradient-to-r from-blue-700 to-purple-800",
  "bg-gradient-to-r from-blue-700 to-purple-900",
  "bg-gradient-to-r from-blue-700 to-purple-950",
  "bg-gradient-to-r from-blue-700 to-fuchsia-300",
  "bg-gradient-to-r from-blue-700 to-fuchsia-400",
  "bg-gradient-to-r from-blue-700 to-fuchsia-500",
  "bg-gradient-to-r from-blue-700 to-fuchsia-600",
  "bg-gradient-to-r from-blue-700 to-fuchsia-700",
  "bg-gradient-to-r from-blue-700 to-fuchsia-800",
  "bg-gradient-to-r from-blue-700 to-fuchsia-900",
  "bg-gradient-to-r from-blue-700 to-fuchsia-950",
  "bg-gradient-to-r from-blue-700 to-pink-300",
  "bg-gradient-to-r from-blue-700 to-pink-400",
  "bg-gradient-to-r from-blue-700 to-pink-500",
  "bg-gradient-to-r from-blue-700 to-pink-600",
  "bg-gradient-to-r from-blue-700 to-pink-700",
  "bg-gradient-to-r from-blue-700 to-pink-800",
  "bg-gradient-to-r from-blue-700 to-pink-900",
  "bg-gradient-to-r from-blue-700 to-pink-950",
  "bg-gradient-to-r from-blue-700 to-rose-300",
  "bg-gradient-to-r from-blue-700 to-rose-400",
  "bg-gradient-to-r from-blue-700 to-rose-500",
  "bg-gradient-to-r from-blue-700 to-rose-600",
  "bg-gradient-to-r from-blue-700 to-rose-700",
  "bg-gradient-to-r from-blue-700 to-rose-800",
  "bg-gradient-to-r from-blue-700 to-rose-900",
  "bg-gradient-to-r from-blue-700 to-rose-950",
  "bg-gradient-to-r from-blue-800 to-red-300",
  "bg-gradient-to-r from-blue-800 to-red-400",
  "bg-gradient-to-r from-blue-800 to-red-500",
  "bg-gradient-to-r from-blue-800 to-red-600",
  "bg-gradient-to-r from-blue-800 to-red-700",
  "bg-gradient-to-r from-blue-800 to-red-800",
  "bg-gradient-to-r from-blue-800 to-red-900",
  "bg-gradient-to-r from-blue-800 to-red-950",
  "bg-gradient-to-r from-blue-800 to-orange-300",
  "bg-gradient-to-r from-blue-800 to-orange-400",
  "bg-gradient-to-r from-blue-800 to-orange-500",
  "bg-gradient-to-r from-blue-800 to-orange-600",
  "bg-gradient-to-r from-blue-800 to-orange-700",
  "bg-gradient-to-r from-blue-800 to-orange-800",
  "bg-gradient-to-r from-blue-800 to-orange-900",
  "bg-gradient-to-r from-blue-800 to-orange-950",
  "bg-gradient-to-r from-blue-800 to-amber-300",
  "bg-gradient-to-r from-blue-800 to-amber-400",
  "bg-gradient-to-r from-blue-800 to-amber-500",
  "bg-gradient-to-r from-blue-800 to-amber-600",
  "bg-gradient-to-r from-blue-800 to-amber-700",
  "bg-gradient-to-r from-blue-800 to-amber-800",
  "bg-gradient-to-r from-blue-800 to-amber-900",
  "bg-gradient-to-r from-blue-800 to-amber-950",
  "bg-gradient-to-r from-blue-800 to-yellow-300",
  "bg-gradient-to-r from-blue-800 to-yellow-400",
  "bg-gradient-to-r from-blue-800 to-yellow-500",
  "bg-gradient-to-r from-blue-800 to-yellow-600",
  "bg-gradient-to-r from-blue-800 to-yellow-700",
  "bg-gradient-to-r from-blue-800 to-yellow-800",
  "bg-gradient-to-r from-blue-800 to-yellow-900",
  "bg-gradient-to-r from-blue-800 to-yellow-950",
  "bg-gradient-to-r from-blue-800 to-lime-300",
  "bg-gradient-to-r from-blue-800 to-lime-400",
  "bg-gradient-to-r from-blue-800 to-lime-500",
  "bg-gradient-to-r from-blue-800 to-lime-600",
  "bg-gradient-to-r from-blue-800 to-lime-700",
  "bg-gradient-to-r from-blue-800 to-lime-800",
  "bg-gradient-to-r from-blue-800 to-lime-900",
  "bg-gradient-to-r from-blue-800 to-lime-950",
  "bg-gradient-to-r from-blue-800 to-green-300",
  "bg-gradient-to-r from-blue-800 to-green-400",
  "bg-gradient-to-r from-blue-800 to-green-500",
  "bg-gradient-to-r from-blue-800 to-green-600",
  "bg-gradient-to-r from-blue-800 to-green-700",
  "bg-gradient-to-r from-blue-800 to-green-800",
  "bg-gradient-to-r from-blue-800 to-green-900",
  "bg-gradient-to-r from-blue-800 to-green-950",
  "bg-gradient-to-r from-blue-800 to-emerald-300",
  "bg-gradient-to-r from-blue-800 to-emerald-400",
  "bg-gradient-to-r from-blue-800 to-emerald-500",
  "bg-gradient-to-r from-blue-800 to-emerald-600",
  "bg-gradient-to-r from-blue-800 to-emerald-700",
  "bg-gradient-to-r from-blue-800 to-emerald-800",
  "bg-gradient-to-r from-blue-800 to-emerald-900",
  "bg-gradient-to-r from-blue-800 to-emerald-950",
  "bg-gradient-to-r from-blue-800 to-teal-300",
  "bg-gradient-to-r from-blue-800 to-teal-400",
  "bg-gradient-to-r from-blue-800 to-teal-500",
  "bg-gradient-to-r from-blue-800 to-teal-600",
  "bg-gradient-to-r from-blue-800 to-teal-700",
  "bg-gradient-to-r from-blue-800 to-teal-800",
  "bg-gradient-to-r from-blue-800 to-teal-900",
  "bg-gradient-to-r from-blue-800 to-teal-950",
  "bg-gradient-to-r from-blue-800 to-cyan-300",
  "bg-gradient-to-r from-blue-800 to-cyan-400",
  "bg-gradient-to-r from-blue-800 to-cyan-500",
  "bg-gradient-to-r from-blue-800 to-cyan-600",
  "bg-gradient-to-r from-blue-800 to-cyan-700",
  "bg-gradient-to-r from-blue-800 to-cyan-800",
  "bg-gradient-to-r from-blue-800 to-cyan-900",
  "bg-gradient-to-r from-blue-800 to-cyan-950",
  "bg-gradient-to-r from-blue-800 to-sky-300",
  "bg-gradient-to-r from-blue-800 to-sky-400",
  "bg-gradient-to-r from-blue-800 to-sky-500",
  "bg-gradient-to-r from-blue-800 to-sky-600",
  "bg-gradient-to-r from-blue-800 to-sky-700",
  "bg-gradient-to-r from-blue-800 to-sky-800",
  "bg-gradient-to-r from-blue-800 to-sky-900",
  "bg-gradient-to-r from-blue-800 to-sky-950",
  "bg-gradient-to-r from-blue-800 to-indigo-300",
  "bg-gradient-to-r from-blue-800 to-indigo-400",
  "bg-gradient-to-r from-blue-800 to-indigo-500",
  "bg-gradient-to-r from-blue-800 to-indigo-600",
  "bg-gradient-to-r from-blue-800 to-indigo-700",
  "bg-gradient-to-r from-blue-800 to-indigo-800",
  "bg-gradient-to-r from-blue-800 to-indigo-900",
  "bg-gradient-to-r from-blue-800 to-indigo-950",
  "bg-gradient-to-r from-blue-800 to-violet-300",
  "bg-gradient-to-r from-blue-800 to-violet-400",
  "bg-gradient-to-r from-blue-800 to-violet-500",
  "bg-gradient-to-r from-blue-800 to-violet-600",
  "bg-gradient-to-r from-blue-800 to-violet-700",
  "bg-gradient-to-r from-blue-800 to-violet-800",
  "bg-gradient-to-r from-blue-800 to-violet-900",
  "bg-gradient-to-r from-blue-800 to-violet-950",
  "bg-gradient-to-r from-blue-800 to-purple-300",
  "bg-gradient-to-r from-blue-800 to-purple-400",
  "bg-gradient-to-r from-blue-800 to-purple-500",
  "bg-gradient-to-r from-blue-800 to-purple-600",
  "bg-gradient-to-r from-blue-800 to-purple-700",
  "bg-gradient-to-r from-blue-800 to-purple-800",
  "bg-gradient-to-r from-blue-800 to-purple-900",
  "bg-gradient-to-r from-blue-800 to-purple-950",
  "bg-gradient-to-r from-blue-800 to-fuchsia-300",
  "bg-gradient-to-r from-blue-800 to-fuchsia-400",
  "bg-gradient-to-r from-blue-800 to-fuchsia-500",
  "bg-gradient-to-r from-blue-800 to-fuchsia-600",
  "bg-gradient-to-r from-blue-800 to-fuchsia-700",
  "bg-gradient-to-r from-blue-800 to-fuchsia-800",
  "bg-gradient-to-r from-blue-800 to-fuchsia-900",
  "bg-gradient-to-r from-blue-800 to-fuchsia-950",
  "bg-gradient-to-r from-blue-800 to-pink-300",
  "bg-gradient-to-r from-blue-800 to-pink-400",
  "bg-gradient-to-r from-blue-800 to-pink-500",
  "bg-gradient-to-r from-blue-800 to-pink-600",
  "bg-gradient-to-r from-blue-800 to-pink-700",
  "bg-gradient-to-r from-blue-800 to-pink-800",
  "bg-gradient-to-r from-blue-800 to-pink-900",
  "bg-gradient-to-r from-blue-800 to-pink-950",
  "bg-gradient-to-r from-blue-800 to-rose-300",
  "bg-gradient-to-r from-blue-800 to-rose-400",
  "bg-gradient-to-r from-blue-800 to-rose-500",
  "bg-gradient-to-r from-blue-800 to-rose-600",
  "bg-gradient-to-r from-blue-800 to-rose-700",
  "bg-gradient-to-r from-blue-800 to-rose-800",
  "bg-gradient-to-r from-blue-800 to-rose-900",
  "bg-gradient-to-r from-blue-800 to-rose-950",
  "bg-gradient-to-r from-blue-900 to-red-300",
  "bg-gradient-to-r from-blue-900 to-red-400",
  "bg-gradient-to-r from-blue-900 to-red-500",
  "bg-gradient-to-r from-blue-900 to-red-600",
  "bg-gradient-to-r from-blue-900 to-red-700",
  "bg-gradient-to-r from-blue-900 to-red-800",
  "bg-gradient-to-r from-blue-900 to-red-900",
  "bg-gradient-to-r from-blue-900 to-red-950",
  "bg-gradient-to-r from-blue-900 to-orange-300",
  "bg-gradient-to-r from-blue-900 to-orange-400",
  "bg-gradient-to-r from-blue-900 to-orange-500",
  "bg-gradient-to-r from-blue-900 to-orange-600",
  "bg-gradient-to-r from-blue-900 to-orange-700",
  "bg-gradient-to-r from-blue-900 to-orange-800",
  "bg-gradient-to-r from-blue-900 to-orange-900",
  "bg-gradient-to-r from-blue-900 to-orange-950",
  "bg-gradient-to-r from-blue-900 to-amber-300",
  "bg-gradient-to-r from-blue-900 to-amber-400",
  "bg-gradient-to-r from-blue-900 to-amber-500",
  "bg-gradient-to-r from-blue-900 to-amber-600",
  "bg-gradient-to-r from-blue-900 to-amber-700",
  "bg-gradient-to-r from-blue-900 to-amber-800",
  "bg-gradient-to-r from-blue-900 to-amber-900",
  "bg-gradient-to-r from-blue-900 to-amber-950",
  "bg-gradient-to-r from-blue-900 to-yellow-300",
  "bg-gradient-to-r from-blue-900 to-yellow-400",
  "bg-gradient-to-r from-blue-900 to-yellow-500",
  "bg-gradient-to-r from-blue-900 to-yellow-600",
  "bg-gradient-to-r from-blue-900 to-yellow-700",
  "bg-gradient-to-r from-blue-900 to-yellow-800",
  "bg-gradient-to-r from-blue-900 to-yellow-900",
  "bg-gradient-to-r from-blue-900 to-yellow-950",
  "bg-gradient-to-r from-blue-900 to-lime-300",
  "bg-gradient-to-r from-blue-900 to-lime-400",
  "bg-gradient-to-r from-blue-900 to-lime-500",
  "bg-gradient-to-r from-blue-900 to-lime-600",
  "bg-gradient-to-r from-blue-900 to-lime-700",
  "bg-gradient-to-r from-blue-900 to-lime-800",
  "bg-gradient-to-r from-blue-900 to-lime-900",
  "bg-gradient-to-r from-blue-900 to-lime-950",
  "bg-gradient-to-r from-blue-900 to-green-300",
  "bg-gradient-to-r from-blue-900 to-green-400",
  "bg-gradient-to-r from-blue-900 to-green-500",
  "bg-gradient-to-r from-blue-900 to-green-600",
  "bg-gradient-to-r from-blue-900 to-green-700",
  "bg-gradient-to-r from-blue-900 to-green-800",
  "bg-gradient-to-r from-blue-900 to-green-900",
  "bg-gradient-to-r from-blue-900 to-green-950",
  "bg-gradient-to-r from-blue-900 to-emerald-300",
  "bg-gradient-to-r from-blue-900 to-emerald-400",
  "bg-gradient-to-r from-blue-900 to-emerald-500",
  "bg-gradient-to-r from-blue-900 to-emerald-600",
  "bg-gradient-to-r from-blue-900 to-emerald-700",
  "bg-gradient-to-r from-blue-900 to-emerald-800",
  "bg-gradient-to-r from-blue-900 to-emerald-900",
  "bg-gradient-to-r from-blue-900 to-emerald-950",
  "bg-gradient-to-r from-blue-900 to-teal-300",
  "bg-gradient-to-r from-blue-900 to-teal-400",
  "bg-gradient-to-r from-blue-900 to-teal-500",
  "bg-gradient-to-r from-blue-900 to-teal-600",
  "bg-gradient-to-r from-blue-900 to-teal-700",
  "bg-gradient-to-r from-blue-900 to-teal-800",
  "bg-gradient-to-r from-blue-900 to-teal-900",
  "bg-gradient-to-r from-blue-900 to-teal-950",
  "bg-gradient-to-r from-blue-900 to-cyan-300",
  "bg-gradient-to-r from-blue-900 to-cyan-400",
  "bg-gradient-to-r from-blue-900 to-cyan-500",
  "bg-gradient-to-r from-blue-900 to-cyan-600",
  "bg-gradient-to-r from-blue-900 to-cyan-700",
  "bg-gradient-to-r from-blue-900 to-cyan-800",
  "bg-gradient-to-r from-blue-900 to-cyan-900",
  "bg-gradient-to-r from-blue-900 to-cyan-950",
  "bg-gradient-to-r from-blue-900 to-sky-300",
  "bg-gradient-to-r from-blue-900 to-sky-400",
  "bg-gradient-to-r from-blue-900 to-sky-500",
  "bg-gradient-to-r from-blue-900 to-sky-600",
  "bg-gradient-to-r from-blue-900 to-sky-700",
  "bg-gradient-to-r from-blue-900 to-sky-800",
  "bg-gradient-to-r from-blue-900 to-sky-900",
  "bg-gradient-to-r from-blue-900 to-sky-950",
  "bg-gradient-to-r from-blue-900 to-indigo-300",
  "bg-gradient-to-r from-blue-900 to-indigo-400",
  "bg-gradient-to-r from-blue-900 to-indigo-500",
  "bg-gradient-to-r from-blue-900 to-indigo-600",
  "bg-gradient-to-r from-blue-900 to-indigo-700",
  "bg-gradient-to-r from-blue-900 to-indigo-800",
  "bg-gradient-to-r from-blue-900 to-indigo-900",
  "bg-gradient-to-r from-blue-900 to-indigo-950",
  "bg-gradient-to-r from-blue-900 to-violet-300",
  "bg-gradient-to-r from-blue-900 to-violet-400",
  "bg-gradient-to-r from-blue-900 to-violet-500",
  "bg-gradient-to-r from-blue-900 to-violet-600",
  "bg-gradient-to-r from-blue-900 to-violet-700",
  "bg-gradient-to-r from-blue-900 to-violet-800",
  "bg-gradient-to-r from-blue-900 to-violet-900",
  "bg-gradient-to-r from-blue-900 to-violet-950",
  "bg-gradient-to-r from-blue-900 to-purple-300",
  "bg-gradient-to-r from-blue-900 to-purple-400",
  "bg-gradient-to-r from-blue-900 to-purple-500",
  "bg-gradient-to-r from-blue-900 to-purple-600",
  "bg-gradient-to-r from-blue-900 to-purple-700",
  "bg-gradient-to-r from-blue-900 to-purple-800",
  "bg-gradient-to-r from-blue-900 to-purple-900",
  "bg-gradient-to-r from-blue-900 to-purple-950",
  "bg-gradient-to-r from-blue-900 to-fuchsia-300",
  "bg-gradient-to-r from-blue-900 to-fuchsia-400",
  "bg-gradient-to-r from-blue-900 to-fuchsia-500",
  "bg-gradient-to-r from-blue-900 to-fuchsia-600",
  "bg-gradient-to-r from-blue-900 to-fuchsia-700",
  "bg-gradient-to-r from-blue-900 to-fuchsia-800",
  "bg-gradient-to-r from-blue-900 to-fuchsia-900",
  "bg-gradient-to-r from-blue-900 to-fuchsia-950",
  "bg-gradient-to-r from-blue-900 to-pink-300",
  "bg-gradient-to-r from-blue-900 to-pink-400",
  "bg-gradient-to-r from-blue-900 to-pink-500",
  "bg-gradient-to-r from-blue-900 to-pink-600",
  "bg-gradient-to-r from-blue-900 to-pink-700",
  "bg-gradient-to-r from-blue-900 to-pink-800",
  "bg-gradient-to-r from-blue-900 to-pink-900",
  "bg-gradient-to-r from-blue-900 to-pink-950",
  "bg-gradient-to-r from-blue-900 to-rose-300",
  "bg-gradient-to-r from-blue-900 to-rose-400",
  "bg-gradient-to-r from-blue-900 to-rose-500",
  "bg-gradient-to-r from-blue-900 to-rose-600",
  "bg-gradient-to-r from-blue-900 to-rose-700",
  "bg-gradient-to-r from-blue-900 to-rose-800",
  "bg-gradient-to-r from-blue-900 to-rose-900",
  "bg-gradient-to-r from-blue-900 to-rose-950",
  "bg-gradient-to-r from-blue-950 to-red-300",
  "bg-gradient-to-r from-blue-950 to-red-400",
  "bg-gradient-to-r from-blue-950 to-red-500",
  "bg-gradient-to-r from-blue-950 to-red-600",
  "bg-gradient-to-r from-blue-950 to-red-700",
  "bg-gradient-to-r from-blue-950 to-red-800",
  "bg-gradient-to-r from-blue-950 to-red-900",
  "bg-gradient-to-r from-blue-950 to-red-950",
  "bg-gradient-to-r from-blue-950 to-orange-300",
  "bg-gradient-to-r from-blue-950 to-orange-400",
  "bg-gradient-to-r from-blue-950 to-orange-500",
  "bg-gradient-to-r from-blue-950 to-orange-600",
  "bg-gradient-to-r from-blue-950 to-orange-700",
  "bg-gradient-to-r from-blue-950 to-orange-800",
  "bg-gradient-to-r from-blue-950 to-orange-900",
  "bg-gradient-to-r from-blue-950 to-orange-950",
  "bg-gradient-to-r from-blue-950 to-amber-300",
  "bg-gradient-to-r from-blue-950 to-amber-400",
  "bg-gradient-to-r from-blue-950 to-amber-500",
  "bg-gradient-to-r from-blue-950 to-amber-600",
  "bg-gradient-to-r from-blue-950 to-amber-700",
  "bg-gradient-to-r from-blue-950 to-amber-800",
  "bg-gradient-to-r from-blue-950 to-amber-900",
  "bg-gradient-to-r from-blue-950 to-amber-950",
  "bg-gradient-to-r from-blue-950 to-yellow-300",
  "bg-gradient-to-r from-blue-950 to-yellow-400",
  "bg-gradient-to-r from-blue-950 to-yellow-500",
  "bg-gradient-to-r from-blue-950 to-yellow-600",
  "bg-gradient-to-r from-blue-950 to-yellow-700",
  "bg-gradient-to-r from-blue-950 to-yellow-800",
  "bg-gradient-to-r from-blue-950 to-yellow-900",
  "bg-gradient-to-r from-blue-950 to-yellow-950",
  "bg-gradient-to-r from-blue-950 to-lime-300",
  "bg-gradient-to-r from-blue-950 to-lime-400",
  "bg-gradient-to-r from-blue-950 to-lime-500",
  "bg-gradient-to-r from-blue-950 to-lime-600",
  "bg-gradient-to-r from-blue-950 to-lime-700",
  "bg-gradient-to-r from-blue-950 to-lime-800",
  "bg-gradient-to-r from-blue-950 to-lime-900",
  "bg-gradient-to-r from-blue-950 to-lime-950",
  "bg-gradient-to-r from-blue-950 to-green-300",
  "bg-gradient-to-r from-blue-950 to-green-400",
  "bg-gradient-to-r from-blue-950 to-green-500",
  "bg-gradient-to-r from-blue-950 to-green-600",
  "bg-gradient-to-r from-blue-950 to-green-700",
  "bg-gradient-to-r from-blue-950 to-green-800",
  "bg-gradient-to-r from-blue-950 to-green-900",
  "bg-gradient-to-r from-blue-950 to-green-950",
  "bg-gradient-to-r from-blue-950 to-emerald-300",
  "bg-gradient-to-r from-blue-950 to-emerald-400",
  "bg-gradient-to-r from-blue-950 to-emerald-500",
  "bg-gradient-to-r from-blue-950 to-emerald-600",
  "bg-gradient-to-r from-blue-950 to-emerald-700",
  "bg-gradient-to-r from-blue-950 to-emerald-800",
  "bg-gradient-to-r from-blue-950 to-emerald-900",
  "bg-gradient-to-r from-blue-950 to-emerald-950",
  "bg-gradient-to-r from-blue-950 to-teal-300",
  "bg-gradient-to-r from-blue-950 to-teal-400",
  "bg-gradient-to-r from-blue-950 to-teal-500",
  "bg-gradient-to-r from-blue-950 to-teal-600",
  "bg-gradient-to-r from-blue-950 to-teal-700",
  "bg-gradient-to-r from-blue-950 to-teal-800",
  "bg-gradient-to-r from-blue-950 to-teal-900",
  "bg-gradient-to-r from-blue-950 to-teal-950",
  "bg-gradient-to-r from-blue-950 to-cyan-300",
  "bg-gradient-to-r from-blue-950 to-cyan-400",
  "bg-gradient-to-r from-blue-950 to-cyan-500",
  "bg-gradient-to-r from-blue-950 to-cyan-600",
  "bg-gradient-to-r from-blue-950 to-cyan-700",
  "bg-gradient-to-r from-blue-950 to-cyan-800",
  "bg-gradient-to-r from-blue-950 to-cyan-900",
  "bg-gradient-to-r from-blue-950 to-cyan-950",
  "bg-gradient-to-r from-blue-950 to-sky-300",
  "bg-gradient-to-r from-blue-950 to-sky-400",
  "bg-gradient-to-r from-blue-950 to-sky-500",
  "bg-gradient-to-r from-blue-950 to-sky-600",
  "bg-gradient-to-r from-blue-950 to-sky-700",
  "bg-gradient-to-r from-blue-950 to-sky-800",
  "bg-gradient-to-r from-blue-950 to-sky-900",
  "bg-gradient-to-r from-blue-950 to-sky-950",
  "bg-gradient-to-r from-blue-950 to-indigo-300",
  "bg-gradient-to-r from-blue-950 to-indigo-400",
  "bg-gradient-to-r from-blue-950 to-indigo-500",
  "bg-gradient-to-r from-blue-950 to-indigo-600",
  "bg-gradient-to-r from-blue-950 to-indigo-700",
  "bg-gradient-to-r from-blue-950 to-indigo-800",
  "bg-gradient-to-r from-blue-950 to-indigo-900",
  "bg-gradient-to-r from-blue-950 to-indigo-950",
  "bg-gradient-to-r from-blue-950 to-violet-300",
  "bg-gradient-to-r from-blue-950 to-violet-400",
  "bg-gradient-to-r from-blue-950 to-violet-500",
  "bg-gradient-to-r from-blue-950 to-violet-600",
  "bg-gradient-to-r from-blue-950 to-violet-700",
  "bg-gradient-to-r from-blue-950 to-violet-800",
  "bg-gradient-to-r from-blue-950 to-violet-900",
  "bg-gradient-to-r from-blue-950 to-violet-950",
  "bg-gradient-to-r from-blue-950 to-purple-300",
  "bg-gradient-to-r from-blue-950 to-purple-400",
  "bg-gradient-to-r from-blue-950 to-purple-500",
  "bg-gradient-to-r from-blue-950 to-purple-600",
  "bg-gradient-to-r from-blue-950 to-purple-700",
  "bg-gradient-to-r from-blue-950 to-purple-800",
  "bg-gradient-to-r from-blue-950 to-purple-900",
  "bg-gradient-to-r from-blue-950 to-purple-950",
  "bg-gradient-to-r from-blue-950 to-fuchsia-300",
  "bg-gradient-to-r from-blue-950 to-fuchsia-400",
  "bg-gradient-to-r from-blue-950 to-fuchsia-500",
  "bg-gradient-to-r from-blue-950 to-fuchsia-600",
  "bg-gradient-to-r from-blue-950 to-fuchsia-700",
  "bg-gradient-to-r from-blue-950 to-fuchsia-800",
  "bg-gradient-to-r from-blue-950 to-fuchsia-900",
  "bg-gradient-to-r from-blue-950 to-fuchsia-950",
  "bg-gradient-to-r from-blue-950 to-pink-300",
  "bg-gradient-to-r from-blue-950 to-pink-400",
  "bg-gradient-to-r from-blue-950 to-pink-500",
  "bg-gradient-to-r from-blue-950 to-pink-600",
  "bg-gradient-to-r from-blue-950 to-pink-700",
  "bg-gradient-to-r from-blue-950 to-pink-800",
  "bg-gradient-to-r from-blue-950 to-pink-900",
  "bg-gradient-to-r from-blue-950 to-pink-950",
  "bg-gradient-to-r from-blue-950 to-rose-300",
  "bg-gradient-to-r from-blue-950 to-rose-400",
  "bg-gradient-to-r from-blue-950 to-rose-500",
  "bg-gradient-to-r from-blue-950 to-rose-600",
  "bg-gradient-to-r from-blue-950 to-rose-700",
  "bg-gradient-to-r from-blue-950 to-rose-800",
  "bg-gradient-to-r from-blue-950 to-rose-900",
  "bg-gradient-to-r from-blue-950 to-rose-950",
  "bg-gradient-to-r from-indigo-300 to-red-300",
  "bg-gradient-to-r from-indigo-300 to-red-400",
  "bg-gradient-to-r from-indigo-300 to-red-500",
  "bg-gradient-to-r from-indigo-300 to-red-600",
  "bg-gradient-to-r from-indigo-300 to-red-700",
  "bg-gradient-to-r from-indigo-300 to-red-800",
  "bg-gradient-to-r from-indigo-300 to-red-900",
  "bg-gradient-to-r from-indigo-300 to-red-950",
  "bg-gradient-to-r from-indigo-300 to-orange-300",
  "bg-gradient-to-r from-indigo-300 to-orange-400",
  "bg-gradient-to-r from-indigo-300 to-orange-500",
  "bg-gradient-to-r from-indigo-300 to-orange-600",
  "bg-gradient-to-r from-indigo-300 to-orange-700",
  "bg-gradient-to-r from-indigo-300 to-orange-800",
  "bg-gradient-to-r from-indigo-300 to-orange-900",
  "bg-gradient-to-r from-indigo-300 to-orange-950",
  "bg-gradient-to-r from-indigo-300 to-amber-300",
  "bg-gradient-to-r from-indigo-300 to-amber-400",
  "bg-gradient-to-r from-indigo-300 to-amber-500",
  "bg-gradient-to-r from-indigo-300 to-amber-600",
  "bg-gradient-to-r from-indigo-300 to-amber-700",
  "bg-gradient-to-r from-indigo-300 to-amber-800",
  "bg-gradient-to-r from-indigo-300 to-amber-900",
  "bg-gradient-to-r from-indigo-300 to-amber-950",
  "bg-gradient-to-r from-indigo-300 to-yellow-300",
  "bg-gradient-to-r from-indigo-300 to-yellow-400",
  "bg-gradient-to-r from-indigo-300 to-yellow-500",
  "bg-gradient-to-r from-indigo-300 to-yellow-600",
  "bg-gradient-to-r from-indigo-300 to-yellow-700",
  "bg-gradient-to-r from-indigo-300 to-yellow-800",
  "bg-gradient-to-r from-indigo-300 to-yellow-900",
  "bg-gradient-to-r from-indigo-300 to-yellow-950",
  "bg-gradient-to-r from-indigo-300 to-lime-300",
  "bg-gradient-to-r from-indigo-300 to-lime-400",
  "bg-gradient-to-r from-indigo-300 to-lime-500",
  "bg-gradient-to-r from-indigo-300 to-lime-600",
  "bg-gradient-to-r from-indigo-300 to-lime-700",
  "bg-gradient-to-r from-indigo-300 to-lime-800",
  "bg-gradient-to-r from-indigo-300 to-lime-900",
  "bg-gradient-to-r from-indigo-300 to-lime-950",
  "bg-gradient-to-r from-indigo-300 to-green-300",
  "bg-gradient-to-r from-indigo-300 to-green-400",
  "bg-gradient-to-r from-indigo-300 to-green-500",
  "bg-gradient-to-r from-indigo-300 to-green-600",
  "bg-gradient-to-r from-indigo-300 to-green-700",
  "bg-gradient-to-r from-indigo-300 to-green-800",
  "bg-gradient-to-r from-indigo-300 to-green-900",
  "bg-gradient-to-r from-indigo-300 to-green-950",
  "bg-gradient-to-r from-indigo-300 to-emerald-300",
  "bg-gradient-to-r from-indigo-300 to-emerald-400",
  "bg-gradient-to-r from-indigo-300 to-emerald-500",
  "bg-gradient-to-r from-indigo-300 to-emerald-600",
  "bg-gradient-to-r from-indigo-300 to-emerald-700",
  "bg-gradient-to-r from-indigo-300 to-emerald-800",
  "bg-gradient-to-r from-indigo-300 to-emerald-900",
  "bg-gradient-to-r from-indigo-300 to-emerald-950",
  "bg-gradient-to-r from-indigo-300 to-teal-300",
  "bg-gradient-to-r from-indigo-300 to-teal-400",
  "bg-gradient-to-r from-indigo-300 to-teal-500",
  "bg-gradient-to-r from-indigo-300 to-teal-600",
  "bg-gradient-to-r from-indigo-300 to-teal-700",
  "bg-gradient-to-r from-indigo-300 to-teal-800",
  "bg-gradient-to-r from-indigo-300 to-teal-900",
  "bg-gradient-to-r from-indigo-300 to-teal-950",
  "bg-gradient-to-r from-indigo-300 to-cyan-300",
  "bg-gradient-to-r from-indigo-300 to-cyan-400",
  "bg-gradient-to-r from-indigo-300 to-cyan-500",
  "bg-gradient-to-r from-indigo-300 to-cyan-600",
  "bg-gradient-to-r from-indigo-300 to-cyan-700",
  "bg-gradient-to-r from-indigo-300 to-cyan-800",
  "bg-gradient-to-r from-indigo-300 to-cyan-900",
  "bg-gradient-to-r from-indigo-300 to-cyan-950",
  "bg-gradient-to-r from-indigo-300 to-sky-300",
  "bg-gradient-to-r from-indigo-300 to-sky-400",
  "bg-gradient-to-r from-indigo-300 to-sky-500",
  "bg-gradient-to-r from-indigo-300 to-sky-600",
  "bg-gradient-to-r from-indigo-300 to-sky-700",
  "bg-gradient-to-r from-indigo-300 to-sky-800",
  "bg-gradient-to-r from-indigo-300 to-sky-900",
  "bg-gradient-to-r from-indigo-300 to-sky-950",
  "bg-gradient-to-r from-indigo-300 to-blue-300",
  "bg-gradient-to-r from-indigo-300 to-blue-400",
  "bg-gradient-to-r from-indigo-300 to-blue-500",
  "bg-gradient-to-r from-indigo-300 to-blue-600",
  "bg-gradient-to-r from-indigo-300 to-blue-700",
  "bg-gradient-to-r from-indigo-300 to-blue-800",
  "bg-gradient-to-r from-indigo-300 to-blue-900",
  "bg-gradient-to-r from-indigo-300 to-blue-950",
  "bg-gradient-to-r from-indigo-300 to-violet-300",
  "bg-gradient-to-r from-indigo-300 to-violet-400",
  "bg-gradient-to-r from-indigo-300 to-violet-500",
  "bg-gradient-to-r from-indigo-300 to-violet-600",
  "bg-gradient-to-r from-indigo-300 to-violet-700",
  "bg-gradient-to-r from-indigo-300 to-violet-800",
  "bg-gradient-to-r from-indigo-300 to-violet-900",
  "bg-gradient-to-r from-indigo-300 to-violet-950",
  "bg-gradient-to-r from-indigo-300 to-purple-300",
  "bg-gradient-to-r from-indigo-300 to-purple-400",
  "bg-gradient-to-r from-indigo-300 to-purple-500",
  "bg-gradient-to-r from-indigo-300 to-purple-600",
  "bg-gradient-to-r from-indigo-300 to-purple-700",
  "bg-gradient-to-r from-indigo-300 to-purple-800",
  "bg-gradient-to-r from-indigo-300 to-purple-900",
  "bg-gradient-to-r from-indigo-300 to-purple-950",
  "bg-gradient-to-r from-indigo-300 to-fuchsia-300",
  "bg-gradient-to-r from-indigo-300 to-fuchsia-400",
  "bg-gradient-to-r from-indigo-300 to-fuchsia-500",
  "bg-gradient-to-r from-indigo-300 to-fuchsia-600",
  "bg-gradient-to-r from-indigo-300 to-fuchsia-700",
  "bg-gradient-to-r from-indigo-300 to-fuchsia-800",
  "bg-gradient-to-r from-indigo-300 to-fuchsia-900",
  "bg-gradient-to-r from-indigo-300 to-fuchsia-950",
  "bg-gradient-to-r from-indigo-300 to-pink-300",
  "bg-gradient-to-r from-indigo-300 to-pink-400",
  "bg-gradient-to-r from-indigo-300 to-pink-500",
  "bg-gradient-to-r from-indigo-300 to-pink-600",
  "bg-gradient-to-r from-indigo-300 to-pink-700",
  "bg-gradient-to-r from-indigo-300 to-pink-800",
  "bg-gradient-to-r from-indigo-300 to-pink-900",
  "bg-gradient-to-r from-indigo-300 to-pink-950",
  "bg-gradient-to-r from-indigo-300 to-rose-300",
  "bg-gradient-to-r from-indigo-300 to-rose-400",
  "bg-gradient-to-r from-indigo-300 to-rose-500",
  "bg-gradient-to-r from-indigo-300 to-rose-600",
  "bg-gradient-to-r from-indigo-300 to-rose-700",
  "bg-gradient-to-r from-indigo-300 to-rose-800",
  "bg-gradient-to-r from-indigo-300 to-rose-900",
  "bg-gradient-to-r from-indigo-300 to-rose-950",
  "bg-gradient-to-r from-indigo-400 to-red-300",
  "bg-gradient-to-r from-indigo-400 to-red-400",
  "bg-gradient-to-r from-indigo-400 to-red-500",
  "bg-gradient-to-r from-indigo-400 to-red-600",
  "bg-gradient-to-r from-indigo-400 to-red-700",
  "bg-gradient-to-r from-indigo-400 to-red-800",
  "bg-gradient-to-r from-indigo-400 to-red-900",
  "bg-gradient-to-r from-indigo-400 to-red-950",
  "bg-gradient-to-r from-indigo-400 to-orange-300",
  "bg-gradient-to-r from-indigo-400 to-orange-400",
  "bg-gradient-to-r from-indigo-400 to-orange-500",
  "bg-gradient-to-r from-indigo-400 to-orange-600",
  "bg-gradient-to-r from-indigo-400 to-orange-700",
  "bg-gradient-to-r from-indigo-400 to-orange-800",
  "bg-gradient-to-r from-indigo-400 to-orange-900",
  "bg-gradient-to-r from-indigo-400 to-orange-950",
  "bg-gradient-to-r from-indigo-400 to-amber-300",
  "bg-gradient-to-r from-indigo-400 to-amber-400",
  "bg-gradient-to-r from-indigo-400 to-amber-500",
  "bg-gradient-to-r from-indigo-400 to-amber-600",
  "bg-gradient-to-r from-indigo-400 to-amber-700",
  "bg-gradient-to-r from-indigo-400 to-amber-800",
  "bg-gradient-to-r from-indigo-400 to-amber-900",
  "bg-gradient-to-r from-indigo-400 to-amber-950",
  "bg-gradient-to-r from-indigo-400 to-yellow-300",
  "bg-gradient-to-r from-indigo-400 to-yellow-400",
  "bg-gradient-to-r from-indigo-400 to-yellow-500",
  "bg-gradient-to-r from-indigo-400 to-yellow-600",
  "bg-gradient-to-r from-indigo-400 to-yellow-700",
  "bg-gradient-to-r from-indigo-400 to-yellow-800",
  "bg-gradient-to-r from-indigo-400 to-yellow-900",
  "bg-gradient-to-r from-indigo-400 to-yellow-950",
  "bg-gradient-to-r from-indigo-400 to-lime-300",
  "bg-gradient-to-r from-indigo-400 to-lime-400",
  "bg-gradient-to-r from-indigo-400 to-lime-500",
  "bg-gradient-to-r from-indigo-400 to-lime-600",
  "bg-gradient-to-r from-indigo-400 to-lime-700",
  "bg-gradient-to-r from-indigo-400 to-lime-800",
  "bg-gradient-to-r from-indigo-400 to-lime-900",
  "bg-gradient-to-r from-indigo-400 to-lime-950",
  "bg-gradient-to-r from-indigo-400 to-green-300",
  "bg-gradient-to-r from-indigo-400 to-green-400",
  "bg-gradient-to-r from-indigo-400 to-green-500",
  "bg-gradient-to-r from-indigo-400 to-green-600",
  "bg-gradient-to-r from-indigo-400 to-green-700",
  "bg-gradient-to-r from-indigo-400 to-green-800",
  "bg-gradient-to-r from-indigo-400 to-green-900",
  "bg-gradient-to-r from-indigo-400 to-green-950",
  "bg-gradient-to-r from-indigo-400 to-emerald-300",
  "bg-gradient-to-r from-indigo-400 to-emerald-400",
  "bg-gradient-to-r from-indigo-400 to-emerald-500",
  "bg-gradient-to-r from-indigo-400 to-emerald-600",
  "bg-gradient-to-r from-indigo-400 to-emerald-700",
  "bg-gradient-to-r from-indigo-400 to-emerald-800",
  "bg-gradient-to-r from-indigo-400 to-emerald-900",
  "bg-gradient-to-r from-indigo-400 to-emerald-950",
  "bg-gradient-to-r from-indigo-400 to-teal-300",
  "bg-gradient-to-r from-indigo-400 to-teal-400",
  "bg-gradient-to-r from-indigo-400 to-teal-500",
  "bg-gradient-to-r from-indigo-400 to-teal-600",
  "bg-gradient-to-r from-indigo-400 to-teal-700",
  "bg-gradient-to-r from-indigo-400 to-teal-800",
  "bg-gradient-to-r from-indigo-400 to-teal-900",
  "bg-gradient-to-r from-indigo-400 to-teal-950",
  "bg-gradient-to-r from-indigo-400 to-cyan-300",
  "bg-gradient-to-r from-indigo-400 to-cyan-400",
  "bg-gradient-to-r from-indigo-400 to-cyan-500",
  "bg-gradient-to-r from-indigo-400 to-cyan-600",
  "bg-gradient-to-r from-indigo-400 to-cyan-700",
  "bg-gradient-to-r from-indigo-400 to-cyan-800",
  "bg-gradient-to-r from-indigo-400 to-cyan-900",
  "bg-gradient-to-r from-indigo-400 to-cyan-950",
  "bg-gradient-to-r from-indigo-400 to-sky-300",
  "bg-gradient-to-r from-indigo-400 to-sky-400",
  "bg-gradient-to-r from-indigo-400 to-sky-500",
  "bg-gradient-to-r from-indigo-400 to-sky-600",
  "bg-gradient-to-r from-indigo-400 to-sky-700",
  "bg-gradient-to-r from-indigo-400 to-sky-800",
  "bg-gradient-to-r from-indigo-400 to-sky-900",
  "bg-gradient-to-r from-indigo-400 to-sky-950",
  "bg-gradient-to-r from-indigo-400 to-blue-300",
  "bg-gradient-to-r from-indigo-400 to-blue-400",
  "bg-gradient-to-r from-indigo-400 to-blue-500",
  "bg-gradient-to-r from-indigo-400 to-blue-600",
  "bg-gradient-to-r from-indigo-400 to-blue-700",
  "bg-gradient-to-r from-indigo-400 to-blue-800",
  "bg-gradient-to-r from-indigo-400 to-blue-900",
  "bg-gradient-to-r from-indigo-400 to-blue-950",
  "bg-gradient-to-r from-indigo-400 to-violet-300",
  "bg-gradient-to-r from-indigo-400 to-violet-400",
  "bg-gradient-to-r from-indigo-400 to-violet-500",
  "bg-gradient-to-r from-indigo-400 to-violet-600",
  "bg-gradient-to-r from-indigo-400 to-violet-700",
  "bg-gradient-to-r from-indigo-400 to-violet-800",
  "bg-gradient-to-r from-indigo-400 to-violet-900",
  "bg-gradient-to-r from-indigo-400 to-violet-950",
  "bg-gradient-to-r from-indigo-400 to-purple-300",
  "bg-gradient-to-r from-indigo-400 to-purple-400",
  "bg-gradient-to-r from-indigo-400 to-purple-500",
  "bg-gradient-to-r from-indigo-400 to-purple-600",
  "bg-gradient-to-r from-indigo-400 to-purple-700",
  "bg-gradient-to-r from-indigo-400 to-purple-800",
  "bg-gradient-to-r from-indigo-400 to-purple-900",
  "bg-gradient-to-r from-indigo-400 to-purple-950",
  "bg-gradient-to-r from-indigo-400 to-fuchsia-300",
  "bg-gradient-to-r from-indigo-400 to-fuchsia-400",
  "bg-gradient-to-r from-indigo-400 to-fuchsia-500",
  "bg-gradient-to-r from-indigo-400 to-fuchsia-600",
  "bg-gradient-to-r from-indigo-400 to-fuchsia-700",
  "bg-gradient-to-r from-indigo-400 to-fuchsia-800",
  "bg-gradient-to-r from-indigo-400 to-fuchsia-900",
  "bg-gradient-to-r from-indigo-400 to-fuchsia-950",
  "bg-gradient-to-r from-indigo-400 to-pink-300",
  "bg-gradient-to-r from-indigo-400 to-pink-400",
  "bg-gradient-to-r from-indigo-400 to-pink-500",
  "bg-gradient-to-r from-indigo-400 to-pink-600",
  "bg-gradient-to-r from-indigo-400 to-pink-700",
  "bg-gradient-to-r from-indigo-400 to-pink-800",
  "bg-gradient-to-r from-indigo-400 to-pink-900",
  "bg-gradient-to-r from-indigo-400 to-pink-950",
  "bg-gradient-to-r from-indigo-400 to-rose-300",
  "bg-gradient-to-r from-indigo-400 to-rose-400",
  "bg-gradient-to-r from-indigo-400 to-rose-500",
  "bg-gradient-to-r from-indigo-400 to-rose-600",
  "bg-gradient-to-r from-indigo-400 to-rose-700",
  "bg-gradient-to-r from-indigo-400 to-rose-800",
  "bg-gradient-to-r from-indigo-400 to-rose-900",
  "bg-gradient-to-r from-indigo-400 to-rose-950",
  "bg-gradient-to-r from-indigo-500 to-red-300",
  "bg-gradient-to-r from-indigo-500 to-red-400",
  "bg-gradient-to-r from-indigo-500 to-red-500",
  "bg-gradient-to-r from-indigo-500 to-red-600",
  "bg-gradient-to-r from-indigo-500 to-red-700",
  "bg-gradient-to-r from-indigo-500 to-red-800",
  "bg-gradient-to-r from-indigo-500 to-red-900",
  "bg-gradient-to-r from-indigo-500 to-red-950",
  "bg-gradient-to-r from-indigo-500 to-orange-300",
  "bg-gradient-to-r from-indigo-500 to-orange-400",
  "bg-gradient-to-r from-indigo-500 to-orange-500",
  "bg-gradient-to-r from-indigo-500 to-orange-600",
  "bg-gradient-to-r from-indigo-500 to-orange-700",
  "bg-gradient-to-r from-indigo-500 to-orange-800",
  "bg-gradient-to-r from-indigo-500 to-orange-900",
  "bg-gradient-to-r from-indigo-500 to-orange-950",
  "bg-gradient-to-r from-indigo-500 to-amber-300",
  "bg-gradient-to-r from-indigo-500 to-amber-400",
  "bg-gradient-to-r from-indigo-500 to-amber-500",
  "bg-gradient-to-r from-indigo-500 to-amber-600",
  "bg-gradient-to-r from-indigo-500 to-amber-700",
  "bg-gradient-to-r from-indigo-500 to-amber-800",
  "bg-gradient-to-r from-indigo-500 to-amber-900",
  "bg-gradient-to-r from-indigo-500 to-amber-950",
  "bg-gradient-to-r from-indigo-500 to-yellow-300",
  "bg-gradient-to-r from-indigo-500 to-yellow-400",
  "bg-gradient-to-r from-indigo-500 to-yellow-500",
  "bg-gradient-to-r from-indigo-500 to-yellow-600",
  "bg-gradient-to-r from-indigo-500 to-yellow-700",
  "bg-gradient-to-r from-indigo-500 to-yellow-800",
  "bg-gradient-to-r from-indigo-500 to-yellow-900",
  "bg-gradient-to-r from-indigo-500 to-yellow-950",
  "bg-gradient-to-r from-indigo-500 to-lime-300",
  "bg-gradient-to-r from-indigo-500 to-lime-400",
  "bg-gradient-to-r from-indigo-500 to-lime-500",
  "bg-gradient-to-r from-indigo-500 to-lime-600",
  "bg-gradient-to-r from-indigo-500 to-lime-700",
  "bg-gradient-to-r from-indigo-500 to-lime-800",
  "bg-gradient-to-r from-indigo-500 to-lime-900",
  "bg-gradient-to-r from-indigo-500 to-lime-950",
  "bg-gradient-to-r from-indigo-500 to-green-300",
  "bg-gradient-to-r from-indigo-500 to-green-400",
  "bg-gradient-to-r from-indigo-500 to-green-500",
  "bg-gradient-to-r from-indigo-500 to-green-600",
  "bg-gradient-to-r from-indigo-500 to-green-700",
  "bg-gradient-to-r from-indigo-500 to-green-800",
  "bg-gradient-to-r from-indigo-500 to-green-900",
  "bg-gradient-to-r from-indigo-500 to-green-950",
  "bg-gradient-to-r from-indigo-500 to-emerald-300",
  "bg-gradient-to-r from-indigo-500 to-emerald-400",
  "bg-gradient-to-r from-indigo-500 to-emerald-500",
  "bg-gradient-to-r from-indigo-500 to-emerald-600",
  "bg-gradient-to-r from-indigo-500 to-emerald-700",
  "bg-gradient-to-r from-indigo-500 to-emerald-800",
  "bg-gradient-to-r from-indigo-500 to-emerald-900",
  "bg-gradient-to-r from-indigo-500 to-emerald-950",
  "bg-gradient-to-r from-indigo-500 to-teal-300",
  "bg-gradient-to-r from-indigo-500 to-teal-400",
  "bg-gradient-to-r from-indigo-500 to-teal-500",
  "bg-gradient-to-r from-indigo-500 to-teal-600",
  "bg-gradient-to-r from-indigo-500 to-teal-700",
  "bg-gradient-to-r from-indigo-500 to-teal-800",
  "bg-gradient-to-r from-indigo-500 to-teal-900",
  "bg-gradient-to-r from-indigo-500 to-teal-950",
  "bg-gradient-to-r from-indigo-500 to-cyan-300",
  "bg-gradient-to-r from-indigo-500 to-cyan-400",
  "bg-gradient-to-r from-indigo-500 to-cyan-500",
  "bg-gradient-to-r from-indigo-500 to-cyan-600",
  "bg-gradient-to-r from-indigo-500 to-cyan-700",
  "bg-gradient-to-r from-indigo-500 to-cyan-800",
  "bg-gradient-to-r from-indigo-500 to-cyan-900",
  "bg-gradient-to-r from-indigo-500 to-cyan-950",
  "bg-gradient-to-r from-indigo-500 to-sky-300",
  "bg-gradient-to-r from-indigo-500 to-sky-400",
  "bg-gradient-to-r from-indigo-500 to-sky-500",
  "bg-gradient-to-r from-indigo-500 to-sky-600",
  "bg-gradient-to-r from-indigo-500 to-sky-700",
  "bg-gradient-to-r from-indigo-500 to-sky-800",
  "bg-gradient-to-r from-indigo-500 to-sky-900",
  "bg-gradient-to-r from-indigo-500 to-sky-950",
  "bg-gradient-to-r from-indigo-500 to-blue-300",
  "bg-gradient-to-r from-indigo-500 to-blue-400",
  "bg-gradient-to-r from-indigo-500 to-blue-500",
  "bg-gradient-to-r from-indigo-500 to-blue-600",
  "bg-gradient-to-r from-indigo-500 to-blue-700",
  "bg-gradient-to-r from-indigo-500 to-blue-800",
  "bg-gradient-to-r from-indigo-500 to-blue-900",
  "bg-gradient-to-r from-indigo-500 to-blue-950",
  "bg-gradient-to-r from-indigo-500 to-violet-300",
  "bg-gradient-to-r from-indigo-500 to-violet-400",
  "bg-gradient-to-r from-indigo-500 to-violet-500",
  "bg-gradient-to-r from-indigo-500 to-violet-600",
  "bg-gradient-to-r from-indigo-500 to-violet-700",
  "bg-gradient-to-r from-indigo-500 to-violet-800",
  "bg-gradient-to-r from-indigo-500 to-violet-900",
  "bg-gradient-to-r from-indigo-500 to-violet-950",
  "bg-gradient-to-r from-indigo-500 to-purple-300",
  "bg-gradient-to-r from-indigo-500 to-purple-400",
  "bg-gradient-to-r from-indigo-500 to-purple-500",
  "bg-gradient-to-r from-indigo-500 to-purple-600",
  "bg-gradient-to-r from-indigo-500 to-purple-700",
  "bg-gradient-to-r from-indigo-500 to-purple-800",
  "bg-gradient-to-r from-indigo-500 to-purple-900",
  "bg-gradient-to-r from-indigo-500 to-purple-950",
  "bg-gradient-to-r from-indigo-500 to-fuchsia-300",
  "bg-gradient-to-r from-indigo-500 to-fuchsia-400",
  "bg-gradient-to-r from-indigo-500 to-fuchsia-500",
  "bg-gradient-to-r from-indigo-500 to-fuchsia-600",
  "bg-gradient-to-r from-indigo-500 to-fuchsia-700",
  "bg-gradient-to-r from-indigo-500 to-fuchsia-800",
  "bg-gradient-to-r from-indigo-500 to-fuchsia-900",
  "bg-gradient-to-r from-indigo-500 to-fuchsia-950",
  "bg-gradient-to-r from-indigo-500 to-pink-300",
  "bg-gradient-to-r from-indigo-500 to-pink-400",
  "bg-gradient-to-r from-indigo-500 to-pink-500",
  "bg-gradient-to-r from-indigo-500 to-pink-600",
  "bg-gradient-to-r from-indigo-500 to-pink-700",
  "bg-gradient-to-r from-indigo-500 to-pink-800",
  "bg-gradient-to-r from-indigo-500 to-pink-900",
  "bg-gradient-to-r from-indigo-500 to-pink-950",
  "bg-gradient-to-r from-indigo-500 to-rose-300",
  "bg-gradient-to-r from-indigo-500 to-rose-400",
  "bg-gradient-to-r from-indigo-500 to-rose-500",
  "bg-gradient-to-r from-indigo-500 to-rose-600",
  "bg-gradient-to-r from-indigo-500 to-rose-700",
  "bg-gradient-to-r from-indigo-500 to-rose-800",
  "bg-gradient-to-r from-indigo-500 to-rose-900",
  "bg-gradient-to-r from-indigo-500 to-rose-950",
  "bg-gradient-to-r from-indigo-600 to-red-300",
  "bg-gradient-to-r from-indigo-600 to-red-400",
  "bg-gradient-to-r from-indigo-600 to-red-500",
  "bg-gradient-to-r from-indigo-600 to-red-600",
  "bg-gradient-to-r from-indigo-600 to-red-700",
  "bg-gradient-to-r from-indigo-600 to-red-800",
  "bg-gradient-to-r from-indigo-600 to-red-900",
  "bg-gradient-to-r from-indigo-600 to-red-950",
  "bg-gradient-to-r from-indigo-600 to-orange-300",
  "bg-gradient-to-r from-indigo-600 to-orange-400",
  "bg-gradient-to-r from-indigo-600 to-orange-500",
  "bg-gradient-to-r from-indigo-600 to-orange-600",
  "bg-gradient-to-r from-indigo-600 to-orange-700",
  "bg-gradient-to-r from-indigo-600 to-orange-800",
  "bg-gradient-to-r from-indigo-600 to-orange-900",
  "bg-gradient-to-r from-indigo-600 to-orange-950",
  "bg-gradient-to-r from-indigo-600 to-amber-300",
  "bg-gradient-to-r from-indigo-600 to-amber-400",
  "bg-gradient-to-r from-indigo-600 to-amber-500",
  "bg-gradient-to-r from-indigo-600 to-amber-600",
  "bg-gradient-to-r from-indigo-600 to-amber-700",
  "bg-gradient-to-r from-indigo-600 to-amber-800",
  "bg-gradient-to-r from-indigo-600 to-amber-900",
  "bg-gradient-to-r from-indigo-600 to-amber-950",
  "bg-gradient-to-r from-indigo-600 to-yellow-300",
  "bg-gradient-to-r from-indigo-600 to-yellow-400",
  "bg-gradient-to-r from-indigo-600 to-yellow-500",
  "bg-gradient-to-r from-indigo-600 to-yellow-600",
  "bg-gradient-to-r from-indigo-600 to-yellow-700",
  "bg-gradient-to-r from-indigo-600 to-yellow-800",
  "bg-gradient-to-r from-indigo-600 to-yellow-900",
  "bg-gradient-to-r from-indigo-600 to-yellow-950",
  "bg-gradient-to-r from-indigo-600 to-lime-300",
  "bg-gradient-to-r from-indigo-600 to-lime-400",
  "bg-gradient-to-r from-indigo-600 to-lime-500",
  "bg-gradient-to-r from-indigo-600 to-lime-600",
  "bg-gradient-to-r from-indigo-600 to-lime-700",
  "bg-gradient-to-r from-indigo-600 to-lime-800",
  "bg-gradient-to-r from-indigo-600 to-lime-900",
  "bg-gradient-to-r from-indigo-600 to-lime-950",
  "bg-gradient-to-r from-indigo-600 to-green-300",
  "bg-gradient-to-r from-indigo-600 to-green-400",
  "bg-gradient-to-r from-indigo-600 to-green-500",
  "bg-gradient-to-r from-indigo-600 to-green-600",
  "bg-gradient-to-r from-indigo-600 to-green-700",
  "bg-gradient-to-r from-indigo-600 to-green-800",
  "bg-gradient-to-r from-indigo-600 to-green-900",
  "bg-gradient-to-r from-indigo-600 to-green-950",
  "bg-gradient-to-r from-indigo-600 to-emerald-300",
  "bg-gradient-to-r from-indigo-600 to-emerald-400",
  "bg-gradient-to-r from-indigo-600 to-emerald-500",
  "bg-gradient-to-r from-indigo-600 to-emerald-600",
  "bg-gradient-to-r from-indigo-600 to-emerald-700",
  "bg-gradient-to-r from-indigo-600 to-emerald-800",
  "bg-gradient-to-r from-indigo-600 to-emerald-900",
  "bg-gradient-to-r from-indigo-600 to-emerald-950",
  "bg-gradient-to-r from-indigo-600 to-teal-300",
  "bg-gradient-to-r from-indigo-600 to-teal-400",
  "bg-gradient-to-r from-indigo-600 to-teal-500",
  "bg-gradient-to-r from-indigo-600 to-teal-600",
  "bg-gradient-to-r from-indigo-600 to-teal-700",
  "bg-gradient-to-r from-indigo-600 to-teal-800",
  "bg-gradient-to-r from-indigo-600 to-teal-900",
  "bg-gradient-to-r from-indigo-600 to-teal-950",
  "bg-gradient-to-r from-indigo-600 to-cyan-300",
  "bg-gradient-to-r from-indigo-600 to-cyan-400",
  "bg-gradient-to-r from-indigo-600 to-cyan-500",
  "bg-gradient-to-r from-indigo-600 to-cyan-600",
  "bg-gradient-to-r from-indigo-600 to-cyan-700",
  "bg-gradient-to-r from-indigo-600 to-cyan-800",
  "bg-gradient-to-r from-indigo-600 to-cyan-900",
  "bg-gradient-to-r from-indigo-600 to-cyan-950",
  "bg-gradient-to-r from-indigo-600 to-sky-300",
  "bg-gradient-to-r from-indigo-600 to-sky-400",
  "bg-gradient-to-r from-indigo-600 to-sky-500",
  "bg-gradient-to-r from-indigo-600 to-sky-600",
  "bg-gradient-to-r from-indigo-600 to-sky-700",
  "bg-gradient-to-r from-indigo-600 to-sky-800",
  "bg-gradient-to-r from-indigo-600 to-sky-900",
  "bg-gradient-to-r from-indigo-600 to-sky-950",
  "bg-gradient-to-r from-indigo-600 to-blue-300",
  "bg-gradient-to-r from-indigo-600 to-blue-400",
  "bg-gradient-to-r from-indigo-600 to-blue-500",
  "bg-gradient-to-r from-indigo-600 to-blue-600",
  "bg-gradient-to-r from-indigo-600 to-blue-700",
  "bg-gradient-to-r from-indigo-600 to-blue-800",
  "bg-gradient-to-r from-indigo-600 to-blue-900",
  "bg-gradient-to-r from-indigo-600 to-blue-950",
  "bg-gradient-to-r from-indigo-600 to-violet-300",
  "bg-gradient-to-r from-indigo-600 to-violet-400",
  "bg-gradient-to-r from-indigo-600 to-violet-500",
  "bg-gradient-to-r from-indigo-600 to-violet-600",
  "bg-gradient-to-r from-indigo-600 to-violet-700",
  "bg-gradient-to-r from-indigo-600 to-violet-800",
  "bg-gradient-to-r from-indigo-600 to-violet-900",
  "bg-gradient-to-r from-indigo-600 to-violet-950",
  "bg-gradient-to-r from-indigo-600 to-purple-300",
  "bg-gradient-to-r from-indigo-600 to-purple-400",
  "bg-gradient-to-r from-indigo-600 to-purple-500",
  "bg-gradient-to-r from-indigo-600 to-purple-600",
  "bg-gradient-to-r from-indigo-600 to-purple-700",
  "bg-gradient-to-r from-indigo-600 to-purple-800",
  "bg-gradient-to-r from-indigo-600 to-purple-900",
  "bg-gradient-to-r from-indigo-600 to-purple-950",
  "bg-gradient-to-r from-indigo-600 to-fuchsia-300",
  "bg-gradient-to-r from-indigo-600 to-fuchsia-400",
  "bg-gradient-to-r from-indigo-600 to-fuchsia-500",
  "bg-gradient-to-r from-indigo-600 to-fuchsia-600",
  "bg-gradient-to-r from-indigo-600 to-fuchsia-700",
  "bg-gradient-to-r from-indigo-600 to-fuchsia-800",
  "bg-gradient-to-r from-indigo-600 to-fuchsia-900",
  "bg-gradient-to-r from-indigo-600 to-fuchsia-950",
  "bg-gradient-to-r from-indigo-600 to-pink-300",
  "bg-gradient-to-r from-indigo-600 to-pink-400",
  "bg-gradient-to-r from-indigo-600 to-pink-500",
  "bg-gradient-to-r from-indigo-600 to-pink-600",
  "bg-gradient-to-r from-indigo-600 to-pink-700",
  "bg-gradient-to-r from-indigo-600 to-pink-800",
  "bg-gradient-to-r from-indigo-600 to-pink-900",
  "bg-gradient-to-r from-indigo-600 to-pink-950",
  "bg-gradient-to-r from-indigo-600 to-rose-300",
  "bg-gradient-to-r from-indigo-600 to-rose-400",
  "bg-gradient-to-r from-indigo-600 to-rose-500",
  "bg-gradient-to-r from-indigo-600 to-rose-600",
  "bg-gradient-to-r from-indigo-600 to-rose-700",
  "bg-gradient-to-r from-indigo-600 to-rose-800",
  "bg-gradient-to-r from-indigo-600 to-rose-900",
  "bg-gradient-to-r from-indigo-600 to-rose-950",
  "bg-gradient-to-r from-indigo-700 to-red-300",
  "bg-gradient-to-r from-indigo-700 to-red-400",
  "bg-gradient-to-r from-indigo-700 to-red-500",
  "bg-gradient-to-r from-indigo-700 to-red-600",
  "bg-gradient-to-r from-indigo-700 to-red-700",
  "bg-gradient-to-r from-indigo-700 to-red-800",
  "bg-gradient-to-r from-indigo-700 to-red-900",
  "bg-gradient-to-r from-indigo-700 to-red-950",
  "bg-gradient-to-r from-indigo-700 to-orange-300",
  "bg-gradient-to-r from-indigo-700 to-orange-400",
  "bg-gradient-to-r from-indigo-700 to-orange-500",
  "bg-gradient-to-r from-indigo-700 to-orange-600",
  "bg-gradient-to-r from-indigo-700 to-orange-700",
  "bg-gradient-to-r from-indigo-700 to-orange-800",
  "bg-gradient-to-r from-indigo-700 to-orange-900",
  "bg-gradient-to-r from-indigo-700 to-orange-950",
  "bg-gradient-to-r from-indigo-700 to-amber-300",
  "bg-gradient-to-r from-indigo-700 to-amber-400",
  "bg-gradient-to-r from-indigo-700 to-amber-500",
  "bg-gradient-to-r from-indigo-700 to-amber-600",
  "bg-gradient-to-r from-indigo-700 to-amber-700",
  "bg-gradient-to-r from-indigo-700 to-amber-800",
  "bg-gradient-to-r from-indigo-700 to-amber-900",
  "bg-gradient-to-r from-indigo-700 to-amber-950",
  "bg-gradient-to-r from-indigo-700 to-yellow-300",
  "bg-gradient-to-r from-indigo-700 to-yellow-400",
  "bg-gradient-to-r from-indigo-700 to-yellow-500",
  "bg-gradient-to-r from-indigo-700 to-yellow-600",
  "bg-gradient-to-r from-indigo-700 to-yellow-700",
  "bg-gradient-to-r from-indigo-700 to-yellow-800",
  "bg-gradient-to-r from-indigo-700 to-yellow-900",
  "bg-gradient-to-r from-indigo-700 to-yellow-950",
  "bg-gradient-to-r from-indigo-700 to-lime-300",
  "bg-gradient-to-r from-indigo-700 to-lime-400",
  "bg-gradient-to-r from-indigo-700 to-lime-500",
  "bg-gradient-to-r from-indigo-700 to-lime-600",
  "bg-gradient-to-r from-indigo-700 to-lime-700",
  "bg-gradient-to-r from-indigo-700 to-lime-800",
  "bg-gradient-to-r from-indigo-700 to-lime-900",
  "bg-gradient-to-r from-indigo-700 to-lime-950",
  "bg-gradient-to-r from-indigo-700 to-green-300",
  "bg-gradient-to-r from-indigo-700 to-green-400",
  "bg-gradient-to-r from-indigo-700 to-green-500",
  "bg-gradient-to-r from-indigo-700 to-green-600",
  "bg-gradient-to-r from-indigo-700 to-green-700",
  "bg-gradient-to-r from-indigo-700 to-green-800",
  "bg-gradient-to-r from-indigo-700 to-green-900",
  "bg-gradient-to-r from-indigo-700 to-green-950",
  "bg-gradient-to-r from-indigo-700 to-emerald-300",
  "bg-gradient-to-r from-indigo-700 to-emerald-400",
  "bg-gradient-to-r from-indigo-700 to-emerald-500",
  "bg-gradient-to-r from-indigo-700 to-emerald-600",
  "bg-gradient-to-r from-indigo-700 to-emerald-700",
  "bg-gradient-to-r from-indigo-700 to-emerald-800",
  "bg-gradient-to-r from-indigo-700 to-emerald-900",
  "bg-gradient-to-r from-indigo-700 to-emerald-950",
  "bg-gradient-to-r from-indigo-700 to-teal-300",
  "bg-gradient-to-r from-indigo-700 to-teal-400",
  "bg-gradient-to-r from-indigo-700 to-teal-500",
  "bg-gradient-to-r from-indigo-700 to-teal-600",
  "bg-gradient-to-r from-indigo-700 to-teal-700",
  "bg-gradient-to-r from-indigo-700 to-teal-800",
  "bg-gradient-to-r from-indigo-700 to-teal-900",
  "bg-gradient-to-r from-indigo-700 to-teal-950",
  "bg-gradient-to-r from-indigo-700 to-cyan-300",
  "bg-gradient-to-r from-indigo-700 to-cyan-400",
  "bg-gradient-to-r from-indigo-700 to-cyan-500",
  "bg-gradient-to-r from-indigo-700 to-cyan-600",
  "bg-gradient-to-r from-indigo-700 to-cyan-700",
  "bg-gradient-to-r from-indigo-700 to-cyan-800",
  "bg-gradient-to-r from-indigo-700 to-cyan-900",
  "bg-gradient-to-r from-indigo-700 to-cyan-950",
  "bg-gradient-to-r from-indigo-700 to-sky-300",
  "bg-gradient-to-r from-indigo-700 to-sky-400",
  "bg-gradient-to-r from-indigo-700 to-sky-500",
  "bg-gradient-to-r from-indigo-700 to-sky-600",
  "bg-gradient-to-r from-indigo-700 to-sky-700",
  "bg-gradient-to-r from-indigo-700 to-sky-800",
  "bg-gradient-to-r from-indigo-700 to-sky-900",
  "bg-gradient-to-r from-indigo-700 to-sky-950",
  "bg-gradient-to-r from-indigo-700 to-blue-300",
  "bg-gradient-to-r from-indigo-700 to-blue-400",
  "bg-gradient-to-r from-indigo-700 to-blue-500",
  "bg-gradient-to-r from-indigo-700 to-blue-600",
  "bg-gradient-to-r from-indigo-700 to-blue-700",
  "bg-gradient-to-r from-indigo-700 to-blue-800",
  "bg-gradient-to-r from-indigo-700 to-blue-900",
  "bg-gradient-to-r from-indigo-700 to-blue-950",
  "bg-gradient-to-r from-indigo-700 to-violet-300",
  "bg-gradient-to-r from-indigo-700 to-violet-400",
  "bg-gradient-to-r from-indigo-700 to-violet-500",
  "bg-gradient-to-r from-indigo-700 to-violet-600",
  "bg-gradient-to-r from-indigo-700 to-violet-700",
  "bg-gradient-to-r from-indigo-700 to-violet-800",
  "bg-gradient-to-r from-indigo-700 to-violet-900",
  "bg-gradient-to-r from-indigo-700 to-violet-950",
  "bg-gradient-to-r from-indigo-700 to-purple-300",
  "bg-gradient-to-r from-indigo-700 to-purple-400",
  "bg-gradient-to-r from-indigo-700 to-purple-500",
  "bg-gradient-to-r from-indigo-700 to-purple-600",
  "bg-gradient-to-r from-indigo-700 to-purple-700",
  "bg-gradient-to-r from-indigo-700 to-purple-800",
  "bg-gradient-to-r from-indigo-700 to-purple-900",
  "bg-gradient-to-r from-indigo-700 to-purple-950",
  "bg-gradient-to-r from-indigo-700 to-fuchsia-300",
  "bg-gradient-to-r from-indigo-700 to-fuchsia-400",
  "bg-gradient-to-r from-indigo-700 to-fuchsia-500",
  "bg-gradient-to-r from-indigo-700 to-fuchsia-600",
  "bg-gradient-to-r from-indigo-700 to-fuchsia-700",
  "bg-gradient-to-r from-indigo-700 to-fuchsia-800",
  "bg-gradient-to-r from-indigo-700 to-fuchsia-900",
  "bg-gradient-to-r from-indigo-700 to-fuchsia-950",
  "bg-gradient-to-r from-indigo-700 to-pink-300",
  "bg-gradient-to-r from-indigo-700 to-pink-400",
  "bg-gradient-to-r from-indigo-700 to-pink-500",
  "bg-gradient-to-r from-indigo-700 to-pink-600",
  "bg-gradient-to-r from-indigo-700 to-pink-700",
  "bg-gradient-to-r from-indigo-700 to-pink-800",
  "bg-gradient-to-r from-indigo-700 to-pink-900",
  "bg-gradient-to-r from-indigo-700 to-pink-950",
  "bg-gradient-to-r from-indigo-700 to-rose-300",
  "bg-gradient-to-r from-indigo-700 to-rose-400",
  "bg-gradient-to-r from-indigo-700 to-rose-500",
  "bg-gradient-to-r from-indigo-700 to-rose-600",
  "bg-gradient-to-r from-indigo-700 to-rose-700",
  "bg-gradient-to-r from-indigo-700 to-rose-800",
  "bg-gradient-to-r from-indigo-700 to-rose-900",
  "bg-gradient-to-r from-indigo-700 to-rose-950",
  "bg-gradient-to-r from-indigo-800 to-red-300",
  "bg-gradient-to-r from-indigo-800 to-red-400",
  "bg-gradient-to-r from-indigo-800 to-red-500",
  "bg-gradient-to-r from-indigo-800 to-red-600",
  "bg-gradient-to-r from-indigo-800 to-red-700",
  "bg-gradient-to-r from-indigo-800 to-red-800",
  "bg-gradient-to-r from-indigo-800 to-red-900",
  "bg-gradient-to-r from-indigo-800 to-red-950",
  "bg-gradient-to-r from-indigo-800 to-orange-300",
  "bg-gradient-to-r from-indigo-800 to-orange-400",
  "bg-gradient-to-r from-indigo-800 to-orange-500",
  "bg-gradient-to-r from-indigo-800 to-orange-600",
  "bg-gradient-to-r from-indigo-800 to-orange-700",
  "bg-gradient-to-r from-indigo-800 to-orange-800",
  "bg-gradient-to-r from-indigo-800 to-orange-900",
  "bg-gradient-to-r from-indigo-800 to-orange-950",
  "bg-gradient-to-r from-indigo-800 to-amber-300",
  "bg-gradient-to-r from-indigo-800 to-amber-400",
  "bg-gradient-to-r from-indigo-800 to-amber-500",
  "bg-gradient-to-r from-indigo-800 to-amber-600",
  "bg-gradient-to-r from-indigo-800 to-amber-700",
  "bg-gradient-to-r from-indigo-800 to-amber-800",
  "bg-gradient-to-r from-indigo-800 to-amber-900",
  "bg-gradient-to-r from-indigo-800 to-amber-950",
  "bg-gradient-to-r from-indigo-800 to-yellow-300",
  "bg-gradient-to-r from-indigo-800 to-yellow-400",
  "bg-gradient-to-r from-indigo-800 to-yellow-500",
  "bg-gradient-to-r from-indigo-800 to-yellow-600",
  "bg-gradient-to-r from-indigo-800 to-yellow-700",
  "bg-gradient-to-r from-indigo-800 to-yellow-800",
  "bg-gradient-to-r from-indigo-800 to-yellow-900",
  "bg-gradient-to-r from-indigo-800 to-yellow-950",
  "bg-gradient-to-r from-indigo-800 to-lime-300",
  "bg-gradient-to-r from-indigo-800 to-lime-400",
  "bg-gradient-to-r from-indigo-800 to-lime-500",
  "bg-gradient-to-r from-indigo-800 to-lime-600",
  "bg-gradient-to-r from-indigo-800 to-lime-700",
  "bg-gradient-to-r from-indigo-800 to-lime-800",
  "bg-gradient-to-r from-indigo-800 to-lime-900",
  "bg-gradient-to-r from-indigo-800 to-lime-950",
  "bg-gradient-to-r from-indigo-800 to-green-300",
  "bg-gradient-to-r from-indigo-800 to-green-400",
  "bg-gradient-to-r from-indigo-800 to-green-500",
  "bg-gradient-to-r from-indigo-800 to-green-600",
  "bg-gradient-to-r from-indigo-800 to-green-700",
  "bg-gradient-to-r from-indigo-800 to-green-800",
  "bg-gradient-to-r from-indigo-800 to-green-900",
  "bg-gradient-to-r from-indigo-800 to-green-950",
  "bg-gradient-to-r from-indigo-800 to-emerald-300",
  "bg-gradient-to-r from-indigo-800 to-emerald-400",
  "bg-gradient-to-r from-indigo-800 to-emerald-500",
  "bg-gradient-to-r from-indigo-800 to-emerald-600",
  "bg-gradient-to-r from-indigo-800 to-emerald-700",
  "bg-gradient-to-r from-indigo-800 to-emerald-800",
  "bg-gradient-to-r from-indigo-800 to-emerald-900",
  "bg-gradient-to-r from-indigo-800 to-emerald-950",
  "bg-gradient-to-r from-indigo-800 to-teal-300",
  "bg-gradient-to-r from-indigo-800 to-teal-400",
  "bg-gradient-to-r from-indigo-800 to-teal-500",
  "bg-gradient-to-r from-indigo-800 to-teal-600",
  "bg-gradient-to-r from-indigo-800 to-teal-700",
  "bg-gradient-to-r from-indigo-800 to-teal-800",
  "bg-gradient-to-r from-indigo-800 to-teal-900",
  "bg-gradient-to-r from-indigo-800 to-teal-950",
  "bg-gradient-to-r from-indigo-800 to-cyan-300",
  "bg-gradient-to-r from-indigo-800 to-cyan-400",
  "bg-gradient-to-r from-indigo-800 to-cyan-500",
  "bg-gradient-to-r from-indigo-800 to-cyan-600",
  "bg-gradient-to-r from-indigo-800 to-cyan-700",
  "bg-gradient-to-r from-indigo-800 to-cyan-800",
  "bg-gradient-to-r from-indigo-800 to-cyan-900",
  "bg-gradient-to-r from-indigo-800 to-cyan-950",
  "bg-gradient-to-r from-indigo-800 to-sky-300",
  "bg-gradient-to-r from-indigo-800 to-sky-400",
  "bg-gradient-to-r from-indigo-800 to-sky-500",
  "bg-gradient-to-r from-indigo-800 to-sky-600",
  "bg-gradient-to-r from-indigo-800 to-sky-700",
  "bg-gradient-to-r from-indigo-800 to-sky-800",
  "bg-gradient-to-r from-indigo-800 to-sky-900",
  "bg-gradient-to-r from-indigo-800 to-sky-950",
  "bg-gradient-to-r from-indigo-800 to-blue-300",
  "bg-gradient-to-r from-indigo-800 to-blue-400",
  "bg-gradient-to-r from-indigo-800 to-blue-500",
  "bg-gradient-to-r from-indigo-800 to-blue-600",
  "bg-gradient-to-r from-indigo-800 to-blue-700",
  "bg-gradient-to-r from-indigo-800 to-blue-800",
  "bg-gradient-to-r from-indigo-800 to-blue-900",
  "bg-gradient-to-r from-indigo-800 to-blue-950",
  "bg-gradient-to-r from-indigo-800 to-violet-300",
  "bg-gradient-to-r from-indigo-800 to-violet-400",
  "bg-gradient-to-r from-indigo-800 to-violet-500",
  "bg-gradient-to-r from-indigo-800 to-violet-600",
  "bg-gradient-to-r from-indigo-800 to-violet-700",
  "bg-gradient-to-r from-indigo-800 to-violet-800",
  "bg-gradient-to-r from-indigo-800 to-violet-900",
  "bg-gradient-to-r from-indigo-800 to-violet-950",
  "bg-gradient-to-r from-indigo-800 to-purple-300",
  "bg-gradient-to-r from-indigo-800 to-purple-400",
  "bg-gradient-to-r from-indigo-800 to-purple-500",
  "bg-gradient-to-r from-indigo-800 to-purple-600",
  "bg-gradient-to-r from-indigo-800 to-purple-700",
  "bg-gradient-to-r from-indigo-800 to-purple-800",
  "bg-gradient-to-r from-indigo-800 to-purple-900",
  "bg-gradient-to-r from-indigo-800 to-purple-950",
  "bg-gradient-to-r from-indigo-800 to-fuchsia-300",
  "bg-gradient-to-r from-indigo-800 to-fuchsia-400",
  "bg-gradient-to-r from-indigo-800 to-fuchsia-500",
  "bg-gradient-to-r from-indigo-800 to-fuchsia-600",
  "bg-gradient-to-r from-indigo-800 to-fuchsia-700",
  "bg-gradient-to-r from-indigo-800 to-fuchsia-800",
  "bg-gradient-to-r from-indigo-800 to-fuchsia-900",
  "bg-gradient-to-r from-indigo-800 to-fuchsia-950",
  "bg-gradient-to-r from-indigo-800 to-pink-300",
  "bg-gradient-to-r from-indigo-800 to-pink-400",
  "bg-gradient-to-r from-indigo-800 to-pink-500",
  "bg-gradient-to-r from-indigo-800 to-pink-600",
  "bg-gradient-to-r from-indigo-800 to-pink-700",
  "bg-gradient-to-r from-indigo-800 to-pink-800",
  "bg-gradient-to-r from-indigo-800 to-pink-900",
  "bg-gradient-to-r from-indigo-800 to-pink-950",
  "bg-gradient-to-r from-indigo-800 to-rose-300",
  "bg-gradient-to-r from-indigo-800 to-rose-400",
  "bg-gradient-to-r from-indigo-800 to-rose-500",
  "bg-gradient-to-r from-indigo-800 to-rose-600",
  "bg-gradient-to-r from-indigo-800 to-rose-700",
  "bg-gradient-to-r from-indigo-800 to-rose-800",
  "bg-gradient-to-r from-indigo-800 to-rose-900",
  "bg-gradient-to-r from-indigo-800 to-rose-950",
  "bg-gradient-to-r from-indigo-900 to-red-300",
  "bg-gradient-to-r from-indigo-900 to-red-400",
  "bg-gradient-to-r from-indigo-900 to-red-500",
  "bg-gradient-to-r from-indigo-900 to-red-600",
  "bg-gradient-to-r from-indigo-900 to-red-700",
  "bg-gradient-to-r from-indigo-900 to-red-800",
  "bg-gradient-to-r from-indigo-900 to-red-900",
  "bg-gradient-to-r from-indigo-900 to-red-950",
  "bg-gradient-to-r from-indigo-900 to-orange-300",
  "bg-gradient-to-r from-indigo-900 to-orange-400",
  "bg-gradient-to-r from-indigo-900 to-orange-500",
  "bg-gradient-to-r from-indigo-900 to-orange-600",
  "bg-gradient-to-r from-indigo-900 to-orange-700",
  "bg-gradient-to-r from-indigo-900 to-orange-800",
  "bg-gradient-to-r from-indigo-900 to-orange-900",
  "bg-gradient-to-r from-indigo-900 to-orange-950",
  "bg-gradient-to-r from-indigo-900 to-amber-300",
  "bg-gradient-to-r from-indigo-900 to-amber-400",
  "bg-gradient-to-r from-indigo-900 to-amber-500",
  "bg-gradient-to-r from-indigo-900 to-amber-600",
  "bg-gradient-to-r from-indigo-900 to-amber-700",
  "bg-gradient-to-r from-indigo-900 to-amber-800",
  "bg-gradient-to-r from-indigo-900 to-amber-900",
  "bg-gradient-to-r from-indigo-900 to-amber-950",
  "bg-gradient-to-r from-indigo-900 to-yellow-300",
  "bg-gradient-to-r from-indigo-900 to-yellow-400",
  "bg-gradient-to-r from-indigo-900 to-yellow-500",
  "bg-gradient-to-r from-indigo-900 to-yellow-600",
  "bg-gradient-to-r from-indigo-900 to-yellow-700",
  "bg-gradient-to-r from-indigo-900 to-yellow-800",
  "bg-gradient-to-r from-indigo-900 to-yellow-900",
  "bg-gradient-to-r from-indigo-900 to-yellow-950",
  "bg-gradient-to-r from-indigo-900 to-lime-300",
  "bg-gradient-to-r from-indigo-900 to-lime-400",
  "bg-gradient-to-r from-indigo-900 to-lime-500",
  "bg-gradient-to-r from-indigo-900 to-lime-600",
  "bg-gradient-to-r from-indigo-900 to-lime-700",
  "bg-gradient-to-r from-indigo-900 to-lime-800",
  "bg-gradient-to-r from-indigo-900 to-lime-900",
  "bg-gradient-to-r from-indigo-900 to-lime-950",
  "bg-gradient-to-r from-indigo-900 to-green-300",
  "bg-gradient-to-r from-indigo-900 to-green-400",
  "bg-gradient-to-r from-indigo-900 to-green-500",
  "bg-gradient-to-r from-indigo-900 to-green-600",
  "bg-gradient-to-r from-indigo-900 to-green-700",
  "bg-gradient-to-r from-indigo-900 to-green-800",
  "bg-gradient-to-r from-indigo-900 to-green-900",
  "bg-gradient-to-r from-indigo-900 to-green-950",
  "bg-gradient-to-r from-indigo-900 to-emerald-300",
  "bg-gradient-to-r from-indigo-900 to-emerald-400",
  "bg-gradient-to-r from-indigo-900 to-emerald-500",
  "bg-gradient-to-r from-indigo-900 to-emerald-600",
  "bg-gradient-to-r from-indigo-900 to-emerald-700",
  "bg-gradient-to-r from-indigo-900 to-emerald-800",
  "bg-gradient-to-r from-indigo-900 to-emerald-900",
  "bg-gradient-to-r from-indigo-900 to-emerald-950",
  "bg-gradient-to-r from-indigo-900 to-teal-300",
  "bg-gradient-to-r from-indigo-900 to-teal-400",
  "bg-gradient-to-r from-indigo-900 to-teal-500",
  "bg-gradient-to-r from-indigo-900 to-teal-600",
  "bg-gradient-to-r from-indigo-900 to-teal-700",
  "bg-gradient-to-r from-indigo-900 to-teal-800",
  "bg-gradient-to-r from-indigo-900 to-teal-900",
  "bg-gradient-to-r from-indigo-900 to-teal-950",
  "bg-gradient-to-r from-indigo-900 to-cyan-300",
  "bg-gradient-to-r from-indigo-900 to-cyan-400",
  "bg-gradient-to-r from-indigo-900 to-cyan-500",
  "bg-gradient-to-r from-indigo-900 to-cyan-600",
  "bg-gradient-to-r from-indigo-900 to-cyan-700",
  "bg-gradient-to-r from-indigo-900 to-cyan-800",
  "bg-gradient-to-r from-indigo-900 to-cyan-900",
  "bg-gradient-to-r from-indigo-900 to-cyan-950",
  "bg-gradient-to-r from-indigo-900 to-sky-300",
  "bg-gradient-to-r from-indigo-900 to-sky-400",
  "bg-gradient-to-r from-indigo-900 to-sky-500",
  "bg-gradient-to-r from-indigo-900 to-sky-600",
  "bg-gradient-to-r from-indigo-900 to-sky-700",
  "bg-gradient-to-r from-indigo-900 to-sky-800",
  "bg-gradient-to-r from-indigo-900 to-sky-900",
  "bg-gradient-to-r from-indigo-900 to-sky-950",
  "bg-gradient-to-r from-indigo-900 to-blue-300",
  "bg-gradient-to-r from-indigo-900 to-blue-400",
  "bg-gradient-to-r from-indigo-900 to-blue-500",
  "bg-gradient-to-r from-indigo-900 to-blue-600",
  "bg-gradient-to-r from-indigo-900 to-blue-700",
  "bg-gradient-to-r from-indigo-900 to-blue-800",
  "bg-gradient-to-r from-indigo-900 to-blue-900",
  "bg-gradient-to-r from-indigo-900 to-blue-950",
  "bg-gradient-to-r from-indigo-900 to-violet-300",
  "bg-gradient-to-r from-indigo-900 to-violet-400",
  "bg-gradient-to-r from-indigo-900 to-violet-500",
  "bg-gradient-to-r from-indigo-900 to-violet-600",
  "bg-gradient-to-r from-indigo-900 to-violet-700",
  "bg-gradient-to-r from-indigo-900 to-violet-800",
  "bg-gradient-to-r from-indigo-900 to-violet-900",
  "bg-gradient-to-r from-indigo-900 to-violet-950",
  "bg-gradient-to-r from-indigo-900 to-purple-300",
  "bg-gradient-to-r from-indigo-900 to-purple-400",
  "bg-gradient-to-r from-indigo-900 to-purple-500",
  "bg-gradient-to-r from-indigo-900 to-purple-600",
  "bg-gradient-to-r from-indigo-900 to-purple-700",
  "bg-gradient-to-r from-indigo-900 to-purple-800",
  "bg-gradient-to-r from-indigo-900 to-purple-900",
  "bg-gradient-to-r from-indigo-900 to-purple-950",
  "bg-gradient-to-r from-indigo-900 to-fuchsia-300",
  "bg-gradient-to-r from-indigo-900 to-fuchsia-400",
  "bg-gradient-to-r from-indigo-900 to-fuchsia-500",
  "bg-gradient-to-r from-indigo-900 to-fuchsia-600",
  "bg-gradient-to-r from-indigo-900 to-fuchsia-700",
  "bg-gradient-to-r from-indigo-900 to-fuchsia-800",
  "bg-gradient-to-r from-indigo-900 to-fuchsia-900",
  "bg-gradient-to-r from-indigo-900 to-fuchsia-950",
  "bg-gradient-to-r from-indigo-900 to-pink-300",
  "bg-gradient-to-r from-indigo-900 to-pink-400",
  "bg-gradient-to-r from-indigo-900 to-pink-500",
  "bg-gradient-to-r from-indigo-900 to-pink-600",
  "bg-gradient-to-r from-indigo-900 to-pink-700",
  "bg-gradient-to-r from-indigo-900 to-pink-800",
  "bg-gradient-to-r from-indigo-900 to-pink-900",
  "bg-gradient-to-r from-indigo-900 to-pink-950",
  "bg-gradient-to-r from-indigo-900 to-rose-300",
  "bg-gradient-to-r from-indigo-900 to-rose-400",
  "bg-gradient-to-r from-indigo-900 to-rose-500",
  "bg-gradient-to-r from-indigo-900 to-rose-600",
  "bg-gradient-to-r from-indigo-900 to-rose-700",
  "bg-gradient-to-r from-indigo-900 to-rose-800",
  "bg-gradient-to-r from-indigo-900 to-rose-900",
  "bg-gradient-to-r from-indigo-900 to-rose-950",
  "bg-gradient-to-r from-indigo-950 to-red-300",
  "bg-gradient-to-r from-indigo-950 to-red-400",
  "bg-gradient-to-r from-indigo-950 to-red-500",
  "bg-gradient-to-r from-indigo-950 to-red-600",
  "bg-gradient-to-r from-indigo-950 to-red-700",
  "bg-gradient-to-r from-indigo-950 to-red-800",
  "bg-gradient-to-r from-indigo-950 to-red-900",
  "bg-gradient-to-r from-indigo-950 to-red-950",
  "bg-gradient-to-r from-indigo-950 to-orange-300",
  "bg-gradient-to-r from-indigo-950 to-orange-400",
  "bg-gradient-to-r from-indigo-950 to-orange-500",
  "bg-gradient-to-r from-indigo-950 to-orange-600",
  "bg-gradient-to-r from-indigo-950 to-orange-700",
  "bg-gradient-to-r from-indigo-950 to-orange-800",
  "bg-gradient-to-r from-indigo-950 to-orange-900",
  "bg-gradient-to-r from-indigo-950 to-orange-950",
  "bg-gradient-to-r from-indigo-950 to-amber-300",
  "bg-gradient-to-r from-indigo-950 to-amber-400",
  "bg-gradient-to-r from-indigo-950 to-amber-500",
  "bg-gradient-to-r from-indigo-950 to-amber-600",
  "bg-gradient-to-r from-indigo-950 to-amber-700",
  "bg-gradient-to-r from-indigo-950 to-amber-800",
  "bg-gradient-to-r from-indigo-950 to-amber-900",
  "bg-gradient-to-r from-indigo-950 to-amber-950",
  "bg-gradient-to-r from-indigo-950 to-yellow-300",
  "bg-gradient-to-r from-indigo-950 to-yellow-400",
  "bg-gradient-to-r from-indigo-950 to-yellow-500",
  "bg-gradient-to-r from-indigo-950 to-yellow-600",
  "bg-gradient-to-r from-indigo-950 to-yellow-700",
  "bg-gradient-to-r from-indigo-950 to-yellow-800",
  "bg-gradient-to-r from-indigo-950 to-yellow-900",
  "bg-gradient-to-r from-indigo-950 to-yellow-950",
  "bg-gradient-to-r from-indigo-950 to-lime-300",
  "bg-gradient-to-r from-indigo-950 to-lime-400",
  "bg-gradient-to-r from-indigo-950 to-lime-500",
  "bg-gradient-to-r from-indigo-950 to-lime-600",
  "bg-gradient-to-r from-indigo-950 to-lime-700",
  "bg-gradient-to-r from-indigo-950 to-lime-800",
  "bg-gradient-to-r from-indigo-950 to-lime-900",
  "bg-gradient-to-r from-indigo-950 to-lime-950",
  "bg-gradient-to-r from-indigo-950 to-green-300",
  "bg-gradient-to-r from-indigo-950 to-green-400",
  "bg-gradient-to-r from-indigo-950 to-green-500",
  "bg-gradient-to-r from-indigo-950 to-green-600",
  "bg-gradient-to-r from-indigo-950 to-green-700",
  "bg-gradient-to-r from-indigo-950 to-green-800",
  "bg-gradient-to-r from-indigo-950 to-green-900",
  "bg-gradient-to-r from-indigo-950 to-green-950",
  "bg-gradient-to-r from-indigo-950 to-emerald-300",
  "bg-gradient-to-r from-indigo-950 to-emerald-400",
  "bg-gradient-to-r from-indigo-950 to-emerald-500",
  "bg-gradient-to-r from-indigo-950 to-emerald-600",
  "bg-gradient-to-r from-indigo-950 to-emerald-700",
  "bg-gradient-to-r from-indigo-950 to-emerald-800",
  "bg-gradient-to-r from-indigo-950 to-emerald-900",
  "bg-gradient-to-r from-indigo-950 to-emerald-950",
  "bg-gradient-to-r from-indigo-950 to-teal-300",
  "bg-gradient-to-r from-indigo-950 to-teal-400",
  "bg-gradient-to-r from-indigo-950 to-teal-500",
  "bg-gradient-to-r from-indigo-950 to-teal-600",
  "bg-gradient-to-r from-indigo-950 to-teal-700",
  "bg-gradient-to-r from-indigo-950 to-teal-800",
  "bg-gradient-to-r from-indigo-950 to-teal-900",
  "bg-gradient-to-r from-indigo-950 to-teal-950",
  "bg-gradient-to-r from-indigo-950 to-cyan-300",
  "bg-gradient-to-r from-indigo-950 to-cyan-400",
  "bg-gradient-to-r from-indigo-950 to-cyan-500",
  "bg-gradient-to-r from-indigo-950 to-cyan-600",
  "bg-gradient-to-r from-indigo-950 to-cyan-700",
  "bg-gradient-to-r from-indigo-950 to-cyan-800",
  "bg-gradient-to-r from-indigo-950 to-cyan-900",
  "bg-gradient-to-r from-indigo-950 to-cyan-950",
  "bg-gradient-to-r from-indigo-950 to-sky-300",
  "bg-gradient-to-r from-indigo-950 to-sky-400",
  "bg-gradient-to-r from-indigo-950 to-sky-500",
  "bg-gradient-to-r from-indigo-950 to-sky-600",
  "bg-gradient-to-r from-indigo-950 to-sky-700",
  "bg-gradient-to-r from-indigo-950 to-sky-800",
  "bg-gradient-to-r from-indigo-950 to-sky-900",
  "bg-gradient-to-r from-indigo-950 to-sky-950",
  "bg-gradient-to-r from-indigo-950 to-blue-300",
  "bg-gradient-to-r from-indigo-950 to-blue-400",
  "bg-gradient-to-r from-indigo-950 to-blue-500",
  "bg-gradient-to-r from-indigo-950 to-blue-600",
  "bg-gradient-to-r from-indigo-950 to-blue-700",
  "bg-gradient-to-r from-indigo-950 to-blue-800",
  "bg-gradient-to-r from-indigo-950 to-blue-900",
  "bg-gradient-to-r from-indigo-950 to-blue-950",
  "bg-gradient-to-r from-indigo-950 to-violet-300",
  "bg-gradient-to-r from-indigo-950 to-violet-400",
  "bg-gradient-to-r from-indigo-950 to-violet-500",
  "bg-gradient-to-r from-indigo-950 to-violet-600",
  "bg-gradient-to-r from-indigo-950 to-violet-700",
  "bg-gradient-to-r from-indigo-950 to-violet-800",
  "bg-gradient-to-r from-indigo-950 to-violet-900",
  "bg-gradient-to-r from-indigo-950 to-violet-950",
  "bg-gradient-to-r from-indigo-950 to-purple-300",
  "bg-gradient-to-r from-indigo-950 to-purple-400",
  "bg-gradient-to-r from-indigo-950 to-purple-500",
  "bg-gradient-to-r from-indigo-950 to-purple-600",
  "bg-gradient-to-r from-indigo-950 to-purple-700",
  "bg-gradient-to-r from-indigo-950 to-purple-800",
  "bg-gradient-to-r from-indigo-950 to-purple-900",
  "bg-gradient-to-r from-indigo-950 to-purple-950",
  "bg-gradient-to-r from-indigo-950 to-fuchsia-300",
  "bg-gradient-to-r from-indigo-950 to-fuchsia-400",
  "bg-gradient-to-r from-indigo-950 to-fuchsia-500",
  "bg-gradient-to-r from-indigo-950 to-fuchsia-600",
  "bg-gradient-to-r from-indigo-950 to-fuchsia-700",
  "bg-gradient-to-r from-indigo-950 to-fuchsia-800",
  "bg-gradient-to-r from-indigo-950 to-fuchsia-900",
  "bg-gradient-to-r from-indigo-950 to-fuchsia-950",
  "bg-gradient-to-r from-indigo-950 to-pink-300",
  "bg-gradient-to-r from-indigo-950 to-pink-400",
  "bg-gradient-to-r from-indigo-950 to-pink-500",
  "bg-gradient-to-r from-indigo-950 to-pink-600",
  "bg-gradient-to-r from-indigo-950 to-pink-700",
  "bg-gradient-to-r from-indigo-950 to-pink-800",
  "bg-gradient-to-r from-indigo-950 to-pink-900",
  "bg-gradient-to-r from-indigo-950 to-pink-950",
  "bg-gradient-to-r from-indigo-950 to-rose-300",
  "bg-gradient-to-r from-indigo-950 to-rose-400",
  "bg-gradient-to-r from-indigo-950 to-rose-500",
  "bg-gradient-to-r from-indigo-950 to-rose-600",
  "bg-gradient-to-r from-indigo-950 to-rose-700",
  "bg-gradient-to-r from-indigo-950 to-rose-800",
  "bg-gradient-to-r from-indigo-950 to-rose-900",
  "bg-gradient-to-r from-indigo-950 to-rose-950",
  "bg-gradient-to-r from-violet-300 to-red-300",
  "bg-gradient-to-r from-violet-300 to-red-400",
  "bg-gradient-to-r from-violet-300 to-red-500",
  "bg-gradient-to-r from-violet-300 to-red-600",
  "bg-gradient-to-r from-violet-300 to-red-700",
  "bg-gradient-to-r from-violet-300 to-red-800",
  "bg-gradient-to-r from-violet-300 to-red-900",
  "bg-gradient-to-r from-violet-300 to-red-950",
  "bg-gradient-to-r from-violet-300 to-orange-300",
  "bg-gradient-to-r from-violet-300 to-orange-400",
  "bg-gradient-to-r from-violet-300 to-orange-500",
  "bg-gradient-to-r from-violet-300 to-orange-600",
  "bg-gradient-to-r from-violet-300 to-orange-700",
  "bg-gradient-to-r from-violet-300 to-orange-800",
  "bg-gradient-to-r from-violet-300 to-orange-900",
  "bg-gradient-to-r from-violet-300 to-orange-950",
  "bg-gradient-to-r from-violet-300 to-amber-300",
  "bg-gradient-to-r from-violet-300 to-amber-400",
  "bg-gradient-to-r from-violet-300 to-amber-500",
  "bg-gradient-to-r from-violet-300 to-amber-600",
  "bg-gradient-to-r from-violet-300 to-amber-700",
  "bg-gradient-to-r from-violet-300 to-amber-800",
  "bg-gradient-to-r from-violet-300 to-amber-900",
  "bg-gradient-to-r from-violet-300 to-amber-950",
  "bg-gradient-to-r from-violet-300 to-yellow-300",
  "bg-gradient-to-r from-violet-300 to-yellow-400",
  "bg-gradient-to-r from-violet-300 to-yellow-500",
  "bg-gradient-to-r from-violet-300 to-yellow-600",
  "bg-gradient-to-r from-violet-300 to-yellow-700",
  "bg-gradient-to-r from-violet-300 to-yellow-800",
  "bg-gradient-to-r from-violet-300 to-yellow-900",
  "bg-gradient-to-r from-violet-300 to-yellow-950",
  "bg-gradient-to-r from-violet-300 to-lime-300",
  "bg-gradient-to-r from-violet-300 to-lime-400",
  "bg-gradient-to-r from-violet-300 to-lime-500",
  "bg-gradient-to-r from-violet-300 to-lime-600",
  "bg-gradient-to-r from-violet-300 to-lime-700",
  "bg-gradient-to-r from-violet-300 to-lime-800",
  "bg-gradient-to-r from-violet-300 to-lime-900",
  "bg-gradient-to-r from-violet-300 to-lime-950",
  "bg-gradient-to-r from-violet-300 to-green-300",
  "bg-gradient-to-r from-violet-300 to-green-400",
  "bg-gradient-to-r from-violet-300 to-green-500",
  "bg-gradient-to-r from-violet-300 to-green-600",
  "bg-gradient-to-r from-violet-300 to-green-700",
  "bg-gradient-to-r from-violet-300 to-green-800",
  "bg-gradient-to-r from-violet-300 to-green-900",
  "bg-gradient-to-r from-violet-300 to-green-950",
  "bg-gradient-to-r from-violet-300 to-emerald-300",
  "bg-gradient-to-r from-violet-300 to-emerald-400",
  "bg-gradient-to-r from-violet-300 to-emerald-500",
  "bg-gradient-to-r from-violet-300 to-emerald-600",
  "bg-gradient-to-r from-violet-300 to-emerald-700",
  "bg-gradient-to-r from-violet-300 to-emerald-800",
  "bg-gradient-to-r from-violet-300 to-emerald-900",
  "bg-gradient-to-r from-violet-300 to-emerald-950",
  "bg-gradient-to-r from-violet-300 to-teal-300",
  "bg-gradient-to-r from-violet-300 to-teal-400",
  "bg-gradient-to-r from-violet-300 to-teal-500",
  "bg-gradient-to-r from-violet-300 to-teal-600",
  "bg-gradient-to-r from-violet-300 to-teal-700",
  "bg-gradient-to-r from-violet-300 to-teal-800",
  "bg-gradient-to-r from-violet-300 to-teal-900",
  "bg-gradient-to-r from-violet-300 to-teal-950",
  "bg-gradient-to-r from-violet-300 to-cyan-300",
  "bg-gradient-to-r from-violet-300 to-cyan-400",
  "bg-gradient-to-r from-violet-300 to-cyan-500",
  "bg-gradient-to-r from-violet-300 to-cyan-600",
  "bg-gradient-to-r from-violet-300 to-cyan-700",
  "bg-gradient-to-r from-violet-300 to-cyan-800",
  "bg-gradient-to-r from-violet-300 to-cyan-900",
  "bg-gradient-to-r from-violet-300 to-cyan-950",
  "bg-gradient-to-r from-violet-300 to-sky-300",
  "bg-gradient-to-r from-violet-300 to-sky-400",
  "bg-gradient-to-r from-violet-300 to-sky-500",
  "bg-gradient-to-r from-violet-300 to-sky-600",
  "bg-gradient-to-r from-violet-300 to-sky-700",
  "bg-gradient-to-r from-violet-300 to-sky-800",
  "bg-gradient-to-r from-violet-300 to-sky-900",
  "bg-gradient-to-r from-violet-300 to-sky-950",
  "bg-gradient-to-r from-violet-300 to-blue-300",
  "bg-gradient-to-r from-violet-300 to-blue-400",
  "bg-gradient-to-r from-violet-300 to-blue-500",
  "bg-gradient-to-r from-violet-300 to-blue-600",
  "bg-gradient-to-r from-violet-300 to-blue-700",
  "bg-gradient-to-r from-violet-300 to-blue-800",
  "bg-gradient-to-r from-violet-300 to-blue-900",
  "bg-gradient-to-r from-violet-300 to-blue-950",
  "bg-gradient-to-r from-violet-300 to-indigo-300",
  "bg-gradient-to-r from-violet-300 to-indigo-400",
  "bg-gradient-to-r from-violet-300 to-indigo-500",
  "bg-gradient-to-r from-violet-300 to-indigo-600",
  "bg-gradient-to-r from-violet-300 to-indigo-700",
  "bg-gradient-to-r from-violet-300 to-indigo-800",
  "bg-gradient-to-r from-violet-300 to-indigo-900",
  "bg-gradient-to-r from-violet-300 to-indigo-950",
  "bg-gradient-to-r from-violet-300 to-purple-300",
  "bg-gradient-to-r from-violet-300 to-purple-400",
  "bg-gradient-to-r from-violet-300 to-purple-500",
  "bg-gradient-to-r from-violet-300 to-purple-600",
  "bg-gradient-to-r from-violet-300 to-purple-700",
  "bg-gradient-to-r from-violet-300 to-purple-800",
  "bg-gradient-to-r from-violet-300 to-purple-900",
  "bg-gradient-to-r from-violet-300 to-purple-950",
  "bg-gradient-to-r from-violet-300 to-fuchsia-300",
  "bg-gradient-to-r from-violet-300 to-fuchsia-400",
  "bg-gradient-to-r from-violet-300 to-fuchsia-500",
  "bg-gradient-to-r from-violet-300 to-fuchsia-600",
  "bg-gradient-to-r from-violet-300 to-fuchsia-700",
  "bg-gradient-to-r from-violet-300 to-fuchsia-800",
  "bg-gradient-to-r from-violet-300 to-fuchsia-900",
  "bg-gradient-to-r from-violet-300 to-fuchsia-950",
  "bg-gradient-to-r from-violet-300 to-pink-300",
  "bg-gradient-to-r from-violet-300 to-pink-400",
  "bg-gradient-to-r from-violet-300 to-pink-500",
  "bg-gradient-to-r from-violet-300 to-pink-600",
  "bg-gradient-to-r from-violet-300 to-pink-700",
  "bg-gradient-to-r from-violet-300 to-pink-800",
  "bg-gradient-to-r from-violet-300 to-pink-900",
  "bg-gradient-to-r from-violet-300 to-pink-950",
  "bg-gradient-to-r from-violet-300 to-rose-300",
  "bg-gradient-to-r from-violet-300 to-rose-400",
  "bg-gradient-to-r from-violet-300 to-rose-500",
  "bg-gradient-to-r from-violet-300 to-rose-600",
  "bg-gradient-to-r from-violet-300 to-rose-700",
  "bg-gradient-to-r from-violet-300 to-rose-800",
  "bg-gradient-to-r from-violet-300 to-rose-900",
  "bg-gradient-to-r from-violet-300 to-rose-950",
  "bg-gradient-to-r from-violet-400 to-red-300",
  "bg-gradient-to-r from-violet-400 to-red-400",
  "bg-gradient-to-r from-violet-400 to-red-500",
  "bg-gradient-to-r from-violet-400 to-red-600",
  "bg-gradient-to-r from-violet-400 to-red-700",
  "bg-gradient-to-r from-violet-400 to-red-800",
  "bg-gradient-to-r from-violet-400 to-red-900",
  "bg-gradient-to-r from-violet-400 to-red-950",
  "bg-gradient-to-r from-violet-400 to-orange-300",
  "bg-gradient-to-r from-violet-400 to-orange-400",
  "bg-gradient-to-r from-violet-400 to-orange-500",
  "bg-gradient-to-r from-violet-400 to-orange-600",
  "bg-gradient-to-r from-violet-400 to-orange-700",
  "bg-gradient-to-r from-violet-400 to-orange-800",
  "bg-gradient-to-r from-violet-400 to-orange-900",
  "bg-gradient-to-r from-violet-400 to-orange-950",
  "bg-gradient-to-r from-violet-400 to-amber-300",
  "bg-gradient-to-r from-violet-400 to-amber-400",
  "bg-gradient-to-r from-violet-400 to-amber-500",
  "bg-gradient-to-r from-violet-400 to-amber-600",
  "bg-gradient-to-r from-violet-400 to-amber-700",
  "bg-gradient-to-r from-violet-400 to-amber-800",
  "bg-gradient-to-r from-violet-400 to-amber-900",
  "bg-gradient-to-r from-violet-400 to-amber-950",
  "bg-gradient-to-r from-violet-400 to-yellow-300",
  "bg-gradient-to-r from-violet-400 to-yellow-400",
  "bg-gradient-to-r from-violet-400 to-yellow-500",
  "bg-gradient-to-r from-violet-400 to-yellow-600",
  "bg-gradient-to-r from-violet-400 to-yellow-700",
  "bg-gradient-to-r from-violet-400 to-yellow-800",
  "bg-gradient-to-r from-violet-400 to-yellow-900",
  "bg-gradient-to-r from-violet-400 to-yellow-950",
  "bg-gradient-to-r from-violet-400 to-lime-300",
  "bg-gradient-to-r from-violet-400 to-lime-400",
  "bg-gradient-to-r from-violet-400 to-lime-500",
  "bg-gradient-to-r from-violet-400 to-lime-600",
  "bg-gradient-to-r from-violet-400 to-lime-700",
  "bg-gradient-to-r from-violet-400 to-lime-800",
  "bg-gradient-to-r from-violet-400 to-lime-900",
  "bg-gradient-to-r from-violet-400 to-lime-950",
  "bg-gradient-to-r from-violet-400 to-green-300",
  "bg-gradient-to-r from-violet-400 to-green-400",
  "bg-gradient-to-r from-violet-400 to-green-500",
  "bg-gradient-to-r from-violet-400 to-green-600",
  "bg-gradient-to-r from-violet-400 to-green-700",
  "bg-gradient-to-r from-violet-400 to-green-800",
  "bg-gradient-to-r from-violet-400 to-green-900",
  "bg-gradient-to-r from-violet-400 to-green-950",
  "bg-gradient-to-r from-violet-400 to-emerald-300",
  "bg-gradient-to-r from-violet-400 to-emerald-400",
  "bg-gradient-to-r from-violet-400 to-emerald-500",
  "bg-gradient-to-r from-violet-400 to-emerald-600",
  "bg-gradient-to-r from-violet-400 to-emerald-700",
  "bg-gradient-to-r from-violet-400 to-emerald-800",
  "bg-gradient-to-r from-violet-400 to-emerald-900",
  "bg-gradient-to-r from-violet-400 to-emerald-950",
  "bg-gradient-to-r from-violet-400 to-teal-300",
  "bg-gradient-to-r from-violet-400 to-teal-400",
  "bg-gradient-to-r from-violet-400 to-teal-500",
  "bg-gradient-to-r from-violet-400 to-teal-600",
  "bg-gradient-to-r from-violet-400 to-teal-700",
  "bg-gradient-to-r from-violet-400 to-teal-800",
  "bg-gradient-to-r from-violet-400 to-teal-900",
  "bg-gradient-to-r from-violet-400 to-teal-950",
  "bg-gradient-to-r from-violet-400 to-cyan-300",
  "bg-gradient-to-r from-violet-400 to-cyan-400",
  "bg-gradient-to-r from-violet-400 to-cyan-500",
  "bg-gradient-to-r from-violet-400 to-cyan-600",
  "bg-gradient-to-r from-violet-400 to-cyan-700",
  "bg-gradient-to-r from-violet-400 to-cyan-800",
  "bg-gradient-to-r from-violet-400 to-cyan-900",
  "bg-gradient-to-r from-violet-400 to-cyan-950",
  "bg-gradient-to-r from-violet-400 to-sky-300",
  "bg-gradient-to-r from-violet-400 to-sky-400",
  "bg-gradient-to-r from-violet-400 to-sky-500",
  "bg-gradient-to-r from-violet-400 to-sky-600",
  "bg-gradient-to-r from-violet-400 to-sky-700",
  "bg-gradient-to-r from-violet-400 to-sky-800",
  "bg-gradient-to-r from-violet-400 to-sky-900",
  "bg-gradient-to-r from-violet-400 to-sky-950",
  "bg-gradient-to-r from-violet-400 to-blue-300",
  "bg-gradient-to-r from-violet-400 to-blue-400",
  "bg-gradient-to-r from-violet-400 to-blue-500",
  "bg-gradient-to-r from-violet-400 to-blue-600",
  "bg-gradient-to-r from-violet-400 to-blue-700",
  "bg-gradient-to-r from-violet-400 to-blue-800",
  "bg-gradient-to-r from-violet-400 to-blue-900",
  "bg-gradient-to-r from-violet-400 to-blue-950",
  "bg-gradient-to-r from-violet-400 to-indigo-300",
  "bg-gradient-to-r from-violet-400 to-indigo-400",
  "bg-gradient-to-r from-violet-400 to-indigo-500",
  "bg-gradient-to-r from-violet-400 to-indigo-600",
  "bg-gradient-to-r from-violet-400 to-indigo-700",
  "bg-gradient-to-r from-violet-400 to-indigo-800",
  "bg-gradient-to-r from-violet-400 to-indigo-900",
  "bg-gradient-to-r from-violet-400 to-indigo-950",
  "bg-gradient-to-r from-violet-400 to-purple-300",
  "bg-gradient-to-r from-violet-400 to-purple-400",
  "bg-gradient-to-r from-violet-400 to-purple-500",
  "bg-gradient-to-r from-violet-400 to-purple-600",
  "bg-gradient-to-r from-violet-400 to-purple-700",
  "bg-gradient-to-r from-violet-400 to-purple-800",
  "bg-gradient-to-r from-violet-400 to-purple-900",
  "bg-gradient-to-r from-violet-400 to-purple-950",
  "bg-gradient-to-r from-violet-400 to-fuchsia-300",
  "bg-gradient-to-r from-violet-400 to-fuchsia-400",
  "bg-gradient-to-r from-violet-400 to-fuchsia-500",
  "bg-gradient-to-r from-violet-400 to-fuchsia-600",
  "bg-gradient-to-r from-violet-400 to-fuchsia-700",
  "bg-gradient-to-r from-violet-400 to-fuchsia-800",
  "bg-gradient-to-r from-violet-400 to-fuchsia-900",
  "bg-gradient-to-r from-violet-400 to-fuchsia-950",
  "bg-gradient-to-r from-violet-400 to-pink-300",
  "bg-gradient-to-r from-violet-400 to-pink-400",
  "bg-gradient-to-r from-violet-400 to-pink-500",
  "bg-gradient-to-r from-violet-400 to-pink-600",
  "bg-gradient-to-r from-violet-400 to-pink-700",
  "bg-gradient-to-r from-violet-400 to-pink-800",
  "bg-gradient-to-r from-violet-400 to-pink-900",
  "bg-gradient-to-r from-violet-400 to-pink-950",
  "bg-gradient-to-r from-violet-400 to-rose-300",
  "bg-gradient-to-r from-violet-400 to-rose-400",
  "bg-gradient-to-r from-violet-400 to-rose-500",
  "bg-gradient-to-r from-violet-400 to-rose-600",
  "bg-gradient-to-r from-violet-400 to-rose-700",
  "bg-gradient-to-r from-violet-400 to-rose-800",
  "bg-gradient-to-r from-violet-400 to-rose-900",
  "bg-gradient-to-r from-violet-400 to-rose-950",
  "bg-gradient-to-r from-violet-500 to-red-300",
  "bg-gradient-to-r from-violet-500 to-red-400",
  "bg-gradient-to-r from-violet-500 to-red-500",
  "bg-gradient-to-r from-violet-500 to-red-600",
  "bg-gradient-to-r from-violet-500 to-red-700",
  "bg-gradient-to-r from-violet-500 to-red-800",
  "bg-gradient-to-r from-violet-500 to-red-900",
  "bg-gradient-to-r from-violet-500 to-red-950",
  "bg-gradient-to-r from-violet-500 to-orange-300",
  "bg-gradient-to-r from-violet-500 to-orange-400",
  "bg-gradient-to-r from-violet-500 to-orange-500",
  "bg-gradient-to-r from-violet-500 to-orange-600",
  "bg-gradient-to-r from-violet-500 to-orange-700",
  "bg-gradient-to-r from-violet-500 to-orange-800",
  "bg-gradient-to-r from-violet-500 to-orange-900",
  "bg-gradient-to-r from-violet-500 to-orange-950",
  "bg-gradient-to-r from-violet-500 to-amber-300",
  "bg-gradient-to-r from-violet-500 to-amber-400",
  "bg-gradient-to-r from-violet-500 to-amber-500",
  "bg-gradient-to-r from-violet-500 to-amber-600",
  "bg-gradient-to-r from-violet-500 to-amber-700",
  "bg-gradient-to-r from-violet-500 to-amber-800",
  "bg-gradient-to-r from-violet-500 to-amber-900",
  "bg-gradient-to-r from-violet-500 to-amber-950",
  "bg-gradient-to-r from-violet-500 to-yellow-300",
  "bg-gradient-to-r from-violet-500 to-yellow-400",
  "bg-gradient-to-r from-violet-500 to-yellow-500",
  "bg-gradient-to-r from-violet-500 to-yellow-600",
  "bg-gradient-to-r from-violet-500 to-yellow-700",
  "bg-gradient-to-r from-violet-500 to-yellow-800",
  "bg-gradient-to-r from-violet-500 to-yellow-900",
  "bg-gradient-to-r from-violet-500 to-yellow-950",
  "bg-gradient-to-r from-violet-500 to-lime-300",
  "bg-gradient-to-r from-violet-500 to-lime-400",
  "bg-gradient-to-r from-violet-500 to-lime-500",
  "bg-gradient-to-r from-violet-500 to-lime-600",
  "bg-gradient-to-r from-violet-500 to-lime-700",
  "bg-gradient-to-r from-violet-500 to-lime-800",
  "bg-gradient-to-r from-violet-500 to-lime-900",
  "bg-gradient-to-r from-violet-500 to-lime-950",
  "bg-gradient-to-r from-violet-500 to-green-300",
  "bg-gradient-to-r from-violet-500 to-green-400",
  "bg-gradient-to-r from-violet-500 to-green-500",
  "bg-gradient-to-r from-violet-500 to-green-600",
  "bg-gradient-to-r from-violet-500 to-green-700",
  "bg-gradient-to-r from-violet-500 to-green-800",
  "bg-gradient-to-r from-violet-500 to-green-900",
  "bg-gradient-to-r from-violet-500 to-green-950",
  "bg-gradient-to-r from-violet-500 to-emerald-300",
  "bg-gradient-to-r from-violet-500 to-emerald-400",
  "bg-gradient-to-r from-violet-500 to-emerald-500",
  "bg-gradient-to-r from-violet-500 to-emerald-600",
  "bg-gradient-to-r from-violet-500 to-emerald-700",
  "bg-gradient-to-r from-violet-500 to-emerald-800",
  "bg-gradient-to-r from-violet-500 to-emerald-900",
  "bg-gradient-to-r from-violet-500 to-emerald-950",
  "bg-gradient-to-r from-violet-500 to-teal-300",
  "bg-gradient-to-r from-violet-500 to-teal-400",
  "bg-gradient-to-r from-violet-500 to-teal-500",
  "bg-gradient-to-r from-violet-500 to-teal-600",
  "bg-gradient-to-r from-violet-500 to-teal-700",
  "bg-gradient-to-r from-violet-500 to-teal-800",
  "bg-gradient-to-r from-violet-500 to-teal-900",
  "bg-gradient-to-r from-violet-500 to-teal-950",
  "bg-gradient-to-r from-violet-500 to-cyan-300",
  "bg-gradient-to-r from-violet-500 to-cyan-400",
  "bg-gradient-to-r from-violet-500 to-cyan-500",
  "bg-gradient-to-r from-violet-500 to-cyan-600",
  "bg-gradient-to-r from-violet-500 to-cyan-700",
  "bg-gradient-to-r from-violet-500 to-cyan-800",
  "bg-gradient-to-r from-violet-500 to-cyan-900",
  "bg-gradient-to-r from-violet-500 to-cyan-950",
  "bg-gradient-to-r from-violet-500 to-sky-300",
  "bg-gradient-to-r from-violet-500 to-sky-400",
  "bg-gradient-to-r from-violet-500 to-sky-500",
  "bg-gradient-to-r from-violet-500 to-sky-600",
  "bg-gradient-to-r from-violet-500 to-sky-700",
  "bg-gradient-to-r from-violet-500 to-sky-800",
  "bg-gradient-to-r from-violet-500 to-sky-900",
  "bg-gradient-to-r from-violet-500 to-sky-950",
  "bg-gradient-to-r from-violet-500 to-blue-300",
  "bg-gradient-to-r from-violet-500 to-blue-400",
  "bg-gradient-to-r from-violet-500 to-blue-500",
  "bg-gradient-to-r from-violet-500 to-blue-600",
  "bg-gradient-to-r from-violet-500 to-blue-700",
  "bg-gradient-to-r from-violet-500 to-blue-800",
  "bg-gradient-to-r from-violet-500 to-blue-900",
  "bg-gradient-to-r from-violet-500 to-blue-950",
  "bg-gradient-to-r from-violet-500 to-indigo-300",
  "bg-gradient-to-r from-violet-500 to-indigo-400",
  "bg-gradient-to-r from-violet-500 to-indigo-500",
  "bg-gradient-to-r from-violet-500 to-indigo-600",
  "bg-gradient-to-r from-violet-500 to-indigo-700",
  "bg-gradient-to-r from-violet-500 to-indigo-800",
  "bg-gradient-to-r from-violet-500 to-indigo-900",
  "bg-gradient-to-r from-violet-500 to-indigo-950",
  "bg-gradient-to-r from-violet-500 to-purple-300",
  "bg-gradient-to-r from-violet-500 to-purple-400",
  "bg-gradient-to-r from-violet-500 to-purple-500",
  "bg-gradient-to-r from-violet-500 to-purple-600",
  "bg-gradient-to-r from-violet-500 to-purple-700",
  "bg-gradient-to-r from-violet-500 to-purple-800",
  "bg-gradient-to-r from-violet-500 to-purple-900",
  "bg-gradient-to-r from-violet-500 to-purple-950",
  "bg-gradient-to-r from-violet-500 to-fuchsia-300",
  "bg-gradient-to-r from-violet-500 to-fuchsia-400",
  "bg-gradient-to-r from-violet-500 to-fuchsia-500",
  "bg-gradient-to-r from-violet-500 to-fuchsia-600",
  "bg-gradient-to-r from-violet-500 to-fuchsia-700",
  "bg-gradient-to-r from-violet-500 to-fuchsia-800",
  "bg-gradient-to-r from-violet-500 to-fuchsia-900",
  "bg-gradient-to-r from-violet-500 to-fuchsia-950",
  "bg-gradient-to-r from-violet-500 to-pink-300",
  "bg-gradient-to-r from-violet-500 to-pink-400",
  "bg-gradient-to-r from-violet-500 to-pink-500",
  "bg-gradient-to-r from-violet-500 to-pink-600",
  "bg-gradient-to-r from-violet-500 to-pink-700",
  "bg-gradient-to-r from-violet-500 to-pink-800",
  "bg-gradient-to-r from-violet-500 to-pink-900",
  "bg-gradient-to-r from-violet-500 to-pink-950",
  "bg-gradient-to-r from-violet-500 to-rose-300",
  "bg-gradient-to-r from-violet-500 to-rose-400",
  "bg-gradient-to-r from-violet-500 to-rose-500",
  "bg-gradient-to-r from-violet-500 to-rose-600",
  "bg-gradient-to-r from-violet-500 to-rose-700",
  "bg-gradient-to-r from-violet-500 to-rose-800",
  "bg-gradient-to-r from-violet-500 to-rose-900",
  "bg-gradient-to-r from-violet-500 to-rose-950",
  "bg-gradient-to-r from-violet-600 to-red-300",
  "bg-gradient-to-r from-violet-600 to-red-400",
  "bg-gradient-to-r from-violet-600 to-red-500",
  "bg-gradient-to-r from-violet-600 to-red-600",
  "bg-gradient-to-r from-violet-600 to-red-700",
  "bg-gradient-to-r from-violet-600 to-red-800",
  "bg-gradient-to-r from-violet-600 to-red-900",
  "bg-gradient-to-r from-violet-600 to-red-950",
  "bg-gradient-to-r from-violet-600 to-orange-300",
  "bg-gradient-to-r from-violet-600 to-orange-400",
  "bg-gradient-to-r from-violet-600 to-orange-500",
  "bg-gradient-to-r from-violet-600 to-orange-600",
  "bg-gradient-to-r from-violet-600 to-orange-700",
  "bg-gradient-to-r from-violet-600 to-orange-800",
  "bg-gradient-to-r from-violet-600 to-orange-900",
  "bg-gradient-to-r from-violet-600 to-orange-950",
  "bg-gradient-to-r from-violet-600 to-amber-300",
  "bg-gradient-to-r from-violet-600 to-amber-400",
  "bg-gradient-to-r from-violet-600 to-amber-500",
  "bg-gradient-to-r from-violet-600 to-amber-600",
  "bg-gradient-to-r from-violet-600 to-amber-700",
  "bg-gradient-to-r from-violet-600 to-amber-800",
  "bg-gradient-to-r from-violet-600 to-amber-900",
  "bg-gradient-to-r from-violet-600 to-amber-950",
  "bg-gradient-to-r from-violet-600 to-yellow-300",
  "bg-gradient-to-r from-violet-600 to-yellow-400",
  "bg-gradient-to-r from-violet-600 to-yellow-500",
  "bg-gradient-to-r from-violet-600 to-yellow-600",
  "bg-gradient-to-r from-violet-600 to-yellow-700",
  "bg-gradient-to-r from-violet-600 to-yellow-800",
  "bg-gradient-to-r from-violet-600 to-yellow-900",
  "bg-gradient-to-r from-violet-600 to-yellow-950",
  "bg-gradient-to-r from-violet-600 to-lime-300",
  "bg-gradient-to-r from-violet-600 to-lime-400",
  "bg-gradient-to-r from-violet-600 to-lime-500",
  "bg-gradient-to-r from-violet-600 to-lime-600",
  "bg-gradient-to-r from-violet-600 to-lime-700",
  "bg-gradient-to-r from-violet-600 to-lime-800",
  "bg-gradient-to-r from-violet-600 to-lime-900",
  "bg-gradient-to-r from-violet-600 to-lime-950",
  "bg-gradient-to-r from-violet-600 to-green-300",
  "bg-gradient-to-r from-violet-600 to-green-400",
  "bg-gradient-to-r from-violet-600 to-green-500",
  "bg-gradient-to-r from-violet-600 to-green-600",
  "bg-gradient-to-r from-violet-600 to-green-700",
  "bg-gradient-to-r from-violet-600 to-green-800",
  "bg-gradient-to-r from-violet-600 to-green-900",
  "bg-gradient-to-r from-violet-600 to-green-950",
  "bg-gradient-to-r from-violet-600 to-emerald-300",
  "bg-gradient-to-r from-violet-600 to-emerald-400",
  "bg-gradient-to-r from-violet-600 to-emerald-500",
  "bg-gradient-to-r from-violet-600 to-emerald-600",
  "bg-gradient-to-r from-violet-600 to-emerald-700",
  "bg-gradient-to-r from-violet-600 to-emerald-800",
  "bg-gradient-to-r from-violet-600 to-emerald-900",
  "bg-gradient-to-r from-violet-600 to-emerald-950",
  "bg-gradient-to-r from-violet-600 to-teal-300",
  "bg-gradient-to-r from-violet-600 to-teal-400",
  "bg-gradient-to-r from-violet-600 to-teal-500",
  "bg-gradient-to-r from-violet-600 to-teal-600",
  "bg-gradient-to-r from-violet-600 to-teal-700",
  "bg-gradient-to-r from-violet-600 to-teal-800",
  "bg-gradient-to-r from-violet-600 to-teal-900",
  "bg-gradient-to-r from-violet-600 to-teal-950",
  "bg-gradient-to-r from-violet-600 to-cyan-300",
  "bg-gradient-to-r from-violet-600 to-cyan-400",
  "bg-gradient-to-r from-violet-600 to-cyan-500",
  "bg-gradient-to-r from-violet-600 to-cyan-600",
  "bg-gradient-to-r from-violet-600 to-cyan-700",
  "bg-gradient-to-r from-violet-600 to-cyan-800",
  "bg-gradient-to-r from-violet-600 to-cyan-900",
  "bg-gradient-to-r from-violet-600 to-cyan-950",
  "bg-gradient-to-r from-violet-600 to-sky-300",
  "bg-gradient-to-r from-violet-600 to-sky-400",
  "bg-gradient-to-r from-violet-600 to-sky-500",
  "bg-gradient-to-r from-violet-600 to-sky-600",
  "bg-gradient-to-r from-violet-600 to-sky-700",
  "bg-gradient-to-r from-violet-600 to-sky-800",
  "bg-gradient-to-r from-violet-600 to-sky-900",
  "bg-gradient-to-r from-violet-600 to-sky-950",
  "bg-gradient-to-r from-violet-600 to-blue-300",
  "bg-gradient-to-r from-violet-600 to-blue-400",
  "bg-gradient-to-r from-violet-600 to-blue-500",
  "bg-gradient-to-r from-violet-600 to-blue-600",
  "bg-gradient-to-r from-violet-600 to-blue-700",
  "bg-gradient-to-r from-violet-600 to-blue-800",
  "bg-gradient-to-r from-violet-600 to-blue-900",
  "bg-gradient-to-r from-violet-600 to-blue-950",
  "bg-gradient-to-r from-violet-600 to-indigo-300",
  "bg-gradient-to-r from-violet-600 to-indigo-400",
  "bg-gradient-to-r from-violet-600 to-indigo-500",
  "bg-gradient-to-r from-violet-600 to-indigo-600",
  "bg-gradient-to-r from-violet-600 to-indigo-700",
  "bg-gradient-to-r from-violet-600 to-indigo-800",
  "bg-gradient-to-r from-violet-600 to-indigo-900",
  "bg-gradient-to-r from-violet-600 to-indigo-950",
  "bg-gradient-to-r from-violet-600 to-purple-300",
  "bg-gradient-to-r from-violet-600 to-purple-400",
  "bg-gradient-to-r from-violet-600 to-purple-500",
  "bg-gradient-to-r from-violet-600 to-purple-600",
  "bg-gradient-to-r from-violet-600 to-purple-700",
  "bg-gradient-to-r from-violet-600 to-purple-800",
  "bg-gradient-to-r from-violet-600 to-purple-900",
  "bg-gradient-to-r from-violet-600 to-purple-950",
  "bg-gradient-to-r from-violet-600 to-fuchsia-300",
  "bg-gradient-to-r from-violet-600 to-fuchsia-400",
  "bg-gradient-to-r from-violet-600 to-fuchsia-500",
  "bg-gradient-to-r from-violet-600 to-fuchsia-600",
  "bg-gradient-to-r from-violet-600 to-fuchsia-700",
  "bg-gradient-to-r from-violet-600 to-fuchsia-800",
  "bg-gradient-to-r from-violet-600 to-fuchsia-900",
  "bg-gradient-to-r from-violet-600 to-fuchsia-950",
  "bg-gradient-to-r from-violet-600 to-pink-300",
  "bg-gradient-to-r from-violet-600 to-pink-400",
  "bg-gradient-to-r from-violet-600 to-pink-500",
  "bg-gradient-to-r from-violet-600 to-pink-600",
  "bg-gradient-to-r from-violet-600 to-pink-700",
  "bg-gradient-to-r from-violet-600 to-pink-800",
  "bg-gradient-to-r from-violet-600 to-pink-900",
  "bg-gradient-to-r from-violet-600 to-pink-950",
  "bg-gradient-to-r from-violet-600 to-rose-300",
  "bg-gradient-to-r from-violet-600 to-rose-400",
  "bg-gradient-to-r from-violet-600 to-rose-500",
  "bg-gradient-to-r from-violet-600 to-rose-600",
  "bg-gradient-to-r from-violet-600 to-rose-700",
  "bg-gradient-to-r from-violet-600 to-rose-800",
  "bg-gradient-to-r from-violet-600 to-rose-900",
  "bg-gradient-to-r from-violet-600 to-rose-950",
  "bg-gradient-to-r from-violet-700 to-red-300",
  "bg-gradient-to-r from-violet-700 to-red-400",
  "bg-gradient-to-r from-violet-700 to-red-500",
  "bg-gradient-to-r from-violet-700 to-red-600",
  "bg-gradient-to-r from-violet-700 to-red-700",
  "bg-gradient-to-r from-violet-700 to-red-800",
  "bg-gradient-to-r from-violet-700 to-red-900",
  "bg-gradient-to-r from-violet-700 to-red-950",
  "bg-gradient-to-r from-violet-700 to-orange-300",
  "bg-gradient-to-r from-violet-700 to-orange-400",
  "bg-gradient-to-r from-violet-700 to-orange-500",
  "bg-gradient-to-r from-violet-700 to-orange-600",
  "bg-gradient-to-r from-violet-700 to-orange-700",
  "bg-gradient-to-r from-violet-700 to-orange-800",
  "bg-gradient-to-r from-violet-700 to-orange-900",
  "bg-gradient-to-r from-violet-700 to-orange-950",
  "bg-gradient-to-r from-violet-700 to-amber-300",
  "bg-gradient-to-r from-violet-700 to-amber-400",
  "bg-gradient-to-r from-violet-700 to-amber-500",
  "bg-gradient-to-r from-violet-700 to-amber-600",
  "bg-gradient-to-r from-violet-700 to-amber-700",
  "bg-gradient-to-r from-violet-700 to-amber-800",
  "bg-gradient-to-r from-violet-700 to-amber-900",
  "bg-gradient-to-r from-violet-700 to-amber-950",
  "bg-gradient-to-r from-violet-700 to-yellow-300",
  "bg-gradient-to-r from-violet-700 to-yellow-400",
  "bg-gradient-to-r from-violet-700 to-yellow-500",
  "bg-gradient-to-r from-violet-700 to-yellow-600",
  "bg-gradient-to-r from-violet-700 to-yellow-700",
  "bg-gradient-to-r from-violet-700 to-yellow-800",
  "bg-gradient-to-r from-violet-700 to-yellow-900",
  "bg-gradient-to-r from-violet-700 to-yellow-950",
  "bg-gradient-to-r from-violet-700 to-lime-300",
  "bg-gradient-to-r from-violet-700 to-lime-400",
  "bg-gradient-to-r from-violet-700 to-lime-500",
  "bg-gradient-to-r from-violet-700 to-lime-600",
  "bg-gradient-to-r from-violet-700 to-lime-700",
  "bg-gradient-to-r from-violet-700 to-lime-800",
  "bg-gradient-to-r from-violet-700 to-lime-900",
  "bg-gradient-to-r from-violet-700 to-lime-950",
  "bg-gradient-to-r from-violet-700 to-green-300",
  "bg-gradient-to-r from-violet-700 to-green-400",
  "bg-gradient-to-r from-violet-700 to-green-500",
  "bg-gradient-to-r from-violet-700 to-green-600",
  "bg-gradient-to-r from-violet-700 to-green-700",
  "bg-gradient-to-r from-violet-700 to-green-800",
  "bg-gradient-to-r from-violet-700 to-green-900",
  "bg-gradient-to-r from-violet-700 to-green-950",
  "bg-gradient-to-r from-violet-700 to-emerald-300",
  "bg-gradient-to-r from-violet-700 to-emerald-400",
  "bg-gradient-to-r from-violet-700 to-emerald-500",
  "bg-gradient-to-r from-violet-700 to-emerald-600",
  "bg-gradient-to-r from-violet-700 to-emerald-700",
  "bg-gradient-to-r from-violet-700 to-emerald-800",
  "bg-gradient-to-r from-violet-700 to-emerald-900",
  "bg-gradient-to-r from-violet-700 to-emerald-950",
  "bg-gradient-to-r from-violet-700 to-teal-300",
  "bg-gradient-to-r from-violet-700 to-teal-400",
  "bg-gradient-to-r from-violet-700 to-teal-500",
  "bg-gradient-to-r from-violet-700 to-teal-600",
  "bg-gradient-to-r from-violet-700 to-teal-700",
  "bg-gradient-to-r from-violet-700 to-teal-800",
  "bg-gradient-to-r from-violet-700 to-teal-900",
  "bg-gradient-to-r from-violet-700 to-teal-950",
  "bg-gradient-to-r from-violet-700 to-cyan-300",
  "bg-gradient-to-r from-violet-700 to-cyan-400",
  "bg-gradient-to-r from-violet-700 to-cyan-500",
  "bg-gradient-to-r from-violet-700 to-cyan-600",
  "bg-gradient-to-r from-violet-700 to-cyan-700",
  "bg-gradient-to-r from-violet-700 to-cyan-800",
  "bg-gradient-to-r from-violet-700 to-cyan-900",
  "bg-gradient-to-r from-violet-700 to-cyan-950",
  "bg-gradient-to-r from-violet-700 to-sky-300",
  "bg-gradient-to-r from-violet-700 to-sky-400",
  "bg-gradient-to-r from-violet-700 to-sky-500",
  "bg-gradient-to-r from-violet-700 to-sky-600",
  "bg-gradient-to-r from-violet-700 to-sky-700",
  "bg-gradient-to-r from-violet-700 to-sky-800",
  "bg-gradient-to-r from-violet-700 to-sky-900",
  "bg-gradient-to-r from-violet-700 to-sky-950",
  "bg-gradient-to-r from-violet-700 to-blue-300",
  "bg-gradient-to-r from-violet-700 to-blue-400",
  "bg-gradient-to-r from-violet-700 to-blue-500",
  "bg-gradient-to-r from-violet-700 to-blue-600",
  "bg-gradient-to-r from-violet-700 to-blue-700",
  "bg-gradient-to-r from-violet-700 to-blue-800",
  "bg-gradient-to-r from-violet-700 to-blue-900",
  "bg-gradient-to-r from-violet-700 to-blue-950",
  "bg-gradient-to-r from-violet-700 to-indigo-300",
  "bg-gradient-to-r from-violet-700 to-indigo-400",
  "bg-gradient-to-r from-violet-700 to-indigo-500",
  "bg-gradient-to-r from-violet-700 to-indigo-600",
  "bg-gradient-to-r from-violet-700 to-indigo-700",
  "bg-gradient-to-r from-violet-700 to-indigo-800",
  "bg-gradient-to-r from-violet-700 to-indigo-900",
  "bg-gradient-to-r from-violet-700 to-indigo-950",
  "bg-gradient-to-r from-violet-700 to-purple-300",
  "bg-gradient-to-r from-violet-700 to-purple-400",
  "bg-gradient-to-r from-violet-700 to-purple-500",
  "bg-gradient-to-r from-violet-700 to-purple-600",
  "bg-gradient-to-r from-violet-700 to-purple-700",
  "bg-gradient-to-r from-violet-700 to-purple-800",
  "bg-gradient-to-r from-violet-700 to-purple-900",
  "bg-gradient-to-r from-violet-700 to-purple-950",
  "bg-gradient-to-r from-violet-700 to-fuchsia-300",
  "bg-gradient-to-r from-violet-700 to-fuchsia-400",
  "bg-gradient-to-r from-violet-700 to-fuchsia-500",
  "bg-gradient-to-r from-violet-700 to-fuchsia-600",
  "bg-gradient-to-r from-violet-700 to-fuchsia-700",
  "bg-gradient-to-r from-violet-700 to-fuchsia-800",
  "bg-gradient-to-r from-violet-700 to-fuchsia-900",
  "bg-gradient-to-r from-violet-700 to-fuchsia-950",
  "bg-gradient-to-r from-violet-700 to-pink-300",
  "bg-gradient-to-r from-violet-700 to-pink-400",
  "bg-gradient-to-r from-violet-700 to-pink-500",
  "bg-gradient-to-r from-violet-700 to-pink-600",
  "bg-gradient-to-r from-violet-700 to-pink-700",
  "bg-gradient-to-r from-violet-700 to-pink-800",
  "bg-gradient-to-r from-violet-700 to-pink-900",
  "bg-gradient-to-r from-violet-700 to-pink-950",
  "bg-gradient-to-r from-violet-700 to-rose-300",
  "bg-gradient-to-r from-violet-700 to-rose-400",
  "bg-gradient-to-r from-violet-700 to-rose-500",
  "bg-gradient-to-r from-violet-700 to-rose-600",
  "bg-gradient-to-r from-violet-700 to-rose-700",
  "bg-gradient-to-r from-violet-700 to-rose-800",
  "bg-gradient-to-r from-violet-700 to-rose-900",
  "bg-gradient-to-r from-violet-700 to-rose-950",
  "bg-gradient-to-r from-violet-800 to-red-300",
  "bg-gradient-to-r from-violet-800 to-red-400",
  "bg-gradient-to-r from-violet-800 to-red-500",
  "bg-gradient-to-r from-violet-800 to-red-600",
  "bg-gradient-to-r from-violet-800 to-red-700",
  "bg-gradient-to-r from-violet-800 to-red-800",
  "bg-gradient-to-r from-violet-800 to-red-900",
  "bg-gradient-to-r from-violet-800 to-red-950",
  "bg-gradient-to-r from-violet-800 to-orange-300",
  "bg-gradient-to-r from-violet-800 to-orange-400",
  "bg-gradient-to-r from-violet-800 to-orange-500",
  "bg-gradient-to-r from-violet-800 to-orange-600",
  "bg-gradient-to-r from-violet-800 to-orange-700",
  "bg-gradient-to-r from-violet-800 to-orange-800",
  "bg-gradient-to-r from-violet-800 to-orange-900",
  "bg-gradient-to-r from-violet-800 to-orange-950",
  "bg-gradient-to-r from-violet-800 to-amber-300",
  "bg-gradient-to-r from-violet-800 to-amber-400",
  "bg-gradient-to-r from-violet-800 to-amber-500",
  "bg-gradient-to-r from-violet-800 to-amber-600",
  "bg-gradient-to-r from-violet-800 to-amber-700",
  "bg-gradient-to-r from-violet-800 to-amber-800",
  "bg-gradient-to-r from-violet-800 to-amber-900",
  "bg-gradient-to-r from-violet-800 to-amber-950",
  "bg-gradient-to-r from-violet-800 to-yellow-300",
  "bg-gradient-to-r from-violet-800 to-yellow-400",
  "bg-gradient-to-r from-violet-800 to-yellow-500",
  "bg-gradient-to-r from-violet-800 to-yellow-600",
  "bg-gradient-to-r from-violet-800 to-yellow-700",
  "bg-gradient-to-r from-violet-800 to-yellow-800",
  "bg-gradient-to-r from-violet-800 to-yellow-900",
  "bg-gradient-to-r from-violet-800 to-yellow-950",
  "bg-gradient-to-r from-violet-800 to-lime-300",
  "bg-gradient-to-r from-violet-800 to-lime-400",
  "bg-gradient-to-r from-violet-800 to-lime-500",
  "bg-gradient-to-r from-violet-800 to-lime-600",
  "bg-gradient-to-r from-violet-800 to-lime-700",
  "bg-gradient-to-r from-violet-800 to-lime-800",
  "bg-gradient-to-r from-violet-800 to-lime-900",
  "bg-gradient-to-r from-violet-800 to-lime-950",
  "bg-gradient-to-r from-violet-800 to-green-300",
  "bg-gradient-to-r from-violet-800 to-green-400",
  "bg-gradient-to-r from-violet-800 to-green-500",
  "bg-gradient-to-r from-violet-800 to-green-600",
  "bg-gradient-to-r from-violet-800 to-green-700",
  "bg-gradient-to-r from-violet-800 to-green-800",
  "bg-gradient-to-r from-violet-800 to-green-900",
  "bg-gradient-to-r from-violet-800 to-green-950",
  "bg-gradient-to-r from-violet-800 to-emerald-300",
  "bg-gradient-to-r from-violet-800 to-emerald-400",
  "bg-gradient-to-r from-violet-800 to-emerald-500",
  "bg-gradient-to-r from-violet-800 to-emerald-600",
  "bg-gradient-to-r from-violet-800 to-emerald-700",
  "bg-gradient-to-r from-violet-800 to-emerald-800",
  "bg-gradient-to-r from-violet-800 to-emerald-900",
  "bg-gradient-to-r from-violet-800 to-emerald-950",
  "bg-gradient-to-r from-violet-800 to-teal-300",
  "bg-gradient-to-r from-violet-800 to-teal-400",
  "bg-gradient-to-r from-violet-800 to-teal-500",
  "bg-gradient-to-r from-violet-800 to-teal-600",
  "bg-gradient-to-r from-violet-800 to-teal-700",
  "bg-gradient-to-r from-violet-800 to-teal-800",
  "bg-gradient-to-r from-violet-800 to-teal-900",
  "bg-gradient-to-r from-violet-800 to-teal-950",
  "bg-gradient-to-r from-violet-800 to-cyan-300",
  "bg-gradient-to-r from-violet-800 to-cyan-400",
  "bg-gradient-to-r from-violet-800 to-cyan-500",
  "bg-gradient-to-r from-violet-800 to-cyan-600",
  "bg-gradient-to-r from-violet-800 to-cyan-700",
  "bg-gradient-to-r from-violet-800 to-cyan-800",
  "bg-gradient-to-r from-violet-800 to-cyan-900",
  "bg-gradient-to-r from-violet-800 to-cyan-950",
  "bg-gradient-to-r from-violet-800 to-sky-300",
  "bg-gradient-to-r from-violet-800 to-sky-400",
  "bg-gradient-to-r from-violet-800 to-sky-500",
  "bg-gradient-to-r from-violet-800 to-sky-600",
  "bg-gradient-to-r from-violet-800 to-sky-700",
  "bg-gradient-to-r from-violet-800 to-sky-800",
  "bg-gradient-to-r from-violet-800 to-sky-900",
  "bg-gradient-to-r from-violet-800 to-sky-950",
  "bg-gradient-to-r from-violet-800 to-blue-300",
  "bg-gradient-to-r from-violet-800 to-blue-400",
  "bg-gradient-to-r from-violet-800 to-blue-500",
  "bg-gradient-to-r from-violet-800 to-blue-600",
  "bg-gradient-to-r from-violet-800 to-blue-700",
  "bg-gradient-to-r from-violet-800 to-blue-800",
  "bg-gradient-to-r from-violet-800 to-blue-900",
  "bg-gradient-to-r from-violet-800 to-blue-950",
  "bg-gradient-to-r from-violet-800 to-indigo-300",
  "bg-gradient-to-r from-violet-800 to-indigo-400",
  "bg-gradient-to-r from-violet-800 to-indigo-500",
  "bg-gradient-to-r from-violet-800 to-indigo-600",
  "bg-gradient-to-r from-violet-800 to-indigo-700",
  "bg-gradient-to-r from-violet-800 to-indigo-800",
  "bg-gradient-to-r from-violet-800 to-indigo-900",
  "bg-gradient-to-r from-violet-800 to-indigo-950",
  "bg-gradient-to-r from-violet-800 to-purple-300",
  "bg-gradient-to-r from-violet-800 to-purple-400",
  "bg-gradient-to-r from-violet-800 to-purple-500",
  "bg-gradient-to-r from-violet-800 to-purple-600",
  "bg-gradient-to-r from-violet-800 to-purple-700",
  "bg-gradient-to-r from-violet-800 to-purple-800",
  "bg-gradient-to-r from-violet-800 to-purple-900",
  "bg-gradient-to-r from-violet-800 to-purple-950",
  "bg-gradient-to-r from-violet-800 to-fuchsia-300",
  "bg-gradient-to-r from-violet-800 to-fuchsia-400",
  "bg-gradient-to-r from-violet-800 to-fuchsia-500",
  "bg-gradient-to-r from-violet-800 to-fuchsia-600",
  "bg-gradient-to-r from-violet-800 to-fuchsia-700",
  "bg-gradient-to-r from-violet-800 to-fuchsia-800",
  "bg-gradient-to-r from-violet-800 to-fuchsia-900",
  "bg-gradient-to-r from-violet-800 to-fuchsia-950",
  "bg-gradient-to-r from-violet-800 to-pink-300",
  "bg-gradient-to-r from-violet-800 to-pink-400",
  "bg-gradient-to-r from-violet-800 to-pink-500",
  "bg-gradient-to-r from-violet-800 to-pink-600",
  "bg-gradient-to-r from-violet-800 to-pink-700",
  "bg-gradient-to-r from-violet-800 to-pink-800",
  "bg-gradient-to-r from-violet-800 to-pink-900",
  "bg-gradient-to-r from-violet-800 to-pink-950",
  "bg-gradient-to-r from-violet-800 to-rose-300",
  "bg-gradient-to-r from-violet-800 to-rose-400",
  "bg-gradient-to-r from-violet-800 to-rose-500",
  "bg-gradient-to-r from-violet-800 to-rose-600",
  "bg-gradient-to-r from-violet-800 to-rose-700",
  "bg-gradient-to-r from-violet-800 to-rose-800",
  "bg-gradient-to-r from-violet-800 to-rose-900",
  "bg-gradient-to-r from-violet-800 to-rose-950",
  "bg-gradient-to-r from-violet-900 to-red-300",
  "bg-gradient-to-r from-violet-900 to-red-400",
  "bg-gradient-to-r from-violet-900 to-red-500",
  "bg-gradient-to-r from-violet-900 to-red-600",
  "bg-gradient-to-r from-violet-900 to-red-700",
  "bg-gradient-to-r from-violet-900 to-red-800",
  "bg-gradient-to-r from-violet-900 to-red-900",
  "bg-gradient-to-r from-violet-900 to-red-950",
  "bg-gradient-to-r from-violet-900 to-orange-300",
  "bg-gradient-to-r from-violet-900 to-orange-400",
  "bg-gradient-to-r from-violet-900 to-orange-500",
  "bg-gradient-to-r from-violet-900 to-orange-600",
  "bg-gradient-to-r from-violet-900 to-orange-700",
  "bg-gradient-to-r from-violet-900 to-orange-800",
  "bg-gradient-to-r from-violet-900 to-orange-900",
  "bg-gradient-to-r from-violet-900 to-orange-950",
  "bg-gradient-to-r from-violet-900 to-amber-300",
  "bg-gradient-to-r from-violet-900 to-amber-400",
  "bg-gradient-to-r from-violet-900 to-amber-500",
  "bg-gradient-to-r from-violet-900 to-amber-600",
  "bg-gradient-to-r from-violet-900 to-amber-700",
  "bg-gradient-to-r from-violet-900 to-amber-800",
  "bg-gradient-to-r from-violet-900 to-amber-900",
  "bg-gradient-to-r from-violet-900 to-amber-950",
  "bg-gradient-to-r from-violet-900 to-yellow-300",
  "bg-gradient-to-r from-violet-900 to-yellow-400",
  "bg-gradient-to-r from-violet-900 to-yellow-500",
  "bg-gradient-to-r from-violet-900 to-yellow-600",
  "bg-gradient-to-r from-violet-900 to-yellow-700",
  "bg-gradient-to-r from-violet-900 to-yellow-800",
  "bg-gradient-to-r from-violet-900 to-yellow-900",
  "bg-gradient-to-r from-violet-900 to-yellow-950",
  "bg-gradient-to-r from-violet-900 to-lime-300",
  "bg-gradient-to-r from-violet-900 to-lime-400",
  "bg-gradient-to-r from-violet-900 to-lime-500",
  "bg-gradient-to-r from-violet-900 to-lime-600",
  "bg-gradient-to-r from-violet-900 to-lime-700",
  "bg-gradient-to-r from-violet-900 to-lime-800",
  "bg-gradient-to-r from-violet-900 to-lime-900",
  "bg-gradient-to-r from-violet-900 to-lime-950",
  "bg-gradient-to-r from-violet-900 to-green-300",
  "bg-gradient-to-r from-violet-900 to-green-400",
  "bg-gradient-to-r from-violet-900 to-green-500",
  "bg-gradient-to-r from-violet-900 to-green-600",
  "bg-gradient-to-r from-violet-900 to-green-700",
  "bg-gradient-to-r from-violet-900 to-green-800",
  "bg-gradient-to-r from-violet-900 to-green-900",
  "bg-gradient-to-r from-violet-900 to-green-950",
  "bg-gradient-to-r from-violet-900 to-emerald-300",
  "bg-gradient-to-r from-violet-900 to-emerald-400",
  "bg-gradient-to-r from-violet-900 to-emerald-500",
  "bg-gradient-to-r from-violet-900 to-emerald-600",
  "bg-gradient-to-r from-violet-900 to-emerald-700",
  "bg-gradient-to-r from-violet-900 to-emerald-800",
  "bg-gradient-to-r from-violet-900 to-emerald-900",
  "bg-gradient-to-r from-violet-900 to-emerald-950",
  "bg-gradient-to-r from-violet-900 to-teal-300",
  "bg-gradient-to-r from-violet-900 to-teal-400",
  "bg-gradient-to-r from-violet-900 to-teal-500",
  "bg-gradient-to-r from-violet-900 to-teal-600",
  "bg-gradient-to-r from-violet-900 to-teal-700",
  "bg-gradient-to-r from-violet-900 to-teal-800",
  "bg-gradient-to-r from-violet-900 to-teal-900",
  "bg-gradient-to-r from-violet-900 to-teal-950",
  "bg-gradient-to-r from-violet-900 to-cyan-300",
  "bg-gradient-to-r from-violet-900 to-cyan-400",
  "bg-gradient-to-r from-violet-900 to-cyan-500",
  "bg-gradient-to-r from-violet-900 to-cyan-600",
  "bg-gradient-to-r from-violet-900 to-cyan-700",
  "bg-gradient-to-r from-violet-900 to-cyan-800",
  "bg-gradient-to-r from-violet-900 to-cyan-900",
  "bg-gradient-to-r from-violet-900 to-cyan-950",
  "bg-gradient-to-r from-violet-900 to-sky-300",
  "bg-gradient-to-r from-violet-900 to-sky-400",
  "bg-gradient-to-r from-violet-900 to-sky-500",
  "bg-gradient-to-r from-violet-900 to-sky-600",
  "bg-gradient-to-r from-violet-900 to-sky-700",
  "bg-gradient-to-r from-violet-900 to-sky-800",
  "bg-gradient-to-r from-violet-900 to-sky-900",
  "bg-gradient-to-r from-violet-900 to-sky-950",
  "bg-gradient-to-r from-violet-900 to-blue-300",
  "bg-gradient-to-r from-violet-900 to-blue-400",
  "bg-gradient-to-r from-violet-900 to-blue-500",
  "bg-gradient-to-r from-violet-900 to-blue-600",
  "bg-gradient-to-r from-violet-900 to-blue-700",
  "bg-gradient-to-r from-violet-900 to-blue-800",
  "bg-gradient-to-r from-violet-900 to-blue-900",
  "bg-gradient-to-r from-violet-900 to-blue-950",
  "bg-gradient-to-r from-violet-900 to-indigo-300",
  "bg-gradient-to-r from-violet-900 to-indigo-400",
  "bg-gradient-to-r from-violet-900 to-indigo-500",
  "bg-gradient-to-r from-violet-900 to-indigo-600",
  "bg-gradient-to-r from-violet-900 to-indigo-700",
  "bg-gradient-to-r from-violet-900 to-indigo-800",
  "bg-gradient-to-r from-violet-900 to-indigo-900",
  "bg-gradient-to-r from-violet-900 to-indigo-950",
  "bg-gradient-to-r from-violet-900 to-purple-300",
  "bg-gradient-to-r from-violet-900 to-purple-400",
  "bg-gradient-to-r from-violet-900 to-purple-500",
  "bg-gradient-to-r from-violet-900 to-purple-600",
  "bg-gradient-to-r from-violet-900 to-purple-700",
  "bg-gradient-to-r from-violet-900 to-purple-800",
  "bg-gradient-to-r from-violet-900 to-purple-900",
  "bg-gradient-to-r from-violet-900 to-purple-950",
  "bg-gradient-to-r from-violet-900 to-fuchsia-300",
  "bg-gradient-to-r from-violet-900 to-fuchsia-400",
  "bg-gradient-to-r from-violet-900 to-fuchsia-500",
  "bg-gradient-to-r from-violet-900 to-fuchsia-600",
  "bg-gradient-to-r from-violet-900 to-fuchsia-700",
  "bg-gradient-to-r from-violet-900 to-fuchsia-800",
  "bg-gradient-to-r from-violet-900 to-fuchsia-900",
  "bg-gradient-to-r from-violet-900 to-fuchsia-950",
  "bg-gradient-to-r from-violet-900 to-pink-300",
  "bg-gradient-to-r from-violet-900 to-pink-400",
  "bg-gradient-to-r from-violet-900 to-pink-500",
  "bg-gradient-to-r from-violet-900 to-pink-600",
  "bg-gradient-to-r from-violet-900 to-pink-700",
  "bg-gradient-to-r from-violet-900 to-pink-800",
  "bg-gradient-to-r from-violet-900 to-pink-900",
  "bg-gradient-to-r from-violet-900 to-pink-950",
  "bg-gradient-to-r from-violet-900 to-rose-300",
  "bg-gradient-to-r from-violet-900 to-rose-400",
  "bg-gradient-to-r from-violet-900 to-rose-500",
  "bg-gradient-to-r from-violet-900 to-rose-600",
  "bg-gradient-to-r from-violet-900 to-rose-700",
  "bg-gradient-to-r from-violet-900 to-rose-800",
  "bg-gradient-to-r from-violet-900 to-rose-900",
  "bg-gradient-to-r from-violet-900 to-rose-950",
  "bg-gradient-to-r from-violet-950 to-red-300",
  "bg-gradient-to-r from-violet-950 to-red-400",
  "bg-gradient-to-r from-violet-950 to-red-500",
  "bg-gradient-to-r from-violet-950 to-red-600",
  "bg-gradient-to-r from-violet-950 to-red-700",
  "bg-gradient-to-r from-violet-950 to-red-800",
  "bg-gradient-to-r from-violet-950 to-red-900",
  "bg-gradient-to-r from-violet-950 to-red-950",
  "bg-gradient-to-r from-violet-950 to-orange-300",
  "bg-gradient-to-r from-violet-950 to-orange-400",
  "bg-gradient-to-r from-violet-950 to-orange-500",
  "bg-gradient-to-r from-violet-950 to-orange-600",
  "bg-gradient-to-r from-violet-950 to-orange-700",
  "bg-gradient-to-r from-violet-950 to-orange-800",
  "bg-gradient-to-r from-violet-950 to-orange-900",
  "bg-gradient-to-r from-violet-950 to-orange-950",
  "bg-gradient-to-r from-violet-950 to-amber-300",
  "bg-gradient-to-r from-violet-950 to-amber-400",
  "bg-gradient-to-r from-violet-950 to-amber-500",
  "bg-gradient-to-r from-violet-950 to-amber-600",
  "bg-gradient-to-r from-violet-950 to-amber-700",
  "bg-gradient-to-r from-violet-950 to-amber-800",
  "bg-gradient-to-r from-violet-950 to-amber-900",
  "bg-gradient-to-r from-violet-950 to-amber-950",
  "bg-gradient-to-r from-violet-950 to-yellow-300",
  "bg-gradient-to-r from-violet-950 to-yellow-400",
  "bg-gradient-to-r from-violet-950 to-yellow-500",
  "bg-gradient-to-r from-violet-950 to-yellow-600",
  "bg-gradient-to-r from-violet-950 to-yellow-700",
  "bg-gradient-to-r from-violet-950 to-yellow-800",
  "bg-gradient-to-r from-violet-950 to-yellow-900",
  "bg-gradient-to-r from-violet-950 to-yellow-950",
  "bg-gradient-to-r from-violet-950 to-lime-300",
  "bg-gradient-to-r from-violet-950 to-lime-400",
  "bg-gradient-to-r from-violet-950 to-lime-500",
  "bg-gradient-to-r from-violet-950 to-lime-600",
  "bg-gradient-to-r from-violet-950 to-lime-700",
  "bg-gradient-to-r from-violet-950 to-lime-800",
  "bg-gradient-to-r from-violet-950 to-lime-900",
  "bg-gradient-to-r from-violet-950 to-lime-950",
  "bg-gradient-to-r from-violet-950 to-green-300",
  "bg-gradient-to-r from-violet-950 to-green-400",
  "bg-gradient-to-r from-violet-950 to-green-500",
  "bg-gradient-to-r from-violet-950 to-green-600",
  "bg-gradient-to-r from-violet-950 to-green-700",
  "bg-gradient-to-r from-violet-950 to-green-800",
  "bg-gradient-to-r from-violet-950 to-green-900",
  "bg-gradient-to-r from-violet-950 to-green-950",
  "bg-gradient-to-r from-violet-950 to-emerald-300",
  "bg-gradient-to-r from-violet-950 to-emerald-400",
  "bg-gradient-to-r from-violet-950 to-emerald-500",
  "bg-gradient-to-r from-violet-950 to-emerald-600",
  "bg-gradient-to-r from-violet-950 to-emerald-700",
  "bg-gradient-to-r from-violet-950 to-emerald-800",
  "bg-gradient-to-r from-violet-950 to-emerald-900",
  "bg-gradient-to-r from-violet-950 to-emerald-950",
  "bg-gradient-to-r from-violet-950 to-teal-300",
  "bg-gradient-to-r from-violet-950 to-teal-400",
  "bg-gradient-to-r from-violet-950 to-teal-500",
  "bg-gradient-to-r from-violet-950 to-teal-600",
  "bg-gradient-to-r from-violet-950 to-teal-700",
  "bg-gradient-to-r from-violet-950 to-teal-800",
  "bg-gradient-to-r from-violet-950 to-teal-900",
  "bg-gradient-to-r from-violet-950 to-teal-950",
  "bg-gradient-to-r from-violet-950 to-cyan-300",
  "bg-gradient-to-r from-violet-950 to-cyan-400",
  "bg-gradient-to-r from-violet-950 to-cyan-500",
  "bg-gradient-to-r from-violet-950 to-cyan-600",
  "bg-gradient-to-r from-violet-950 to-cyan-700",
  "bg-gradient-to-r from-violet-950 to-cyan-800",
  "bg-gradient-to-r from-violet-950 to-cyan-900",
  "bg-gradient-to-r from-violet-950 to-cyan-950",
  "bg-gradient-to-r from-violet-950 to-sky-300",
  "bg-gradient-to-r from-violet-950 to-sky-400",
  "bg-gradient-to-r from-violet-950 to-sky-500",
  "bg-gradient-to-r from-violet-950 to-sky-600",
  "bg-gradient-to-r from-violet-950 to-sky-700",
  "bg-gradient-to-r from-violet-950 to-sky-800",
  "bg-gradient-to-r from-violet-950 to-sky-900",
  "bg-gradient-to-r from-violet-950 to-sky-950",
  "bg-gradient-to-r from-violet-950 to-blue-300",
  "bg-gradient-to-r from-violet-950 to-blue-400",
  "bg-gradient-to-r from-violet-950 to-blue-500",
  "bg-gradient-to-r from-violet-950 to-blue-600",
  "bg-gradient-to-r from-violet-950 to-blue-700",
  "bg-gradient-to-r from-violet-950 to-blue-800",
  "bg-gradient-to-r from-violet-950 to-blue-900",
  "bg-gradient-to-r from-violet-950 to-blue-950",
  "bg-gradient-to-r from-violet-950 to-indigo-300",
  "bg-gradient-to-r from-violet-950 to-indigo-400",
  "bg-gradient-to-r from-violet-950 to-indigo-500",
  "bg-gradient-to-r from-violet-950 to-indigo-600",
  "bg-gradient-to-r from-violet-950 to-indigo-700",
  "bg-gradient-to-r from-violet-950 to-indigo-800",
  "bg-gradient-to-r from-violet-950 to-indigo-900",
  "bg-gradient-to-r from-violet-950 to-indigo-950",
  "bg-gradient-to-r from-violet-950 to-purple-300",
  "bg-gradient-to-r from-violet-950 to-purple-400",
  "bg-gradient-to-r from-violet-950 to-purple-500",
  "bg-gradient-to-r from-violet-950 to-purple-600",
  "bg-gradient-to-r from-violet-950 to-purple-700",
  "bg-gradient-to-r from-violet-950 to-purple-800",
  "bg-gradient-to-r from-violet-950 to-purple-900",
  "bg-gradient-to-r from-violet-950 to-purple-950",
  "bg-gradient-to-r from-violet-950 to-fuchsia-300",
  "bg-gradient-to-r from-violet-950 to-fuchsia-400",
  "bg-gradient-to-r from-violet-950 to-fuchsia-500",
  "bg-gradient-to-r from-violet-950 to-fuchsia-600",
  "bg-gradient-to-r from-violet-950 to-fuchsia-700",
  "bg-gradient-to-r from-violet-950 to-fuchsia-800",
  "bg-gradient-to-r from-violet-950 to-fuchsia-900",
  "bg-gradient-to-r from-violet-950 to-fuchsia-950",
  "bg-gradient-to-r from-violet-950 to-pink-300",
  "bg-gradient-to-r from-violet-950 to-pink-400",
  "bg-gradient-to-r from-violet-950 to-pink-500",
  "bg-gradient-to-r from-violet-950 to-pink-600",
  "bg-gradient-to-r from-violet-950 to-pink-700",
  "bg-gradient-to-r from-violet-950 to-pink-800",
  "bg-gradient-to-r from-violet-950 to-pink-900",
  "bg-gradient-to-r from-violet-950 to-pink-950",
  "bg-gradient-to-r from-violet-950 to-rose-300",
  "bg-gradient-to-r from-violet-950 to-rose-400",
  "bg-gradient-to-r from-violet-950 to-rose-500",
  "bg-gradient-to-r from-violet-950 to-rose-600",
  "bg-gradient-to-r from-violet-950 to-rose-700",
  "bg-gradient-to-r from-violet-950 to-rose-800",
  "bg-gradient-to-r from-violet-950 to-rose-900",
  "bg-gradient-to-r from-violet-950 to-rose-950",
  "bg-gradient-to-r from-purple-300 to-red-300",
  "bg-gradient-to-r from-purple-300 to-red-400",
  "bg-gradient-to-r from-purple-300 to-red-500",
  "bg-gradient-to-r from-purple-300 to-red-600",
  "bg-gradient-to-r from-purple-300 to-red-700",
  "bg-gradient-to-r from-purple-300 to-red-800",
  "bg-gradient-to-r from-purple-300 to-red-900",
  "bg-gradient-to-r from-purple-300 to-red-950",
  "bg-gradient-to-r from-purple-300 to-orange-300",
  "bg-gradient-to-r from-purple-300 to-orange-400",
  "bg-gradient-to-r from-purple-300 to-orange-500",
  "bg-gradient-to-r from-purple-300 to-orange-600",
  "bg-gradient-to-r from-purple-300 to-orange-700",
  "bg-gradient-to-r from-purple-300 to-orange-800",
  "bg-gradient-to-r from-purple-300 to-orange-900",
  "bg-gradient-to-r from-purple-300 to-orange-950",
  "bg-gradient-to-r from-purple-300 to-amber-300",
  "bg-gradient-to-r from-purple-300 to-amber-400",
  "bg-gradient-to-r from-purple-300 to-amber-500",
  "bg-gradient-to-r from-purple-300 to-amber-600",
  "bg-gradient-to-r from-purple-300 to-amber-700",
  "bg-gradient-to-r from-purple-300 to-amber-800",
  "bg-gradient-to-r from-purple-300 to-amber-900",
  "bg-gradient-to-r from-purple-300 to-amber-950",
  "bg-gradient-to-r from-purple-300 to-yellow-300",
  "bg-gradient-to-r from-purple-300 to-yellow-400",
  "bg-gradient-to-r from-purple-300 to-yellow-500",
  "bg-gradient-to-r from-purple-300 to-yellow-600",
  "bg-gradient-to-r from-purple-300 to-yellow-700",
  "bg-gradient-to-r from-purple-300 to-yellow-800",
  "bg-gradient-to-r from-purple-300 to-yellow-900",
  "bg-gradient-to-r from-purple-300 to-yellow-950",
  "bg-gradient-to-r from-purple-300 to-lime-300",
  "bg-gradient-to-r from-purple-300 to-lime-400",
  "bg-gradient-to-r from-purple-300 to-lime-500",
  "bg-gradient-to-r from-purple-300 to-lime-600",
  "bg-gradient-to-r from-purple-300 to-lime-700",
  "bg-gradient-to-r from-purple-300 to-lime-800",
  "bg-gradient-to-r from-purple-300 to-lime-900",
  "bg-gradient-to-r from-purple-300 to-lime-950",
  "bg-gradient-to-r from-purple-300 to-green-300",
  "bg-gradient-to-r from-purple-300 to-green-400",
  "bg-gradient-to-r from-purple-300 to-green-500",
  "bg-gradient-to-r from-purple-300 to-green-600",
  "bg-gradient-to-r from-purple-300 to-green-700",
  "bg-gradient-to-r from-purple-300 to-green-800",
  "bg-gradient-to-r from-purple-300 to-green-900",
  "bg-gradient-to-r from-purple-300 to-green-950",
  "bg-gradient-to-r from-purple-300 to-emerald-300",
  "bg-gradient-to-r from-purple-300 to-emerald-400",
  "bg-gradient-to-r from-purple-300 to-emerald-500",
  "bg-gradient-to-r from-purple-300 to-emerald-600",
  "bg-gradient-to-r from-purple-300 to-emerald-700",
  "bg-gradient-to-r from-purple-300 to-emerald-800",
  "bg-gradient-to-r from-purple-300 to-emerald-900",
  "bg-gradient-to-r from-purple-300 to-emerald-950",
  "bg-gradient-to-r from-purple-300 to-teal-300",
  "bg-gradient-to-r from-purple-300 to-teal-400",
  "bg-gradient-to-r from-purple-300 to-teal-500",
  "bg-gradient-to-r from-purple-300 to-teal-600",
  "bg-gradient-to-r from-purple-300 to-teal-700",
  "bg-gradient-to-r from-purple-300 to-teal-800",
  "bg-gradient-to-r from-purple-300 to-teal-900",
  "bg-gradient-to-r from-purple-300 to-teal-950",
  "bg-gradient-to-r from-purple-300 to-cyan-300",
  "bg-gradient-to-r from-purple-300 to-cyan-400",
  "bg-gradient-to-r from-purple-300 to-cyan-500",
  "bg-gradient-to-r from-purple-300 to-cyan-600",
  "bg-gradient-to-r from-purple-300 to-cyan-700",
  "bg-gradient-to-r from-purple-300 to-cyan-800",
  "bg-gradient-to-r from-purple-300 to-cyan-900",
  "bg-gradient-to-r from-purple-300 to-cyan-950",
  "bg-gradient-to-r from-purple-300 to-sky-300",
  "bg-gradient-to-r from-purple-300 to-sky-400",
  "bg-gradient-to-r from-purple-300 to-sky-500",
  "bg-gradient-to-r from-purple-300 to-sky-600",
  "bg-gradient-to-r from-purple-300 to-sky-700",
  "bg-gradient-to-r from-purple-300 to-sky-800",
  "bg-gradient-to-r from-purple-300 to-sky-900",
  "bg-gradient-to-r from-purple-300 to-sky-950",
  "bg-gradient-to-r from-purple-300 to-blue-300",
  "bg-gradient-to-r from-purple-300 to-blue-400",
  "bg-gradient-to-r from-purple-300 to-blue-500",
  "bg-gradient-to-r from-purple-300 to-blue-600",
  "bg-gradient-to-r from-purple-300 to-blue-700",
  "bg-gradient-to-r from-purple-300 to-blue-800",
  "bg-gradient-to-r from-purple-300 to-blue-900",
  "bg-gradient-to-r from-purple-300 to-blue-950",
  "bg-gradient-to-r from-purple-300 to-indigo-300",
  "bg-gradient-to-r from-purple-300 to-indigo-400",
  "bg-gradient-to-r from-purple-300 to-indigo-500",
  "bg-gradient-to-r from-purple-300 to-indigo-600",
  "bg-gradient-to-r from-purple-300 to-indigo-700",
  "bg-gradient-to-r from-purple-300 to-indigo-800",
  "bg-gradient-to-r from-purple-300 to-indigo-900",
  "bg-gradient-to-r from-purple-300 to-indigo-950",
  "bg-gradient-to-r from-purple-300 to-violet-300",
  "bg-gradient-to-r from-purple-300 to-violet-400",
  "bg-gradient-to-r from-purple-300 to-violet-500",
  "bg-gradient-to-r from-purple-300 to-violet-600",
  "bg-gradient-to-r from-purple-300 to-violet-700",
  "bg-gradient-to-r from-purple-300 to-violet-800",
  "bg-gradient-to-r from-purple-300 to-violet-900",
  "bg-gradient-to-r from-purple-300 to-violet-950",
  "bg-gradient-to-r from-purple-300 to-fuchsia-300",
  "bg-gradient-to-r from-purple-300 to-fuchsia-400",
  "bg-gradient-to-r from-purple-300 to-fuchsia-500",
  "bg-gradient-to-r from-purple-300 to-fuchsia-600",
  "bg-gradient-to-r from-purple-300 to-fuchsia-700",
  "bg-gradient-to-r from-purple-300 to-fuchsia-800",
  "bg-gradient-to-r from-purple-300 to-fuchsia-900",
  "bg-gradient-to-r from-purple-300 to-fuchsia-950",
  "bg-gradient-to-r from-purple-300 to-pink-300",
  "bg-gradient-to-r from-purple-300 to-pink-400",
  "bg-gradient-to-r from-purple-300 to-pink-500",
  "bg-gradient-to-r from-purple-300 to-pink-600",
  "bg-gradient-to-r from-purple-300 to-pink-700",
  "bg-gradient-to-r from-purple-300 to-pink-800",
  "bg-gradient-to-r from-purple-300 to-pink-900",
  "bg-gradient-to-r from-purple-300 to-pink-950",
  "bg-gradient-to-r from-purple-300 to-rose-300",
  "bg-gradient-to-r from-purple-300 to-rose-400",
  "bg-gradient-to-r from-purple-300 to-rose-500",
  "bg-gradient-to-r from-purple-300 to-rose-600",
  "bg-gradient-to-r from-purple-300 to-rose-700",
  "bg-gradient-to-r from-purple-300 to-rose-800",
  "bg-gradient-to-r from-purple-300 to-rose-900",
  "bg-gradient-to-r from-purple-300 to-rose-950",
  "bg-gradient-to-r from-purple-400 to-red-300",
  "bg-gradient-to-r from-purple-400 to-red-400",
  "bg-gradient-to-r from-purple-400 to-red-500",
  "bg-gradient-to-r from-purple-400 to-red-600",
  "bg-gradient-to-r from-purple-400 to-red-700",
  "bg-gradient-to-r from-purple-400 to-red-800",
  "bg-gradient-to-r from-purple-400 to-red-900",
  "bg-gradient-to-r from-purple-400 to-red-950",
  "bg-gradient-to-r from-purple-400 to-orange-300",
  "bg-gradient-to-r from-purple-400 to-orange-400",
  "bg-gradient-to-r from-purple-400 to-orange-500",
  "bg-gradient-to-r from-purple-400 to-orange-600",
  "bg-gradient-to-r from-purple-400 to-orange-700",
  "bg-gradient-to-r from-purple-400 to-orange-800",
  "bg-gradient-to-r from-purple-400 to-orange-900",
  "bg-gradient-to-r from-purple-400 to-orange-950",
  "bg-gradient-to-r from-purple-400 to-amber-300",
  "bg-gradient-to-r from-purple-400 to-amber-400",
  "bg-gradient-to-r from-purple-400 to-amber-500",
  "bg-gradient-to-r from-purple-400 to-amber-600",
  "bg-gradient-to-r from-purple-400 to-amber-700",
  "bg-gradient-to-r from-purple-400 to-amber-800",
  "bg-gradient-to-r from-purple-400 to-amber-900",
  "bg-gradient-to-r from-purple-400 to-amber-950",
  "bg-gradient-to-r from-purple-400 to-yellow-300",
  "bg-gradient-to-r from-purple-400 to-yellow-400",
  "bg-gradient-to-r from-purple-400 to-yellow-500",
  "bg-gradient-to-r from-purple-400 to-yellow-600",
  "bg-gradient-to-r from-purple-400 to-yellow-700",
  "bg-gradient-to-r from-purple-400 to-yellow-800",
  "bg-gradient-to-r from-purple-400 to-yellow-900",
  "bg-gradient-to-r from-purple-400 to-yellow-950",
  "bg-gradient-to-r from-purple-400 to-lime-300",
  "bg-gradient-to-r from-purple-400 to-lime-400",
  "bg-gradient-to-r from-purple-400 to-lime-500",
  "bg-gradient-to-r from-purple-400 to-lime-600",
  "bg-gradient-to-r from-purple-400 to-lime-700",
  "bg-gradient-to-r from-purple-400 to-lime-800",
  "bg-gradient-to-r from-purple-400 to-lime-900",
  "bg-gradient-to-r from-purple-400 to-lime-950",
  "bg-gradient-to-r from-purple-400 to-green-300",
  "bg-gradient-to-r from-purple-400 to-green-400",
  "bg-gradient-to-r from-purple-400 to-green-500",
  "bg-gradient-to-r from-purple-400 to-green-600",
  "bg-gradient-to-r from-purple-400 to-green-700",
  "bg-gradient-to-r from-purple-400 to-green-800",
  "bg-gradient-to-r from-purple-400 to-green-900",
  "bg-gradient-to-r from-purple-400 to-green-950",
  "bg-gradient-to-r from-purple-400 to-emerald-300",
  "bg-gradient-to-r from-purple-400 to-emerald-400",
  "bg-gradient-to-r from-purple-400 to-emerald-500",
  "bg-gradient-to-r from-purple-400 to-emerald-600",
  "bg-gradient-to-r from-purple-400 to-emerald-700",
  "bg-gradient-to-r from-purple-400 to-emerald-800",
  "bg-gradient-to-r from-purple-400 to-emerald-900",
  "bg-gradient-to-r from-purple-400 to-emerald-950",
  "bg-gradient-to-r from-purple-400 to-teal-300",
  "bg-gradient-to-r from-purple-400 to-teal-400",
  "bg-gradient-to-r from-purple-400 to-teal-500",
  "bg-gradient-to-r from-purple-400 to-teal-600",
  "bg-gradient-to-r from-purple-400 to-teal-700",
  "bg-gradient-to-r from-purple-400 to-teal-800",
  "bg-gradient-to-r from-purple-400 to-teal-900",
  "bg-gradient-to-r from-purple-400 to-teal-950",
  "bg-gradient-to-r from-purple-400 to-cyan-300",
  "bg-gradient-to-r from-purple-400 to-cyan-400",
  "bg-gradient-to-r from-purple-400 to-cyan-500",
  "bg-gradient-to-r from-purple-400 to-cyan-600",
  "bg-gradient-to-r from-purple-400 to-cyan-700",
  "bg-gradient-to-r from-purple-400 to-cyan-800",
  "bg-gradient-to-r from-purple-400 to-cyan-900",
  "bg-gradient-to-r from-purple-400 to-cyan-950",
  "bg-gradient-to-r from-purple-400 to-sky-300",
  "bg-gradient-to-r from-purple-400 to-sky-400",
  "bg-gradient-to-r from-purple-400 to-sky-500",
  "bg-gradient-to-r from-purple-400 to-sky-600",
  "bg-gradient-to-r from-purple-400 to-sky-700",
  "bg-gradient-to-r from-purple-400 to-sky-800",
  "bg-gradient-to-r from-purple-400 to-sky-900",
  "bg-gradient-to-r from-purple-400 to-sky-950",
  "bg-gradient-to-r from-purple-400 to-blue-300",
  "bg-gradient-to-r from-purple-400 to-blue-400",
  "bg-gradient-to-r from-purple-400 to-blue-500",
  "bg-gradient-to-r from-purple-400 to-blue-600",
  "bg-gradient-to-r from-purple-400 to-blue-700",
  "bg-gradient-to-r from-purple-400 to-blue-800",
  "bg-gradient-to-r from-purple-400 to-blue-900",
  "bg-gradient-to-r from-purple-400 to-blue-950",
  "bg-gradient-to-r from-purple-400 to-indigo-300",
  "bg-gradient-to-r from-purple-400 to-indigo-400",
  "bg-gradient-to-r from-purple-400 to-indigo-500",
  "bg-gradient-to-r from-purple-400 to-indigo-600",
  "bg-gradient-to-r from-purple-400 to-indigo-700",
  "bg-gradient-to-r from-purple-400 to-indigo-800",
  "bg-gradient-to-r from-purple-400 to-indigo-900",
  "bg-gradient-to-r from-purple-400 to-indigo-950",
  "bg-gradient-to-r from-purple-400 to-violet-300",
  "bg-gradient-to-r from-purple-400 to-violet-400",
  "bg-gradient-to-r from-purple-400 to-violet-500",
  "bg-gradient-to-r from-purple-400 to-violet-600",
  "bg-gradient-to-r from-purple-400 to-violet-700",
  "bg-gradient-to-r from-purple-400 to-violet-800",
  "bg-gradient-to-r from-purple-400 to-violet-900",
  "bg-gradient-to-r from-purple-400 to-violet-950",
  "bg-gradient-to-r from-purple-400 to-fuchsia-300",
  "bg-gradient-to-r from-purple-400 to-fuchsia-400",
  "bg-gradient-to-r from-purple-400 to-fuchsia-500",
  "bg-gradient-to-r from-purple-400 to-fuchsia-600",
  "bg-gradient-to-r from-purple-400 to-fuchsia-700",
  "bg-gradient-to-r from-purple-400 to-fuchsia-800",
  "bg-gradient-to-r from-purple-400 to-fuchsia-900",
  "bg-gradient-to-r from-purple-400 to-fuchsia-950",
  "bg-gradient-to-r from-purple-400 to-pink-300",
  "bg-gradient-to-r from-purple-400 to-pink-400",
  "bg-gradient-to-r from-purple-400 to-pink-500",
  "bg-gradient-to-r from-purple-400 to-pink-600",
  "bg-gradient-to-r from-purple-400 to-pink-700",
  "bg-gradient-to-r from-purple-400 to-pink-800",
  "bg-gradient-to-r from-purple-400 to-pink-900",
  "bg-gradient-to-r from-purple-400 to-pink-950",
  "bg-gradient-to-r from-purple-400 to-rose-300",
  "bg-gradient-to-r from-purple-400 to-rose-400",
  "bg-gradient-to-r from-purple-400 to-rose-500",
  "bg-gradient-to-r from-purple-400 to-rose-600",
  "bg-gradient-to-r from-purple-400 to-rose-700",
  "bg-gradient-to-r from-purple-400 to-rose-800",
  "bg-gradient-to-r from-purple-400 to-rose-900",
  "bg-gradient-to-r from-purple-400 to-rose-950",
  "bg-gradient-to-r from-purple-500 to-red-300",
  "bg-gradient-to-r from-purple-500 to-red-400",
  "bg-gradient-to-r from-purple-500 to-red-500",
  "bg-gradient-to-r from-purple-500 to-red-600",
  "bg-gradient-to-r from-purple-500 to-red-700",
  "bg-gradient-to-r from-purple-500 to-red-800",
  "bg-gradient-to-r from-purple-500 to-red-900",
  "bg-gradient-to-r from-purple-500 to-red-950",
  "bg-gradient-to-r from-purple-500 to-orange-300",
  "bg-gradient-to-r from-purple-500 to-orange-400",
  "bg-gradient-to-r from-purple-500 to-orange-500",
  "bg-gradient-to-r from-purple-500 to-orange-600",
  "bg-gradient-to-r from-purple-500 to-orange-700",
  "bg-gradient-to-r from-purple-500 to-orange-800",
  "bg-gradient-to-r from-purple-500 to-orange-900",
  "bg-gradient-to-r from-purple-500 to-orange-950",
  "bg-gradient-to-r from-purple-500 to-amber-300",
  "bg-gradient-to-r from-purple-500 to-amber-400",
  "bg-gradient-to-r from-purple-500 to-amber-500",
  "bg-gradient-to-r from-purple-500 to-amber-600",
  "bg-gradient-to-r from-purple-500 to-amber-700",
  "bg-gradient-to-r from-purple-500 to-amber-800",
  "bg-gradient-to-r from-purple-500 to-amber-900",
  "bg-gradient-to-r from-purple-500 to-amber-950",
  "bg-gradient-to-r from-purple-500 to-yellow-300",
  "bg-gradient-to-r from-purple-500 to-yellow-400",
  "bg-gradient-to-r from-purple-500 to-yellow-500",
  "bg-gradient-to-r from-purple-500 to-yellow-600",
  "bg-gradient-to-r from-purple-500 to-yellow-700",
  "bg-gradient-to-r from-purple-500 to-yellow-800",
  "bg-gradient-to-r from-purple-500 to-yellow-900",
  "bg-gradient-to-r from-purple-500 to-yellow-950",
  "bg-gradient-to-r from-purple-500 to-lime-300",
  "bg-gradient-to-r from-purple-500 to-lime-400",
  "bg-gradient-to-r from-purple-500 to-lime-500",
  "bg-gradient-to-r from-purple-500 to-lime-600",
  "bg-gradient-to-r from-purple-500 to-lime-700",
  "bg-gradient-to-r from-purple-500 to-lime-800",
  "bg-gradient-to-r from-purple-500 to-lime-900",
  "bg-gradient-to-r from-purple-500 to-lime-950",
  "bg-gradient-to-r from-purple-500 to-green-300",
  "bg-gradient-to-r from-purple-500 to-green-400",
  "bg-gradient-to-r from-purple-500 to-green-500",
  "bg-gradient-to-r from-purple-500 to-green-600",
  "bg-gradient-to-r from-purple-500 to-green-700",
  "bg-gradient-to-r from-purple-500 to-green-800",
  "bg-gradient-to-r from-purple-500 to-green-900",
  "bg-gradient-to-r from-purple-500 to-green-950",
  "bg-gradient-to-r from-purple-500 to-emerald-300",
  "bg-gradient-to-r from-purple-500 to-emerald-400",
  "bg-gradient-to-r from-purple-500 to-emerald-500",
  "bg-gradient-to-r from-purple-500 to-emerald-600",
  "bg-gradient-to-r from-purple-500 to-emerald-700",
  "bg-gradient-to-r from-purple-500 to-emerald-800",
  "bg-gradient-to-r from-purple-500 to-emerald-900",
  "bg-gradient-to-r from-purple-500 to-emerald-950",
  "bg-gradient-to-r from-purple-500 to-teal-300",
  "bg-gradient-to-r from-purple-500 to-teal-400",
  "bg-gradient-to-r from-purple-500 to-teal-500",
  "bg-gradient-to-r from-purple-500 to-teal-600",
  "bg-gradient-to-r from-purple-500 to-teal-700",
  "bg-gradient-to-r from-purple-500 to-teal-800",
  "bg-gradient-to-r from-purple-500 to-teal-900",
  "bg-gradient-to-r from-purple-500 to-teal-950",
  "bg-gradient-to-r from-purple-500 to-cyan-300",
  "bg-gradient-to-r from-purple-500 to-cyan-400",
  "bg-gradient-to-r from-purple-500 to-cyan-500",
  "bg-gradient-to-r from-purple-500 to-cyan-600",
  "bg-gradient-to-r from-purple-500 to-cyan-700",
  "bg-gradient-to-r from-purple-500 to-cyan-800",
  "bg-gradient-to-r from-purple-500 to-cyan-900",
  "bg-gradient-to-r from-purple-500 to-cyan-950",
  "bg-gradient-to-r from-purple-500 to-sky-300",
  "bg-gradient-to-r from-purple-500 to-sky-400",
  "bg-gradient-to-r from-purple-500 to-sky-500",
  "bg-gradient-to-r from-purple-500 to-sky-600",
  "bg-gradient-to-r from-purple-500 to-sky-700",
  "bg-gradient-to-r from-purple-500 to-sky-800",
  "bg-gradient-to-r from-purple-500 to-sky-900",
  "bg-gradient-to-r from-purple-500 to-sky-950",
  "bg-gradient-to-r from-purple-500 to-blue-300",
  "bg-gradient-to-r from-purple-500 to-blue-400",
  "bg-gradient-to-r from-purple-500 to-blue-500",
  "bg-gradient-to-r from-purple-500 to-blue-600",
  "bg-gradient-to-r from-purple-500 to-blue-700",
  "bg-gradient-to-r from-purple-500 to-blue-800",
  "bg-gradient-to-r from-purple-500 to-blue-900",
  "bg-gradient-to-r from-purple-500 to-blue-950",
  "bg-gradient-to-r from-purple-500 to-indigo-300",
  "bg-gradient-to-r from-purple-500 to-indigo-400",
  "bg-gradient-to-r from-purple-500 to-indigo-500",
  "bg-gradient-to-r from-purple-500 to-indigo-600",
  "bg-gradient-to-r from-purple-500 to-indigo-700",
  "bg-gradient-to-r from-purple-500 to-indigo-800",
  "bg-gradient-to-r from-purple-500 to-indigo-900",
  "bg-gradient-to-r from-purple-500 to-indigo-950",
  "bg-gradient-to-r from-purple-500 to-violet-300",
  "bg-gradient-to-r from-purple-500 to-violet-400",
  "bg-gradient-to-r from-purple-500 to-violet-500",
  "bg-gradient-to-r from-purple-500 to-violet-600",
  "bg-gradient-to-r from-purple-500 to-violet-700",
  "bg-gradient-to-r from-purple-500 to-violet-800",
  "bg-gradient-to-r from-purple-500 to-violet-900",
  "bg-gradient-to-r from-purple-500 to-violet-950",
  "bg-gradient-to-r from-purple-500 to-fuchsia-300",
  "bg-gradient-to-r from-purple-500 to-fuchsia-400",
  "bg-gradient-to-r from-purple-500 to-fuchsia-500",
  "bg-gradient-to-r from-purple-500 to-fuchsia-600",
  "bg-gradient-to-r from-purple-500 to-fuchsia-700",
  "bg-gradient-to-r from-purple-500 to-fuchsia-800",
  "bg-gradient-to-r from-purple-500 to-fuchsia-900",
  "bg-gradient-to-r from-purple-500 to-fuchsia-950",
  "bg-gradient-to-r from-purple-500 to-pink-300",
  "bg-gradient-to-r from-purple-500 to-pink-400",
  "bg-gradient-to-r from-purple-500 to-pink-500",
  "bg-gradient-to-r from-purple-500 to-pink-600",
  "bg-gradient-to-r from-purple-500 to-pink-700",
  "bg-gradient-to-r from-purple-500 to-pink-800",
  "bg-gradient-to-r from-purple-500 to-pink-900",
  "bg-gradient-to-r from-purple-500 to-pink-950",
  "bg-gradient-to-r from-purple-500 to-rose-300",
  "bg-gradient-to-r from-purple-500 to-rose-400",
  "bg-gradient-to-r from-purple-500 to-rose-500",
  "bg-gradient-to-r from-purple-500 to-rose-600",
  "bg-gradient-to-r from-purple-500 to-rose-700",
  "bg-gradient-to-r from-purple-500 to-rose-800",
  "bg-gradient-to-r from-purple-500 to-rose-900",
  "bg-gradient-to-r from-purple-500 to-rose-950",
  "bg-gradient-to-r from-purple-600 to-red-300",
  "bg-gradient-to-r from-purple-600 to-red-400",
  "bg-gradient-to-r from-purple-600 to-red-500",
  "bg-gradient-to-r from-purple-600 to-red-600",
  "bg-gradient-to-r from-purple-600 to-red-700",
  "bg-gradient-to-r from-purple-600 to-red-800",
  "bg-gradient-to-r from-purple-600 to-red-900",
  "bg-gradient-to-r from-purple-600 to-red-950",
  "bg-gradient-to-r from-purple-600 to-orange-300",
  "bg-gradient-to-r from-purple-600 to-orange-400",
  "bg-gradient-to-r from-purple-600 to-orange-500",
  "bg-gradient-to-r from-purple-600 to-orange-600",
  "bg-gradient-to-r from-purple-600 to-orange-700",
  "bg-gradient-to-r from-purple-600 to-orange-800",
  "bg-gradient-to-r from-purple-600 to-orange-900",
  "bg-gradient-to-r from-purple-600 to-orange-950",
  "bg-gradient-to-r from-purple-600 to-amber-300",
  "bg-gradient-to-r from-purple-600 to-amber-400",
  "bg-gradient-to-r from-purple-600 to-amber-500",
  "bg-gradient-to-r from-purple-600 to-amber-600",
  "bg-gradient-to-r from-purple-600 to-amber-700",
  "bg-gradient-to-r from-purple-600 to-amber-800",
  "bg-gradient-to-r from-purple-600 to-amber-900",
  "bg-gradient-to-r from-purple-600 to-amber-950",
  "bg-gradient-to-r from-purple-600 to-yellow-300",
  "bg-gradient-to-r from-purple-600 to-yellow-400",
  "bg-gradient-to-r from-purple-600 to-yellow-500",
  "bg-gradient-to-r from-purple-600 to-yellow-600",
  "bg-gradient-to-r from-purple-600 to-yellow-700",
  "bg-gradient-to-r from-purple-600 to-yellow-800",
  "bg-gradient-to-r from-purple-600 to-yellow-900",
  "bg-gradient-to-r from-purple-600 to-yellow-950",
  "bg-gradient-to-r from-purple-600 to-lime-300",
  "bg-gradient-to-r from-purple-600 to-lime-400",
  "bg-gradient-to-r from-purple-600 to-lime-500",
  "bg-gradient-to-r from-purple-600 to-lime-600",
  "bg-gradient-to-r from-purple-600 to-lime-700",
  "bg-gradient-to-r from-purple-600 to-lime-800",
  "bg-gradient-to-r from-purple-600 to-lime-900",
  "bg-gradient-to-r from-purple-600 to-lime-950",
  "bg-gradient-to-r from-purple-600 to-green-300",
  "bg-gradient-to-r from-purple-600 to-green-400",
  "bg-gradient-to-r from-purple-600 to-green-500",
  "bg-gradient-to-r from-purple-600 to-green-600",
  "bg-gradient-to-r from-purple-600 to-green-700",
  "bg-gradient-to-r from-purple-600 to-green-800",
  "bg-gradient-to-r from-purple-600 to-green-900",
  "bg-gradient-to-r from-purple-600 to-green-950",
  "bg-gradient-to-r from-purple-600 to-emerald-300",
  "bg-gradient-to-r from-purple-600 to-emerald-400",
  "bg-gradient-to-r from-purple-600 to-emerald-500",
  "bg-gradient-to-r from-purple-600 to-emerald-600",
  "bg-gradient-to-r from-purple-600 to-emerald-700",
  "bg-gradient-to-r from-purple-600 to-emerald-800",
  "bg-gradient-to-r from-purple-600 to-emerald-900",
  "bg-gradient-to-r from-purple-600 to-emerald-950",
  "bg-gradient-to-r from-purple-600 to-teal-300",
  "bg-gradient-to-r from-purple-600 to-teal-400",
  "bg-gradient-to-r from-purple-600 to-teal-500",
  "bg-gradient-to-r from-purple-600 to-teal-600",
  "bg-gradient-to-r from-purple-600 to-teal-700",
  "bg-gradient-to-r from-purple-600 to-teal-800",
  "bg-gradient-to-r from-purple-600 to-teal-900",
  "bg-gradient-to-r from-purple-600 to-teal-950",
  "bg-gradient-to-r from-purple-600 to-cyan-300",
  "bg-gradient-to-r from-purple-600 to-cyan-400",
  "bg-gradient-to-r from-purple-600 to-cyan-500",
  "bg-gradient-to-r from-purple-600 to-cyan-600",
  "bg-gradient-to-r from-purple-600 to-cyan-700",
  "bg-gradient-to-r from-purple-600 to-cyan-800",
  "bg-gradient-to-r from-purple-600 to-cyan-900",
  "bg-gradient-to-r from-purple-600 to-cyan-950",
  "bg-gradient-to-r from-purple-600 to-sky-300",
  "bg-gradient-to-r from-purple-600 to-sky-400",
  "bg-gradient-to-r from-purple-600 to-sky-500",
  "bg-gradient-to-r from-purple-600 to-sky-600",
  "bg-gradient-to-r from-purple-600 to-sky-700",
  "bg-gradient-to-r from-purple-600 to-sky-800",
  "bg-gradient-to-r from-purple-600 to-sky-900",
  "bg-gradient-to-r from-purple-600 to-sky-950",
  "bg-gradient-to-r from-purple-600 to-blue-300",
  "bg-gradient-to-r from-purple-600 to-blue-400",
  "bg-gradient-to-r from-purple-600 to-blue-500",
  "bg-gradient-to-r from-purple-600 to-blue-600",
  "bg-gradient-to-r from-purple-600 to-blue-700",
  "bg-gradient-to-r from-purple-600 to-blue-800",
  "bg-gradient-to-r from-purple-600 to-blue-900",
  "bg-gradient-to-r from-purple-600 to-blue-950",
  "bg-gradient-to-r from-purple-600 to-indigo-300",
  "bg-gradient-to-r from-purple-600 to-indigo-400",
  "bg-gradient-to-r from-purple-600 to-indigo-500",
  "bg-gradient-to-r from-purple-600 to-indigo-600",
  "bg-gradient-to-r from-purple-600 to-indigo-700",
  "bg-gradient-to-r from-purple-600 to-indigo-800",
  "bg-gradient-to-r from-purple-600 to-indigo-900",
  "bg-gradient-to-r from-purple-600 to-indigo-950",
  "bg-gradient-to-r from-purple-600 to-violet-300",
  "bg-gradient-to-r from-purple-600 to-violet-400",
  "bg-gradient-to-r from-purple-600 to-violet-500",
  "bg-gradient-to-r from-purple-600 to-violet-600",
  "bg-gradient-to-r from-purple-600 to-violet-700",
  "bg-gradient-to-r from-purple-600 to-violet-800",
  "bg-gradient-to-r from-purple-600 to-violet-900",
  "bg-gradient-to-r from-purple-600 to-violet-950",
  "bg-gradient-to-r from-purple-600 to-fuchsia-300",
  "bg-gradient-to-r from-purple-600 to-fuchsia-400",
  "bg-gradient-to-r from-purple-600 to-fuchsia-500",
  "bg-gradient-to-r from-purple-600 to-fuchsia-600",
  "bg-gradient-to-r from-purple-600 to-fuchsia-700",
  "bg-gradient-to-r from-purple-600 to-fuchsia-800",
  "bg-gradient-to-r from-purple-600 to-fuchsia-900",
  "bg-gradient-to-r from-purple-600 to-fuchsia-950",
  "bg-gradient-to-r from-purple-600 to-pink-300",
  "bg-gradient-to-r from-purple-600 to-pink-400",
  "bg-gradient-to-r from-purple-600 to-pink-500",
  "bg-gradient-to-r from-purple-600 to-pink-600",
  "bg-gradient-to-r from-purple-600 to-pink-700",
  "bg-gradient-to-r from-purple-600 to-pink-800",
  "bg-gradient-to-r from-purple-600 to-pink-900",
  "bg-gradient-to-r from-purple-600 to-pink-950",
  "bg-gradient-to-r from-purple-600 to-rose-300",
  "bg-gradient-to-r from-purple-600 to-rose-400",
  "bg-gradient-to-r from-purple-600 to-rose-500",
  "bg-gradient-to-r from-purple-600 to-rose-600",
  "bg-gradient-to-r from-purple-600 to-rose-700",
  "bg-gradient-to-r from-purple-600 to-rose-800",
  "bg-gradient-to-r from-purple-600 to-rose-900",
  "bg-gradient-to-r from-purple-600 to-rose-950",
  "bg-gradient-to-r from-purple-700 to-red-300",
  "bg-gradient-to-r from-purple-700 to-red-400",
  "bg-gradient-to-r from-purple-700 to-red-500",
  "bg-gradient-to-r from-purple-700 to-red-600",
  "bg-gradient-to-r from-purple-700 to-red-700",
  "bg-gradient-to-r from-purple-700 to-red-800",
  "bg-gradient-to-r from-purple-700 to-red-900",
  "bg-gradient-to-r from-purple-700 to-red-950",
  "bg-gradient-to-r from-purple-700 to-orange-300",
  "bg-gradient-to-r from-purple-700 to-orange-400",
  "bg-gradient-to-r from-purple-700 to-orange-500",
  "bg-gradient-to-r from-purple-700 to-orange-600",
  "bg-gradient-to-r from-purple-700 to-orange-700",
  "bg-gradient-to-r from-purple-700 to-orange-800",
  "bg-gradient-to-r from-purple-700 to-orange-900",
  "bg-gradient-to-r from-purple-700 to-orange-950",
  "bg-gradient-to-r from-purple-700 to-amber-300",
  "bg-gradient-to-r from-purple-700 to-amber-400",
  "bg-gradient-to-r from-purple-700 to-amber-500",
  "bg-gradient-to-r from-purple-700 to-amber-600",
  "bg-gradient-to-r from-purple-700 to-amber-700",
  "bg-gradient-to-r from-purple-700 to-amber-800",
  "bg-gradient-to-r from-purple-700 to-amber-900",
  "bg-gradient-to-r from-purple-700 to-amber-950",
  "bg-gradient-to-r from-purple-700 to-yellow-300",
  "bg-gradient-to-r from-purple-700 to-yellow-400",
  "bg-gradient-to-r from-purple-700 to-yellow-500",
  "bg-gradient-to-r from-purple-700 to-yellow-600",
  "bg-gradient-to-r from-purple-700 to-yellow-700",
  "bg-gradient-to-r from-purple-700 to-yellow-800",
  "bg-gradient-to-r from-purple-700 to-yellow-900",
  "bg-gradient-to-r from-purple-700 to-yellow-950",
  "bg-gradient-to-r from-purple-700 to-lime-300",
  "bg-gradient-to-r from-purple-700 to-lime-400",
  "bg-gradient-to-r from-purple-700 to-lime-500",
  "bg-gradient-to-r from-purple-700 to-lime-600",
  "bg-gradient-to-r from-purple-700 to-lime-700",
  "bg-gradient-to-r from-purple-700 to-lime-800",
  "bg-gradient-to-r from-purple-700 to-lime-900",
  "bg-gradient-to-r from-purple-700 to-lime-950",
  "bg-gradient-to-r from-purple-700 to-green-300",
  "bg-gradient-to-r from-purple-700 to-green-400",
  "bg-gradient-to-r from-purple-700 to-green-500",
  "bg-gradient-to-r from-purple-700 to-green-600",
  "bg-gradient-to-r from-purple-700 to-green-700",
  "bg-gradient-to-r from-purple-700 to-green-800",
  "bg-gradient-to-r from-purple-700 to-green-900",
  "bg-gradient-to-r from-purple-700 to-green-950",
  "bg-gradient-to-r from-purple-700 to-emerald-300",
  "bg-gradient-to-r from-purple-700 to-emerald-400",
  "bg-gradient-to-r from-purple-700 to-emerald-500",
  "bg-gradient-to-r from-purple-700 to-emerald-600",
  "bg-gradient-to-r from-purple-700 to-emerald-700",
  "bg-gradient-to-r from-purple-700 to-emerald-800",
  "bg-gradient-to-r from-purple-700 to-emerald-900",
  "bg-gradient-to-r from-purple-700 to-emerald-950",
  "bg-gradient-to-r from-purple-700 to-teal-300",
  "bg-gradient-to-r from-purple-700 to-teal-400",
  "bg-gradient-to-r from-purple-700 to-teal-500",
  "bg-gradient-to-r from-purple-700 to-teal-600",
  "bg-gradient-to-r from-purple-700 to-teal-700",
  "bg-gradient-to-r from-purple-700 to-teal-800",
  "bg-gradient-to-r from-purple-700 to-teal-900",
  "bg-gradient-to-r from-purple-700 to-teal-950",
  "bg-gradient-to-r from-purple-700 to-cyan-300",
  "bg-gradient-to-r from-purple-700 to-cyan-400",
  "bg-gradient-to-r from-purple-700 to-cyan-500",
  "bg-gradient-to-r from-purple-700 to-cyan-600",
  "bg-gradient-to-r from-purple-700 to-cyan-700",
  "bg-gradient-to-r from-purple-700 to-cyan-800",
  "bg-gradient-to-r from-purple-700 to-cyan-900",
  "bg-gradient-to-r from-purple-700 to-cyan-950",
  "bg-gradient-to-r from-purple-700 to-sky-300",
  "bg-gradient-to-r from-purple-700 to-sky-400",
  "bg-gradient-to-r from-purple-700 to-sky-500",
  "bg-gradient-to-r from-purple-700 to-sky-600",
  "bg-gradient-to-r from-purple-700 to-sky-700",
  "bg-gradient-to-r from-purple-700 to-sky-800",
  "bg-gradient-to-r from-purple-700 to-sky-900",
  "bg-gradient-to-r from-purple-700 to-sky-950",
  "bg-gradient-to-r from-purple-700 to-blue-300",
  "bg-gradient-to-r from-purple-700 to-blue-400",
  "bg-gradient-to-r from-purple-700 to-blue-500",
  "bg-gradient-to-r from-purple-700 to-blue-600",
  "bg-gradient-to-r from-purple-700 to-blue-700",
  "bg-gradient-to-r from-purple-700 to-blue-800",
  "bg-gradient-to-r from-purple-700 to-blue-900",
  "bg-gradient-to-r from-purple-700 to-blue-950",
  "bg-gradient-to-r from-purple-700 to-indigo-300",
  "bg-gradient-to-r from-purple-700 to-indigo-400",
  "bg-gradient-to-r from-purple-700 to-indigo-500",
  "bg-gradient-to-r from-purple-700 to-indigo-600",
  "bg-gradient-to-r from-purple-700 to-indigo-700",
  "bg-gradient-to-r from-purple-700 to-indigo-800",
  "bg-gradient-to-r from-purple-700 to-indigo-900",
  "bg-gradient-to-r from-purple-700 to-indigo-950",
  "bg-gradient-to-r from-purple-700 to-violet-300",
  "bg-gradient-to-r from-purple-700 to-violet-400",
  "bg-gradient-to-r from-purple-700 to-violet-500",
  "bg-gradient-to-r from-purple-700 to-violet-600",
  "bg-gradient-to-r from-purple-700 to-violet-700",
  "bg-gradient-to-r from-purple-700 to-violet-800",
  "bg-gradient-to-r from-purple-700 to-violet-900",
  "bg-gradient-to-r from-purple-700 to-violet-950",
  "bg-gradient-to-r from-purple-700 to-fuchsia-300",
  "bg-gradient-to-r from-purple-700 to-fuchsia-400",
  "bg-gradient-to-r from-purple-700 to-fuchsia-500",
  "bg-gradient-to-r from-purple-700 to-fuchsia-600",
  "bg-gradient-to-r from-purple-700 to-fuchsia-700",
  "bg-gradient-to-r from-purple-700 to-fuchsia-800",
  "bg-gradient-to-r from-purple-700 to-fuchsia-900",
  "bg-gradient-to-r from-purple-700 to-fuchsia-950",
  "bg-gradient-to-r from-purple-700 to-pink-300",
  "bg-gradient-to-r from-purple-700 to-pink-400",
  "bg-gradient-to-r from-purple-700 to-pink-500",
  "bg-gradient-to-r from-purple-700 to-pink-600",
  "bg-gradient-to-r from-purple-700 to-pink-700",
  "bg-gradient-to-r from-purple-700 to-pink-800",
  "bg-gradient-to-r from-purple-700 to-pink-900",
  "bg-gradient-to-r from-purple-700 to-pink-950",
  "bg-gradient-to-r from-purple-700 to-rose-300",
  "bg-gradient-to-r from-purple-700 to-rose-400",
  "bg-gradient-to-r from-purple-700 to-rose-500",
  "bg-gradient-to-r from-purple-700 to-rose-600",
  "bg-gradient-to-r from-purple-700 to-rose-700",
  "bg-gradient-to-r from-purple-700 to-rose-800",
  "bg-gradient-to-r from-purple-700 to-rose-900",
  "bg-gradient-to-r from-purple-700 to-rose-950",
  "bg-gradient-to-r from-purple-800 to-red-300",
  "bg-gradient-to-r from-purple-800 to-red-400",
  "bg-gradient-to-r from-purple-800 to-red-500",
  "bg-gradient-to-r from-purple-800 to-red-600",
  "bg-gradient-to-r from-purple-800 to-red-700",
  "bg-gradient-to-r from-purple-800 to-red-800",
  "bg-gradient-to-r from-purple-800 to-red-900",
  "bg-gradient-to-r from-purple-800 to-red-950",
  "bg-gradient-to-r from-purple-800 to-orange-300",
  "bg-gradient-to-r from-purple-800 to-orange-400",
  "bg-gradient-to-r from-purple-800 to-orange-500",
  "bg-gradient-to-r from-purple-800 to-orange-600",
  "bg-gradient-to-r from-purple-800 to-orange-700",
  "bg-gradient-to-r from-purple-800 to-orange-800",
  "bg-gradient-to-r from-purple-800 to-orange-900",
  "bg-gradient-to-r from-purple-800 to-orange-950",
  "bg-gradient-to-r from-purple-800 to-amber-300",
  "bg-gradient-to-r from-purple-800 to-amber-400",
  "bg-gradient-to-r from-purple-800 to-amber-500",
  "bg-gradient-to-r from-purple-800 to-amber-600",
  "bg-gradient-to-r from-purple-800 to-amber-700",
  "bg-gradient-to-r from-purple-800 to-amber-800",
  "bg-gradient-to-r from-purple-800 to-amber-900",
  "bg-gradient-to-r from-purple-800 to-amber-950",
  "bg-gradient-to-r from-purple-800 to-yellow-300",
  "bg-gradient-to-r from-purple-800 to-yellow-400",
  "bg-gradient-to-r from-purple-800 to-yellow-500",
  "bg-gradient-to-r from-purple-800 to-yellow-600",
  "bg-gradient-to-r from-purple-800 to-yellow-700",
  "bg-gradient-to-r from-purple-800 to-yellow-800",
  "bg-gradient-to-r from-purple-800 to-yellow-900",
  "bg-gradient-to-r from-purple-800 to-yellow-950",
  "bg-gradient-to-r from-purple-800 to-lime-300",
  "bg-gradient-to-r from-purple-800 to-lime-400",
  "bg-gradient-to-r from-purple-800 to-lime-500",
  "bg-gradient-to-r from-purple-800 to-lime-600",
  "bg-gradient-to-r from-purple-800 to-lime-700",
  "bg-gradient-to-r from-purple-800 to-lime-800",
  "bg-gradient-to-r from-purple-800 to-lime-900",
  "bg-gradient-to-r from-purple-800 to-lime-950",
  "bg-gradient-to-r from-purple-800 to-green-300",
  "bg-gradient-to-r from-purple-800 to-green-400",
  "bg-gradient-to-r from-purple-800 to-green-500",
  "bg-gradient-to-r from-purple-800 to-green-600",
  "bg-gradient-to-r from-purple-800 to-green-700",
  "bg-gradient-to-r from-purple-800 to-green-800",
  "bg-gradient-to-r from-purple-800 to-green-900",
  "bg-gradient-to-r from-purple-800 to-green-950",
  "bg-gradient-to-r from-purple-800 to-emerald-300",
  "bg-gradient-to-r from-purple-800 to-emerald-400",
  "bg-gradient-to-r from-purple-800 to-emerald-500",
  "bg-gradient-to-r from-purple-800 to-emerald-600",
  "bg-gradient-to-r from-purple-800 to-emerald-700",
  "bg-gradient-to-r from-purple-800 to-emerald-800",
  "bg-gradient-to-r from-purple-800 to-emerald-900",
  "bg-gradient-to-r from-purple-800 to-emerald-950",
  "bg-gradient-to-r from-purple-800 to-teal-300",
  "bg-gradient-to-r from-purple-800 to-teal-400",
  "bg-gradient-to-r from-purple-800 to-teal-500",
  "bg-gradient-to-r from-purple-800 to-teal-600",
  "bg-gradient-to-r from-purple-800 to-teal-700",
  "bg-gradient-to-r from-purple-800 to-teal-800",
  "bg-gradient-to-r from-purple-800 to-teal-900",
  "bg-gradient-to-r from-purple-800 to-teal-950",
  "bg-gradient-to-r from-purple-800 to-cyan-300",
  "bg-gradient-to-r from-purple-800 to-cyan-400",
  "bg-gradient-to-r from-purple-800 to-cyan-500",
  "bg-gradient-to-r from-purple-800 to-cyan-600",
  "bg-gradient-to-r from-purple-800 to-cyan-700",
  "bg-gradient-to-r from-purple-800 to-cyan-800",
  "bg-gradient-to-r from-purple-800 to-cyan-900",
  "bg-gradient-to-r from-purple-800 to-cyan-950",
  "bg-gradient-to-r from-purple-800 to-sky-300",
  "bg-gradient-to-r from-purple-800 to-sky-400",
  "bg-gradient-to-r from-purple-800 to-sky-500",
  "bg-gradient-to-r from-purple-800 to-sky-600",
  "bg-gradient-to-r from-purple-800 to-sky-700",
  "bg-gradient-to-r from-purple-800 to-sky-800",
  "bg-gradient-to-r from-purple-800 to-sky-900",
  "bg-gradient-to-r from-purple-800 to-sky-950",
  "bg-gradient-to-r from-purple-800 to-blue-300",
  "bg-gradient-to-r from-purple-800 to-blue-400",
  "bg-gradient-to-r from-purple-800 to-blue-500",
  "bg-gradient-to-r from-purple-800 to-blue-600",
  "bg-gradient-to-r from-purple-800 to-blue-700",
  "bg-gradient-to-r from-purple-800 to-blue-800",
  "bg-gradient-to-r from-purple-800 to-blue-900",
  "bg-gradient-to-r from-purple-800 to-blue-950",
  "bg-gradient-to-r from-purple-800 to-indigo-300",
  "bg-gradient-to-r from-purple-800 to-indigo-400",
  "bg-gradient-to-r from-purple-800 to-indigo-500",
  "bg-gradient-to-r from-purple-800 to-indigo-600",
  "bg-gradient-to-r from-purple-800 to-indigo-700",
  "bg-gradient-to-r from-purple-800 to-indigo-800",
  "bg-gradient-to-r from-purple-800 to-indigo-900",
  "bg-gradient-to-r from-purple-800 to-indigo-950",
  "bg-gradient-to-r from-purple-800 to-violet-300",
  "bg-gradient-to-r from-purple-800 to-violet-400",
  "bg-gradient-to-r from-purple-800 to-violet-500",
  "bg-gradient-to-r from-purple-800 to-violet-600",
  "bg-gradient-to-r from-purple-800 to-violet-700",
  "bg-gradient-to-r from-purple-800 to-violet-800",
  "bg-gradient-to-r from-purple-800 to-violet-900",
  "bg-gradient-to-r from-purple-800 to-violet-950",
  "bg-gradient-to-r from-purple-800 to-fuchsia-300",
  "bg-gradient-to-r from-purple-800 to-fuchsia-400",
  "bg-gradient-to-r from-purple-800 to-fuchsia-500",
  "bg-gradient-to-r from-purple-800 to-fuchsia-600",
  "bg-gradient-to-r from-purple-800 to-fuchsia-700",
  "bg-gradient-to-r from-purple-800 to-fuchsia-800",
  "bg-gradient-to-r from-purple-800 to-fuchsia-900",
  "bg-gradient-to-r from-purple-800 to-fuchsia-950",
  "bg-gradient-to-r from-purple-800 to-pink-300",
  "bg-gradient-to-r from-purple-800 to-pink-400",
  "bg-gradient-to-r from-purple-800 to-pink-500",
  "bg-gradient-to-r from-purple-800 to-pink-600",
  "bg-gradient-to-r from-purple-800 to-pink-700",
  "bg-gradient-to-r from-purple-800 to-pink-800",
  "bg-gradient-to-r from-purple-800 to-pink-900",
  "bg-gradient-to-r from-purple-800 to-pink-950",
  "bg-gradient-to-r from-purple-800 to-rose-300",
  "bg-gradient-to-r from-purple-800 to-rose-400",
  "bg-gradient-to-r from-purple-800 to-rose-500",
  "bg-gradient-to-r from-purple-800 to-rose-600",
  "bg-gradient-to-r from-purple-800 to-rose-700",
  "bg-gradient-to-r from-purple-800 to-rose-800",
  "bg-gradient-to-r from-purple-800 to-rose-900",
  "bg-gradient-to-r from-purple-800 to-rose-950",
  "bg-gradient-to-r from-purple-900 to-red-300",
  "bg-gradient-to-r from-purple-900 to-red-400",
  "bg-gradient-to-r from-purple-900 to-red-500",
  "bg-gradient-to-r from-purple-900 to-red-600",
  "bg-gradient-to-r from-purple-900 to-red-700",
  "bg-gradient-to-r from-purple-900 to-red-800",
  "bg-gradient-to-r from-purple-900 to-red-900",
  "bg-gradient-to-r from-purple-900 to-red-950",
  "bg-gradient-to-r from-purple-900 to-orange-300",
  "bg-gradient-to-r from-purple-900 to-orange-400",
  "bg-gradient-to-r from-purple-900 to-orange-500",
  "bg-gradient-to-r from-purple-900 to-orange-600",
  "bg-gradient-to-r from-purple-900 to-orange-700",
  "bg-gradient-to-r from-purple-900 to-orange-800",
  "bg-gradient-to-r from-purple-900 to-orange-900",
  "bg-gradient-to-r from-purple-900 to-orange-950",
  "bg-gradient-to-r from-purple-900 to-amber-300",
  "bg-gradient-to-r from-purple-900 to-amber-400",
  "bg-gradient-to-r from-purple-900 to-amber-500",
  "bg-gradient-to-r from-purple-900 to-amber-600",
  "bg-gradient-to-r from-purple-900 to-amber-700",
  "bg-gradient-to-r from-purple-900 to-amber-800",
  "bg-gradient-to-r from-purple-900 to-amber-900",
  "bg-gradient-to-r from-purple-900 to-amber-950",
  "bg-gradient-to-r from-purple-900 to-yellow-300",
  "bg-gradient-to-r from-purple-900 to-yellow-400",
  "bg-gradient-to-r from-purple-900 to-yellow-500",
  "bg-gradient-to-r from-purple-900 to-yellow-600",
  "bg-gradient-to-r from-purple-900 to-yellow-700",
  "bg-gradient-to-r from-purple-900 to-yellow-800",
  "bg-gradient-to-r from-purple-900 to-yellow-900",
  "bg-gradient-to-r from-purple-900 to-yellow-950",
  "bg-gradient-to-r from-purple-900 to-lime-300",
  "bg-gradient-to-r from-purple-900 to-lime-400",
  "bg-gradient-to-r from-purple-900 to-lime-500",
  "bg-gradient-to-r from-purple-900 to-lime-600",
  "bg-gradient-to-r from-purple-900 to-lime-700",
  "bg-gradient-to-r from-purple-900 to-lime-800",
  "bg-gradient-to-r from-purple-900 to-lime-900",
  "bg-gradient-to-r from-purple-900 to-lime-950",
  "bg-gradient-to-r from-purple-900 to-green-300",
  "bg-gradient-to-r from-purple-900 to-green-400",
  "bg-gradient-to-r from-purple-900 to-green-500",
  "bg-gradient-to-r from-purple-900 to-green-600",
  "bg-gradient-to-r from-purple-900 to-green-700",
  "bg-gradient-to-r from-purple-900 to-green-800",
  "bg-gradient-to-r from-purple-900 to-green-900",
  "bg-gradient-to-r from-purple-900 to-green-950",
  "bg-gradient-to-r from-purple-900 to-emerald-300",
  "bg-gradient-to-r from-purple-900 to-emerald-400",
  "bg-gradient-to-r from-purple-900 to-emerald-500",
  "bg-gradient-to-r from-purple-900 to-emerald-600",
  "bg-gradient-to-r from-purple-900 to-emerald-700",
  "bg-gradient-to-r from-purple-900 to-emerald-800",
  "bg-gradient-to-r from-purple-900 to-emerald-900",
  "bg-gradient-to-r from-purple-900 to-emerald-950",
  "bg-gradient-to-r from-purple-900 to-teal-300",
  "bg-gradient-to-r from-purple-900 to-teal-400",
  "bg-gradient-to-r from-purple-900 to-teal-500",
  "bg-gradient-to-r from-purple-900 to-teal-600",
  "bg-gradient-to-r from-purple-900 to-teal-700",
  "bg-gradient-to-r from-purple-900 to-teal-800",
  "bg-gradient-to-r from-purple-900 to-teal-900",
  "bg-gradient-to-r from-purple-900 to-teal-950",
  "bg-gradient-to-r from-purple-900 to-cyan-300",
  "bg-gradient-to-r from-purple-900 to-cyan-400",
  "bg-gradient-to-r from-purple-900 to-cyan-500",
  "bg-gradient-to-r from-purple-900 to-cyan-600",
  "bg-gradient-to-r from-purple-900 to-cyan-700",
  "bg-gradient-to-r from-purple-900 to-cyan-800",
  "bg-gradient-to-r from-purple-900 to-cyan-900",
  "bg-gradient-to-r from-purple-900 to-cyan-950",
  "bg-gradient-to-r from-purple-900 to-sky-300",
  "bg-gradient-to-r from-purple-900 to-sky-400",
  "bg-gradient-to-r from-purple-900 to-sky-500",
  "bg-gradient-to-r from-purple-900 to-sky-600",
  "bg-gradient-to-r from-purple-900 to-sky-700",
  "bg-gradient-to-r from-purple-900 to-sky-800",
  "bg-gradient-to-r from-purple-900 to-sky-900",
  "bg-gradient-to-r from-purple-900 to-sky-950",
  "bg-gradient-to-r from-purple-900 to-blue-300",
  "bg-gradient-to-r from-purple-900 to-blue-400",
  "bg-gradient-to-r from-purple-900 to-blue-500",
  "bg-gradient-to-r from-purple-900 to-blue-600",
  "bg-gradient-to-r from-purple-900 to-blue-700",
  "bg-gradient-to-r from-purple-900 to-blue-800",
  "bg-gradient-to-r from-purple-900 to-blue-900",
  "bg-gradient-to-r from-purple-900 to-blue-950",
  "bg-gradient-to-r from-purple-900 to-indigo-300",
  "bg-gradient-to-r from-purple-900 to-indigo-400",
  "bg-gradient-to-r from-purple-900 to-indigo-500",
  "bg-gradient-to-r from-purple-900 to-indigo-600",
  "bg-gradient-to-r from-purple-900 to-indigo-700",
  "bg-gradient-to-r from-purple-900 to-indigo-800",
  "bg-gradient-to-r from-purple-900 to-indigo-900",
  "bg-gradient-to-r from-purple-900 to-indigo-950",
  "bg-gradient-to-r from-purple-900 to-violet-300",
  "bg-gradient-to-r from-purple-900 to-violet-400",
  "bg-gradient-to-r from-purple-900 to-violet-500",
  "bg-gradient-to-r from-purple-900 to-violet-600",
  "bg-gradient-to-r from-purple-900 to-violet-700",
  "bg-gradient-to-r from-purple-900 to-violet-800",
  "bg-gradient-to-r from-purple-900 to-violet-900",
  "bg-gradient-to-r from-purple-900 to-violet-950",
  "bg-gradient-to-r from-purple-900 to-fuchsia-300",
  "bg-gradient-to-r from-purple-900 to-fuchsia-400",
  "bg-gradient-to-r from-purple-900 to-fuchsia-500",
  "bg-gradient-to-r from-purple-900 to-fuchsia-600",
  "bg-gradient-to-r from-purple-900 to-fuchsia-700",
  "bg-gradient-to-r from-purple-900 to-fuchsia-800",
  "bg-gradient-to-r from-purple-900 to-fuchsia-900",
  "bg-gradient-to-r from-purple-900 to-fuchsia-950",
  "bg-gradient-to-r from-purple-900 to-pink-300",
  "bg-gradient-to-r from-purple-900 to-pink-400",
  "bg-gradient-to-r from-purple-900 to-pink-500",
  "bg-gradient-to-r from-purple-900 to-pink-600",
  "bg-gradient-to-r from-purple-900 to-pink-700",
  "bg-gradient-to-r from-purple-900 to-pink-800",
  "bg-gradient-to-r from-purple-900 to-pink-900",
  "bg-gradient-to-r from-purple-900 to-pink-950",
  "bg-gradient-to-r from-purple-900 to-rose-300",
  "bg-gradient-to-r from-purple-900 to-rose-400",
  "bg-gradient-to-r from-purple-900 to-rose-500",
  "bg-gradient-to-r from-purple-900 to-rose-600",
  "bg-gradient-to-r from-purple-900 to-rose-700",
  "bg-gradient-to-r from-purple-900 to-rose-800",
  "bg-gradient-to-r from-purple-900 to-rose-900",
  "bg-gradient-to-r from-purple-900 to-rose-950",
  "bg-gradient-to-r from-purple-950 to-red-300",
  "bg-gradient-to-r from-purple-950 to-red-400",
  "bg-gradient-to-r from-purple-950 to-red-500",
  "bg-gradient-to-r from-purple-950 to-red-600",
  "bg-gradient-to-r from-purple-950 to-red-700",
  "bg-gradient-to-r from-purple-950 to-red-800",
  "bg-gradient-to-r from-purple-950 to-red-900",
  "bg-gradient-to-r from-purple-950 to-red-950",
  "bg-gradient-to-r from-purple-950 to-orange-300",
  "bg-gradient-to-r from-purple-950 to-orange-400",
  "bg-gradient-to-r from-purple-950 to-orange-500",
  "bg-gradient-to-r from-purple-950 to-orange-600",
  "bg-gradient-to-r from-purple-950 to-orange-700",
  "bg-gradient-to-r from-purple-950 to-orange-800",
  "bg-gradient-to-r from-purple-950 to-orange-900",
  "bg-gradient-to-r from-purple-950 to-orange-950",
  "bg-gradient-to-r from-purple-950 to-amber-300",
  "bg-gradient-to-r from-purple-950 to-amber-400",
  "bg-gradient-to-r from-purple-950 to-amber-500",
  "bg-gradient-to-r from-purple-950 to-amber-600",
  "bg-gradient-to-r from-purple-950 to-amber-700",
  "bg-gradient-to-r from-purple-950 to-amber-800",
  "bg-gradient-to-r from-purple-950 to-amber-900",
  "bg-gradient-to-r from-purple-950 to-amber-950",
  "bg-gradient-to-r from-purple-950 to-yellow-300",
  "bg-gradient-to-r from-purple-950 to-yellow-400",
  "bg-gradient-to-r from-purple-950 to-yellow-500",
  "bg-gradient-to-r from-purple-950 to-yellow-600",
  "bg-gradient-to-r from-purple-950 to-yellow-700",
  "bg-gradient-to-r from-purple-950 to-yellow-800",
  "bg-gradient-to-r from-purple-950 to-yellow-900",
  "bg-gradient-to-r from-purple-950 to-yellow-950",
  "bg-gradient-to-r from-purple-950 to-lime-300",
  "bg-gradient-to-r from-purple-950 to-lime-400",
  "bg-gradient-to-r from-purple-950 to-lime-500",
  "bg-gradient-to-r from-purple-950 to-lime-600",
  "bg-gradient-to-r from-purple-950 to-lime-700",
  "bg-gradient-to-r from-purple-950 to-lime-800",
  "bg-gradient-to-r from-purple-950 to-lime-900",
  "bg-gradient-to-r from-purple-950 to-lime-950",
  "bg-gradient-to-r from-purple-950 to-green-300",
  "bg-gradient-to-r from-purple-950 to-green-400",
  "bg-gradient-to-r from-purple-950 to-green-500",
  "bg-gradient-to-r from-purple-950 to-green-600",
  "bg-gradient-to-r from-purple-950 to-green-700",
  "bg-gradient-to-r from-purple-950 to-green-800",
  "bg-gradient-to-r from-purple-950 to-green-900",
  "bg-gradient-to-r from-purple-950 to-green-950",
  "bg-gradient-to-r from-purple-950 to-emerald-300",
  "bg-gradient-to-r from-purple-950 to-emerald-400",
  "bg-gradient-to-r from-purple-950 to-emerald-500",
  "bg-gradient-to-r from-purple-950 to-emerald-600",
  "bg-gradient-to-r from-purple-950 to-emerald-700",
  "bg-gradient-to-r from-purple-950 to-emerald-800",
  "bg-gradient-to-r from-purple-950 to-emerald-900",
  "bg-gradient-to-r from-purple-950 to-emerald-950",
  "bg-gradient-to-r from-purple-950 to-teal-300",
  "bg-gradient-to-r from-purple-950 to-teal-400",
  "bg-gradient-to-r from-purple-950 to-teal-500",
  "bg-gradient-to-r from-purple-950 to-teal-600",
  "bg-gradient-to-r from-purple-950 to-teal-700",
  "bg-gradient-to-r from-purple-950 to-teal-800",
  "bg-gradient-to-r from-purple-950 to-teal-900",
  "bg-gradient-to-r from-purple-950 to-teal-950",
  "bg-gradient-to-r from-purple-950 to-cyan-300",
  "bg-gradient-to-r from-purple-950 to-cyan-400",
  "bg-gradient-to-r from-purple-950 to-cyan-500",
  "bg-gradient-to-r from-purple-950 to-cyan-600",
  "bg-gradient-to-r from-purple-950 to-cyan-700",
  "bg-gradient-to-r from-purple-950 to-cyan-800",
  "bg-gradient-to-r from-purple-950 to-cyan-900",
  "bg-gradient-to-r from-purple-950 to-cyan-950",
  "bg-gradient-to-r from-purple-950 to-sky-300",
  "bg-gradient-to-r from-purple-950 to-sky-400",
  "bg-gradient-to-r from-purple-950 to-sky-500",
  "bg-gradient-to-r from-purple-950 to-sky-600",
  "bg-gradient-to-r from-purple-950 to-sky-700",
  "bg-gradient-to-r from-purple-950 to-sky-800",
  "bg-gradient-to-r from-purple-950 to-sky-900",
  "bg-gradient-to-r from-purple-950 to-sky-950",
  "bg-gradient-to-r from-purple-950 to-blue-300",
  "bg-gradient-to-r from-purple-950 to-blue-400",
  "bg-gradient-to-r from-purple-950 to-blue-500",
  "bg-gradient-to-r from-purple-950 to-blue-600",
  "bg-gradient-to-r from-purple-950 to-blue-700",
  "bg-gradient-to-r from-purple-950 to-blue-800",
  "bg-gradient-to-r from-purple-950 to-blue-900",
  "bg-gradient-to-r from-purple-950 to-blue-950",
  "bg-gradient-to-r from-purple-950 to-indigo-300",
  "bg-gradient-to-r from-purple-950 to-indigo-400",
  "bg-gradient-to-r from-purple-950 to-indigo-500",
  "bg-gradient-to-r from-purple-950 to-indigo-600",
  "bg-gradient-to-r from-purple-950 to-indigo-700",
  "bg-gradient-to-r from-purple-950 to-indigo-800",
  "bg-gradient-to-r from-purple-950 to-indigo-900",
  "bg-gradient-to-r from-purple-950 to-indigo-950",
  "bg-gradient-to-r from-purple-950 to-violet-300",
  "bg-gradient-to-r from-purple-950 to-violet-400",
  "bg-gradient-to-r from-purple-950 to-violet-500",
  "bg-gradient-to-r from-purple-950 to-violet-600",
  "bg-gradient-to-r from-purple-950 to-violet-700",
  "bg-gradient-to-r from-purple-950 to-violet-800",
  "bg-gradient-to-r from-purple-950 to-violet-900",
  "bg-gradient-to-r from-purple-950 to-violet-950",
  "bg-gradient-to-r from-purple-950 to-fuchsia-300",
  "bg-gradient-to-r from-purple-950 to-fuchsia-400",
  "bg-gradient-to-r from-purple-950 to-fuchsia-500",
  "bg-gradient-to-r from-purple-950 to-fuchsia-600",
  "bg-gradient-to-r from-purple-950 to-fuchsia-700",
  "bg-gradient-to-r from-purple-950 to-fuchsia-800",
  "bg-gradient-to-r from-purple-950 to-fuchsia-900",
  "bg-gradient-to-r from-purple-950 to-fuchsia-950",
  "bg-gradient-to-r from-purple-950 to-pink-300",
  "bg-gradient-to-r from-purple-950 to-pink-400",
  "bg-gradient-to-r from-purple-950 to-pink-500",
  "bg-gradient-to-r from-purple-950 to-pink-600",
  "bg-gradient-to-r from-purple-950 to-pink-700",
  "bg-gradient-to-r from-purple-950 to-pink-800",
  "bg-gradient-to-r from-purple-950 to-pink-900",
  "bg-gradient-to-r from-purple-950 to-pink-950",
  "bg-gradient-to-r from-purple-950 to-rose-300",
  "bg-gradient-to-r from-purple-950 to-rose-400",
  "bg-gradient-to-r from-purple-950 to-rose-500",
  "bg-gradient-to-r from-purple-950 to-rose-600",
  "bg-gradient-to-r from-purple-950 to-rose-700",
  "bg-gradient-to-r from-purple-950 to-rose-800",
  "bg-gradient-to-r from-purple-950 to-rose-900",
  "bg-gradient-to-r from-purple-950 to-rose-950",
  "bg-gradient-to-r from-fuchsia-300 to-red-300",
  "bg-gradient-to-r from-fuchsia-300 to-red-400",
  "bg-gradient-to-r from-fuchsia-300 to-red-500",
  "bg-gradient-to-r from-fuchsia-300 to-red-600",
  "bg-gradient-to-r from-fuchsia-300 to-red-700",
  "bg-gradient-to-r from-fuchsia-300 to-red-800",
  "bg-gradient-to-r from-fuchsia-300 to-red-900",
  "bg-gradient-to-r from-fuchsia-300 to-red-950",
  "bg-gradient-to-r from-fuchsia-300 to-orange-300",
  "bg-gradient-to-r from-fuchsia-300 to-orange-400",
  "bg-gradient-to-r from-fuchsia-300 to-orange-500",
  "bg-gradient-to-r from-fuchsia-300 to-orange-600",
  "bg-gradient-to-r from-fuchsia-300 to-orange-700",
  "bg-gradient-to-r from-fuchsia-300 to-orange-800",
  "bg-gradient-to-r from-fuchsia-300 to-orange-900",
  "bg-gradient-to-r from-fuchsia-300 to-orange-950",
  "bg-gradient-to-r from-fuchsia-300 to-amber-300",
  "bg-gradient-to-r from-fuchsia-300 to-amber-400",
  "bg-gradient-to-r from-fuchsia-300 to-amber-500",
  "bg-gradient-to-r from-fuchsia-300 to-amber-600",
  "bg-gradient-to-r from-fuchsia-300 to-amber-700",
  "bg-gradient-to-r from-fuchsia-300 to-amber-800",
  "bg-gradient-to-r from-fuchsia-300 to-amber-900",
  "bg-gradient-to-r from-fuchsia-300 to-amber-950",
  "bg-gradient-to-r from-fuchsia-300 to-yellow-300",
  "bg-gradient-to-r from-fuchsia-300 to-yellow-400",
  "bg-gradient-to-r from-fuchsia-300 to-yellow-500",
  "bg-gradient-to-r from-fuchsia-300 to-yellow-600",
  "bg-gradient-to-r from-fuchsia-300 to-yellow-700",
  "bg-gradient-to-r from-fuchsia-300 to-yellow-800",
  "bg-gradient-to-r from-fuchsia-300 to-yellow-900",
  "bg-gradient-to-r from-fuchsia-300 to-yellow-950",
  "bg-gradient-to-r from-fuchsia-300 to-lime-300",
  "bg-gradient-to-r from-fuchsia-300 to-lime-400",
  "bg-gradient-to-r from-fuchsia-300 to-lime-500",
  "bg-gradient-to-r from-fuchsia-300 to-lime-600",
  "bg-gradient-to-r from-fuchsia-300 to-lime-700",
  "bg-gradient-to-r from-fuchsia-300 to-lime-800",
  "bg-gradient-to-r from-fuchsia-300 to-lime-900",
  "bg-gradient-to-r from-fuchsia-300 to-lime-950",
  "bg-gradient-to-r from-fuchsia-300 to-green-300",
  "bg-gradient-to-r from-fuchsia-300 to-green-400",
  "bg-gradient-to-r from-fuchsia-300 to-green-500",
  "bg-gradient-to-r from-fuchsia-300 to-green-600",
  "bg-gradient-to-r from-fuchsia-300 to-green-700",
  "bg-gradient-to-r from-fuchsia-300 to-green-800",
  "bg-gradient-to-r from-fuchsia-300 to-green-900",
  "bg-gradient-to-r from-fuchsia-300 to-green-950",
  "bg-gradient-to-r from-fuchsia-300 to-emerald-300",
  "bg-gradient-to-r from-fuchsia-300 to-emerald-400",
  "bg-gradient-to-r from-fuchsia-300 to-emerald-500",
  "bg-gradient-to-r from-fuchsia-300 to-emerald-600",
  "bg-gradient-to-r from-fuchsia-300 to-emerald-700",
  "bg-gradient-to-r from-fuchsia-300 to-emerald-800",
  "bg-gradient-to-r from-fuchsia-300 to-emerald-900",
  "bg-gradient-to-r from-fuchsia-300 to-emerald-950",
  "bg-gradient-to-r from-fuchsia-300 to-teal-300",
  "bg-gradient-to-r from-fuchsia-300 to-teal-400",
  "bg-gradient-to-r from-fuchsia-300 to-teal-500",
  "bg-gradient-to-r from-fuchsia-300 to-teal-600",
  "bg-gradient-to-r from-fuchsia-300 to-teal-700",
  "bg-gradient-to-r from-fuchsia-300 to-teal-800",
  "bg-gradient-to-r from-fuchsia-300 to-teal-900",
  "bg-gradient-to-r from-fuchsia-300 to-teal-950",
  "bg-gradient-to-r from-fuchsia-300 to-cyan-300",
  "bg-gradient-to-r from-fuchsia-300 to-cyan-400",
  "bg-gradient-to-r from-fuchsia-300 to-cyan-500",
  "bg-gradient-to-r from-fuchsia-300 to-cyan-600",
  "bg-gradient-to-r from-fuchsia-300 to-cyan-700",
  "bg-gradient-to-r from-fuchsia-300 to-cyan-800",
  "bg-gradient-to-r from-fuchsia-300 to-cyan-900",
  "bg-gradient-to-r from-fuchsia-300 to-cyan-950",
  "bg-gradient-to-r from-fuchsia-300 to-sky-300",
  "bg-gradient-to-r from-fuchsia-300 to-sky-400",
  "bg-gradient-to-r from-fuchsia-300 to-sky-500",
  "bg-gradient-to-r from-fuchsia-300 to-sky-600",
  "bg-gradient-to-r from-fuchsia-300 to-sky-700",
  "bg-gradient-to-r from-fuchsia-300 to-sky-800",
  "bg-gradient-to-r from-fuchsia-300 to-sky-900",
  "bg-gradient-to-r from-fuchsia-300 to-sky-950",
  "bg-gradient-to-r from-fuchsia-300 to-blue-300",
  "bg-gradient-to-r from-fuchsia-300 to-blue-400",
  "bg-gradient-to-r from-fuchsia-300 to-blue-500",
  "bg-gradient-to-r from-fuchsia-300 to-blue-600",
  "bg-gradient-to-r from-fuchsia-300 to-blue-700",
  "bg-gradient-to-r from-fuchsia-300 to-blue-800",
  "bg-gradient-to-r from-fuchsia-300 to-blue-900",
  "bg-gradient-to-r from-fuchsia-300 to-blue-950",
  "bg-gradient-to-r from-fuchsia-300 to-indigo-300",
  "bg-gradient-to-r from-fuchsia-300 to-indigo-400",
  "bg-gradient-to-r from-fuchsia-300 to-indigo-500",
  "bg-gradient-to-r from-fuchsia-300 to-indigo-600",
  "bg-gradient-to-r from-fuchsia-300 to-indigo-700",
  "bg-gradient-to-r from-fuchsia-300 to-indigo-800",
  "bg-gradient-to-r from-fuchsia-300 to-indigo-900",
  "bg-gradient-to-r from-fuchsia-300 to-indigo-950",
  "bg-gradient-to-r from-fuchsia-300 to-violet-300",
  "bg-gradient-to-r from-fuchsia-300 to-violet-400",
  "bg-gradient-to-r from-fuchsia-300 to-violet-500",
  "bg-gradient-to-r from-fuchsia-300 to-violet-600",
  "bg-gradient-to-r from-fuchsia-300 to-violet-700",
  "bg-gradient-to-r from-fuchsia-300 to-violet-800",
  "bg-gradient-to-r from-fuchsia-300 to-violet-900",
  "bg-gradient-to-r from-fuchsia-300 to-violet-950",
  "bg-gradient-to-r from-fuchsia-300 to-purple-300",
  "bg-gradient-to-r from-fuchsia-300 to-purple-400",
  "bg-gradient-to-r from-fuchsia-300 to-purple-500",
  "bg-gradient-to-r from-fuchsia-300 to-purple-600",
  "bg-gradient-to-r from-fuchsia-300 to-purple-700",
  "bg-gradient-to-r from-fuchsia-300 to-purple-800",
  "bg-gradient-to-r from-fuchsia-300 to-purple-900",
  "bg-gradient-to-r from-fuchsia-300 to-purple-950",
  "bg-gradient-to-r from-fuchsia-300 to-pink-300",
  "bg-gradient-to-r from-fuchsia-300 to-pink-400",
  "bg-gradient-to-r from-fuchsia-300 to-pink-500",
  "bg-gradient-to-r from-fuchsia-300 to-pink-600",
  "bg-gradient-to-r from-fuchsia-300 to-pink-700",
  "bg-gradient-to-r from-fuchsia-300 to-pink-800",
  "bg-gradient-to-r from-fuchsia-300 to-pink-900",
  "bg-gradient-to-r from-fuchsia-300 to-pink-950",
  "bg-gradient-to-r from-fuchsia-300 to-rose-300",
  "bg-gradient-to-r from-fuchsia-300 to-rose-400",
  "bg-gradient-to-r from-fuchsia-300 to-rose-500",
  "bg-gradient-to-r from-fuchsia-300 to-rose-600",
  "bg-gradient-to-r from-fuchsia-300 to-rose-700",
  "bg-gradient-to-r from-fuchsia-300 to-rose-800",
  "bg-gradient-to-r from-fuchsia-300 to-rose-900",
  "bg-gradient-to-r from-fuchsia-300 to-rose-950",
  "bg-gradient-to-r from-fuchsia-400 to-red-300",
  "bg-gradient-to-r from-fuchsia-400 to-red-400",
  "bg-gradient-to-r from-fuchsia-400 to-red-500",
  "bg-gradient-to-r from-fuchsia-400 to-red-600",
  "bg-gradient-to-r from-fuchsia-400 to-red-700",
  "bg-gradient-to-r from-fuchsia-400 to-red-800",
  "bg-gradient-to-r from-fuchsia-400 to-red-900",
  "bg-gradient-to-r from-fuchsia-400 to-red-950",
  "bg-gradient-to-r from-fuchsia-400 to-orange-300",
  "bg-gradient-to-r from-fuchsia-400 to-orange-400",
  "bg-gradient-to-r from-fuchsia-400 to-orange-500",
  "bg-gradient-to-r from-fuchsia-400 to-orange-600",
  "bg-gradient-to-r from-fuchsia-400 to-orange-700",
  "bg-gradient-to-r from-fuchsia-400 to-orange-800",
  "bg-gradient-to-r from-fuchsia-400 to-orange-900",
  "bg-gradient-to-r from-fuchsia-400 to-orange-950",
  "bg-gradient-to-r from-fuchsia-400 to-amber-300",
  "bg-gradient-to-r from-fuchsia-400 to-amber-400",
  "bg-gradient-to-r from-fuchsia-400 to-amber-500",
  "bg-gradient-to-r from-fuchsia-400 to-amber-600",
  "bg-gradient-to-r from-fuchsia-400 to-amber-700",
  "bg-gradient-to-r from-fuchsia-400 to-amber-800",
  "bg-gradient-to-r from-fuchsia-400 to-amber-900",
  "bg-gradient-to-r from-fuchsia-400 to-amber-950",
  "bg-gradient-to-r from-fuchsia-400 to-yellow-300",
  "bg-gradient-to-r from-fuchsia-400 to-yellow-400",
  "bg-gradient-to-r from-fuchsia-400 to-yellow-500",
  "bg-gradient-to-r from-fuchsia-400 to-yellow-600",
  "bg-gradient-to-r from-fuchsia-400 to-yellow-700",
  "bg-gradient-to-r from-fuchsia-400 to-yellow-800",
  "bg-gradient-to-r from-fuchsia-400 to-yellow-900",
  "bg-gradient-to-r from-fuchsia-400 to-yellow-950",
  "bg-gradient-to-r from-fuchsia-400 to-lime-300",
  "bg-gradient-to-r from-fuchsia-400 to-lime-400",
  "bg-gradient-to-r from-fuchsia-400 to-lime-500",
  "bg-gradient-to-r from-fuchsia-400 to-lime-600",
  "bg-gradient-to-r from-fuchsia-400 to-lime-700",
  "bg-gradient-to-r from-fuchsia-400 to-lime-800",
  "bg-gradient-to-r from-fuchsia-400 to-lime-900",
  "bg-gradient-to-r from-fuchsia-400 to-lime-950",
  "bg-gradient-to-r from-fuchsia-400 to-green-300",
  "bg-gradient-to-r from-fuchsia-400 to-green-400",
  "bg-gradient-to-r from-fuchsia-400 to-green-500",
  "bg-gradient-to-r from-fuchsia-400 to-green-600",
  "bg-gradient-to-r from-fuchsia-400 to-green-700",
  "bg-gradient-to-r from-fuchsia-400 to-green-800",
  "bg-gradient-to-r from-fuchsia-400 to-green-900",
  "bg-gradient-to-r from-fuchsia-400 to-green-950",
  "bg-gradient-to-r from-fuchsia-400 to-emerald-300",
  "bg-gradient-to-r from-fuchsia-400 to-emerald-400",
  "bg-gradient-to-r from-fuchsia-400 to-emerald-500",
  "bg-gradient-to-r from-fuchsia-400 to-emerald-600",
  "bg-gradient-to-r from-fuchsia-400 to-emerald-700",
  "bg-gradient-to-r from-fuchsia-400 to-emerald-800",
  "bg-gradient-to-r from-fuchsia-400 to-emerald-900",
  "bg-gradient-to-r from-fuchsia-400 to-emerald-950",
  "bg-gradient-to-r from-fuchsia-400 to-teal-300",
  "bg-gradient-to-r from-fuchsia-400 to-teal-400",
  "bg-gradient-to-r from-fuchsia-400 to-teal-500",
  "bg-gradient-to-r from-fuchsia-400 to-teal-600",
  "bg-gradient-to-r from-fuchsia-400 to-teal-700",
  "bg-gradient-to-r from-fuchsia-400 to-teal-800",
  "bg-gradient-to-r from-fuchsia-400 to-teal-900",
  "bg-gradient-to-r from-fuchsia-400 to-teal-950",
  "bg-gradient-to-r from-fuchsia-400 to-cyan-300",
  "bg-gradient-to-r from-fuchsia-400 to-cyan-400",
  "bg-gradient-to-r from-fuchsia-400 to-cyan-500",
  "bg-gradient-to-r from-fuchsia-400 to-cyan-600",
  "bg-gradient-to-r from-fuchsia-400 to-cyan-700",
  "bg-gradient-to-r from-fuchsia-400 to-cyan-800",
  "bg-gradient-to-r from-fuchsia-400 to-cyan-900",
  "bg-gradient-to-r from-fuchsia-400 to-cyan-950",
  "bg-gradient-to-r from-fuchsia-400 to-sky-300",
  "bg-gradient-to-r from-fuchsia-400 to-sky-400",
  "bg-gradient-to-r from-fuchsia-400 to-sky-500",
  "bg-gradient-to-r from-fuchsia-400 to-sky-600",
  "bg-gradient-to-r from-fuchsia-400 to-sky-700",
  "bg-gradient-to-r from-fuchsia-400 to-sky-800",
  "bg-gradient-to-r from-fuchsia-400 to-sky-900",
  "bg-gradient-to-r from-fuchsia-400 to-sky-950",
  "bg-gradient-to-r from-fuchsia-400 to-blue-300",
  "bg-gradient-to-r from-fuchsia-400 to-blue-400",
  "bg-gradient-to-r from-fuchsia-400 to-blue-500",
  "bg-gradient-to-r from-fuchsia-400 to-blue-600",
  "bg-gradient-to-r from-fuchsia-400 to-blue-700",
  "bg-gradient-to-r from-fuchsia-400 to-blue-800",
  "bg-gradient-to-r from-fuchsia-400 to-blue-900",
  "bg-gradient-to-r from-fuchsia-400 to-blue-950",
  "bg-gradient-to-r from-fuchsia-400 to-indigo-300",
  "bg-gradient-to-r from-fuchsia-400 to-indigo-400",
  "bg-gradient-to-r from-fuchsia-400 to-indigo-500",
  "bg-gradient-to-r from-fuchsia-400 to-indigo-600",
  "bg-gradient-to-r from-fuchsia-400 to-indigo-700",
  "bg-gradient-to-r from-fuchsia-400 to-indigo-800",
  "bg-gradient-to-r from-fuchsia-400 to-indigo-900",
  "bg-gradient-to-r from-fuchsia-400 to-indigo-950",
  "bg-gradient-to-r from-fuchsia-400 to-violet-300",
  "bg-gradient-to-r from-fuchsia-400 to-violet-400",
  "bg-gradient-to-r from-fuchsia-400 to-violet-500",
  "bg-gradient-to-r from-fuchsia-400 to-violet-600",
  "bg-gradient-to-r from-fuchsia-400 to-violet-700",
  "bg-gradient-to-r from-fuchsia-400 to-violet-800",
  "bg-gradient-to-r from-fuchsia-400 to-violet-900",
  "bg-gradient-to-r from-fuchsia-400 to-violet-950",
  "bg-gradient-to-r from-fuchsia-400 to-purple-300",
  "bg-gradient-to-r from-fuchsia-400 to-purple-400",
  "bg-gradient-to-r from-fuchsia-400 to-purple-500",
  "bg-gradient-to-r from-fuchsia-400 to-purple-600",
  "bg-gradient-to-r from-fuchsia-400 to-purple-700",
  "bg-gradient-to-r from-fuchsia-400 to-purple-800",
  "bg-gradient-to-r from-fuchsia-400 to-purple-900",
  "bg-gradient-to-r from-fuchsia-400 to-purple-950",
  "bg-gradient-to-r from-fuchsia-400 to-pink-300",
  "bg-gradient-to-r from-fuchsia-400 to-pink-400",
  "bg-gradient-to-r from-fuchsia-400 to-pink-500",
  "bg-gradient-to-r from-fuchsia-400 to-pink-600",
  "bg-gradient-to-r from-fuchsia-400 to-pink-700",
  "bg-gradient-to-r from-fuchsia-400 to-pink-800",
  "bg-gradient-to-r from-fuchsia-400 to-pink-900",
  "bg-gradient-to-r from-fuchsia-400 to-pink-950",
  "bg-gradient-to-r from-fuchsia-400 to-rose-300",
  "bg-gradient-to-r from-fuchsia-400 to-rose-400",
  "bg-gradient-to-r from-fuchsia-400 to-rose-500",
  "bg-gradient-to-r from-fuchsia-400 to-rose-600",
  "bg-gradient-to-r from-fuchsia-400 to-rose-700",
  "bg-gradient-to-r from-fuchsia-400 to-rose-800",
  "bg-gradient-to-r from-fuchsia-400 to-rose-900",
  "bg-gradient-to-r from-fuchsia-400 to-rose-950",
  "bg-gradient-to-r from-fuchsia-500 to-red-300",
  "bg-gradient-to-r from-fuchsia-500 to-red-400",
  "bg-gradient-to-r from-fuchsia-500 to-red-500",
  "bg-gradient-to-r from-fuchsia-500 to-red-600",
  "bg-gradient-to-r from-fuchsia-500 to-red-700",
  "bg-gradient-to-r from-fuchsia-500 to-red-800",
  "bg-gradient-to-r from-fuchsia-500 to-red-900",
  "bg-gradient-to-r from-fuchsia-500 to-red-950",
  "bg-gradient-to-r from-fuchsia-500 to-orange-300",
  "bg-gradient-to-r from-fuchsia-500 to-orange-400",
  "bg-gradient-to-r from-fuchsia-500 to-orange-500",
  "bg-gradient-to-r from-fuchsia-500 to-orange-600",
  "bg-gradient-to-r from-fuchsia-500 to-orange-700",
  "bg-gradient-to-r from-fuchsia-500 to-orange-800",
  "bg-gradient-to-r from-fuchsia-500 to-orange-900",
  "bg-gradient-to-r from-fuchsia-500 to-orange-950",
  "bg-gradient-to-r from-fuchsia-500 to-amber-300",
  "bg-gradient-to-r from-fuchsia-500 to-amber-400",
  "bg-gradient-to-r from-fuchsia-500 to-amber-500",
  "bg-gradient-to-r from-fuchsia-500 to-amber-600",
  "bg-gradient-to-r from-fuchsia-500 to-amber-700",
  "bg-gradient-to-r from-fuchsia-500 to-amber-800",
  "bg-gradient-to-r from-fuchsia-500 to-amber-900",
  "bg-gradient-to-r from-fuchsia-500 to-amber-950",
  "bg-gradient-to-r from-fuchsia-500 to-yellow-300",
  "bg-gradient-to-r from-fuchsia-500 to-yellow-400",
  "bg-gradient-to-r from-fuchsia-500 to-yellow-500",
  "bg-gradient-to-r from-fuchsia-500 to-yellow-600",
  "bg-gradient-to-r from-fuchsia-500 to-yellow-700",
  "bg-gradient-to-r from-fuchsia-500 to-yellow-800",
  "bg-gradient-to-r from-fuchsia-500 to-yellow-900",
  "bg-gradient-to-r from-fuchsia-500 to-yellow-950",
  "bg-gradient-to-r from-fuchsia-500 to-lime-300",
  "bg-gradient-to-r from-fuchsia-500 to-lime-400",
  "bg-gradient-to-r from-fuchsia-500 to-lime-500",
  "bg-gradient-to-r from-fuchsia-500 to-lime-600",
  "bg-gradient-to-r from-fuchsia-500 to-lime-700",
  "bg-gradient-to-r from-fuchsia-500 to-lime-800",
  "bg-gradient-to-r from-fuchsia-500 to-lime-900",
  "bg-gradient-to-r from-fuchsia-500 to-lime-950",
  "bg-gradient-to-r from-fuchsia-500 to-green-300",
  "bg-gradient-to-r from-fuchsia-500 to-green-400",
  "bg-gradient-to-r from-fuchsia-500 to-green-500",
  "bg-gradient-to-r from-fuchsia-500 to-green-600",
  "bg-gradient-to-r from-fuchsia-500 to-green-700",
  "bg-gradient-to-r from-fuchsia-500 to-green-800",
  "bg-gradient-to-r from-fuchsia-500 to-green-900",
  "bg-gradient-to-r from-fuchsia-500 to-green-950",
  "bg-gradient-to-r from-fuchsia-500 to-emerald-300",
  "bg-gradient-to-r from-fuchsia-500 to-emerald-400",
  "bg-gradient-to-r from-fuchsia-500 to-emerald-500",
  "bg-gradient-to-r from-fuchsia-500 to-emerald-600",
  "bg-gradient-to-r from-fuchsia-500 to-emerald-700",
  "bg-gradient-to-r from-fuchsia-500 to-emerald-800",
  "bg-gradient-to-r from-fuchsia-500 to-emerald-900",
  "bg-gradient-to-r from-fuchsia-500 to-emerald-950",
  "bg-gradient-to-r from-fuchsia-500 to-teal-300",
  "bg-gradient-to-r from-fuchsia-500 to-teal-400",
  "bg-gradient-to-r from-fuchsia-500 to-teal-500",
  "bg-gradient-to-r from-fuchsia-500 to-teal-600",
  "bg-gradient-to-r from-fuchsia-500 to-teal-700",
  "bg-gradient-to-r from-fuchsia-500 to-teal-800",
  "bg-gradient-to-r from-fuchsia-500 to-teal-900",
  "bg-gradient-to-r from-fuchsia-500 to-teal-950",
  "bg-gradient-to-r from-fuchsia-500 to-cyan-300",
  "bg-gradient-to-r from-fuchsia-500 to-cyan-400",
  "bg-gradient-to-r from-fuchsia-500 to-cyan-500",
  "bg-gradient-to-r from-fuchsia-500 to-cyan-600",
  "bg-gradient-to-r from-fuchsia-500 to-cyan-700",
  "bg-gradient-to-r from-fuchsia-500 to-cyan-800",
  "bg-gradient-to-r from-fuchsia-500 to-cyan-900",
  "bg-gradient-to-r from-fuchsia-500 to-cyan-950",
  "bg-gradient-to-r from-fuchsia-500 to-sky-300",
  "bg-gradient-to-r from-fuchsia-500 to-sky-400",
  "bg-gradient-to-r from-fuchsia-500 to-sky-500",
  "bg-gradient-to-r from-fuchsia-500 to-sky-600",
  "bg-gradient-to-r from-fuchsia-500 to-sky-700",
  "bg-gradient-to-r from-fuchsia-500 to-sky-800",
  "bg-gradient-to-r from-fuchsia-500 to-sky-900",
  "bg-gradient-to-r from-fuchsia-500 to-sky-950",
  "bg-gradient-to-r from-fuchsia-500 to-blue-300",
  "bg-gradient-to-r from-fuchsia-500 to-blue-400",
  "bg-gradient-to-r from-fuchsia-500 to-blue-500",
  "bg-gradient-to-r from-fuchsia-500 to-blue-600",
  "bg-gradient-to-r from-fuchsia-500 to-blue-700",
  "bg-gradient-to-r from-fuchsia-500 to-blue-800",
  "bg-gradient-to-r from-fuchsia-500 to-blue-900",
  "bg-gradient-to-r from-fuchsia-500 to-blue-950",
  "bg-gradient-to-r from-fuchsia-500 to-indigo-300",
  "bg-gradient-to-r from-fuchsia-500 to-indigo-400",
  "bg-gradient-to-r from-fuchsia-500 to-indigo-500",
  "bg-gradient-to-r from-fuchsia-500 to-indigo-600",
  "bg-gradient-to-r from-fuchsia-500 to-indigo-700",
  "bg-gradient-to-r from-fuchsia-500 to-indigo-800",
  "bg-gradient-to-r from-fuchsia-500 to-indigo-900",
  "bg-gradient-to-r from-fuchsia-500 to-indigo-950",
  "bg-gradient-to-r from-fuchsia-500 to-violet-300",
  "bg-gradient-to-r from-fuchsia-500 to-violet-400",
  "bg-gradient-to-r from-fuchsia-500 to-violet-500",
  "bg-gradient-to-r from-fuchsia-500 to-violet-600",
  "bg-gradient-to-r from-fuchsia-500 to-violet-700",
  "bg-gradient-to-r from-fuchsia-500 to-violet-800",
  "bg-gradient-to-r from-fuchsia-500 to-violet-900",
  "bg-gradient-to-r from-fuchsia-500 to-violet-950",
  "bg-gradient-to-r from-fuchsia-500 to-purple-300",
  "bg-gradient-to-r from-fuchsia-500 to-purple-400",
  "bg-gradient-to-r from-fuchsia-500 to-purple-500",
  "bg-gradient-to-r from-fuchsia-500 to-purple-600",
  "bg-gradient-to-r from-fuchsia-500 to-purple-700",
  "bg-gradient-to-r from-fuchsia-500 to-purple-800",
  "bg-gradient-to-r from-fuchsia-500 to-purple-900",
  "bg-gradient-to-r from-fuchsia-500 to-purple-950",
  "bg-gradient-to-r from-fuchsia-500 to-pink-300",
  "bg-gradient-to-r from-fuchsia-500 to-pink-400",
  "bg-gradient-to-r from-fuchsia-500 to-pink-500",
  "bg-gradient-to-r from-fuchsia-500 to-pink-600",
  "bg-gradient-to-r from-fuchsia-500 to-pink-700",
  "bg-gradient-to-r from-fuchsia-500 to-pink-800",
  "bg-gradient-to-r from-fuchsia-500 to-pink-900",
  "bg-gradient-to-r from-fuchsia-500 to-pink-950",
  "bg-gradient-to-r from-fuchsia-500 to-rose-300",
  "bg-gradient-to-r from-fuchsia-500 to-rose-400",
  "bg-gradient-to-r from-fuchsia-500 to-rose-500",
  "bg-gradient-to-r from-fuchsia-500 to-rose-600",
  "bg-gradient-to-r from-fuchsia-500 to-rose-700",
  "bg-gradient-to-r from-fuchsia-500 to-rose-800",
  "bg-gradient-to-r from-fuchsia-500 to-rose-900",
  "bg-gradient-to-r from-fuchsia-500 to-rose-950",
  "bg-gradient-to-r from-fuchsia-600 to-red-300",
  "bg-gradient-to-r from-fuchsia-600 to-red-400",
  "bg-gradient-to-r from-fuchsia-600 to-red-500",
  "bg-gradient-to-r from-fuchsia-600 to-red-600",
  "bg-gradient-to-r from-fuchsia-600 to-red-700",
  "bg-gradient-to-r from-fuchsia-600 to-red-800",
  "bg-gradient-to-r from-fuchsia-600 to-red-900",
  "bg-gradient-to-r from-fuchsia-600 to-red-950",
  "bg-gradient-to-r from-fuchsia-600 to-orange-300",
  "bg-gradient-to-r from-fuchsia-600 to-orange-400",
  "bg-gradient-to-r from-fuchsia-600 to-orange-500",
  "bg-gradient-to-r from-fuchsia-600 to-orange-600",
  "bg-gradient-to-r from-fuchsia-600 to-orange-700",
  "bg-gradient-to-r from-fuchsia-600 to-orange-800",
  "bg-gradient-to-r from-fuchsia-600 to-orange-900",
  "bg-gradient-to-r from-fuchsia-600 to-orange-950",
  "bg-gradient-to-r from-fuchsia-600 to-amber-300",
  "bg-gradient-to-r from-fuchsia-600 to-amber-400",
  "bg-gradient-to-r from-fuchsia-600 to-amber-500",
  "bg-gradient-to-r from-fuchsia-600 to-amber-600",
  "bg-gradient-to-r from-fuchsia-600 to-amber-700",
  "bg-gradient-to-r from-fuchsia-600 to-amber-800",
  "bg-gradient-to-r from-fuchsia-600 to-amber-900",
  "bg-gradient-to-r from-fuchsia-600 to-amber-950",
  "bg-gradient-to-r from-fuchsia-600 to-yellow-300",
  "bg-gradient-to-r from-fuchsia-600 to-yellow-400",
  "bg-gradient-to-r from-fuchsia-600 to-yellow-500",
  "bg-gradient-to-r from-fuchsia-600 to-yellow-600",
  "bg-gradient-to-r from-fuchsia-600 to-yellow-700",
  "bg-gradient-to-r from-fuchsia-600 to-yellow-800",
  "bg-gradient-to-r from-fuchsia-600 to-yellow-900",
  "bg-gradient-to-r from-fuchsia-600 to-yellow-950",
  "bg-gradient-to-r from-fuchsia-600 to-lime-300",
  "bg-gradient-to-r from-fuchsia-600 to-lime-400",
  "bg-gradient-to-r from-fuchsia-600 to-lime-500",
  "bg-gradient-to-r from-fuchsia-600 to-lime-600",
  "bg-gradient-to-r from-fuchsia-600 to-lime-700",
  "bg-gradient-to-r from-fuchsia-600 to-lime-800",
  "bg-gradient-to-r from-fuchsia-600 to-lime-900",
  "bg-gradient-to-r from-fuchsia-600 to-lime-950",
  "bg-gradient-to-r from-fuchsia-600 to-green-300",
  "bg-gradient-to-r from-fuchsia-600 to-green-400",
  "bg-gradient-to-r from-fuchsia-600 to-green-500",
  "bg-gradient-to-r from-fuchsia-600 to-green-600",
  "bg-gradient-to-r from-fuchsia-600 to-green-700",
  "bg-gradient-to-r from-fuchsia-600 to-green-800",
  "bg-gradient-to-r from-fuchsia-600 to-green-900",
  "bg-gradient-to-r from-fuchsia-600 to-green-950",
  "bg-gradient-to-r from-fuchsia-600 to-emerald-300",
  "bg-gradient-to-r from-fuchsia-600 to-emerald-400",
  "bg-gradient-to-r from-fuchsia-600 to-emerald-500",
  "bg-gradient-to-r from-fuchsia-600 to-emerald-600",
  "bg-gradient-to-r from-fuchsia-600 to-emerald-700",
  "bg-gradient-to-r from-fuchsia-600 to-emerald-800",
  "bg-gradient-to-r from-fuchsia-600 to-emerald-900",
  "bg-gradient-to-r from-fuchsia-600 to-emerald-950",
  "bg-gradient-to-r from-fuchsia-600 to-teal-300",
  "bg-gradient-to-r from-fuchsia-600 to-teal-400",
  "bg-gradient-to-r from-fuchsia-600 to-teal-500",
  "bg-gradient-to-r from-fuchsia-600 to-teal-600",
  "bg-gradient-to-r from-fuchsia-600 to-teal-700",
  "bg-gradient-to-r from-fuchsia-600 to-teal-800",
  "bg-gradient-to-r from-fuchsia-600 to-teal-900",
  "bg-gradient-to-r from-fuchsia-600 to-teal-950",
  "bg-gradient-to-r from-fuchsia-600 to-cyan-300",
  "bg-gradient-to-r from-fuchsia-600 to-cyan-400",
  "bg-gradient-to-r from-fuchsia-600 to-cyan-500",
  "bg-gradient-to-r from-fuchsia-600 to-cyan-600",
  "bg-gradient-to-r from-fuchsia-600 to-cyan-700",
  "bg-gradient-to-r from-fuchsia-600 to-cyan-800",
  "bg-gradient-to-r from-fuchsia-600 to-cyan-900",
  "bg-gradient-to-r from-fuchsia-600 to-cyan-950",
  "bg-gradient-to-r from-fuchsia-600 to-sky-300",
  "bg-gradient-to-r from-fuchsia-600 to-sky-400",
  "bg-gradient-to-r from-fuchsia-600 to-sky-500",
  "bg-gradient-to-r from-fuchsia-600 to-sky-600",
  "bg-gradient-to-r from-fuchsia-600 to-sky-700",
  "bg-gradient-to-r from-fuchsia-600 to-sky-800",
  "bg-gradient-to-r from-fuchsia-600 to-sky-900",
  "bg-gradient-to-r from-fuchsia-600 to-sky-950",
  "bg-gradient-to-r from-fuchsia-600 to-blue-300",
  "bg-gradient-to-r from-fuchsia-600 to-blue-400",
  "bg-gradient-to-r from-fuchsia-600 to-blue-500",
  "bg-gradient-to-r from-fuchsia-600 to-blue-600",
  "bg-gradient-to-r from-fuchsia-600 to-blue-700",
  "bg-gradient-to-r from-fuchsia-600 to-blue-800",
  "bg-gradient-to-r from-fuchsia-600 to-blue-900",
  "bg-gradient-to-r from-fuchsia-600 to-blue-950",
  "bg-gradient-to-r from-fuchsia-600 to-indigo-300",
  "bg-gradient-to-r from-fuchsia-600 to-indigo-400",
  "bg-gradient-to-r from-fuchsia-600 to-indigo-500",
  "bg-gradient-to-r from-fuchsia-600 to-indigo-600",
  "bg-gradient-to-r from-fuchsia-600 to-indigo-700",
  "bg-gradient-to-r from-fuchsia-600 to-indigo-800",
  "bg-gradient-to-r from-fuchsia-600 to-indigo-900",
  "bg-gradient-to-r from-fuchsia-600 to-indigo-950",
  "bg-gradient-to-r from-fuchsia-600 to-violet-300",
  "bg-gradient-to-r from-fuchsia-600 to-violet-400",
  "bg-gradient-to-r from-fuchsia-600 to-violet-500",
  "bg-gradient-to-r from-fuchsia-600 to-violet-600",
  "bg-gradient-to-r from-fuchsia-600 to-violet-700",
  "bg-gradient-to-r from-fuchsia-600 to-violet-800",
  "bg-gradient-to-r from-fuchsia-600 to-violet-900",
  "bg-gradient-to-r from-fuchsia-600 to-violet-950",
  "bg-gradient-to-r from-fuchsia-600 to-purple-300",
  "bg-gradient-to-r from-fuchsia-600 to-purple-400",
  "bg-gradient-to-r from-fuchsia-600 to-purple-500",
  "bg-gradient-to-r from-fuchsia-600 to-purple-600",
  "bg-gradient-to-r from-fuchsia-600 to-purple-700",
  "bg-gradient-to-r from-fuchsia-600 to-purple-800",
  "bg-gradient-to-r from-fuchsia-600 to-purple-900",
  "bg-gradient-to-r from-fuchsia-600 to-purple-950",
  "bg-gradient-to-r from-fuchsia-600 to-pink-300",
  "bg-gradient-to-r from-fuchsia-600 to-pink-400",
  "bg-gradient-to-r from-fuchsia-600 to-pink-500",
  "bg-gradient-to-r from-fuchsia-600 to-pink-600",
  "bg-gradient-to-r from-fuchsia-600 to-pink-700",
  "bg-gradient-to-r from-fuchsia-600 to-pink-800",
  "bg-gradient-to-r from-fuchsia-600 to-pink-900",
  "bg-gradient-to-r from-fuchsia-600 to-pink-950",
  "bg-gradient-to-r from-fuchsia-600 to-rose-300",
  "bg-gradient-to-r from-fuchsia-600 to-rose-400",
  "bg-gradient-to-r from-fuchsia-600 to-rose-500",
  "bg-gradient-to-r from-fuchsia-600 to-rose-600",
  "bg-gradient-to-r from-fuchsia-600 to-rose-700",
  "bg-gradient-to-r from-fuchsia-600 to-rose-800",
  "bg-gradient-to-r from-fuchsia-600 to-rose-900",
  "bg-gradient-to-r from-fuchsia-600 to-rose-950",
  "bg-gradient-to-r from-fuchsia-700 to-red-300",
  "bg-gradient-to-r from-fuchsia-700 to-red-400",
  "bg-gradient-to-r from-fuchsia-700 to-red-500",
  "bg-gradient-to-r from-fuchsia-700 to-red-600",
  "bg-gradient-to-r from-fuchsia-700 to-red-700",
  "bg-gradient-to-r from-fuchsia-700 to-red-800",
  "bg-gradient-to-r from-fuchsia-700 to-red-900",
  "bg-gradient-to-r from-fuchsia-700 to-red-950",
  "bg-gradient-to-r from-fuchsia-700 to-orange-300",
  "bg-gradient-to-r from-fuchsia-700 to-orange-400",
  "bg-gradient-to-r from-fuchsia-700 to-orange-500",
  "bg-gradient-to-r from-fuchsia-700 to-orange-600",
  "bg-gradient-to-r from-fuchsia-700 to-orange-700",
  "bg-gradient-to-r from-fuchsia-700 to-orange-800",
  "bg-gradient-to-r from-fuchsia-700 to-orange-900",
  "bg-gradient-to-r from-fuchsia-700 to-orange-950",
  "bg-gradient-to-r from-fuchsia-700 to-amber-300",
  "bg-gradient-to-r from-fuchsia-700 to-amber-400",
  "bg-gradient-to-r from-fuchsia-700 to-amber-500",
  "bg-gradient-to-r from-fuchsia-700 to-amber-600",
  "bg-gradient-to-r from-fuchsia-700 to-amber-700",
  "bg-gradient-to-r from-fuchsia-700 to-amber-800",
  "bg-gradient-to-r from-fuchsia-700 to-amber-900",
  "bg-gradient-to-r from-fuchsia-700 to-amber-950",
  "bg-gradient-to-r from-fuchsia-700 to-yellow-300",
  "bg-gradient-to-r from-fuchsia-700 to-yellow-400",
  "bg-gradient-to-r from-fuchsia-700 to-yellow-500",
  "bg-gradient-to-r from-fuchsia-700 to-yellow-600",
  "bg-gradient-to-r from-fuchsia-700 to-yellow-700",
  "bg-gradient-to-r from-fuchsia-700 to-yellow-800",
  "bg-gradient-to-r from-fuchsia-700 to-yellow-900",
  "bg-gradient-to-r from-fuchsia-700 to-yellow-950",
  "bg-gradient-to-r from-fuchsia-700 to-lime-300",
  "bg-gradient-to-r from-fuchsia-700 to-lime-400",
  "bg-gradient-to-r from-fuchsia-700 to-lime-500",
  "bg-gradient-to-r from-fuchsia-700 to-lime-600",
  "bg-gradient-to-r from-fuchsia-700 to-lime-700",
  "bg-gradient-to-r from-fuchsia-700 to-lime-800",
  "bg-gradient-to-r from-fuchsia-700 to-lime-900",
  "bg-gradient-to-r from-fuchsia-700 to-lime-950",
  "bg-gradient-to-r from-fuchsia-700 to-green-300",
  "bg-gradient-to-r from-fuchsia-700 to-green-400",
  "bg-gradient-to-r from-fuchsia-700 to-green-500",
  "bg-gradient-to-r from-fuchsia-700 to-green-600",
  "bg-gradient-to-r from-fuchsia-700 to-green-700",
  "bg-gradient-to-r from-fuchsia-700 to-green-800",
  "bg-gradient-to-r from-fuchsia-700 to-green-900",
  "bg-gradient-to-r from-fuchsia-700 to-green-950",
  "bg-gradient-to-r from-fuchsia-700 to-emerald-300",
  "bg-gradient-to-r from-fuchsia-700 to-emerald-400",
  "bg-gradient-to-r from-fuchsia-700 to-emerald-500",
  "bg-gradient-to-r from-fuchsia-700 to-emerald-600",
  "bg-gradient-to-r from-fuchsia-700 to-emerald-700",
  "bg-gradient-to-r from-fuchsia-700 to-emerald-800",
  "bg-gradient-to-r from-fuchsia-700 to-emerald-900",
  "bg-gradient-to-r from-fuchsia-700 to-emerald-950",
  "bg-gradient-to-r from-fuchsia-700 to-teal-300",
  "bg-gradient-to-r from-fuchsia-700 to-teal-400",
  "bg-gradient-to-r from-fuchsia-700 to-teal-500",
  "bg-gradient-to-r from-fuchsia-700 to-teal-600",
  "bg-gradient-to-r from-fuchsia-700 to-teal-700",
  "bg-gradient-to-r from-fuchsia-700 to-teal-800",
  "bg-gradient-to-r from-fuchsia-700 to-teal-900",
  "bg-gradient-to-r from-fuchsia-700 to-teal-950",
  "bg-gradient-to-r from-fuchsia-700 to-cyan-300",
  "bg-gradient-to-r from-fuchsia-700 to-cyan-400",
  "bg-gradient-to-r from-fuchsia-700 to-cyan-500",
  "bg-gradient-to-r from-fuchsia-700 to-cyan-600",
  "bg-gradient-to-r from-fuchsia-700 to-cyan-700",
  "bg-gradient-to-r from-fuchsia-700 to-cyan-800",
  "bg-gradient-to-r from-fuchsia-700 to-cyan-900",
  "bg-gradient-to-r from-fuchsia-700 to-cyan-950",
  "bg-gradient-to-r from-fuchsia-700 to-sky-300",
  "bg-gradient-to-r from-fuchsia-700 to-sky-400",
  "bg-gradient-to-r from-fuchsia-700 to-sky-500",
  "bg-gradient-to-r from-fuchsia-700 to-sky-600",
  "bg-gradient-to-r from-fuchsia-700 to-sky-700",
  "bg-gradient-to-r from-fuchsia-700 to-sky-800",
  "bg-gradient-to-r from-fuchsia-700 to-sky-900",
  "bg-gradient-to-r from-fuchsia-700 to-sky-950",
  "bg-gradient-to-r from-fuchsia-700 to-blue-300",
  "bg-gradient-to-r from-fuchsia-700 to-blue-400",
  "bg-gradient-to-r from-fuchsia-700 to-blue-500",
  "bg-gradient-to-r from-fuchsia-700 to-blue-600",
  "bg-gradient-to-r from-fuchsia-700 to-blue-700",
  "bg-gradient-to-r from-fuchsia-700 to-blue-800",
  "bg-gradient-to-r from-fuchsia-700 to-blue-900",
  "bg-gradient-to-r from-fuchsia-700 to-blue-950",
  "bg-gradient-to-r from-fuchsia-700 to-indigo-300",
  "bg-gradient-to-r from-fuchsia-700 to-indigo-400",
  "bg-gradient-to-r from-fuchsia-700 to-indigo-500",
  "bg-gradient-to-r from-fuchsia-700 to-indigo-600",
  "bg-gradient-to-r from-fuchsia-700 to-indigo-700",
  "bg-gradient-to-r from-fuchsia-700 to-indigo-800",
  "bg-gradient-to-r from-fuchsia-700 to-indigo-900",
  "bg-gradient-to-r from-fuchsia-700 to-indigo-950",
  "bg-gradient-to-r from-fuchsia-700 to-violet-300",
  "bg-gradient-to-r from-fuchsia-700 to-violet-400",
  "bg-gradient-to-r from-fuchsia-700 to-violet-500",
  "bg-gradient-to-r from-fuchsia-700 to-violet-600",
  "bg-gradient-to-r from-fuchsia-700 to-violet-700",
  "bg-gradient-to-r from-fuchsia-700 to-violet-800",
  "bg-gradient-to-r from-fuchsia-700 to-violet-900",
  "bg-gradient-to-r from-fuchsia-700 to-violet-950",
  "bg-gradient-to-r from-fuchsia-700 to-purple-300",
  "bg-gradient-to-r from-fuchsia-700 to-purple-400",
  "bg-gradient-to-r from-fuchsia-700 to-purple-500",
  "bg-gradient-to-r from-fuchsia-700 to-purple-600",
  "bg-gradient-to-r from-fuchsia-700 to-purple-700",
  "bg-gradient-to-r from-fuchsia-700 to-purple-800",
  "bg-gradient-to-r from-fuchsia-700 to-purple-900",
  "bg-gradient-to-r from-fuchsia-700 to-purple-950",
  "bg-gradient-to-r from-fuchsia-700 to-pink-300",
  "bg-gradient-to-r from-fuchsia-700 to-pink-400",
  "bg-gradient-to-r from-fuchsia-700 to-pink-500",
  "bg-gradient-to-r from-fuchsia-700 to-pink-600",
  "bg-gradient-to-r from-fuchsia-700 to-pink-700",
  "bg-gradient-to-r from-fuchsia-700 to-pink-800",
  "bg-gradient-to-r from-fuchsia-700 to-pink-900",
  "bg-gradient-to-r from-fuchsia-700 to-pink-950",
  "bg-gradient-to-r from-fuchsia-700 to-rose-300",
  "bg-gradient-to-r from-fuchsia-700 to-rose-400",
  "bg-gradient-to-r from-fuchsia-700 to-rose-500",
  "bg-gradient-to-r from-fuchsia-700 to-rose-600",
  "bg-gradient-to-r from-fuchsia-700 to-rose-700",
  "bg-gradient-to-r from-fuchsia-700 to-rose-800",
  "bg-gradient-to-r from-fuchsia-700 to-rose-900",
  "bg-gradient-to-r from-fuchsia-700 to-rose-950",
  "bg-gradient-to-r from-fuchsia-800 to-red-300",
  "bg-gradient-to-r from-fuchsia-800 to-red-400",
  "bg-gradient-to-r from-fuchsia-800 to-red-500",
  "bg-gradient-to-r from-fuchsia-800 to-red-600",
  "bg-gradient-to-r from-fuchsia-800 to-red-700",
  "bg-gradient-to-r from-fuchsia-800 to-red-800",
  "bg-gradient-to-r from-fuchsia-800 to-red-900",
  "bg-gradient-to-r from-fuchsia-800 to-red-950",
  "bg-gradient-to-r from-fuchsia-800 to-orange-300",
  "bg-gradient-to-r from-fuchsia-800 to-orange-400",
  "bg-gradient-to-r from-fuchsia-800 to-orange-500",
  "bg-gradient-to-r from-fuchsia-800 to-orange-600",
  "bg-gradient-to-r from-fuchsia-800 to-orange-700",
  "bg-gradient-to-r from-fuchsia-800 to-orange-800",
  "bg-gradient-to-r from-fuchsia-800 to-orange-900",
  "bg-gradient-to-r from-fuchsia-800 to-orange-950",
  "bg-gradient-to-r from-fuchsia-800 to-amber-300",
  "bg-gradient-to-r from-fuchsia-800 to-amber-400",
  "bg-gradient-to-r from-fuchsia-800 to-amber-500",
  "bg-gradient-to-r from-fuchsia-800 to-amber-600",
  "bg-gradient-to-r from-fuchsia-800 to-amber-700",
  "bg-gradient-to-r from-fuchsia-800 to-amber-800",
  "bg-gradient-to-r from-fuchsia-800 to-amber-900",
  "bg-gradient-to-r from-fuchsia-800 to-amber-950",
  "bg-gradient-to-r from-fuchsia-800 to-yellow-300",
  "bg-gradient-to-r from-fuchsia-800 to-yellow-400",
  "bg-gradient-to-r from-fuchsia-800 to-yellow-500",
  "bg-gradient-to-r from-fuchsia-800 to-yellow-600",
  "bg-gradient-to-r from-fuchsia-800 to-yellow-700",
  "bg-gradient-to-r from-fuchsia-800 to-yellow-800",
  "bg-gradient-to-r from-fuchsia-800 to-yellow-900",
  "bg-gradient-to-r from-fuchsia-800 to-yellow-950",
  "bg-gradient-to-r from-fuchsia-800 to-lime-300",
  "bg-gradient-to-r from-fuchsia-800 to-lime-400",
  "bg-gradient-to-r from-fuchsia-800 to-lime-500",
  "bg-gradient-to-r from-fuchsia-800 to-lime-600",
  "bg-gradient-to-r from-fuchsia-800 to-lime-700",
  "bg-gradient-to-r from-fuchsia-800 to-lime-800",
  "bg-gradient-to-r from-fuchsia-800 to-lime-900",
  "bg-gradient-to-r from-fuchsia-800 to-lime-950",
  "bg-gradient-to-r from-fuchsia-800 to-green-300",
  "bg-gradient-to-r from-fuchsia-800 to-green-400",
  "bg-gradient-to-r from-fuchsia-800 to-green-500",
  "bg-gradient-to-r from-fuchsia-800 to-green-600",
  "bg-gradient-to-r from-fuchsia-800 to-green-700",
  "bg-gradient-to-r from-fuchsia-800 to-green-800",
  "bg-gradient-to-r from-fuchsia-800 to-green-900",
  "bg-gradient-to-r from-fuchsia-800 to-green-950",
  "bg-gradient-to-r from-fuchsia-800 to-emerald-300",
  "bg-gradient-to-r from-fuchsia-800 to-emerald-400",
  "bg-gradient-to-r from-fuchsia-800 to-emerald-500",
  "bg-gradient-to-r from-fuchsia-800 to-emerald-600",
  "bg-gradient-to-r from-fuchsia-800 to-emerald-700",
  "bg-gradient-to-r from-fuchsia-800 to-emerald-800",
  "bg-gradient-to-r from-fuchsia-800 to-emerald-900",
  "bg-gradient-to-r from-fuchsia-800 to-emerald-950",
  "bg-gradient-to-r from-fuchsia-800 to-teal-300",
  "bg-gradient-to-r from-fuchsia-800 to-teal-400",
  "bg-gradient-to-r from-fuchsia-800 to-teal-500",
  "bg-gradient-to-r from-fuchsia-800 to-teal-600",
  "bg-gradient-to-r from-fuchsia-800 to-teal-700",
  "bg-gradient-to-r from-fuchsia-800 to-teal-800",
  "bg-gradient-to-r from-fuchsia-800 to-teal-900",
  "bg-gradient-to-r from-fuchsia-800 to-teal-950",
  "bg-gradient-to-r from-fuchsia-800 to-cyan-300",
  "bg-gradient-to-r from-fuchsia-800 to-cyan-400",
  "bg-gradient-to-r from-fuchsia-800 to-cyan-500",
  "bg-gradient-to-r from-fuchsia-800 to-cyan-600",
  "bg-gradient-to-r from-fuchsia-800 to-cyan-700",
  "bg-gradient-to-r from-fuchsia-800 to-cyan-800",
  "bg-gradient-to-r from-fuchsia-800 to-cyan-900",
  "bg-gradient-to-r from-fuchsia-800 to-cyan-950",
  "bg-gradient-to-r from-fuchsia-800 to-sky-300",
  "bg-gradient-to-r from-fuchsia-800 to-sky-400",
  "bg-gradient-to-r from-fuchsia-800 to-sky-500",
  "bg-gradient-to-r from-fuchsia-800 to-sky-600",
  "bg-gradient-to-r from-fuchsia-800 to-sky-700",
  "bg-gradient-to-r from-fuchsia-800 to-sky-800",
  "bg-gradient-to-r from-fuchsia-800 to-sky-900",
  "bg-gradient-to-r from-fuchsia-800 to-sky-950",
  "bg-gradient-to-r from-fuchsia-800 to-blue-300",
  "bg-gradient-to-r from-fuchsia-800 to-blue-400",
  "bg-gradient-to-r from-fuchsia-800 to-blue-500",
  "bg-gradient-to-r from-fuchsia-800 to-blue-600",
  "bg-gradient-to-r from-fuchsia-800 to-blue-700",
  "bg-gradient-to-r from-fuchsia-800 to-blue-800",
  "bg-gradient-to-r from-fuchsia-800 to-blue-900",
  "bg-gradient-to-r from-fuchsia-800 to-blue-950",
  "bg-gradient-to-r from-fuchsia-800 to-indigo-300",
  "bg-gradient-to-r from-fuchsia-800 to-indigo-400",
  "bg-gradient-to-r from-fuchsia-800 to-indigo-500",
  "bg-gradient-to-r from-fuchsia-800 to-indigo-600",
  "bg-gradient-to-r from-fuchsia-800 to-indigo-700",
  "bg-gradient-to-r from-fuchsia-800 to-indigo-800",
  "bg-gradient-to-r from-fuchsia-800 to-indigo-900",
  "bg-gradient-to-r from-fuchsia-800 to-indigo-950",
  "bg-gradient-to-r from-fuchsia-800 to-violet-300",
  "bg-gradient-to-r from-fuchsia-800 to-violet-400",
  "bg-gradient-to-r from-fuchsia-800 to-violet-500",
  "bg-gradient-to-r from-fuchsia-800 to-violet-600",
  "bg-gradient-to-r from-fuchsia-800 to-violet-700",
  "bg-gradient-to-r from-fuchsia-800 to-violet-800",
  "bg-gradient-to-r from-fuchsia-800 to-violet-900",
  "bg-gradient-to-r from-fuchsia-800 to-violet-950",
  "bg-gradient-to-r from-fuchsia-800 to-purple-300",
  "bg-gradient-to-r from-fuchsia-800 to-purple-400",
  "bg-gradient-to-r from-fuchsia-800 to-purple-500",
  "bg-gradient-to-r from-fuchsia-800 to-purple-600",
  "bg-gradient-to-r from-fuchsia-800 to-purple-700",
  "bg-gradient-to-r from-fuchsia-800 to-purple-800",
  "bg-gradient-to-r from-fuchsia-800 to-purple-900",
  "bg-gradient-to-r from-fuchsia-800 to-purple-950",
  "bg-gradient-to-r from-fuchsia-800 to-pink-300",
  "bg-gradient-to-r from-fuchsia-800 to-pink-400",
  "bg-gradient-to-r from-fuchsia-800 to-pink-500",
  "bg-gradient-to-r from-fuchsia-800 to-pink-600",
  "bg-gradient-to-r from-fuchsia-800 to-pink-700",
  "bg-gradient-to-r from-fuchsia-800 to-pink-800",
  "bg-gradient-to-r from-fuchsia-800 to-pink-900",
  "bg-gradient-to-r from-fuchsia-800 to-pink-950",
  "bg-gradient-to-r from-fuchsia-800 to-rose-300",
  "bg-gradient-to-r from-fuchsia-800 to-rose-400",
  "bg-gradient-to-r from-fuchsia-800 to-rose-500",
  "bg-gradient-to-r from-fuchsia-800 to-rose-600",
  "bg-gradient-to-r from-fuchsia-800 to-rose-700",
  "bg-gradient-to-r from-fuchsia-800 to-rose-800",
  "bg-gradient-to-r from-fuchsia-800 to-rose-900",
  "bg-gradient-to-r from-fuchsia-800 to-rose-950",
  "bg-gradient-to-r from-fuchsia-900 to-red-300",
  "bg-gradient-to-r from-fuchsia-900 to-red-400",
  "bg-gradient-to-r from-fuchsia-900 to-red-500",
  "bg-gradient-to-r from-fuchsia-900 to-red-600",
  "bg-gradient-to-r from-fuchsia-900 to-red-700",
  "bg-gradient-to-r from-fuchsia-900 to-red-800",
  "bg-gradient-to-r from-fuchsia-900 to-red-900",
  "bg-gradient-to-r from-fuchsia-900 to-red-950",
  "bg-gradient-to-r from-fuchsia-900 to-orange-300",
  "bg-gradient-to-r from-fuchsia-900 to-orange-400",
  "bg-gradient-to-r from-fuchsia-900 to-orange-500",
  "bg-gradient-to-r from-fuchsia-900 to-orange-600",
  "bg-gradient-to-r from-fuchsia-900 to-orange-700",
  "bg-gradient-to-r from-fuchsia-900 to-orange-800",
  "bg-gradient-to-r from-fuchsia-900 to-orange-900",
  "bg-gradient-to-r from-fuchsia-900 to-orange-950",
  "bg-gradient-to-r from-fuchsia-900 to-amber-300",
  "bg-gradient-to-r from-fuchsia-900 to-amber-400",
  "bg-gradient-to-r from-fuchsia-900 to-amber-500",
  "bg-gradient-to-r from-fuchsia-900 to-amber-600",
  "bg-gradient-to-r from-fuchsia-900 to-amber-700",
  "bg-gradient-to-r from-fuchsia-900 to-amber-800",
  "bg-gradient-to-r from-fuchsia-900 to-amber-900",
  "bg-gradient-to-r from-fuchsia-900 to-amber-950",
  "bg-gradient-to-r from-fuchsia-900 to-yellow-300",
  "bg-gradient-to-r from-fuchsia-900 to-yellow-400",
  "bg-gradient-to-r from-fuchsia-900 to-yellow-500",
  "bg-gradient-to-r from-fuchsia-900 to-yellow-600",
  "bg-gradient-to-r from-fuchsia-900 to-yellow-700",
  "bg-gradient-to-r from-fuchsia-900 to-yellow-800",
  "bg-gradient-to-r from-fuchsia-900 to-yellow-900",
  "bg-gradient-to-r from-fuchsia-900 to-yellow-950",
  "bg-gradient-to-r from-fuchsia-900 to-lime-300",
  "bg-gradient-to-r from-fuchsia-900 to-lime-400",
  "bg-gradient-to-r from-fuchsia-900 to-lime-500",
  "bg-gradient-to-r from-fuchsia-900 to-lime-600",
  "bg-gradient-to-r from-fuchsia-900 to-lime-700",
  "bg-gradient-to-r from-fuchsia-900 to-lime-800",
  "bg-gradient-to-r from-fuchsia-900 to-lime-900",
  "bg-gradient-to-r from-fuchsia-900 to-lime-950",
  "bg-gradient-to-r from-fuchsia-900 to-green-300",
  "bg-gradient-to-r from-fuchsia-900 to-green-400",
  "bg-gradient-to-r from-fuchsia-900 to-green-500",
  "bg-gradient-to-r from-fuchsia-900 to-green-600",
  "bg-gradient-to-r from-fuchsia-900 to-green-700",
  "bg-gradient-to-r from-fuchsia-900 to-green-800",
  "bg-gradient-to-r from-fuchsia-900 to-green-900",
  "bg-gradient-to-r from-fuchsia-900 to-green-950",
  "bg-gradient-to-r from-fuchsia-900 to-emerald-300",
  "bg-gradient-to-r from-fuchsia-900 to-emerald-400",
  "bg-gradient-to-r from-fuchsia-900 to-emerald-500",
  "bg-gradient-to-r from-fuchsia-900 to-emerald-600",
  "bg-gradient-to-r from-fuchsia-900 to-emerald-700",
  "bg-gradient-to-r from-fuchsia-900 to-emerald-800",
  "bg-gradient-to-r from-fuchsia-900 to-emerald-900",
  "bg-gradient-to-r from-fuchsia-900 to-emerald-950",
  "bg-gradient-to-r from-fuchsia-900 to-teal-300",
  "bg-gradient-to-r from-fuchsia-900 to-teal-400",
  "bg-gradient-to-r from-fuchsia-900 to-teal-500",
  "bg-gradient-to-r from-fuchsia-900 to-teal-600",
  "bg-gradient-to-r from-fuchsia-900 to-teal-700",
  "bg-gradient-to-r from-fuchsia-900 to-teal-800",
  "bg-gradient-to-r from-fuchsia-900 to-teal-900",
  "bg-gradient-to-r from-fuchsia-900 to-teal-950",
  "bg-gradient-to-r from-fuchsia-900 to-cyan-300",
  "bg-gradient-to-r from-fuchsia-900 to-cyan-400",
  "bg-gradient-to-r from-fuchsia-900 to-cyan-500",
  "bg-gradient-to-r from-fuchsia-900 to-cyan-600",
  "bg-gradient-to-r from-fuchsia-900 to-cyan-700",
  "bg-gradient-to-r from-fuchsia-900 to-cyan-800",
  "bg-gradient-to-r from-fuchsia-900 to-cyan-900",
  "bg-gradient-to-r from-fuchsia-900 to-cyan-950",
  "bg-gradient-to-r from-fuchsia-900 to-sky-300",
  "bg-gradient-to-r from-fuchsia-900 to-sky-400",
  "bg-gradient-to-r from-fuchsia-900 to-sky-500",
  "bg-gradient-to-r from-fuchsia-900 to-sky-600",
  "bg-gradient-to-r from-fuchsia-900 to-sky-700",
  "bg-gradient-to-r from-fuchsia-900 to-sky-800",
  "bg-gradient-to-r from-fuchsia-900 to-sky-900",
  "bg-gradient-to-r from-fuchsia-900 to-sky-950",
  "bg-gradient-to-r from-fuchsia-900 to-blue-300",
  "bg-gradient-to-r from-fuchsia-900 to-blue-400",
  "bg-gradient-to-r from-fuchsia-900 to-blue-500",
  "bg-gradient-to-r from-fuchsia-900 to-blue-600",
  "bg-gradient-to-r from-fuchsia-900 to-blue-700",
  "bg-gradient-to-r from-fuchsia-900 to-blue-800",
  "bg-gradient-to-r from-fuchsia-900 to-blue-900",
  "bg-gradient-to-r from-fuchsia-900 to-blue-950",
  "bg-gradient-to-r from-fuchsia-900 to-indigo-300",
  "bg-gradient-to-r from-fuchsia-900 to-indigo-400",
  "bg-gradient-to-r from-fuchsia-900 to-indigo-500",
  "bg-gradient-to-r from-fuchsia-900 to-indigo-600",
  "bg-gradient-to-r from-fuchsia-900 to-indigo-700",
  "bg-gradient-to-r from-fuchsia-900 to-indigo-800",
  "bg-gradient-to-r from-fuchsia-900 to-indigo-900",
  "bg-gradient-to-r from-fuchsia-900 to-indigo-950",
  "bg-gradient-to-r from-fuchsia-900 to-violet-300",
  "bg-gradient-to-r from-fuchsia-900 to-violet-400",
  "bg-gradient-to-r from-fuchsia-900 to-violet-500",
  "bg-gradient-to-r from-fuchsia-900 to-violet-600",
  "bg-gradient-to-r from-fuchsia-900 to-violet-700",
  "bg-gradient-to-r from-fuchsia-900 to-violet-800",
  "bg-gradient-to-r from-fuchsia-900 to-violet-900",
  "bg-gradient-to-r from-fuchsia-900 to-violet-950",
  "bg-gradient-to-r from-fuchsia-900 to-purple-300",
  "bg-gradient-to-r from-fuchsia-900 to-purple-400",
  "bg-gradient-to-r from-fuchsia-900 to-purple-500",
  "bg-gradient-to-r from-fuchsia-900 to-purple-600",
  "bg-gradient-to-r from-fuchsia-900 to-purple-700",
  "bg-gradient-to-r from-fuchsia-900 to-purple-800",
  "bg-gradient-to-r from-fuchsia-900 to-purple-900",
  "bg-gradient-to-r from-fuchsia-900 to-purple-950",
  "bg-gradient-to-r from-fuchsia-900 to-pink-300",
  "bg-gradient-to-r from-fuchsia-900 to-pink-400",
  "bg-gradient-to-r from-fuchsia-900 to-pink-500",
  "bg-gradient-to-r from-fuchsia-900 to-pink-600",
  "bg-gradient-to-r from-fuchsia-900 to-pink-700",
  "bg-gradient-to-r from-fuchsia-900 to-pink-800",
  "bg-gradient-to-r from-fuchsia-900 to-pink-900",
  "bg-gradient-to-r from-fuchsia-900 to-pink-950",
  "bg-gradient-to-r from-fuchsia-900 to-rose-300",
  "bg-gradient-to-r from-fuchsia-900 to-rose-400",
  "bg-gradient-to-r from-fuchsia-900 to-rose-500",
  "bg-gradient-to-r from-fuchsia-900 to-rose-600",
  "bg-gradient-to-r from-fuchsia-900 to-rose-700",
  "bg-gradient-to-r from-fuchsia-900 to-rose-800",
  "bg-gradient-to-r from-fuchsia-900 to-rose-900",
  "bg-gradient-to-r from-fuchsia-900 to-rose-950",
  "bg-gradient-to-r from-fuchsia-950 to-red-300",
  "bg-gradient-to-r from-fuchsia-950 to-red-400",
  "bg-gradient-to-r from-fuchsia-950 to-red-500",
  "bg-gradient-to-r from-fuchsia-950 to-red-600",
  "bg-gradient-to-r from-fuchsia-950 to-red-700",
  "bg-gradient-to-r from-fuchsia-950 to-red-800",
  "bg-gradient-to-r from-fuchsia-950 to-red-900",
  "bg-gradient-to-r from-fuchsia-950 to-red-950",
  "bg-gradient-to-r from-fuchsia-950 to-orange-300",
  "bg-gradient-to-r from-fuchsia-950 to-orange-400",
  "bg-gradient-to-r from-fuchsia-950 to-orange-500",
  "bg-gradient-to-r from-fuchsia-950 to-orange-600",
  "bg-gradient-to-r from-fuchsia-950 to-orange-700",
  "bg-gradient-to-r from-fuchsia-950 to-orange-800",
  "bg-gradient-to-r from-fuchsia-950 to-orange-900",
  "bg-gradient-to-r from-fuchsia-950 to-orange-950",
  "bg-gradient-to-r from-fuchsia-950 to-amber-300",
  "bg-gradient-to-r from-fuchsia-950 to-amber-400",
  "bg-gradient-to-r from-fuchsia-950 to-amber-500",
  "bg-gradient-to-r from-fuchsia-950 to-amber-600",
  "bg-gradient-to-r from-fuchsia-950 to-amber-700",
  "bg-gradient-to-r from-fuchsia-950 to-amber-800",
  "bg-gradient-to-r from-fuchsia-950 to-amber-900",
  "bg-gradient-to-r from-fuchsia-950 to-amber-950",
  "bg-gradient-to-r from-fuchsia-950 to-yellow-300",
  "bg-gradient-to-r from-fuchsia-950 to-yellow-400",
  "bg-gradient-to-r from-fuchsia-950 to-yellow-500",
  "bg-gradient-to-r from-fuchsia-950 to-yellow-600",
  "bg-gradient-to-r from-fuchsia-950 to-yellow-700",
  "bg-gradient-to-r from-fuchsia-950 to-yellow-800",
  "bg-gradient-to-r from-fuchsia-950 to-yellow-900",
  "bg-gradient-to-r from-fuchsia-950 to-yellow-950",
  "bg-gradient-to-r from-fuchsia-950 to-lime-300",
  "bg-gradient-to-r from-fuchsia-950 to-lime-400",
  "bg-gradient-to-r from-fuchsia-950 to-lime-500",
  "bg-gradient-to-r from-fuchsia-950 to-lime-600",
  "bg-gradient-to-r from-fuchsia-950 to-lime-700",
  "bg-gradient-to-r from-fuchsia-950 to-lime-800",
  "bg-gradient-to-r from-fuchsia-950 to-lime-900",
  "bg-gradient-to-r from-fuchsia-950 to-lime-950",
  "bg-gradient-to-r from-fuchsia-950 to-green-300",
  "bg-gradient-to-r from-fuchsia-950 to-green-400",
  "bg-gradient-to-r from-fuchsia-950 to-green-500",
  "bg-gradient-to-r from-fuchsia-950 to-green-600",
  "bg-gradient-to-r from-fuchsia-950 to-green-700",
  "bg-gradient-to-r from-fuchsia-950 to-green-800",
  "bg-gradient-to-r from-fuchsia-950 to-green-900",
  "bg-gradient-to-r from-fuchsia-950 to-green-950",
  "bg-gradient-to-r from-fuchsia-950 to-emerald-300",
  "bg-gradient-to-r from-fuchsia-950 to-emerald-400",
  "bg-gradient-to-r from-fuchsia-950 to-emerald-500",
  "bg-gradient-to-r from-fuchsia-950 to-emerald-600",
  "bg-gradient-to-r from-fuchsia-950 to-emerald-700",
  "bg-gradient-to-r from-fuchsia-950 to-emerald-800",
  "bg-gradient-to-r from-fuchsia-950 to-emerald-900",
  "bg-gradient-to-r from-fuchsia-950 to-emerald-950",
  "bg-gradient-to-r from-fuchsia-950 to-teal-300",
  "bg-gradient-to-r from-fuchsia-950 to-teal-400",
  "bg-gradient-to-r from-fuchsia-950 to-teal-500",
  "bg-gradient-to-r from-fuchsia-950 to-teal-600",
  "bg-gradient-to-r from-fuchsia-950 to-teal-700",
  "bg-gradient-to-r from-fuchsia-950 to-teal-800",
  "bg-gradient-to-r from-fuchsia-950 to-teal-900",
  "bg-gradient-to-r from-fuchsia-950 to-teal-950",
  "bg-gradient-to-r from-fuchsia-950 to-cyan-300",
  "bg-gradient-to-r from-fuchsia-950 to-cyan-400",
  "bg-gradient-to-r from-fuchsia-950 to-cyan-500",
  "bg-gradient-to-r from-fuchsia-950 to-cyan-600",
  "bg-gradient-to-r from-fuchsia-950 to-cyan-700",
  "bg-gradient-to-r from-fuchsia-950 to-cyan-800",
  "bg-gradient-to-r from-fuchsia-950 to-cyan-900",
  "bg-gradient-to-r from-fuchsia-950 to-cyan-950",
  "bg-gradient-to-r from-fuchsia-950 to-sky-300",
  "bg-gradient-to-r from-fuchsia-950 to-sky-400",
  "bg-gradient-to-r from-fuchsia-950 to-sky-500",
  "bg-gradient-to-r from-fuchsia-950 to-sky-600",
  "bg-gradient-to-r from-fuchsia-950 to-sky-700",
  "bg-gradient-to-r from-fuchsia-950 to-sky-800",
  "bg-gradient-to-r from-fuchsia-950 to-sky-900",
  "bg-gradient-to-r from-fuchsia-950 to-sky-950",
  "bg-gradient-to-r from-fuchsia-950 to-blue-300",
  "bg-gradient-to-r from-fuchsia-950 to-blue-400",
  "bg-gradient-to-r from-fuchsia-950 to-blue-500",
  "bg-gradient-to-r from-fuchsia-950 to-blue-600",
  "bg-gradient-to-r from-fuchsia-950 to-blue-700",
  "bg-gradient-to-r from-fuchsia-950 to-blue-800",
  "bg-gradient-to-r from-fuchsia-950 to-blue-900",
  "bg-gradient-to-r from-fuchsia-950 to-blue-950",
  "bg-gradient-to-r from-fuchsia-950 to-indigo-300",
  "bg-gradient-to-r from-fuchsia-950 to-indigo-400",
  "bg-gradient-to-r from-fuchsia-950 to-indigo-500",
  "bg-gradient-to-r from-fuchsia-950 to-indigo-600",
  "bg-gradient-to-r from-fuchsia-950 to-indigo-700",
  "bg-gradient-to-r from-fuchsia-950 to-indigo-800",
  "bg-gradient-to-r from-fuchsia-950 to-indigo-900",
  "bg-gradient-to-r from-fuchsia-950 to-indigo-950",
  "bg-gradient-to-r from-fuchsia-950 to-violet-300",
  "bg-gradient-to-r from-fuchsia-950 to-violet-400",
  "bg-gradient-to-r from-fuchsia-950 to-violet-500",
  "bg-gradient-to-r from-fuchsia-950 to-violet-600",
  "bg-gradient-to-r from-fuchsia-950 to-violet-700",
  "bg-gradient-to-r from-fuchsia-950 to-violet-800",
  "bg-gradient-to-r from-fuchsia-950 to-violet-900",
  "bg-gradient-to-r from-fuchsia-950 to-violet-950",
  "bg-gradient-to-r from-fuchsia-950 to-purple-300",
  "bg-gradient-to-r from-fuchsia-950 to-purple-400",
  "bg-gradient-to-r from-fuchsia-950 to-purple-500",
  "bg-gradient-to-r from-fuchsia-950 to-purple-600",
  "bg-gradient-to-r from-fuchsia-950 to-purple-700",
  "bg-gradient-to-r from-fuchsia-950 to-purple-800",
  "bg-gradient-to-r from-fuchsia-950 to-purple-900",
  "bg-gradient-to-r from-fuchsia-950 to-purple-950",
  "bg-gradient-to-r from-fuchsia-950 to-pink-300",
  "bg-gradient-to-r from-fuchsia-950 to-pink-400",
  "bg-gradient-to-r from-fuchsia-950 to-pink-500",
  "bg-gradient-to-r from-fuchsia-950 to-pink-600",
  "bg-gradient-to-r from-fuchsia-950 to-pink-700",
  "bg-gradient-to-r from-fuchsia-950 to-pink-800",
  "bg-gradient-to-r from-fuchsia-950 to-pink-900",
  "bg-gradient-to-r from-fuchsia-950 to-pink-950",
  "bg-gradient-to-r from-fuchsia-950 to-rose-300",
  "bg-gradient-to-r from-fuchsia-950 to-rose-400",
  "bg-gradient-to-r from-fuchsia-950 to-rose-500",
  "bg-gradient-to-r from-fuchsia-950 to-rose-600",
  "bg-gradient-to-r from-fuchsia-950 to-rose-700",
  "bg-gradient-to-r from-fuchsia-950 to-rose-800",
  "bg-gradient-to-r from-fuchsia-950 to-rose-900",
  "bg-gradient-to-r from-fuchsia-950 to-rose-950",
  "bg-gradient-to-r from-pink-300 to-red-300",
  "bg-gradient-to-r from-pink-300 to-red-400",
  "bg-gradient-to-r from-pink-300 to-red-500",
  "bg-gradient-to-r from-pink-300 to-red-600",
  "bg-gradient-to-r from-pink-300 to-red-700",
  "bg-gradient-to-r from-pink-300 to-red-800",
  "bg-gradient-to-r from-pink-300 to-red-900",
  "bg-gradient-to-r from-pink-300 to-red-950",
  "bg-gradient-to-r from-pink-300 to-orange-300",
  "bg-gradient-to-r from-pink-300 to-orange-400",
  "bg-gradient-to-r from-pink-300 to-orange-500",
  "bg-gradient-to-r from-pink-300 to-orange-600",
  "bg-gradient-to-r from-pink-300 to-orange-700",
  "bg-gradient-to-r from-pink-300 to-orange-800",
  "bg-gradient-to-r from-pink-300 to-orange-900",
  "bg-gradient-to-r from-pink-300 to-orange-950",
  "bg-gradient-to-r from-pink-300 to-amber-300",
  "bg-gradient-to-r from-pink-300 to-amber-400",
  "bg-gradient-to-r from-pink-300 to-amber-500",
  "bg-gradient-to-r from-pink-300 to-amber-600",
  "bg-gradient-to-r from-pink-300 to-amber-700",
  "bg-gradient-to-r from-pink-300 to-amber-800",
  "bg-gradient-to-r from-pink-300 to-amber-900",
  "bg-gradient-to-r from-pink-300 to-amber-950",
  "bg-gradient-to-r from-pink-300 to-yellow-300",
  "bg-gradient-to-r from-pink-300 to-yellow-400",
  "bg-gradient-to-r from-pink-300 to-yellow-500",
  "bg-gradient-to-r from-pink-300 to-yellow-600",
  "bg-gradient-to-r from-pink-300 to-yellow-700",
  "bg-gradient-to-r from-pink-300 to-yellow-800",
  "bg-gradient-to-r from-pink-300 to-yellow-900",
  "bg-gradient-to-r from-pink-300 to-yellow-950",
  "bg-gradient-to-r from-pink-300 to-lime-300",
  "bg-gradient-to-r from-pink-300 to-lime-400",
  "bg-gradient-to-r from-pink-300 to-lime-500",
  "bg-gradient-to-r from-pink-300 to-lime-600",
  "bg-gradient-to-r from-pink-300 to-lime-700",
  "bg-gradient-to-r from-pink-300 to-lime-800",
  "bg-gradient-to-r from-pink-300 to-lime-900",
  "bg-gradient-to-r from-pink-300 to-lime-950",
  "bg-gradient-to-r from-pink-300 to-green-300",
  "bg-gradient-to-r from-pink-300 to-green-400",
  "bg-gradient-to-r from-pink-300 to-green-500",
  "bg-gradient-to-r from-pink-300 to-green-600",
  "bg-gradient-to-r from-pink-300 to-green-700",
  "bg-gradient-to-r from-pink-300 to-green-800",
  "bg-gradient-to-r from-pink-300 to-green-900",
  "bg-gradient-to-r from-pink-300 to-green-950",
  "bg-gradient-to-r from-pink-300 to-emerald-300",
  "bg-gradient-to-r from-pink-300 to-emerald-400",
  "bg-gradient-to-r from-pink-300 to-emerald-500",
  "bg-gradient-to-r from-pink-300 to-emerald-600",
  "bg-gradient-to-r from-pink-300 to-emerald-700",
  "bg-gradient-to-r from-pink-300 to-emerald-800",
  "bg-gradient-to-r from-pink-300 to-emerald-900",
  "bg-gradient-to-r from-pink-300 to-emerald-950",
  "bg-gradient-to-r from-pink-300 to-teal-300",
  "bg-gradient-to-r from-pink-300 to-teal-400",
  "bg-gradient-to-r from-pink-300 to-teal-500",
  "bg-gradient-to-r from-pink-300 to-teal-600",
  "bg-gradient-to-r from-pink-300 to-teal-700",
  "bg-gradient-to-r from-pink-300 to-teal-800",
  "bg-gradient-to-r from-pink-300 to-teal-900",
  "bg-gradient-to-r from-pink-300 to-teal-950",
  "bg-gradient-to-r from-pink-300 to-cyan-300",
  "bg-gradient-to-r from-pink-300 to-cyan-400",
  "bg-gradient-to-r from-pink-300 to-cyan-500",
  "bg-gradient-to-r from-pink-300 to-cyan-600",
  "bg-gradient-to-r from-pink-300 to-cyan-700",
  "bg-gradient-to-r from-pink-300 to-cyan-800",
  "bg-gradient-to-r from-pink-300 to-cyan-900",
  "bg-gradient-to-r from-pink-300 to-cyan-950",
  "bg-gradient-to-r from-pink-300 to-sky-300",
  "bg-gradient-to-r from-pink-300 to-sky-400",
  "bg-gradient-to-r from-pink-300 to-sky-500",
  "bg-gradient-to-r from-pink-300 to-sky-600",
  "bg-gradient-to-r from-pink-300 to-sky-700",
  "bg-gradient-to-r from-pink-300 to-sky-800",
  "bg-gradient-to-r from-pink-300 to-sky-900",
  "bg-gradient-to-r from-pink-300 to-sky-950",
  "bg-gradient-to-r from-pink-300 to-blue-300",
  "bg-gradient-to-r from-pink-300 to-blue-400",
  "bg-gradient-to-r from-pink-300 to-blue-500",
  "bg-gradient-to-r from-pink-300 to-blue-600",
  "bg-gradient-to-r from-pink-300 to-blue-700",
  "bg-gradient-to-r from-pink-300 to-blue-800",
  "bg-gradient-to-r from-pink-300 to-blue-900",
  "bg-gradient-to-r from-pink-300 to-blue-950",
  "bg-gradient-to-r from-pink-300 to-indigo-300",
  "bg-gradient-to-r from-pink-300 to-indigo-400",
  "bg-gradient-to-r from-pink-300 to-indigo-500",
  "bg-gradient-to-r from-pink-300 to-indigo-600",
  "bg-gradient-to-r from-pink-300 to-indigo-700",
  "bg-gradient-to-r from-pink-300 to-indigo-800",
  "bg-gradient-to-r from-pink-300 to-indigo-900",
  "bg-gradient-to-r from-pink-300 to-indigo-950",
  "bg-gradient-to-r from-pink-300 to-violet-300",
  "bg-gradient-to-r from-pink-300 to-violet-400",
  "bg-gradient-to-r from-pink-300 to-violet-500",
  "bg-gradient-to-r from-pink-300 to-violet-600",
  "bg-gradient-to-r from-pink-300 to-violet-700",
  "bg-gradient-to-r from-pink-300 to-violet-800",
  "bg-gradient-to-r from-pink-300 to-violet-900",
  "bg-gradient-to-r from-pink-300 to-violet-950",
  "bg-gradient-to-r from-pink-300 to-purple-300",
  "bg-gradient-to-r from-pink-300 to-purple-400",
  "bg-gradient-to-r from-pink-300 to-purple-500",
  "bg-gradient-to-r from-pink-300 to-purple-600",
  "bg-gradient-to-r from-pink-300 to-purple-700",
  "bg-gradient-to-r from-pink-300 to-purple-800",
  "bg-gradient-to-r from-pink-300 to-purple-900",
  "bg-gradient-to-r from-pink-300 to-purple-950",
  "bg-gradient-to-r from-pink-300 to-fuchsia-300",
  "bg-gradient-to-r from-pink-300 to-fuchsia-400",
  "bg-gradient-to-r from-pink-300 to-fuchsia-500",
  "bg-gradient-to-r from-pink-300 to-fuchsia-600",
  "bg-gradient-to-r from-pink-300 to-fuchsia-700",
  "bg-gradient-to-r from-pink-300 to-fuchsia-800",
  "bg-gradient-to-r from-pink-300 to-fuchsia-900",
  "bg-gradient-to-r from-pink-300 to-fuchsia-950",
  "bg-gradient-to-r from-pink-300 to-rose-300",
  "bg-gradient-to-r from-pink-300 to-rose-400",
  "bg-gradient-to-r from-pink-300 to-rose-500",
  "bg-gradient-to-r from-pink-300 to-rose-600",
  "bg-gradient-to-r from-pink-300 to-rose-700",
  "bg-gradient-to-r from-pink-300 to-rose-800",
  "bg-gradient-to-r from-pink-300 to-rose-900",
  "bg-gradient-to-r from-pink-300 to-rose-950",
  "bg-gradient-to-r from-pink-400 to-red-300",
  "bg-gradient-to-r from-pink-400 to-red-400",
  "bg-gradient-to-r from-pink-400 to-red-500",
  "bg-gradient-to-r from-pink-400 to-red-600",
  "bg-gradient-to-r from-pink-400 to-red-700",
  "bg-gradient-to-r from-pink-400 to-red-800",
  "bg-gradient-to-r from-pink-400 to-red-900",
  "bg-gradient-to-r from-pink-400 to-red-950",
  "bg-gradient-to-r from-pink-400 to-orange-300",
  "bg-gradient-to-r from-pink-400 to-orange-400",
  "bg-gradient-to-r from-pink-400 to-orange-500",
  "bg-gradient-to-r from-pink-400 to-orange-600",
  "bg-gradient-to-r from-pink-400 to-orange-700",
  "bg-gradient-to-r from-pink-400 to-orange-800",
  "bg-gradient-to-r from-pink-400 to-orange-900",
  "bg-gradient-to-r from-pink-400 to-orange-950",
  "bg-gradient-to-r from-pink-400 to-amber-300",
  "bg-gradient-to-r from-pink-400 to-amber-400",
  "bg-gradient-to-r from-pink-400 to-amber-500",
  "bg-gradient-to-r from-pink-400 to-amber-600",
  "bg-gradient-to-r from-pink-400 to-amber-700",
  "bg-gradient-to-r from-pink-400 to-amber-800",
  "bg-gradient-to-r from-pink-400 to-amber-900",
  "bg-gradient-to-r from-pink-400 to-amber-950",
  "bg-gradient-to-r from-pink-400 to-yellow-300",
  "bg-gradient-to-r from-pink-400 to-yellow-400",
  "bg-gradient-to-r from-pink-400 to-yellow-500",
  "bg-gradient-to-r from-pink-400 to-yellow-600",
  "bg-gradient-to-r from-pink-400 to-yellow-700",
  "bg-gradient-to-r from-pink-400 to-yellow-800",
  "bg-gradient-to-r from-pink-400 to-yellow-900",
  "bg-gradient-to-r from-pink-400 to-yellow-950",
  "bg-gradient-to-r from-pink-400 to-lime-300",
  "bg-gradient-to-r from-pink-400 to-lime-400",
  "bg-gradient-to-r from-pink-400 to-lime-500",
  "bg-gradient-to-r from-pink-400 to-lime-600",
  "bg-gradient-to-r from-pink-400 to-lime-700",
  "bg-gradient-to-r from-pink-400 to-lime-800",
  "bg-gradient-to-r from-pink-400 to-lime-900",
  "bg-gradient-to-r from-pink-400 to-lime-950",
  "bg-gradient-to-r from-pink-400 to-green-300",
  "bg-gradient-to-r from-pink-400 to-green-400",
  "bg-gradient-to-r from-pink-400 to-green-500",
  "bg-gradient-to-r from-pink-400 to-green-600",
  "bg-gradient-to-r from-pink-400 to-green-700",
  "bg-gradient-to-r from-pink-400 to-green-800",
  "bg-gradient-to-r from-pink-400 to-green-900",
  "bg-gradient-to-r from-pink-400 to-green-950",
  "bg-gradient-to-r from-pink-400 to-emerald-300",
  "bg-gradient-to-r from-pink-400 to-emerald-400",
  "bg-gradient-to-r from-pink-400 to-emerald-500",
  "bg-gradient-to-r from-pink-400 to-emerald-600",
  "bg-gradient-to-r from-pink-400 to-emerald-700",
  "bg-gradient-to-r from-pink-400 to-emerald-800",
  "bg-gradient-to-r from-pink-400 to-emerald-900",
  "bg-gradient-to-r from-pink-400 to-emerald-950",
  "bg-gradient-to-r from-pink-400 to-teal-300",
  "bg-gradient-to-r from-pink-400 to-teal-400",
  "bg-gradient-to-r from-pink-400 to-teal-500",
  "bg-gradient-to-r from-pink-400 to-teal-600",
  "bg-gradient-to-r from-pink-400 to-teal-700",
  "bg-gradient-to-r from-pink-400 to-teal-800",
  "bg-gradient-to-r from-pink-400 to-teal-900",
  "bg-gradient-to-r from-pink-400 to-teal-950",
  "bg-gradient-to-r from-pink-400 to-cyan-300",
  "bg-gradient-to-r from-pink-400 to-cyan-400",
  "bg-gradient-to-r from-pink-400 to-cyan-500",
  "bg-gradient-to-r from-pink-400 to-cyan-600",
  "bg-gradient-to-r from-pink-400 to-cyan-700",
  "bg-gradient-to-r from-pink-400 to-cyan-800",
  "bg-gradient-to-r from-pink-400 to-cyan-900",
  "bg-gradient-to-r from-pink-400 to-cyan-950",
  "bg-gradient-to-r from-pink-400 to-sky-300",
  "bg-gradient-to-r from-pink-400 to-sky-400",
  "bg-gradient-to-r from-pink-400 to-sky-500",
  "bg-gradient-to-r from-pink-400 to-sky-600",
  "bg-gradient-to-r from-pink-400 to-sky-700",
  "bg-gradient-to-r from-pink-400 to-sky-800",
  "bg-gradient-to-r from-pink-400 to-sky-900",
  "bg-gradient-to-r from-pink-400 to-sky-950",
  "bg-gradient-to-r from-pink-400 to-blue-300",
  "bg-gradient-to-r from-pink-400 to-blue-400",
  "bg-gradient-to-r from-pink-400 to-blue-500",
  "bg-gradient-to-r from-pink-400 to-blue-600",
  "bg-gradient-to-r from-pink-400 to-blue-700",
  "bg-gradient-to-r from-pink-400 to-blue-800",
  "bg-gradient-to-r from-pink-400 to-blue-900",
  "bg-gradient-to-r from-pink-400 to-blue-950",
  "bg-gradient-to-r from-pink-400 to-indigo-300",
  "bg-gradient-to-r from-pink-400 to-indigo-400",
  "bg-gradient-to-r from-pink-400 to-indigo-500",
  "bg-gradient-to-r from-pink-400 to-indigo-600",
  "bg-gradient-to-r from-pink-400 to-indigo-700",
  "bg-gradient-to-r from-pink-400 to-indigo-800",
  "bg-gradient-to-r from-pink-400 to-indigo-900",
  "bg-gradient-to-r from-pink-400 to-indigo-950",
  "bg-gradient-to-r from-pink-400 to-violet-300",
  "bg-gradient-to-r from-pink-400 to-violet-400",
  "bg-gradient-to-r from-pink-400 to-violet-500",
  "bg-gradient-to-r from-pink-400 to-violet-600",
  "bg-gradient-to-r from-pink-400 to-violet-700",
  "bg-gradient-to-r from-pink-400 to-violet-800",
  "bg-gradient-to-r from-pink-400 to-violet-900",
  "bg-gradient-to-r from-pink-400 to-violet-950",
  "bg-gradient-to-r from-pink-400 to-purple-300",
  "bg-gradient-to-r from-pink-400 to-purple-400",
  "bg-gradient-to-r from-pink-400 to-purple-500",
  "bg-gradient-to-r from-pink-400 to-purple-600",
  "bg-gradient-to-r from-pink-400 to-purple-700",
  "bg-gradient-to-r from-pink-400 to-purple-800",
  "bg-gradient-to-r from-pink-400 to-purple-900",
  "bg-gradient-to-r from-pink-400 to-purple-950",
  "bg-gradient-to-r from-pink-400 to-fuchsia-300",
  "bg-gradient-to-r from-pink-400 to-fuchsia-400",
  "bg-gradient-to-r from-pink-400 to-fuchsia-500",
  "bg-gradient-to-r from-pink-400 to-fuchsia-600",
  "bg-gradient-to-r from-pink-400 to-fuchsia-700",
  "bg-gradient-to-r from-pink-400 to-fuchsia-800",
  "bg-gradient-to-r from-pink-400 to-fuchsia-900",
  "bg-gradient-to-r from-pink-400 to-fuchsia-950",
  "bg-gradient-to-r from-pink-400 to-rose-300",
  "bg-gradient-to-r from-pink-400 to-rose-400",
  "bg-gradient-to-r from-pink-400 to-rose-500",
  "bg-gradient-to-r from-pink-400 to-rose-600",
  "bg-gradient-to-r from-pink-400 to-rose-700",
  "bg-gradient-to-r from-pink-400 to-rose-800",
  "bg-gradient-to-r from-pink-400 to-rose-900",
  "bg-gradient-to-r from-pink-400 to-rose-950",
  "bg-gradient-to-r from-pink-500 to-red-300",
  "bg-gradient-to-r from-pink-500 to-red-400",
  "bg-gradient-to-r from-pink-500 to-red-500",
  "bg-gradient-to-r from-pink-500 to-red-600",
  "bg-gradient-to-r from-pink-500 to-red-700",
  "bg-gradient-to-r from-pink-500 to-red-800",
  "bg-gradient-to-r from-pink-500 to-red-900",
  "bg-gradient-to-r from-pink-500 to-red-950",
  "bg-gradient-to-r from-pink-500 to-orange-300",
  "bg-gradient-to-r from-pink-500 to-orange-400",
  "bg-gradient-to-r from-pink-500 to-orange-500",
  "bg-gradient-to-r from-pink-500 to-orange-600",
  "bg-gradient-to-r from-pink-500 to-orange-700",
  "bg-gradient-to-r from-pink-500 to-orange-800",
  "bg-gradient-to-r from-pink-500 to-orange-900",
  "bg-gradient-to-r from-pink-500 to-orange-950",
  "bg-gradient-to-r from-pink-500 to-amber-300",
  "bg-gradient-to-r from-pink-500 to-amber-400",
  "bg-gradient-to-r from-pink-500 to-amber-500",
  "bg-gradient-to-r from-pink-500 to-amber-600",
  "bg-gradient-to-r from-pink-500 to-amber-700",
  "bg-gradient-to-r from-pink-500 to-amber-800",
  "bg-gradient-to-r from-pink-500 to-amber-900",
  "bg-gradient-to-r from-pink-500 to-amber-950",
  "bg-gradient-to-r from-pink-500 to-yellow-300",
  "bg-gradient-to-r from-pink-500 to-yellow-400",
  "bg-gradient-to-r from-pink-500 to-yellow-500",
  "bg-gradient-to-r from-pink-500 to-yellow-600",
  "bg-gradient-to-r from-pink-500 to-yellow-700",
  "bg-gradient-to-r from-pink-500 to-yellow-800",
  "bg-gradient-to-r from-pink-500 to-yellow-900",
  "bg-gradient-to-r from-pink-500 to-yellow-950",
  "bg-gradient-to-r from-pink-500 to-lime-300",
  "bg-gradient-to-r from-pink-500 to-lime-400",
  "bg-gradient-to-r from-pink-500 to-lime-500",
  "bg-gradient-to-r from-pink-500 to-lime-600",
  "bg-gradient-to-r from-pink-500 to-lime-700",
  "bg-gradient-to-r from-pink-500 to-lime-800",
  "bg-gradient-to-r from-pink-500 to-lime-900",
  "bg-gradient-to-r from-pink-500 to-lime-950",
  "bg-gradient-to-r from-pink-500 to-green-300",
  "bg-gradient-to-r from-pink-500 to-green-400",
  "bg-gradient-to-r from-pink-500 to-green-500",
  "bg-gradient-to-r from-pink-500 to-green-600",
  "bg-gradient-to-r from-pink-500 to-green-700",
  "bg-gradient-to-r from-pink-500 to-green-800",
  "bg-gradient-to-r from-pink-500 to-green-900",
  "bg-gradient-to-r from-pink-500 to-green-950",
  "bg-gradient-to-r from-pink-500 to-emerald-300",
  "bg-gradient-to-r from-pink-500 to-emerald-400",
  "bg-gradient-to-r from-pink-500 to-emerald-500",
  "bg-gradient-to-r from-pink-500 to-emerald-600",
  "bg-gradient-to-r from-pink-500 to-emerald-700",
  "bg-gradient-to-r from-pink-500 to-emerald-800",
  "bg-gradient-to-r from-pink-500 to-emerald-900",
  "bg-gradient-to-r from-pink-500 to-emerald-950",
  "bg-gradient-to-r from-pink-500 to-teal-300",
  "bg-gradient-to-r from-pink-500 to-teal-400",
  "bg-gradient-to-r from-pink-500 to-teal-500",
  "bg-gradient-to-r from-pink-500 to-teal-600",
  "bg-gradient-to-r from-pink-500 to-teal-700",
  "bg-gradient-to-r from-pink-500 to-teal-800",
  "bg-gradient-to-r from-pink-500 to-teal-900",
  "bg-gradient-to-r from-pink-500 to-teal-950",
  "bg-gradient-to-r from-pink-500 to-cyan-300",
  "bg-gradient-to-r from-pink-500 to-cyan-400",
  "bg-gradient-to-r from-pink-500 to-cyan-500",
  "bg-gradient-to-r from-pink-500 to-cyan-600",
  "bg-gradient-to-r from-pink-500 to-cyan-700",
  "bg-gradient-to-r from-pink-500 to-cyan-800",
  "bg-gradient-to-r from-pink-500 to-cyan-900",
  "bg-gradient-to-r from-pink-500 to-cyan-950",
  "bg-gradient-to-r from-pink-500 to-sky-300",
  "bg-gradient-to-r from-pink-500 to-sky-400",
  "bg-gradient-to-r from-pink-500 to-sky-500",
  "bg-gradient-to-r from-pink-500 to-sky-600",
  "bg-gradient-to-r from-pink-500 to-sky-700",
  "bg-gradient-to-r from-pink-500 to-sky-800",
  "bg-gradient-to-r from-pink-500 to-sky-900",
  "bg-gradient-to-r from-pink-500 to-sky-950",
  "bg-gradient-to-r from-pink-500 to-blue-300",
  "bg-gradient-to-r from-pink-500 to-blue-400",
  "bg-gradient-to-r from-pink-500 to-blue-500",
  "bg-gradient-to-r from-pink-500 to-blue-600",
  "bg-gradient-to-r from-pink-500 to-blue-700",
  "bg-gradient-to-r from-pink-500 to-blue-800",
  "bg-gradient-to-r from-pink-500 to-blue-900",
  "bg-gradient-to-r from-pink-500 to-blue-950",
  "bg-gradient-to-r from-pink-500 to-indigo-300",
  "bg-gradient-to-r from-pink-500 to-indigo-400",
  "bg-gradient-to-r from-pink-500 to-indigo-500",
  "bg-gradient-to-r from-pink-500 to-indigo-600",
  "bg-gradient-to-r from-pink-500 to-indigo-700",
  "bg-gradient-to-r from-pink-500 to-indigo-800",
  "bg-gradient-to-r from-pink-500 to-indigo-900",
  "bg-gradient-to-r from-pink-500 to-indigo-950",
  "bg-gradient-to-r from-pink-500 to-violet-300",
  "bg-gradient-to-r from-pink-500 to-violet-400",
  "bg-gradient-to-r from-pink-500 to-violet-500",
  "bg-gradient-to-r from-pink-500 to-violet-600",
  "bg-gradient-to-r from-pink-500 to-violet-700",
  "bg-gradient-to-r from-pink-500 to-violet-800",
  "bg-gradient-to-r from-pink-500 to-violet-900",
  "bg-gradient-to-r from-pink-500 to-violet-950",
  "bg-gradient-to-r from-pink-500 to-purple-300",
  "bg-gradient-to-r from-pink-500 to-purple-400",
  "bg-gradient-to-r from-pink-500 to-purple-500",
  "bg-gradient-to-r from-pink-500 to-purple-600",
  "bg-gradient-to-r from-pink-500 to-purple-700",
  "bg-gradient-to-r from-pink-500 to-purple-800",
  "bg-gradient-to-r from-pink-500 to-purple-900",
  "bg-gradient-to-r from-pink-500 to-purple-950",
  "bg-gradient-to-r from-pink-500 to-fuchsia-300",
  "bg-gradient-to-r from-pink-500 to-fuchsia-400",
  "bg-gradient-to-r from-pink-500 to-fuchsia-500",
  "bg-gradient-to-r from-pink-500 to-fuchsia-600",
  "bg-gradient-to-r from-pink-500 to-fuchsia-700",
  "bg-gradient-to-r from-pink-500 to-fuchsia-800",
  "bg-gradient-to-r from-pink-500 to-fuchsia-900",
  "bg-gradient-to-r from-pink-500 to-fuchsia-950",
  "bg-gradient-to-r from-pink-500 to-rose-300",
  "bg-gradient-to-r from-pink-500 to-rose-400",
  "bg-gradient-to-r from-pink-500 to-rose-500",
  "bg-gradient-to-r from-pink-500 to-rose-600",
  "bg-gradient-to-r from-pink-500 to-rose-700",
  "bg-gradient-to-r from-pink-500 to-rose-800",
  "bg-gradient-to-r from-pink-500 to-rose-900",
  "bg-gradient-to-r from-pink-500 to-rose-950",
  "bg-gradient-to-r from-pink-600 to-red-300",
  "bg-gradient-to-r from-pink-600 to-red-400",
  "bg-gradient-to-r from-pink-600 to-red-500",
  "bg-gradient-to-r from-pink-600 to-red-600",
  "bg-gradient-to-r from-pink-600 to-red-700",
  "bg-gradient-to-r from-pink-600 to-red-800",
  "bg-gradient-to-r from-pink-600 to-red-900",
  "bg-gradient-to-r from-pink-600 to-red-950",
  "bg-gradient-to-r from-pink-600 to-orange-300",
  "bg-gradient-to-r from-pink-600 to-orange-400",
  "bg-gradient-to-r from-pink-600 to-orange-500",
  "bg-gradient-to-r from-pink-600 to-orange-600",
  "bg-gradient-to-r from-pink-600 to-orange-700",
  "bg-gradient-to-r from-pink-600 to-orange-800",
  "bg-gradient-to-r from-pink-600 to-orange-900",
  "bg-gradient-to-r from-pink-600 to-orange-950",
  "bg-gradient-to-r from-pink-600 to-amber-300",
  "bg-gradient-to-r from-pink-600 to-amber-400",
  "bg-gradient-to-r from-pink-600 to-amber-500",
  "bg-gradient-to-r from-pink-600 to-amber-600",
  "bg-gradient-to-r from-pink-600 to-amber-700",
  "bg-gradient-to-r from-pink-600 to-amber-800",
  "bg-gradient-to-r from-pink-600 to-amber-900",
  "bg-gradient-to-r from-pink-600 to-amber-950",
  "bg-gradient-to-r from-pink-600 to-yellow-300",
  "bg-gradient-to-r from-pink-600 to-yellow-400",
  "bg-gradient-to-r from-pink-600 to-yellow-500",
  "bg-gradient-to-r from-pink-600 to-yellow-600",
  "bg-gradient-to-r from-pink-600 to-yellow-700",
  "bg-gradient-to-r from-pink-600 to-yellow-800",
  "bg-gradient-to-r from-pink-600 to-yellow-900",
  "bg-gradient-to-r from-pink-600 to-yellow-950",
  "bg-gradient-to-r from-pink-600 to-lime-300",
  "bg-gradient-to-r from-pink-600 to-lime-400",
  "bg-gradient-to-r from-pink-600 to-lime-500",
  "bg-gradient-to-r from-pink-600 to-lime-600",
  "bg-gradient-to-r from-pink-600 to-lime-700",
  "bg-gradient-to-r from-pink-600 to-lime-800",
  "bg-gradient-to-r from-pink-600 to-lime-900",
  "bg-gradient-to-r from-pink-600 to-lime-950",
  "bg-gradient-to-r from-pink-600 to-green-300",
  "bg-gradient-to-r from-pink-600 to-green-400",
  "bg-gradient-to-r from-pink-600 to-green-500",
  "bg-gradient-to-r from-pink-600 to-green-600",
  "bg-gradient-to-r from-pink-600 to-green-700",
  "bg-gradient-to-r from-pink-600 to-green-800",
  "bg-gradient-to-r from-pink-600 to-green-900",
  "bg-gradient-to-r from-pink-600 to-green-950",
  "bg-gradient-to-r from-pink-600 to-emerald-300",
  "bg-gradient-to-r from-pink-600 to-emerald-400",
  "bg-gradient-to-r from-pink-600 to-emerald-500",
  "bg-gradient-to-r from-pink-600 to-emerald-600",
  "bg-gradient-to-r from-pink-600 to-emerald-700",
  "bg-gradient-to-r from-pink-600 to-emerald-800",
  "bg-gradient-to-r from-pink-600 to-emerald-900",
  "bg-gradient-to-r from-pink-600 to-emerald-950",
  "bg-gradient-to-r from-pink-600 to-teal-300",
  "bg-gradient-to-r from-pink-600 to-teal-400",
  "bg-gradient-to-r from-pink-600 to-teal-500",
  "bg-gradient-to-r from-pink-600 to-teal-600",
  "bg-gradient-to-r from-pink-600 to-teal-700",
  "bg-gradient-to-r from-pink-600 to-teal-800",
  "bg-gradient-to-r from-pink-600 to-teal-900",
  "bg-gradient-to-r from-pink-600 to-teal-950",
  "bg-gradient-to-r from-pink-600 to-cyan-300",
  "bg-gradient-to-r from-pink-600 to-cyan-400",
  "bg-gradient-to-r from-pink-600 to-cyan-500",
  "bg-gradient-to-r from-pink-600 to-cyan-600",
  "bg-gradient-to-r from-pink-600 to-cyan-700",
  "bg-gradient-to-r from-pink-600 to-cyan-800",
  "bg-gradient-to-r from-pink-600 to-cyan-900",
  "bg-gradient-to-r from-pink-600 to-cyan-950",
  "bg-gradient-to-r from-pink-600 to-sky-300",
  "bg-gradient-to-r from-pink-600 to-sky-400",
  "bg-gradient-to-r from-pink-600 to-sky-500",
  "bg-gradient-to-r from-pink-600 to-sky-600",
  "bg-gradient-to-r from-pink-600 to-sky-700",
  "bg-gradient-to-r from-pink-600 to-sky-800",
  "bg-gradient-to-r from-pink-600 to-sky-900",
  "bg-gradient-to-r from-pink-600 to-sky-950",
  "bg-gradient-to-r from-pink-600 to-blue-300",
  "bg-gradient-to-r from-pink-600 to-blue-400",
  "bg-gradient-to-r from-pink-600 to-blue-500",
  "bg-gradient-to-r from-pink-600 to-blue-600",
  "bg-gradient-to-r from-pink-600 to-blue-700",
  "bg-gradient-to-r from-pink-600 to-blue-800",
  "bg-gradient-to-r from-pink-600 to-blue-900",
  "bg-gradient-to-r from-pink-600 to-blue-950",
  "bg-gradient-to-r from-pink-600 to-indigo-300",
  "bg-gradient-to-r from-pink-600 to-indigo-400",
  "bg-gradient-to-r from-pink-600 to-indigo-500",
  "bg-gradient-to-r from-pink-600 to-indigo-600",
  "bg-gradient-to-r from-pink-600 to-indigo-700",
  "bg-gradient-to-r from-pink-600 to-indigo-800",
  "bg-gradient-to-r from-pink-600 to-indigo-900",
  "bg-gradient-to-r from-pink-600 to-indigo-950",
  "bg-gradient-to-r from-pink-600 to-violet-300",
  "bg-gradient-to-r from-pink-600 to-violet-400",
  "bg-gradient-to-r from-pink-600 to-violet-500",
  "bg-gradient-to-r from-pink-600 to-violet-600",
  "bg-gradient-to-r from-pink-600 to-violet-700",
  "bg-gradient-to-r from-pink-600 to-violet-800",
  "bg-gradient-to-r from-pink-600 to-violet-900",
  "bg-gradient-to-r from-pink-600 to-violet-950",
  "bg-gradient-to-r from-pink-600 to-purple-300",
  "bg-gradient-to-r from-pink-600 to-purple-400",
  "bg-gradient-to-r from-pink-600 to-purple-500",
  "bg-gradient-to-r from-pink-600 to-purple-600",
  "bg-gradient-to-r from-pink-600 to-purple-700",
  "bg-gradient-to-r from-pink-600 to-purple-800",
  "bg-gradient-to-r from-pink-600 to-purple-900",
  "bg-gradient-to-r from-pink-600 to-purple-950",
  "bg-gradient-to-r from-pink-600 to-fuchsia-300",
  "bg-gradient-to-r from-pink-600 to-fuchsia-400",
  "bg-gradient-to-r from-pink-600 to-fuchsia-500",
  "bg-gradient-to-r from-pink-600 to-fuchsia-600",
  "bg-gradient-to-r from-pink-600 to-fuchsia-700",
  "bg-gradient-to-r from-pink-600 to-fuchsia-800",
  "bg-gradient-to-r from-pink-600 to-fuchsia-900",
  "bg-gradient-to-r from-pink-600 to-fuchsia-950",
  "bg-gradient-to-r from-pink-600 to-rose-300",
  "bg-gradient-to-r from-pink-600 to-rose-400",
  "bg-gradient-to-r from-pink-600 to-rose-500",
  "bg-gradient-to-r from-pink-600 to-rose-600",
  "bg-gradient-to-r from-pink-600 to-rose-700",
  "bg-gradient-to-r from-pink-600 to-rose-800",
  "bg-gradient-to-r from-pink-600 to-rose-900",
  "bg-gradient-to-r from-pink-600 to-rose-950",
  "bg-gradient-to-r from-pink-700 to-red-300",
  "bg-gradient-to-r from-pink-700 to-red-400",
  "bg-gradient-to-r from-pink-700 to-red-500",
  "bg-gradient-to-r from-pink-700 to-red-600",
  "bg-gradient-to-r from-pink-700 to-red-700",
  "bg-gradient-to-r from-pink-700 to-red-800",
  "bg-gradient-to-r from-pink-700 to-red-900",
  "bg-gradient-to-r from-pink-700 to-red-950",
  "bg-gradient-to-r from-pink-700 to-orange-300",
  "bg-gradient-to-r from-pink-700 to-orange-400",
  "bg-gradient-to-r from-pink-700 to-orange-500",
  "bg-gradient-to-r from-pink-700 to-orange-600",
  "bg-gradient-to-r from-pink-700 to-orange-700",
  "bg-gradient-to-r from-pink-700 to-orange-800",
  "bg-gradient-to-r from-pink-700 to-orange-900",
  "bg-gradient-to-r from-pink-700 to-orange-950",
  "bg-gradient-to-r from-pink-700 to-amber-300",
  "bg-gradient-to-r from-pink-700 to-amber-400",
  "bg-gradient-to-r from-pink-700 to-amber-500",
  "bg-gradient-to-r from-pink-700 to-amber-600",
  "bg-gradient-to-r from-pink-700 to-amber-700",
  "bg-gradient-to-r from-pink-700 to-amber-800",
  "bg-gradient-to-r from-pink-700 to-amber-900",
  "bg-gradient-to-r from-pink-700 to-amber-950",
  "bg-gradient-to-r from-pink-700 to-yellow-300",
  "bg-gradient-to-r from-pink-700 to-yellow-400",
  "bg-gradient-to-r from-pink-700 to-yellow-500",
  "bg-gradient-to-r from-pink-700 to-yellow-600",
  "bg-gradient-to-r from-pink-700 to-yellow-700",
  "bg-gradient-to-r from-pink-700 to-yellow-800",
  "bg-gradient-to-r from-pink-700 to-yellow-900",
  "bg-gradient-to-r from-pink-700 to-yellow-950",
  "bg-gradient-to-r from-pink-700 to-lime-300",
  "bg-gradient-to-r from-pink-700 to-lime-400",
  "bg-gradient-to-r from-pink-700 to-lime-500",
  "bg-gradient-to-r from-pink-700 to-lime-600",
  "bg-gradient-to-r from-pink-700 to-lime-700",
  "bg-gradient-to-r from-pink-700 to-lime-800",
  "bg-gradient-to-r from-pink-700 to-lime-900",
  "bg-gradient-to-r from-pink-700 to-lime-950",
  "bg-gradient-to-r from-pink-700 to-green-300",
  "bg-gradient-to-r from-pink-700 to-green-400",
  "bg-gradient-to-r from-pink-700 to-green-500",
  "bg-gradient-to-r from-pink-700 to-green-600",
  "bg-gradient-to-r from-pink-700 to-green-700",
  "bg-gradient-to-r from-pink-700 to-green-800",
  "bg-gradient-to-r from-pink-700 to-green-900",
  "bg-gradient-to-r from-pink-700 to-green-950",
  "bg-gradient-to-r from-pink-700 to-emerald-300",
  "bg-gradient-to-r from-pink-700 to-emerald-400",
  "bg-gradient-to-r from-pink-700 to-emerald-500",
  "bg-gradient-to-r from-pink-700 to-emerald-600",
  "bg-gradient-to-r from-pink-700 to-emerald-700",
  "bg-gradient-to-r from-pink-700 to-emerald-800",
  "bg-gradient-to-r from-pink-700 to-emerald-900",
  "bg-gradient-to-r from-pink-700 to-emerald-950",
  "bg-gradient-to-r from-pink-700 to-teal-300",
  "bg-gradient-to-r from-pink-700 to-teal-400",
  "bg-gradient-to-r from-pink-700 to-teal-500",
  "bg-gradient-to-r from-pink-700 to-teal-600",
  "bg-gradient-to-r from-pink-700 to-teal-700",
  "bg-gradient-to-r from-pink-700 to-teal-800",
  "bg-gradient-to-r from-pink-700 to-teal-900",
  "bg-gradient-to-r from-pink-700 to-teal-950",
  "bg-gradient-to-r from-pink-700 to-cyan-300",
  "bg-gradient-to-r from-pink-700 to-cyan-400",
  "bg-gradient-to-r from-pink-700 to-cyan-500",
  "bg-gradient-to-r from-pink-700 to-cyan-600",
  "bg-gradient-to-r from-pink-700 to-cyan-700",
  "bg-gradient-to-r from-pink-700 to-cyan-800",
  "bg-gradient-to-r from-pink-700 to-cyan-900",
  "bg-gradient-to-r from-pink-700 to-cyan-950",
  "bg-gradient-to-r from-pink-700 to-sky-300",
  "bg-gradient-to-r from-pink-700 to-sky-400",
  "bg-gradient-to-r from-pink-700 to-sky-500",
  "bg-gradient-to-r from-pink-700 to-sky-600",
  "bg-gradient-to-r from-pink-700 to-sky-700",
  "bg-gradient-to-r from-pink-700 to-sky-800",
  "bg-gradient-to-r from-pink-700 to-sky-900",
  "bg-gradient-to-r from-pink-700 to-sky-950",
  "bg-gradient-to-r from-pink-700 to-blue-300",
  "bg-gradient-to-r from-pink-700 to-blue-400",
  "bg-gradient-to-r from-pink-700 to-blue-500",
  "bg-gradient-to-r from-pink-700 to-blue-600",
  "bg-gradient-to-r from-pink-700 to-blue-700",
  "bg-gradient-to-r from-pink-700 to-blue-800",
  "bg-gradient-to-r from-pink-700 to-blue-900",
  "bg-gradient-to-r from-pink-700 to-blue-950",
  "bg-gradient-to-r from-pink-700 to-indigo-300",
  "bg-gradient-to-r from-pink-700 to-indigo-400",
  "bg-gradient-to-r from-pink-700 to-indigo-500",
  "bg-gradient-to-r from-pink-700 to-indigo-600",
  "bg-gradient-to-r from-pink-700 to-indigo-700",
  "bg-gradient-to-r from-pink-700 to-indigo-800",
  "bg-gradient-to-r from-pink-700 to-indigo-900",
  "bg-gradient-to-r from-pink-700 to-indigo-950",
  "bg-gradient-to-r from-pink-700 to-violet-300",
  "bg-gradient-to-r from-pink-700 to-violet-400",
  "bg-gradient-to-r from-pink-700 to-violet-500",
  "bg-gradient-to-r from-pink-700 to-violet-600",
  "bg-gradient-to-r from-pink-700 to-violet-700",
  "bg-gradient-to-r from-pink-700 to-violet-800",
  "bg-gradient-to-r from-pink-700 to-violet-900",
  "bg-gradient-to-r from-pink-700 to-violet-950",
  "bg-gradient-to-r from-pink-700 to-purple-300",
  "bg-gradient-to-r from-pink-700 to-purple-400",
  "bg-gradient-to-r from-pink-700 to-purple-500",
  "bg-gradient-to-r from-pink-700 to-purple-600",
  "bg-gradient-to-r from-pink-700 to-purple-700",
  "bg-gradient-to-r from-pink-700 to-purple-800",
  "bg-gradient-to-r from-pink-700 to-purple-900",
  "bg-gradient-to-r from-pink-700 to-purple-950",
  "bg-gradient-to-r from-pink-700 to-fuchsia-300",
  "bg-gradient-to-r from-pink-700 to-fuchsia-400",
  "bg-gradient-to-r from-pink-700 to-fuchsia-500",
  "bg-gradient-to-r from-pink-700 to-fuchsia-600",
  "bg-gradient-to-r from-pink-700 to-fuchsia-700",
  "bg-gradient-to-r from-pink-700 to-fuchsia-800",
  "bg-gradient-to-r from-pink-700 to-fuchsia-900",
  "bg-gradient-to-r from-pink-700 to-fuchsia-950",
  "bg-gradient-to-r from-pink-700 to-rose-300",
  "bg-gradient-to-r from-pink-700 to-rose-400",
  "bg-gradient-to-r from-pink-700 to-rose-500",
  "bg-gradient-to-r from-pink-700 to-rose-600",
  "bg-gradient-to-r from-pink-700 to-rose-700",
  "bg-gradient-to-r from-pink-700 to-rose-800",
  "bg-gradient-to-r from-pink-700 to-rose-900",
  "bg-gradient-to-r from-pink-700 to-rose-950",
  "bg-gradient-to-r from-pink-800 to-red-300",
  "bg-gradient-to-r from-pink-800 to-red-400",
  "bg-gradient-to-r from-pink-800 to-red-500",
  "bg-gradient-to-r from-pink-800 to-red-600",
  "bg-gradient-to-r from-pink-800 to-red-700",
  "bg-gradient-to-r from-pink-800 to-red-800",
  "bg-gradient-to-r from-pink-800 to-red-900",
  "bg-gradient-to-r from-pink-800 to-red-950",
  "bg-gradient-to-r from-pink-800 to-orange-300",
  "bg-gradient-to-r from-pink-800 to-orange-400",
  "bg-gradient-to-r from-pink-800 to-orange-500",
  "bg-gradient-to-r from-pink-800 to-orange-600",
  "bg-gradient-to-r from-pink-800 to-orange-700",
  "bg-gradient-to-r from-pink-800 to-orange-800",
  "bg-gradient-to-r from-pink-800 to-orange-900",
  "bg-gradient-to-r from-pink-800 to-orange-950",
  "bg-gradient-to-r from-pink-800 to-amber-300",
  "bg-gradient-to-r from-pink-800 to-amber-400",
  "bg-gradient-to-r from-pink-800 to-amber-500",
  "bg-gradient-to-r from-pink-800 to-amber-600",
  "bg-gradient-to-r from-pink-800 to-amber-700",
  "bg-gradient-to-r from-pink-800 to-amber-800",
  "bg-gradient-to-r from-pink-800 to-amber-900",
  "bg-gradient-to-r from-pink-800 to-amber-950",
  "bg-gradient-to-r from-pink-800 to-yellow-300",
  "bg-gradient-to-r from-pink-800 to-yellow-400",
  "bg-gradient-to-r from-pink-800 to-yellow-500",
  "bg-gradient-to-r from-pink-800 to-yellow-600",
  "bg-gradient-to-r from-pink-800 to-yellow-700",
  "bg-gradient-to-r from-pink-800 to-yellow-800",
  "bg-gradient-to-r from-pink-800 to-yellow-900",
  "bg-gradient-to-r from-pink-800 to-yellow-950",
  "bg-gradient-to-r from-pink-800 to-lime-300",
  "bg-gradient-to-r from-pink-800 to-lime-400",
  "bg-gradient-to-r from-pink-800 to-lime-500",
  "bg-gradient-to-r from-pink-800 to-lime-600",
  "bg-gradient-to-r from-pink-800 to-lime-700",
  "bg-gradient-to-r from-pink-800 to-lime-800",
  "bg-gradient-to-r from-pink-800 to-lime-900",
  "bg-gradient-to-r from-pink-800 to-lime-950",
  "bg-gradient-to-r from-pink-800 to-green-300",
  "bg-gradient-to-r from-pink-800 to-green-400",
  "bg-gradient-to-r from-pink-800 to-green-500",
  "bg-gradient-to-r from-pink-800 to-green-600",
  "bg-gradient-to-r from-pink-800 to-green-700",
  "bg-gradient-to-r from-pink-800 to-green-800",
  "bg-gradient-to-r from-pink-800 to-green-900",
  "bg-gradient-to-r from-pink-800 to-green-950",
  "bg-gradient-to-r from-pink-800 to-emerald-300",
  "bg-gradient-to-r from-pink-800 to-emerald-400",
  "bg-gradient-to-r from-pink-800 to-emerald-500",
  "bg-gradient-to-r from-pink-800 to-emerald-600",
  "bg-gradient-to-r from-pink-800 to-emerald-700",
  "bg-gradient-to-r from-pink-800 to-emerald-800",
  "bg-gradient-to-r from-pink-800 to-emerald-900",
  "bg-gradient-to-r from-pink-800 to-emerald-950",
  "bg-gradient-to-r from-pink-800 to-teal-300",
  "bg-gradient-to-r from-pink-800 to-teal-400",
  "bg-gradient-to-r from-pink-800 to-teal-500",
  "bg-gradient-to-r from-pink-800 to-teal-600",
  "bg-gradient-to-r from-pink-800 to-teal-700",
  "bg-gradient-to-r from-pink-800 to-teal-800",
  "bg-gradient-to-r from-pink-800 to-teal-900",
  "bg-gradient-to-r from-pink-800 to-teal-950",
  "bg-gradient-to-r from-pink-800 to-cyan-300",
  "bg-gradient-to-r from-pink-800 to-cyan-400",
  "bg-gradient-to-r from-pink-800 to-cyan-500",
  "bg-gradient-to-r from-pink-800 to-cyan-600",
  "bg-gradient-to-r from-pink-800 to-cyan-700",
  "bg-gradient-to-r from-pink-800 to-cyan-800",
  "bg-gradient-to-r from-pink-800 to-cyan-900",
  "bg-gradient-to-r from-pink-800 to-cyan-950",
  "bg-gradient-to-r from-pink-800 to-sky-300",
  "bg-gradient-to-r from-pink-800 to-sky-400",
  "bg-gradient-to-r from-pink-800 to-sky-500",
  "bg-gradient-to-r from-pink-800 to-sky-600",
  "bg-gradient-to-r from-pink-800 to-sky-700",
  "bg-gradient-to-r from-pink-800 to-sky-800",
  "bg-gradient-to-r from-pink-800 to-sky-900",
  "bg-gradient-to-r from-pink-800 to-sky-950",
  "bg-gradient-to-r from-pink-800 to-blue-300",
  "bg-gradient-to-r from-pink-800 to-blue-400",
  "bg-gradient-to-r from-pink-800 to-blue-500",
  "bg-gradient-to-r from-pink-800 to-blue-600",
  "bg-gradient-to-r from-pink-800 to-blue-700",
  "bg-gradient-to-r from-pink-800 to-blue-800",
  "bg-gradient-to-r from-pink-800 to-blue-900",
  "bg-gradient-to-r from-pink-800 to-blue-950",
  "bg-gradient-to-r from-pink-800 to-indigo-300",
  "bg-gradient-to-r from-pink-800 to-indigo-400",
  "bg-gradient-to-r from-pink-800 to-indigo-500",
  "bg-gradient-to-r from-pink-800 to-indigo-600",
  "bg-gradient-to-r from-pink-800 to-indigo-700",
  "bg-gradient-to-r from-pink-800 to-indigo-800",
  "bg-gradient-to-r from-pink-800 to-indigo-900",
  "bg-gradient-to-r from-pink-800 to-indigo-950",
  "bg-gradient-to-r from-pink-800 to-violet-300",
  "bg-gradient-to-r from-pink-800 to-violet-400",
  "bg-gradient-to-r from-pink-800 to-violet-500",
  "bg-gradient-to-r from-pink-800 to-violet-600",
  "bg-gradient-to-r from-pink-800 to-violet-700",
  "bg-gradient-to-r from-pink-800 to-violet-800",
  "bg-gradient-to-r from-pink-800 to-violet-900",
  "bg-gradient-to-r from-pink-800 to-violet-950",
  "bg-gradient-to-r from-pink-800 to-purple-300",
  "bg-gradient-to-r from-pink-800 to-purple-400",
  "bg-gradient-to-r from-pink-800 to-purple-500",
  "bg-gradient-to-r from-pink-800 to-purple-600",
  "bg-gradient-to-r from-pink-800 to-purple-700",
  "bg-gradient-to-r from-pink-800 to-purple-800",
  "bg-gradient-to-r from-pink-800 to-purple-900",
  "bg-gradient-to-r from-pink-800 to-purple-950",
  "bg-gradient-to-r from-pink-800 to-fuchsia-300",
  "bg-gradient-to-r from-pink-800 to-fuchsia-400",
  "bg-gradient-to-r from-pink-800 to-fuchsia-500",
  "bg-gradient-to-r from-pink-800 to-fuchsia-600",
  "bg-gradient-to-r from-pink-800 to-fuchsia-700",
  "bg-gradient-to-r from-pink-800 to-fuchsia-800",
  "bg-gradient-to-r from-pink-800 to-fuchsia-900",
  "bg-gradient-to-r from-pink-800 to-fuchsia-950",
  "bg-gradient-to-r from-pink-800 to-rose-300",
  "bg-gradient-to-r from-pink-800 to-rose-400",
  "bg-gradient-to-r from-pink-800 to-rose-500",
  "bg-gradient-to-r from-pink-800 to-rose-600",
  "bg-gradient-to-r from-pink-800 to-rose-700",
  "bg-gradient-to-r from-pink-800 to-rose-800",
  "bg-gradient-to-r from-pink-800 to-rose-900",
  "bg-gradient-to-r from-pink-800 to-rose-950",
  "bg-gradient-to-r from-pink-900 to-red-300",
  "bg-gradient-to-r from-pink-900 to-red-400",
  "bg-gradient-to-r from-pink-900 to-red-500",
  "bg-gradient-to-r from-pink-900 to-red-600",
  "bg-gradient-to-r from-pink-900 to-red-700",
  "bg-gradient-to-r from-pink-900 to-red-800",
  "bg-gradient-to-r from-pink-900 to-red-900",
  "bg-gradient-to-r from-pink-900 to-red-950",
  "bg-gradient-to-r from-pink-900 to-orange-300",
  "bg-gradient-to-r from-pink-900 to-orange-400",
  "bg-gradient-to-r from-pink-900 to-orange-500",
  "bg-gradient-to-r from-pink-900 to-orange-600",
  "bg-gradient-to-r from-pink-900 to-orange-700",
  "bg-gradient-to-r from-pink-900 to-orange-800",
  "bg-gradient-to-r from-pink-900 to-orange-900",
  "bg-gradient-to-r from-pink-900 to-orange-950",
  "bg-gradient-to-r from-pink-900 to-amber-300",
  "bg-gradient-to-r from-pink-900 to-amber-400",
  "bg-gradient-to-r from-pink-900 to-amber-500",
  "bg-gradient-to-r from-pink-900 to-amber-600",
  "bg-gradient-to-r from-pink-900 to-amber-700",
  "bg-gradient-to-r from-pink-900 to-amber-800",
  "bg-gradient-to-r from-pink-900 to-amber-900",
  "bg-gradient-to-r from-pink-900 to-amber-950",
  "bg-gradient-to-r from-pink-900 to-yellow-300",
  "bg-gradient-to-r from-pink-900 to-yellow-400",
  "bg-gradient-to-r from-pink-900 to-yellow-500",
  "bg-gradient-to-r from-pink-900 to-yellow-600",
  "bg-gradient-to-r from-pink-900 to-yellow-700",
  "bg-gradient-to-r from-pink-900 to-yellow-800",
  "bg-gradient-to-r from-pink-900 to-yellow-900",
  "bg-gradient-to-r from-pink-900 to-yellow-950",
  "bg-gradient-to-r from-pink-900 to-lime-300",
  "bg-gradient-to-r from-pink-900 to-lime-400",
  "bg-gradient-to-r from-pink-900 to-lime-500",
  "bg-gradient-to-r from-pink-900 to-lime-600",
  "bg-gradient-to-r from-pink-900 to-lime-700",
  "bg-gradient-to-r from-pink-900 to-lime-800",
  "bg-gradient-to-r from-pink-900 to-lime-900",
  "bg-gradient-to-r from-pink-900 to-lime-950",
  "bg-gradient-to-r from-pink-900 to-green-300",
  "bg-gradient-to-r from-pink-900 to-green-400",
  "bg-gradient-to-r from-pink-900 to-green-500",
  "bg-gradient-to-r from-pink-900 to-green-600",
  "bg-gradient-to-r from-pink-900 to-green-700",
  "bg-gradient-to-r from-pink-900 to-green-800",
  "bg-gradient-to-r from-pink-900 to-green-900",
  "bg-gradient-to-r from-pink-900 to-green-950",
  "bg-gradient-to-r from-pink-900 to-emerald-300",
  "bg-gradient-to-r from-pink-900 to-emerald-400",
  "bg-gradient-to-r from-pink-900 to-emerald-500",
  "bg-gradient-to-r from-pink-900 to-emerald-600",
  "bg-gradient-to-r from-pink-900 to-emerald-700",
  "bg-gradient-to-r from-pink-900 to-emerald-800",
  "bg-gradient-to-r from-pink-900 to-emerald-900",
  "bg-gradient-to-r from-pink-900 to-emerald-950",
  "bg-gradient-to-r from-pink-900 to-teal-300",
  "bg-gradient-to-r from-pink-900 to-teal-400",
  "bg-gradient-to-r from-pink-900 to-teal-500",
  "bg-gradient-to-r from-pink-900 to-teal-600",
  "bg-gradient-to-r from-pink-900 to-teal-700",
  "bg-gradient-to-r from-pink-900 to-teal-800",
  "bg-gradient-to-r from-pink-900 to-teal-900",
  "bg-gradient-to-r from-pink-900 to-teal-950",
  "bg-gradient-to-r from-pink-900 to-cyan-300",
  "bg-gradient-to-r from-pink-900 to-cyan-400",
  "bg-gradient-to-r from-pink-900 to-cyan-500",
  "bg-gradient-to-r from-pink-900 to-cyan-600",
  "bg-gradient-to-r from-pink-900 to-cyan-700",
  "bg-gradient-to-r from-pink-900 to-cyan-800",
  "bg-gradient-to-r from-pink-900 to-cyan-900",
  "bg-gradient-to-r from-pink-900 to-cyan-950",
  "bg-gradient-to-r from-pink-900 to-sky-300",
  "bg-gradient-to-r from-pink-900 to-sky-400",
  "bg-gradient-to-r from-pink-900 to-sky-500",
  "bg-gradient-to-r from-pink-900 to-sky-600",
  "bg-gradient-to-r from-pink-900 to-sky-700",
  "bg-gradient-to-r from-pink-900 to-sky-800",
  "bg-gradient-to-r from-pink-900 to-sky-900",
  "bg-gradient-to-r from-pink-900 to-sky-950",
  "bg-gradient-to-r from-pink-900 to-blue-300",
  "bg-gradient-to-r from-pink-900 to-blue-400",
  "bg-gradient-to-r from-pink-900 to-blue-500",
  "bg-gradient-to-r from-pink-900 to-blue-600",
  "bg-gradient-to-r from-pink-900 to-blue-700",
  "bg-gradient-to-r from-pink-900 to-blue-800",
  "bg-gradient-to-r from-pink-900 to-blue-900",
  "bg-gradient-to-r from-pink-900 to-blue-950",
  "bg-gradient-to-r from-pink-900 to-indigo-300",
  "bg-gradient-to-r from-pink-900 to-indigo-400",
  "bg-gradient-to-r from-pink-900 to-indigo-500",
  "bg-gradient-to-r from-pink-900 to-indigo-600",
  "bg-gradient-to-r from-pink-900 to-indigo-700",
  "bg-gradient-to-r from-pink-900 to-indigo-800",
  "bg-gradient-to-r from-pink-900 to-indigo-900",
  "bg-gradient-to-r from-pink-900 to-indigo-950",
  "bg-gradient-to-r from-pink-900 to-violet-300",
  "bg-gradient-to-r from-pink-900 to-violet-400",
  "bg-gradient-to-r from-pink-900 to-violet-500",
  "bg-gradient-to-r from-pink-900 to-violet-600",
  "bg-gradient-to-r from-pink-900 to-violet-700",
  "bg-gradient-to-r from-pink-900 to-violet-800",
  "bg-gradient-to-r from-pink-900 to-violet-900",
  "bg-gradient-to-r from-pink-900 to-violet-950",
  "bg-gradient-to-r from-pink-900 to-purple-300",
  "bg-gradient-to-r from-pink-900 to-purple-400",
  "bg-gradient-to-r from-pink-900 to-purple-500",
  "bg-gradient-to-r from-pink-900 to-purple-600",
  "bg-gradient-to-r from-pink-900 to-purple-700",
  "bg-gradient-to-r from-pink-900 to-purple-800",
  "bg-gradient-to-r from-pink-900 to-purple-900",
  "bg-gradient-to-r from-pink-900 to-purple-950",
  "bg-gradient-to-r from-pink-900 to-fuchsia-300",
  "bg-gradient-to-r from-pink-900 to-fuchsia-400",
  "bg-gradient-to-r from-pink-900 to-fuchsia-500",
  "bg-gradient-to-r from-pink-900 to-fuchsia-600",
  "bg-gradient-to-r from-pink-900 to-fuchsia-700",
  "bg-gradient-to-r from-pink-900 to-fuchsia-800",
  "bg-gradient-to-r from-pink-900 to-fuchsia-900",
  "bg-gradient-to-r from-pink-900 to-fuchsia-950",
  "bg-gradient-to-r from-pink-900 to-rose-300",
  "bg-gradient-to-r from-pink-900 to-rose-400",
  "bg-gradient-to-r from-pink-900 to-rose-500",
  "bg-gradient-to-r from-pink-900 to-rose-600",
  "bg-gradient-to-r from-pink-900 to-rose-700",
  "bg-gradient-to-r from-pink-900 to-rose-800",
  "bg-gradient-to-r from-pink-900 to-rose-900",
  "bg-gradient-to-r from-pink-900 to-rose-950",
  "bg-gradient-to-r from-pink-950 to-red-300",
  "bg-gradient-to-r from-pink-950 to-red-400",
  "bg-gradient-to-r from-pink-950 to-red-500",
  "bg-gradient-to-r from-pink-950 to-red-600",
  "bg-gradient-to-r from-pink-950 to-red-700",
  "bg-gradient-to-r from-pink-950 to-red-800",
  "bg-gradient-to-r from-pink-950 to-red-900",
  "bg-gradient-to-r from-pink-950 to-red-950",
  "bg-gradient-to-r from-pink-950 to-orange-300",
  "bg-gradient-to-r from-pink-950 to-orange-400",
  "bg-gradient-to-r from-pink-950 to-orange-500",
  "bg-gradient-to-r from-pink-950 to-orange-600",
  "bg-gradient-to-r from-pink-950 to-orange-700",
  "bg-gradient-to-r from-pink-950 to-orange-800",
  "bg-gradient-to-r from-pink-950 to-orange-900",
  "bg-gradient-to-r from-pink-950 to-orange-950",
  "bg-gradient-to-r from-pink-950 to-amber-300",
  "bg-gradient-to-r from-pink-950 to-amber-400",
  "bg-gradient-to-r from-pink-950 to-amber-500",
  "bg-gradient-to-r from-pink-950 to-amber-600",
  "bg-gradient-to-r from-pink-950 to-amber-700",
  "bg-gradient-to-r from-pink-950 to-amber-800",
  "bg-gradient-to-r from-pink-950 to-amber-900",
  "bg-gradient-to-r from-pink-950 to-amber-950",
  "bg-gradient-to-r from-pink-950 to-yellow-300",
  "bg-gradient-to-r from-pink-950 to-yellow-400",
  "bg-gradient-to-r from-pink-950 to-yellow-500",
  "bg-gradient-to-r from-pink-950 to-yellow-600",
  "bg-gradient-to-r from-pink-950 to-yellow-700",
  "bg-gradient-to-r from-pink-950 to-yellow-800",
  "bg-gradient-to-r from-pink-950 to-yellow-900",
  "bg-gradient-to-r from-pink-950 to-yellow-950",
  "bg-gradient-to-r from-pink-950 to-lime-300",
  "bg-gradient-to-r from-pink-950 to-lime-400",
  "bg-gradient-to-r from-pink-950 to-lime-500",
  "bg-gradient-to-r from-pink-950 to-lime-600",
  "bg-gradient-to-r from-pink-950 to-lime-700",
  "bg-gradient-to-r from-pink-950 to-lime-800",
  "bg-gradient-to-r from-pink-950 to-lime-900",
  "bg-gradient-to-r from-pink-950 to-lime-950",
  "bg-gradient-to-r from-pink-950 to-green-300",
  "bg-gradient-to-r from-pink-950 to-green-400",
  "bg-gradient-to-r from-pink-950 to-green-500",
  "bg-gradient-to-r from-pink-950 to-green-600",
  "bg-gradient-to-r from-pink-950 to-green-700",
  "bg-gradient-to-r from-pink-950 to-green-800",
  "bg-gradient-to-r from-pink-950 to-green-900",
  "bg-gradient-to-r from-pink-950 to-green-950",
  "bg-gradient-to-r from-pink-950 to-emerald-300",
  "bg-gradient-to-r from-pink-950 to-emerald-400",
  "bg-gradient-to-r from-pink-950 to-emerald-500",
  "bg-gradient-to-r from-pink-950 to-emerald-600",
  "bg-gradient-to-r from-pink-950 to-emerald-700",
  "bg-gradient-to-r from-pink-950 to-emerald-800",
  "bg-gradient-to-r from-pink-950 to-emerald-900",
  "bg-gradient-to-r from-pink-950 to-emerald-950",
  "bg-gradient-to-r from-pink-950 to-teal-300",
  "bg-gradient-to-r from-pink-950 to-teal-400",
  "bg-gradient-to-r from-pink-950 to-teal-500",
  "bg-gradient-to-r from-pink-950 to-teal-600",
  "bg-gradient-to-r from-pink-950 to-teal-700",
  "bg-gradient-to-r from-pink-950 to-teal-800",
  "bg-gradient-to-r from-pink-950 to-teal-900",
  "bg-gradient-to-r from-pink-950 to-teal-950",
  "bg-gradient-to-r from-pink-950 to-cyan-300",
  "bg-gradient-to-r from-pink-950 to-cyan-400",
  "bg-gradient-to-r from-pink-950 to-cyan-500",
  "bg-gradient-to-r from-pink-950 to-cyan-600",
  "bg-gradient-to-r from-pink-950 to-cyan-700",
  "bg-gradient-to-r from-pink-950 to-cyan-800",
  "bg-gradient-to-r from-pink-950 to-cyan-900",
  "bg-gradient-to-r from-pink-950 to-cyan-950",
  "bg-gradient-to-r from-pink-950 to-sky-300",
  "bg-gradient-to-r from-pink-950 to-sky-400",
  "bg-gradient-to-r from-pink-950 to-sky-500",
  "bg-gradient-to-r from-pink-950 to-sky-600",
  "bg-gradient-to-r from-pink-950 to-sky-700",
  "bg-gradient-to-r from-pink-950 to-sky-800",
  "bg-gradient-to-r from-pink-950 to-sky-900",
  "bg-gradient-to-r from-pink-950 to-sky-950",
  "bg-gradient-to-r from-pink-950 to-blue-300",
  "bg-gradient-to-r from-pink-950 to-blue-400",
  "bg-gradient-to-r from-pink-950 to-blue-500",
  "bg-gradient-to-r from-pink-950 to-blue-600",
  "bg-gradient-to-r from-pink-950 to-blue-700",
  "bg-gradient-to-r from-pink-950 to-blue-800",
  "bg-gradient-to-r from-pink-950 to-blue-900",
  "bg-gradient-to-r from-pink-950 to-blue-950",
  "bg-gradient-to-r from-pink-950 to-indigo-300",
  "bg-gradient-to-r from-pink-950 to-indigo-400",
  "bg-gradient-to-r from-pink-950 to-indigo-500",
  "bg-gradient-to-r from-pink-950 to-indigo-600",
  "bg-gradient-to-r from-pink-950 to-indigo-700",
  "bg-gradient-to-r from-pink-950 to-indigo-800",
  "bg-gradient-to-r from-pink-950 to-indigo-900",
  "bg-gradient-to-r from-pink-950 to-indigo-950",
  "bg-gradient-to-r from-pink-950 to-violet-300",
  "bg-gradient-to-r from-pink-950 to-violet-400",
  "bg-gradient-to-r from-pink-950 to-violet-500",
  "bg-gradient-to-r from-pink-950 to-violet-600",
  "bg-gradient-to-r from-pink-950 to-violet-700",
  "bg-gradient-to-r from-pink-950 to-violet-800",
  "bg-gradient-to-r from-pink-950 to-violet-900",
  "bg-gradient-to-r from-pink-950 to-violet-950",
  "bg-gradient-to-r from-pink-950 to-purple-300",
  "bg-gradient-to-r from-pink-950 to-purple-400",
  "bg-gradient-to-r from-pink-950 to-purple-500",
  "bg-gradient-to-r from-pink-950 to-purple-600",
  "bg-gradient-to-r from-pink-950 to-purple-700",
  "bg-gradient-to-r from-pink-950 to-purple-800",
  "bg-gradient-to-r from-pink-950 to-purple-900",
  "bg-gradient-to-r from-pink-950 to-purple-950",
  "bg-gradient-to-r from-pink-950 to-fuchsia-300",
  "bg-gradient-to-r from-pink-950 to-fuchsia-400",
  "bg-gradient-to-r from-pink-950 to-fuchsia-500",
  "bg-gradient-to-r from-pink-950 to-fuchsia-600",
  "bg-gradient-to-r from-pink-950 to-fuchsia-700",
  "bg-gradient-to-r from-pink-950 to-fuchsia-800",
  "bg-gradient-to-r from-pink-950 to-fuchsia-900",
  "bg-gradient-to-r from-pink-950 to-fuchsia-950",
  "bg-gradient-to-r from-pink-950 to-rose-300",
  "bg-gradient-to-r from-pink-950 to-rose-400",
  "bg-gradient-to-r from-pink-950 to-rose-500",
  "bg-gradient-to-r from-pink-950 to-rose-600",
  "bg-gradient-to-r from-pink-950 to-rose-700",
  "bg-gradient-to-r from-pink-950 to-rose-800",
  "bg-gradient-to-r from-pink-950 to-rose-900",
  "bg-gradient-to-r from-pink-950 to-rose-950",
  "bg-gradient-to-r from-rose-300 to-red-300",
  "bg-gradient-to-r from-rose-300 to-red-400",
  "bg-gradient-to-r from-rose-300 to-red-500",
  "bg-gradient-to-r from-rose-300 to-red-600",
  "bg-gradient-to-r from-rose-300 to-red-700",
  "bg-gradient-to-r from-rose-300 to-red-800",
  "bg-gradient-to-r from-rose-300 to-red-900",
  "bg-gradient-to-r from-rose-300 to-red-950",
  "bg-gradient-to-r from-rose-300 to-orange-300",
  "bg-gradient-to-r from-rose-300 to-orange-400",
  "bg-gradient-to-r from-rose-300 to-orange-500",
  "bg-gradient-to-r from-rose-300 to-orange-600",
  "bg-gradient-to-r from-rose-300 to-orange-700",
  "bg-gradient-to-r from-rose-300 to-orange-800",
  "bg-gradient-to-r from-rose-300 to-orange-900",
  "bg-gradient-to-r from-rose-300 to-orange-950",
  "bg-gradient-to-r from-rose-300 to-amber-300",
  "bg-gradient-to-r from-rose-300 to-amber-400",
  "bg-gradient-to-r from-rose-300 to-amber-500",
  "bg-gradient-to-r from-rose-300 to-amber-600",
  "bg-gradient-to-r from-rose-300 to-amber-700",
  "bg-gradient-to-r from-rose-300 to-amber-800",
  "bg-gradient-to-r from-rose-300 to-amber-900",
  "bg-gradient-to-r from-rose-300 to-amber-950",
  "bg-gradient-to-r from-rose-300 to-yellow-300",
  "bg-gradient-to-r from-rose-300 to-yellow-400",
  "bg-gradient-to-r from-rose-300 to-yellow-500",
  "bg-gradient-to-r from-rose-300 to-yellow-600",
  "bg-gradient-to-r from-rose-300 to-yellow-700",
  "bg-gradient-to-r from-rose-300 to-yellow-800",
  "bg-gradient-to-r from-rose-300 to-yellow-900",
  "bg-gradient-to-r from-rose-300 to-yellow-950",
  "bg-gradient-to-r from-rose-300 to-lime-300",
  "bg-gradient-to-r from-rose-300 to-lime-400",
  "bg-gradient-to-r from-rose-300 to-lime-500",
  "bg-gradient-to-r from-rose-300 to-lime-600",
  "bg-gradient-to-r from-rose-300 to-lime-700",
  "bg-gradient-to-r from-rose-300 to-lime-800",
  "bg-gradient-to-r from-rose-300 to-lime-900",
  "bg-gradient-to-r from-rose-300 to-lime-950",
  "bg-gradient-to-r from-rose-300 to-green-300",
  "bg-gradient-to-r from-rose-300 to-green-400",
  "bg-gradient-to-r from-rose-300 to-green-500",
  "bg-gradient-to-r from-rose-300 to-green-600",
  "bg-gradient-to-r from-rose-300 to-green-700",
  "bg-gradient-to-r from-rose-300 to-green-800",
  "bg-gradient-to-r from-rose-300 to-green-900",
  "bg-gradient-to-r from-rose-300 to-green-950",
  "bg-gradient-to-r from-rose-300 to-emerald-300",
  "bg-gradient-to-r from-rose-300 to-emerald-400",
  "bg-gradient-to-r from-rose-300 to-emerald-500",
  "bg-gradient-to-r from-rose-300 to-emerald-600",
  "bg-gradient-to-r from-rose-300 to-emerald-700",
  "bg-gradient-to-r from-rose-300 to-emerald-800",
  "bg-gradient-to-r from-rose-300 to-emerald-900",
  "bg-gradient-to-r from-rose-300 to-emerald-950",
  "bg-gradient-to-r from-rose-300 to-teal-300",
  "bg-gradient-to-r from-rose-300 to-teal-400",
  "bg-gradient-to-r from-rose-300 to-teal-500",
  "bg-gradient-to-r from-rose-300 to-teal-600",
  "bg-gradient-to-r from-rose-300 to-teal-700",
  "bg-gradient-to-r from-rose-300 to-teal-800",
  "bg-gradient-to-r from-rose-300 to-teal-900",
  "bg-gradient-to-r from-rose-300 to-teal-950",
  "bg-gradient-to-r from-rose-300 to-cyan-300",
  "bg-gradient-to-r from-rose-300 to-cyan-400",
  "bg-gradient-to-r from-rose-300 to-cyan-500",
  "bg-gradient-to-r from-rose-300 to-cyan-600",
  "bg-gradient-to-r from-rose-300 to-cyan-700",
  "bg-gradient-to-r from-rose-300 to-cyan-800",
  "bg-gradient-to-r from-rose-300 to-cyan-900",
  "bg-gradient-to-r from-rose-300 to-cyan-950",
  "bg-gradient-to-r from-rose-300 to-sky-300",
  "bg-gradient-to-r from-rose-300 to-sky-400",
  "bg-gradient-to-r from-rose-300 to-sky-500",
  "bg-gradient-to-r from-rose-300 to-sky-600",
  "bg-gradient-to-r from-rose-300 to-sky-700",
  "bg-gradient-to-r from-rose-300 to-sky-800",
  "bg-gradient-to-r from-rose-300 to-sky-900",
  "bg-gradient-to-r from-rose-300 to-sky-950",
  "bg-gradient-to-r from-rose-300 to-blue-300",
  "bg-gradient-to-r from-rose-300 to-blue-400",
  "bg-gradient-to-r from-rose-300 to-blue-500",
  "bg-gradient-to-r from-rose-300 to-blue-600",
  "bg-gradient-to-r from-rose-300 to-blue-700",
  "bg-gradient-to-r from-rose-300 to-blue-800",
  "bg-gradient-to-r from-rose-300 to-blue-900",
  "bg-gradient-to-r from-rose-300 to-blue-950",
  "bg-gradient-to-r from-rose-300 to-indigo-300",
  "bg-gradient-to-r from-rose-300 to-indigo-400",
  "bg-gradient-to-r from-rose-300 to-indigo-500",
  "bg-gradient-to-r from-rose-300 to-indigo-600",
  "bg-gradient-to-r from-rose-300 to-indigo-700",
  "bg-gradient-to-r from-rose-300 to-indigo-800",
  "bg-gradient-to-r from-rose-300 to-indigo-900",
  "bg-gradient-to-r from-rose-300 to-indigo-950",
  "bg-gradient-to-r from-rose-300 to-violet-300",
  "bg-gradient-to-r from-rose-300 to-violet-400",
  "bg-gradient-to-r from-rose-300 to-violet-500",
  "bg-gradient-to-r from-rose-300 to-violet-600",
  "bg-gradient-to-r from-rose-300 to-violet-700",
  "bg-gradient-to-r from-rose-300 to-violet-800",
  "bg-gradient-to-r from-rose-300 to-violet-900",
  "bg-gradient-to-r from-rose-300 to-violet-950",
  "bg-gradient-to-r from-rose-300 to-purple-300",
  "bg-gradient-to-r from-rose-300 to-purple-400",
  "bg-gradient-to-r from-rose-300 to-purple-500",
  "bg-gradient-to-r from-rose-300 to-purple-600",
  "bg-gradient-to-r from-rose-300 to-purple-700",
  "bg-gradient-to-r from-rose-300 to-purple-800",
  "bg-gradient-to-r from-rose-300 to-purple-900",
  "bg-gradient-to-r from-rose-300 to-purple-950",
  "bg-gradient-to-r from-rose-300 to-fuchsia-300",
  "bg-gradient-to-r from-rose-300 to-fuchsia-400",
  "bg-gradient-to-r from-rose-300 to-fuchsia-500",
  "bg-gradient-to-r from-rose-300 to-fuchsia-600",
  "bg-gradient-to-r from-rose-300 to-fuchsia-700",
  "bg-gradient-to-r from-rose-300 to-fuchsia-800",
  "bg-gradient-to-r from-rose-300 to-fuchsia-900",
  "bg-gradient-to-r from-rose-300 to-fuchsia-950",
  "bg-gradient-to-r from-rose-300 to-pink-300",
  "bg-gradient-to-r from-rose-300 to-pink-400",
  "bg-gradient-to-r from-rose-300 to-pink-500",
  "bg-gradient-to-r from-rose-300 to-pink-600",
  "bg-gradient-to-r from-rose-300 to-pink-700",
  "bg-gradient-to-r from-rose-300 to-pink-800",
  "bg-gradient-to-r from-rose-300 to-pink-900",
  "bg-gradient-to-r from-rose-300 to-pink-950",
  "bg-gradient-to-r from-rose-400 to-red-300",
  "bg-gradient-to-r from-rose-400 to-red-400",
  "bg-gradient-to-r from-rose-400 to-red-500",
  "bg-gradient-to-r from-rose-400 to-red-600",
  "bg-gradient-to-r from-rose-400 to-red-700",
  "bg-gradient-to-r from-rose-400 to-red-800",
  "bg-gradient-to-r from-rose-400 to-red-900",
  "bg-gradient-to-r from-rose-400 to-red-950",
  "bg-gradient-to-r from-rose-400 to-orange-300",
  "bg-gradient-to-r from-rose-400 to-orange-400",
  "bg-gradient-to-r from-rose-400 to-orange-500",
  "bg-gradient-to-r from-rose-400 to-orange-600",
  "bg-gradient-to-r from-rose-400 to-orange-700",
  "bg-gradient-to-r from-rose-400 to-orange-800",
  "bg-gradient-to-r from-rose-400 to-orange-900",
  "bg-gradient-to-r from-rose-400 to-orange-950",
  "bg-gradient-to-r from-rose-400 to-amber-300",
  "bg-gradient-to-r from-rose-400 to-amber-400",
  "bg-gradient-to-r from-rose-400 to-amber-500",
  "bg-gradient-to-r from-rose-400 to-amber-600",
  "bg-gradient-to-r from-rose-400 to-amber-700",
  "bg-gradient-to-r from-rose-400 to-amber-800",
  "bg-gradient-to-r from-rose-400 to-amber-900",
  "bg-gradient-to-r from-rose-400 to-amber-950",
  "bg-gradient-to-r from-rose-400 to-yellow-300",
  "bg-gradient-to-r from-rose-400 to-yellow-400",
  "bg-gradient-to-r from-rose-400 to-yellow-500",
  "bg-gradient-to-r from-rose-400 to-yellow-600",
  "bg-gradient-to-r from-rose-400 to-yellow-700",
  "bg-gradient-to-r from-rose-400 to-yellow-800",
  "bg-gradient-to-r from-rose-400 to-yellow-900",
  "bg-gradient-to-r from-rose-400 to-yellow-950",
  "bg-gradient-to-r from-rose-400 to-lime-300",
  "bg-gradient-to-r from-rose-400 to-lime-400",
  "bg-gradient-to-r from-rose-400 to-lime-500",
  "bg-gradient-to-r from-rose-400 to-lime-600",
  "bg-gradient-to-r from-rose-400 to-lime-700",
  "bg-gradient-to-r from-rose-400 to-lime-800",
  "bg-gradient-to-r from-rose-400 to-lime-900",
  "bg-gradient-to-r from-rose-400 to-lime-950",
  "bg-gradient-to-r from-rose-400 to-green-300",
  "bg-gradient-to-r from-rose-400 to-green-400",
  "bg-gradient-to-r from-rose-400 to-green-500",
  "bg-gradient-to-r from-rose-400 to-green-600",
  "bg-gradient-to-r from-rose-400 to-green-700",
  "bg-gradient-to-r from-rose-400 to-green-800",
  "bg-gradient-to-r from-rose-400 to-green-900",
  "bg-gradient-to-r from-rose-400 to-green-950",
  "bg-gradient-to-r from-rose-400 to-emerald-300",
  "bg-gradient-to-r from-rose-400 to-emerald-400",
  "bg-gradient-to-r from-rose-400 to-emerald-500",
  "bg-gradient-to-r from-rose-400 to-emerald-600",
  "bg-gradient-to-r from-rose-400 to-emerald-700",
  "bg-gradient-to-r from-rose-400 to-emerald-800",
  "bg-gradient-to-r from-rose-400 to-emerald-900",
  "bg-gradient-to-r from-rose-400 to-emerald-950",
  "bg-gradient-to-r from-rose-400 to-teal-300",
  "bg-gradient-to-r from-rose-400 to-teal-400",
  "bg-gradient-to-r from-rose-400 to-teal-500",
  "bg-gradient-to-r from-rose-400 to-teal-600",
  "bg-gradient-to-r from-rose-400 to-teal-700",
  "bg-gradient-to-r from-rose-400 to-teal-800",
  "bg-gradient-to-r from-rose-400 to-teal-900",
  "bg-gradient-to-r from-rose-400 to-teal-950",
  "bg-gradient-to-r from-rose-400 to-cyan-300",
  "bg-gradient-to-r from-rose-400 to-cyan-400",
  "bg-gradient-to-r from-rose-400 to-cyan-500",
  "bg-gradient-to-r from-rose-400 to-cyan-600",
  "bg-gradient-to-r from-rose-400 to-cyan-700",
  "bg-gradient-to-r from-rose-400 to-cyan-800",
  "bg-gradient-to-r from-rose-400 to-cyan-900",
  "bg-gradient-to-r from-rose-400 to-cyan-950",
  "bg-gradient-to-r from-rose-400 to-sky-300",
  "bg-gradient-to-r from-rose-400 to-sky-400",
  "bg-gradient-to-r from-rose-400 to-sky-500",
  "bg-gradient-to-r from-rose-400 to-sky-600",
  "bg-gradient-to-r from-rose-400 to-sky-700",
  "bg-gradient-to-r from-rose-400 to-sky-800",
  "bg-gradient-to-r from-rose-400 to-sky-900",
  "bg-gradient-to-r from-rose-400 to-sky-950",
  "bg-gradient-to-r from-rose-400 to-blue-300",
  "bg-gradient-to-r from-rose-400 to-blue-400",
  "bg-gradient-to-r from-rose-400 to-blue-500",
  "bg-gradient-to-r from-rose-400 to-blue-600",
  "bg-gradient-to-r from-rose-400 to-blue-700",
  "bg-gradient-to-r from-rose-400 to-blue-800",
  "bg-gradient-to-r from-rose-400 to-blue-900",
  "bg-gradient-to-r from-rose-400 to-blue-950",
  "bg-gradient-to-r from-rose-400 to-indigo-300",
  "bg-gradient-to-r from-rose-400 to-indigo-400",
  "bg-gradient-to-r from-rose-400 to-indigo-500",
  "bg-gradient-to-r from-rose-400 to-indigo-600",
  "bg-gradient-to-r from-rose-400 to-indigo-700",
  "bg-gradient-to-r from-rose-400 to-indigo-800",
  "bg-gradient-to-r from-rose-400 to-indigo-900",
  "bg-gradient-to-r from-rose-400 to-indigo-950",
  "bg-gradient-to-r from-rose-400 to-violet-300",
  "bg-gradient-to-r from-rose-400 to-violet-400",
  "bg-gradient-to-r from-rose-400 to-violet-500",
  "bg-gradient-to-r from-rose-400 to-violet-600",
  "bg-gradient-to-r from-rose-400 to-violet-700",
  "bg-gradient-to-r from-rose-400 to-violet-800",
  "bg-gradient-to-r from-rose-400 to-violet-900",
  "bg-gradient-to-r from-rose-400 to-violet-950",
  "bg-gradient-to-r from-rose-400 to-purple-300",
  "bg-gradient-to-r from-rose-400 to-purple-400",
  "bg-gradient-to-r from-rose-400 to-purple-500",
  "bg-gradient-to-r from-rose-400 to-purple-600",
  "bg-gradient-to-r from-rose-400 to-purple-700",
  "bg-gradient-to-r from-rose-400 to-purple-800",
  "bg-gradient-to-r from-rose-400 to-purple-900",
  "bg-gradient-to-r from-rose-400 to-purple-950",
  "bg-gradient-to-r from-rose-400 to-fuchsia-300",
  "bg-gradient-to-r from-rose-400 to-fuchsia-400",
  "bg-gradient-to-r from-rose-400 to-fuchsia-500",
  "bg-gradient-to-r from-rose-400 to-fuchsia-600",
  "bg-gradient-to-r from-rose-400 to-fuchsia-700",
  "bg-gradient-to-r from-rose-400 to-fuchsia-800",
  "bg-gradient-to-r from-rose-400 to-fuchsia-900",
  "bg-gradient-to-r from-rose-400 to-fuchsia-950",
  "bg-gradient-to-r from-rose-400 to-pink-300",
  "bg-gradient-to-r from-rose-400 to-pink-400",
  "bg-gradient-to-r from-rose-400 to-pink-500",
  "bg-gradient-to-r from-rose-400 to-pink-600",
  "bg-gradient-to-r from-rose-400 to-pink-700",
  "bg-gradient-to-r from-rose-400 to-pink-800",
  "bg-gradient-to-r from-rose-400 to-pink-900",
  "bg-gradient-to-r from-rose-400 to-pink-950",
  "bg-gradient-to-r from-rose-500 to-red-300",
  "bg-gradient-to-r from-rose-500 to-red-400",
  "bg-gradient-to-r from-rose-500 to-red-500",
  "bg-gradient-to-r from-rose-500 to-red-600",
  "bg-gradient-to-r from-rose-500 to-red-700",
  "bg-gradient-to-r from-rose-500 to-red-800",
  "bg-gradient-to-r from-rose-500 to-red-900",
  "bg-gradient-to-r from-rose-500 to-red-950",
  "bg-gradient-to-r from-rose-500 to-orange-300",
  "bg-gradient-to-r from-rose-500 to-orange-400",
  "bg-gradient-to-r from-rose-500 to-orange-500",
  "bg-gradient-to-r from-rose-500 to-orange-600",
  "bg-gradient-to-r from-rose-500 to-orange-700",
  "bg-gradient-to-r from-rose-500 to-orange-800",
  "bg-gradient-to-r from-rose-500 to-orange-900",
  "bg-gradient-to-r from-rose-500 to-orange-950",
  "bg-gradient-to-r from-rose-500 to-amber-300",
  "bg-gradient-to-r from-rose-500 to-amber-400",
  "bg-gradient-to-r from-rose-500 to-amber-500",
  "bg-gradient-to-r from-rose-500 to-amber-600",
  "bg-gradient-to-r from-rose-500 to-amber-700",
  "bg-gradient-to-r from-rose-500 to-amber-800",
  "bg-gradient-to-r from-rose-500 to-amber-900",
  "bg-gradient-to-r from-rose-500 to-amber-950",
  "bg-gradient-to-r from-rose-500 to-yellow-300",
  "bg-gradient-to-r from-rose-500 to-yellow-400",
  "bg-gradient-to-r from-rose-500 to-yellow-500",
  "bg-gradient-to-r from-rose-500 to-yellow-600",
  "bg-gradient-to-r from-rose-500 to-yellow-700",
  "bg-gradient-to-r from-rose-500 to-yellow-800",
  "bg-gradient-to-r from-rose-500 to-yellow-900",
  "bg-gradient-to-r from-rose-500 to-yellow-950",
  "bg-gradient-to-r from-rose-500 to-lime-300",
  "bg-gradient-to-r from-rose-500 to-lime-400",
  "bg-gradient-to-r from-rose-500 to-lime-500",
  "bg-gradient-to-r from-rose-500 to-lime-600",
  "bg-gradient-to-r from-rose-500 to-lime-700",
  "bg-gradient-to-r from-rose-500 to-lime-800",
  "bg-gradient-to-r from-rose-500 to-lime-900",
  "bg-gradient-to-r from-rose-500 to-lime-950",
  "bg-gradient-to-r from-rose-500 to-green-300",
  "bg-gradient-to-r from-rose-500 to-green-400",
  "bg-gradient-to-r from-rose-500 to-green-500",
  "bg-gradient-to-r from-rose-500 to-green-600",
  "bg-gradient-to-r from-rose-500 to-green-700",
  "bg-gradient-to-r from-rose-500 to-green-800",
  "bg-gradient-to-r from-rose-500 to-green-900",
  "bg-gradient-to-r from-rose-500 to-green-950",
  "bg-gradient-to-r from-rose-500 to-emerald-300",
  "bg-gradient-to-r from-rose-500 to-emerald-400",
  "bg-gradient-to-r from-rose-500 to-emerald-500",
  "bg-gradient-to-r from-rose-500 to-emerald-600",
  "bg-gradient-to-r from-rose-500 to-emerald-700",
  "bg-gradient-to-r from-rose-500 to-emerald-800",
  "bg-gradient-to-r from-rose-500 to-emerald-900",
  "bg-gradient-to-r from-rose-500 to-emerald-950",
  "bg-gradient-to-r from-rose-500 to-teal-300",
  "bg-gradient-to-r from-rose-500 to-teal-400",
  "bg-gradient-to-r from-rose-500 to-teal-500",
  "bg-gradient-to-r from-rose-500 to-teal-600",
  "bg-gradient-to-r from-rose-500 to-teal-700",
  "bg-gradient-to-r from-rose-500 to-teal-800",
  "bg-gradient-to-r from-rose-500 to-teal-900",
  "bg-gradient-to-r from-rose-500 to-teal-950",
  "bg-gradient-to-r from-rose-500 to-cyan-300",
  "bg-gradient-to-r from-rose-500 to-cyan-400",
  "bg-gradient-to-r from-rose-500 to-cyan-500",
  "bg-gradient-to-r from-rose-500 to-cyan-600",
  "bg-gradient-to-r from-rose-500 to-cyan-700",
  "bg-gradient-to-r from-rose-500 to-cyan-800",
  "bg-gradient-to-r from-rose-500 to-cyan-900",
  "bg-gradient-to-r from-rose-500 to-cyan-950",
  "bg-gradient-to-r from-rose-500 to-sky-300",
  "bg-gradient-to-r from-rose-500 to-sky-400",
  "bg-gradient-to-r from-rose-500 to-sky-500",
  "bg-gradient-to-r from-rose-500 to-sky-600",
  "bg-gradient-to-r from-rose-500 to-sky-700",
  "bg-gradient-to-r from-rose-500 to-sky-800",
  "bg-gradient-to-r from-rose-500 to-sky-900",
  "bg-gradient-to-r from-rose-500 to-sky-950",
  "bg-gradient-to-r from-rose-500 to-blue-300",
  "bg-gradient-to-r from-rose-500 to-blue-400",
  "bg-gradient-to-r from-rose-500 to-blue-500",
  "bg-gradient-to-r from-rose-500 to-blue-600",
  "bg-gradient-to-r from-rose-500 to-blue-700",
  "bg-gradient-to-r from-rose-500 to-blue-800",
  "bg-gradient-to-r from-rose-500 to-blue-900",
  "bg-gradient-to-r from-rose-500 to-blue-950",
  "bg-gradient-to-r from-rose-500 to-indigo-300",
  "bg-gradient-to-r from-rose-500 to-indigo-400",
  "bg-gradient-to-r from-rose-500 to-indigo-500",
  "bg-gradient-to-r from-rose-500 to-indigo-600",
  "bg-gradient-to-r from-rose-500 to-indigo-700",
  "bg-gradient-to-r from-rose-500 to-indigo-800",
  "bg-gradient-to-r from-rose-500 to-indigo-900",
  "bg-gradient-to-r from-rose-500 to-indigo-950",
  "bg-gradient-to-r from-rose-500 to-violet-300",
  "bg-gradient-to-r from-rose-500 to-violet-400",
  "bg-gradient-to-r from-rose-500 to-violet-500",
  "bg-gradient-to-r from-rose-500 to-violet-600",
  "bg-gradient-to-r from-rose-500 to-violet-700",
  "bg-gradient-to-r from-rose-500 to-violet-800",
  "bg-gradient-to-r from-rose-500 to-violet-900",
  "bg-gradient-to-r from-rose-500 to-violet-950",
  "bg-gradient-to-r from-rose-500 to-purple-300",
  "bg-gradient-to-r from-rose-500 to-purple-400",
  "bg-gradient-to-r from-rose-500 to-purple-500",
  "bg-gradient-to-r from-rose-500 to-purple-600",
  "bg-gradient-to-r from-rose-500 to-purple-700",
  "bg-gradient-to-r from-rose-500 to-purple-800",
  "bg-gradient-to-r from-rose-500 to-purple-900",
  "bg-gradient-to-r from-rose-500 to-purple-950",
  "bg-gradient-to-r from-rose-500 to-fuchsia-300",
  "bg-gradient-to-r from-rose-500 to-fuchsia-400",
  "bg-gradient-to-r from-rose-500 to-fuchsia-500",
  "bg-gradient-to-r from-rose-500 to-fuchsia-600",
  "bg-gradient-to-r from-rose-500 to-fuchsia-700",
  "bg-gradient-to-r from-rose-500 to-fuchsia-800",
  "bg-gradient-to-r from-rose-500 to-fuchsia-900",
  "bg-gradient-to-r from-rose-500 to-fuchsia-950",
  "bg-gradient-to-r from-rose-500 to-pink-300",
  "bg-gradient-to-r from-rose-500 to-pink-400",
  "bg-gradient-to-r from-rose-500 to-pink-500",
  "bg-gradient-to-r from-rose-500 to-pink-600",
  "bg-gradient-to-r from-rose-500 to-pink-700",
  "bg-gradient-to-r from-rose-500 to-pink-800",
  "bg-gradient-to-r from-rose-500 to-pink-900",
  "bg-gradient-to-r from-rose-500 to-pink-950",
  "bg-gradient-to-r from-rose-600 to-red-300",
  "bg-gradient-to-r from-rose-600 to-red-400",
  "bg-gradient-to-r from-rose-600 to-red-500",
  "bg-gradient-to-r from-rose-600 to-red-600",
  "bg-gradient-to-r from-rose-600 to-red-700",
  "bg-gradient-to-r from-rose-600 to-red-800",
  "bg-gradient-to-r from-rose-600 to-red-900",
  "bg-gradient-to-r from-rose-600 to-red-950",
  "bg-gradient-to-r from-rose-600 to-orange-300",
  "bg-gradient-to-r from-rose-600 to-orange-400",
  "bg-gradient-to-r from-rose-600 to-orange-500",
  "bg-gradient-to-r from-rose-600 to-orange-600",
  "bg-gradient-to-r from-rose-600 to-orange-700",
  "bg-gradient-to-r from-rose-600 to-orange-800",
  "bg-gradient-to-r from-rose-600 to-orange-900",
  "bg-gradient-to-r from-rose-600 to-orange-950",
  "bg-gradient-to-r from-rose-600 to-amber-300",
  "bg-gradient-to-r from-rose-600 to-amber-400",
  "bg-gradient-to-r from-rose-600 to-amber-500",
  "bg-gradient-to-r from-rose-600 to-amber-600",
  "bg-gradient-to-r from-rose-600 to-amber-700",
  "bg-gradient-to-r from-rose-600 to-amber-800",
  "bg-gradient-to-r from-rose-600 to-amber-900",
  "bg-gradient-to-r from-rose-600 to-amber-950",
  "bg-gradient-to-r from-rose-600 to-yellow-300",
  "bg-gradient-to-r from-rose-600 to-yellow-400",
  "bg-gradient-to-r from-rose-600 to-yellow-500",
  "bg-gradient-to-r from-rose-600 to-yellow-600",
  "bg-gradient-to-r from-rose-600 to-yellow-700",
  "bg-gradient-to-r from-rose-600 to-yellow-800",
  "bg-gradient-to-r from-rose-600 to-yellow-900",
  "bg-gradient-to-r from-rose-600 to-yellow-950",
  "bg-gradient-to-r from-rose-600 to-lime-300",
  "bg-gradient-to-r from-rose-600 to-lime-400",
  "bg-gradient-to-r from-rose-600 to-lime-500",
  "bg-gradient-to-r from-rose-600 to-lime-600",
  "bg-gradient-to-r from-rose-600 to-lime-700",
  "bg-gradient-to-r from-rose-600 to-lime-800",
  "bg-gradient-to-r from-rose-600 to-lime-900",
  "bg-gradient-to-r from-rose-600 to-lime-950",
  "bg-gradient-to-r from-rose-600 to-green-300",
  "bg-gradient-to-r from-rose-600 to-green-400",
  "bg-gradient-to-r from-rose-600 to-green-500",
  "bg-gradient-to-r from-rose-600 to-green-600",
  "bg-gradient-to-r from-rose-600 to-green-700",
  "bg-gradient-to-r from-rose-600 to-green-800",
  "bg-gradient-to-r from-rose-600 to-green-900",
  "bg-gradient-to-r from-rose-600 to-green-950",
  "bg-gradient-to-r from-rose-600 to-emerald-300",
  "bg-gradient-to-r from-rose-600 to-emerald-400",
  "bg-gradient-to-r from-rose-600 to-emerald-500",
  "bg-gradient-to-r from-rose-600 to-emerald-600",
  "bg-gradient-to-r from-rose-600 to-emerald-700",
  "bg-gradient-to-r from-rose-600 to-emerald-800",
  "bg-gradient-to-r from-rose-600 to-emerald-900",
  "bg-gradient-to-r from-rose-600 to-emerald-950",
  "bg-gradient-to-r from-rose-600 to-teal-300",
  "bg-gradient-to-r from-rose-600 to-teal-400",
  "bg-gradient-to-r from-rose-600 to-teal-500",
  "bg-gradient-to-r from-rose-600 to-teal-600",
  "bg-gradient-to-r from-rose-600 to-teal-700",
  "bg-gradient-to-r from-rose-600 to-teal-800",
  "bg-gradient-to-r from-rose-600 to-teal-900",
  "bg-gradient-to-r from-rose-600 to-teal-950",
  "bg-gradient-to-r from-rose-600 to-cyan-300",
  "bg-gradient-to-r from-rose-600 to-cyan-400",
  "bg-gradient-to-r from-rose-600 to-cyan-500",
  "bg-gradient-to-r from-rose-600 to-cyan-600",
  "bg-gradient-to-r from-rose-600 to-cyan-700",
  "bg-gradient-to-r from-rose-600 to-cyan-800",
  "bg-gradient-to-r from-rose-600 to-cyan-900",
  "bg-gradient-to-r from-rose-600 to-cyan-950",
  "bg-gradient-to-r from-rose-600 to-sky-300",
  "bg-gradient-to-r from-rose-600 to-sky-400",
  "bg-gradient-to-r from-rose-600 to-sky-500",
  "bg-gradient-to-r from-rose-600 to-sky-600",
  "bg-gradient-to-r from-rose-600 to-sky-700",
  "bg-gradient-to-r from-rose-600 to-sky-800",
  "bg-gradient-to-r from-rose-600 to-sky-900",
  "bg-gradient-to-r from-rose-600 to-sky-950",
  "bg-gradient-to-r from-rose-600 to-blue-300",
  "bg-gradient-to-r from-rose-600 to-blue-400",
  "bg-gradient-to-r from-rose-600 to-blue-500",
  "bg-gradient-to-r from-rose-600 to-blue-600",
  "bg-gradient-to-r from-rose-600 to-blue-700",
  "bg-gradient-to-r from-rose-600 to-blue-800",
  "bg-gradient-to-r from-rose-600 to-blue-900",
  "bg-gradient-to-r from-rose-600 to-blue-950",
  "bg-gradient-to-r from-rose-600 to-indigo-300",
  "bg-gradient-to-r from-rose-600 to-indigo-400",
  "bg-gradient-to-r from-rose-600 to-indigo-500",
  "bg-gradient-to-r from-rose-600 to-indigo-600",
  "bg-gradient-to-r from-rose-600 to-indigo-700",
  "bg-gradient-to-r from-rose-600 to-indigo-800",
  "bg-gradient-to-r from-rose-600 to-indigo-900",
  "bg-gradient-to-r from-rose-600 to-indigo-950",
  "bg-gradient-to-r from-rose-600 to-violet-300",
  "bg-gradient-to-r from-rose-600 to-violet-400",
  "bg-gradient-to-r from-rose-600 to-violet-500",
  "bg-gradient-to-r from-rose-600 to-violet-600",
  "bg-gradient-to-r from-rose-600 to-violet-700",
  "bg-gradient-to-r from-rose-600 to-violet-800",
  "bg-gradient-to-r from-rose-600 to-violet-900",
  "bg-gradient-to-r from-rose-600 to-violet-950",
  "bg-gradient-to-r from-rose-600 to-purple-300",
  "bg-gradient-to-r from-rose-600 to-purple-400",
  "bg-gradient-to-r from-rose-600 to-purple-500",
  "bg-gradient-to-r from-rose-600 to-purple-600",
  "bg-gradient-to-r from-rose-600 to-purple-700",
  "bg-gradient-to-r from-rose-600 to-purple-800",
  "bg-gradient-to-r from-rose-600 to-purple-900",
  "bg-gradient-to-r from-rose-600 to-purple-950",
  "bg-gradient-to-r from-rose-600 to-fuchsia-300",
  "bg-gradient-to-r from-rose-600 to-fuchsia-400",
  "bg-gradient-to-r from-rose-600 to-fuchsia-500",
  "bg-gradient-to-r from-rose-600 to-fuchsia-600",
  "bg-gradient-to-r from-rose-600 to-fuchsia-700",
  "bg-gradient-to-r from-rose-600 to-fuchsia-800",
  "bg-gradient-to-r from-rose-600 to-fuchsia-900",
  "bg-gradient-to-r from-rose-600 to-fuchsia-950",
  "bg-gradient-to-r from-rose-600 to-pink-300",
  "bg-gradient-to-r from-rose-600 to-pink-400",
  "bg-gradient-to-r from-rose-600 to-pink-500",
  "bg-gradient-to-r from-rose-600 to-pink-600",
  "bg-gradient-to-r from-rose-600 to-pink-700",
  "bg-gradient-to-r from-rose-600 to-pink-800",
  "bg-gradient-to-r from-rose-600 to-pink-900",
  "bg-gradient-to-r from-rose-600 to-pink-950",
  "bg-gradient-to-r from-rose-700 to-red-300",
  "bg-gradient-to-r from-rose-700 to-red-400",
  "bg-gradient-to-r from-rose-700 to-red-500",
  "bg-gradient-to-r from-rose-700 to-red-600",
  "bg-gradient-to-r from-rose-700 to-red-700",
  "bg-gradient-to-r from-rose-700 to-red-800",
  "bg-gradient-to-r from-rose-700 to-red-900",
  "bg-gradient-to-r from-rose-700 to-red-950",
  "bg-gradient-to-r from-rose-700 to-orange-300",
  "bg-gradient-to-r from-rose-700 to-orange-400",
  "bg-gradient-to-r from-rose-700 to-orange-500",
  "bg-gradient-to-r from-rose-700 to-orange-600",
  "bg-gradient-to-r from-rose-700 to-orange-700",
  "bg-gradient-to-r from-rose-700 to-orange-800",
  "bg-gradient-to-r from-rose-700 to-orange-900",
  "bg-gradient-to-r from-rose-700 to-orange-950",
  "bg-gradient-to-r from-rose-700 to-amber-300",
  "bg-gradient-to-r from-rose-700 to-amber-400",
  "bg-gradient-to-r from-rose-700 to-amber-500",
  "bg-gradient-to-r from-rose-700 to-amber-600",
  "bg-gradient-to-r from-rose-700 to-amber-700",
  "bg-gradient-to-r from-rose-700 to-amber-800",
  "bg-gradient-to-r from-rose-700 to-amber-900",
  "bg-gradient-to-r from-rose-700 to-amber-950",
  "bg-gradient-to-r from-rose-700 to-yellow-300",
  "bg-gradient-to-r from-rose-700 to-yellow-400",
  "bg-gradient-to-r from-rose-700 to-yellow-500",
  "bg-gradient-to-r from-rose-700 to-yellow-600",
  "bg-gradient-to-r from-rose-700 to-yellow-700",
  "bg-gradient-to-r from-rose-700 to-yellow-800",
  "bg-gradient-to-r from-rose-700 to-yellow-900",
  "bg-gradient-to-r from-rose-700 to-yellow-950",
  "bg-gradient-to-r from-rose-700 to-lime-300",
  "bg-gradient-to-r from-rose-700 to-lime-400",
  "bg-gradient-to-r from-rose-700 to-lime-500",
  "bg-gradient-to-r from-rose-700 to-lime-600",
  "bg-gradient-to-r from-rose-700 to-lime-700",
  "bg-gradient-to-r from-rose-700 to-lime-800",
  "bg-gradient-to-r from-rose-700 to-lime-900",
  "bg-gradient-to-r from-rose-700 to-lime-950",
  "bg-gradient-to-r from-rose-700 to-green-300",
  "bg-gradient-to-r from-rose-700 to-green-400",
  "bg-gradient-to-r from-rose-700 to-green-500",
  "bg-gradient-to-r from-rose-700 to-green-600",
  "bg-gradient-to-r from-rose-700 to-green-700",
  "bg-gradient-to-r from-rose-700 to-green-800",
  "bg-gradient-to-r from-rose-700 to-green-900",
  "bg-gradient-to-r from-rose-700 to-green-950",
  "bg-gradient-to-r from-rose-700 to-emerald-300",
  "bg-gradient-to-r from-rose-700 to-emerald-400",
  "bg-gradient-to-r from-rose-700 to-emerald-500",
  "bg-gradient-to-r from-rose-700 to-emerald-600",
  "bg-gradient-to-r from-rose-700 to-emerald-700",
  "bg-gradient-to-r from-rose-700 to-emerald-800",
  "bg-gradient-to-r from-rose-700 to-emerald-900",
  "bg-gradient-to-r from-rose-700 to-emerald-950",
  "bg-gradient-to-r from-rose-700 to-teal-300",
  "bg-gradient-to-r from-rose-700 to-teal-400",
  "bg-gradient-to-r from-rose-700 to-teal-500",
  "bg-gradient-to-r from-rose-700 to-teal-600",
  "bg-gradient-to-r from-rose-700 to-teal-700",
  "bg-gradient-to-r from-rose-700 to-teal-800",
  "bg-gradient-to-r from-rose-700 to-teal-900",
  "bg-gradient-to-r from-rose-700 to-teal-950",
  "bg-gradient-to-r from-rose-700 to-cyan-300",
  "bg-gradient-to-r from-rose-700 to-cyan-400",
  "bg-gradient-to-r from-rose-700 to-cyan-500",
  "bg-gradient-to-r from-rose-700 to-cyan-600",
  "bg-gradient-to-r from-rose-700 to-cyan-700",
  "bg-gradient-to-r from-rose-700 to-cyan-800",
  "bg-gradient-to-r from-rose-700 to-cyan-900",
  "bg-gradient-to-r from-rose-700 to-cyan-950",
  "bg-gradient-to-r from-rose-700 to-sky-300",
  "bg-gradient-to-r from-rose-700 to-sky-400",
  "bg-gradient-to-r from-rose-700 to-sky-500",
  "bg-gradient-to-r from-rose-700 to-sky-600",
  "bg-gradient-to-r from-rose-700 to-sky-700",
  "bg-gradient-to-r from-rose-700 to-sky-800",
  "bg-gradient-to-r from-rose-700 to-sky-900",
  "bg-gradient-to-r from-rose-700 to-sky-950",
  "bg-gradient-to-r from-rose-700 to-blue-300",
  "bg-gradient-to-r from-rose-700 to-blue-400",
  "bg-gradient-to-r from-rose-700 to-blue-500",
  "bg-gradient-to-r from-rose-700 to-blue-600",
  "bg-gradient-to-r from-rose-700 to-blue-700",
  "bg-gradient-to-r from-rose-700 to-blue-800",
  "bg-gradient-to-r from-rose-700 to-blue-900",
  "bg-gradient-to-r from-rose-700 to-blue-950",
  "bg-gradient-to-r from-rose-700 to-indigo-300",
  "bg-gradient-to-r from-rose-700 to-indigo-400",
  "bg-gradient-to-r from-rose-700 to-indigo-500",
  "bg-gradient-to-r from-rose-700 to-indigo-600",
  "bg-gradient-to-r from-rose-700 to-indigo-700",
  "bg-gradient-to-r from-rose-700 to-indigo-800",
  "bg-gradient-to-r from-rose-700 to-indigo-900",
  "bg-gradient-to-r from-rose-700 to-indigo-950",
  "bg-gradient-to-r from-rose-700 to-violet-300",
  "bg-gradient-to-r from-rose-700 to-violet-400",
  "bg-gradient-to-r from-rose-700 to-violet-500",
  "bg-gradient-to-r from-rose-700 to-violet-600",
  "bg-gradient-to-r from-rose-700 to-violet-700",
  "bg-gradient-to-r from-rose-700 to-violet-800",
  "bg-gradient-to-r from-rose-700 to-violet-900",
  "bg-gradient-to-r from-rose-700 to-violet-950",
  "bg-gradient-to-r from-rose-700 to-purple-300",
  "bg-gradient-to-r from-rose-700 to-purple-400",
  "bg-gradient-to-r from-rose-700 to-purple-500",
  "bg-gradient-to-r from-rose-700 to-purple-600",
  "bg-gradient-to-r from-rose-700 to-purple-700",
  "bg-gradient-to-r from-rose-700 to-purple-800",
  "bg-gradient-to-r from-rose-700 to-purple-900",
  "bg-gradient-to-r from-rose-700 to-purple-950",
  "bg-gradient-to-r from-rose-700 to-fuchsia-300",
  "bg-gradient-to-r from-rose-700 to-fuchsia-400",
  "bg-gradient-to-r from-rose-700 to-fuchsia-500",
  "bg-gradient-to-r from-rose-700 to-fuchsia-600",
  "bg-gradient-to-r from-rose-700 to-fuchsia-700",
  "bg-gradient-to-r from-rose-700 to-fuchsia-800",
  "bg-gradient-to-r from-rose-700 to-fuchsia-900",
  "bg-gradient-to-r from-rose-700 to-fuchsia-950",
  "bg-gradient-to-r from-rose-700 to-pink-300",
  "bg-gradient-to-r from-rose-700 to-pink-400",
  "bg-gradient-to-r from-rose-700 to-pink-500",
  "bg-gradient-to-r from-rose-700 to-pink-600",
  "bg-gradient-to-r from-rose-700 to-pink-700",
  "bg-gradient-to-r from-rose-700 to-pink-800",
  "bg-gradient-to-r from-rose-700 to-pink-900",
  "bg-gradient-to-r from-rose-700 to-pink-950",
  "bg-gradient-to-r from-rose-800 to-red-300",
  "bg-gradient-to-r from-rose-800 to-red-400",
  "bg-gradient-to-r from-rose-800 to-red-500",
  "bg-gradient-to-r from-rose-800 to-red-600",
  "bg-gradient-to-r from-rose-800 to-red-700",
  "bg-gradient-to-r from-rose-800 to-red-800",
  "bg-gradient-to-r from-rose-800 to-red-900",
  "bg-gradient-to-r from-rose-800 to-red-950",
  "bg-gradient-to-r from-rose-800 to-orange-300",
  "bg-gradient-to-r from-rose-800 to-orange-400",
  "bg-gradient-to-r from-rose-800 to-orange-500",
  "bg-gradient-to-r from-rose-800 to-orange-600",
  "bg-gradient-to-r from-rose-800 to-orange-700",
  "bg-gradient-to-r from-rose-800 to-orange-800",
  "bg-gradient-to-r from-rose-800 to-orange-900",
  "bg-gradient-to-r from-rose-800 to-orange-950",
  "bg-gradient-to-r from-rose-800 to-amber-300",
  "bg-gradient-to-r from-rose-800 to-amber-400",
  "bg-gradient-to-r from-rose-800 to-amber-500",
  "bg-gradient-to-r from-rose-800 to-amber-600",
  "bg-gradient-to-r from-rose-800 to-amber-700",
  "bg-gradient-to-r from-rose-800 to-amber-800",
  "bg-gradient-to-r from-rose-800 to-amber-900",
  "bg-gradient-to-r from-rose-800 to-amber-950",
  "bg-gradient-to-r from-rose-800 to-yellow-300",
  "bg-gradient-to-r from-rose-800 to-yellow-400",
  "bg-gradient-to-r from-rose-800 to-yellow-500",
  "bg-gradient-to-r from-rose-800 to-yellow-600",
  "bg-gradient-to-r from-rose-800 to-yellow-700",
  "bg-gradient-to-r from-rose-800 to-yellow-800",
  "bg-gradient-to-r from-rose-800 to-yellow-900",
  "bg-gradient-to-r from-rose-800 to-yellow-950",
  "bg-gradient-to-r from-rose-800 to-lime-300",
  "bg-gradient-to-r from-rose-800 to-lime-400",
  "bg-gradient-to-r from-rose-800 to-lime-500",
  "bg-gradient-to-r from-rose-800 to-lime-600",
  "bg-gradient-to-r from-rose-800 to-lime-700",
  "bg-gradient-to-r from-rose-800 to-lime-800",
  "bg-gradient-to-r from-rose-800 to-lime-900",
  "bg-gradient-to-r from-rose-800 to-lime-950",
  "bg-gradient-to-r from-rose-800 to-green-300",
  "bg-gradient-to-r from-rose-800 to-green-400",
  "bg-gradient-to-r from-rose-800 to-green-500",
  "bg-gradient-to-r from-rose-800 to-green-600",
  "bg-gradient-to-r from-rose-800 to-green-700",
  "bg-gradient-to-r from-rose-800 to-green-800",
  "bg-gradient-to-r from-rose-800 to-green-900",
  "bg-gradient-to-r from-rose-800 to-green-950",
  "bg-gradient-to-r from-rose-800 to-emerald-300",
  "bg-gradient-to-r from-rose-800 to-emerald-400",
  "bg-gradient-to-r from-rose-800 to-emerald-500",
  "bg-gradient-to-r from-rose-800 to-emerald-600",
  "bg-gradient-to-r from-rose-800 to-emerald-700",
  "bg-gradient-to-r from-rose-800 to-emerald-800",
  "bg-gradient-to-r from-rose-800 to-emerald-900",
  "bg-gradient-to-r from-rose-800 to-emerald-950",
  "bg-gradient-to-r from-rose-800 to-teal-300",
  "bg-gradient-to-r from-rose-800 to-teal-400",
  "bg-gradient-to-r from-rose-800 to-teal-500",
  "bg-gradient-to-r from-rose-800 to-teal-600",
  "bg-gradient-to-r from-rose-800 to-teal-700",
  "bg-gradient-to-r from-rose-800 to-teal-800",
  "bg-gradient-to-r from-rose-800 to-teal-900",
  "bg-gradient-to-r from-rose-800 to-teal-950",
  "bg-gradient-to-r from-rose-800 to-cyan-300",
  "bg-gradient-to-r from-rose-800 to-cyan-400",
  "bg-gradient-to-r from-rose-800 to-cyan-500",
  "bg-gradient-to-r from-rose-800 to-cyan-600",
  "bg-gradient-to-r from-rose-800 to-cyan-700",
  "bg-gradient-to-r from-rose-800 to-cyan-800",
  "bg-gradient-to-r from-rose-800 to-cyan-900",
  "bg-gradient-to-r from-rose-800 to-cyan-950",
  "bg-gradient-to-r from-rose-800 to-sky-300",
  "bg-gradient-to-r from-rose-800 to-sky-400",
  "bg-gradient-to-r from-rose-800 to-sky-500",
  "bg-gradient-to-r from-rose-800 to-sky-600",
  "bg-gradient-to-r from-rose-800 to-sky-700",
  "bg-gradient-to-r from-rose-800 to-sky-800",
  "bg-gradient-to-r from-rose-800 to-sky-900",
  "bg-gradient-to-r from-rose-800 to-sky-950",
  "bg-gradient-to-r from-rose-800 to-blue-300",
  "bg-gradient-to-r from-rose-800 to-blue-400",
  "bg-gradient-to-r from-rose-800 to-blue-500",
  "bg-gradient-to-r from-rose-800 to-blue-600",
  "bg-gradient-to-r from-rose-800 to-blue-700",
  "bg-gradient-to-r from-rose-800 to-blue-800",
  "bg-gradient-to-r from-rose-800 to-blue-900",
  "bg-gradient-to-r from-rose-800 to-blue-950",
  "bg-gradient-to-r from-rose-800 to-indigo-300",
  "bg-gradient-to-r from-rose-800 to-indigo-400",
  "bg-gradient-to-r from-rose-800 to-indigo-500",
  "bg-gradient-to-r from-rose-800 to-indigo-600",
  "bg-gradient-to-r from-rose-800 to-indigo-700",
  "bg-gradient-to-r from-rose-800 to-indigo-800",
  "bg-gradient-to-r from-rose-800 to-indigo-900",
  "bg-gradient-to-r from-rose-800 to-indigo-950",
  "bg-gradient-to-r from-rose-800 to-violet-300",
  "bg-gradient-to-r from-rose-800 to-violet-400",
  "bg-gradient-to-r from-rose-800 to-violet-500",
  "bg-gradient-to-r from-rose-800 to-violet-600",
  "bg-gradient-to-r from-rose-800 to-violet-700",
  "bg-gradient-to-r from-rose-800 to-violet-800",
  "bg-gradient-to-r from-rose-800 to-violet-900",
  "bg-gradient-to-r from-rose-800 to-violet-950",
  "bg-gradient-to-r from-rose-800 to-purple-300",
  "bg-gradient-to-r from-rose-800 to-purple-400",
  "bg-gradient-to-r from-rose-800 to-purple-500",
  "bg-gradient-to-r from-rose-800 to-purple-600",
  "bg-gradient-to-r from-rose-800 to-purple-700",
  "bg-gradient-to-r from-rose-800 to-purple-800",
  "bg-gradient-to-r from-rose-800 to-purple-900",
  "bg-gradient-to-r from-rose-800 to-purple-950",
  "bg-gradient-to-r from-rose-800 to-fuchsia-300",
  "bg-gradient-to-r from-rose-800 to-fuchsia-400",
  "bg-gradient-to-r from-rose-800 to-fuchsia-500",
  "bg-gradient-to-r from-rose-800 to-fuchsia-600",
  "bg-gradient-to-r from-rose-800 to-fuchsia-700",
  "bg-gradient-to-r from-rose-800 to-fuchsia-800",
  "bg-gradient-to-r from-rose-800 to-fuchsia-900",
  "bg-gradient-to-r from-rose-800 to-fuchsia-950",
  "bg-gradient-to-r from-rose-800 to-pink-300",
  "bg-gradient-to-r from-rose-800 to-pink-400",
  "bg-gradient-to-r from-rose-800 to-pink-500",
  "bg-gradient-to-r from-rose-800 to-pink-600",
  "bg-gradient-to-r from-rose-800 to-pink-700",
  "bg-gradient-to-r from-rose-800 to-pink-800",
  "bg-gradient-to-r from-rose-800 to-pink-900",
  "bg-gradient-to-r from-rose-800 to-pink-950",
  "bg-gradient-to-r from-rose-900 to-red-300",
  "bg-gradient-to-r from-rose-900 to-red-400",
  "bg-gradient-to-r from-rose-900 to-red-500",
  "bg-gradient-to-r from-rose-900 to-red-600",
  "bg-gradient-to-r from-rose-900 to-red-700",
  "bg-gradient-to-r from-rose-900 to-red-800",
  "bg-gradient-to-r from-rose-900 to-red-900",
  "bg-gradient-to-r from-rose-900 to-red-950",
  "bg-gradient-to-r from-rose-900 to-orange-300",
  "bg-gradient-to-r from-rose-900 to-orange-400",
  "bg-gradient-to-r from-rose-900 to-orange-500",
  "bg-gradient-to-r from-rose-900 to-orange-600",
  "bg-gradient-to-r from-rose-900 to-orange-700",
  "bg-gradient-to-r from-rose-900 to-orange-800",
  "bg-gradient-to-r from-rose-900 to-orange-900",
  "bg-gradient-to-r from-rose-900 to-orange-950",
  "bg-gradient-to-r from-rose-900 to-amber-300",
  "bg-gradient-to-r from-rose-900 to-amber-400",
  "bg-gradient-to-r from-rose-900 to-amber-500",
  "bg-gradient-to-r from-rose-900 to-amber-600",
  "bg-gradient-to-r from-rose-900 to-amber-700",
  "bg-gradient-to-r from-rose-900 to-amber-800",
  "bg-gradient-to-r from-rose-900 to-amber-900",
  "bg-gradient-to-r from-rose-900 to-amber-950",
  "bg-gradient-to-r from-rose-900 to-yellow-300",
  "bg-gradient-to-r from-rose-900 to-yellow-400",
  "bg-gradient-to-r from-rose-900 to-yellow-500",
  "bg-gradient-to-r from-rose-900 to-yellow-600",
  "bg-gradient-to-r from-rose-900 to-yellow-700",
  "bg-gradient-to-r from-rose-900 to-yellow-800",
  "bg-gradient-to-r from-rose-900 to-yellow-900",
  "bg-gradient-to-r from-rose-900 to-yellow-950",
  "bg-gradient-to-r from-rose-900 to-lime-300",
  "bg-gradient-to-r from-rose-900 to-lime-400",
  "bg-gradient-to-r from-rose-900 to-lime-500",
  "bg-gradient-to-r from-rose-900 to-lime-600",
  "bg-gradient-to-r from-rose-900 to-lime-700",
  "bg-gradient-to-r from-rose-900 to-lime-800",
  "bg-gradient-to-r from-rose-900 to-lime-900",
  "bg-gradient-to-r from-rose-900 to-lime-950",
  "bg-gradient-to-r from-rose-900 to-green-300",
  "bg-gradient-to-r from-rose-900 to-green-400",
  "bg-gradient-to-r from-rose-900 to-green-500",
  "bg-gradient-to-r from-rose-900 to-green-600",
  "bg-gradient-to-r from-rose-900 to-green-700",
  "bg-gradient-to-r from-rose-900 to-green-800",
  "bg-gradient-to-r from-rose-900 to-green-900",
  "bg-gradient-to-r from-rose-900 to-green-950",
  "bg-gradient-to-r from-rose-900 to-emerald-300",
  "bg-gradient-to-r from-rose-900 to-emerald-400",
  "bg-gradient-to-r from-rose-900 to-emerald-500",
  "bg-gradient-to-r from-rose-900 to-emerald-600",
  "bg-gradient-to-r from-rose-900 to-emerald-700",
  "bg-gradient-to-r from-rose-900 to-emerald-800",
  "bg-gradient-to-r from-rose-900 to-emerald-900",
  "bg-gradient-to-r from-rose-900 to-emerald-950",
  "bg-gradient-to-r from-rose-900 to-teal-300",
  "bg-gradient-to-r from-rose-900 to-teal-400",
  "bg-gradient-to-r from-rose-900 to-teal-500",
  "bg-gradient-to-r from-rose-900 to-teal-600",
  "bg-gradient-to-r from-rose-900 to-teal-700",
  "bg-gradient-to-r from-rose-900 to-teal-800",
  "bg-gradient-to-r from-rose-900 to-teal-900",
  "bg-gradient-to-r from-rose-900 to-teal-950",
  "bg-gradient-to-r from-rose-900 to-cyan-300",
  "bg-gradient-to-r from-rose-900 to-cyan-400",
  "bg-gradient-to-r from-rose-900 to-cyan-500",
  "bg-gradient-to-r from-rose-900 to-cyan-600",
  "bg-gradient-to-r from-rose-900 to-cyan-700",
  "bg-gradient-to-r from-rose-900 to-cyan-800",
  "bg-gradient-to-r from-rose-900 to-cyan-900",
  "bg-gradient-to-r from-rose-900 to-cyan-950",
  "bg-gradient-to-r from-rose-900 to-sky-300",
  "bg-gradient-to-r from-rose-900 to-sky-400",
  "bg-gradient-to-r from-rose-900 to-sky-500",
  "bg-gradient-to-r from-rose-900 to-sky-600",
  "bg-gradient-to-r from-rose-900 to-sky-700",
  "bg-gradient-to-r from-rose-900 to-sky-800",
  "bg-gradient-to-r from-rose-900 to-sky-900",
  "bg-gradient-to-r from-rose-900 to-sky-950",
  "bg-gradient-to-r from-rose-900 to-blue-300",
  "bg-gradient-to-r from-rose-900 to-blue-400",
  "bg-gradient-to-r from-rose-900 to-blue-500",
  "bg-gradient-to-r from-rose-900 to-blue-600",
  "bg-gradient-to-r from-rose-900 to-blue-700",
  "bg-gradient-to-r from-rose-900 to-blue-800",
  "bg-gradient-to-r from-rose-900 to-blue-900",
  "bg-gradient-to-r from-rose-900 to-blue-950",
  "bg-gradient-to-r from-rose-900 to-indigo-300",
  "bg-gradient-to-r from-rose-900 to-indigo-400",
  "bg-gradient-to-r from-rose-900 to-indigo-500",
  "bg-gradient-to-r from-rose-900 to-indigo-600",
  "bg-gradient-to-r from-rose-900 to-indigo-700",
  "bg-gradient-to-r from-rose-900 to-indigo-800",
  "bg-gradient-to-r from-rose-900 to-indigo-900",
  "bg-gradient-to-r from-rose-900 to-indigo-950",
  "bg-gradient-to-r from-rose-900 to-violet-300",
  "bg-gradient-to-r from-rose-900 to-violet-400",
  "bg-gradient-to-r from-rose-900 to-violet-500",
  "bg-gradient-to-r from-rose-900 to-violet-600",
  "bg-gradient-to-r from-rose-900 to-violet-700",
  "bg-gradient-to-r from-rose-900 to-violet-800",
  "bg-gradient-to-r from-rose-900 to-violet-900",
  "bg-gradient-to-r from-rose-900 to-violet-950",
  "bg-gradient-to-r from-rose-900 to-purple-300",
  "bg-gradient-to-r from-rose-900 to-purple-400",
  "bg-gradient-to-r from-rose-900 to-purple-500",
  "bg-gradient-to-r from-rose-900 to-purple-600",
  "bg-gradient-to-r from-rose-900 to-purple-700",
  "bg-gradient-to-r from-rose-900 to-purple-800",
  "bg-gradient-to-r from-rose-900 to-purple-900",
  "bg-gradient-to-r from-rose-900 to-purple-950",
  "bg-gradient-to-r from-rose-900 to-fuchsia-300",
  "bg-gradient-to-r from-rose-900 to-fuchsia-400",
  "bg-gradient-to-r from-rose-900 to-fuchsia-500",
  "bg-gradient-to-r from-rose-900 to-fuchsia-600",
  "bg-gradient-to-r from-rose-900 to-fuchsia-700",
  "bg-gradient-to-r from-rose-900 to-fuchsia-800",
  "bg-gradient-to-r from-rose-900 to-fuchsia-900",
  "bg-gradient-to-r from-rose-900 to-fuchsia-950",
  "bg-gradient-to-r from-rose-900 to-pink-300",
  "bg-gradient-to-r from-rose-900 to-pink-400",
  "bg-gradient-to-r from-rose-900 to-pink-500",
  "bg-gradient-to-r from-rose-900 to-pink-600",
  "bg-gradient-to-r from-rose-900 to-pink-700",
  "bg-gradient-to-r from-rose-900 to-pink-800",
  "bg-gradient-to-r from-rose-900 to-pink-900",
  "bg-gradient-to-r from-rose-900 to-pink-950",
  "bg-gradient-to-r from-rose-950 to-red-300",
  "bg-gradient-to-r from-rose-950 to-red-400",
  "bg-gradient-to-r from-rose-950 to-red-500",
  "bg-gradient-to-r from-rose-950 to-red-600",
  "bg-gradient-to-r from-rose-950 to-red-700",
  "bg-gradient-to-r from-rose-950 to-red-800",
  "bg-gradient-to-r from-rose-950 to-red-900",
  "bg-gradient-to-r from-rose-950 to-red-950",
  "bg-gradient-to-r from-rose-950 to-orange-300",
  "bg-gradient-to-r from-rose-950 to-orange-400",
  "bg-gradient-to-r from-rose-950 to-orange-500",
  "bg-gradient-to-r from-rose-950 to-orange-600",
  "bg-gradient-to-r from-rose-950 to-orange-700",
  "bg-gradient-to-r from-rose-950 to-orange-800",
  "bg-gradient-to-r from-rose-950 to-orange-900",
  "bg-gradient-to-r from-rose-950 to-orange-950",
  "bg-gradient-to-r from-rose-950 to-amber-300",
  "bg-gradient-to-r from-rose-950 to-amber-400",
  "bg-gradient-to-r from-rose-950 to-amber-500",
  "bg-gradient-to-r from-rose-950 to-amber-600",
  "bg-gradient-to-r from-rose-950 to-amber-700",
  "bg-gradient-to-r from-rose-950 to-amber-800",
  "bg-gradient-to-r from-rose-950 to-amber-900",
  "bg-gradient-to-r from-rose-950 to-amber-950",
  "bg-gradient-to-r from-rose-950 to-yellow-300",
  "bg-gradient-to-r from-rose-950 to-yellow-400",
  "bg-gradient-to-r from-rose-950 to-yellow-500",
  "bg-gradient-to-r from-rose-950 to-yellow-600",
  "bg-gradient-to-r from-rose-950 to-yellow-700",
  "bg-gradient-to-r from-rose-950 to-yellow-800",
  "bg-gradient-to-r from-rose-950 to-yellow-900",
  "bg-gradient-to-r from-rose-950 to-yellow-950",
  "bg-gradient-to-r from-rose-950 to-lime-300",
  "bg-gradient-to-r from-rose-950 to-lime-400",
  "bg-gradient-to-r from-rose-950 to-lime-500",
  "bg-gradient-to-r from-rose-950 to-lime-600",
  "bg-gradient-to-r from-rose-950 to-lime-700",
  "bg-gradient-to-r from-rose-950 to-lime-800",
  "bg-gradient-to-r from-rose-950 to-lime-900",
  "bg-gradient-to-r from-rose-950 to-lime-950",
  "bg-gradient-to-r from-rose-950 to-green-300",
  "bg-gradient-to-r from-rose-950 to-green-400",
  "bg-gradient-to-r from-rose-950 to-green-500",
  "bg-gradient-to-r from-rose-950 to-green-600",
  "bg-gradient-to-r from-rose-950 to-green-700",
  "bg-gradient-to-r from-rose-950 to-green-800",
  "bg-gradient-to-r from-rose-950 to-green-900",
  "bg-gradient-to-r from-rose-950 to-green-950",
  "bg-gradient-to-r from-rose-950 to-emerald-300",
  "bg-gradient-to-r from-rose-950 to-emerald-400",
  "bg-gradient-to-r from-rose-950 to-emerald-500",
  "bg-gradient-to-r from-rose-950 to-emerald-600",
  "bg-gradient-to-r from-rose-950 to-emerald-700",
  "bg-gradient-to-r from-rose-950 to-emerald-800",
  "bg-gradient-to-r from-rose-950 to-emerald-900",
  "bg-gradient-to-r from-rose-950 to-emerald-950",
  "bg-gradient-to-r from-rose-950 to-teal-300",
  "bg-gradient-to-r from-rose-950 to-teal-400",
  "bg-gradient-to-r from-rose-950 to-teal-500",
  "bg-gradient-to-r from-rose-950 to-teal-600",
  "bg-gradient-to-r from-rose-950 to-teal-700",
  "bg-gradient-to-r from-rose-950 to-teal-800",
  "bg-gradient-to-r from-rose-950 to-teal-900",
  "bg-gradient-to-r from-rose-950 to-teal-950",
  "bg-gradient-to-r from-rose-950 to-cyan-300",
  "bg-gradient-to-r from-rose-950 to-cyan-400",
  "bg-gradient-to-r from-rose-950 to-cyan-500",
  "bg-gradient-to-r from-rose-950 to-cyan-600",
  "bg-gradient-to-r from-rose-950 to-cyan-700",
  "bg-gradient-to-r from-rose-950 to-cyan-800",
  "bg-gradient-to-r from-rose-950 to-cyan-900",
  "bg-gradient-to-r from-rose-950 to-cyan-950",
  "bg-gradient-to-r from-rose-950 to-sky-300",
  "bg-gradient-to-r from-rose-950 to-sky-400",
  "bg-gradient-to-r from-rose-950 to-sky-500",
  "bg-gradient-to-r from-rose-950 to-sky-600",
  "bg-gradient-to-r from-rose-950 to-sky-700",
  "bg-gradient-to-r from-rose-950 to-sky-800",
  "bg-gradient-to-r from-rose-950 to-sky-900",
  "bg-gradient-to-r from-rose-950 to-sky-950",
  "bg-gradient-to-r from-rose-950 to-blue-300",
  "bg-gradient-to-r from-rose-950 to-blue-400",
  "bg-gradient-to-r from-rose-950 to-blue-500",
  "bg-gradient-to-r from-rose-950 to-blue-600",
  "bg-gradient-to-r from-rose-950 to-blue-700",
  "bg-gradient-to-r from-rose-950 to-blue-800",
  "bg-gradient-to-r from-rose-950 to-blue-900",
  "bg-gradient-to-r from-rose-950 to-blue-950",
  "bg-gradient-to-r from-rose-950 to-indigo-300",
  "bg-gradient-to-r from-rose-950 to-indigo-400",
  "bg-gradient-to-r from-rose-950 to-indigo-500",
  "bg-gradient-to-r from-rose-950 to-indigo-600",
  "bg-gradient-to-r from-rose-950 to-indigo-700",
  "bg-gradient-to-r from-rose-950 to-indigo-800",
  "bg-gradient-to-r from-rose-950 to-indigo-900",
  "bg-gradient-to-r from-rose-950 to-indigo-950",
  "bg-gradient-to-r from-rose-950 to-violet-300",
  "bg-gradient-to-r from-rose-950 to-violet-400",
  "bg-gradient-to-r from-rose-950 to-violet-500",
  "bg-gradient-to-r from-rose-950 to-violet-600",
  "bg-gradient-to-r from-rose-950 to-violet-700",
  "bg-gradient-to-r from-rose-950 to-violet-800",
  "bg-gradient-to-r from-rose-950 to-violet-900",
  "bg-gradient-to-r from-rose-950 to-violet-950",
  "bg-gradient-to-r from-rose-950 to-purple-300",
  "bg-gradient-to-r from-rose-950 to-purple-400",
  "bg-gradient-to-r from-rose-950 to-purple-500",
  "bg-gradient-to-r from-rose-950 to-purple-600",
  "bg-gradient-to-r from-rose-950 to-purple-700",
  "bg-gradient-to-r from-rose-950 to-purple-800",
  "bg-gradient-to-r from-rose-950 to-purple-900",
  "bg-gradient-to-r from-rose-950 to-purple-950",
  "bg-gradient-to-r from-rose-950 to-fuchsia-300",
  "bg-gradient-to-r from-rose-950 to-fuchsia-400",
  "bg-gradient-to-r from-rose-950 to-fuchsia-500",
  "bg-gradient-to-r from-rose-950 to-fuchsia-600",
  "bg-gradient-to-r from-rose-950 to-fuchsia-700",
  "bg-gradient-to-r from-rose-950 to-fuchsia-800",
  "bg-gradient-to-r from-rose-950 to-fuchsia-900",
  "bg-gradient-to-r from-rose-950 to-fuchsia-950",
  "bg-gradient-to-r from-rose-950 to-pink-300",
  "bg-gradient-to-r from-rose-950 to-pink-400",
  "bg-gradient-to-r from-rose-950 to-pink-500",
  "bg-gradient-to-r from-rose-950 to-pink-600",
  "bg-gradient-to-r from-rose-950 to-pink-700",
  "bg-gradient-to-r from-rose-950 to-pink-800",
  "bg-gradient-to-r from-rose-950 to-pink-900",
  "bg-gradient-to-r from-rose-950 to-pink-950",
];
