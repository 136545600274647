import Image from "next/image";

import TryForFreeButton from "@/components/TryForFreeButton";
import PeopleSmilingImage from "@/public/pages/about/people_smiling.png";

export default function TransformBrowse() {
  return (
    <div className="flex flex-col-reverse items-center md:grid md:grid-cols-2 w-full max-w-[var(--max-width-app)] text-[var(--color-text-light-blue)] mb-20 rounded-xl overflow-hidden">
      <div className="flex flex-col justify-center h-full gap-6 md:gap-8 p-8 md:p-12 bg-[var(--color-card)]">
        <h1 className="text-[var(--color-primary)] text-3xl lg:text-4xl font-medium">
          Ready to transform the way you bookmark?
        </h1>

        <p className="text-lg lg:text-xl font-light">
          Sign up for Yoyo Shortcut today and take control of your bookmarks
          like never before. Say goodbye to clutter and hello to a faster, more
          organized online experience!
        </p>

        <div className="flex flex-wrap flex-row mt-4 gap-4 items-center md:mb-12">
          <TryForFreeButton
            size="bg"
            buttonLocation="TransformBrowse - Try It Free Button"
            text="Try It Free"
            className="!m-0 bg-[var(--color-yellow)] text-black text-base lg:text-xl font-medium px-6 lg:px-10 py-3 rounded-lg w-fit cursor-pointer"
          />

          <div className="text-xs font-light flex flex-col gap-1">
            <p>Generous Freemium plan.</p>
            <p>No credit card required.</p>
          </div>
        </div>
      </div>

      <div className="w-full h-full max-h-[480px] md:max-h-[unset]">
        <Image
          src={PeopleSmilingImage.src}
          height={PeopleSmilingImage.height}
          width={PeopleSmilingImage.width}
          alt="People Smiling"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
}
