import { useEffect, useMemo, useState } from "react";

import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import clsx from "clsx";
import { EmblaCarouselType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";

import DucThanImg from "@/public/pages/about/duc-than.jpeg";
import PhuocDoImg from "@/public/pages/about/phuoc-do.jpeg";
import TuDoImg from "@/public/pages/about/tu-do.jpeg";

import TestimonialItem from "./TestimonialItem";

export default function TestimonialSection() {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false });
  const [slide, setSlide] = useState(0);

  useEffect(() => {
    if (emblaApi) {
      const handleScroll = (e: EmblaCarouselType) => {
        const progress = e.scrollProgress();
        // progress 0, 1/2, 1 -> slide 0, 1, 2
        setSlide(Math.round(progress * 2));
      };

      const handleReInit = (e: EmblaCarouselType) => {
        e.scrollTo(0);
        setSlide(0);
      };

      emblaApi.on("scroll", handleScroll);
      emblaApi.on("reInit", handleReInit);
      return () => {
        emblaApi.off("scroll", handleScroll);
        emblaApi.off("reInit", handleReInit);
      };
    }
  }, [emblaApi]);

  const items = useMemo(() => {
    return [
      <TestimonialItem
        key={0}
        name="Phuoc Do"
        imgSrc={PhuocDoImg.src}
        title="Software Engineer @ Meta"
        testimonial="I use this tool almost on every workday, and it has greatly cut down my rampup time on context switch. Using this tool makes me feel like I am the resource holder for my team. Now every work links are all in my head."
      />,

      <TestimonialItem
        key={1}
        name="David Than"
        imgSrc={DucThanImg.src}
        title="Software Engineer @ Samsung"
        testimonial="Thanks to Yoyo Shortcut, I’ve cut down my link-searching time by over 80%, which is invaluable in my busy schedule. I can’t recommend Yoyo enough for anyone looking to optimize their web browsing and link management. It's a small tool that makes a huge impact!"
      />,

      <TestimonialItem
        key={2}
        name="Tu Do"
        imgSrc={TuDoImg.src}
        title="CS Student @ UTD"
        testimonial="Yoyo Shortcut is my trusted link database because of its simple and customizable interface to make searching for links smoother. There are many types of links out there: researching, studying, coding, saving for later, etc. Yoyo Shortcut can handle most of my use cases."
      />,
    ];
  }, []);

  return (
    <div className="relative flex flex-col items-center mt-4 p-8 gap-8 w-full text-[var(--color-text-light-blue)] bg-[var(--color-card-dark)]">
      <h1 className="text-3xl font-medium text-[--color-text-highlight]">
        Testimonials
      </h1>

      <div className="hidden lg:grid lg:grid-cols-3 gap-12 w-full max-w-[--max-width-app]">
        {items.map((item) => item)}
      </div>

      {/* Slider */}
      <div className="max-w-full overflow-hidden lg:hidden" ref={emblaRef}>
        <div className="flex">
          {items.map((item, idx) => (
            <div
              key={idx}
              className="min-w-0 flex justify-center grow-0 shrink-0 basis-full"
            >
              <div>{item}</div>
            </div>
          ))}
        </div>
      </div>

      {/* Slide status */}
      <div className="lg:hidden flex gap-2">
        <div
          className={clsx("rounded-full bg-[--color-text-secondary] w-3 h-3", {
            ["!bg-[--color-secondary]"]: slide === 0,
          })}
          onClick={() => {
            emblaApi?.scrollTo(0);
          }}
        />
        <div
          className={clsx("rounded-full bg-[--color-text-secondary] w-3 h-3", {
            ["!bg-[--color-secondary]"]: slide === 1,
          })}
          onClick={() => {
            emblaApi?.scrollTo(1);
          }}
        />
        <div
          className={clsx("rounded-full bg-[--color-text-secondary] w-3 h-3", {
            ["!bg-[--color-secondary]"]: slide === 2,
          })}
          onClick={() => {
            emblaApi?.scrollTo(2);
          }}
        />
      </div>

      {/* Arrows */}
      <ArrowBackIosRoundedIcon
        className={clsx(
          "lg:!hidden absolute !h-7 !w-auto top-[50%] left-0 -translate-y-[50%]",
          { ["!hidden"]: slide === 0 }
        )}
        onClick={() => {
          if (emblaApi?.canScrollPrev()) {
            emblaApi?.scrollTo(slide - 1);
          }
        }}
      />
      <ArrowForwardIosRoundedIcon
        className={clsx(
          "lg:!hidden absolute !h-7 !w-auto top-[50%] right-0 -translate-y-[50%]",
          { ["!hidden"]: slide === 2 }
        )}
        onClick={() => {
          if (emblaApi?.canScrollNext()) {
            emblaApi?.scrollTo(slide + 1);
          }
        }}
      />
    </div>
  );
}
